import {atom, useAtom} from 'jotai';
import {useCallback, useMemo} from 'react';
import {getTableLayout, updateTableLayout} from '../api';
import {TableLayoutKey} from '../types';
import {TableLayout, TableLayoutResult} from './useTableLayout';
import {tableLayoutFields} from '../constants';
import {getTableLayoutForDB} from '@indexedDB/tableLayoutSetting/repository';
import {useAsyncEffect} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isEqual} from 'lodash';

type LayoutData = {[layoutName: string]: string[]};
export const tableLayoutAtom = atom<LayoutData | undefined>(undefined);
const loadingAtom = atom(false);
/**LocalStorageのKeyのプレフィックス */
const tableLayoutPrefix = 'tableLayout';

/**
 * 引数のKeyを持つテーブルレイアウトをAPI経由で取得・保存する
 * @param layoutName
 * @param myInfo
 * @returns
 */
export const useTableLayoutResult = (
  layoutName: TableLayoutKey
): [string[] | undefined, (currentLayout: string[]) => void] => {
  const {myInfo} = useMyInfo();
  const [tableLayoutData, setTableLayoutData] = useAtom(tableLayoutAtom);
  const [isLoading, setLoading] = useAtom(loadingAtom);

  const localStorageTableLayout = useMemo<TableLayoutResult | null>(() => {
    const storageKey = `${tableLayoutPrefix}_${layoutName}`;
    const item = localStorage.getItem(storageKey);
    if (item === null) {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (error) {
      console.error(error);
      localStorage.removeItem(storageKey);

      return null;
    }
  }, [layoutName]);

  /**
   * 新しいレイアウトのセット
   * @param {string[]} currentLayout セットするテーブルレイアウト
   */
  const setData = useCallback(
    async (currentLayout: string[]) => {
      if (isEqual(currentLayout, tableLayoutData?.[layoutName])) return;

      // Atomに新しいLayoutをSet
      const newTableLayoutData = {...tableLayoutData};
      newTableLayoutData[layoutName] = currentLayout;
      setTableLayoutData(newTableLayoutData);

      // サーバとやり取り中でなければ、サーバに保存する
      if (isLoading === true) return;

      try {
        setLoading(true);

        await updateTableLayout(myInfo.hospitalHashId, myInfo.hashId, layoutName, currentLayout);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setTableLayoutData, tableLayoutData, layoutName, isLoading, myInfo]
  );

  /** APIからレイアウトデータを取得 */
  const getLayoutDataForApi = useCallback(async () => {
    if (isLoading === true) return;

    try {
      setLoading(true);

      const [{data}, tableLayoutForDB] = await Promise.all([
        getTableLayout(myInfo.hospitalHashId, myInfo.hashId),
        getTableLayoutForDB(myInfo.hashId),
      ]);

      const layoutData: LayoutData = data?.reduce((obj, item) => {
        try {
          // NOTE:indexedDBにデータがあればそちらを優先
          const currentLayoutForDB = tableLayoutForDB?.tableLayoutResponse.find(
            (v) => v.layoutName === item.layoutName
          );
          obj[item.layoutName] = currentLayoutForDB?.currentLayout ?? item.currentLayout;
        } catch (error) {
          console.error(error);
        }
        return obj;
      }, {} as LayoutData);

      setTableLayoutData(layoutData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [isLoading, myInfo.hashId, myInfo.hospitalHashId, setLoading, setTableLayoutData]);

  /**
   * ローカルストレージにあるテーブルレイアウトをサーバに保存して、ローカルストレージからデータを削除する
   * @param {string[]} currentLayout セットするテーブルレイアウト
   */
  const migrateTableLayoutToAPI = useCallback(
    async (currentLayout: TableLayout[]) => {
      if (isLoading) return;

      try {
        setLoading(true);

        // ローカルストレージのデータをAPIに保存
        const localStorageLayout = currentLayout.map(({field}) => field);
        const result = await updateTableLayout(myInfo.hospitalHashId, myInfo.hashId, layoutName, localStorageLayout);
        setData(result.currentLayout);

        //送信が終わったらローカルストレージを削除 以降はAPI経由で取得保存する
        localStorage.removeItem(`${tableLayoutPrefix}_${layoutName}`);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setData, setLoading, isLoading, layoutName, myInfo.hashId, myInfo.hospitalHashId]
  );

  useAsyncEffect(async () => {
    if (localStorageTableLayout !== null) {
      // LocalStorageにデータがあれば削除してデータを保存
      await migrateTableLayoutToAPI(localStorageTableLayout.currentLayout);
    } else if (!tableLayoutData) {
      // 取得済みがなければAPIから取得
      await getLayoutDataForApi();
    }
  }, [localStorageTableLayout]);

  return useMemo(() => {
    // 点検表の設定、点検対象画面はデフォルトの値を返す
    if (layoutName === 'inspectionList' || layoutName === 'inspectionProductSettingList') {
      const defaultLayout = tableLayoutFields[layoutName]
        .filter(({defaultShow}) => defaultShow)
        .map((item) => item.field);

      return [defaultLayout, setData];
    }
    // NOTE:初期動作等で値がない場合はデフォルトの値を返す
    if (!tableLayoutData?.[layoutName] && isLoading === false) {
      const defaultLayout = tableLayoutFields[layoutName]
        .filter(({defaultShow}) => defaultShow)
        .map((item) => item.field);

      return [defaultLayout, setData];
    }

    return [tableLayoutData?.[layoutName], setData];
  }, [setData, layoutName, tableLayoutData, isLoading]);
};
