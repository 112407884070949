import {Box, IconButton, Typography, makeStyles} from '@material-ui/core';
import React, {useCallback} from 'react';
import skip from '@assets/skip.svg';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import {
  StartInspectionDialog,
  StartInspectionDialogProps,
  StartInspectionDialogResult,
} from './Dialog/StartInspectionDialog';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {useNavigate} from 'react-router-dom';
import {
  SkipInspectionDialog,
  SkipInspectionDialogProps,
  SkipInspectionDialogResult,
} from './Dialog/SkipInspectionDialog';
import {
  UpdateScheduledDateDialog,
  UpdateScheduledDateDialogProps,
  UpdateScheduledDateDialogResult,
} from './Dialog/UpdateScheduledInspectionDialog';
import {NextUpdateInspectionStatusParam, updateInspectionResult} from '@modules/inspection_results/api';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {openSnackBar} from '@components/molecules/SnackBar';
import CheckIcon from '@material-ui/icons/Check';

export const ActionBtnContainer: React.FC<ActionBtnContainerProps> = (props) => {
  const {inspectionResult, refetch} = props;
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const handleClickStart = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      try {
        const res = await dialogHandler.open<StartInspectionDialogProps, StartInspectionDialogResult>(
          StartInspectionDialog,
          {
            defaultInspectorHashId: myInfo.hashId,
            inspectionResult: inspectionResult,
          }
        );

        const nextUpdateInspectionStatusParam: NextUpdateInspectionStatusParam = {
          status: 'uncompleted',
          inspectorHashId: res.inspectorHashId,
        };
        // 未実施の場合のみ、点検表をDialogから選択できる
        const inspectionHashId =
          inspectionResult.status === 'uncompleted' ? inspectionResult.inspection.hashId : res.inspectionHashId;

        navigate(`/sp/inspections/${inspectionHashId}/result/${inspectionResult.hashId}`, {
          state: nextUpdateInspectionStatusParam,
        });
      } catch (_e) {
        return;
      }
    },
    [inspectionResult, myInfo.hashId, navigate]
  );

  const handleClickSkip = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      let skipDate: Date;
      let operator: string;
      let reason: string | undefined;

      try {
        const res = await dialogHandler.open<SkipInspectionDialogProps, SkipInspectionDialogResult>(
          SkipInspectionDialog,
          {
            inspectionResult: inspectionResult,
            defaultOperatorHashId: myInfo.hashId,
            defaultSkipDate: convertDateToSimpleDate(new Date()),
          }
        );
        skipDate = res.skipDate;
        operator = res.operator;
        reason = res.reason;
      } catch (_e) {
        return;
      }

      try {
        await updateInspectionResult(
          myInfo.hospitalHashId,
          inspectionResult.inspectionHashId,
          inspectionResult.hashId,
          {
            status: 'skipped',
            skippedByHashId: operator,
            skippedTime: convertDateToSimpleDate(skipDate),
            skipReason: reason,
          }
        );
        openSnackBar('点検をスキップしました', 'left', 'bottom', 'success');
        refetch();
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar('点検のスキップに失敗しました', 'left', 'bottom', 'error');
      }
    },
    [inspectionResult, myInfo.hashId, myInfo.hospitalHashId, refetch]
  );

  const handleClickReSchedule = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      let scheduledDate: Date;
      try {
        const res = await dialogHandler.open<UpdateScheduledDateDialogProps, UpdateScheduledDateDialogResult>(
          UpdateScheduledDateDialog,
          {inspectionResult: inspectionResult}
        );
        scheduledDate = res.scheduledDate;
      } catch (_e) {
        return;
      }

      try {
        await updateInspectionResult(
          myInfo.hospitalHashId,
          inspectionResult.inspectionHashId,
          inspectionResult.hashId,
          {
            scheduledTime: convertDateToSimpleDate(scheduledDate),
          }
        );
        openSnackBar('点検予定日を更新しました', 'left', 'bottom', 'success');
        refetch();
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar('点検予定日の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [inspectionResult, myInfo.hospitalHashId, refetch]
  );

  const handleClickCompleted = useCallback(() => {
    navigate(`/sp/inspections/${inspectionResult.inspectionHashId}/result/${inspectionResult.hashId}`);
  }, [inspectionResult, navigate]);

  switch (inspectionResult.status) {
    case 'unplanned':
    case 'uncompleted':
      return UnPlannedAndUnCompletedActionBtnContainer({
        handleClickStart,
        handleClickReSchedule,
        handleClickSkip,
      });
    case 'completed':
      return CompleteActionBtnContainer({handleClickCompleted});
    case 'skipped':
      return SkipActionBtnContainer({handleClickStart});
    default:
      return null;
  }
};

type ActionBtnContainerProps = {
  inspectionResult: InspectionResultIndex;
  refetch: () => void;
};

const UnPlannedAndUnCompletedActionBtnContainer: React.FC<ActionBtnCustomContainerProps> = (props) => {
  const {handleClickStart, handleClickReSchedule, handleClickSkip} = props;
  const classes = useStyles();
  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.inspectionButtonContainer} onClick={handleClickStart}>
        <IconButton style={{width: '28px', height: '32px'}}>
          <CheckIcon className={classes.icon} />
        </IconButton>
        <Typography style={{color: '#172B4D', whiteSpace: 'nowrap'}}>点検</Typography>
      </Box>
      <Box className={classes.inspectionButtonContainer} onClick={handleClickSkip}>
        <IconButton style={{width: '28px', height: '32px'}}>
          <img src={skip} alt="skip" width={'12px'} height={'16px'} />
        </IconButton>
        <Typography style={{color: '#172B4D', whiteSpace: 'nowrap'}}>スキップ</Typography>
      </Box>
      <Box className={classes.inspectionButtonContainer} onClick={handleClickReSchedule}>
        <IconButton style={{width: '20px', height: '32px'}}>
          <DateRangeOutlinedIcon style={{width: '16px', color: '#172B4D'}} />
        </IconButton>
        <Typography style={{color: '#172B4D', whiteSpace: 'nowrap'}}>変更</Typography>
      </Box>
    </Box>
  );
};

const CompleteActionBtnContainer: React.FC<ActionBtnCustomContainerProps> = (props) => {
  const {handleClickCompleted} = props;
  const classes = useStyles();

  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.inspectionButtonContainer} onClick={handleClickCompleted}>
        <IconButton style={{width: '28px', height: '32px'}}>
          <CheckIcon className={classes.icon} />
        </IconButton>
        <Typography style={{color: '#172B4D', whiteSpace: 'nowrap'}}>点検結果確認</Typography>
      </Box>
    </Box>
  );
};

const SkipActionBtnContainer: React.FC<ActionBtnCustomContainerProps> = (props) => {
  const {handleClickStart} = props;
  const classes = useStyles();
  return (
    <Box className={classes.boxStyle}>
      <Box className={classes.inspectionButtonContainer} onClick={handleClickStart}>
        <IconButton style={{width: '28px', height: '32px'}}>
          <CheckIcon className={classes.icon} />
        </IconButton>
        <Typography style={{color: '#172B4D', whiteSpace: 'nowrap'}}>点検開始</Typography>
      </Box>
    </Box>
  );
};

type ActionBtnCustomContainerProps = {
  handleClickStart?: (e: React.MouseEvent) => void;
  handleClickSkip?: (e: React.MouseEvent) => void;
  handleClickReSchedule?: (e: React.MouseEvent) => void;
  handleClickCompleted?: () => void;
};

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    width: '208px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 8px',
    justifyContent: 'flex-start',
    paddingLeft: '70px',
  },
  inspectionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '10px',
    paddingRight: '8px',
    marginRight: '8px',
  },
  icon: {
    width: '20px',
    height: '20px',
    color: '#172B4D',
  },
}));
