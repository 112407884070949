import {NotificationCategory, NotificationOptionFilter} from '@modules/notifications/types';

export const enabled =
  import.meta.env.VITE_APP_ENV !== 'local' || import.meta.env.VITE_DEBUG_USER_NOTIFICATION === 'true';

export const USER_NOTIFICATION_DRAWER_TITLE = '通知';
export const PMDA_NOTIFICATION_DRAWER_TITLE = 'PMDA関連通知';

// ユーザ用の通知
export const RENT_NOTIFICATION = 'rent_notification';
export const RETURN_NOTIFICATION = 'return_notification';
export const INSPECTION_REMINDER = 'inspection_reminder';
export const FAULT_REPAIR_PIC_NOTIFICATION = 'fault_repair_pic_notification';
export const EXPORT_HOSPITAL_PRODUCT_NOTIFICATION = 'export_hospital_product_notification';
export const EXPORT_INSPECTION_RESULT_NOTIFICATION = 'export_inspection_result_notification';
export const EXPORT_TRAINING_PLAN_NOTIFICATION = 'export_training_plan_notification';
export const IMPORT_HOSPITAL_PRODUCT_NOTIFICATION = 'import_hospital_product_notification';
export const GENERATE_INSPECTION_RESULT_PDFS_NOTIFICATION = 'generate_inspection_result_pdfs_notification';

// PMDA用の通知
export const PMDA_UPLOAD_NOTIFICATION = 'pmda_upload_notification';
export const PMDA_RECALL_NOTIFICATION = 'pmda_recall_notification';

export const userNotificationCategories: NotificationCategory[] = [
  RENT_NOTIFICATION,
  RETURN_NOTIFICATION,
  INSPECTION_REMINDER,
  FAULT_REPAIR_PIC_NOTIFICATION,
  EXPORT_HOSPITAL_PRODUCT_NOTIFICATION,
  EXPORT_INSPECTION_RESULT_NOTIFICATION,
  EXPORT_TRAINING_PLAN_NOTIFICATION,
  IMPORT_HOSPITAL_PRODUCT_NOTIFICATION,
  GENERATE_INSPECTION_RESULT_PDFS_NOTIFICATION,
];

export const userNotificationOptionFilters: NotificationOptionFilter[] = [
  {
    id: 'all',
    label: 'すべて選択',
    values: [
      RENT_NOTIFICATION,
      RETURN_NOTIFICATION,
      INSPECTION_REMINDER,
      FAULT_REPAIR_PIC_NOTIFICATION,
      EXPORT_HOSPITAL_PRODUCT_NOTIFICATION,
      EXPORT_INSPECTION_RESULT_NOTIFICATION,
      EXPORT_TRAINING_PLAN_NOTIFICATION,
      IMPORT_HOSPITAL_PRODUCT_NOTIFICATION,
      GENERATE_INSPECTION_RESULT_PDFS_NOTIFICATION,
    ],
  },
  {
    id: 'rent_notification',
    values: [RENT_NOTIFICATION],
    label: '貸出',
  },
  {
    id: 'return_notification',
    values: [RETURN_NOTIFICATION],
    label: '返却',
  },
  {
    id: 'inspection_reminder',
    values: [INSPECTION_REMINDER],
    label: 'リマインダー',
  },
  {
    id: 'fault_repair_pic_notification',
    values: [FAULT_REPAIR_PIC_NOTIFICATION],
    label: '修理担当者の割り当て',
  },
  {
    id: 'background',
    values: [
      EXPORT_HOSPITAL_PRODUCT_NOTIFICATION,
      EXPORT_INSPECTION_RESULT_NOTIFICATION,
      EXPORT_TRAINING_PLAN_NOTIFICATION,
      IMPORT_HOSPITAL_PRODUCT_NOTIFICATION,
      GENERATE_INSPECTION_RESULT_PDFS_NOTIFICATION,
    ],
    label: 'バックグラウンド処理',
  },
];

export const pmdaNotificationCategories: NotificationCategory[] = [PMDA_UPLOAD_NOTIFICATION, PMDA_RECALL_NOTIFICATION];

export const pmdaNotificationOptionFilters: NotificationOptionFilter[] = [
  {
    id: 'all',
    label: 'すべて選択',
    values: [PMDA_UPLOAD_NOTIFICATION, PMDA_RECALL_NOTIFICATION],
  },
  {
    id: 'pmda_upload_notification',
    values: [PMDA_UPLOAD_NOTIFICATION],
    label: '添付文書の更新',
  },
  {
    id: 'pmda_recall_notification',
    values: [PMDA_RECALL_NOTIFICATION],
    label: '回収情報の発出',
  },
];
