import {useCallback} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {isNullish} from '@front-libs/helpers';
import {ResetMyInfo} from '@modules/hospital_users/api';

export const useLogout = () => {
  const {logout, isAuthenticated} = useAuth0();
  return useCallback(() => {
    if (isAuthenticated) {
      logout({
        returnTo: window.location.origin,
      });
      ResetMyInfo();
      !isNullish(window.zE) && window.zE('messenger', 'logoutUser');
    }
  }, [isAuthenticated, logout]);
};
