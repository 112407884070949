import {Typography, styled} from '@material-ui/core';
import React from 'react';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const LabelTypography = styled(Typography)(({theme}) => ({
  paddingLeft: '8px',
  color: theme.palette.primary.main, // テーマの primaryColor を使用
  fontSize: '14px',
  cursor: 'pointer',
}));

const AlignTopIcon = styled(VerticalAlignTopIcon)(({theme}) => ({
  color: theme.palette.primary.main, // テーマの primaryColor を使用
  cursor: 'pointer',
}));

type Props = {
  onUploadFile: () => void;
};

export const PmdaFileUploadIconButton = ({onUploadFile}: Props) => {
  return (
    <RootContainer>
      <AlignTopIcon onClick={onUploadFile} />
      <LabelTypography onClick={onUploadFile}>添付文書ファイルをアップロード</LabelTypography>
    </RootContainer>
  );
};
