import React from 'react';
import {Button, Card, Divider, Grid, IconButton, makeStyles, Theme} from '@material-ui/core';
import {ResultType} from './types';
import {Delete} from '@material-ui/icons';

type ResultCardProps = {
  result: ResultType;
  onRemoveFilter: () => void;
  onClickResultCard: () => void;
};

const ResultCard: React.VFC<ResultCardProps> = ({result, ...props}) => {
  const classes = useCardStyles();

  return (
    <Card elevation={0} className={classes.root} onClick={props.onClickResultCard}>
      <Grid container className={classes.content} justifyContent={'space-between'}>
        <Grid item style={{margin: 'auto 0', maxWidth: 'calc(100% - 56px)'}}>
          <span className={classes.contentLabel}>{result.displayLabel}</span>：{result.filterLabel}
        </Grid>
        <Grid item>
          <IconButton
            className={classes.contentLabel}
            onClick={(e) => {
              props.onRemoveFilter();
              e.stopPropagation();
            }}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

const useCardStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #C6CBD4',
    borderRadius: '4px',
    transition: 'all 300ms ease 0s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5F6F7',
    },
  },
  content: {
    padding: '24px 24px 24px 16px',
    fontWeight: 'bold',
  },
  contentLabel: {
    color: theme.palette.secondary.dark,
  },
}));

type FilterResultBodyProps = {
  results: ResultType[];
  totalCount: number;
  filteredCount: number;
  onReqAdditionalFilter: () => void;
  onRemoveFilterByKey: (key: string) => void;
  onNavigateCard?: (key: string) => void;
};

export const FilterResultBody: React.VFC<FilterResultBodyProps> = ({results, totalCount, filteredCount, ...props}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.searchContainer}>
        <div>{`${totalCount}件中の${filteredCount}件のレコードを表示`}</div>
      </Grid>
      <Divider variant="middle" className={classes.divider} />
      <Grid container className={classes.resultContainer}>
        {results.map((item, index) => (
          <div key={index} style={{marginBottom: '16px', width: '100%', marginLeft: '32px', marginRight: '32px'}}>
            <ResultCard
              result={item}
              onRemoveFilter={() => props.onRemoveFilterByKey(item.key)}
              onClickResultCard={() => props.onNavigateCard && props.onNavigateCard(item.key)}
            />
          </div>
        ))}
      </Grid>
      <Grid container className={classes.addFilterContainer}>
        <Button className={classes.addFilterButton} onClick={props.onReqAdditionalFilter}>
          さらに絞り込む
        </Button>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 450,
  },
  resultContainer: {},
  searchContainer: {
    width: '400px',
    padding: '32px 32px 0',
  },
  addFilterContainer: {
    marginLeft: '32px',
    marginRight: '32px',
    marginTop: '16px',
  },
  addFilterButton: {
    borderRadius: '2px',
    border: '1px solid #C6CBD4',
    backgroundColor: '#F5F6F7',
  },
  divider: {
    margin: '24px 32px 32px 32px',
  },

  contentContainer: {
    padding: '32px',
  },
  property: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  searchText: {
    marginTop: '16px',
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
  },
}));
