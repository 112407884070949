import React, {useCallback, useMemo} from 'react';
import {useAtom, useAtomValue} from 'jotai';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {searchVariablesAtom} from './state';
import {Column} from '@molecules/Table/props';
import {useMutation, useQuery} from 'react-query';
import {getTrainingPlans} from '@modules/training_plans/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {TrainingTypeValue} from '@modules/training_plans/types';
import {convertDisplaySimpleDate, isNullish, StrUtil} from '@front-libs/helpers';
import {ScheduleTableData} from './ScheduleList';
import {trainingTypeOptions} from '@modules/training_plans/constants';
import {
  createTrainingSchedule,
  CreateTrainingScheduleParam,
  getTrainingSchedules,
  updateTrainingSchedule,
  UpdateTrainingScheduleParam,
} from '@modules/training_schedules/api';
import {StatusBadge} from '@molecules/StatusBadge';
import {Link} from 'react-router-dom';
import {styled} from '@material-ui/core';

const SortingDisableMap: Record<string, boolean> = {
  trainingTypes: true,
};

export const useSearchVariables = () => {
  const [variables, setVariables] = useAtom(searchVariablesAtom);

  const setName = useCallback(
    (name: string) => {
      setVariables({...variables, name: name === '' ? null : name});
    },
    [setVariables, variables]
  );

  const setTrainingPlanHashIds = useCallback(
    (hashIds: string[]) => {
      setVariables({...variables, trainingPlanHashIds: hashIds.length === 0 ? null : hashIds});
    },
    [setVariables, variables]
  );

  const setTrainingCategories = useCallback(
    (trainingTypes: TrainingTypeValue[]) => {
      setVariables({...variables, trainingTypes: trainingTypes.length === 0 ? null : trainingTypes});
    },
    [setVariables, variables]
  );

  const setPage = useCallback(
    (newPage: number) => {
      setVariables({...variables, page: newPage});
    },
    [setVariables, variables]
  );

  const setOrder = useCallback(
    (field: string, orderDirection: 'asc' | 'desc') => {
      setVariables({...variables, order: `${orderDirection === 'desc' ? '-' : ''}${field}`});
    },
    [setVariables, variables]
  );

  return {variables, setName, setTrainingPlanHashIds, setTrainingCategories, setPage, setOrder};
};

export const useTableData = () => {
  const {data, isLoading} = useTrainingSchedulesQuery();
  const [tableLayout] = useTableLayout('trainingScheduleList');

  const tableColumns = useMemo(() => {
    return (tableLayout?.currentLayout ?? []).map<Column<ScheduleTableData>>((item) => {
      const column: Column<ScheduleTableData> = {
        title: item.title,
        field: item.field,
        noBodyWrap: true,
        sorting: SortingDisableMap[item.field] === true ? false : true,
      };
      switch (item.field) {
        case 'name':
          column.render = ({hashId, name, currentTimes}: ScheduleTableData) => (
            <NavLink to={`/training_managements/schedules/${hashId}`}>
              {name} - {currentTimes}回目
            </NavLink>
          );
          break;
        case 'status':
          column.render = ({status}: ScheduleTableData) => {
            if (status === 'done') {
              return <StatusBadge status={'success'} text={'完了'} />;
            }
            if (status === 'incomplete') {
              return <StatusBadge status={'primary'} text={'未完了'} />;
            }
            return '';
          };
          break;
        case 'trainingTypes':
          column.render = ({trainingTypes}: ScheduleTableData) =>
            StrUtil.nl2br(
              trainingTypes
                .map((category) => {
                  const displayCategory = trainingTypeOptions.find((typeOption) => typeOption.value === category);
                  if (isNullish(displayCategory)) {
                    return '';
                  } else {
                    return `■ ${displayCategory.label}`;
                  }
                })
                .join('\n')
            );
          break;
        case 'scheduleDateRange':
          column.render = ({scheduleDateRange}: ScheduleTableData) => {
            const startAt = convertDisplaySimpleDate(scheduleDateRange[0]);
            const finishAt = convertDisplaySimpleDate(scheduleDateRange[1]);
            return `${startAt} 〜 ${finishAt}`;
          };
          break;
        case 'numberOfTrainees':
          column.render = ({numberOfTrainees}: ScheduleTableData) => {
            return isNullish(numberOfTrainees) ? '' : `${numberOfTrainees}名`;
          };
          break;
        default:
          break;
      }
      return column;
    });
  }, [tableLayout?.currentLayout]);

  const scheduleData = useMemo<ScheduleTableData[]>(() => {
    return (
      data?.data.map((item) => ({
        hashId: item.hashId,
        status: item.status,
        name: item.trainingPlan.name,
        currentTimes: item.currentTimes,
        trainingTypes: item.trainingPlan.trainingTypes,
        scheduleDateRange: [item.startAt, item.finishAt],
        place: item.place ?? '',
        numberOfTrainees: item.numberOfTrainees,
      })) ?? []
    );
  }, [data?.data]);

  return {tableColumns, scheduleData, isLoading};
};

export const useTrainingPlansQueryOptions = () => {
  const {myInfo} = useMyInfo();
  const query = useQuery(['useTrainingPlansQuery'], () => getTrainingPlans(myInfo.hospitalHashId, {perPage: 100}), {});

  const trainingPlanOptions = useMemo(
    () => (query.data?.data ?? []).map((item) => ({value: item.hashId, label: item.name})),
    [query.data?.data]
  );

  return {trainingPlanOptions};
};

export const useTrainingSchedulesQuery = () => {
  const {myInfo} = useMyInfo();
  const searchVariables = useAtomValue(searchVariablesAtom);

  const query = useQuery(['useTrainingSchedulesQuery', myInfo, searchVariables], () =>
    getTrainingSchedules(myInfo.hospitalHashId, {
      name: searchVariables.name ?? undefined,
      trainingTypes: searchVariables.trainingTypes ?? undefined,
      trainingPlanHashIds: searchVariables.trainingPlanHashIds ?? undefined,
      page: searchVariables.page ?? undefined,
      perPage: searchVariables.perPage ?? undefined,
      order: searchVariables.order ?? undefined,
    })
  );
  return {...query, data: query.data};
};

export const useCreateTrainingScheduleMutation = () => {
  const {refetch} = useTrainingSchedulesQuery();
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, CreateTrainingScheduleParam>(
    (variables) => createTrainingSchedule(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => refetch(),
    }
  );

  return mutation;
};

export const useUpdateTrainingScheduleMutation = () => {
  const {refetch} = useTrainingSchedulesQuery();
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, UpdateTrainingScheduleParam>(
    (variables) => updateTrainingSchedule(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => refetch(),
    }
  );

  return mutation;
};

const NavLink = styled(Link)(({theme}) => ({
  color: theme.palette.secondary.dark,
  textDecoration: 'none',
  fontWeight: 'bold',
}));
