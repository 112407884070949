import React, {useCallback} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {useBackPrevious, withSuspense} from '@front-libs/core';
import {TemplateList} from './TemplateList';
import {Header} from './Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 'calc(100vh - 56px)',
    },
    headerContainer: {
      width: '100%',
      margin: '36px 36px 0px',
      height: '48px',
    },
    main: {
      padding: '42px 40px 0px 40px',
      height: 'calc(100vh - 56px - 48px - 36px)',
    },
  })
);

const _InspectionTemplates: React.FC = () => {
  const classes = useStyles();
  const goBackToSettingPage = useBackPrevious('/inspection/setting');

  const handleClickPrevButton = useCallback(() => {
    goBackToSettingPage();
  }, [goBackToSettingPage]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.headerContainer}>
        <Header onClickPrevButton={handleClickPrevButton} />
      </Grid>
      <Grid item container xs={12} className={classes.main}>
        <TemplateList />
      </Grid>
    </Grid>
  );
};

export const InspectionTemplates = withSuspense(_InspectionTemplates, null);
