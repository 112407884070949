import React, {useCallback, useMemo} from 'react';
import {Box, Button, Grid} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import {useAtom} from 'jotai';
import {splitAtom} from 'jotai/utils';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import EditableColumnModal from '../EditableColumnModal';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {productsAtom, InitialValue} from '../../state';
import {TableFormContents} from './TableFormContents';
import {v4 as uuidv4} from 'uuid';
import {ProductIndex} from '@modules/products/types';
import {Product} from '../../state';
import {
  NewRegistrationDialogProps,
  NewRegistrationDialogResult,
  NewRegistrationGS1Dialog,
} from '@Apps/ProductsList/pc/Dialogs/NewRegistrationGS1Dialog';
import {ApplicationIndicator, extractCodeFromGS1Barcode} from '@front-libs/helpers';
import {openSnackBar} from '@molecules/SnackBar';
import {RequiredLabelBadge} from '@atoms/RequiredLabelBadge';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useHospitalDealerOption} from '@modules/hospital_dealer/api';
import {useHospitalProductMgmtSectionOption} from '@modules/hospital_product_mgmt_section/api';
/**
 * テーブルのヘッダー行に表示する名称とID
 * 追加する際、TableFormContents側の並び順と合わせる必要がある
 */
const rows: Array<{id: string; label: string; required?: boolean}> = [
  // 1.管理番号
  {
    label: '管理番号',
    id: 'managementId',
    required: true,
  },
  // 2.シリアル番号
  {
    label: 'シリアル番号',
    id: 'serialNumber',
  },
  // 3.ロット番号
  {
    label: 'ロット番号',
    id: 'lotNumber',
  },
  // 4.貸出区分
  {
    label: '貸出区分',
    id: 'permanentlyAssigned',
  },
  // 5.管理部署
  {
    label: '管理部署',
    id: 'hospitalProductMgmtSectionHashId',
  },
  // 6.稼働状況
  {
    label: '稼働状況',
    id: 'status',
  },
  // 7.機器管理場所
  {
    label: '機器管理場所',
    id: 'hospitalRoomHashId',
  },
  // 8.購入日
  {
    label: '購入日',
    id: 'dateOfPurchase',
  },
  // 9.親機・子機
  {
    label: '親機・子機',
    id: 'isBaseUnit',
  },
  // 10.購入区分
  {
    label: '購入区分',
    id: 'waysOfPurchase',
  },
  // 11.院内耐用年数（年）
  {
    label: '院内耐用年数（年）',
    id: 'legalDurableYear',
  },
  // 12.保守契約
  {
    label: '保守契約',
    id: 'isMaintenanceContract',
  },
  // 13.担当代理店
  {
    label: '担当代理店',
    id: 'hospitalDealerHashId',
  },
  // 14.資産番号
  {
    label: '資産番号',
    id: 'assetRegisterNumber',
  },
  // {
  //   label: '減価償却費（円）',
  //   id: 'depreciationAmount',
  // },
  // {
  //   label: '帳簿価格（円）',
  //   id: 'bookValue',
  // },
  // 15.廃棄日
  {
    label: '廃棄日',
    id: 'dateOfDisposal',
  },
  // 16.廃棄理由
  {
    label: '廃棄理由',
    id: 'reasonOfDisposal',
  },
  // 17.院内点検開始日
  {
    label: '院内点検開始日',
    id: 'periodicInspectionStartDate',
  },
  // 18.備考1
  {
    label: '備考1',
    id: 'notes',
  },
  // 19.備考2
  {
    label: '備考2',
    id: 'notes2',
  },
  // 20.備考3
  {
    label: '備考3',
    id: 'notes3',
  },
  // 21.バーコード読み取り値
  {
    label: 'バーコード読み取り値',
    id: 'optionalBarcode',
  },
  // 22.GS1-128
  {
    label: 'GS1-128',
    id: 'rawBarcode',
  },
];

const productAtomsAtom = splitAtom(productsAtom);

type TableFormProps = {
  hashId: string;
  wholeProduct: ProductIndex | undefined;
  fromGs1?: boolean;
};

/**
 * 病院の機器情報を表示および編集するためのテーブル
 * テーブルの項目には各種Formが配置される
 *
 * @param {string} props.hashId - ハッシュID
 * @param { ProductIndex | undefined } props.wholeProduct - プロダクト情報
 * @param {boolean} props.fromGs1  - GS1からの情報かどうか (オプション)
 * @returns {React.ReactElement} テーブルフォーム全体のReact要素
 */
export const TableForm = ({hashId, wholeProduct, fromGs1 = false}: TableFormProps) => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [products, setProducts] = useAtom(productsAtom);
  const [productAtoms] = useAtom(productAtomsAtom);
  const {data: hospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId);
  const hospitalDealerOptionsList = useHospitalDealerOption(myInfo.hospitalHashId);
  const hospitalProductMgmtSectionOptions = useHospitalProductMgmtSectionOption(myInfo.hospitalHashId);
  const handleSelectAllClick = (allData: Product[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = allData.map((n: Product) => n.uuid || '');
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, key: string) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = useMemo(
    () => (key: string) => {
      return selected.indexOf(key) !== -1;
    },
    [selected]
  );

  /**
   * 一括編集
   * @param e
   * @param targets 選択された行のuuid
   */
  const handleEdit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, targets: string[]) => {
      const value = await dialogHandler.open(EditableColumnModal, {
        hospitalRooms: hospitalRooms,
        hospitalDealerOptionsList: hospitalDealerOptionsList,
        hospitalProductMgmtSectionOptions: hospitalProductMgmtSectionOptions,
        selected: targets,
      });

      const updateProducts = products.map((product) => {
        if (targets.includes(product?.uuid + '')) {
          return {
            ...product,
            ...value,
          };
        }
        return product;
      });

      setProducts(updateProducts);
      setSelected([]);
    },
    [hospitalDealerOptionsList, hospitalProductMgmtSectionOptions, hospitalRooms, products, setProducts]
  );
  /**
   * 一括削除
   * @param e
   * @param targets 選択された行のuuid
   */
  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, targets: string[]) => {
    try {
      await dialogHandler.open(AlertDialog, {
        title: 'リストから削除',
        content: `このリストから${selected.length}件の情報が削除されますが、よろしいですか？`,
        positiveButtonLabel: '削除',
      });
      // 削除対象のProduct以外を抽出し、setterで書き換える。
      const restProducts = products.filter((product) => targets.indexOf(product?.uuid + '') == -1);
      setProducts(restProducts);
    } finally {
      setSelected([]);
    }
  };

  /**
   * チェックが入っている機器の複製
   * @param e
   * @param targets
   */
  const handleReplication = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, targets: string[]) => {
    const updateProducts = products
      .filter((v) => v.uuid && targets.includes(v.uuid))
      .map((product) => {
        return {
          ...product,
          uuid: uuidv4(),
        };
      });

    setProducts([...products, ...updateProducts]);
    setSelected([]);
  };
  /** 行の追加 */
  const handleAdd = () => {
    setProducts((oldValue) => [...oldValue, {...InitialValue, uuid: uuidv4(), wholeProductHashId: hashId}]);
  };

  /** GS1で機器追加 */
  const handleAddByGs1 = async () => {
    const {gs1Barcode} = await dialogHandler.open<NewRegistrationDialogProps, NewRegistrationDialogResult>(
      NewRegistrationGS1Dialog,
      {}
    );

    // Memo: 現状は、異なる機種を同時登録できないので、一旦エラーで返す
    const gtinIndicator = extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.gtinIndicator);
    if (wholeProduct?.newJanCode !== gtinIndicator && wholeProduct?.janCode !== gtinIndicator) {
      openSnackBar('異なる機種を同時に登録することはできません。', 'left', 'bottom', 'error');
      return;
    }

    // シリアル番号とロット番号が同一の場合、重複での登録になるのでエラー
    const serialNumber = extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.serialNumberIndicator);
    const lotNumber = extractCodeFromGS1Barcode(gs1Barcode ?? '', ApplicationIndicator.lotNumberIndicator);
    if (products.some((item) => item.serialNumber === serialNumber && item.lotNumber === lotNumber)) {
      openSnackBar('読み込まれた機種は既に登録情報が入力されています。', 'left', 'bottom', 'error');
      return;
    }

    setProducts((oldValue) => [
      ...oldValue,
      {...InitialValue, uuid: uuidv4(), wholeProductHashId: hashId, serialNumber, lotNumber, rawBarcode: gs1Barcode},
    ]);
  };

  const IconButtons = [
    {label: '編集', icon: <CreateIcon />, action: handleEdit},
    {
      label: '削除',
      icon: <DeleteIcon />,
      action: handleDelete,
    },
    {label: '複製', icon: <CreateIcon />, action: handleReplication},
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              onSelectAllClick={handleSelectAllClick(products)}
              rowCount={productAtoms.length}
              selected={selected}
              iconButtons={IconButtons}
            />
            <TableBody>
              {productAtoms.map((productAtom, index) => {
                return (
                  <TableFormContents
                    key={`form${index}`}
                    productAtom={productAtom}
                    index={index}
                    handleClick={handleClick}
                    isSelected={isSelected}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box>
        <Button onClick={handleAdd} color={'primary'}>
          +さらに行を追加
        </Button>
        {fromGs1 && (
          <Button onClick={handleAddByGs1} color={'primary'}>
            +さらに同機種のGS1-128を読み込み
          </Button>
        )}
      </Box>
    </div>
  );
};

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  selected: string[];
  iconButtons: {
    label: string;
    icon: JSX.Element;
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selected: string[]) => void;
  }[];
}

/**
 * テーブルヘッド
 * @param props EnhancedTableHeadProps
 * @returns
 */
const EnhancedTableHead: React.VFC<EnhancedTableHeadProps> = (props) => {
  const {onSelectAllClick, rowCount, selected, iconButtons} = props;
  const numSelected = selected.length;
  const classes = useStyles();

  return numSelected > 0 ? (
    <TableHead className={classes.tableHeadBorderBottom}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography>{numSelected}件が選択されました</Typography>
        </TableCell>
        <TableCell colSpan={2} className={classes.tableCell}>
          <Grid container direction={'row'} justifyContent={'space-around'}>
            {iconButtons.map((iconButton) => {
              return (
                <Button
                  key={iconButton.label}
                  color={'primary'}
                  startIcon={iconButton.icon}
                  onClick={(e) => {
                    iconButton.action(e, selected);
                  }}>
                  {iconButton.label}
                </Button>
              );
            })}
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  ) : (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        {rows.map((headCell) => (
          <TableCell key={headCell.id} padding={'none'} className={classes.headCell}>
            {headCell.label}
            {headCell.required === true && (
              <div className={classes.requiredBadgeContainer}>
                <RequiredLabelBadge />
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableHeadBorderBottom: {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    tableCell: {
      minWidth: 200,
      padding: 'initial',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headCell: {
      paddingRight: 8,
      paddingLeft: 8,
    },
    requiredBadgeContainer: {
      display: 'inline-flex',
      marginLeft: '12px',
      '& > span': {
        lineHeight: '14.4px',
        marginBottom: '0.6px',
      },
    },
  })
);
