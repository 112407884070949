import React, {useCallback, useState} from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {InspectionType} from '@modules/inspections/enum';
import {CategoryFormatter} from '@modules/categories/helpers';
import {useGetHospitalCategories, useGetHospitalDescendantCategories} from '@modules/categories/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useAtom} from 'jotai';
import {
  inspectionTypeAtom,
  narrowCategoryAtom,
  rootCategoryAtom,
  searchNameAtom,
  selectedTemplateAtom,
} from '../states';
import {useDebounceCallback} from '@front-libs/core';
import {useResetAtom} from 'jotai/utils';

const inspectionOptions: {label: string; value: InspectionType}[] = [
  {
    label: '使用前点検',
    value: 'pre_use',
  },
  // {
  //   label: '使用中点検',
  //   value: 'in_use',
  // },
  {
    label: '使用後点検',
    value: 'post_use',
  },
  {
    label: '定期点検',
    value: 'periodic',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      width: '360px',
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
      height: 'fit-content',
    },
    popperSelectBoxBtn: {
      marginLeft: '24px',
    },
  })
);

export const Filters: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [, setInsepctionType] = useAtom(inspectionTypeAtom);
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const resetSelectedTemplate = useResetAtom(selectedTemplateAtom);

  const {data: allRootCategory} = useGetHospitalCategories(myInfo.hospitalHashId, {depth: 0});
  const {data: allNarrowCategory} = useGetHospitalCategories(myInfo.hospitalHashId, {depth: 1});
  const [broadCategoryHashId, setBroadCategoryHashId] = useState<null | string>(null);
  const {data: descendantCategories} = useGetHospitalDescendantCategories(
    myInfo.hospitalHashId,
    broadCategoryHashId ?? undefined
  );
  const [, setRootCategory] = useAtom(rootCategoryAtom);
  const [, setNarrowCategory] = useAtom(narrowCategoryAtom);
  const [resetNarrowCategoryValue, setResetNarrowCategoryValue] = useState<boolean>(false);

  const handleChangeRootCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      resetSelectedTemplate();
      setRootCategory(row?.value ?? null);
      setBroadCategoryHashId(row?.value ?? null);
      setNarrowCategory(null);
      setResetNarrowCategoryValue(true);
    },
    [resetSelectedTemplate, setNarrowCategory, setRootCategory]
  );

  const handleChangeNarrowCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      resetSelectedTemplate();
      setNarrowCategory(row?.value ?? null);
      setResetNarrowCategoryValue(false);
    },
    [resetSelectedTemplate, setNarrowCategory]
  );

  const handleChangeSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      resetSelectedTemplate();
      setSearchName(e.target.value);
    },
    500,
    []
  );

  const handleTypeChange = useCallback(
    (type) => {
      resetSelectedTemplate();
      if (type !== null) {
        setInsepctionType(type.value);
      } else {
        setInsepctionType(null);
      }
    },
    [resetSelectedTemplate, setInsepctionType]
  );

  return (
    <Grid container>
      <Grid item>
        <TextField
          label="点検表の名前を検索"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={searchName}
          className={classes.searchBox}
          onChange={handleChangeSearchName}
        />
      </Grid>
      <Grid item className={classes.popperSelectBoxBtn}>
        <PopperSelectBoxButton
          buttonLabel="大分類"
          options={CategoryFormatter.getOptions(allRootCategory)}
          isMulti={false}
          onChange={handleChangeRootCategory}
          searchable={true}
        />
      </Grid>
      <Grid item className={classes.popperSelectBoxBtn}>
        <PopperSelectBoxButton
          buttonLabel="小分類"
          options={CategoryFormatter.getOptions(descendantCategories ?? allNarrowCategory)}
          isMulti={false}
          onChange={handleChangeNarrowCategory}
          searchable={true}
          resetValue={resetNarrowCategoryValue}
        />
      </Grid>
      <Grid item className={classes.popperSelectBoxBtn}>
        <PopperSelectBoxButton
          buttonLabel="点検タイプ"
          options={inspectionOptions}
          isMulti={false}
          searchable={false}
          onChange={handleTypeChange}
        />
      </Grid>
    </Grid>
  );
};
