import {UserIndex} from '@modules/hospital_users/types';
import {getTableLayout, TableLayoutResponse, updateTableLayout} from '@modules/table_layout/api';
import {isTableLayoutKey} from '@modules/table_layout/types';
import {getTableLayoutForDB, upsertTableLayoutSetting} from './repository';

/**
 * 引数のUserがIndexedDBに登録されていなければ登録を行う
 * @param userIndex
 * @returns
 */
export const setUserTableLayoutSetting = async (userIndex: UserIndex) => {
  const tableLayout = await getTableLayoutForDB(userIndex.hashId);
  // 取得済みなら抜ける
  if (tableLayout) return;

  // 未取得ならAPIから取得
  await initTableLayoutSetting(userIndex);
};

/**
 * テーブルレイアウト初回全件取得
 * @param userIndex
 */
export const initTableLayoutSetting = async (userIndex: UserIndex) => {
  const {data} = await getTableLayout(userIndex.hospitalHashId, userIndex.hashId);
  await upsertTableLayoutSetting([
    {
      hospitalHashId: userIndex.hospitalHashId,
      hospitalUserHashId: userIndex.hashId,
      tableLayoutResponse: data,
    },
  ]);
};

/**
 * 引数のTableLayoutResponse配列の中身を変更する
 * @param data
 * @param layoutName
 * @param newLayout
 */
const updateLayout = async (data: TableLayoutResponse[], layoutName: string, newLayout: string[]) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].layoutName === layoutName) {
      data[i].currentLayout.length = 0;
      data[i].currentLayout = newLayout;
      break;
    }
  }
};

export const updateDifferentialTableLayoutSetting = async (userIndex: UserIndex) => {
  try {
    const tableLayoutForDB = await getTableLayoutForDB(userIndex.hashId);
    if (!tableLayoutForDB) return;

    const {data} = await getTableLayout(userIndex.hospitalHashId, userIndex.hashId);
    tableLayoutForDB.tableLayoutResponse.forEach(async (v) => {
      const layoutData = data.find((res) => res.layoutName === v.layoutName);
      // APIにて差分を送信
      if (JSON.stringify(v.currentLayout) !== JSON.stringify(layoutData?.currentLayout)) {
        if (isTableLayoutKey(v.layoutName)) {
          await updateTableLayout(userIndex.hospitalHashId, userIndex.hashId, v.layoutName, v.currentLayout);
        }
      }
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * レイアウトのアップデート
 * @param hospitalHashId
 * @param hospitalUserHashId
 * @param layoutName
 * @param newLayout
 */
export const updateTableLayoutSetting = async (
  hospitalHashId: string,
  hospitalUserHashId: string,
  layoutName?: string,
  newLayout?: string[]
) => {
  const {data} = await getTableLayout(hospitalHashId, hospitalUserHashId);
  if (newLayout && layoutName) {
    updateLayout(data, layoutName, newLayout);
  }

  await upsertTableLayoutSetting([
    {
      hospitalHashId: hospitalHashId,
      hospitalUserHashId: hospitalUserHashId,
      tableLayoutResponse: data,
    },
  ]);
};
