export const ViewInspectionTypes = ['daily', 'pre_use', 'in_use', 'post_use', 'periodic', 'maker_periodic'] as const;

export const ViewInspectionTypeToText: Record<typeof ViewInspectionTypes[number], string> = {
  daily: '日常点検',
  pre_use: '使用前点検',
  in_use: '使用中点検',
  post_use: '使用後点検',
  periodic: '定期点検',
  maker_periodic: 'メーカー保守点検',
};
