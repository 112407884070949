import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import {Button} from '@material-ui/core';
import {styled} from '@material-ui/styles';

const StyledAddIcon = styled(AddIcon)({
  fontSize: '24px',
});
const StyledButton = styled(Button)({
  fontSize: '16px',
});

type FileUploadButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const FileUploadButton = ({onClick}: FileUploadButtonProps) => {
  return (
    <StyledButton variant="text" color="primary" startIcon={<StyledAddIcon />} onClick={onClick}>
      ファイルをアップロード
    </StyledButton>
  );
};
