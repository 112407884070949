import React, {createContext, useContext} from 'react';
import {FormValue} from '../common/types';

type PreviewFileDispatcher = (hashId: string) => Promise<void>;

const PreviewFileDispatcherContext = createContext<PreviewFileDispatcher>(async () => {
  // empty function
});

type PreviewFileDispatcherProviderProps = {
  handler: DeleteFileDispatcher;
};

export const PreviewFileDispatcherProvider: React.FC<PreviewFileDispatcherProviderProps> = ({handler, children}) => {
  return <PreviewFileDispatcherContext.Provider value={handler}>{children}</PreviewFileDispatcherContext.Provider>;
};

export const usePreviewFileDispatcher = () => {
  return useContext(PreviewFileDispatcherContext);
};

type UpdateFileDispatcher = (hashId: string) => Promise<void>;

const UpdateFileDispatcherContext = createContext<UpdateFileDispatcher>(async () => {
  // empty function
});

type UpdateFileDispatcherProviderProps = {
  handler: UpdateFileDispatcher;
};

export const UpdateFileDispatcherProvider: React.FC<UpdateFileDispatcherProviderProps> = ({handler, children}) => {
  return <UpdateFileDispatcherContext.Provider value={handler}>{children}</UpdateFileDispatcherContext.Provider>;
};

export const useUpdateFileDispatcher = () => {
  return useContext(UpdateFileDispatcherContext);
};

type DeleteFileDispatcher = (hashId: string) => Promise<void>;

const DeleteFileDispatcherContext = createContext<DeleteFileDispatcher>(async () => {
  // empty function
});

type DeleteFileDispatcherProviderProps = {
  handler: DeleteFileDispatcher;
};

export const DeleteFileDispatcherProvider: React.FC<DeleteFileDispatcherProviderProps> = ({handler, children}) => {
  return <DeleteFileDispatcherContext.Provider value={handler}>{children}</DeleteFileDispatcherContext.Provider>;
};

export const useDeleteFileDispatcher = () => {
  return useContext(DeleteFileDispatcherContext);
};

type SubmitFormDispatcher = (e: React.MouseEvent, values: FormValue) => Promise<void>;

const SubmitFormDispatcherContext = createContext<SubmitFormDispatcher>(async () => {
  // empty function
});

type SubmitDispatcherProviderProps = {
  handler: SubmitFormDispatcher;
};

export const SubmitFormDispatcherProvider: React.FC<SubmitDispatcherProviderProps> = ({handler, children}) => {
  return <SubmitFormDispatcherContext.Provider value={handler}>{children}</SubmitFormDispatcherContext.Provider>;
};

export const useSubmitDispatcher = () => {
  return useContext(SubmitFormDispatcherContext);
};
