import React from 'react';
import {RequiredLabelBadge} from '@atoms/RequiredLabelBadge';

export const RequiredLabel: React.FC<
  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
> = ({children, ...props}) => {
  return (
    <div style={{marginBottom: 2}}>
      <label {...props}>
        {children}
        <RequiredLabelBadge style={{marginLeft: 8}} />
      </label>
    </div>
  );
};
