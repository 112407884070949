import React, {useCallback, useMemo} from 'react';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';

export type ContentTitleProps = {
  selectedViewType: string;
  onChangeContent: (value: string) => void;
  menuItemList: MenuItemType[];
};

export const ContentTitle: React.FC<ContentTitleProps> = ({menuItemList, selectedViewType, onChangeContent}) => {
  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      onChangeContent(item.value);
    },
    [onChangeContent]
  );
  const currentTitle = useMemo(
    () => menuItemList.find((item) => item.value === selectedViewType)?.label ?? '',
    [selectedViewType, menuItemList]
  );
  return (
    <PopperMenuButton
      menuItemList={menuItemList}
      onMenuClick={handleMenuClick}
      buttonProps={{color: 'primary', variant: 'text', style: {fontWeight: 700}}}>
      {currentTitle}
    </PopperMenuButton>
  );
};
