import React, {useCallback, Suspense} from 'react';
import {useResetAtom} from 'jotai/utils';
import {useSetAtom} from 'jotai';
import {useOnlyOnce} from '@front-libs/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {InnerLoading} from '@molecules/Loading';
import {DialogInner} from './DialogInner';
import {searchWordAtom, rootCategoryAtom, narrowCategoryAtom, selectedProductsAtom, ProductElement} from './states';
import {WholeProductIndex} from '@modules/products/types';

export type EditProductsDialogProps = {
  hospitalHashId: string;
  defaultValues?: WholeProductIndex[];
} & DialogProps;

export const EditProductsDialog: React.FC<EditProductsDialogProps> = (props) => {
  const {hospitalHashId, defaultValues = []} = props;

  const handleSubmit = useCallback(
    async (products: WholeProductIndex[]) => {
      await props.actions.resolve(products);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  const resetSearchWord = useResetAtom(searchWordAtom);
  const resetRootCategoryAtom = useResetAtom(rootCategoryAtom);
  const resetNarrowCategoryAtom = useResetAtom(narrowCategoryAtom);
  const setSelectedProductsAtom = useSetAtom(selectedProductsAtom);

  useOnlyOnce(() => {
    resetSearchWord();
    resetRootCategoryAtom();
    resetNarrowCategoryAtom();
    setSelectedProductsAtom(
      defaultValues?.map<ProductElement>((item) => ({...item, productName: item.name || item.displayName}))
    );
  });

  return (
    <Suspense fallback={<InnerLoading />}>
      <DialogInner hospitalHashId={hospitalHashId} onSubmit={handleSubmit} onClose={handleClose} />
    </Suspense>
  );
};
