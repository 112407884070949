import React from 'react';
import {LinearProgress, Typography} from '@material-ui/core';
import {withSuspense} from '@front-libs/core';
import {useResetByQueryParams, useUpdateQueryParamsByState} from './InternalInspection/states/query';
import {useInitialized, useInspectionType} from './InternalInspection/states/states';
import {InspectionResultListBody} from './InternalInspection';
import {Header} from './Header';
import {MakerInspectionListBody} from './MakerInspection';
import {onlineManager} from 'react-query';
import {ListPageLayout} from '@components/layouts/ListPageLayout';

// 現状だとTableLayoutのキーを後から変更できないので、キーが変わったら再描画する形式にしています
const _InspectionResultsList: React.FC = () => {
  // const tableLayoutKey = useTableLayoutKey();
  useResetByQueryParams();
  useUpdateQueryParamsByState();
  const [type] = useInspectionType();

  const [initialized] = useInitialized();
  if (!initialized) {
    return <LinearProgress />;
  }

  const isOnline = onlineManager.isOnline();

  return (
    <ListPageLayout page="inspection_results">
      <ListPageLayout.Header>
        <Header />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        {!isOnline && (
          <Typography variant="body1" style={{padding: '4px 24px 0px', fontSize: '8pt', color: 'red'}}>
            オフラインでは一部機能が利用できません。
          </Typography>
        )}
        {type !== 'maker_periodic' ? <InspectionResultListBody /> : <MakerInspectionListBody />}
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const InspectionResultsList = withSuspense(_InspectionResultsList, null);
