import {Grid, IconButton, styled} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import React from 'react';

export type NextPreviousButtonProps = {
  isDisabledNext: boolean;
  isDisabledPrevious: boolean;
  onClickButton: (buttonType: 'next' | 'previous') => void;
};

export const NextPreviousButton: React.FC<NextPreviousButtonProps> = ({
  isDisabledNext,
  isDisabledPrevious,
  onClickButton,
}) => {
  return (
    <Grid container>
      <Grid item style={{marginRight: 24}}>
        <BaseIconButton size="small" onClick={() => onClickButton('previous')} disabled={isDisabledPrevious}>
          <ChevronLeft />
        </BaseIconButton>
      </Grid>
      <Grid item>
        <BaseIconButton size="small" onClick={() => onClickButton('next')} disabled={isDisabledNext}>
          <ChevronRight />
        </BaseIconButton>
      </Grid>
    </Grid>
  );
};

const BaseIconButton = styled(IconButton)({
  background: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
});
