import {axios} from '@front-libs/core';
import {FileIndex, HospitalProductFileIndex} from '@modules/files/types';
import {CreateHospitalProductFilesParam, FetchHospitalProductFilesParams} from '@modules/hospital_products/api';

export const getHospitalProductFiles = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductFilesParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/files`;
  const response = await axios.get<FileIndex[] | HospitalProductFileIndex[]>(baseUrl, {params});
  return response.data;
};

export const createHospitalProductFiles = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  data: CreateHospitalProductFilesParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/files`;
  return await axios.post<void>(baseURL, data);
};
