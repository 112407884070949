import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {Button, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import dayjs from 'dayjs';
import {HistoryCardProps} from './Cards/HistoryCard';
import {RegistrationCard} from './Cards/RegistrationCard';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {CommentDialog} from '@molecules/Dialogs/CommentDialog';
import {RepairIndex} from '@modules/repairs/types';
import {createFaultRepairLog, deleteFaultRepairLog, updateFaultRepairLog} from '@modules/repairs/api';
import {useFetchFaultRepairLogsQuery} from '@modules/repairs/hooks';
import {repairLogTypeEnum} from '@modules/repairs/constants';
import {StatusChangeCard} from './Cards/StatusChangeCard';
import {CommentCard} from '@components/molecules/Cards/CommentCard';
import {openSnackBar} from '@molecules/SnackBar';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type Props = {
  faultRepair: RepairIndex;
};

/**
 * 機器管理の履歴表示
 *
 * @todo React.VFCはReact18から非推奨になるのでJSXElementかFCに置き換え
 * @param  {RepairIndex} faultRepair
 * @returns {React.VFC<Props>}
 */
export const ProductTransactionHistory: React.VFC<Props> = ({faultRepair}) => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {isReadOnly, isAdmin} = useMyRole();
  const [openCommentDialog, setOpenCommentDialog] = useState(false);

  const faultLogQuery = useFetchFaultRepairLogsQuery(myInfo.hospitalHashId, faultRepair.hashId, {perPage: 100});

  const isLoading = useMemo(() => faultLogQuery.isLoading, [faultLogQuery]);
  const hasError = useMemo(() => faultLogQuery.isError, [faultLogQuery]);

  const [statusChangeLogs, commentLogs] = useMemo(() => {
    if (isLoading && hasError) return [[], []];
    return [
      faultLogQuery.data.filter((item) => item.logType === repairLogTypeEnum.statusChange),
      faultLogQuery.data.filter((item) => item.logType === repairLogTypeEnum.comment),
    ];
  }, [faultLogQuery.data, hasError, isLoading]);

  const productHistory: HistoryCardProps[] = useMemo(() => {
    if (isLoading && hasError) return [];
    let historyCards: HistoryCardProps[] = [];

    historyCards = historyCards.concat(
      statusChangeLogs.map<HistoryCardProps>((item) => ({
        cardType: repairLogTypeEnum.statusChange,
        logHashId: item.hashId,
        user: item.createdBy,
        displayDate: item.createdAt,
        contentSlot: [
          {value: item.statusFromHashId ?? '', label: item.statusFromName ?? ''},
          {value: item.statusToHashId ?? '', label: item.statusToName ?? ''},
        ],
      }))
    );

    historyCards = historyCards.concat(
      commentLogs.map<HistoryCardProps>((item) => ({
        cardType: repairLogTypeEnum.comment,
        logHashId: item.hashId,
        pinned: item.pinned,
        user: item.createdBy,
        displayDate: item.createdAt,
        description: item.description,
      }))
    );

    historyCards.push({
      cardType: 'registration',
      logHashId: '',
      user: faultRepair.createdBy,
      displayDate: new Date(faultRepair.createdAt),
    });

    return historyCards.sort((a, b) => {
      if (a.displayDate < b.displayDate) return 1;
      if (a.displayDate > b.displayDate) return -1;
      return 0;
    });
  }, [isLoading, hasError, statusChangeLogs, commentLogs, faultRepair.createdBy, faultRepair.createdAt]);

  const monthlyProductHistory: {section: string; productHistories: HistoryCardProps[]}[] = useMemo(() => {
    const tmpHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    const pinnedHistory: {section: string; productHistories: HistoryCardProps[]}[] = [];
    productHistory
      .filter((item) => item.pinned === true)
      .forEach((item, index) => {
        if (index === 0) {
          pinnedHistory.push({
            section: 'ピン留めしたコメント',
            productHistories: [item],
          });
        } else {
          pinnedHistory[pinnedHistory.length - 1].productHistories.push(item);
        }
      });

    productHistory
      .filter((item) => !(item.pinned === true))
      .forEach((item, index) => {
        if (index === 0 || tmpHistory[tmpHistory.length - 1].section !== dayjs(item.displayDate).format('MM月 YYYY')) {
          tmpHistory.push({
            section: dayjs(item.displayDate).format('MM月 YYYY'),
            productHistories: [item],
          });
        } else {
          tmpHistory[tmpHistory.length - 1].productHistories.push(item);
        }
      });
    return [...pinnedHistory, ...tmpHistory];
  }, [productHistory]);

  const handleClickAddComment = useCallback(async () => {
    setOpenCommentDialog(true);
    try {
      const comment = await dialogHandler.open(CommentDialog, {});
      await createFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, {
        description: comment,
        logType: 'comment',
      });
      faultLogQuery.refetch();
      openSnackBar('コメントを追加しました');
    } finally {
      setOpenCommentDialog(false);
    }
  }, [faultLogQuery, faultRepair.hashId, myInfo.hospitalHashId]);

  const handleUpdatePin = useCallback(
    async (logHashId: string, currentPinned: boolean) => {
      try {
        await updateFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, logHashId, {
          pinned: !currentPinned,
        });
        await faultLogQuery.refetch();
        if (currentPinned) {
          openSnackBar('コメントのピン留めを外しました');
        } else {
          openSnackBar('コメントをピン留めしました');
        }
      } catch (error) {
        openSnackBar('コメントのピン留めに失敗しました', 'left', 'bottom', 'error');
      }
    },
    [faultLogQuery, faultRepair.hashId, myInfo.hospitalHashId]
  );

  const handleUpdateComment = useCallback(
    async (logHashId: string, comment: string) => {
      try {
        await updateFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, logHashId, {
          description: comment,
        });
        openSnackBar('コメントを更新しました');
      } catch (error) {
        openSnackBar('コメントの更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [faultRepair.hashId, myInfo.hospitalHashId]
  );

  const handleDeleteComment = useCallback(
    async (logHashId: string) => {
      try {
        const ok = await dialogHandler
          .open(AlertDialog, {
            title: 'コメントを削除しますか？',
            content: 'コメントを削除しようとしています。\n\nこのアクションは元に戻せません。',
            positiveButtonLabel: 'コメントを削除',
          })
          .then(() => true)
          .catch(() => false);

        if (!ok) {
          return;
        }

        await deleteFaultRepairLog(myInfo.hospitalHashId, faultRepair.hashId, logHashId);
        await faultLogQuery.refetch();
        openSnackBar('コメントを削除しました');
      } catch (error) {
        openSnackBar('コメントの削除に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [faultLogQuery, faultRepair.hashId, myInfo.hospitalHashId]
  );

  useEffect(() => {
    return () => {
      if (openCommentDialog) dialogHandler.close(CommentDialog, {});
    };
  }, [openCommentDialog]);

  return (
    <Grid container>
      <Grid container style={{justifyContent: 'flex-end'}}>
        <Grid item className={classes.actionContainer}>
          {!isReadOnly && (
            <Button color="inherit" className={classes.addCommentBtn} onClick={handleClickAddComment}>
              コメントを追加
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {monthlyProductHistory.map((outer, outerIdx) => (
          <React.Fragment key={outer.section + String(outerIdx)}>
            <Typography style={{paddingTop: '40px', paddingBottom: '8px'}}>{outer.section}</Typography>
            {outer.productHistories.map((innerItem, innerIdx) => (
              <Grid item className={classes.historyContainer} key={innerItem.logHashId + String(innerIdx)}>
                {innerItem.cardType === 'status_change' && <StatusChangeCard {...innerItem} />}
                {innerItem.cardType === 'comment' && (
                  <CommentCard
                    {...innerItem}
                    isReadOnly={isReadOnly}
                    isUserAdmin={isAdmin}
                    pinned={innerItem.pinned}
                    onDeleteComment={() => handleDeleteComment(innerItem.logHashId)}
                    onUpdateComment={(comment) => handleUpdateComment(innerItem.logHashId, comment)}
                    onUpdatePinned={() => handleUpdatePin(innerItem.logHashId, innerItem.pinned === true)}
                  />
                )}
                {innerItem.cardType === 'registration' && <RegistrationCard {...innerItem} />}
              </Grid>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    paddingTop: '24px',
    fontSize: theme.typography.fontSize,
  },
  action: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  historyContainer: {
    paddingTop: '16px',
    fontSize: theme.typography.fontSize,
    width: '100%',
    paddingRight: '24px',
  },
  addCommentBtn: {
    backgroundColor: '#323F4E',
    borderRadius: '2px',
    padding: '8px 22px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
}));
