import {atomWithReset} from 'jotai/utils';
import {atom} from 'jotai';
import {WholeProductIndex} from '@modules/products/types';

export type ProductElement = WholeProductIndex & {
  name?: string;
  displayName?: string;
};

export const searchWordAtom = atomWithReset<string>('');
export const rootCategoryAtom = atomWithReset<string | null>(null);
export const narrowCategoryAtom = atomWithReset<string | null>(null);

export const selectedProductsAtom = atom<ProductElement[]>([]);

export const isProductSelectedAtom = atom((get) => {
  const selectedProducts = get(selectedProductsAtom);

  const isSelected: Record<string, boolean> = {};
  selectedProducts.forEach((p) => {
    isSelected[p.hashId] = true;
  });

  return isSelected;
});
