import {HospitalProductIndex} from '@modules/hospital_products/types';
import {UnacceptedStatus} from '../types';

export const useValidateToRent = () => {
  /**
   * 貸出可能かどうかを判定します。
   * 貸出不可の場合は理由を返します。
   *
   * @remarks
   * この関数は、複数の機器を貸し出しする場合に、一つでも貸出不可の場合は貸出不可と判定します。
   * 仕様については再考の余地があります。
   *
   * @param products 貸出対象の機器一覧
   * @returns {UnacceptedStatus | null} 貸出不可の場合は理由を返します。
   */
  const validateToRent = (products: HospitalProductIndex[]): UnacceptedStatus | null => {
    if (products.some((p) => (p.numberPeriodInspectionResultsInPeriod ?? 0) > 0)) {
      return 'overdue_inspection';
    }
    if (products.some((p) => p.status === 'working')) {
      return 'working';
    }
    if (products.some((p) => p.status === 'repairing')) {
      return 'repairing';
    }
    if (products.some((p) => p.status === 'uninspected')) {
      return 'uninspected';
    }
    return null;
  };
  return {validateToRent};
};
