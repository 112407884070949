import React, {Suspense, useState, useCallback} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography} from '@material-ui/core';
import {Form, Formik, useFormikContext} from 'formik';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import {InspectionRecord} from './InspectionComponent';
import {convertDateToSimpleDateTime} from '@front-libs/helpers';
import dayjs from 'dayjs';

type FinishInspectionFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

export type FinishInspectionFormValue = {
  completedAt: string;
};

const FinishInspectionForm = (props: FinishInspectionFormProps) => {
  const {open, onClose} = props;
  const classes = useStyles();
  const {submitForm, isValid} = useFormikContext<FinishInspectionFormValue>();
  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: 'auto',
          height: 'auto',
          margin: 0,
          borderRadius: '8px',
        },
      }}>
      <DialogTitle
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px', margin: '8px 16px'}}>
        <Typography style={{fontSize: '16px', textAlign: 'center'}}>
          点検を完了し、
          <br />
          結果を保存します
        </Typography>
      </DialogTitle>
      <DialogContent style={{margin: '0px', padding: '0px', marginBottom: '16px'}}>
        <InspectionRecord />
      </DialogContent>
      <DialogActions style={{display: 'flex', padding: '0px', height: '52px'}}>
        <Button
          variant="contained"
          onClick={onClose}
          style={{width: '50%', height: '52px', color: '#0052CC', border: '0.5px solid #C6CBD4'}}
          disabled={!isValid}>
          <Typography>キャンセル</Typography>
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{marginLeft: '0px', width: '50%', height: '52px', color: '#0052CC', border: '0.5px solid #C6CBD4'}}
          disabled={!isValid}>
          <Typography>保存</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type FinishInspectionDialogProps = DialogProps;

export type FinishInspectionDialogResult = {
  completedAt: string;
};

export const FinishInspectionDialog: React.FC<FinishInspectionDialogProps> = (props) => {
  const {open} = props;

  const [validationSchema] = useState(() =>
    yup.object({
      completedAt: yup.string().required(),
    })
  );

  const handleSubmit = useCallback(
    async (data: FinishInspectionFormValue) => {
      const {completedAt} = data;
      props.actions.resolve({
        completedAt: completedAt,
      });
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );
  const initialValues = {
    completedAt: convertDateToSimpleDateTime(dayjs().toDate()),
  };

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={true}>
        <Form>
          <FinishInspectionForm open={open} onClose={handleClose} />
        </Form>
      </Formik>
    </Suspense>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    width: '100%',
    padding: '0px 0px',
    overflow: 'hidden',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '32px 16px',
  },
  box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  customDialogActions: {
    '& > :not(:first-child)': {
      marginLeft: '0px',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px',
    width: '240px',
    height: '72px',
    margin: '8px 16px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
  },
  txt: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#172B4D',
  },
}));
