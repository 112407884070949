import React, {useCallback, useState} from 'react';
import _ from 'lodash';
import {useAtom, WritableAtom} from 'jotai';
import {useResetAtom} from 'jotai/utils';
import {createStyles, Grid, makeStyles, Theme, Button, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {CategoryFormatter} from '@modules/categories/helpers';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {searchWordAtom, rootCategoryAtom, narrowCategoryAtom} from './states';
import {useOnlyOnce} from '@front-libs/core';
import {
  useGetHospitalCategories,
  useGetHospitalDescendantCategories,
} from '@modules/categories/api/hospitalCategoryApi';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    filters: {
      '& > *:not(:first-child)': {
        marginLeft: '16px',
      },
    },
    searchBox: {
      width: '300px',
    },
    searchInput: {
      background: 'white',
    },
    addButton: {
      marginLeft: 'auto',
    },
  })
);

const useOnMount = () => {
  const resetSearchWord = useResetAtom(searchWordAtom);
  const resetRootCategoryAtom = useResetAtom(rootCategoryAtom);
  const resetNarrowCategoryAtom = useResetAtom(narrowCategoryAtom);

  useOnlyOnce(() => {
    resetSearchWord();
    resetRootCategoryAtom();
    resetNarrowCategoryAtom();
  });
};

function useDebounceAtom<T>(atom: WritableAtom<T, T>, ms: number) {
  const [state, setter] = useAtom(atom);
  const debounceSetter = _.debounce(setter, ms);

  return [state, debounceSetter] as const;
}

type SearchBarProps = {
  showsFilter: boolean;
  onClickEditProducts: React.MouseEventHandler;
};

export const SearchBar: React.VFC<SearchBarProps> = (props) => {
  const {showsFilter, onClickEditProducts} = props;
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [broadCategoryHashId, setBroadCategoryHashId] = useState<null | string>(null);
  const {data: descendantCategories} = useGetHospitalDescendantCategories(
    myInfo.hospitalHashId,
    broadCategoryHashId ?? undefined
  );

  useOnMount();

  const [searchWord, setSearchWord] = useDebounceAtom<string>(searchWordAtom, 500);
  const [, setRootCategory] = useDebounceAtom<string | null>(rootCategoryAtom, 500);
  const [, setNarrowCategory] = useDebounceAtom<string | null>(narrowCategoryAtom, 500);
  const [resetNarrowCategoryValue, setResetNarrowCategoryValue] = useState<boolean>(false);

  const handleChangeSearchWord = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchWord(e.target.value);
    },
    [setSearchWord]
  );

  const handleChangeRootCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setRootCategory(row?.value ?? null);
      setBroadCategoryHashId(row?.value ?? null);
      setNarrowCategory(null);
      setResetNarrowCategoryValue(true);
    },
    [setNarrowCategory, setRootCategory]
  );

  const handleChangeNarrowCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setNarrowCategory(row?.value ?? null);
      setResetNarrowCategoryValue(false);
    },
    [setNarrowCategory]
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: allRootCategory} = useGetHospitalCategories(myInfo.hospitalHashId!, {depth: 0});
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: allNarrowCategory} = useGetHospitalCategories(myInfo.hospitalHashId!, {depth: 1});

  // TODO: メーカー機種名で検索

  return (
    <Grid container item justify="space-between" alignItems="center">
      <Grid item xs container className={classes.filters}>
        {showsFilter && (
          <>
            <TextField
              className={classes.searchBox}
              label="メーカー・機種名で検索"
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                className: classes.searchInput,
                endAdornment: <Search />,
              }}
              defaultValue={searchWord}
              onChange={handleChangeSearchWord}
            />
            <Grid item>
              <PopperSelectBoxButton
                buttonLabel="大分類"
                options={CategoryFormatter.getOptions(allRootCategory)}
                isMulti={false}
                onChange={handleChangeRootCategory}
                searchable={true}
              />
            </Grid>
            <Grid item>
              <PopperSelectBoxButton
                buttonLabel="小分類"
                options={CategoryFormatter.getOptions(descendantCategories ?? allNarrowCategory)}
                isMulti={false}
                onChange={handleChangeNarrowCategory}
                searchable={true}
                resetValue={resetNarrowCategoryValue}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" className={classes.addButton} onClick={onClickEditProducts}>
          対象機種を選択
        </Button>
      </Grid>
    </Grid>
  );
};
