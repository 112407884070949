import React, {useCallback} from 'react';
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  Theme,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardActionArea,
  Grid,
  Box,
} from '@material-ui/core';
import {formatRFC3339Date} from '@front-libs/helpers';
import {InspectionIndex} from '@modules/inspections/types';
import {useAtom} from 'jotai';
import {pageAtom, selectedTemplateAtom} from '../states';
import {Pagination} from '@material-ui/lab';
import {CategoryFormatter} from '@modules/categories/helpers';

// NOTE:/Apps/InspectionResult/ViewInspectionResult/index.tsxのtypeToTextと同義
// NOTE:/Apps/InspectionResultList/hooks.tsのtypeToTextと同義

const typeToText = {
  pre_use: '使用前点検',
  in_use: '使用中点検',
  post_use: '使用後点検',
  periodic: '定期点検',
  // TODO: 以下型定義の為だけに定義
  maker_periodic: '',
  dairy: '',
  'pre_use,in_use,post_use': '',
} as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      maxHeight: 'calc(100vh - 56px - 48px - 36px - 120px - 24px - 56px)',
      overflowY: 'scroll',
    },
    card: {
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: '0px',
      },
    },
    selectedCard: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    cardContentRoot: {
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
    table: {
      marginTop: '24px',
    },
    tableCellLeft: {
      padding: '0px 0px 4px 0px',
      borderBottom: 'none',
    },
    tableCellRight: {
      padding: '0px 0px 4px 16px',
      borderBottom: 'none',
    },
    pagination: {
      marginTop: '24px',
    },
    hide: {
      display: 'none',
    },
  })
);

type StatusSelectorProps = {
  inspectionsQueryData: InspectionIndex[];
  totalCount: number;
};

export const TemplateSelector: React.VFC<StatusSelectorProps> = (props) => {
  const {inspectionsQueryData, totalCount} = props;
  const classes = useStyles();
  const [selectedTemplate, setSelectedTemplate] = useAtom(selectedTemplateAtom);
  const [page, setPage] = useAtom(pageAtom);

  const handleClick = useCallback(
    (inspection: InspectionIndex) => {
      setSelectedTemplate(inspection);
    },
    [setSelectedTemplate]
  );

  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, p: number) => {
      setPage(p);
    },
    [setPage]
  );

  return (
    <>
      <Box className={classes.cardContainer}>
        {inspectionsQueryData.map((inspection) => (
          <Card
            className={clsx(classes.card, inspection.hashId === selectedTemplate?.hashId && classes.selectedCard)}
            variant="outlined"
            key={inspection.hashId}>
            <CardActionArea onClick={() => handleClick(inspection)}>
              <CardContent
                classes={{
                  root: classes.cardContentRoot,
                }}>
                {inspection.inspectionProducts && inspection.inspectionProducts[0].wholeProduct && (
                  <Typography variant="body2">
                    {CategoryFormatter.getRootCategory(inspection.inspectionProducts[0].wholeProduct.categories)
                      ?.name ?? ''}{' '}
                    ＞{' '}
                    {CategoryFormatter.getNarrowCategory(inspection.inspectionProducts[0].wholeProduct.categories)
                      ?.name ?? ''}
                  </Typography>
                )}
                <Typography variant="body2">{inspection.name}</Typography>
                <Table size="small" className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" className={classes.tableCellLeft}>
                        点検タイプ
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellRight}>
                        {inspection.type ? typeToText[inspection.type] : ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" className={classes.tableCellLeft}>
                        作成者
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellRight}>
                        {inspection.hospital.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" className={classes.tableCellLeft}>
                        作成日時
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellRight}>
                        {formatRFC3339Date(inspection.createdAt, 'YYYY/MM/DD HH:mm') ?? ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
      <Grid className={clsx(classes.pagination, totalCount < 1 && classes.hide)} container justifyContent="center">
        <Pagination page={page} count={totalCount} color={'primary'} shape="rounded" onChange={handleChangePage} />
      </Grid>
    </>
  );
};
