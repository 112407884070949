import React, {useCallback} from 'react';
import {withSuspense} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@molecules/Loading';
import {useInspection, useInspectionResult} from '@Apps/InspectionResult/pc/common/hooks';
import {ViewInspectionResult} from '@Apps/InspectionResult/pc/ViewInspectionResult';
import {EditInspectionResult} from '@Apps/InspectionResult/pc/EditInspectionResult';
import {useLocation, useParams} from 'react-router-dom';
import {NextUpdateInspectionStatusParam} from '@modules/inspection_results/api';

const _InspectionResult: React.FC = () => {
  const location = useLocation();
  const nextUpdateInspectionStatusParam = location.state as NextUpdateInspectionStatusParam;
  const {inspectionHashId, inspectionResultHashId} = useParams();
  const {myInfo} = useMyInfo();
  const {data: inspection, isLoading: isInspectionLoading} = useInspection(
    myInfo.hospitalHashId,
    inspectionHashId ? inspectionHashId : ''
  );

  const {
    data: inspectionResult,
    isLoading: isInspectionResultLoading,
    refetch: refetchResult,
  } = useInspectionResult(
    myInfo.hospitalHashId,
    inspectionHashId ? inspectionHashId : '',
    inspectionResultHashId ? inspectionResultHashId : '',
    {noCache: true}
  );

  const handleResultUpdate = useCallback(() => {
    refetchResult();
  }, [refetchResult]);

  if (isInspectionLoading || isInspectionResultLoading) {
    return <InnerLoading />;
  }
  if (!inspection || !inspectionResult) {
    return null;
  }

  const inspectionTable = inspectionResult.inspectionTable ?? inspection.table;

  if (inspectionResult.status === 'completed') {
    return (
      <ViewInspectionResult
        key={inspection?.table.hashId}
        inspection={inspection}
        inspectionTable={inspectionTable}
        inspectionResult={inspectionResult}
        onResultUpdate={handleResultUpdate}
      />
    );
  } else {
    return (
      <EditInspectionResult
        key={inspection?.table.hashId}
        inspection={inspection}
        inspectionTable={inspectionTable}
        inspectionResult={inspectionResult}
        onResultUpdate={handleResultUpdate}
        nextUpdateInspectionStatusParam={nextUpdateInspectionStatusParam}
      />
    );
  }
};

export const InspectionResult = withSuspense(_InspectionResult, null);
