import React from 'react';
import {FilterDrawerSelectorField} from './fields/FilterDrawerSelectorField';
import {FilterDrawerDateRangeField} from './fields/FilterDrawerDateRangeField';
import {
  ConditionValue,
  DateRangeConditionValue,
  FilterOption,
  NumberConditionValue,
  SelectorConditionValue,
  TextConditionValue,
} from './types';
import {FilterDrawerTextField} from './fields/FilterDrawerTextField';
import {FilterDrawerNumberRangeField} from './fields/FilterDrawerNumberRangeField';

type DrawerFieldSelectorProps = {
  option?: FilterOption;
  defaultCondition?: ConditionValue;

  onChangeTextFilter: (x: string) => void;
  onChangeSelectorFilter: (x: SelectorConditionValue) => void;
  onChangeDateRangeFilter: (x: DateRangeConditionValue) => void;
  onChangeNumberFilter: (x: NumberConditionValue) => void;
  onSubmit: ((x: SelectorConditionValue) => void) | ((x: DateRangeConditionValue) => void);
};

export const DrawerFieldSelector = (props: DrawerFieldSelectorProps) => {
  const {
    option,
    defaultCondition,
    onChangeNumberFilter,
    onChangeTextFilter,
    onChangeSelectorFilter,
    onChangeDateRangeFilter,
    onSubmit,
  } = props;

  switch (option?.optionType) {
    case 'text':
      return (
        <FilterDrawerTextField
          {...option}
          onSubmit={onSubmit as (x: string) => void}
          onChange={onChangeTextFilter}
          defaultValue={defaultCondition as TextConditionValue}
        />
      );

    case 'selector':
      return (
        <FilterDrawerSelectorField
          {...option}
          defaultOptions={defaultCondition as SelectorConditionValue}
          onChange={onChangeSelectorFilter}
          onSubmit={onSubmit as (x: SelectorConditionValue) => void}
        />
      );
    case 'dateRange':
      return (
        <FilterDrawerDateRangeField
          {...option}
          onChange={onChangeDateRangeFilter}
          onSubmit={onSubmit as (x: DateRangeConditionValue) => void}
        />
      );
    case 'number':
      return (
        <FilterDrawerNumberRangeField
          {...option}
          defaultValues={defaultCondition as NumberConditionValue}
          onChange={onChangeNumberFilter}
          onSubmit={onSubmit as (x: NumberConditionValue) => void}
        />
      );
    default:
      return null;
  }
};
