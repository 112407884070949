import {useRef, useEffect} from 'react';
import {axios} from '@front-libs/core';
import {atom, useAtom} from 'jotai';
import {isEqual} from 'lodash';

export type HospitalDepartment = {
  hashId: string;
  hospitalHashId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: null | string;
  name: string;
};

export type HospitalDepartments = HospitalDepartment[];

export type HospitalDepartmentsResponse = {
  page: number;
  totalCount: number;
  data: HospitalDepartments;
};

export type HospitalDepartmentParams = {
  page: number;
  perPage: number;
  name?: string;
  order?: string;
};
/**
 * 管理部署の一覧を取得
 * @param hospitalHashId
 * @returns
 */
export const getHospitalProductMgmtSection = async (hospitalHashId: string, params?: HospitalDepartmentParams) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_product_mgmt_section`;
  const response = await axios.get<HospitalDepartmentsResponse>(url, params && {params});
  return response.data;
};

/**
 * 管理部署の追加
 * @param hospitalHashId
 * @param productMgmtSectionName
 * @returns
 */
export const createHospitalProductMgmtSection = async (hospitalHashId: string, params: HospitalDepartmentParams) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_product_mgmt_section`;
  const res = await axios.post(url, {name: params.name});
  return res.data;
};

/**
 * 管理部署の名前変更
 * @param hospitalHashId
 * @param hospitalProductMgmtSectionHashID
 * @param productMgmtSectionName
 * @returns
 */
export const updateHospitalProductMgmtSections = async (
  hospitalHashId: string,
  hospitalProductMgmtSectionHashID: string,
  params: HospitalDepartmentParams
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_product_mgmt_section/${hospitalProductMgmtSectionHashID}`;
  const response = await axios.put(url, {name: params.name});
  return response.data;
};

/**
 * 管理部署の削除
 * @param hospitalHashId
 * @returns
 */
export const deleteHospitalProductMgmtSections = async (
  hospitalHashId: string,
  hospitalProductMgmtSectionHashID: string,
  deleteHospitalDepartmentsParam: HospitalDepartmentParams
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_product_mgmt_section/${hospitalProductMgmtSectionHashID}`;
  const response = await axios.delete(url, {data: deleteHospitalDepartmentsParam});
  return response.data;
};

const hospitalProductMgmtSectionAtom = atom<
  {
    label: string;
    value: string;
  }[]
>([]);

const mgmtSecAtom = atom<HospitalDepartmentsResponse | undefined>(undefined);

export const useHospitalProductMgmtSection = (hospitalHashId: string) => {
  const [managementDepartment, setManagementDepartment] = useAtom(mgmtSecAtom);

  const isLoading = useRef(true);

  useEffect(() => {
    (async () => {
      if (!isLoading.current) return;

      const hospitalDepartments = await getHospitalProductMgmtSection(hospitalHashId);

      // NOTE:deletedByに値が入っていれば削除済みとして表示させない
      hospitalDepartments.data.filter((v) => v.deletedBy === null);
      if (!isEqual(managementDepartment, hospitalDepartments)) {
        setManagementDepartment(hospitalDepartments);
      }
      isLoading.current = false;
    })();
  }, [hospitalHashId, managementDepartment, setManagementDepartment]);

  return managementDepartment;
};

/**
 * 指定された病院のハッシュIDに基づいて、病院製品管理セクションのオプションを管理するためのフック
 *
 * このフックは、病院の製品管理セクションをフェッチし、削除されたものをフィルタリングし、
 * アクティブなセクションで管理部門の状態を設定します。フックは管理部門の現在の状態を返します
 *
 * @param hospitalHashId - 製品管理セクションを管理する病院のハッシュID
 * @returns - 管理部門の現在の状態
 *
 * @example
 * const managementDepartment = useHospitalProductMgmtSectionOption('ある病院のハッシュID');
 */
export const useHospitalProductMgmtSectionOption = (hospitalHashId: string) => {
  const [managementDepartment, setManagementDepartment] = useAtom(hospitalProductMgmtSectionAtom);

  const isLoading = useRef(true);

  useEffect(() => {
    (async () => {
      if (!isLoading.current) return;

      const hospitalDepartments = (await getHospitalProductMgmtSection(hospitalHashId)).data;

      // NOTE:deletedByに値が入っていれば削除済みとして表示させない
      const options = hospitalDepartments
        .filter((v) => v.deletedBy === null)
        .map((hospitalDepartment) => ({
          label: hospitalDepartment.name,
          value: hospitalDepartment.hashId,
        }));
      if (!isEqual(managementDepartment, options)) {
        setManagementDepartment(options);
      }
      isLoading.current = false;
    })();
  }, [hospitalHashId, managementDepartment, setManagementDepartment]);

  return managementDepartment;
};
