import {Grid, Paper} from '@material-ui/core';
import React, {useMemo} from 'react';
import {ChartByCostCategory} from './ChartByCostCategory';
import {useStyles} from './styles';
import {useDisplayTimePeriod, usePaymentDateRange, useSelectView} from './states';
import {Toolbar} from './Toolbar';
import {ChartByRootCategory} from './ChartByRootCategory';
import {ChartByNarrowCategory} from './ChartByNarrowCategory';

export type CostManagementProps = {
  // ban
};

export const CostManagement: React.FC<CostManagementProps> = () => {
  const classes = useStyles();
  const [selectView, setSelectView] = useSelectView();
  const [displayTimePeriod, setDisplayTimePeriod] = useDisplayTimePeriod();
  const [paymentDateTo, setPaymentDateTo] = usePaymentDateRange();

  const ChartComponent = useMemo(() => {
    if (selectView === 'costCategory') {
      return ChartByCostCategory;
    } else if (selectView === 'rootCategory') {
      return ChartByRootCategory;
    } else if (selectView === 'narrowCategory') {
      return ChartByNarrowCategory;
    } else {
      throw new Error('unreachable');
    }
  }, [selectView]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <div className={classes.contentTitleContainer}>
          <Toolbar
            selectView={selectView}
            displayTimePeriod={displayTimePeriod}
            paymentDateTo={paymentDateTo}
            onChangeSelectView={setSelectView}
            onChangeDisplayTimePeriod={setDisplayTimePeriod}
            onChangePaymentDateTo={setPaymentDateTo}
          />
        </div>
        <Grid className={classes.highChartContainer}>
          <ChartComponent />
        </Grid>
      </div>
    </Paper>
  );
};
