import {createContext, useContext} from 'react';
import {User} from '@auth0/auth0-react';

export type Auth0MultiSignInContextValues = {
  currentUser: User | null;
  users: User[];
  signIn: () => Promise<void>;
  switchUser: (userSub: string) => Promise<void>;
  signOut: () => Promise<void>;
};

const notReady = async () => {
  throw new Error('There are no values set in Auth0MultiSignInContext');
};

export const Auth0MultiSignInContext = createContext<Auth0MultiSignInContextValues>({
  currentUser: null,
  users: [],
  signIn: async () => await notReady(),
  switchUser: async (_userSub: string) => await notReady(),
  signOut: async () => await notReady(),
});

export const useAuth0MultiSignInContext = () => {
  return useContext(Auth0MultiSignInContext);
};
