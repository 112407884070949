import React from 'react';
import {createStyles, Grid, makeStyles, Theme, Link} from '@material-ui/core';
import {HospitalProductWithInspectionProduct} from '@modules/hospital_products/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  })
);

type ManagementIdColumnProps = {
  rowData: HospitalProductWithInspectionProduct;
  handleClickRow: (_e: React.MouseEvent, data: HospitalProductWithInspectionProduct) => void;
};

export const ManagementIdColumn = ({rowData, handleClickRow}: ManagementIdColumnProps) => {
  const classes = useStyles();
  return (
    <Grid item xs container direction="column">
      <Link onClick={(e) => handleClickRow(e, rowData)} className={classes.name}>
        {rowData.managementId}
      </Link>
    </Grid>
  );
};
