import {theme} from '@atoms/theme';
import {isNullish} from '@front-libs/helpers';

export const productStatusType = [
  {
    label: '待機中',
    value: 'ready',
    color: '#3C9E58',
  },
  {
    label: '貸出中',
    value: 'working',
    color: '#C7243A',
  },
  {
    label: '点検待ち',
    value: 'uninspected',
    color: '#826149',
  },
  {
    label: '修理中',
    value: 'repairing',
    color: theme.palette.primary.dark,
  },
  {
    label: '廃棄済み',
    value: 'disabled',
    color: '#727272',
  },
];

const productStatusTypeMap = Object.fromEntries(
  productStatusType.map((item) => [item.value, {label: item.label, color: item.color}])
);

export function getProductStatusByValue(value?: string): {label: string; color: string} {
  if (isNullish(value)) return {label: '', color: '#FFF'};
  return productStatusTypeMap[value] ?? '';
}

export const productStatusOptions = productStatusType.map((item) => ({
  value: item.value,
  label: item.label,
}));
