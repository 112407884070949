type PropertyOption = {
  value: string;
  label: string;
  alias: string[];
} & (StringProp | BoolProp | SelectProp);

type StringProp = {
  propType: 'string' | 'date' | 'number';
};

type BoolProp = {
  propType: 'bool';
  optionMap: {
    true: string;
    false: string;
    default: boolean;
  };
};

type SelectProp = {
  propType: 'select';
  optionMap: {
    [key: string]: unknown;
    default: unknown;
  };
};

export const hitotsuPropertyOptions: PropertyOption[] = [
  {value: 'name', label: '型式', propType: 'string', alias: ['型番', '機種名', '品番']},
  {value: 'displayName', label: '機種名', propType: 'string', alias: ['商品', '機種名', '品名', '販売名']},
  {value: 'makerName', label: 'メーカー名', propType: 'string', alias: ['メーカ', '製造販売業者']},
  {
    value: 'isSpecificMaintain',
    label: '特定保守機種',
    propType: 'bool',
    optionMap: {
      true: '該当',
      false: '非該当',
      default: false,
    },
    alias: ['特定保守管理機器'],
  },
  {
    value: 'className',
    label: 'クラス分類',
    propType: 'select',
    optionMap: {
      one: '一般医療機器',
      two: '管理医療機器',
      three: '高度医療機器',
      four: '高度管理医療機器',
      default: null,
    },
    alias: ['クラス'],
  },
  {value: 'jmdnCode', label: 'JMDNコード', propType: 'string', alias: []},
  {value: 'janCode', label: 'JANコード', propType: 'string', alias: ['JAN']},
  {value: 'optionalBarcode', label: 'バーコード読み取り値', propType: 'string', alias: []},
  {value: 'rawBarcode', label: 'GS1バーコード', propType: 'string', alias: ['GS1']},

  {value: 'approvalNumber', label: '承認番号', propType: 'string', alias: []},
  {value: 'rootCategory', label: '大分類', propType: 'string', alias: []},
  {value: 'narrowCategory', label: '小分類', propType: 'string', alias: []},

  {value: 'managementId', label: '管理番号', propType: 'string', alias: ['管理No', '管理ナンバー', '管理NO']},
  {value: 'serialNumber', label: 'シリアル番号', propType: 'string', alias: ['シリアル', '製造番号']},
  {value: 'status', label: '稼働状況', propType: 'string', alias: ['ステータス']},
  {value: 'dateOfPurchase', label: '購入日', propType: 'date', alias: ['納入日', '納品日', '購入年月日']},
  {
    value: 'permanentlyAssigned',
    label: '貸出区分',
    propType: 'bool',
    optionMap: {
      true: '貸出不可',
      false: '貸出可',
      default: false,
    },
    alias: [],
  },
  {
    value: 'waysOfPurchase',
    label: '購入区分',
    propType: 'select',
    optionMap: {
      purchase: '購入',
      demo: 'デモ',
      rental: 'レンタル',
      lease: 'リース',
      default: 'purchase',
    },
    alias: [],
  },
  {
    value: 'isMaintenanceContract',
    label: '保守契約有無',
    propType: 'bool',
    optionMap: {
      true: '保守契約有り',
      false: '保守契約有無し',
      default: false,
    },
    alias: [],
  },
  {value: 'hospitalRoomHashId', label: '機器管理場所', propType: 'number', alias: ['管理部署', '管理場所']},
  {value: 'legalDurableYear', label: '耐用年数', propType: 'number', alias: ['耐用年']},
  {value: 'assetRegisterNumber', label: '資産番号', propType: 'string', alias: []},
  {value: 'dateOfDisposal', label: '廃棄日', propType: 'date', alias: []},
  {value: 'reasonOfDisposal', label: '廃棄理由', propType: 'string', alias: []},
  {value: 'dealerName', label: '担当代理店', propType: 'string', alias: ['ディーラー']},
  {value: 'rentalId', label: 'レンタル番号', propType: 'string', alias: []},
  {value: 'rentalDealerName', label: 'レンタル企業名', propType: 'string', alias: []},
  {value: 'rentalFee', label: 'レンタル金額', propType: 'number', alias: []},
  {value: 'rentalStartDate', label: 'レンタル開始日', propType: 'date', alias: []},
  {value: 'rentalDueDate', label: 'レンタル終了予定日', propType: 'date', alias: []},
  {value: 'rentalReturnDate', label: 'レンタル機器返却日', propType: 'date', alias: []},
  {value: 'leaseId', label: 'リース番号', propType: 'string', alias: []},
  {value: 'leaseDealerName', label: 'リース企業名', propType: 'string', alias: []},
  {value: 'leaseFee', label: 'リース金額', propType: 'number', alias: []},
  {value: 'leaseStartDate', label: 'リース開始日', propType: 'date', alias: []},
  {value: 'leaseDueDate', label: 'リース終了予定日', propType: 'date', alias: []},
  {value: 'leaseReturnDate', label: 'リース機器返却日', propType: 'date', alias: []},
  {value: 'purposeOfDemonstration', label: 'デモの目的', propType: 'string', alias: []},
  {value: 'demonstrationStartDate', label: 'デモ開始日', propType: 'date', alias: []},
  {value: 'demonstrationEndDate', label: 'デモ終了日', propType: 'date', alias: []},
  {value: 'catalogPrice', label: '定価', propType: 'number', alias: ['定価']},
  {value: 'deliveryPrice', label: '納入価', propType: 'number', alias: ['購入価']},
  {
    value: 'taxIncluded',
    label: '税込／税抜',
    propType: 'bool',
    optionMap: {
      true: '税込',
      false: '税抜',
      default: true,
    },
    alias: [],
  },
  {value: 'taxRate', label: '税率', propType: 'number', alias: []},
  {value: 'notes', label: '備考1', propType: 'string', alias: []},
  {value: 'notes2', label: '備考2', propType: 'string', alias: []},
  {value: 'notes3', label: '備考3', propType: 'string', alias: []},
];

export const wholeProductCandidateColumns = [
  'name',
  'displayName',
  'janCode',
  'approvalNumber',
  'makerName',
  'rootCategory',
  'narrowCategory',
];

export const mavinFormatKey = [
  {columnIndex: 1, columnName: '固有番号'},
  {columnIndex: 2, columnName: '所属施設'},
  {columnIndex: 3, columnName: '任意コード'},
];

export const mavinFormatMap = [
  {columnIndex: 2, columnName: '任意コード', hitotsuProperty: 'managementId'},
  {columnIndex: 3, columnName: '型式', hitotsuProperty: 'name'},
  {columnIndex: 4, columnName: '機種名', hitotsuProperty: 'displayName'},
  {columnIndex: 6, columnName: '製造番号', hitotsuProperty: 'serialNumber'},
  {columnIndex: 7, columnName: '製造組織名漢字', hitotsuProperty: 'makerName'},
  {columnIndex: 14, columnName: '納入日', hitotsuProperty: 'dateOfPurchase', dateFormat: 'YYYYMMDD'},
  {
    columnIndex: 15,
    columnName: '納入区分名',
    hitotsuProperty: 'waysOfPurchase',
    optionMap: {
      資産: 'purchase',
      デモ: 'demo',
      レンタル: 'rental',
      リース: 'lease',
      default: 'purchase',
    },
  },
  {
    columnIndex: 17,
    columnName: '状態区分名',
    hitotsuProperty: 'status',
    optionMap: {
      貸出中: 'working',
      貸出可能: 'ready',
      使用中: 'working',
      返却後点検待ち: 'uninspected',
      廃棄: 'disabled',
      メーカー修理依頼: 'repairing',
      メーカー修理中: 'repairing',
      default: 'ready',
    },
  },
  {
    columnIndex: 18,
    columnName: '特定保守名',
    hitotsuProperty: 'isSpecificMaintain',
    optionMap: {
      該当: true,
      非該当: false,
      default: false,
    },
  },
  {
    columnIndex: 19,
    columnName: 'クラス分類名',
    hitotsuProperty: 'className',
    optionMap: {
      一般医療機器: 'one',
      管理医療機器: 'two',
      高度医療機器: 'three',
      高度管理医療機器: 'four',
      default: null,
    },
  },
  {columnIndex: 23, columnName: 'JANコード', hitotsuProperty: 'janCode'},
  {columnIndex: 25, columnName: '資産番号', hitotsuProperty: 'assetRegisterNumber'},
  {columnIndex: 29, columnName: '廃棄日', hitotsuProperty: 'dateOfDisposal', dateFormat: 'YYYYMMDD'},
  // {columnName: '納入価', hitotsuProperty: 'className'},
];
