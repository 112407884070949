import React, {Suspense} from 'react';
import {useParams} from 'react-router-dom';
import {use2ColumnsLayoutTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {Grid} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchMakerInspectionResultQuery} from '@modules/maker_inspection_results/hooks';
import {InnerLoading} from '@molecules/Loading';
import {NewMakerInspectionDialog} from './NewMakerInspectionDialog';
import {isNullish} from '@front-libs/helpers';
import {MakerInspectionResultContent} from './MakerInspectionResultContent';

type Props = {
  hashId: string;
};

const MakerInspectionResultContainer: React.FC<Props> = ({hashId}) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useFetchMakerInspectionResultQuery(myInfo.hospitalHashId, hashId);

  return isLoading || isNullish(data?.data) ? (
    <InnerLoading />
  ) : data?.data.status !== 'completed' ? (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <NewMakerInspectionDialog hospitalHashId={myInfo.hospitalHashId} makerInspectionResult={data!.data} />
  ) : (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <MakerInspectionResultContent hospitalHashId={myInfo.hospitalHashId} makerInspectionResult={data!.data} />
  );
};

export const MakerInspectionResult: React.FC = () => {
  const templateClasses = use2ColumnsLayoutTemplate();
  const {hashId} = useParams();

  return (
    <Grid container className={templateClasses.root}>
      <Suspense fallback={null}>
        <MakerInspectionResultContainer hashId={hashId ? hashId : ''} />
      </Suspense>
    </Grid>
  );
};
