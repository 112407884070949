import {makeStyles, Theme} from '@material-ui/core';
import {Brightness1} from '@material-ui/icons';
import React from 'react';
import {inspectionStatusType} from '@constants/constants';

const useStyles = makeStyles((theme: Theme) => ({
  statusColor: {
    width: '10px !important',
    top: '6px',
    marginRight: '8px',
    position: 'relative',
  },
}));

type Props = {
  status: 'available' | 'draft';
};

export const InspectionStatus: React.VFC<Props> = ({status}) => {
  const inspectionStatusConst = inspectionStatusType[status] || {
    color: '#FFF',
    label: '',
  };
  const classes = useStyles();
  return (
    <span>
      <Brightness1 className={classes.statusColor} style={{color: inspectionStatusConst.color}} />
      <span>{inspectionStatusConst.label}</span>
    </span>
  );
};
