import {RootCauseCategoryType, SymptomCategoryType} from '@modules/repairs/types';

export const seriesForChartBySymptomCategory: {[key in SymptomCategoryType]: {name: string; color: string}} = {
  others: {
    name: 'その他',
    color: '#7D8FAD',
  },
  no_issue: {
    name: '異常なし',
    color: '#4A5D7D',
  },
  lost: {
    name: '紛失',
    color: '#253858',
  },
  component_deficiency: {
    name: '部品不備',
    color: '#A5C9FD',
  },
  maintenance_required: {
    name: '要点検',
    color: '#689DEB',
  },
  internal_failure: {
    name: '内部故障',
    color: '#3A77D2',
  },
  external_damage: {
    name: '外装破損',
    color: '#0747A6',
  },
};

export const seriesForChartByRootCauseCategory: {[key in RootCauseCategoryType]: {name: string; color: string}} = {
  others: {
    name: 'その他',
    color: '#4A5D7D',
  },
  unknown_cause: {
    name: '原因不明',
    color: '#253858',
  },
  inspection_not_performed: {
    name: '点検未実施',
    color: '#A5C9FD',
  },
  patient_related_cause: {
    name: '人的要因（患者）',
    color: '#689DEB',
  },
  staff_related_cause: {
    name: '人的要因（職員）',
    color: '#3A77D2',
  },
  degradation: {
    name: '劣化',
    color: '#0747A6',
  },
};
