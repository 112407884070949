import React, {useEffect, useCallback, useMemo, useState} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Column} from '@molecules/Table/props';
import {Pagination} from '@material-ui/lab';
import {useAtomValue, useUpdateAtom} from 'jotai/utils';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@molecules/SnackBar';
import {
  updateInspectionPeriods,
  useFetchInspectionPeriodProductsQuery,
  FetchInspectionPeriodProductsParam,
  updateInspectionPeriod,
  useFetchInspectionPeriodProductsByAssignedQuery,
} from '@modules/inspection_setting/api';
import {SearchBar} from './SearchBar';
import {InspectionType} from '@modules/inspections/enum';
import {InspectionPeriodProduct} from '@modules/inspection_setting/types';
import {NoProducts} from '../NoProducts';
import {ProductListElement} from './types';
import {rootCategoryAtom, searchWordAtom, narrowCategoryAtom} from './states';
import {EditProductsDialog, EditProductsDialogProps} from './EditProductsDialog';
import {
  UpdatePeriodRequestDispatcherContextProvider,
  UnassignWholeProductRequestDispatcherContextProvider,
  DisplayHospitalProductRequestDispatcherContextProvider,
} from './contexts';
import {PeriodicInspectionPeriodicColumn} from './columns/InspectionPeriodColumn';
import {MakerNameColumn} from './columns/MakerNameColumn';
import {tabAtom} from '../states';
import {productHashIdAtom} from '../HospitalProductsForm';
import {Table} from '@molecules/Table';
import {useOnlyOnce} from '@front-libs/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1 1 auto',
      width: '100%',
      padding: '0px 24px',
    },
    toolBar: {
      display: 'flex',
      alignItems: 'center',
      '& > *:not(:first-child)': {
        marginLeft: '16px',
      },
      '& > *:last-child': {
        marginLeft: 'auto',
      },
    },
    searchBox: {
      width: '300px',
    },
    searchInput: {
      background: 'white',
    },
    table: {
      marginTop: '24px',
    },
    pagination: {
      marginTop: '16px',
    },
  })
);

const NUM_PER_PAGE = 10;

type ProductsFormProps = {
  inspectionHashId: string;
  inspectionType: InspectionType;
};

export const ProductsForm: React.VFC<ProductsFormProps> = (props) => {
  const {inspectionHashId, inspectionType} = props;
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const searchWord = useAtomValue(searchWordAtom);
  const rootCategory = useAtomValue(rootCategoryAtom);
  const narrowCategory = useAtomValue(narrowCategoryAtom);

  const [tableLayout] = useTableLayout('inspectionProductList');
  const [page, setPage] = useState(1);
  const [showsFilter, setShowsFilter] = useState(false);

  useEffect(() => {
    setPage(1);
  }, [searchWord, rootCategory, narrowCategory]);

  const params = useMemo(() => {
    const _p: FetchInspectionPeriodProductsParam = {
      page: page - 1,
      perPage: NUM_PER_PAGE,
      inspectionHashId: inspectionHashId,
      hasInspectionId: true,
      inspectionType: inspectionType,
    };

    if (searchWord) {
      _p.name = searchWord;
    }

    if (narrowCategory !== null) {
      _p.categoryHashIds = narrowCategory;
    } else if (rootCategory !== null) {
      _p.categoryHashIds = rootCategory;
    }

    return _p;
  }, [page, inspectionHashId, inspectionType, searchWord, narrowCategory, rootCategory]);

  const productsCountQuery = useFetchInspectionPeriodProductsQuery(myInfo.hospitalHashId, {
    inspectionHashId: inspectionHashId,
    hasInspectionId: true,
    perPage: 0,
    inspectionType: inspectionType,
  });

  const query = useFetchInspectionPeriodProductsQuery(myInfo.hospitalHashId, params);
  const selectedProductsQuery = useFetchInspectionPeriodProductsByAssignedQuery(myInfo.hospitalHashId, {
    inspectionHashId: inspectionHashId,
    perPage: 0,
    inspectionType: inspectionType,
  });

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<ProductListElement>[], TableLayout[]>([], tableLayout?.currentLayout);

    return tableColumn.map((item) => {
      switch (item.field) {
        case 'inspectionPeriod':
          if (inspectionType !== 'periodic') {
            item.hidden = true;
          } else {
            item.render = PeriodicInspectionPeriodicColumn;
          }
          break;
        case 'makerName':
          item.render = MakerNameColumn;
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout?.currentLayout, inspectionType]);

  const listData = useMemo<ProductListElement[]>(() => {
    return (query.data ?? []).map(
      (product): ProductListElement => ({
        inspectionType: inspectionType,
        hashId: product.hashId,
        name: product.name,
        displayName: product.displayName,
        makerName: product?.maker?.name ?? '',
        periodicInspectionPeriod: product.inspectionPeriod?.periodicInspectionPeriod,
        periodicInspectionPeriodUnit: product.inspectionPeriod?.periodicInspectionPeriodUnit,
      })
    );
  }, [query.data, inspectionType]);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / NUM_PER_PAGE);
  }, [query.totalCount]);

  const selectedProductsValues = useMemo(
    () =>
      (selectedProductsQuery.data ?? []).filter((item) => {
        return item.inspectionPeriodCount > 0;
      }),
    [selectedProductsQuery]
  );

  const handleClickEditProducts = useCallback(async () => {
    try {
      const products = await dialogHandler.open<EditProductsDialogProps, InspectionPeriodProduct[]>(
        EditProductsDialog,
        {
          hospitalHashId: myInfo.hospitalHashId,
          inspectionHashId: inspectionHashId,
          inspectionType: inspectionType,
          defaultValues: selectedProductsValues,
        }
      );

      const additionalProducts = products.filter(
        (item) => !selectedProductsValues.some((i) => i.hashId === item.hashId)
      );
      const deletedProducts = selectedProductsValues.filter((item) => !products.some((i) => i.hashId === item.hashId));

      await updateInspectionPeriods(myInfo.hospitalHashId, {
        inspectionHashId: inspectionHashId,
        addWholeProductHashIds: additionalProducts.map((i) => i.hashId),
        deleteWholeProductHashIds: deletedProducts.map((i) => i.hashId),
      });

      if (additionalProducts.length > 0 || deletedProducts.length > 0) {
        openSnackBar('対象機種を更新しました', 'center', 'top', 'success');
      }

      query.refetch();
      productsCountQuery.refetch();
      selectedProductsQuery.refetch();
    } catch (e: unknown) {
      if (e) {
        console.error(e);
        openSnackBar(`機種追加に失敗しました: ${e}`, 'center', 'top', 'error');
      }
    }
  }, [
    myInfo.hospitalHashId,
    inspectionHashId,
    inspectionType,
    selectedProductsValues,
    query,
    productsCountQuery,
    selectedProductsQuery,
  ]);

  const handleUnassignWholeProduct = useCallback(
    async (wholeProductHashId) => {
      try {
        await updateInspectionPeriods(myInfo.hospitalHashId, {
          inspectionHashId: inspectionHashId,
          addWholeProductHashIds: [],
          deleteWholeProductHashIds: [wholeProductHashId],
        });

        openSnackBar(`機種割当を解除しました`, 'center', 'top', 'success');

        query.refetch();
        productsCountQuery.refetch();
        selectedProductsQuery.refetch();
      } catch (e: unknown) {
        if (e) {
          console.error(e);
          openSnackBar(`機種割当の解除に失敗しました: ${e}`, 'center', 'top', 'error');
        }
      }
    },
    [inspectionHashId, myInfo.hospitalHashId, productsCountQuery, query, selectedProductsQuery]
  );

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const handleUpdatePeriodRequest = useCallback(
    async (productHashId: string, type: 'periodic' | 'maker', period: number, unit: 'day' | 'month' | 'year') => {
      const data = (query.data ?? []).find((d) => d.hashId === productHashId);
      if (!data) {
        throw new Error('product not found');
      }

      await updateInspectionPeriod(myInfo.hospitalHashId, {
        wholeProductHashId: data.hashId,
        periodicInspectionPeriod: period,
        periodicInspectionPeriodUnit: unit,
      });

      query.refetch();
    },
    [query, myInfo.hospitalHashId]
  );

  const setTab = useUpdateAtom(tabAtom);
  const setProductHashId = useUpdateAtom(productHashIdAtom);

  const handleSwitchHospitalProducts = useCallback(
    (wholeProductHashId: string) => {
      setProductHashId(wholeProductHashId);
      setTab('start_dates');
    },
    [setProductHashId, setTab]
  );

  // 2ページ以上のみフィルターを表示する
  useOnlyOnce(() => setShowsFilter(Math.ceil(query.totalCount / NUM_PER_PAGE) > 1), query.isSuccess);

  return (
    <Grid container className={classes.root} direction="column">
      <SearchBar showsFilter={showsFilter} onClickEditProducts={handleClickEditProducts} />
      {productsCountQuery.totalCount > 0 && (
        <DisplayHospitalProductRequestDispatcherContextProvider handler={handleSwitchHospitalProducts}>
          <UnassignWholeProductRequestDispatcherContextProvider handler={handleUnassignWholeProduct}>
            <UpdatePeriodRequestDispatcherContextProvider handler={handleUpdatePeriodRequest}>
              <Grid container direction="column" className={classes.table}>
                <Table<ProductListElement>
                  selectionButtons={[]}
                  isLoading={query.isLoading}
                  columns={serializedTableColumn}
                  data={listData}
                />
                <Grid item container className={classes.pagination} justifyContent="center">
                  <Pagination
                    page={page}
                    count={totalPage}
                    color="primary"
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Grid>
              </Grid>
            </UpdatePeriodRequestDispatcherContextProvider>
          </UnassignWholeProductRequestDispatcherContextProvider>
        </DisplayHospitalProductRequestDispatcherContextProvider>
      )}
      {!productsCountQuery.isLoading && productsCountQuery.totalCount === 0 && (
        <Grid item container alignItems="center" justifyContent="center" style={{width: '100%', marginTop: '72px'}}>
          <Grid item>
            <NoProducts
              title="まだ機種が選択されていません。"
              message={`右上の対象機種を選択より、機種を選択してください。\n選択された機種がここに表示されます。`}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
