import React from 'react';
import {createStyles, Paper, Grid, Tabs, Tab, Divider, Button, makeStyles, Theme} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';

type HeaderProps = {
  name: string;
  onClickPrevButton: React.MouseEventHandler;
};

export const Header = ({name, onClickPrevButton}: HeaderProps) => {
  const classes = useStyles();

  return (
    <Paper elevation={2} square className={classes.root}>
      <Grid container>
        <Grid container className={classes.navigations}>
          <Grid item xs container justifyContent="flex-start" alignItems="center">
            <Button onClick={onClickPrevButton}>
              <ChevronLeft />
              点検開始日の設定に戻る
            </Button>
          </Grid>
          <Grid item xs container alignItems="center" className={classes.nameInputContainer}>
            {name}
          </Grid>
          <Grid item xs container className={classes.rightAction} />
        </Grid>

        <Divider variant="middle" className={classes.divider} />
        <Grid item container justifyContent="center" className={classes.tabsContainer}>
          <Tabs value="preview" indicatorColor="primary" textColor="primary">
            <Tab label="プレビュー" value="preview" />
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
    navigations: {
      padding: '16px 24px 16px',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '16px 32px',
      },
    },
    nameInputContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    rightAction: {
      minWidth: '200px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    divider: {
      margin: '0px 0',
      width: '100%',
    },
    tabsContainer: {
      marginTop: '8px',
    },
  })
);
