import React, {useEffect, useState} from 'react';
import {styled} from '@material-ui/core/styles';
import {Button, Typography, Theme} from '@material-ui/core';
import {productStatusType} from '@modules/hospital_products/constants';
import Checkbox from '@material-ui/core/Checkbox';
import {useAtom, useAtomValue} from 'jotai';
import {filterCheckListAtom, searchTextChangeAtom} from '../jotai';

const flexCenterCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const StyledHeadTextDiv = styled('div')({
  ...flexCenterCss,
});

const StyledCheckBoxDiv = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: 'column',
});
const StyledFilterContentsDiv = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledCircle = styled(({backGroundColor, ...otherProps}) => <div {...otherProps} />)(
  ({backGroundColor}: {backGroundColor: string}) => ({
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    display: 'block',
    marginRight: '8px',
    background: backGroundColor,
  })
);

const StyledCheckText = styled('div')({
  ...flexCenterCss,
});
const StyledCheckBox = styled(Checkbox)(({theme}: {theme: Theme}) => ({
  '&.MuiIconButton-colorPrimary': {
    color: theme.palette.grey[600],
  },
  '&.Mui-checked': {
    color: theme.palette.primary.dark,
  },
}));

/**
 * スマホ用機器詳細のフィルター入力ボックス
 * @returns
 */
export const ProductFilter = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const searchText = useAtomValue(searchTextChangeAtom);
  const defaultProductStatus = productStatusType.filter((v) => v.value !== 'disabled');
  const [productStatus, setProductStatus] = useState(defaultProductStatus);
  const [operatingStatusFilterCheckList, setOperatingStatusFilterCheckListAtom] = useAtom(filterCheckListAtom);

  useEffect(() => {
    if (!searchText || searchText === '') {
      setProductStatus(defaultProductStatus);
      return;
    }
    setProductStatus(defaultProductStatus.filter((v) => v.label.includes(searchText)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    // 選択解除ボタンのDisabledを切り替えるためにチェックしている箇所があるかを判別
    setDisabled(!Object.values(operatingStatusFilterCheckList).some((v) => v === true));
  }, [operatingStatusFilterCheckList]);

  /** 全フィルター解除 */
  const onClickAllDisable = () => {
    const allDisable = Object.keys(operatingStatusFilterCheckList).reduce<{[key: string]: boolean}>((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setOperatingStatusFilterCheckListAtom({...allDisable});
  };

  /** チェック状態ハンドラー */
  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, value: string) => {
    const currentCheck = {...operatingStatusFilterCheckList, [value]: checked};
    setOperatingStatusFilterCheckListAtom(currentCheck);
  };
  return (
    <>
      <StyledHeadTextDiv>
        <Typography>稼働状況で絞り込み</Typography>
        <Button color="primary" disabled={disabled} onClick={onClickAllDisable} style={{fontSize: '16px'}}>
          すべて選択解除
        </Button>
      </StyledHeadTextDiv>
      <StyledCheckBoxDiv>
        {productStatus.map((v, i) => (
          <StyledFilterContentsDiv key={`filter_${i}`}>
            <StyledCheckText>
              <StyledCircle backGroundColor={v.color} />
              {v.label}
            </StyledCheckText>
            <StyledCheckBox
              value={v.value}
              checked={operatingStatusFilterCheckList[v.value] || false}
              color="primary"
              onChange={(e, checked) => handleCheckBox(e, checked, v.value)}
            />
          </StyledFilterContentsDiv>
        ))}
      </StyledCheckBoxDiv>
    </>
  );
};
