import {axios} from '@front-libs/core';
import {TableLayoutKey} from './types';
import {updateTableLayoutSetting} from '@indexedDB/tableLayoutSetting/service';

export type TableLayoutResponse = {
  hashId: string;
  hospitalHashId: string;
  hospitalUserHashId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  layoutName: string;
  currentLayout: string[];
};

/**
 * TableLayoutAPIのURLを返す
 * @param hospitalHashId
 * @param hospitalUserHashId
 * @param layoutName
 * @returns
 */
const getTableLayoutApiUrl = (hospitalHashId: string, hospitalUserHashId: string, layoutName?: TableLayoutKey) => {
  const query = layoutName ? `?layout_name=${layoutName}` : '';
  return `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${hospitalUserHashId}/table_layout_setting${query}`;
};

/**
 * テーブルレイアウトに表示する項目を取得するAPI
 * @param hospitalHashId
 * @param hospitalUserHashId
 * @returns
 */
export const getTableLayout = async (hospitalHashId: string, hospitalUserHashId: string) => {
  const url = getTableLayoutApiUrl(hospitalHashId, hospitalUserHashId);
  const result = await axios.get<TableLayoutResponse[]>(url);
  return result;
};

/**
 * テーブルレイアウトに表示する項目を保存するAPI
 * @param hospitalHashId
 * @param hospitalUserHashId
 * @param layoutName
 * @returns
 */
export const updateTableLayout = async (
  hospitalHashId: string,
  hospitalUserHashId: string,
  layoutName: TableLayoutKey,
  layout: string[]
) => {
  const getTableLayoutURL = getTableLayoutApiUrl(hospitalHashId, hospitalUserHashId);
  const {data} = await axios.post<TableLayoutResponse>(getTableLayoutURL, {layoutName: layoutName, layout: layout});
  // オンライン時はindexedDBの値も更新する
  updateTableLayoutSetting(hospitalHashId, hospitalUserHashId, layoutName, layout);
  return data;
};
