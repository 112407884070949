import React, {useCallback, useEffect, useMemo} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {UserIndex} from '@modules/hospital_users/types';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import Selector from '@molecules/Formik/fields/Selector';
import {Checkbox, TextField} from '@molecules/Formik/fields';
import {yup} from '@front-libs/core';
import {InferType} from 'yup';
import {HospitalUserPermission} from '@modules/auth/consts';
import {SessionExpirationTimes} from '../consts';

const validationSchema = yup.object({
  username: yup.string().required(),
  fullName: yup.string().required(),
  fullNameKana: yup.string(),
  authority: yup.string(),
  canReadManagementDashboard: yup.boolean().optional(),
  sessionExpirationTime: yup.number().min(0),
});

type DialogUser = InferType<typeof validationSchema>;

const HospitalUserForm: React.VFC<EditUserDialogProps> = (props) => {
  const {submitForm, values, isValid, setFieldValue} = useFormikContext<DialogUser>();
  const handleClickResist = useCallback(async () => {
    await submitForm();
    await props.actions.resolve(values);
  }, [props.actions, submitForm, values]);

  useEffect(() => {
    if (values.authority === 'rental') {
      setFieldValue('sessionExpirationTime', 0);
    }
  }, [setFieldValue, values.authority]);

  return (
    <Dialog
      open={props.open}
      onClose={props.actions.reject}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'md'}>
      <DialogTitle>ユーザーを編集</DialogTitle>
      <DialogContent>
        <Grid container>
          <Form style={{width: '100%'}}>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <label>ユーザーID</label>
              <TextField name="username" size={'small'} fullWidth disabled />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <label>氏名（漢字）</label>
              <TextField name="fullName" size={'small'} fullWidth />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <label>氏名（かな）</label>
              <TextField name="fullNameKana" size={'small'} fullWidth />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <label>権限</label>
              <Selector
                name="authority"
                size={'small'}
                options={[
                  {label: '一般ユーザー', value: 'user'},
                  {label: 'ユーザー管理者', value: 'userAdmin'},
                  {label: '貸出・返却のみ', value: 'rental'},
                ]}
              />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <label>自動ログアウト</label>
              <Selector
                name="sessionExpirationTime"
                size="small"
                isDisabled={values.authority === 'rental'}
                options={[
                  {label: '自動ログアウトしない', value: 0},
                  ...SessionExpirationTimes.map((min) => ({
                    label: `自動ログアウトを${min}分後に行う`,
                    value: 60 * min,
                  })),
                ]}
              />
            </div>
            {props.showGrantManagementDashboard && (
              <div style={{width: '100%', marginBottom: '16px'}}>
                <Checkbox
                  name="canReadManagementDashboard"
                  size={'small'}
                  label="経営ダッシュボードの閲覧を許可"
                  checked={values.canReadManagementDashboard}
                />
              </div>
            )}
          </Form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={handleClickResist}>
          変更
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type EditUserDialogProps = {
  defaultHospitalUser: UserIndex;
  showGrantManagementDashboard: boolean;
} & DialogProps;

export const EditUserDialog: React.VFC<EditUserDialogProps> = (props) => {
  const initialValues: DialogUser = useMemo(
    () => ({
      fullName: `${props.defaultHospitalUser.lastName} ${props.defaultHospitalUser.firstName}`,
      fullNameKana: `${props.defaultHospitalUser.lastNameKana} ${props.defaultHospitalUser.firstNameKana}`,
      username: props.defaultHospitalUser.username,
      authority: props.defaultHospitalUser.isShared
        ? 'rental'
        : props.defaultHospitalUser.isUserAdmin
        ? 'userAdmin'
        : 'user',
      canReadManagementDashboard: props.defaultHospitalUser.permissions?.includes(
        HospitalUserPermission.ReadManagementDashboard
      ),
      sessionExpirationTime: props.defaultHospitalUser.sessionExpirationTimeSec ?? 0,
    }),
    [
      props.defaultHospitalUser.firstName,
      props.defaultHospitalUser.firstNameKana,
      props.defaultHospitalUser.isShared,
      props.defaultHospitalUser.isUserAdmin,
      props.defaultHospitalUser.lastName,
      props.defaultHospitalUser.lastNameKana,
      props.defaultHospitalUser.permissions,
      props.defaultHospitalUser.username,
      props.defaultHospitalUser.sessionExpirationTimeSec,
    ]
  );

  const handleSubmit = async (res: DialogUser) => {
    await props.actions.resolve(res);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}>
      <HospitalUserForm {...props} />
    </Formik>
  );
};
