import React, {useCallback, useEffect, useState} from 'react';
import {HomeIndicatorImage} from '@components/molecules/MobileUnderMenu/HomeIndicatorImage';
import {SearchText} from '@components/molecules/SearchBox/sp/searchText';
import {Avatar, Box, Button} from '@material-ui/core';
import {Check} from '@material-ui/icons';
import {styled} from '@material-ui/styles';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {UserIndex} from '@modules/hospital_users/types';
import {useAtom} from 'jotai';
import {selectedInspectorAtom} from './hooks';
import {useEffectOnce} from 'react-use';

const StyledDialogBox = styled(Box)({
  backgroundColor: 'white',
  margin: '0px',
  boxShadow:
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  zIndex: 2,
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  borderRadius: '16px 16px 0px 0px',
});
const BackgroundBlack = styled('div')({
  backgroundColor: 'black',
  opacity: '.5',
  position: 'absolute',
  width: '100%',
  height: '100vh',
  overflow: 'visible',
  left: '0',
  top: '0',
  zIndex: 1,
});

const StyledContainerDiv = styled('div')({
  height: 'auto',
});
const StyledScrollDiv = styled('div')({
  maxHeight: '500px',
  overflowY: 'auto',
});
const StyledMarginDiv = styled('div')({
  width: '100%',
  height: '40px',
});

const StyledCompleteButton = styled(Button)({
  fontSize: '16px',
});

// FIXME:フィルターと選択者を上に表示する対応を合わせるとバグが起きるので、一旦選択者を上に表示する対応をペンディング
// const StyledHr = styled('hr')({
//   color: '#C6CBD4',
//   width: '100%',
//   height: '0px',
//   marginTop: '16px',
//   padding: '0',
// });

const StyledCheck = styled(Check)({
  marginRight: '20px',
  color: '#0052CC',
});
const StyledFlexDiv = styled('div')({
  display: 'flex',
  padding: '4px 8px',
});

const StyledTextButton = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '16px',
  margin: '24px 0px 0px',
  fontSize: '16px',
});

const StyledAvatarText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0px 16px',
  '& .MuiAvatar-root': {
    width: '18px',
    height: '18px',
    marginRight: '8px',
  },
});

/**
 * @param InspectionSheetSelectorProps - InspectionSheetSelectorコンポーネントのPropsの型定義
 * @param inspectionOptions: 点検表のオプションの配列
 * @param completeCallBack:完了時に選択されている点検表のHashIdを返すコールバック
 */
export type InspectorSelectorProps = {
  userIndexList: UserIndex[];
  defaultUser: UserIndex;
  completeCallBack: (userIndex?: UserIndex) => void;
};

type TextElementProps = {
  userIndex: UserIndex;
  selectedUserIndex?: UserIndex;
  handleSelected?: (userIndex: UserIndex) => void;
};

const TextElement = ({userIndex, selectedUserIndex, handleSelected}: TextElementProps) => {
  return (
    <StyledTextButton
      onClick={() => {
        if (handleSelected) handleSelected(userIndex);
      }}>
      <StyledAvatarText>
        <Avatar src={userIndex.thumbnailUrl} variant="circular" />
        {`${UserFormatter.getFullName(userIndex)}`}
      </StyledAvatarText>
      {userIndex.hashId === selectedUserIndex?.hashId && <StyledCheck />}
    </StyledTextButton>
  );
};

type TextButtonListProps = {
  userIndexList: UserIndex[];
  selectedUserIndex?: UserIndex;
  handleSelected: (userIndex: UserIndex) => void;
};
const TextButtonList = ({userIndexList, selectedUserIndex, handleSelected}: TextButtonListProps) => {
  return (
    <StyledScrollDiv>
      {userIndexList.map((v, i) => {
        return (
          <TextElement
            userIndex={v}
            selectedUserIndex={selectedUserIndex}
            handleSelected={handleSelected}
            key={`text${i}`}
          />
        );
      })}
    </StyledScrollDiv>
  );
};

/**
 * 点検者選択するためのコンポーネント
 * 検索機能と点検者の一覧表示機能を提供
 *
 * @param {UserIndex[]} props.userIndexList 対象の点検者の配列
 * @param {UserIndex} props.defaultUser デフォルト点検者
 * @param {(userIndex?: UserIndex) => void} props.completeCallBack 選択後のコールバック関数
 */
export const InspectorSelector = ({userIndexList, defaultUser, completeCallBack}: InspectorSelectorProps) => {
  const [selectedUserIndex, setSelectedUserIndex] = useAtom(selectedInspectorAtom);
  const [currentUserIndexList, setCurrentUserIndexList] = useState<UserIndex[]>(userIndexList);
  const [filterUserIndexList, setFilterUserIndexList] = useState<UserIndex[]>(userIndexList);
  const [searchInspectorName, setSearchInspectorName] = useState('');

  useEffectOnce(() => {
    if (!selectedUserIndex) setSelectedUserIndex(defaultUser);
  });

  const filter = useCallback(() => {
    if (searchInspectorName === '') {
      setCurrentUserIndexList(currentUserIndexList);
    }
    const newUserIndexList = currentUserIndexList.filter((v) =>
      `${v.firstName}${v.lastName}${v.firstNameKana}${v.lastNameKana}`
        .toLocaleLowerCase()
        .includes(searchInspectorName.toLocaleLowerCase())
    );
    setFilterUserIndexList(newUserIndexList);
  }, [currentUserIndexList, searchInspectorName]);

  useEffect(() => {
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInspectorName]);

  useEffect(() => {
    // FIXME:フィルターと選択者を上に表示する対応を合わせるとバグが起きるので、一旦選択者を上に表示する対応をペンディング
    // setCurrentUserIndexList(userIndexList.filter((v) => v.hashId !== selectedUserIndex.hashId));
    // filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserIndex]);

  return (
    <>
      <StyledDialogBox>
        <HomeIndicatorImage />
        <StyledContainerDiv>
          <StyledFlexDiv>
            <SearchText
              searchCallback={(text: string) => {
                setSearchInspectorName(text);
              }}
              placeholderText="点検者を検索"
            />
            <StyledCompleteButton
              color="primary"
              onClick={() => {
                completeCallBack(selectedUserIndex);
              }}>
              完了
            </StyledCompleteButton>
          </StyledFlexDiv>
          {/* FIXME:フィルターと選択者を上に表示する対応を合わせるとバグが起きるので、一旦選択者を上に表示する対応をペンディング */}
          {/* <StyledContainerDiv>
            <TextElement userIndex={selectedUserIndex} selectedUserIndex={selectedUserIndex} />
          </StyledContainerDiv> */}
        </StyledContainerDiv>
        {/* <StyledHr /> */}
        <StyledContainerDiv>
          <TextButtonList
            userIndexList={filterUserIndexList}
            selectedUserIndex={selectedUserIndex}
            handleSelected={setSelectedUserIndex}
          />
        </StyledContainerDiv>
        <StyledMarginDiv />
      </StyledDialogBox>
      <BackgroundBlack />
    </>
  );
};
