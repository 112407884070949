import React, {useCallback, useMemo} from 'react';
import FormControl, {FormControlProps} from '@material-ui/core/FormControl';
import Select, {Props} from 'react-select';
import {theme} from '@atoms/theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Option<T = any> = {
  label: string;
  value: T;
};

type CustomProps = {
  value: string | number | boolean | null;
  options: Option[];
  formControlProps?: FormControlProps;
};

export type SelectProps = Omit<Props, 'options' | 'value'> & CustomProps;

export const Selector: React.FC<SelectProps> = (props) => {
  const {value, options, onChange, styles, formControlProps, ...rest} = props;

  const actualValue = useMemo(
    () => (value !== undefined && value !== null ? options.find((option) => option.value === value) : null),
    [options, value]
  );

  const handleChange = useCallback(
    (option, action) => {
      if (onChange) onChange(option, action);
    },
    [onChange]
  );

  return (
    <div>
      <FormControl variant="outlined" size={'small'} fullWidth {...formControlProps}>
        <Select
          {...rest}
          menuPortalTarget={document.body}
          styles={{
            ...styles,
            menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value={actualValue as any}
          options={options}
          onChange={handleChange}
          placeholder={'検索...'}
        />
      </FormControl>
    </div>
  );
};
