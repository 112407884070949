import {CategoryIndex, CategoryTrees} from './types';

export const CategoryFormatter = {
  getOptions(categories: CategoryIndex[]) {
    return categories.map((cate) => ({
      label: cate.name,
      value: cate.hashId,
    }));
  },
  getRootCategory(categories: CategoryIndex[]) {
    return categories.find((item) => item.depth === 0);
  },
  getNarrowCategory(categories: CategoryIndex[]) {
    return categories.find((item) => item.depth === 1);
  },
  getCategoryByName(categories: CategoryIndex[], name: string) {
    return categories.find((item) => item.name === name);
  },
};

export const getRootCategoryHashId = (categoryTrees: CategoryTrees, rootCategory: string) => {
  const foundTree = categoryTrees.find((tree) => {
    return tree.rootCategory.name === rootCategory;
  });

  return foundTree ? foundTree.rootCategory.hashId : undefined;
};
