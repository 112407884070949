import React, {useCallback, useEffect, useRef, useState} from 'react';
import {papers} from '../consts';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import {makeStyles} from '@material-ui/core/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {UpdatePaperType, useUpdateDashboardSubject} from '../hooks';
import {Button, Grid, Paper} from '@material-ui/core';
import {PaperHeader} from '../PaperHeader';
import {useNoticeBoard} from './hooks';
import {isNullish} from '@front-libs/helpers';

export const NoticeBoard = () => {
  const paper = papers.noticeBoard;
  const [isEditable, setIsEditable] = useState(false);
  const editorRef = useRef<Editor>(null);
  const {currentNoticeBoardQuery, noticeBoardMutation} = useNoticeBoard();

  useEffect(() => {
    if (currentNoticeBoardQuery.isSuccess && !isNullish(currentNoticeBoardQuery.data?.content)) {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(currentNoticeBoardQuery.data.content))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNoticeBoardQuery.isSuccess]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const {subscribe, unsubscribe} = useUpdateDashboardSubject();

  const classes = useStyles();

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      if (paperType !== 'all' && paperType !== 'notice_board') return;
      currentNoticeBoardQuery.refetch();
    },
    [currentNoticeBoardQuery]
  );

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.paper}>
      <PaperHeader title={paper.label} settingMenuItems={paper.settingMenuItems} />
      <Grid container className={classes.root}>
        <div style={{width: '100%', height: '100%'}}>
          <div style={{width: '100%', height: 'calc(100% - 48px)'}}>
            <Editor
              ref={editorRef}
              readOnly={!isEditable}
              toolbarHidden={!isEditable}
              wrapperClassName={isEditable ? classes.editorWrapper : classes.readOnlyEditorWrapper}
              editorClassName={classes.editor}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                options: ['inline', 'colorPicker'],
                inline: {
                  options: ['bold', 'underline', 'strikethrough'],
                },
                colorPicker: {
                  popupClassName: classes.colorPicker, // テキスト、ハイライトの選択肢を非表示にする
                },
              }}
            />
          </div>
          <div className={classes.toolbar}>
            <Grid container justifyContent={'flex-end'}>
              <Grid item style={{marginRight: 8}}>
                {isEditable ? (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      setIsEditable(!isEditable);
                      noticeBoardMutation({
                        content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                      });
                    }}>
                    保存
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      setIsEditable(!isEditable);
                      (editorRef.current as Editor).focusEditor();
                    }}>
                    編集
                  </Button>
                )}
              </Grid>
              {isEditable && (
                <Grid item>
                  <Button
                    onClick={() => {
                      setIsEditable(false);
                      if (!isNullish(currentNoticeBoardQuery.data?.content)) {
                        setEditorState(
                          EditorState.createWithContent(
                            convertFromRaw(JSON.parse(currentNoticeBoardQuery.data.content))
                          )
                        );
                      } else {
                        setEditorState(EditorState.createEmpty());
                      }
                    }}>
                    キャンセル
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0px 16px 16px',
    borderTop: `4px solid ${theme.palette.primary.dark}`,
    height: 'calc(100% - 20px)',
  },
  root: {
    paddingBottom: '40px',
    height: '100%',
  },
  toolbar: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
  },
  colorPicker: {
    '& .rdw-colorpicker-modal-header': {
      display: 'none',
    },
  },
  toolbarButton: {
    marginRight: theme.spacing(2),
  },
  readOnlyEditorWrapper: {
    width: '100%',
    height: '100%',
  },
  editorWrapper: {
    width: '100%',
    height: '100%',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  editor: {
    paddingRight: 8,
    paddingLeft: 8,
    width: '100%',
    height: 'calc(100% - 56px)',
  },
}));
