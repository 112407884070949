import {Container} from '@material-ui/core';
import {ImportExport} from '@material-ui/icons';
import React, {memo, useMemo} from 'react';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {MobileSearchBar} from '@molecules/SearchBar';
import arrow_up from '@assets/arrow_up.svg';
import arrow_down from '@assets/arrow_down.svg';
import {styled} from '@material-ui/styles';

type ToolBarProp = {
  searchName: string | null;
  selectedValue: string;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
  onChangeOrderChange: (item: MenuItemType) => void;
};

const StyledContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 8px 8px 16px',
  '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: '8px',
  },
  // ２番目のdiv要素に適用
  '& div:nth-of-type(2)': {
    width: '24px',
    padding: '4px',
  },
});

const StyledSortIcon = styled(ImportExport)({
  height: '24px',
  width: '24px',
  fill: ' #172B4D',
});

const StyledPopperMenuButton = styled(PopperMenuButton)({
  width: '24px',
  '.MuiButton-root': {
    width: '48px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const StyledImage = styled('img')({
  width: '8px',
  objectFit: 'contain',
  loading: 'lazy',
  decoding: 'async',
});
const StyledSpan = styled('span')({
  paddingLeft: 8,
});

const menuItemTitleList = [
  {
    title: '管理番号の昇順',
    value: 'management_id',
    src: arrow_up,
  },
  {
    title: '管理番号の降順',
    value: '-management_id',
    src: arrow_down,
  },
  {
    title: '機種名の昇順',
    value: 'display_name',
    src: arrow_up,
  },
  {
    title: '機種名の降順',
    value: '-display_name',
    src: arrow_down,
  },
  {
    title: '型式の昇順',
    value: 'name',
    src: arrow_up,
  },
  {
    title: '型式の降順',
    value: '-name',
    src: arrow_down,
  },
];

const createMenuListElement = (src: string, title: string) => {
  return (
    <>
      <StyledImage src={src} alt={title} />
      <StyledSpan>{title}</StyledSpan>
    </>
  );
};
/**
 * 検索バーとソートボタン
 * @param param0
 * @returns
 */
export const ToolBar = memo(({searchName, selectedValue, onChangeSearchName, onChangeOrderChange}: ToolBarProp) => {
  const menuItemList = useMemo(() => {
    return menuItemTitleList.map((v) => ({
      label: createMenuListElement(v.src, v.title),
      value: v.value,
      selectedMenuItem: v.value === selectedValue,
    }));
  }, [selectedValue]);

  return (
    <StyledContainer>
      <MobileSearchBar searchName={searchName} onChangeSearchName={onChangeSearchName} />
      <StyledPopperMenuButton
        menuItemList={menuItemList}
        onMenuClick={onChangeOrderChange}
        hiddenArrow
        buttonProps={{variant: 'text', size: 'small', disableElevation: true}}>
        <StyledSortIcon />
      </StyledPopperMenuButton>
    </StyledContainer>
  );
});
