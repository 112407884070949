import {useCallback} from 'react';
import {ZoneIdType} from '@Apps/Channel/consts';

export type UpdateChannelType = 'all' | ZoneIdType[];
export type ChannelUpdateObserver = (paperType: UpdateChannelType) => void | Promise<void>;

class UpdateChannelSubject {
  public observers: ChannelUpdateObserver[];

  constructor() {
    this.observers = [];
  }

  public subscribe(func: ChannelUpdateObserver) {
    this.observers.push(func);
  }

  public unsubscribe(func: ChannelUpdateObserver) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notify(data: UpdateChannelType) {
    this.observers.forEach((observer) => observer(data));
  }
}

export const updateChannelSubject = new UpdateChannelSubject();

export const useUpdateChannelSubject = () => {
  const subscribe = useCallback((func: ChannelUpdateObserver) => {
    updateChannelSubject.subscribe(func);
  }, []);
  const unsubscribe = useCallback((func: ChannelUpdateObserver) => {
    updateChannelSubject.unsubscribe(func);
  }, []);
  const notify = useCallback((data: UpdateChannelType) => {
    updateChannelSubject.notify(data);
  }, []);
  return {subscribe, unsubscribe, notify};
};
