import {Box, Typography, makeStyles} from '@material-ui/core';
import React from 'react';

export const ResultDetailHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.settingBar}>
      <Typography className={classes.settingBarTxt}>点検項目</Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  settingBar: {
    display: 'flex',
  },
  settingBarTxt: {
    padding: '8px 16px',
    borderBottom: '2px solid #172B4D',
    fontWeight: 'bold',
    color: '#172B4D',
  },
}));
