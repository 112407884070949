import React from 'react';
import {Selector} from '@molecules/Selector';

type Props = {
  selectValue: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: {label: string; value: string; [key: string]: any}) => void;
  columnOptions: {
    label: string;
    value: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }[];
};

export const PropertySelectorColumn: React.FC<Props> = ({selectValue, columnOptions, onChange}) => {
  return (
    <Selector
      value={selectValue}
      options={columnOptions}
      onChange={onChange}
      formControlProps={{style: {minWidth: 200, width: 200}}}
    />
  );
};
