import React from 'react';
import {FieldProps} from 'formik';
import BaseTextField, {TextFieldProps} from '@material-ui/core/TextField';
import {makeStyles, Theme} from '@material-ui/core/styles';
import wrapField from './wrapField';

type Props = {
  containerType?: 'block' | 'inline';
  variant?: 'filled' | 'standard' | 'outlined';
} & FieldProps<string> &
  Omit<TextFieldProps, 'variant' | 'className'>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '42%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '84%',
    },
  },
  textField: {
    minWidth: '92%',
  },
}));

export function TextField(props: Props) {
  const {containerType = 'block', field, ...rest} = props;
  const classes = useStyles();

  return containerType === 'block' ? (
    <div className={classes.root}>
      <BaseTextField {...rest} {...field} className={classes.textField} variant={props.variant ?? 'outlined'} />
    </div>
  ) : (
    <BaseTextField {...rest} {...field} className={classes.textField} variant={props.variant ?? 'outlined'} />
  );
}

export default wrapField(TextField);
