import React, {useCallback} from 'react';
import {createStyles, makeStyles, Theme, Grid, Button, Typography} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {useFormikContext} from 'formik';
import {FormValue} from '@modules/hospital_ward_layout_setting/types';
import {useAtomValue} from 'jotai';
import {radioAtom} from '../states';
import {DEFAULT_ORDER} from './consts';

type HeaderTopProps = {
  handleClickCancel: () => void;
};

export const HeaderTop = ({handleClickCancel}: HeaderTopProps) => {
  const {submitForm} = useFormikContext<FormValue>();
  const radio = useAtomValue(radioAtom);
  const classes = useStyles();
  const navigate = useNavigate();

  const onClickPrevButton = useCallback(() => {
    navigate('/shared');
  }, [navigate]);

  const handleClickUpdate = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const isValid = radio === DEFAULT_ORDER;

  return (
    <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.root}>
      <Grid item>
        <Grid container>
          <Grid item xs container direction="column">
            <Button color="inherit" className={classes.prevButton} onClick={onClickPrevButton}>
              <ChevronLeft />
              <span>貸出・返却トップへ戻る</span>
            </Button>
            <Typography variant="h1" className={classes.pageTitle}>
              エリアボタンの並び替え
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <Button
            variant={'contained'}
            color={'primary'}
            className={classes.updateButton}
            disabled={isValid}
            onClick={handleClickUpdate}>
            変更を保存
          </Button>
          <Button variant={'text'} color={'primary'} disabled={isValid} onClick={handleClickCancel}>
            変更をキャンセル
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '24px 32px 12px 24px',
      backgroundColor: theme.palette.common.white,
    },
    prevButton: {
      width: '200px',
      padding: '0px',
      justifyContent: 'flex-start',
      color: theme.palette.rent.main,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    pageTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#172B4D',
      padding: '10px 0px 4px 24px',
    },
    topActions: {
      marginLeft: 'auto',
      height: 'auto',
    },
    updateButton: {
      marginRight: 16,
    },
  })
);
