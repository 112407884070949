import React, {useCallback, useMemo, useState} from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Divider,
  Typography,
  Button,
  Box,
  Switch,
  FormHelperText,
} from '@material-ui/core';
import {Sidebar} from '@components/organisms/Sidebar';
import {InnerLoading} from '@molecules/Loading';
import {Form, Formik, useFormikContext} from 'formik';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {openSnackBar} from '@molecules/SnackBar';
import {useFaultRepairPropertyRequirements, useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {formSection} from '@Apps/RepairDetail/constants';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';
import {FetchFaultRepairPropertyRequrementsParams, PropertyRequirements, RepairIndex} from '@modules/repairs/types';
import Selector from '@molecules/Formik/fields/Selector';
import {
  createFaultRepairPropertyRequirement,
  deleteFaultRepairPropertyRequirement,
  resequenceFaultRepairPropertyRequirements,
  updateFaultRepairPropertyRequirement,
} from '@modules/repairs/api/propertyRequirementsApi';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {ExtendedPropertyRequirements, GeneratedFieldObjectsType, ItemListType, ItemProps, ItemType} from './types';
import clsx from 'clsx';

export const SettingsHospitalRepair: React.FC = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <RepairContainer>
        <RepairForm />
      </RepairContainer>
    </Grid>
  );
};

const RepairContainer: React.FC = ({children}) => {
  const {myInfo} = useMyInfo();
  const statusQuery = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const startStatusHashId = useMemo(
    () => statusQuery.data.find((status) => status.statusType === 'start')?.hashId,
    [statusQuery]
  );

  const params: FetchFaultRepairPropertyRequrementsParams = {
    faultRepairStatusHashID: startStatusHashId,
    order: 'sequence',
  };
  const {data, isLoading, refetch} = useFaultRepairPropertyRequirements(params);

  const handleSubmit = async (res: ExtendedPropertyRequirements) => {
    try {
      if (res.propertyName && startStatusHashId) {
        await createFaultRepairPropertyRequirement(myInfo.hospitalHashId, {
          faultRepairStatusHashId: startStatusHashId,
          property: res.propertyName,
        });
      }
      if (res.targetProperty) {
        await resequenceFaultRepairPropertyRequirements(
          myInfo.hospitalHashId,
          data?.find((d) => d.property === res.targetProperty?.property)?.hashId ?? '',
          {sequence: res.targetProperty.newSequence}
        );
      }
      if (res.switchedProperty) {
        await updateFaultRepairPropertyRequirement(
          myInfo.hospitalHashId,
          data?.find((d) => d.property === res.switchedProperty?.property)?.hashId ?? '',
          {isRequired: res.switchedProperty.isRequired}
        );
      }
      if (res.deleteProperty) {
        await deleteFaultRepairPropertyRequirement(
          myInfo.hospitalHashId,
          data?.find((d) => d.property === res.deleteProperty?.property)?.hashId ?? ''
        );
      }

      await refetch();
      openSnackBar('修理の設定を更新しました');
    } catch (error) {
      openSnackBar('修理の設定の更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  if (isLoading) {
    return <InnerLoading />;
  }

  return (
    <Formik initialValues={data as unknown as PropertyRequirements[]} onSubmit={handleSubmit} enableReinitialize={true}>
      {children}
    </Formik>
  );
};

const RepairForm: React.FC = () => {
  const classes = useStyles();
  const templateClasses = useSettingsContentTemplate();
  const {hospitalUsers} = useHospitalUsers();
  const {submitForm, values, setFieldValue, isValid} = useFormikContext<ExtendedPropertyRequirements>();
  const sections = formSection(hospitalUsers);
  const generatedFieldObjects = useMemo(() => {
    const result: GeneratedFieldObjectsType = {options: [], items: []};

    for (const section of sections) {
      for (const field of section.fields) {
        if (field.name === 'symptomDetailCategory') {
          continue; // 事象詳細区分（内部故障）は事象区分が内部故障として選択されたときのみ表示するため、オプションから除外する
        }

        const correspondingRequirement = values.find((val) => val.property === field.name);

        if (correspondingRequirement) {
          result.items.push({
            label: field.label,
            value: correspondingRequirement.property,
            isRequired: correspondingRequirement.isRequired,
            sequence: correspondingRequirement.sequence,
          });
        } else {
          result.options.push({
            label: field.label,
            value: field.name,
          });
        }
      }
    }

    // ステータスはformSectionに含まれていないため、ここで追加
    const faultRepairStatus = values.find((val) => val.property === 'statusHashId');
    if (faultRepairStatus) {
      result.items.push({
        label: 'ステータス',
        value: faultRepairStatus.property,
        isRequired: faultRepairStatus.isRequired,
        sequence: faultRepairStatus.sequence,
      });
    } else {
      result.options.push({
        label: 'ステータス',
        value: 'statusHashId',
      });
    }

    result.items.sort((a, b) => a.sequence - b.sequence);

    return result;
  }, [sections, values]);

  const [items, setItems] = useState(generatedFieldObjects.items as ItemType[]);
  const [selectedOption, setSelectedOption] = useState(null);

  const reorder = useCallback((list: ItemType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      if (result.destination.index === result.source.index) {
        return;
      }

      const newItems = reorder(items, result.source.index, result.destination.index);
      setItems(newItems);
      setFieldValue('targetProperty', {property: result.draggableId, newSequence: result.destination.index + 1});
      setSelectedOption(null);
      submitForm();
    },
    [items, reorder, setFieldValue, submitForm]
  );

  const handleAddingProperty = useCallback(() => {
    const newItem = {
      value: values.propertyName as keyof RepairIndex,
      label: generatedFieldObjects.options.find((option) => option.value === values.propertyName)?.label ?? '',
      isRequired: false,
    };
    setItems((prevItems) => [...prevItems, newItem]);
    setSelectedOption(null);
    submitForm();
  }, [generatedFieldObjects.options, submitForm, values.propertyName]);

  const handleSwitchIsRequired = useCallback(
    (property: string, isRequired: boolean) => {
      setItems(items.map((item) => (item.value === property ? {...item, isRequired: isRequired} : item)));
      setFieldValue('switchedProperty', {property: property, isRequired: isRequired});
      setSelectedOption(null);
      submitForm();
    },
    [items, setFieldValue, submitForm]
  );

  const handleDelete = useCallback(
    (property: string) => {
      setItems(items.filter((item) => item.value !== property));
      setFieldValue('deleteProperty', {property: property});
      setSelectedOption(null);
      submitForm();
    },
    [items, setFieldValue, submitForm]
  );

  return (
    <Form className={templateClasses.form}>
      <Grid container className={templateClasses.grid}>
        <Grid item className={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item className={clsx(templateClasses.content, classes.content)}>
          <Grid container>
            <Grid item>
              <Typography variant={'h5'} className={templateClasses.pageTitle}>
                修理
              </Typography>
              <p>修理に関するユーザー共通設定を管理します。</p>
            </Grid>
            <div className={templateClasses.flex} />
          </Grid>
          <Divider variant="middle" className={templateClasses.divider} />
          <Grid container>
            <Grid item>
              <Typography variant={'h6'} className={templateClasses.pageSubTitle1}>
                セットアップ
              </Typography>
              <Box className={classes.selectorTitleContainer}>
                <Typography>修理登録の項目管理</Typography>
                <FormHelperText className={classes.formHelperText}>
                  修理登録を行う項目について、登録項目の管理を行います。
                </FormHelperText>
              </Box>
              <Box className={classes.selectorContainer}>
                <Selector
                  className={classes.selectBox}
                  name="propertyName"
                  size="large"
                  options={generatedFieldObjects.options}
                  onChange={setSelectedOption}
                />
                <Button
                  disabled={!isValid || generatedFieldObjects.options.length === 0 || selectedOption === null}
                  variant={'contained'}
                  color="primary"
                  onClick={handleAddingProperty}
                  className={classes.addBtn}>
                  追加
                </Button>
              </Box>
              <Box className={classes.subtitle} style={items.length === 0 ? {display: 'none'} : undefined}>
                <Typography>表示する項目</Typography>
                <Typography>必須</Typography>
              </Box>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <ItemList items={items} onSwitchClick={handleSwitchIsRequired} onDeleteClick={handleDelete} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

const ItemList: React.FC<ItemListType> = ({items, onSwitchClick, onDeleteClick}) => (
  <>
    {items.map((item, index) => (
      <DraggableItem
        item={item}
        index={index}
        key={item.value + index}
        onSwitchClick={onSwitchClick}
        onDeleteClick={onDeleteClick}
      />
    ))}
  </>
);

const DraggableItem: React.FC<ItemProps> = ({item, index, onSwitchClick, onDeleteClick}) => {
  const classes = useStyles();

  const handleOnChange = useCallback(() => {
    onSwitchClick(item.value, !item.isRequired);
  }, [item.isRequired, item.value, onSwitchClick]);

  const handleOnClick = useCallback(() => {
    onDeleteClick(item.value);
  }, [item.value, onDeleteClick]);
  return (
    <Draggable draggableId={item.value} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box className={classes.itemContainer}>
            <Box className={classes.labelContainer}>
              <DragIndicatorIcon />
              {item.label}
            </Box>
            <Box>
              <Switch
                checked={item.isRequired}
                onChange={handleOnChange}
                color="primary"
                name={item.value}
                inputProps={{'aria-label': 'primary checkbox'}}
              />
              <Button variant={'contained'} className={classes.deleteBtn} onClick={handleOnClick}>
                削除
              </Button>
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      height: 'auto',
    },
    selectorTitleContainer: {
      marginTop: 24,
    },
    selectorContainer: {
      display: 'flex',
    },
    selectBox: {
      height: 32,
      width: 328,
    },
    addBtn: {
      marginLeft: 20,
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 8,
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: 280,
    },
    deleteBtn: {
      marginLeft: 12,
      backgroundColor: theme.palette.grey[50],
    },
    subtitle: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 'calc(328px - 12px)',
      padding: '20px 6px 12px',
    },
    formHelperText: {
      fontSize: 14,
      color: theme.palette.grey[600],
      margin: '9px 0px 9px',
    },
  })
);
