import React from 'react';
import {Accordion} from '@components/molecules/Accordion';
import {useStoredAccordionSetting} from '@modules/localstorage';

type Props = {
  settingKey: string;
  children: React.ReactNode;
};

export const CollapseHeader = (props: Props) => {
  const {children, settingKey} = props;

  const accordionSettingProps = useStoredAccordionSetting(settingKey);

  return <Accordion {...accordionSettingProps}>{children}</Accordion>;
};
