import React, {useMemo} from 'react';
import {Grid, makeStyles, Paper, Theme} from '@material-ui/core';
import dayjs from 'dayjs';
import {HistoryCardProps} from './HistoryCard';
import {UserFormatter} from '@modules/hospital_users/helpers';

export const StatusChangeCard: React.VFC<HistoryCardProps> = ({user, displayDate, contentSlot}) => {
  const classes = useHistoryCardStyles();
  const contentBody = useMemo(
    () =>
      contentSlot &&
      `${user ? `${UserFormatter.getFullName(user)}さん` : '不明なユーザー'}によって、この修理のステータスは${
        contentSlot[0].label
      }から${contentSlot[1].label}に変更されました。`,
    [contentSlot, user]
  );
  return (
    <Paper className={classes.root}>
      <Grid container alignItems={'center'} className={classes.cardHeader}>
        <Grid item className={classes.boldText}>
          ステータスの変更
        </Grid>
        <div className={classes.flex} />
        <Grid item className={classes.displayDate}>
          {dayjs(displayDate).format('YYYY年MM月DD日 HH:mm')}
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} className={classes.propertyContainer}>
        <Grid item>{contentBody}</Grid>
      </Grid>
    </Paper>
  );
};

const useHistoryCardStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
    width: '100%',
  },
  cardHeader: {
    paddingTop: 8,
    paddingLeft: 16,
  },
  flex: {
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 700,
  },
  displayDate: {
    color: theme.palette.grey[600],
  },
  propertyContainer: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingBottom: 8,
  },
  property: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.common.black,
  },
  propertyLabel: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.grey[600],
  },
}));
