import {atom, useAtom} from 'jotai';
import {useCallback, useState} from 'react';

export const stepLabels = ['メール・パスワード設定', 'ユーザー情報設定'] as const;

const currentStepAtom = atom(0);
const completedStepsAtom = atom<number[]>([]);

export const useSteps = () => {
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [completedSteps, setCompletedStep] = useAtom(completedStepsAtom);
  const [completeAll, setCompleteAll] = useState(false);
  const addCompletedStep = useCallback(
    (completeStepNumber: number) => {
      if (completedSteps.includes(completeStepNumber)) return;
      setCompletedStep([...completedSteps, completeStepNumber].sort());
    },
    [completedSteps, setCompletedStep]
  );
  const next = useCallback(
    (completeCurrentStep: boolean) => {
      setCurrentStep(currentStep + 1);
      if (completeCurrentStep) {
        addCompletedStep(currentStep);
      }
      if (stepLabels.length - 1 === currentStep) {
        setCompleteAll(true);
      }
    },
    [addCompletedStep, currentStep, setCurrentStep]
  );

  const previous = useCallback(
    (cancelCompleteCurrentStep: boolean) => {
      if (currentStep === 0) return;

      setCurrentStep(currentStep - 1);
      if (cancelCompleteCurrentStep) {
        addCompletedStep(currentStep);
        setCompleteAll(false);
      }
    },
    [addCompletedStep, currentStep, setCurrentStep]
  );

  const changeStep = useCallback((stepNumber: number) => setCurrentStep(stepNumber), [setCurrentStep]);

  return {currentStep, completedSteps, completeAll, addCompletedStep, next, previous, changeStep};
};
