import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {fetchCostTrendsByCostCategory, fetchCostTrendsByNarrowCategory, fetchCostTrendsByRootCategory} from './api';
import {
  getHospitalProductCostTrendsByCostCategoryKey,
  getHospitalProductCostTrendsByRootCategoryKey,
} from '@constants/api';
import {TimePeriodType} from './type';
import dayjs from 'dayjs';
import {isNullish} from '@front-libs/helpers';

export type FetchCostTrendsByCostCategoryParams = {
  displayTimePeriod: TimePeriodType;
  paymentDateTo: Date;
};

export const useFetchCostTrendsByCostCategory = (
  hospitalHashId: string,
  params: FetchCostTrendsByCostCategoryParams
) => {
  const query = useQuery([getHospitalProductCostTrendsByCostCategoryKey, hospitalHashId, params], () =>
    fetchCostTrendsByCostCategory(hospitalHashId, {
      ...params,
      paymentDateTo: dayjs(params.paymentDateTo).format('YYYY-MM-DD'),
    })
  );
  return useMemo(() => ({...query, data: query.data}), [query]);
};

export type FetchCostTrendsByRootCategoryParams = {
  displayTimePeriod: TimePeriodType;
  paymentDateTo: Date;
};

export const useFetchCostTrendsByRootCategory = (
  hospitalHashId: string,
  params: FetchCostTrendsByRootCategoryParams
) => {
  const query = useQuery([getHospitalProductCostTrendsByRootCategoryKey, hospitalHashId, params], () =>
    fetchCostTrendsByRootCategory(hospitalHashId, {
      ...params,
      paymentDateTo: dayjs(params.paymentDateTo).format('YYYY-MM-DD'),
    })
  );
  return useMemo(() => ({...query, data: query.data}), [query]);
};

export type FetchCostTrendsByNarrowCategoryParams = {
  displayTimePeriod: TimePeriodType;
  paymentDateTo: Date;
  rootCategoryHashId?: string;
};

export const useFetchCostTrendsByNarrowCategory = (
  hospitalHashId: string,
  params: FetchCostTrendsByNarrowCategoryParams
) => {
  const query = useQuery(
    [getHospitalProductCostTrendsByRootCategoryKey, hospitalHashId, params],
    () =>
      fetchCostTrendsByNarrowCategory(hospitalHashId, {
        ...params,
        paymentDateTo: dayjs(params.paymentDateTo).format('YYYY-MM-DD'),
        rootCategoryHashId: params.rootCategoryHashId ?? '',
      }),
    {
      enabled: !isNullish(params.rootCategoryHashId),
    }
  );
  return useMemo(() => ({...query, data: query.data}), [query]);
};
