import React from 'react';
import {createStyles, makeStyles, Theme, Link, Grid} from '@material-ui/core';
import {InspectionProductWithWholeProduct} from '@modules/inspection_setting/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  })
);

export const InspectionProductsByColumn: React.VFC<{
  inspectionProduct: InspectionProductWithWholeProduct[];
  handleClickEditProducts: (_e: React.MouseEvent, data: InspectionProductWithWholeProduct[]) => Promise<void>;
}> = (props) => {
  const {inspectionProduct, handleClickEditProducts} = props;
  const classes = useStyles();

  if (!inspectionProduct || inspectionProduct.length === 0) {
    return (
      <Link onClick={(e) => handleClickEditProducts(e, inspectionProduct)} className={classes.name}>
        未割当
      </Link>
    );
  }

  return (
    <Grid item xs container direction="column">
      {inspectionProduct?.map((product, index) => (
        <Grid key={product.hashId}>
          {index === 0 && (
            <Link onClick={(e) => handleClickEditProducts(e, inspectionProduct)} className={classes.name}>
              {product.wholeProduct?.displayName}
              {inspectionProduct.length > 1 && <Grid>{`(他${inspectionProduct.length - 1}製品)`}</Grid>}
            </Link>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
