import React from 'react';
import {createStyles, Grid, makeStyles, Theme, Link, Typography} from '@material-ui/core';
import {InspectionStatus as InspectionStatusIndicator} from '@components/molecules/InspectionStatus';
import {InspectionStatus} from '@modules/inspections/enum';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  })
);

export type InspectionNameColumnProps = {
  name: {
    name: React.ReactNode;
    status: InspectionStatus;
  };
  onClick?: () => void;
};

export const InspectionNameColumn: React.VFC<InspectionNameColumnProps> = (props) => {
  const {
    name: {name, status},
  } = props;
  const classes = useStyles();

  return (
    <Grid item xs container direction="column">
      <Link className={classes.name} onClick={props.onClick}>
        {name}
      </Link>
      <Typography style={{color: '#7C98B6', fontSize: '0.875rem'}}>
        <InspectionStatusIndicator status={status as 'available' | 'draft'} />
      </Typography>
    </Grid>
  );
};
