import React, {useState, useCallback, useMemo} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {DateRangeConditionValue} from '../types';
import {DatePicker} from '@components/molecules/DatePicker';
import {minDate, maxDate} from '@front-libs/helpers';

type Props = {
  label: string;
  value: string;
  optionType: 'dateRange';
  min?: Date;
  max?: Date;
  onSubmit: (options: DateRangeConditionValue) => void;
  onChange: (options: DateRangeConditionValue) => void;
};

export const FilterDrawerDateRangeField: React.VFC<Props> = (props) => {
  const {min, max, onChange} = props;
  const classes = useStyles();
  const [selected, setSelected] = useState<DateRangeConditionValue>({});

  const handleChangeCompletedAtFrom = useCallback(
    (d: Date | null) => {
      // eslint-disable-next-line no-shadow
      const newValue = {
        ...selected,
        from: d ?? undefined,
      };

      onChange(newValue);
      setSelected(newValue);
    },
    [onChange, selected]
  );

  const handleChangeCompletedAtTo = useCallback(
    (d: Date | null) => {
      // eslint-disable-next-line no-shadow
      const newValue = {
        ...selected,
        to: d ?? undefined,
      };

      onChange(newValue);
      setSelected(newValue);
    },
    [onChange, selected]
  );

  const fromMaxDate = useMemo(() => {
    const rightBounds = [max, selected.to].filter((d): d is Date => d !== undefined);
    return rightBounds.length > 0 ? minDate(...rightBounds) : undefined;
  }, [max, selected.to]);

  const toMinDate = useMemo(() => {
    const leftBounds = [min, selected.from].filter((d): d is Date => d !== undefined);
    return leftBounds.length > 0 ? maxDate(...leftBounds) : undefined;
  }, [min, selected.from]);

  return (
    <Grid container className={classes.root}>
      <DatePicker
        value={selected.from ?? null}
        placeholder="日付を選択"
        onChange={handleChangeCompletedAtFrom}
        minDate={min}
        maxDate={fromMaxDate}
      />
      <span className={classes.dateDivider}>から</span>
      <DatePicker
        value={selected.to ?? null}
        placeholder="日付を選択"
        onChange={handleChangeCompletedAtTo}
        minDate={toMinDate}
        maxDate={max}
      />
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  dateDivider: {
    margin: '0px 8px',
  },
}));
