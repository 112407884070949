import {useMemo} from 'react';
import {axios} from '@front-libs/core';
import {atom} from 'jotai';
import {useQuery} from 'react-query';
import {FetchChannelsParams} from './types';

import {getChannelKey} from '@constants/api';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {UserIndex} from '@modules/hospital_users/types';
import {WholeProductIndex} from '@modules/products/types';
import {CategoryIndex} from '../categories/types';

type ChannelInfo = {
  channelHashId: string;
  channelNumber: number;
  zoneIds: number[];
  primaryZoneId: number;
  // FIXME: wholeProduct追加に伴いproductNameは削除可能。削除すると影響範囲が大きくなることから一旦残している。
  productName?: string;
  floorNumber?: number;
  isGroundFloor?: boolean;
  hospitalWardName?: string;
  hospitalWardHashId?: string;
  hospitalProduct?: HospitalProductDetail;
  wholeProduct?: WholeProductIndex & CategoryIndex[];
};

export type FetchChannelsResult = {
  totalCount: number;
  page: number;
  data: ChannelInfo[];
};

const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/users/me`;
export const fetchMyInfo = atom(async () => {
  const res = await axios.get<UserIndex>(baseUrl);
  return res.data;
});

const fetchChannelInfo = async (hospitalHashId: string, channelHashId: string) => {
  const channelUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/channels/${channelHashId}/product`;
  const res = await axios.get<HospitalProductDetail>(channelUrl);
  return res.data;
};

export const useFetchChannelInfo = (hospitalHashId: string, channelHashId: string) => {
  const query = useQuery([getChannelKey, hospitalHashId, channelHashId], () =>
    fetchChannelInfo(hospitalHashId, channelHashId)
  );
  return useMemo(
    () => ({
      ...query,
      hospitalProduct: query.data ?? {},
    }),
    [query]
  );
};

export const fetchChannelsInfo = async (hospitalHashId: string, params: FetchChannelsParams) => {
  const channelUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/channels`;
  const res = await axios.get<FetchChannelsResult>(channelUrl, {params});
  return res.data;
};

export const useFetchChannelsInfo = (hospitalHashId: string, params: FetchChannelsParams = {page: 0, perPage: 480}) => {
  const query = useQuery([getChannelKey, hospitalHashId, params?.zoneId], () =>
    fetchChannelsInfo(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      channels: query.data?.data ?? [],
    }),
    [query]
  );
};

type AssignChannelParams = {
  channelHashId: string;
  hospitalRoomHashId?: string;
};

export const assignChannel = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  data: AssignChannelParams
) => {
  const channelUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/channels`;
  const res = await axios.post<HospitalProductDetail>(channelUrl, data);
  return res.data;
};

export const unassignChannel = async (hospitalHashId: string, hospitalProductHashId: string) => {
  const channelUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/channels`;
  const res = await axios.delete(channelUrl);
  return res.data;
};
