import React from 'react';
import {Typography, Grid, makeStyles, Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fields: {
    fontSize: '16px',
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
}));

type StartDateColumnProps = {
  isPeriodsSet: boolean;
};

export const StartDateColumn: React.FC<StartDateColumnProps> = (props) => {
  const {isPeriodsSet} = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.fields} style={{color: '#172B4D'}}>
      <Grid>
        <div>
          {isPeriodsSet === true ? (
            <div>
              機種に新たな点検表と点検間隔を設定しました。
              <br />
              最後に、対象機器ごとの点検開始日を設定してください。
            </div>
          ) : (
            '引き続き、対象機器ごとに点検開始日を設定することができます。'
          )}
        </div>
      </Grid>
      <Grid>
        <Typography style={{fontSize: '14px'}}>後で設定する場合は、以下の工程で設定することができます。</Typography>
        <Typography style={{fontSize: '14px'}}>①右上の「点検設定▼」ボタンをクリック</Typography>
        <Typography style={{fontSize: '14px'}}>②「点検開始日の設定」ボタンをクリック</Typography>
        <Typography style={{fontSize: '14px'}}>
          ③対象の機種を検索した上で、リスト上で点検対象機器ごとの点検開始日を設定
        </Typography>
      </Grid>
    </Grid>
  );
};
