export const PRODUCT_REGISTRATION_HEADER_HEIGHT = '104px';
export const PRODUCT_REGISTRATION_FOOTER_HEIGHT = '72px';
export const WEB_CHAT_ICON_WIDTH = '60px';
export const TOOL_BAR_HEIGHT = '80px';

export const classNameOpts = [
  {
    label: '一般医療機器（クラスⅠ）',
    value: 'one',
  },
  {
    label: '管理医療機器（クラスⅡ）',
    value: 'two',
  },
  {
    label: '高度管理医療機器（クラスⅢ）',
    value: 'three',
  },
  {
    label: '高度管理医療機器（クラスⅣ）',
    value: 'four',
  },
];
