import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {createStyles, Grid, makeStyles, TextField, Theme, useMediaQuery} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {useAtom} from 'jotai';
import {
  searchSymptomCategoriesAtom,
  searchNameAtom,
  searchReq4RepairAtFromAtom,
  searchReq4RepairAtToAtom,
  searchPersonInChargeHashIDsAtom,
  kanbanOrder,
  overdueSetting as overdueSettingAtom,
  repairListPageAtom,
} from '../jotai';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {DatePicker} from '@molecules/DatePicker';
import {symptomCategoryOptions} from '@modules/repairs/constants';
import {SymptomCategoryType} from '@modules/repairs/types';
import {useDebounceCallback} from '@front-libs/core';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {KanbanSortDialog} from '../components/KanbanSortDialog';
import {OverdueSettingDialog} from '../components/OverdueSettingDialog';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {useResetAtom} from 'jotai/utils';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import dayjs from 'dayjs';

type KanbanToolbarProp = {
  onChangeCardLayout: (layout: TableLayoutResult) => void;
};

export const KanbanToolbar: React.VFC<KanbanToolbarProp> = (prop) => {
  const {onChangeCardLayout} = prop;
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1024px)');
  const {hospitalUsers} = useHospitalUsers();

  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const resetRepairListPage = useResetAtom(repairListPageAtom);
  const [personInChargeHashIDs, setPersonInChargeHashIDs] = useAtom(searchPersonInChargeHashIDsAtom);
  const [initialPersonInChargeHashIDs, setInitialPersonInChargeHashIDs] = useState<SelectOptionProps[] | undefined>(
    undefined
  );
  const [req4RepairAtFrom, setReq4RepairAtFrom] = useAtom(searchReq4RepairAtFromAtom);
  const [req4RepairAtTo, setReq4RepairAtTo] = useAtom(searchReq4RepairAtToAtom);
  const [symptomCategories, setSymptomCategories] = useAtom(searchSymptomCategoriesAtom);
  const [order, setOrder] = useAtom(kanbanOrder);
  const [overdueSetting, setOverdueSetting] = useAtom(overdueSettingAtom);
  const [cardLayout] = useTableLayout('repairKanban');

  const actionMenuItems = useMemo(
    () => [
      {label: '並べ替え', value: 'sort'},
      {label: '時間経過をマーク', value: 'markExcessCard'},
      {label: '表示項目を変更', value: 'editCardLabel'},
      // {label: '一括削除', value: 'bulkDelete'},
    ],
    []
  );

  const handleChangeName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) setSearchName(e.target.value);
      else setSearchName(undefined);
      resetRepairListPage();
    },
    300,
    [resetRepairListPage, setSearchName]
  );

  const handleChangeLayout = useCallback(async () => {
    try {
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: cardLayout?.tableLayout ?? [],
        defaultOptions: cardLayout?.currentLayout ?? [],
        forceValue: cardLayout?.forceValue ?? {},
      });
      const newCardLayout = {
        tableLayout: cardLayout?.tableLayout ?? [],
        currentLayout: currentLayout,
      };
      onChangeCardLayout(newCardLayout);
    } catch (_e) {
      console.log(_e);
    }
  }, [cardLayout?.tableLayout, cardLayout?.currentLayout, cardLayout?.forceValue, onChangeCardLayout]);

  const handleActionMenuClick = useCallback(
    async (item: MenuItemType) => {
      switch (item.value) {
        case 'sort': {
          const newOrderValue = await dialogHandler.open(KanbanSortDialog, {
            value: order,
          });
          setOrder(newOrderValue);
          break;
        }
        case 'markExcessCard': {
          const newOverdueSetting = await dialogHandler.open(OverdueSettingDialog, {
            value: overdueSetting,
          });
          setOverdueSetting(newOverdueSetting);
          break;
        }
        case 'editCardLabel': {
          handleChangeLayout();
          break;
        }
        default:
          break;
      }
    },
    [order, setOrder, overdueSetting, setOverdueSetting, handleChangeLayout]
  );
  const handleChangeReq4RepairAtFrom = (d: Date | null) => {
    setReq4RepairAtFrom(d ? dayjs(d).startOf('day').toDate() : d);
  };

  const actionButtonProps = useMemo(
    () => ({variant: 'contained', disableElevation: true, className: classes.actionBtn} as const),
    [classes.actionBtn]
  );

  useEffect(() => {
    if (personInChargeHashIDs.length > 0) {
      const initOptions = UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}).filter((i) =>
        personInChargeHashIDs.includes(i.value)
      );
      setInitialPersonInChargeHashIDs(initOptions);
    }
  }, [hospitalUsers, personInChargeHashIDs]);

  return (
    <Grid container>
      <Grid item sm={4} md={3} style={{marginRight: '32px'}}>
        <TextField
          label={'機種名・型式・管理番号で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={searchName}
          className={classes.searchText}
          onChange={handleChangeName}
        />
      </Grid>
      <Grid item>
        <PopperSelectBoxButton
          buttonLabel={'担当者'}
          options={UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true})}
          initialOption={initialPersonInChargeHashIDs}
          isMulti={true}
          onChange={(val) => setPersonInChargeHashIDs(val?.map((item) => item.value) || [])}
          searchable={true}
        />
      </Grid>
      {!matches && (
        <Grid item className={classes.dateSelector}>
          <span className={classes.date}>修理受付日時: </span>
          <DatePicker
            value={req4RepairAtFrom}
            placeholder="日付を選択"
            disableFuture={true}
            maxDate={req4RepairAtTo ?? undefined}
            onChange={handleChangeReq4RepairAtFrom}
          />
          <span>から</span>
          <DatePicker
            value={req4RepairAtTo}
            placeholder="日付を選択"
            disableFuture={true}
            minDate={req4RepairAtFrom ?? undefined}
            onChange={(d) => setReq4RepairAtTo(d)}
          />
        </Grid>
      )}
      <Grid item>
        <PopperSelectBoxButton
          buttonLabel={'事象区分'}
          options={symptomCategoryOptions}
          isMulti={true}
          initialOption={symptomCategoryOptions.filter((i) =>
            symptomCategories.includes(i.value as SymptomCategoryType)
          )}
          onChange={(val) =>
            setSymptomCategories(val?.map<SymptomCategoryType>((item) => item.value as SymptomCategoryType) || [])
          }
          searchable={true}
        />
      </Grid>
      <div className={classes.flex} />
      <Grid item className={classes.actionBtnContainer}>
        <PopperMenuButton
          buttonProps={actionButtonProps}
          menuItemList={actionMenuItems}
          onMenuClick={handleActionMenuClick}>
          アクション
        </PopperMenuButton>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flexGrow: 1,
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    dateSelector: {
      marginTop: '-4px',
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      marginLeft: '16px',
      fontSize: 14,
    },
    actionBtnContainer: {
      minWidth: '150px',
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
  })
);
