import {Container, styled} from '@material-ui/core';
import {ImportExport} from '@material-ui/icons';
import React, {useCallback} from 'react';
import {ToolBar} from './ToolBar';
import arrow_up from '@assets/arrow_up.svg';
import arrow_down from '@assets/arrow_down.svg';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {ProductFilterButtonList} from './Products/ProductFilterButtonList';

type ProductHeaderProps = {
  handleNameChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleOrderChange: (item: MenuItemType) => void;
  searchName: string | null;
};

const StyledContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 8px',
  // ２番目のdiv要素に適用
  '& div:nth-of-type(2)': {
    width: '24px',
  },
});

const LeftElement = styled('div')({
  flex: '1',
  padding: '6px 8px',
});

const SortIcon = styled(ImportExport)({
  height: '24px',
  width: '24px',
  fill: ' #172B4D',
});

const StyledPopperMenuButton = styled(PopperMenuButton)({
  '.MuiButton-root': {
    color: '#46484B',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const MenuItemImage = styled('img')({
  width: '8px',
  objectFit: 'contain',
  loading: 'lazy',
  decoding: 'async',
});

const MenuItemText = styled('span')({
  paddingLeft: 8,
});

/**
 * メニューアイテムのラベルを生成
 *
 * @param {string} src - 画像のソースURL
 * @param {string} alt - 画像の代替テキスト
 * @param {string} itemText - メニューアイテムのテキスト
 * @returns {JSX.Element} メニューアイテムのラベル要素
 */
const createLabel = (src: string, alt: string, itemText: string) => {
  return (
    <>
      <MenuItemImage src={src} alt={alt} />
      <MenuItemText>{itemText}</MenuItemText>
    </>
  );
};

const menuItemList = [
  {
    label: createLabel(arrow_up, 'arrow_up', '管理番号の昇順'),
    value: 'management_id',
  },
  {
    label: createLabel(arrow_down, 'arrow_down', '管理番号の降順'),
    value: '-management_id',
  },
  {
    label: createLabel(arrow_up, 'arrow_up', '機種名の昇順'),
    value: 'display_name',
  },
  {
    label: createLabel(arrow_down, 'arrow_down', '機種名の降順'),
    value: '-display_name',
  },
  {
    label: createLabel(arrow_up, 'arrow_up', '型式の昇順'),
    value: 'name',
  },
  {
    label: createLabel(arrow_down, 'arrow_down', '型式の降順'),
    value: '-name',
  },
  {
    label: createLabel(arrow_up, 'arrow_up', 'メーカー名の昇順'),
    value: 'maker_name',
  },
  {
    label: createLabel(arrow_down, 'arrow_down', 'メーカー名の降順'),
    value: '-maker_name',
  },
];

/**
 * 機器一覧のヘッダー部分を表示
 * 製品の検索と並べ替えの機能を有する
 *
 * @param {ProductHeaderProps} props - コンポーネントのプロパティ
 * @param {Function} props.handleNameChange - 製品名の検索フィールドの値が変更されたときに呼び出される関数
 * @param {Function} props.handleOrderChange - 並べ替え項目が選択されたときに呼び出される関数
 * @param {string | null} props.searchName - 現在の検索フィールドの値
 * @returns {React.FC<ProductHeaderProps>} 製品ヘッダーのReactコンポーネント
 */
export const ProductHeader: React.FC<ProductHeaderProps> = (props) => {
  const {handleNameChange, handleOrderChange, searchName} = props;

  const memoizedHandleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handleNameChange(e);
    },
    [handleNameChange]
  );

  const memoizedHandleOrderChange = useCallback(
    (item: MenuItemType) => {
      handleOrderChange(item);
    },
    [handleOrderChange]
  );

  return (
    <>
      <StyledContainer>
        <LeftElement>
          <ToolBar searchName={searchName} onChangeSearchName={memoizedHandleNameChange} />
        </LeftElement>
        <StyledPopperMenuButton
          menuItemList={menuItemList}
          onMenuClick={memoizedHandleOrderChange}
          hiddenArrow
          buttonProps={{variant: 'text', size: 'small', disableElevation: true}}>
          <SortIcon />
        </StyledPopperMenuButton>
      </StyledContainer>
      <Container>
        <ProductFilterButtonList />
      </Container>
    </>
  );
};
