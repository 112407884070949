import React from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {Filters} from './Filters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prevButton: {
      width: '200px',
      padding: '0px',
      justifyContent: 'flex-start',
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    pageTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontColor: theme.palette.primary.dark,
    },
    topActions: {
      marginLeft: 'auto',
      height: 'auto',
    },
  })
);

type HeaderProps = {
  onClickPrevButton: React.MouseEventHandler;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const {onClickPrevButton} = props;
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={2} container direction="column">
        <Button color="inherit" className={classes.prevButton} onClick={onClickPrevButton}>
          <ChevronLeft />
          <span>点検表設定画面に戻る</span>
        </Button>
        <Typography variant="h1" className={classes.pageTitle}>
          テンプレートを選択
        </Typography>
      </Grid>
      <Grid item xs={10} container direction="row">
        <Filters />
      </Grid>
    </Grid>
  );
};
