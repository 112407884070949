export type TabValues<T> = T extends {label: string; value: infer U} ? U : never;

export const tabs = [
  {
    label: '点検項目',
    value: 'items',
  },
  {
    label: '写真・ファイル',
    value: 'files',
  },
] as const;

export type TabKey = TabValues<typeof tabs[number]>;

type BaseField = {
  id: string;
};

export type SelectField = BaseField & {
  type: 'select';
  value: string | null;
};

export type MultiSelectField = BaseField & {
  type: 'multi-select';
  values: {[key: string]: boolean | null};
};

export type NumericField = BaseField & {
  type: 'numeric';
  value: string | null;
};

export type TextField = BaseField & {
  type: 'text';
  value: string | null;
};

export type MultilineTextField = BaseField & {
  type: 'multiline-text';
  value: string | null;
};

export type DateField = BaseField & {
  type: 'date';
  value: string | null;
};

export type TimeField = BaseField & {
  type: 'time';
  value: string | null;
};

export type InputField =
  | SelectField
  | MultiSelectField
  | NumericField
  | TextField
  | MultilineTextField
  | DateField
  | TimeField;

export type FormValue = {
  items: {[key: string]: InputField};
  isDraft: boolean;
};

export const EMPTY_INSPECTION_ITEM_VALUES_ERROR = 'EMPTY_INSPECTION_ITEM_VALUES_ERROR' as const;
export const EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR = 'EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR' as const;
export const INVALID_INSPECTION_SELECTED_ITEM_ERROR = 'INVALID_INSPECTION_SELECTED_ITEM_ERROR' as const;
export const INVALID_INSPECTION_DATE_FORMAT_ERROR = 'INVALID_INSPECTION_DATE_FORMAT_ERROR' as const;
export const INVALID_INSPECTION_TIME_FORMAT_ERROR = 'INVALID_INSPECTION_TIME_FORMAT_ERROR' as const;
export const INSPECTION_SELECT_ITEM_VALIDATION_ERROR = 'INSPECTION_SELECT_ITEM_VALIDATION_ERROR' as const;
export const INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR = 'INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR' as const;

export type EmptyInspectionItemValuesError = {
  type: typeof EMPTY_INSPECTION_ITEM_VALUES_ERROR;
};

export type EmptyRequiredInspectionItemValueError = {
  type: typeof EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR;
  itemId: string;
};

export type InvalidInspectionSelectedItemError = {
  type: typeof INVALID_INSPECTION_SELECTED_ITEM_ERROR;
  itemId: string;
};

export type InvalidInspectionDateFormatError = {
  type: typeof INVALID_INSPECTION_DATE_FORMAT_ERROR;
  itemId: string;
};

export type InvalidInspectionTimeFormatError = {
  type: typeof INVALID_INSPECTION_TIME_FORMAT_ERROR;
  itemId: string;
};

export type InspectionSelectItemValidationError = {
  type: typeof INSPECTION_SELECT_ITEM_VALIDATION_ERROR;
  itemId: string;
};

export type InspectionNumericItemValidationError = {
  type: typeof INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR;
  itemId: string;
};

export type FormError =
  | EmptyInspectionItemValuesError
  | EmptyRequiredInspectionItemValueError
  | InvalidInspectionSelectedItemError
  | InvalidInspectionDateFormatError
  | InvalidInspectionTimeFormatError
  | InspectionSelectItemValidationError
  | InspectionNumericItemValidationError;

export const ErrorTypeToFormError: Record<FormError['type'], string> = {
  EMPTY_INSPECTION_ITEM_VALUES_ERROR: '回答が空です',
  EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR: '必須項目は入力が必要です',
  INVALID_INSPECTION_SELECTED_ITEM_ERROR: '不正な選択肢入力です',
  INVALID_INSPECTION_DATE_FORMAT_ERROR: '不正な日付フォーマットです',
  INVALID_INSPECTION_TIME_FORMAT_ERROR: '不正な時間フォーマットです',
  INSPECTION_SELECT_ITEM_VALIDATION_ERROR: 'この回答では異常があります',
  INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR: 'この回答では異常があります',
};

export type EmptyAnswer = {
  sectionIndex: number;
  sectionName: string;
  fieldIndex: number;
  fieldName: string;
};
