import React, {useCallback, useMemo, useState} from 'react';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import {isNullish} from '@front-libs/helpers';

export type optionValue = 'bothMenu' | 'onlyRentalMenu' | 'onlyReturnMenu';

const options: {label: string; value: optionValue}[] = [
  {label: '貸出メニュー、返却メニューを表示', value: 'bothMenu'},
  {label: '貸出メニューのみ表示', value: 'onlyRentalMenu'},
  {label: '返却メニューのみ表示', value: 'onlyReturnMenu'},
];

export type TerminalSettingDialogProps = {
  defaultValue: {
    rentalMenuSetting?: string;
  };
} & DialogProps;

export const TerminalSettingDialog: React.FC<TerminalSettingDialogProps> = ({open, actions, defaultValue}) => {
  const [rentalMenuSetting, setRentalMenuSetting] = useState(defaultValue.rentalMenuSetting ?? 'bothMenu');
  const onClose = actions?.reject;
  const defaultRentalMenuSetting = useMemo(
    () => options.find((option) => option.value === rentalMenuSetting),
    [rentalMenuSetting]
  );

  const handleSubmit = useCallback(() => {
    const terminalSetting = {
      rentalMenuSetting,
    };
    actions?.resolve(terminalSetting);
  }, [rentalMenuSetting, actions]);

  const handleChange = useCallback((value: {label: string; value: string} | null) => {
    if (isNullish(value)) return;
    setRentalMenuSetting(value.value);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {minHeight: 400},
      }}>
      <DialogTitle disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <DialogTitleText variant="h6">{`貸出・返却画面端末設定`}</DialogTitleText>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <SettingContainer>
              <SettingTitle>貸出・返却メニューの表示設定</SettingTitle>
              <SettingSubTitle>貸出・返却メニューについて表示するかどうかを設定します。</SettingSubTitle>
            </SettingContainer>
            <Select
              isSearchable={false}
              options={options}
              defaultValue={defaultRentalMenuSetting}
              isMulti={false}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          保存
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitleText = styled(Typography)({
  fontWeight: 700,
});

const SettingContainer = styled('div')({
  marginBottom: 16,
});

const SettingTitle = styled('div')({
  fontSize: 18,
  lineHeight: '20px',
  color: '#172B4D',
  marginBottom: 8,
});

const SettingSubTitle = styled(Typography)({
  fontSize: 14,
  lineHeight: '20px',
  color: '#65676B',
});
