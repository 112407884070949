import {useMemo} from 'react';
import {useFetchHospitalSettingsQuery} from './api';
import {
  HospitalSettingsIndex,
  allowJointingPostUseInspectionResult,
  periodForJointingPostUseInspectionResultKey,
} from './types';

export const extractJointingPostUseInspectionSettings = (settings: HospitalSettingsIndex) => {
  const allowJointingRecord = settings.data.find((x) => x.key === allowJointingPostUseInspectionResult);
  const allowJointing = allowJointingRecord?.value === 'true';

  if (!allowJointing) {
    return {
      allowed: false,
      period: undefined,
    };
  }

  const periodRecord = settings.data.find((x) => x.key === periodForJointingPostUseInspectionResultKey);
  const period = periodRecord ? Number.parseInt(periodRecord.value) : undefined;

  return {
    allowed: true,
    period: period !== undefined && Number.isInteger(period) ? period : undefined,
  };
};

export const useJointingPostUseInspectionSettings = (hospitalHashId: string) => {
  const {data, isLoading} = useFetchHospitalSettingsQuery(hospitalHashId);

  return useMemo(() => {
    if (isLoading || data === undefined) {
      return {
        isLoading,
        allowed: null,
        period: null,
      };
    }

    return {
      isLoading: false,
      ...extractJointingPostUseInspectionSettings(data),
    };
  }, [data, isLoading]);
};
