import {useEffect, useState} from 'react';
import {ROW_SIZE, SECTION_SIZE} from './consts';
import {LayoutSequenceType} from '.';
import _, {isEqual} from 'lodash';

export const useChunking = () => {
  /**
   * 与えられたサイズで配列を小さなチャンク（配列）に分割します。
   *
   * @param array チャンク化する入力配列。
   * @param size 各チャンクの所望のサイズ。
   * @returns 指定されたサイズまでの長さを持つ各内部配列の配列。
   */
  const chunkArray = (array: LayoutSequenceType, size: number) => _.chunk(array, size);

  /**
   * 配列をセクションに整理します。各セクションは3つ（行）以下の配列を持っており、最大3行x6アイテム=18個のアイテムを表示します。
   *
   * @param array 整理する入力配列。
   * @returns 6個のアイテムを含んだ行の配列を持つセクションの配列。
   */
  const chunkIntoSections = (array: LayoutSequenceType) => {
    return _.chunk(array, SECTION_SIZE).map((section) => _.chunk(section, ROW_SIZE));
  };

  /**
   * セクション（各行を含む）の配列を単一の配列に平坦化します。
   *
   * @param sections 平坦化するセクションの配列。
   * @returns セクションからのすべてのアイテムを含む単一の配列。
   */
  const flattenItems = (sections: LayoutSequenceType[][]) =>
    sections.flatMap((section) => section.flatMap((row: LayoutSequenceType) => row));

  return {chunkArray, chunkIntoSections, flattenItems};
};

export const useDnDLogic = (initialItems: LayoutSequenceType, resetFlag: boolean) => {
  const {flattenItems, chunkIntoSections} = useChunking();
  const initialSections = chunkIntoSections(initialItems);
  const [sections, setSections] = useState(initialSections);
  const [dragFlag, setDragFlag] = useState(false);

  useEffect(() => {
    if (dragFlag) setDragFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItems, resetFlag]);

  // 初回レンダリング時とinitialItemsが変更された時に実行
  useEffect(() => {
    if (!isEqual(sections, initialSections) && !dragFlag) {
      setSections(initialSections);
    }
  }, [dragFlag, initialSections, sections]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const flattened = flattenItems(sections);

    // 平坦化されたリスト内のアイテムを移動
    const [moved] = flattened.splice(result.source.index, 1);
    flattened.splice(result.destination.index, 0, moved);
    // 平坦化されたリストをセクションと行に再編成
    const newSections = chunkIntoSections(flattened);
    setDragFlag(true);
    setSections(newSections);
  };

  return {sections, handleOnDragEnd};
};
