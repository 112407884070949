import React, {useCallback, useMemo} from 'react';
import {TableLayout, TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Table} from '@molecules/Table';
import {Column} from '@components/molecules/Table/props';
import {HospitalProductWithInspectionProduct} from '@modules/hospital_products/types';
import TodayIcon from '@material-ui/icons/Today';
import {ManagementIdColumn} from './ManagementIdColumn';
import {useNavigate} from 'react-router-dom';
import {useAtomValue} from 'jotai';
import {inspectionStartDateVariables} from './jotai';
import {styled} from '@material-ui/styles';
import {DateColumn} from './DateColumn';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {BulkUpdateStartDateDialog} from '@Apps/Inspection/dialogs/BulkUpdateStartDateDialog';
import {bulkUpdateHospitalProducts} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import dayjs from 'dayjs';
import {openSnackBar} from '@molecules/SnackBar';
import {InspectionNameColumn} from './InspectionNameColumn';

type InspectionStartDateListProps = {
  isLoading: boolean;
  products: HospitalProductWithInspectionProduct[];
  handleChangeStartDate: (productHashId: string, date: string) => Promise<void>;
  refetch: () => void;
};

const useTableColumns = (
  tableLayout: TableLayoutResult | undefined,
  handleClickRow: (_e: React.MouseEvent, data: HospitalProductWithInspectionProduct) => void,
  handleClickInspection: (_e: React.MouseEvent, data: HospitalProductWithInspectionProduct) => void,
  handleChangeStartDate: (productHashId: string, date: string) => Promise<void>
) => {
  return useMemo(() => {
    if (!tableLayout) return;
    const tableColumn = Object.assign<Column<HospitalProductWithInspectionProduct>[], TableLayout[]>(
      [],
      tableLayout.currentLayout
    );
    return tableColumn.map<Column<HospitalProductWithInspectionProduct>>((item) => {
      switch (item.field) {
        case 'managementId':
          item.render = (rowData: HospitalProductWithInspectionProduct) => {
            return <ManagementIdColumn rowData={rowData} handleClickRow={handleClickRow} />;
          };
          break;
        case 'periodicInspectionStartDate':
          item.sorting = false;
          item.render = (rowData: HospitalProductWithInspectionProduct) => {
            return (
              <DateColumn
                hashId={rowData.hashId}
                startDate={rowData.periodicInspectionStartDate}
                handleChangeStartDate={handleChangeStartDate}
              />
            );
          };
          break;
        case 'inspectionName':
          item.render = (rowData: HospitalProductWithInspectionProduct) => {
            return <InspectionNameColumn rowData={rowData} handleClickInspection={handleClickInspection} />;
          };
          break;
      }
      return item;
    });
  }, [handleChangeStartDate, handleClickInspection, handleClickRow, tableLayout]);
};

export const InspectionStartDateList = ({
  isLoading,
  products,
  handleChangeStartDate,
  refetch,
}: InspectionStartDateListProps) => {
  const [tableLayout] = useTableLayout('inspectionProductStartDateList');
  const navigate = useNavigate();
  const variables = useAtomValue(inspectionStartDateVariables);
  const {myInfo} = useMyInfo();

  const handleBulkChangeStartDates = useCallback(
    async (_unusedEvent, values: HospitalProductWithInspectionProduct[]) => {
      const updateHashIds = values.map((item) => item.hashId);
      const {startDate} = await dialogHandler.open(BulkUpdateStartDateDialog, {});

      await bulkUpdateHospitalProducts(myInfo.hospitalHashId, updateHashIds, {
        periodicInspectionStartDate: dayjs(startDate).toDate(), //YYYY-MM-DD
      });
      refetch();
      openSnackBar('点検開始日を更新しました。', 'center', 'top', 'success');
    },
    [myInfo.hospitalHashId, refetch]
  );

  const handleClickRow = useCallback(
    (event?: React.MouseEvent, rowData?: HospitalProductWithInspectionProduct) => {
      navigate(`/products/${rowData?.hashId}`);
    },
    [navigate]
  );

  const handleClickInspection = useCallback(
    (event?: React.MouseEvent, rowData?: HospitalProductWithInspectionProduct) => {
      window.open(`/inspectionPreview/${rowData?.inspectionHashId}`, '_blank');
    },
    []
  );

  /** 該当データなし */
  const noDataComponent = useMemo(() => {
    if (variables) {
      return (
        <div>
          <FontBoldP>現在の検索条件に一致する機器はありません。</FontBoldP>
          <p>検索条件を変えて、再度検索してみてください。</p>
        </div>
      );
    }
  }, [variables]);

  const listData = products.map((data) => {
    return {
      ...data,
      hashId: data.hashId,
      managementId: data.managementId,
      rootCategory: data.rootCategoryName || '',
      narrowCategory: data.narrowCategoryName || '',
      name: data.wpName || '',
      displayName: data.wpDisplayName,
      makerName: data.makerName,
      periodicInspectionStartDate: data.periodicInspectionStartDate,
      inspectionName: data.inspectionName,
    };
  });

  const serializedTableColumn = useTableColumns(
    tableLayout,
    handleClickRow,
    handleClickInspection,
    handleChangeStartDate
  );

  if (!serializedTableColumn) return null;
  return (
    <Table<HospitalProductWithInspectionProduct>
      stickyHeader={true}
      showSelection={true}
      selectionButtons={[
        {
          label: '点検開始日を一括設定',
          IconComponent: TodayIcon,
          onClick: handleBulkChangeStartDates,
        },
      ]}
      isLoading={isLoading}
      data={listData}
      columns={serializedTableColumn}
      noDataComponent={noDataComponent}
      tableSize="small"
    />
  );
};

const FontBoldP = styled('p')({
  fontWeight: 700,
});
