import React, {useEffect, useCallback, useMemo} from 'react';
import {Box, Grid, makeStyles, Theme} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {Header} from '../Header';
import {Footer} from '../Footer';
import clsx from 'clsx';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalWards} from '@modules/hospital_places/api';
import {useNavigate} from 'react-router-dom';
import {
  allowRentalRoomUndefinedDataAtom,
  defaultBarcodeScannerTypeAtom,
  rentFloorAtom,
  rentRoomAtom,
  rentStepperAtom,
  rentWardAtom,
} from '../states';
import {useAtomValue, useSetAtom} from 'jotai';
import {RentalCard} from '../RentalCard';
import {HospitalWard} from '@modules/hospital_wards/types';
import {useFetchHospitalSettingQuery} from '@modules/hospital_settings/api';
import {rentalSettings} from '@modules/hospital_settings/types';
import _ from 'lodash';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {HospitalRoom} from '@modules/hospital_places/types';
import {useFetchHospitalWardLayoutSetting} from '@modules/hospital_ward_layout_setting/api';
import {isNullish} from '@front-libs/helpers';

const MAX_WARDS_PER_PAGE = 18;

export const WardSelection: React.VFC = () => {
  const classes = useStyles();
  const templateClasses = useTemplate();
  const navigate = useNavigate();
  const setRentalWard = useSetAtom(rentWardAtom);
  const setRentalFloor = useSetAtom(rentFloorAtom);
  const setRentalRoom = useSetAtom(rentRoomAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const setAllowRentalRoomUndefinedData = useSetAtom(allowRentalRoomUndefinedDataAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);

  const {myInfo} = useMyInfo();
  const {data: wardsData} = useFetchHospitalWards(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    onlyRentableRooms: true,
  });

  const {data: wardLayoutSettingsData} = useFetchHospitalWardLayoutSetting(myInfo.hospitalHashId, myInfo.hashId);

  const filteredWardsData = useMemo(() => {
    const filtered = wardsData.filter((ward) => ward.numberOfRooms > 0);

    if (myInfo.rentalLayoutPreference === 'customize' && !isNullish(wardLayoutSettingsData)) {
      // myInfo.rentalLayoutPreferenceが'customize'の場合、
      // customSequenceに基づいてfilteredの順序を変更
      const customSequenceIds = wardLayoutSettingsData?.customSequence.map((item) => item.id) || [];

      // customSequenceIdsに存在するIDのみを抽出してfilteredの順序を変更
      const sortedFiltered = customSequenceIds
        .map((id) => filtered.find((ward) => ward.hashId === id))
        .filter((ward) => ward !== undefined) as HospitalWard[];

      return {data: _.chunk(sortedFiltered, MAX_WARDS_PER_PAGE), length: sortedFiltered.length};
    }
    return {data: _.chunk(filtered, MAX_WARDS_PER_PAGE), length: filtered.length};
  }, [wardsData, myInfo.rentalLayoutPreference, wardLayoutSettingsData]);

  const {data: allowRentalRoomUndefinedData} = useFetchHospitalSettingQuery(
    myInfo.hospitalHashId,
    rentalSettings.allow_undefined_room.key
  );

  const {data: skipFloorSelectionData} = useFetchHospitalSettingQuery(
    myInfo.hospitalHashId,
    rentalSettings.skip_floor_selection.key
  );

  const handleClickNextButton = useCallback(() => {
    setRentalRoom(null);
    defaultBarcodeScannerType === 'barcode_reader'
      ? navigate(`/shared/rental/product/barcode_reader`)
      : navigate(`/shared/rental/product/camera`);
  }, [defaultBarcodeScannerType, navigate, setRentalRoom]);

  const handleSelectChange = useCallback(
    (value: HospitalWard | HospitalRoom) => {
      if ('numberOfRooms' in value) {
        setRentalWard(value as HospitalWard);

        if (skipFloorSelectionData?.value === 'false') {
          navigate(`/shared/rental/floorSelection`);
        } else {
          setRentalFloor(null);
          navigate(`/shared/rental/roomSelection`);
        }
      }
    },
    [navigate, setRentalFloor, setRentalWard, skipFloorSelectionData?.value]
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  useEffect(() => {
    setRentalStepper(1);
    setAllowRentalRoomUndefinedData(allowRentalRoomUndefinedData?.value === 'true');
  }, [allowRentalRoomUndefinedData?.value, setAllowRentalRoomUndefinedData, setRentalStepper]);

  return (
    <>
      <Header title={'貸出'} />
      <Box id={'mainContent'} className={clsx(templateClasses.content, classes.content)} style={defaultHeightWidth}>
        {filteredWardsData.length <= 12 ? (
          <Grid
            container
            justifyContent={'center'}
            alignItems="center"
            className={clsx(classes.gridRoot, filteredWardsData.length > 4 ? classes.gridRootSecondLine : undefined)}
            spacing={3}>
            {filteredWardsData.data[0] &&
              filteredWardsData.data[0].map((ward, index) => (
                <Grid
                  item
                  key={index}
                  className={clsx(filteredWardsData.length > 8 ? classes.grid6Items : classes.grid4Items)}>
                  <RentalCard
                    label={ward.name}
                    itemsCount={filteredWardsData.length}
                    value={ward}
                    onChange={handleSelectChange}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={24}
            slidesPerView={1}
            navigation
            pagination={{clickable: true}}
            className={classes.swiper}>
            {filteredWardsData.data.map((wards, index) => (
              <SwiperSlide key={index}>
                <Grid
                  container
                  justifyContent={'center'}
                  alignItems="center"
                  className={clsx(
                    classes.gridRoot,
                    filteredWardsData.length > 4 ? classes.gridRootSecondLine : undefined
                  )}
                  spacing={3}>
                  {wards.map((ward, index2) => (
                    <Grid item key={index2} className={classes.grid6Items}>
                      <RentalCard
                        label={ward.name}
                        itemsCount={filteredWardsData.length}
                        value={ward}
                        onChange={handleSelectChange}
                      />
                    </Grid>
                  ))}
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
      <Footer
        text={'貸出先を選択してください'}
        nextButtonLabel={allowRentalRoomUndefinedData?.value === 'true' ? '貸出先を選択せず次へ' : undefined}
        onClickNextButton={handleClickNextButton}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: 'scroll',
    alignItems: 'center',
  },
  gridRoot: {
    margin: 'auto',
    flexWrap: 'nowrap',
    padding: '0px 80px 0px 56px', // swiperのspaceBetween分を引いている
  },
  gridRootSecondLine: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  grid4Items: {
    flexBasis: '25%',
  },
  grid6Items: {
    flexBasis: '16.66%',
  },
  swiper: {
    padding: '40px 0px',
  },
}));
