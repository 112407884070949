import {IconButton, Tooltip} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';

type Props = {
  onClickDelete: () => void;
};
/**
 * 削除ボタン
 **/
export const DeleteIconButton = ({onClickDelete}: Props) => {
  return (
    <Tooltip title="削除">
      <IconButton onClick={onClickDelete} size="small">
        <DeleteOutlineIcon style={{color: '#172B4D'}} />
      </IconButton>
    </Tooltip>
  );
};
