import {axios} from '@front-libs/core';
import {useQueries, useQuery} from 'react-query';
import {isNullish} from '@front-libs/helpers';

import {CategoryIndex} from '../types';
import {FetchCategoriesParams, FetchCategoriesResult} from './categoryApi';

export type FetchHospitalCategoriesParams = {
  hospitalHashId: string;
} & FetchCategoriesParams;

export const getHospitalCategories = async (hospitalHashId: string, params: FetchCategoriesParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/categories`;
  const {data} = await axios.get<FetchCategoriesResult>(baseUrl, {params});
  return data;
};

export const getHospitalDescendantCategories = async (
  hospitalHashId: string,
  categoryHashId?: string,
  isMedicalDevice?: boolean
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/categories/${categoryHashId}/descendants`;

  const response = await axios.get<CategoryIndex[]>(baseUrl, {params: {isMedical: isMedicalDevice}});
  return response;
};

export const useGetHospitalCategories = (hospitalHashId: string, params: FetchCategoriesParams) => {
  const query = useQuery(
    ['useGetHospitalCategories', hospitalHashId, params],
    () => getHospitalCategories(hospitalHashId, params),
    {
      cacheTime: 0,
    }
  );
  return {...query, data: query.data?.data ?? []};
};

const DEPTH_FOR_NARROW_CATEGORY = 1;

export const useGetHospitalDescendantCategories = (
  hospitalHashId: string,
  broadCategoryHashId?: string,
  isMedicalDevice?: boolean
) => {
  const query = useQuery(
    ['useGetHospitalDescendantCategories', hospitalHashId, broadCategoryHashId, isMedicalDevice],
    async (): Promise<CategoryIndex[]> => {
      if (isNullish(broadCategoryHashId)) {
        const {data: res} = await getHospitalCategories(hospitalHashId, {
          depth: DEPTH_FOR_NARROW_CATEGORY,
          isMedicalDevice,
        });
        return res;
      } else {
        const {data: res} = await getHospitalDescendantCategories(hospitalHashId, broadCategoryHashId, isMedicalDevice);
        return (res ?? []).filter((item) => item.depth === DEPTH_FOR_NARROW_CATEGORY); // 小分類のみにフィルタ
      }
    }
  );
  return {...query, data: query.data ?? []};
};

export const useGetHospitalSomeDescendantCategories = (hospitalHashId: string, categoryHashIds: string[]) => {
  const query = useQueries(
    categoryHashIds.map((categoryHashId) => ({
      queryKey: ['useGetHospitalDescendantCategories', hospitalHashId, categoryHashIds, categoryHashId],
      queryFn: () => getHospitalDescendantCategories(hospitalHashId, categoryHashId),
    }))
  );
  return query;
};
