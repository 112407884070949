import React, {useCallback, useMemo} from 'react';
import {Grid, Typography, TypographyProps} from '@material-ui/core';
import styled from '@emotion/styled';
import {Column, RowAction, RowMenuActionItem} from '@molecules/Table/props';
import dayjs from 'dayjs';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {FileIndex, HospitalProductFileIndex, isHospitalProductFileIndex} from '@modules/files/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {Table} from '@molecules/Table';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {FilePreviewDialog} from '@molecules/Dialogs/FilePreviewDialog';
import {EditFileNameDialog} from './EditFileNameDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {
  updateFile,
  deleteFile,
  UpdateFileParam,
  updateHospitalProductFile,
  UpdateHospitalProductFileParam,
} from '@modules/files/api';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import MultipleFolder from '../../../../assets/multiple_folder.svg';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

type FileElement = {
  hashId: string;
  fileName: string;
  fileType: string;
  url: string;
  createdAt: string;
  createdBy: string;
  isFileSharingAcrossProducts?: boolean;
  hospitalProductHashId?: string;
};

type FilesTableProps = {
  files: FileIndex[] | HospitalProductFileIndex[];
  disableEdit?: boolean;
  isLoading?: boolean;
  noDataComponent: JSX.Element;
  productHashId?: string;
  onEditFileName: (fileHashId: string) => void;
  onDeleteFile: (fileHashId: string) => void;
  onOrderChange?: (columnIndex: number, orderDirection: 'asc' | 'desc') => void | Promise<void>;
  onUpdateFile?: (fileHashId: string, isFileSharingAcrossProducts: boolean) => void;
};

interface StyledTypographyProps extends TypographyProps {
  addmargin: string;
}

const DEFAULT_MARGIN = 18;
const ICON_WIDTH = 24;

const StyedImg = styled('img')({
  width: `${ICON_WIDTH}px`,
  marginRight: `${DEFAULT_MARGIN}px`,
});

const WrapDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

/** テキストフィールドの動的マージン変更 */
const StyledTypography = styled(Typography)<StyledTypographyProps>`
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: ${(props) => (props.addmargin === 'true' ? `${DEFAULT_MARGIN + ICON_WIDTH}px` : '0')};
`;

/** テーブル全体のコンテナ */
const FileTableContainer = styled(Grid)({
  marginTop: '24px',
  minWidth: '700px',
});

/** フォルダーアイコン */
const MultipleFolderImg = () => {
  return <StyedImg src={MultipleFolder} alt="MultipleFolder" />;
};

export const FileTable: React.VFC<FilesTableProps> = ({
  files,
  isLoading,
  noDataComponent,
  disableEdit = false,
  onEditFileName,
  onDeleteFile,
  onOrderChange,
  onUpdateFile,
}) => {
  const [tableLayout] = useTableLayout('fileList');
  /** NOTE:共有データのブーリアン値があるか */
  const isHospitalProduct = files.find((v) => isHospitalProductFileIndex(v)) !== undefined;
  const {myInfo} = useMyInfo();
  /** プレビューダイアログ表示 */
  const handleClickPreview = useCallback(async (_e: React.MouseEvent, data: FileElement) => {
    await dialogHandler.open(FilePreviewDialog, {
      fileName: data.fileName,
      fileType: data.fileType,
      title: 'ファイルプレビュー',
      url: data.url,
    });
  }, []);

  /** リネームダイアログ表示およびリネーム処理   */
  const handleClickRename = useCallback(
    async (_e: React.MouseEvent, data: FileElement) => {
      const updatedFileName = await dialogHandler.open(EditFileNameDialog, {defaultFileName: data.fileName});

      try {
        await updateFile(data.hashId, updatedFileName as UpdateFileParam);

        openSnackBar('ファイルを更新しました。');
        onEditFileName(data.hashId);
      } catch (e) {
        openSnackBar('ファイルの更新に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [onEditFileName]
  );

  /** 削除ダイアログ表示および削除処理 */
  const handleClickDelete = useCallback(
    async (_e: React.MouseEvent, data: FileElement) => {
      await dialogHandler.open(AlertDialog, {
        title: data.isFileSharingAcrossProducts ? '機種共通ファイルを削除しますか？' : 'ファイルを削除しますか？',
        content: data.isFileSharingAcrossProducts
          ? '同一機種に登録されている共通ファイルを削除しようとしています。\n\nこのアクションは元に戻せず、同一機種の他の機器に登録されている\nファイルも同時に削除されます。'
          : 'このファイルを削除しようとしています。\n\nこのアクションは元に戻せません。',
        positiveButtonLabel: 'ファイルを削除',
      });

      try {
        await deleteFile(data.hashId);

        openSnackBar('ファイルを削除しました。');
        onDeleteFile(data.hashId);
      } catch (e: unknown) {
        openSnackBar('ファイルの削除に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [onDeleteFile]
  );
  /** ファイル共有処理 */
  const handleClickShare = useCallback(
    async (_e: React.MouseEvent, data: FileElement) => {
      try {
        if (!data.hospitalProductHashId) {
          openSnackBar('ファイルの共有に失敗しました。', 'left', 'bottom', 'error');
          return;
        }
        const updateFileParam: UpdateHospitalProductFileParam = {
          isFileSharingAcrossProducts: !data.isFileSharingAcrossProducts,
        };
        await updateHospitalProductFile(
          myInfo.hospitalHashId,
          data.hospitalProductHashId,
          data.hashId,
          updateFileParam
        );

        openSnackBar(
          data.isFileSharingAcrossProducts
            ? '機種共通ファイルから解除しました。'
            : '機種共通ファイルとして登録しました。'
        );

        if (onUpdateFile) onUpdateFile(data.hashId, !data.isFileSharingAcrossProducts);
      } catch (e: unknown) {
        openSnackBar('ファイルの共有に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo.hospitalHashId, onUpdateFile]
  );

  /** フォルダーアイコン付きテキストフィールド */
  const addFolderItem = useCallback((fileName: string, isFileSharingAcrossProducts: boolean) => {
    return (
      <WrapDiv>
        {isFileSharingAcrossProducts && <MultipleFolderImg />}
        <StyledTypography addmargin={!isFileSharingAcrossProducts + ''} variant="inherit">
          {`${fileName}`}
        </StyledTypography>
      </WrapDiv>
    );
  }, []);

  /** 標準テキストフィールド */
  const defaultItem = useCallback((fileName: string) => {
    return (
      <StyledTypography addmargin={'false'} variant="inherit">
        {`${fileName}`}
      </StyledTypography>
    );
  }, []);

  /** カラムのレンダリング */
  const serializedTableColumn = useMemo(() => {
    // NOTE:DeepCopy tableLayout.currentLayoutにはTableLayout型が割り当てられているが実際はFileElement
    const copyTableLayout = JSON.parse(JSON.stringify(tableLayout.currentLayout));
    const tableColumn = Object.assign<Column<FileElement>[], TableLayout[]>([], copyTableLayout);
    if (isHospitalProduct) {
      tableColumn.forEach((v) => {
        if (v.field === 'fileName') {
          // FIXME:フォルダーアイコンを表示させるためにJSX.Elementをstringに偽装して表示させている
          v.title = addFolderItem(v.title + '', isHospitalProduct) as unknown as string;
        }
      });
    }
    return tableColumn.map<Column<FileElement>>((item) => {
      if (item.field === 'fileName') {
        item.render = (data) =>
          isHospitalProduct
            ? addFolderItem(data.fileName, Boolean(data.isFileSharingAcrossProducts))
            : defaultItem(data.fileName);
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [addFolderItem, defaultItem, isHospitalProduct, tableLayout.currentLayout]);

  /** 一列分のファイル表示に必要なデータ */
  const fileListElements = useMemo<FileElement[]>(() => {
    return (
      files.map((file) => ({
        hashId: file.hashId,
        fileName: file.fileName,
        fileType: file.fileType,
        url: file.url,
        createdAt: dayjs(file.createdAt).format('YYYY/MM/DD'),
        createdBy: UserFormatter.getFullName(file.createdBy),
        isFileSharingAcrossProducts: isHospitalProductFileIndex(file) ? file.isFileSharingAcrossProducts : false,
        hospitalProductHashId: isHospitalProductFileIndex(file) ? file.hospitalProductHashId : undefined,
      })) || []
    );
  }, [files]);

  const rowActions: RowAction<FileElement>[] = useMemo(() => {
    const items: RowMenuActionItem<FileElement>[] = [
      {
        label: 'ファイル名を変更',
        onClick: handleClickRename,
      },
      {
        label: 'ファイルを削除',
        onClick: handleClickDelete,
      },
    ];

    if (isHospitalProduct) {
      items.push({
        label: (data: FileElement) => {
          // NOTE:すでに登録済みなら解除
          return `${data.isFileSharingAcrossProducts ? '機種共通ファイルから解除' : '機種共通ファイルとして登録'}`;
        },
        onClick: handleClickShare,
      });
    }

    return disableEdit
      ? [
          {
            type: 'button',
            label: 'プレビュー',
            onClick: handleClickPreview,
          },
        ]
      : [
          {
            type: 'button',
            label: 'プレビュー',
            onClick: handleClickPreview,
          },
          {
            type: 'menu',
            label: 'アクション',
            items: items,
          },
        ];
  }, [handleClickRename, handleClickDelete, isHospitalProduct, disableEdit, handleClickPreview, handleClickShare]);

  return (
    <FileTableContainer container>
      <Table<FileElement>
        showSelection={false}
        isLoading={isLoading}
        columns={serializedTableColumn}
        data={fileListElements}
        noDataComponent={noDataComponent}
        onOrderChange={onOrderChange}
        rowActions={rowActions}
      />
    </FileTableContainer>
  );
};
