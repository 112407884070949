import React, {useCallback, useMemo} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {yup} from '@front-libs/core';
import {styled} from '@material-ui/styles';
import {HospitalDepartment} from '@modules/hospital_product_mgmt_section/api';

const MarginDiv = styled('div')({
  marginBottom: '24px',
  '& label': {
    fontSize: '12px',
  },
  '& .MuiInputBase-root': {
    marginTop: '8px',
  },
});
const StyledForm = styled(Form)({
  width: '100%',
});

/**
 * 代理店名入力フォーム
 * @param props
 * @returns
 */
const HospitalManagementNameEditForm = (props: ManagementNameEditDialogProps) => {
  const {submitForm, values, isValid} = useFormikContext();
  const handleClickResist = useCallback(() => {
    submitForm();
    props.actions.resolve(values);
  }, [props.actions, submitForm, values]);

  return (
    <Dialog
      open={Boolean(props.open)}
      onClose={props.actions.reject}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}>
      <DialogTitle>
        {props.mode === 'edit' ? `管理部署「${props.hospitalDepartments?.name}」を編集` : '管理部署を登録'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <StyledForm>
            <MarginDiv>
              <label>管理部署名</label>
              <TextField placeholder="管理部署" name="name" size={'small'} fullWidth />
            </MarginDiv>
          </StyledForm>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={handleClickResist}>
          保存
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ManagementNameEditDialogProps = {
  hospitalDepartments?: HospitalDepartment;
  mode: 'create' | 'edit';
} & DialogProps;

/**
 * 管理部署名を追加・編集するためのダイアログコンポーネント。
 *
 * @param {ManagementNameEditDialogProps} props - このコンポーネントに渡されるプロパティ。
 * props には以下が含まれます:
 *   - `hospitalDepartment`: 編集対象の部門情報を含むオブジェクト。
 *   - `actions`: 編集完了時に呼び出されるコールバック関数。
 *
 * @returns {React.ReactElement} Formikを使用した編集フォームのReact要素。
 */
export const ManagementDepartmentNameEditDialog = (props: ManagementNameEditDialogProps) => {
  const initialValues = useMemo(
    () => ({
      name: props.hospitalDepartments ? props.hospitalDepartments.name : '',
    }),
    [props.hospitalDepartments]
  );

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .test('', '', function (value) {
        // 初期値と異なる場合にのみtrueを返す
        return value !== initialValues.name;
      }),
  });

  const handleSubmit = (res: {name: string}) => {
    props.actions.resolve(res);
  };

  return (
    <Formik initialValues={initialValues} validateOnMount onSubmit={handleSubmit} validationSchema={validationSchema}>
      <HospitalManagementNameEditForm {...props} />
    </Formik>
  );
};
