import React from 'react';
import {Grid, makeStyles, Paper, Theme} from '@material-ui/core';
import dayjs from 'dayjs';
import {HistoryCardProps} from './HistoryCard';
import {UserFormatter} from '@modules/hospital_users/helpers';

export const RegistrationCard: React.VFC<HistoryCardProps> = (props) => {
  const {displayDate, user} = props;
  const classes = useHistoryCardStyles();
  return (
    <Paper className={classes.root}>
      <Grid container alignItems={'center'} className={classes.cardHeader}>
        <Grid item className={classes.boldText}>
          修理情報の登録
        </Grid>
        <div className={classes.flex} />
        <Grid item className={classes.displayDate}>
          {dayjs(displayDate).format('YYYY年MM月DD日 HH:mm')}
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} className={classes.propertyContainer}>
        <Grid item>{UserFormatter.getFullName(user)}さんが修理情報を作成しました。</Grid>
      </Grid>
    </Paper>
  );
};

const useHistoryCardStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
    width: '100%',
  },
  cardHeader: {
    paddingTop: 8,
    paddingLeft: 16,
  },
  flex: {
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 700,
  },
  displayDate: {
    color: theme.palette.grey[600],
  },
  propertyContainer: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingBottom: 8,
  },
  property: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.common.black,
  },
  propertyLabel: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.grey[600],
  },
}));
