import React from 'react';

// FIXME: primary,secondaryで値を渡せない箇所があるため、SVGIconが利用できていない
export const InspectionSVG: React.FC<InspectionSVGProps> = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 11L19.5 11" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M12.5 15L19.5 15" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2071 16.2929C25.5976 16.6834 25.5976 17.3166 25.2071 17.7071L20.4142 22.5C19.6332 23.281 18.3668 23.281 17.5858 22.5L15.2929 20.2071C14.9024 19.8166 14.9024 19.1834 15.2929 18.7929C15.6834 18.4024 16.3166 18.4024 16.7071 18.7929L19 21.0858L23.7929 16.2929C24.1834 15.9024 24.8166 15.9024 25.2071 16.2929Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8H22V15.9473C22 16.2146 22.3231 16.3484 22.5121 16.1594L23.7071 14.9645C23.8946 14.7769 24 14.5226 24 14.2574V8C24 6.89543 23.1046 6 22 6H10C8.89543 6 8 6.89543 8 8V24C8 25.1046 8.89543 26 10 26H22C23.1046 26 24 25.1046 24 24V21.0527C24 20.7854 23.6769 20.6516 23.4879 20.8406L23.0607 21.2678C22.8143 21.5142 22.5741 21.7701 22.3249 22.0154C22.1223 22.2149 22 22.4844 22 22.7687V24H10V8Z"
        fill={color}
      />
    </svg>
  );
};

type InspectionSVGProps = {
  color: string;
};
