import React, {ComponentProps} from 'react';
import {Grid} from '@material-ui/core';

// コンポーネントが左右に分かれているようなレイアウト
// 使用例:
// const Toolbar = () => {
//   return (
//     <TwoColumns spacing={1}>
//       {/* 左側に配置したいコンポーネントを置く */}
//       <TwoColumns.Left>
//         <button />
//         <button />
//       </TwoColumns.Left>
//
//       {/* 右側に配置したいコンポーネントを置く */}
//       <TwoColumns.Right>
//         <button />
//       </TwoColumns.Right>
//     </TwoColumns>
//   );
// };

type Props = ComponentProps<typeof Grid> & {
  children?: React.ReactNode;
};

export const TwoColumns: React.FC<Props> & {
  Left: React.FC<ChildProps>;
  Right: React.FC<ChildProps>;
} = ({children, spacing = 1, ...rest}) => {
  return (
    <Grid container spacing={spacing} {...rest}>
      {children}
    </Grid>
  );
};

type ChildProps = {
  children?: React.ReactNode;
};

TwoColumns.Left = ({children}) => {
  const components = Array.isArray(children) ? children : [children];

  return (
    <>
      {components}
      <div style={{marginRight: 'auto'}} />
    </>
  );
};

TwoColumns.Right = ({children}) => {
  const components = Array.isArray(children) ? children : [children];

  return <>{components}</>;
};
