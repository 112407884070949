import React from 'react';
import {Grid, Paper} from '@material-ui/core';
import {Build} from '@material-ui/icons';
import dayjs from 'dayjs';

type MobileRepairRegistrationCardProps = {
  displayDate: Date;
};

export const MobileRepairRegistrationCard = (props: MobileRepairRegistrationCardProps) => {
  const {displayDate} = props;
  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 0,
        borderBottom: '0.5px solid #DDE0E4',
        padding: 16,
        marginLeft: '-16px',
        marginRight: '-16px',
      }}>
      <Grid container alignItems={'center'} justifyContent="space-between" style={{marginBottom: 8}}>
        <Grid item>
          <Grid container style={{gap: 8}} alignItems="center">
            <Grid item>
              <div
                style={{
                  width: 28,
                  height: 28,
                  paddingTop: 4,
                  paddingLeft: 4,
                  background: '#F0F2F5',
                  borderRadius: '16px',
                }}>
                <Build />
              </div>
            </Grid>
            <Grid item style={{fontSize: 16}}>
              <span style={{color: '#0052CC'}}>修理情報</span>の登録
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{dayjs(displayDate).format('YYYY/M/D')}</Grid>
      </Grid>
      {/* <Grid container alignItems={'center'} style={{paddingLeft: 40}}>
        <Grid item style={{width: '100%'}}>
          <CardText>登録者：{executeUser ? `${UserFormatter.getFullName(executeUser)}` : '不明なユーザー'}</CardText>
        </Grid>
      </Grid> */}
    </Paper>
  );
};
