import {TableBody, TableCell} from '@material-ui/core';
import dayjs from 'dayjs';
import React, {useCallback, useEffect, useState} from 'react';
import {StatusTable, StyledTableHead, StyledTableRow} from '.';
import {serviceWorkerRegistration, SERVICE_WORKER_KEY} from '@serviceWorker/serviceWorkerRegistration';

/** サービスワーカーのStateを表示 */
export const ServiceWorkerList = () => {
  const [serviceWorkerStatus, setServiceWorkerStatus] = useState('未取得');
  const registrationTime = Number(localStorage.getItem(SERVICE_WORKER_KEY));
  const registrationDate =
    isNaN(registrationTime) || registrationTime === 0
      ? '未取得'
      : dayjs(Math.floor(registrationTime)).format('YYYY-MM-DD HH:mm:ss');

  const getRegistration = useCallback(() => {
    if (!serviceWorkerRegistration) {
      setServiceWorkerStatus('取得中');
      // 再取得
      setTimeout(() => {
        getRegistration();
      }, 1000);
      return;
    }

    if (serviceWorkerRegistration.active !== null) {
      setServiceWorkerStatus(serviceWorkerRegistration.active.state);
    }
  }, []);

  useEffect(() => {
    getRegistration();
  }, [getRegistration]);

  return (
    <StatusTable>
      <StyledTableHead>
        <StyledTableRow>
          <TableCell colSpan={2}>Service Worker</TableCell>
        </StyledTableRow>
      </StyledTableHead>
      <TableBody>
        <StyledTableRow key="swStatus">
          <TableCell component="th" scope="row">
            ステータス
          </TableCell>
          <TableCell align="right"> {serviceWorkerStatus}</TableCell>
        </StyledTableRow>
        <StyledTableRow key="swDate">
          <TableCell component="th" scope="row">
            登録日時
          </TableCell>
          <TableCell align="right"> {registrationDate}</TableCell>
        </StyledTableRow>
      </TableBody>
    </StatusTable>
  );
};
