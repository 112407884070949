import React from 'react';
import {SelectedViewType} from '../types';
import {Grid} from '@material-ui/core';
import {ContentTitle} from '../../components/ContentTitle';
import {RootCategorySelector} from '../../components/RootCategorySelector';
import {useRootCategories} from '../../components/hooks';

const menuItemList = [
  {label: '年間故障率(事象区分)', value: 'symptomCategory'},
  {label: '年間故障率(原因区分)', value: 'rootCauseCategory'},
];

export type ToolbarProps = {
  selectView: SelectedViewType;
  rootCategoryHashId: string | null;
  onChangeSelectView: (value: SelectedViewType) => void;
  onChangeRootCategoryHashId: (value: string) => void;
};

export const Toolbar: React.FC<ToolbarProps> = ({
  selectView,
  rootCategoryHashId,
  onChangeSelectView,
  onChangeRootCategoryHashId,
}) => {
  const {rootCategoryOptions} = useRootCategories();
  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item>
        <ContentTitle
          selectedViewType={selectView}
          onChangeContent={(view) => onChangeSelectView(view as SelectedViewType)}
          menuItemList={menuItemList}
        />
      </Grid>
      <Grid item>
        <Grid item>
          <RootCategorySelector
            currentValue={rootCategoryHashId}
            rootCategoryOptions={rootCategoryOptions}
            onChangeHashId={onChangeRootCategoryHashId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
