import React, {useState} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {Grid, makeStyles, TextField, Typography} from '@material-ui/core';
import {Selector} from '@molecules/Selector';
import {CSSObject} from '@emotion/react';

type OverdueSettingProps = {
  dateNumber: number;
  unit: 'day' | 'week';
};

type OverdueSettingDialogProps = {
  value: OverdueSettingProps;
} & Omit<DialogProps, 'value' | 'onChange'>;

export const OverdueSettingDialog = ({value, ...props}: OverdueSettingDialogProps) => {
  const classes = useStyles();
  const [overdueSetting, setOverdueSetting] = useState(value);
  return (
    <SimpleDialog
      {...props}
      title={'時間経過をマーク'}
      actions={{
        resolve: async (res) => {
          props.actions.resolve(overdueSetting);
          return res;
        },
        reject: async (res) => {
          props.actions.reject(res);
          return res;
        },
      }}>
      <Grid container className={classes.fields} direction="column">
        <div style={{width: '100%', marginBottom: '16px'}}>
          <label>次の期限を超過したカードをマーク：</label>
          <Grid container>
            <Grid item>
              <TextField
                variant="outlined"
                type="number"
                name="dateNumber"
                size={'small'}
                value={overdueSetting.dateNumber}
                onChange={(e) => setOverdueSetting({...overdueSetting, dateNumber: Number.parseFloat(e.target.value)})}
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  style: {
                    borderRadius: '4px 0 0 4px',
                  },
                }}
              />
            </Grid>
            <Grid item style={{minWidth: '20%'}}>
              <Selector
                isSearchable={false}
                name="direction"
                size={'small'}
                options={[
                  {label: '日', value: 'day'},
                  {label: '週', value: 'week'},
                ]}
                styles={{
                  control: (provided: CSSObject) => ({
                    ...provided,
                    borderRadius: '0 4px 4px 0',
                    marginLeft: '-1px',
                    height: 40,
                  }),
                }}
                style={{height: '100%'}}
                value={overdueSetting.unit}
                onChange={(data: {label: string; value: 'day' | 'week'}) =>
                  setOverdueSetting({...overdueSetting, unit: data.value})
                }
              />
            </Grid>
          </Grid>
        </div>
        <Typography variant="body2">
          ボード上で期限を超過している対応が必要なカードをすばやく見つけられます。
          <br />
          期限を設定すると、修理受付日時に対して期限を過ぎたカードは自動的にグレー表示されます。
        </Typography>
      </Grid>
    </SimpleDialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    padding: '24px 48px 24px',
  },
  divider: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
  },
  content: {
    padding: '24px 48px 0px',
  },
  fields: {
    '& > div': {
      marginTop: '16px',
    },
  },
  fileInput: {
    visibility: 'hidden',
  },
  inputName: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '0px 48px 24px',
  },
}));
