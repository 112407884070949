import React, {useMemo, useState} from 'react';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {
  ButtonProps,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  withStyles,
} from '@material-ui/core';
import {Dropzone} from '@molecules/Dropzone';
import {Close} from '@material-ui/icons';

export type MultipleUploadFormProps = {
  file: File;
  fileName: string;
};

export type MultipleUploadDialogProps = {
  title?: string;
  setDefaultFileName?: boolean;
  acceptFile?: string[];
} & Omit<DialogProps<MultipleUploadFormProps[]>, 'content'>;

export const MultipleUploadDialog: React.VFC<MultipleUploadDialogProps> = ({
  title = 'ファイルの一括アップロード',
  setDefaultFileName = true,
  acceptFile,
  ...props
}) => {
  const classes = useStyles();

  const [files, setFiles] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const disabledButton = useMemo(() => {
    return files.length + fileNames.length === 0;
  }, [files, fileNames]);

  const positiveButtonProps: ButtonProps = {
    disabled: disabledButton,
  };

  return (
    <SimpleDialog
      {...props}
      positiveButtonProps={positiveButtonProps}
      maxWidth="xl"
      actions={{
        resolve: async (res) => {
          props.actions.resolve(files.map((item, index) => ({file: item, fileName: fileNames[index]})));
          return res;
        },
        reject: async (res) => {
          props.actions.reject(res);
          return res;
        },
      }}
      title={title}>
      <Grid container direction="column">
        <Grid>
          <p>
            HITOTSUに一括でファイルをアップロードします。
            <br />
            この画面からアップロードしたファイルのファイルタイプは「指定なし」に分類されます。
          </p>
          <p>
            ・画像ファイルは自動的にリサイズされます。
            <br />
            ・一度にアップロード可能なデータ容量は25MBまでです。
            <br />
            ・ファイル保管にご利用可能なファイル容量の全体容量は100GBまでです。
          </p>
        </Grid>
        <Grid item container>
          <Grid item xs={6} style={{paddingRight: 8}}>
            <Dropzone
              accept={acceptFile}
              multiple={true}
              maxSize={25 * 1024 * 1024}
              onDrop={(acceptedFiles, _fileRejections) => {
                setFiles([...files, ...acceptedFiles]);
                if (setDefaultFileName) setFileNames([...fileNames, ...acceptedFiles.map((file) => file.name)]);
              }}
            />
          </Grid>
          <Grid item xs={6} style={{paddingLeft: 8}}>
            <Table size="small" style={{width: '100%'}}>
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell> </StyledTableCell> */}
                  <StyledTableCell style={{width: '300px'}}>ファイル名</StyledTableCell>
                  <StyledTableCell> </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileNames.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant={'outlined'}
                        className={classes.inputName}
                        label={'ファイル名'}
                        name="name"
                        size="small"
                        value={item}
                        onChange={(e) => {
                          const newFileNames = [
                            ...fileNames.slice(0, index),
                            e.target.value,
                            ...fileNames.slice(index + 1),
                          ];
                          setFileNames(newFileNames);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => {
                          const newFiles = [...files];
                          const newFileNames = [...fileNames];
                          newFiles.splice(index, 1);
                          newFileNames.splice(index, 1);
                          setFiles(newFiles);
                          setFileNames(newFileNames);
                        }}>
                        <Close />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </SimpleDialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    padding: '24px 48px 24px',
  },
  divider: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
  },
  content: {
    padding: '24px 48px 0px',
  },
  fileInput: {
    visibility: 'hidden',
  },
  inputName: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '0px 48px 24px',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F5F8FA',
  },
}))(TableCell);
