import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  datePickerButton: {
    marginLeft: '8px',
    minWidth: '112px',
    color: 'black',
    height: '32px',
    border: '2px solid #DFE1E5',
    backgroundColor: 'rgba(250, 251, 252, 1)',
  },
  selectCategoryButton: {
    marginLeft: '8px',
    minWidth: '112px',
    height: '32px',
    border: '2px solid #DFE1E5',
    backgroundColor: 'rgba(250, 251, 252, 1)',
  },
}));
