import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {InspectionIndex} from '@modules/inspections/types';
import {StartInspectionFormBody, StartInspectionFormValue} from './StartInspectionFormBody';
import * as yup from 'yup';

type StartInspectionFormProps = {
  open: boolean;

  // inspection result selector (optional)
  showsInspectionResultSelector: boolean;
  inspectionResultOptions?: Array<{label: string; value: string; defaultInspectionHashId?: string}>;
  defaultInspectionResultHashId: string;

  // inspection selector
  showsInspectionSelector: boolean;
  inspectionOptions: Array<{label: string; options: Array<{label: string; value: InspectionIndex}>}>;
  defaultInspectionHashId?: string;

  //  inspector selector
  userOptions: Array<{label: string; value: string}>;
  defaultInspectorHashId: string;

  onSearchInspection: (name: string) => void;
  onSubmit: (values: StartInspectionFormValue) => void;
  onClose: React.MouseEventHandler;
};

export const StartInspectionForm: React.VFC<StartInspectionFormProps> = (props) => {
  const {
    open,

    showsInspectionResultSelector,
    inspectionResultOptions,
    defaultInspectionResultHashId,

    showsInspectionSelector,
    inspectionOptions,
    defaultInspectionHashId,
    userOptions,
    defaultInspectorHashId,
    onSubmit,
    onSearchInspection,
    onClose,
  } = props;

  const [validationSchema] = useState(() => yup.object({
    inspection: yup.string().required(),
    inspectionResult: yup.string().required(),
    inspector: yup.string().required(),
  }));

  const [initialValues] = useState<StartInspectionFormValue>(() => ({
    inspectionResult: defaultInspectionResultHashId,
    inspection: defaultInspectionHashId ?? '',
    inspector: defaultInspectorHashId,
  }));

  const [defaultInspection] = useState(() => (inspectionResultOptions ?? []).find((o) => o.value === defaultInspectionResultHashId))
  const [defaultUser] = useState(() => userOptions.find((o) => o.value === defaultInspectorHashId));

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} isInitialValid={true}>
      <Form>
        <StartInspectionFormBody
          open={open}
          // inspection result selector
          showsInspectionResultSelector={showsInspectionResultSelector}
          inspectionResultOptions={inspectionResultOptions}
          defaultInspectionResult={defaultInspection}
          // inspection selector
          showsInspectionSelector={showsInspectionSelector}
          inspectionOptions={inspectionOptions}
          defaultInspectionHashId={defaultInspectionHashId}
          // inspector selector
          userOptions={userOptions}
          defaultUser={defaultUser}
          onSearchInspection={onSearchInspection}
          onClose={onClose}
        />
      </Form>
    </Formik>
  );
};
