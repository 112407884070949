import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Button,
  Typography,
  ClickAwayListener,
  Popper,
  Paper,
  Grow,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  CreateInspectionResultDialog,
  CreateInspectionResultDialogResult,
  CreateInspectionResultDialogProps,
} from '@organisms/CreateInspectionResultDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  NextUpdateInspectionStatusParam,
  createInspectionResult,
  requestExportInspectionResultTask,
} from '@modules/inspection_results/api';
import {ViewInspectionType} from './types';
import {ViewInspectionTypeToText} from './consts';
import {useInspectionType} from './InternalInspection/states/states';
import {convertDateToRFC3339, convertDateToSimpleDate} from '@front-libs/helpers';
import {createMakerInspectionResult} from '@modules/maker_inspection_results/api';
import {
  CreateMakerInspectionResultDialog,
  CreateMakerInspectionResultDialogProps,
  CreateMakerInspectionResultDialogResult,
} from './MakerInspection/dialogs/CreateMakerInspectionResultDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {
  ExportPeriodicInspectionResultDialogProps,
  ExportPeriodicInspectionResultDialogResult,
  ExportPeriodicInspectionResultDialog,
} from './InternalInspection/dialogs/ExportPeriodicInspectionResult';
import {onlineManager} from 'react-query';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const useMenusStyle = makeStyles((theme) => ({
  popper: {
    zIndex: 100000,
    maxHeight: 360,
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '200px',
    background: '#FAFBFC',
    border: '2px solid #DFE1E5',
    padding: '4px 16px',
  },
  paper: {
    border: `1px solid ${theme.palette.grey.A100}`,
  },
  menuList: {
    '& li': {
      width: '200px',
    },
  },
  menuItemHovered: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

type TypeMenusProps = {
  type: ViewInspectionType;
  onChange: (t: ViewInspectionType) => void;
};

const TypeMenus = (props: TypeMenusProps) => {
  const {type, onChange} = props;
  const classes = useMenusStyle();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOnline = onlineManager.isOnline();

  const toggleMenu = () => setOpen((_open) => !_open);

  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    toggleMenu();
  };

  const handleClose = (e: React.MouseEvent<Document, MouseEvent>): void => {
    e.stopPropagation();
    if (!open) return;
    setOpen(false);
  };

  const handleMenuClick = useCallback(
    // eslint-disable-next-line no-shadow
    (type: ViewInspectionType) => {
      onChange(type);
      setOpen(false);
    },
    [onChange]
  );

  const text = ViewInspectionTypeToText[type];

  // const isOnline = onlineManager.isOnline();

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <ClickAwayListener onClickAway={open ? handleClose : () => {}}>
      <div className={classes.container}>
        <Button className={classes.button} onClick={handleClickButton}>
          <Grid container justifyContent="space-between">
            <Typography>{text}</Typography>
            <ArrowDropDown fontSize="small" />
          </Grid>
        </Button>

        <Popper open={open} transition placement="bottom" anchorEl={anchorEl} className={classes.popper}>
          {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} style={{transformOrigin: placement}}>
              <Paper id="menu-list-grow" elevation={2} className={classes.paper}>
                <MenuList className={classes.menuList}>
                  <MenuItem
                    className={classes.menuItemHovered}
                    onClick={() => {
                      handleMenuClick('daily');
                    }}>
                    日常点検
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemHovered}
                    style={{paddingLeft: '48px'}}
                    onClick={() => {
                      handleMenuClick('pre_use');
                    }}>
                    使用前点検
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemHovered}
                    style={{paddingLeft: '48px'}}
                    onClick={() => {
                      handleMenuClick('in_use');
                    }}>
                    使用中点検
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemHovered}
                    style={{paddingLeft: '48px'}}
                    onClick={() => {
                      handleMenuClick('post_use');
                    }}>
                    使用後点検
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemHovered}
                    onClick={() => {
                      handleMenuClick('periodic');
                    }}>
                    定期点検
                  </MenuItem>
                  {isOnline && (
                    <MenuItem
                      className={classes.menuItemHovered}
                      onClick={() => {
                        handleMenuClick('maker_periodic');
                      }}>
                      メーカー保守点検
                    </MenuItem>
                  )}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.primary.dark,
    },
    inspectionTypeMenu: {
      width: '204px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '40px',
      border: '2px solid #DFE1E5',
    },
    flex: {
      flexGrow: 1,
    },
  })
);

export const Header: React.VFC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const isOnline = onlineManager.isOnline();
  const {isReadOnly} = useMyRole();

  const exportPeriodicInspectionResult = async (hospitalHashId: string): Promise<string | null> => {
    const now = new Date();
    try {
      const res = await dialogHandler.open<
        ExportPeriodicInspectionResultDialogProps,
        ExportPeriodicInspectionResultDialogResult
      >(ExportPeriodicInspectionResultDialog, {
        startYear: now.getFullYear(),
        startMonth: now.getMonth(),
      });

      const startDate = convertDateToRFC3339(new Date(res.startYear, res.startMonth, 1));

      // 1年後の日付を取得。サーバ側で最終日当日は含まない設定のため、1年間分のデータがExcelに出力される
      const endDate = convertDateToRFC3339(new Date(res.startYear + 1, res.startMonth, 1));
      await requestExportInspectionResultTask(myInfo.hospitalHashId, startDate, endDate);
      openSnackBar('点検表のエクスポートを受け付けました。\n処理完了後、通知をご確認ください。', 'center', 'top');
    } catch (_e) {
      return null;
    }

    return null;
  };

  const handleClickExportPeriodicInspectionResult = useCallback(async () => {
    await exportPeriodicInspectionResult(myInfo.hospitalHashId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfo?.hospitalHashId]);

  const handleClickStartInspection = useCallback(
    async (e) => {
      try {
        const {hospitalProductHashId, inspectionHashId, scheduledDate, inspector, isAdhoc} = await dialogHandler.open<
          CreateInspectionResultDialogProps,
          CreateInspectionResultDialogResult
        >(CreateInspectionResultDialog, {
          defaultInspectorHashId: myInfo.hashId,
          defaultScheduledDate: convertDateToSimpleDate(new Date()),
        });

        const result = await createInspectionResult(myInfo.hospitalHashId, inspectionHashId, {
          hospitalProductHashId,
          category: 'in_hospital',
          scheduledTime: scheduledDate,
          inspectorHashId: inspector,
          status: 'draft',
          items: {},
          isAdhoc: isAdhoc ? true : undefined,
        });
        // NOTE:_InspectionResultでuseLocation経由で受け取るためのParam
        const nextUpdateInspectionStatusParam: NextUpdateInspectionStatusParam = {
          status: 'uncompleted',
          inspectorHashId: inspector,
        };
        navigate(`/inspections/${result.data.inspectionHashId}/result/${result.data.hashId}`, {
          state: nextUpdateInspectionStatusParam,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [myInfo.hashId, myInfo.hospitalHashId, navigate]
  );

  const handleClickStartMakerInspection = useCallback(async () => {
    try {
      const res = await dialogHandler.open<
        CreateMakerInspectionResultDialogProps,
        CreateMakerInspectionResultDialogResult
      >(CreateMakerInspectionResultDialog, {
        defaultInspectorHashId: myInfo.hashId,
        defaultScheduledDate: convertDateToSimpleDate(new Date()),
      });

      if (!res) return;
      const result = await createMakerInspectionResult(myInfo.hospitalHashId, {
        hospitalProductHashId: res.hospitalProductHashId,
        scheduledTime: res.scheduledDate,
        inspectorUserHashId: res.inspector,
      });
      if (result && result.data) navigate(`/maker_inspections/results/${result.data.hashId}`);
    } catch (err) {
      console.error(err);
    }
  }, [myInfo.hashId, myInfo.hospitalHashId, navigate]);

  const actionMenuItems = [
    {
      label: '点検表設定',
      value: '',
    },
    {
      label: '点検対象製品の設定',
      value: 'products',
    },
    {
      label: '点検開始日の設定',
      value: 'start_date',
    },
  ];

  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      if (item.value) {
        navigate(`/inspection/setting/${item.value}`);
      } else {
        navigate('/inspection/setting/');
      }
    },
    [navigate]
  );

  const [type, setInspectionType] = useInspectionType();

  const handleChangeType = useCallback(
    // eslint-disable-next-line no-shadow
    (type: ViewInspectionType) => {
      setInspectionType(type);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Grid container>
      <Grid item>
        <Grid container>
          <Grid item>
            <PageRoutingMenu>
              <Typography id="tutorial-inspection-list" variant={'h5'} className={classes.pageTitle}>
                点検
              </Typography>
              <ArrowDropDown />
            </PageRoutingMenu>
          </Grid>
          <Grid item style={{marginLeft: '40px'}}>
            <TypeMenus type={type} onChange={handleChangeType} />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.flex} />
      <Grid item>
        {type === 'periodic' && isOnline && (
          <Button
            variant={'outlined'}
            color={'primary'}
            style={{background: '#FFFFFF', marginRight: '8px'}}
            onClick={handleClickExportPeriodicInspectionResult}>
            点検計画出力
          </Button>
        )}
      </Grid>
      <Grid item>
        {type !== 'maker_periodic' && isOnline && !isReadOnly && (
          <PopperMenuButton
            buttonProps={{variant: 'outlined', color: 'primary', disableElevation: true}}
            menuItemList={actionMenuItems}
            onMenuClick={handleActionMenuClick}
            containerProps={{style: {background: '#FFFFFF', marginRight: '8px'}}}>
            点検設定
          </PopperMenuButton>
        )}
      </Grid>
      {!isReadOnly && (
        <Grid item>
          {type === 'maker_periodic' ? (
            <Button variant={'contained'} color={'primary'} onClick={handleClickStartMakerInspection}>
              点検結果を記録
            </Button>
          ) : (
            <Button variant={'contained'} color={'primary'} onClick={handleClickStartInspection}>
              点検開始
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};
