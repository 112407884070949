import {axios} from '@front-libs/core';
import {RootCauseCategoryType, SymptomCategoryType} from '../types';

export type GetHospitalFaultRatioParam = {
  hospitalHashId: string;
  rootCategoryHashId: string;
  fiscalYear: number;
};

export type FaultRatio = {
  narrowCategoryHashId: string;
  narrowCategoryName: string;
  faultRatio: number;
};

export type GetHospitalFaultRatioBySymptomCategoryResult = {
  fiscalYear: number;
  data: (FaultRatio & {
    symptomCategory: SymptomCategoryType;
  })[];
};
export type GetHospitalFaultRatioByRootCauseCategoryResult = {
  fiscalYear: number;
  data: (FaultRatio & {
    rootCauseCategory: RootCauseCategoryType;
  })[];
};

export const getHospitalFaultRatioBySymptomCategory = async (params: GetHospitalFaultRatioParam) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${
    params.hospitalHashId
  }/fault_repair/fault_ratio/symptom_category`;
  const {data} = await axios.get<GetHospitalFaultRatioBySymptomCategoryResult>(baseUrl, {params});
  return data;
};

export const getHospitalFaultRatioByRootCauseCategory = async (params: GetHospitalFaultRatioParam) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${
    params.hospitalHashId
  }/fault_repair/fault_ratio/root_cause_category`;
  const {data} = await axios.get<GetHospitalFaultRatioByRootCauseCategoryResult>(baseUrl, {params});
  return data;
};
