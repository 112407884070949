import * as React from 'react';
import MuiTextField, {TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField';
import {FieldProps, getIn} from 'formik';
import {makeStyles, Theme} from '@material-ui/core/styles';

export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {
  readOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  inputRoot: {
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  underline: {
    '&&&:before': {
      borderBottom: '0px solid',
    },
    '&&:after': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));

export function fieldToTextField({
  disabled,
  field: {onBlur: fieldOnBlur, ...field},
  form: {isSubmitting, touched, errors},
  onBlur,
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export const StandardTextField: React.FC<TextFieldProps> = ({children, ...props}) => {
  const classes = useStyles();

  return (
    <MuiTextField
      {...fieldToTextField(props)}
      multiline={props.multiline || false}
      variant={'standard'}
      fullWidth={props.fullWidth}
      InputLabelProps={{
        classes: {
          root: classes.inputText,
          shrink: classes.shrink,
        },
        shrink: props.type === 'datetime-local' ? true : undefined,
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.inputText,
          underline: classes.underline,
        },
        readOnly: props.readOnly || false,
      }}>
      {children}
    </MuiTextField>
  );
};

StandardTextField.displayName = 'FormikMaterialUITextField';
