import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getNotificationSettingsKey} from '@constants/api';
import {AdditionalCondition, NotificationType} from './types';

export type NotificationSettingsListResult = {
  inspectionReminder: {
    isEnabled: boolean;
    xDaysAgo: number;
  };
  rentNotification: {
    isEnabled: boolean;
  };
  returnNotification: {
    isEnabled: boolean;
  };
  rentalAmountNotification: {
    isEnabled: boolean;
  };
  faultRepairPicNotification: {
    isEnabled: boolean;
  };
  pmdaUploadNotification: {
    isEnabled: boolean;
  };
  pmdaRecallNotification: {
    isEnabled: boolean;
  };
};

export type UpdateNotificationSettingsParams = {
  notificationType: NotificationType;
  isEnabled: boolean;
  additionalCondition?: AdditionalCondition;
};

const getNotificationSettings = async (hospitalHashId: string, hospitalUserHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${hospitalUserHashId}/notifications/settings`;
  const {data} = await axios.get<NotificationSettingsListResult>(baseUrl);
  return data;
};

export const updateNotificationSettings = async (
  hospitalHashId: string,
  hospitalUserHashId: string,
  data: UpdateNotificationSettingsParams[]
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${hospitalUserHashId}/notifications/settings`;

  return axios.post<UpdateNotificationSettingsParams>(baseUrl, {settings: data});
};

export const useFetchNotificationSettingsQuery = (hospitalHashId: string, hospitalUserHashId: string) => {
  return useQuery(getNotificationSettingsKey, () => getNotificationSettings(hospitalHashId, hospitalUserHashId));
};
