import React from 'react';
import {ViewInspectionTypeToText} from '@Apps/InspectionResultList/pc/consts';
import {HomeIndicatorImage} from '@components/molecules/MobileUnderMenu/HomeIndicatorImage';
import {SearchText} from '@components/molecules/SearchBox/sp/searchText';
import {Check} from '@material-ui/icons';
import {styled} from '@material-ui/styles';
import {ProductInspectionsOptionsType} from '@modules/inspection/hooks';
import {InspectionIndex} from '@modules/inspections/types';
import {Box, Button} from '@material-ui/core';
import {useAtom} from 'jotai';
import {selectedInspectionAtom} from './hooks';

const StyledDialogBox = styled(Box)({
  backgroundColor: 'white',
  margin: '0px',
  boxShadow:
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  zIndex: 2,
  position: 'fixed',
  left: '0',
  bottom: '0',
  maxHeight: '80%',
  width: '100%',
  borderRadius: '16px 16px 0px 0px',
});

const BackgroundBlack = styled('div')({
  backgroundColor: 'black',
  opacity: '.5',
  position: 'absolute',
  width: '100%',
  height: '100vh',
  overflow: 'visible',
  left: '0',
  top: '0',
  zIndex: 1,
});

const StyledContainerDiv = styled('div')({
  padding: '2px 8px',
});

const StyledScrollDiv = styled('div')({
  maxHeight: '500px', // 適切な高さを指定してください
  overflowY: 'auto', // 垂直方向のスクロールを有効にします
  padding: '0px 16px',
});

const StyledFlexDiv = styled('div')({
  display: 'flex',
});

const StyledCompleteButton = styled(Button)({
  fontSize: '16px',
});

const StyledHr = styled('hr')({
  color: '#C6CBD4',
  width: '100%',
  height: '0px',
  margin: '0',
  padding: '0',
});
const StyledCaption = styled('p')({
  color: '#65676B',
  fontSize: '12px',
  margin: '6px 0px 16px',
});

const StyledCheck = styled(Check)({
  fontSize: '12px',
  color: '#0052CC',
});

const StyledTextButton = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '16px',
  margin: '14px 0px 0px',
  fontSize: '16px',
});

/**
 * @param InspectionSheetSelectorProps - InspectionSheetSelectorコンポーネントのPropsの型定義
 * @param inspectionOptions: 点検表のオプションの配列
 * @param completeCallBack:完了時に選択されている点検表のHashIdを返すコールバック
 */
export type InspectionSheetSelectorProps = {
  inspectionOptions: ProductInspectionsOptionsType[];
  completeCallBack: () => void;
  handleSearchInspection: (name: string) => void;
};

type TextElementProps = {
  value: InspectionIndex;
  label: string;
  selectedValue?: InspectionIndex;
  handleSelected: (inspectionIndex: InspectionIndex) => void;
};

const TextElement = ({value, label, selectedValue, handleSelected}: TextElementProps) => {
  const inspectionTypeText =
    ViewInspectionTypeToText[value.type as 'pre_use' | 'in_use' | 'post_use' | 'periodic' | 'maker_periodic' | 'daily'];

  return (
    <>
      <StyledTextButton
        onClick={() => {
          handleSelected(value);
        }}>
        {`${label}`} {value.hashId === selectedValue?.hashId && <StyledCheck />}
      </StyledTextButton>
      <StyledCaption>{inspectionTypeText}</StyledCaption>
    </>
  );
};

type TextButtonListProps = {
  options: ProductInspectionsOptionsType;
  selectedValue?: InspectionIndex;
  handleSelected: (inspectionIndex: InspectionIndex) => void;
};
const TextButtonList = ({options, selectedValue, handleSelected}: TextButtonListProps) => {
  return (
    <>
      <StyledCaption>{options.label}</StyledCaption>
      {options.options.map((v, i) => {
        return (
          <TextElement
            value={v.value}
            label={v.label}
            selectedValue={selectedValue}
            handleSelected={handleSelected}
            key={`text${i}`}
          />
        );
      })}
    </>
  );
};

/**
 * 点検表を選択するためのコンポーネント
 * 検索機能と点検表の一覧表示機能を提供
 */
export const InspectionSheetSelector = ({
  inspectionOptions,
  completeCallBack,
  handleSearchInspection,
}: InspectionSheetSelectorProps) => {
  const [selectedInspectionIndex, setSelectedInspectionIndex] = useAtom(selectedInspectionAtom);
  // 機種に基づく点検表
  const modelOptions = inspectionOptions[0];
  // 全ての点検表
  const allOptions = inspectionOptions.length > 0 ? inspectionOptions[1] : undefined;

  return (
    <>
      <StyledDialogBox>
        <HomeIndicatorImage />
        <StyledContainerDiv>
          <StyledFlexDiv>
            <SearchText
              searchCallback={(text: string) => {
                handleSearchInspection(text);
              }}
            />
            <StyledCompleteButton
              color="primary"
              onClick={() => {
                completeCallBack();
              }}>
              完了
            </StyledCompleteButton>
          </StyledFlexDiv>
          <StyledContainerDiv>
            {modelOptions && modelOptions.options.length > 0 && (
              <TextButtonList
                options={modelOptions}
                selectedValue={selectedInspectionIndex}
                handleSelected={setSelectedInspectionIndex}
              />
            )}
          </StyledContainerDiv>
        </StyledContainerDiv>
        {modelOptions && modelOptions.options.length > 0 && <StyledHr />}
        <StyledScrollDiv>
          {allOptions && allOptions.options.length > 0 && (
            <TextButtonList
              options={allOptions}
              selectedValue={selectedInspectionIndex}
              handleSelected={setSelectedInspectionIndex}
            />
          )}
        </StyledScrollDiv>
      </StyledDialogBox>
      <BackgroundBlack />
    </>
  );
};
