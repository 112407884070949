import React from 'react';
import {MobileTimeline} from '@organisms/MobileTimeline';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {Loading} from '@organisms/Loading';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {styled} from '@material-ui/core';

type TimelineContentProps = {
  hospitalProductHashId: string;
};

export const TimelineContent = (props: TimelineContentProps) => {
  const {hospitalProductHashId} = props;
  const {myInfo} = useMyInfo();

  const {data, isLoading: isLoadingHospitalProduct} = useFetchHospitalProductQuery(
    myInfo.hospitalHashId,
    hospitalProductHashId
  );

  if (isLoadingHospitalProduct || !data) {
    return <Loading />;
  }

  return (
    <Container>
      <MobileTimeline hospitalProductDetail={data} />
    </Container>
  );
};

const Container = styled('div')({
  padding: '16px',
  paddingTop: 0,
});
