import React, {useMemo, useCallback} from 'react';
import {createStyles, Grid, makeStyles} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {Column} from '@molecules/Table/props';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useAtom} from 'jotai';
import {
  listOrder,
  repairListPageAtom,
  repairListTablePageSizeAtom,
  searchSymptomCategoriesAtom,
  searchNameAtom,
  searchPersonInChargeHashIDsAtom,
  searchReq4RepairAtFromAtom,
  searchReq4RepairAtToAtom,
} from '../jotai';
import {useFaultRepairsQuery} from '../hooks';
import {RepairIndex} from '@modules/repairs/types';
import {
  getDealtCategoryByValue,
  getSymptomCategoryLabel,
  getSymptomDetailCategoryLabel,
  getRootCauseCategoryByValue,
} from '@modules/repairs/constants';
import {RouterLinkColumn} from '../components/RouterLinkColumn';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {useAtomValue, useSetAtom} from 'jotai';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchFaultRepairStatuses, useRepairStatusMap} from '@modules/repairs/hooks';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {CategoryFormatter} from '@modules/categories/helpers';
import {isNullish, StrUtil} from '@front-libs/helpers';
import {Table} from '@molecules/Table';
import {getRepairStatusByHashId} from '@modules/repairs/helpers';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';
import {TableViewLayout} from '@components/layouts/TableViewLayout';

type RepairTableProp = {
  currentTableLayout: TableLayout[] | undefined;
};

export const RepairTable: React.VFC<RepairTableProp> = ({currentTableLayout}: RepairTableProp) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [page, setPage] = useAtom(repairListPageAtom);
  const setOrder = useSetAtom(listOrder);
  const searchName = useAtomValue(searchNameAtom);
  const personInChargeHashIDs = useAtomValue(searchPersonInChargeHashIDsAtom);
  const req4RepairAtFrom = useAtomValue(searchReq4RepairAtFromAtom);
  const req4RepairAtTo = useAtomValue(searchReq4RepairAtToAtom);
  const symptomCategories = useAtomValue(searchSymptomCategoriesAtom);
  const {getHospitalUser} = useHospitalUsers();
  const [pageSize, setPageSize] = useAtom(repairListTablePageSizeAtom);

  const {myInfo} = useMyInfo();
  const statusQuery = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const {repairStatusesMap} = useRepairStatusMap(statusQuery.data);

  const noDataComponent = useMemo(() => {
    if (searchName || personInChargeHashIDs.length || req4RepairAtFrom || req4RepairAtTo || symptomCategories.length) {
      return (
        <div>
          <p className={classes.bold}>現在の検索条件に一致する修理はありません。</p>
          <p>検索条件を変えて、再度検索してみてください。</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className={classes.bold}>修理はありません。</p>
          <p>ここで機器の修理対応を管理します。</p>
        </div>
      );
    }
  }, [
    searchName,
    personInChargeHashIDs.length,
    req4RepairAtFrom,
    req4RepairAtTo,
    symptomCategories.length,
    classes.bold,
  ]);

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<RepairIndex>[], TableLayout[]>([], currentTableLayout ?? []);
    return tableColumn.map<Column<RepairIndex>>((item) => {
      switch (item.field) {
        case 'managementId':
          item.render = RouterLinkColumn;
          break;
        case 'statusId':
          item.render = ({statusHashId}: RepairIndex) => getRepairStatusByHashId(repairStatusesMap, statusHashId);
          break;
        case 'rootCategory':
          item.render = ({faultHospitalProduct}: RepairIndex) => {
            return CategoryFormatter.getRootCategory(faultHospitalProduct.categories)?.name ?? '';
          };
          break;
        case 'narrowCategory':
          item.render = ({faultHospitalProduct}: RepairIndex) => {
            return CategoryFormatter.getNarrowCategory(faultHospitalProduct.categories)?.name ?? '';
          };
          break;
        case 'displayName':
          item.render = ({faultHospitalProduct}: RepairIndex) => faultHospitalProduct.displayName;
          break;
        case 'createdAt':
          item.render = ({createdAt}: RepairIndex) => dayjs(createdAt).format('YYYY/MM/DD HH:mm');
          break;
        case 'createdBy':
          item.render = ({createdBy}) => UserFormatter.getFullName(createdBy);
          break;
        case 'requestForRepairAt':
          item.render = ({requestForRepairAt}: RepairIndex) =>
            requestForRepairAt ? dayjs(requestForRepairAt).format('YYYY/MM/DD HH:mm') : '';
          break;
        case 'fixedAt':
          item.render = ({fixedAt}: RepairIndex) => (fixedAt ? dayjs(fixedAt).format('YYYY/MM/DD HH:mm') : '');
          break;
        case 'appliedAtToSuppliesDepartment':
          item.render = ({appliedAtToSuppliesDepartment}: RepairIndex) =>
            appliedAtToSuppliesDepartment ? dayjs(appliedAtToSuppliesDepartment).format('YYYY/MM/DD HH:mm') : '';
          break;
        case 'makerContactAt':
          item.render = ({makerContactAt}: RepairIndex) =>
            makerContactAt ? dayjs(makerContactAt).format('YYYY/MM/DD HH:mm') : '';
          break;
        case 'personInCharge':
          item.render = ({personInChargeHashId}: RepairIndex) => {
            if (isNullish(personInChargeHashId)) return '';
            const user = getHospitalUser(personInChargeHashId);
            if (isNullish(user)) return '';
            return UserFormatter.getFullName(user);
          };
          break;
        case 'symptomCategory':
          item.render = ({symptomCategory}: RepairIndex) => getSymptomCategoryLabel(symptomCategory ?? undefined);
          break;
        case 'symptomDetailCategory':
          item.render = ({symptomDetailCategory}: RepairIndex) =>
            getSymptomDetailCategoryLabel(symptomDetailCategory ?? undefined);
          break;
        case 'rootCauseCategory':
          item.render = ({rootCauseCategory}: RepairIndex) =>
            getRootCauseCategoryByValue(rootCauseCategory ?? undefined);
          break;
        case 'dealtCategory':
          item.render = ({dealtCategory}: RepairIndex) => getDealtCategoryByValue(dealtCategory ?? undefined);
          break;
        case 'costOfFix':
          item.render = ({costOfFix}: RepairIndex) => (!isNullish(costOfFix) ? StrUtil.addComma(costOfFix) : '');
          item.justifyContentBody = 'right';
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [currentTableLayout, repairStatusesMap, getHospitalUser]);

  const faultRepairsQuery = useFaultRepairsQuery();
  const totalPage = useMemo(() => {
    return Math.ceil(faultRepairsQuery.totalCount / pageSize);
  }, [faultRepairsQuery.totalCount, pageSize]);

  const startDisplayPosition = useMemo(() => {
    return faultRepairsQuery.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [faultRepairsQuery.totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > faultRepairsQuery.totalCount ? faultRepairsQuery.totalCount : endPosition;
  }, [page, pageSize, faultRepairsQuery.totalCount]);

  const handleClickRow = useCallback(
    (_e?: React.MouseEvent, row?: RepairIndex) => {
      if (isNullish(row)) return;
      navigate(`/repairs/${row.hashId}`);
    },
    [navigate]
  );
  const handleChangePage = useCallback((_e: React.ChangeEvent<unknown>, p: number) => setPage(p), [setPage]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder({
        orderColumn: columnIndex === -1 ? 'createdAt' : String(serializedTableColumn[columnIndex].field),
        direction: columnIndex === -1 ? 'desc' : orderDirection,
      });
    },
    [serializedTableColumn, setOrder]
  );

  return (
    <>
      <TableViewLayout.Body>
        <Table<RepairIndex>
          stickyHeader={true}
          columns={serializedTableColumn}
          data={faultRepairsQuery.data}
          showSelection={false}
          onRowClick={handleClickRow}
          noDataComponent={noDataComponent}
          onOrderChange={handleOrderChange}
          isLoading={faultRepairsQuery.isLoading}
          tableSize="small"
        />
      </TableViewLayout.Body>
      <TableViewLayout.Footer container justifyContent="space-between">
        <Grid item className={classes.pageDescription}>
          {faultRepairsQuery.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
        </Grid>
        <Grid item className={classes.paginationContainer}>
          <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
          <DisplayNumberSelect
            pageSize={pageSize}
            update={(selectNum) => {
              setPageSize(selectNum);
              setPage(1);
            }}
          />
        </Grid>
        <Grid className={classes.emptyGrid} />
      </TableViewLayout.Footer>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    pageDescription: {
      margin: 'auto 0px',
      flex: 1,
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
    },
    emptyGrid: {
      flex: 1,
    },
    pageSize: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  })
);
