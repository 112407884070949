import {FilterOption} from '@molecules/Drawers/FilterDrawer/types';
import {isNullish} from '@front-libs/helpers';

export const waysOfPurchaseOptions = [
  {label: '購入', value: 'purchase'},
  {label: 'リース', value: 'lease'},
  {label: 'レンタル', value: 'rental'},
  {label: '代替品', value: 'alternative'},
  {label: 'デモ機', value: 'demo'},
  {label: '寄贈', value: 'gift'},
  {label: '移管', value: 'transfer_control'},
];

const waysOfPurchaseOptionsMap = Object.fromEntries(waysOfPurchaseOptions.map((item) => [item.value, item.label]));

export function getWaysOfPurchaseOptions(value?: string): string {
  if (isNullish(value)) return '';
  return waysOfPurchaseOptionsMap[value] ?? '';
}

type Option = {
  label: string;
  value: string;
};

export const productFilterConditions: (
  roomOptions: Option[],
  wardOptions: Option[],
  createdByOptions: Option[]
) => FilterOption[] = (roomOptions, wardOptions, createdByOptions) => [
  {
    label: '管理番号',
    value: 'managementId',
    optionType: 'text',
  },
  {
    label: 'シリアル番号',
    value: 'serialNumber',
    optionType: 'text',
  },
  {
    label: 'ロット番号',
    value: 'lotNumber',
    optionType: 'text',
  },
  {
    label: '機種名',
    value: 'displayName',
    optionType: 'text',
  },
  {
    label: '型式',
    value: 'name',
    optionType: 'text',
  },
  {
    label: '定価',
    value: 'catalogPrice',
    optionType: 'number',
    min: 0,
    unitLabel: '円',
  },

  {
    label: '機器管理場所（大エリア）',
    value: 'hospitalWardHashId',
    optionType: 'selector',
    options: wardOptions,
  },
  {
    label: '機器管理場所（小エリア）',
    value: 'hospitalRoomHashId',
    optionType: 'selector',
    options: roomOptions,
  },

  {
    label: '貸出区分',
    value: 'permanentlyAssigneds',
    optionType: 'selector',
    options: [
      {label: '貸出不可', value: true},
      {label: '貸出可', value: false},
    ],
  },
  {
    label: '機器貸出場所（大エリア）',
    value: 'rentHospitalWardHashId',
    optionType: 'selector',
    options: wardOptions,
  },
  {
    label: '機器貸出場所（小エリア）',
    value: 'rentHospitalRoomHashId',
    optionType: 'selector',
    options: roomOptions,
  },
  {
    label: '親機・子機',
    value: 'isBaseUnit',
    optionType: 'selector',
    options: [
      {label: '親機', value: true},
      {label: '子機', value: false},
    ],
  },
  // カレンダー
  {
    label: '購入日',
    value: 'dateOfPurchase',
    optionType: 'dateRange',
  },
  {
    label: '点検開始日',
    value: 'periodicInspectionStartDate',
    optionType: 'dateRange',
  },
  {
    label: '購入区分',
    value: 'waysOfPurchases',
    optionType: 'selector',
    options: waysOfPurchaseOptions,
  },

  {
    label: '購入元',
    value: 'purchasedNationalExpense',
    optionType: 'selector',
    options: [
      {label: '国費', value: true},
      {label: '院費', value: false},
    ],
  },
  {
    label: '院内耐用年数',
    value: 'legalDurableYear',
    optionType: 'number',
    min: 0,
    unitLabel: '年',
  },
  {
    label: '資産番号',
    value: 'assetRegisterNumber',
    optionType: 'text',
  },

  {
    label: '保守契約',
    value: 'isMaintenanceContract',
    optionType: 'selector',
    options: [
      {label: '保守契約', value: true},
      {label: '保守契約外', value: false},
    ],
  },

  {
    label: 'レンタル番号',
    value: 'rentalId',
    optionType: 'text',
  },
  {
    label: 'レンタル企業名',
    value: 'rentalDealerName',
    optionType: 'text',
  },
  {
    label: 'レンタル金額',
    value: 'rentalFee',
    optionType: 'number',
  },

  // レンタル開始日
  {
    label: 'レンタル開始日',
    value: 'rentalStartDate',
    optionType: 'dateRange',
  },
  // レンタル終了予定日
  {
    label: 'レンタル終了予定日',
    value: 'rentalDueDateFrom',
    optionType: 'dateRange',
  },
  // レンタル機器返却日
  {
    label: 'レンタル機器返却日',
    value: 'rentalReturnDateFrom',
    optionType: 'dateRange',
  },

  // リース関連
  {
    label: 'リース番号',
    value: 'leaseId',
    optionType: 'text',
  },
  {
    label: 'リース企業名',
    value: 'leaseDealerName',
    optionType: 'text',
  },
  {
    label: 'リース金額',
    value: 'leaseFee',
    optionType: 'number',
    min: 0,
    unitLabel: '円',
  },
  {
    label: 'リース開始日',
    value: 'leaseStartDateFrom',
    optionType: 'dateRange',
  },
  {
    label: 'リース終了予定日',
    value: 'leaseDueDateFrom',
    optionType: 'dateRange',
  },
  {
    label: 'リース機器返却日',
    value: 'leaseReturnDate',
    optionType: 'dateRange',
  },

  // 廃棄日
  {
    label: '廃棄日',
    value: 'dateOfDisposal',
    optionType: 'dateRange',
  },
  {
    label: '廃棄理由',
    value: 'reasonOfDisposal',
    optionType: 'text',
  },
  {
    label: 'バーコード読み取り値',
    value: 'optionalBarcode',
    optionType: 'text',
  },
  {
    label: 'GS1バーコード',
    value: 'rawBarcode',
    optionType: 'text',
  },

  // 登録日
  {
    label: '登録者',
    value: 'createdByHashId',
    optionType: 'selector',
    options: createdByOptions,
  },
  {
    label: '登録日',
    value: 'createdAt',
    optionType: 'dateRange',
  },

  {
    label: '備考',
    value: 'notes',
    optionType: 'text',
  },

  // {
  //   label: '院内耐用年数（年）',
  //   value: 'legalDurableYear',
  //   optionType: 'number',
  // },
  // {
  //   label: '製造番号（S/N）',
  //   value: 'serialNumber',
  //   optionType: 'text',
  // },
];

export const pageRoutingMenuItems = [
  {
    label: '機種を検索して登録',
    value: 'registration',
  },
  {
    label: 'Excelから一括登録',
    value: 'import',
  },
  {
    label: 'GS1-128を読み込んで登録',
    value: 'gs1_dialog',
  },
];
