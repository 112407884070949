import {TableBody, TableCell, TableRow} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {StatusTable, StyledTableHead, StyledTableRow} from '.';

/** キャッシュストレージから情報取得 */
const getFromCache = async () => {
  const cacheNames = await caches.keys();
  let total = 0;

  for (const cacheName of cacheNames) {
    const cache = await caches.open(cacheName);
    const requests = await cache.keys();

    for (const request of requests) {
      const response = await cache.match(request);

      if (response) {
        const headers = response.headers;
        if (headers.has('content-length')) {
          total += Number(headers.get('content-length'));
        } else {
          // content-lengthがない場合、一部のブラウザではResponseオブジェクトをarrayBufferに変換し、その長さを取得できます。
          // 注意: response.arrayBuffer() はレスポンスを一度しか読むことができないため、読んだ後に再度必要な場合はクローンする必要があります。
          const clonedResponse = response.clone();
          const buffer = await clonedResponse.arrayBuffer();
          total += buffer.byteLength;
        }
      }
    }
  }
  return total;
};

/**
 * キャッシュストレージ情報
 * @returns
 */
export const CacheStorageList = () => {
  const [total, setTotal] = useState(0);
  useEffect(() => {
    (async () => {
      const cacheSize = await getFromCache();
      setTotal(cacheSize);
    })();
  }, []);
  return (
    <StatusTable>
      <StyledTableHead>
        <TableRow>
          <TableCell colSpan={2}>CacheStorage</TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        <StyledTableRow>
          <TableCell component="th" scope="row">
            ファイルサイズ
          </TableCell>
          <TableCell align="right"> {`${total.toLocaleString()} byte`}</TableCell>
        </StyledTableRow>
      </TableBody>
    </StatusTable>
  );
};
