import {styled, Theme} from '@material-ui/core';
import React from 'react';

interface ProductStatusIconProps {
  status: string;
}

const StyledCircle = styled('div')(({status, theme}: {status: string; theme: Theme}) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: getBackgroundColor(status, theme),
  marginTop: '8px',
}));

const getBackgroundColor = (status: string, theme: Theme) => {
  switch (status) {
    case 'ready':
      return '#3C9E58';
    case 'working':
      return '#C7243A';
    case 'uninspected':
      return '#826149';
    case 'repairing':
      return theme.palette.primary.dark;
    case 'disabled':
      return '#727272';
    default:
      return '#3C9E58';
  }
};

export const ProductStatusIcon = ({status}: ProductStatusIconProps) => {
  return <StyledCircle status={status} />;
};
