import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export const DialogContent = withStyles({
  root: {
    flex: '1 1 auto',
    height: '100%',
    padding: `0 24px 16px`,
    maxHeight: 'calc(100vh - 192px)',
    overflow: 'auto',
  },
})(Grid);
