import React, {useCallback} from 'react';
import {makeStyles, Grid, Typography, IconButton} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {CategoryIndex} from '@modules/categories/types';
import {selectedProductsAtom} from './states';
import {useAtom} from 'jotai';
import {WholeProductIndex} from '@modules/products/types';
import {isNullish} from '@front-libs/helpers';

const CategoryInfo: React.VFC<{categories: CategoryIndex[]}> = ({categories}) => {
  if (!categories) {
    return null;
  }

  const lastCategory = categories[categories.length - 1];
  const categoryText = categories.length === 1 ? '大分類' : '小分類';

  return <Typography variant="body2">{`${categoryText} ${lastCategory?.name}`}</Typography>;
};

const useSearchProductInfoStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    border: '1px solid #EAF0F5',
    cursor: 'pointer',
  },
  checkBoxContainer: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    flex: '1 0 auto',
    padding: '16px',
  },
}));

type ProductInfoProps = {
  product: WholeProductIndex;
};

const ProductInfo: React.VFC<ProductInfoProps> = (props) => {
  const {product} = props;
  const classes = useSearchProductInfoStyles();

  return (
    <Grid className={classes.infoContainer} container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="body2" style={{fontWeight: 'bold'}}>
          {!isNullish(product.name) && `${product.name} / `}
          {product.displayName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">メーカー {product.maker?.name ?? ''}</Typography>
      </Grid>
      <Grid item>
        <CategoryInfo categories={product.categories ?? []} />
      </Grid>
    </Grid>
  );
};

const useSelectedProductCardStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    border: '1px solid #CDD6E1',
    cursor: 'pointer',
    padding: '0px 16px',
    background: '#EAF0F5',
  },
  infoContainer: {
    flex: '1 1 auto',
  },
  removeButtonContainer: {
    width: '30px',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type SelectedProductCardProps = {
  product: WholeProductIndex;
  onRemove: (productHashId: string) => void;
};

const SelectedProductCard: React.VFC<SelectedProductCardProps> = (props) => {
  const {product, onRemove} = props;

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      onRemove(product.hashId);
    },
    [onRemove, product.hashId]
  );

  const classes = useSelectedProductCardStyles();
  return (
    <li className={classes.container}>
      <Grid className={classes.infoContainer}>
        <ProductInfo product={product} />
      </Grid>
      <div className={classes.removeButtonContainer}>
        <IconButton onClick={handleClick}>
          <Clear />
        </IconButton>
      </div>
    </li>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '40px',
  },
  title: {
    fontWeight: 'bold',
  },
  products: {
    flex: '1',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginTop: '16px',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    '& > :not(:first-child)': {
      marginTop: '16px',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export const SelectedProducts: React.VFC = (props) => {
  const classes = useStyles();
  const [products, setProducts] = useAtom(selectedProductsAtom);

  const handleProductRemove = useCallback(
    (hashId: string) => {
      setProducts((prevProducts) => prevProducts.filter((p) => p.hashId !== hashId));
    },
    [setProducts]
  );

  return (
    <Grid container className={classes.container}>
      <Typography variant="body2" className={classes.title} style={{fontWeight: 'bold'}}>
        {`選択されている機種 (${products.length})`}
      </Typography>
      <ul className={classes.products}>
        {products.map((p) => (
          <SelectedProductCard key={p.hashId} product={p} onRemove={handleProductRemove} />
        ))}
      </ul>
    </Grid>
  );
};
