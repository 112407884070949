import React from 'react';
import {createStyles, Grid, makeStyles, Theme, Link, Typography} from '@material-ui/core';
import {InspectionStatus as InspectionStatusIndicator} from '@components/molecules/InspectionStatus';
import {InspectionListElement} from '@Apps/InspectionSetting/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  })
);

type InspectionNameColumnProps = {
  rowData: InspectionListElement;
  handleClickRow: (_e: React.MouseEvent, data: InspectionListElement) => void;
};

export const InspectionNameColumn: React.VFC<InspectionNameColumnProps> = ({rowData, handleClickRow}) => {
  const classes = useStyles();

  return (
    <Grid item xs container direction="column">
      <Link onClick={(e) => handleClickRow(e, rowData)} className={classes.name}>
        {rowData.name.name}
      </Link>
      <Typography style={{color: '#7C98B6', fontSize: '0.875rem'}}>
        <InspectionStatusIndicator status={rowData.name.status as 'available' | 'draft'} />
      </Typography>
    </Grid>
  );
};
