import React, {useCallback, useMemo, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {dialogHandler, DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {yup} from '@front-libs/core';
import Selector from '@molecules/Formik/fields/Selector';
import {
  FetchHospitalProductsParams,
  getHospitalProductFaultRepairHistories,
  getHospitalProducts,
} from '@modules/hospital_products/api';
import {
  SymptomCategoryType,
  RepairStatuses,
  SymptomDetailCategoryType,
  FetchFaultRepairPropertyRequrementsParams,
  RepairIndex,
} from '@modules/repairs/types';
import {UserIndex} from '@modules/hospital_users/types';
import {Close} from '@material-ui/icons';
import AsyncSelector from '@molecules/Formik/fields/AsyncSelector';
import {convertDateToSimpleDateTime, isNullish} from '@front-libs/helpers';
import dayjs from 'dayjs';
import {SameHospitalProductDialog} from './SameHospitalProductDialog';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {useFaultRepairPropertyRequirements} from '@modules/repairs/hooks';
import {formSection} from '@Apps/RepairDetail/constants';
import {SelectOptionProps} from '@molecules/Formik/fields/StandardSelectorField';
import {symptomDetailCategoryOptions} from '@modules/repairs/constants';
import {styled} from '@material-ui/styles';

type Props = {
  hospitalHashId: string;
  hospitalUsers: UserIndex[];
  repairStatuses: RepairStatuses[];
  defaultValues?: Partial<RegistrationRepairDialogProps>;
  readOnlyKeys?: [keyof RegistrationRepairDialogProps];
} & DialogProps<Partial<RegistrationRepairDialogProps>>;

type FormFieldsType = {
  value: keyof RepairIndex;
  label: string;
  type: string;
  isRequired: boolean | undefined;
  sequence: number | undefined;
  options: SelectOptionProps[] | undefined;
}[];

type RepairFromProps = {formFields: FormFieldsType} & Props;

const FullWidthMB16Div = styled('div')({
  width: '100%',
  marginBottom: '16px',
});
const FullWidthForm = styled(Form)({
  width: '100%',
});

const FlexGrowDiv = styled('div')({
  flexGrow: 1,
});

const RepairForm: React.VFC<RepairFromProps> = (props) => {
  const {isValid, submitForm, values} = useFormikContext<Partial<RegistrationRepairDialogProps>>();
  const [isSelected, setIsSelected] = useState(false);
  const hideProductSelector = useMemo(
    () => props.readOnlyKeys?.includes('hospitalProductHashId'),
    [props.readOnlyKeys]
  );

  const loadHospitalProductOptions = useCallback(
    async (newValue: string) => {
      const baseParams: FetchHospitalProductsParams = {
        perPage: 100,
        statuses: 'ready,working,uninspected,repairing',
        order: 'management_id',
      };
      if (newValue !== '') baseParams.name = newValue;
      const res = await getHospitalProducts(props.hospitalHashId, baseParams);
      return res.data.map((item) => ({label: item.managementId, value: item.hashId}));
    },
    [props.hospitalHashId]
  );

  const handleOnChange = useCallback((e: {label: string; value: string}) => {
    if (!isNullish(e)) {
      setIsSelected(true);
    }
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') return;
        props.actions.reject(e);
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}>
      <DialogTitle>
        <Grid container>
          <Grid item>
            <span>修理を新規作成</span>
          </Grid>
          <FlexGrowDiv />
          <Grid item>
            <Button onClick={() => props.actions.reject()}>
              <Close />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <FullWidthForm>
            {!hideProductSelector && (
              <FullWidthMB16Div>
                <RequiredLabel>修理対象機器</RequiredLabel>
                <AsyncSelector
                  name="hospitalProductHashId"
                  placeholder={'管理番号で検索...'}
                  loadOptions={loadHospitalProductOptions}
                  onChange={handleOnChange}
                />
              </FullWidthMB16Div>
            )}
            {props.formFields.map((field, index) => {
              if (field.value === 'symptomCategory') {
                // 事象詳細区分は事象区分（symptomCategory）が内部故障として選択されたときのみ表示する
                return (
                  <React.Fragment key={`field_${index}`}>
                    <FullWidthMB16Div>
                      {field.isRequired ? <RequiredLabel>{field.label}</RequiredLabel> : <label>{field.label}</label>}
                      <Selector name={field.value} size={'small'} options={field.options} />
                    </FullWidthMB16Div>
                    {values.symptomCategory === 'internal_failure' && (
                      <FullWidthMB16Div>
                        <label>事象詳細区分</label>
                        <Selector name="symptomDetailCategory" size={'small'} options={symptomDetailCategoryOptions} />
                      </FullWidthMB16Div>
                    )}
                  </React.Fragment>
                );
              } else {
                return (
                  <FullWidthMB16Div key={`field_${index}`}>
                    {field.isRequired ? <RequiredLabel>{field.label}</RequiredLabel> : <label>{field.label}</label>}
                    {field.type === 'select' ? (
                      <Selector name={field.value} size={'small'} options={field.options} />
                    ) : (
                      <TextField
                        type={field.type === 'datetime' || field.value === 'dateOfDisposal' ? 'date' : field.type}
                        name={field.value}
                        size={'small'}
                        fullWidth
                      />
                    )}
                  </FullWidthMB16Div>
                );
              }
            })}
          </FullWidthForm>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid || (!hideProductSelector && !isSelected)}
          variant={'contained'}
          color="primary"
          onClick={submitForm}>
          登録
        </Button>
        <Button onClick={() => props.actions.reject()} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type RegistrationRepairDialogProps = {
  hospitalProductHashId: string;
  symptomCategory?: SymptomCategoryType;
  symptomDetailCategory?: SymptomDetailCategoryType;
  causeOfIssueDetail?: string;
  requestUserHashId?: string;
  requests?: string;
  requestForRepairAt?: string;
  substitutionHospitalProductHashId?: string;
  statusHashId?: string;
};

export const RegistrationRepairDialog: React.VFC<Props> = (props) => {
  const startStatusHashId = useMemo(
    () => props.repairStatuses.find((status) => status.statusType === 'start')?.hashId,
    [props.repairStatuses]
  );
  const statusOptions = useMemo(
    () =>
      props.repairStatuses.map((item) => ({
        value: item.hashId,
        label: item.statusName,
      })),
    [props.repairStatuses]
  );
  const params: FetchFaultRepairPropertyRequrementsParams = {
    faultRepairStatusHashID: startStatusHashId,
    order: 'sequence',
  };
  const {data} = useFaultRepairPropertyRequirements(params);

  const formFields = useMemo(() => {
    const fields = formSection(props.hospitalUsers).flatMap((form) =>
      form.fields
        .filter((field) => data?.some((item) => item.property === field.name))
        .map((field) => {
          const matchedData = data?.find((item) => item.property === field.name);
          return {
            value: field.name,
            label: field.label,
            type: field.type,
            isRequired: matchedData?.isRequired,
            sequence: matchedData?.sequence,
            options: field.options,
          };
        })
    );

    // ステータスはformSectionに含まれていないため、ここで追加
    const faultRepairStatus = data?.find((val) => val.property === 'statusHashId');
    if (faultRepairStatus) {
      fields.push({
        value: faultRepairStatus.property,
        label: 'ステータス',
        type: 'select',
        isRequired: faultRepairStatus.isRequired,
        sequence: faultRepairStatus.sequence,
        options: statusOptions,
      });
    }
    return fields.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0));
  }, [data, props.hospitalUsers, statusOptions]);

  const validationSchema = useMemo(
    () =>
      yup.object(
        formFields.reduce((obj: Record<string, yup.StringSchema>, item) => {
          obj[item.value] = item.isRequired ? yup.string().required() : yup.string();
          return obj;
        }, {})
      ),
    [formFields]
  );

  const handleSubmit = async (res: Partial<RegistrationRepairDialogProps>) => {
    const {
      data: {data: repairHistories},
    } = await getHospitalProductFaultRepairHistories(props.hospitalHashId, res.hospitalProductHashId ?? '', {
      order: '-created_at',
    });

    const excludeStatuses = props.repairStatuses
      .filter((item) => ['complete', 'archive'].includes(item.statusType))
      .map((item) => item.hashId);

    // repairHistories の件数が何件かあって、完了 or 廃棄ではない場合に重複ダイアログを上げる
    if (repairHistories.length > 0 && !excludeStatuses.includes(repairHistories[0].statusHashId)) {
      const status = props.repairStatuses.find((item) => item.hashId === repairHistories[0].statusHashId);
      try {
        await dialogHandler.open(SameHospitalProductDialog, {
          managementId: repairHistories[0].faultHospitalProduct.managementId,
          statusName: status?.statusName ?? '不明なステータス',
        });
      } catch (_e) {
        props.actions.reject();
        return;
      }
    }

    props.actions.resolve({
      ...res,
      // symptomDetailCategory は現状 internal_failure の場合のみ設定する。それ以外は undefined にする
      symptomDetailCategory: res.symptomCategory === 'internal_failure' ? res.symptomDetailCategory : undefined,
    });
  };
  const initialValues: Partial<RegistrationRepairDialogProps> = useMemo(
    () => ({
      ...props.defaultValues,
      requestForRepairAt: convertDateToSimpleDateTime(dayjs().toDate()),
    }),
    [props.defaultValues]
  );

  const checkInitialValidation = useCallback((schema) => {
    try {
      schema.validateSync({});
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialValid={checkInitialValidation(validationSchema)}>
      <RepairForm {...props} formFields={formFields} />
    </Formik>
  );
};
