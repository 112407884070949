import {atomWithReset} from 'jotai/utils';

export type SearchVariable = {
  name: string | null;
  trainingPlanHashIds: string[] | null;
  trainingScheduleHashIds: string[] | null;
  page: number;
  perPage: number;
  order: string | null;
};

export const searchVariablesAtom = atomWithReset<SearchVariable>({
  name: null,
  trainingPlanHashIds: null,
  trainingScheduleHashIds: null,
  page: 0,
  perPage: 20,
  order: null,
});
