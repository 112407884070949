import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import Selector from '@molecules/Formik/fields/Selector';
import {Checkbox, TextField} from '@molecules/Formik/fields';
import {yup} from '@front-libs/core';
import {InferType} from 'yup';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {SessionExpirationTimes} from '../consts';

const USERNAME_MAX_LENGTH = 'ユーザーIDは64文字以内にしてください';

const validationSchema = yup.object({
  username: yup.string().required().max(64, USERNAME_MAX_LENGTH),
  fullName: yup.string().required(),
  fullNameKana: yup.string(),
  authority: yup.string().required(),
  canReadManagementDashboard: yup.boolean(),
  sessionExpirationTime: yup.number().min(0).default(0),
});

export type NewUserRegistrationResult = InferType<typeof validationSchema>;

const HospitalUserForm: React.VFC<NewUserRegistrationDialogProps> = (props) => {
  const {submitForm, values, isValid, errors, setFieldValue} = useFormikContext<NewUserRegistrationResult>();
  const handleClickResist = useCallback(() => {
    submitForm();
    props.actions.resolve(values);
  }, [props.actions, submitForm, values]);

  useEffect(() => {
    if (values.authority === 'rental') {
      setFieldValue('sessionExpirationTime', 0);
    }
  }, [setFieldValue, values.authority]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.actions.reject()}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'md'}>
      <DialogTitle>ユーザーを追加</DialogTitle>
      <DialogContent>
        <Grid container>
          <div style={{width: '100%', marginBottom: '16px'}}>
            初期パスワードはランダムに生成され、次のダイアログに表示されます。
            <br />
            初期パスワードはコピーの上、新規登録したユーザーにご連絡ください。
          </div>
          <Form style={{width: '100%'}}>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <RequiredLabel>ユーザーID</RequiredLabel>
              <TextField
                name="username"
                size={'small'}
                fullWidth
                error={errors.username === USERNAME_MAX_LENGTH}
                helperText={errors.username === USERNAME_MAX_LENGTH ? USERNAME_MAX_LENGTH : ''}
              />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <RequiredLabel>氏名（漢字）</RequiredLabel>
              <TextField name="fullName" size={'small'} fullWidth />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <label>氏名（かな）</label>
              <TextField name="fullNameKana" size={'small'} fullWidth />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <RequiredLabel>権限</RequiredLabel>
              <Selector
                name="authority"
                size={'small'}
                options={[
                  {label: '一般ユーザー', value: 'user'},
                  {label: 'ユーザー管理者', value: 'userAdmin'},
                  {label: '貸出・返却のみ', value: 'rental'},
                ]}
              />
            </div>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <RequiredLabel>自動ログアウト</RequiredLabel>
              <Selector
                name="sessionExpirationTime"
                size="small"
                isDisabled={values.authority === 'rental'}
                options={[
                  {label: '自動ログアウトしない', value: 0},
                  ...SessionExpirationTimes.map((min) => ({
                    label: `自動ログアウトを${min}分後に行う`,
                    value: 60 * min,
                  })),
                ]}
              />
            </div>
            {props.showGrantManagementDashboard && (
              <div style={{width: '100%', marginBottom: '16px'}}>
                <Checkbox name="canReadManagementDashboard" size={'small'} label="経営ダッシュボードの閲覧を許可" />
              </div>
            )}
          </Form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={handleClickResist}>
          登録
        </Button>
        <Button onClick={() => props.actions.reject()} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type NewUserRegistrationDialogProps = {
  showGrantManagementDashboard: boolean;
} & DialogProps<NewUserRegistrationResult, void>;

export const NewRegistrationDialog: React.VFC<NewUserRegistrationDialogProps> = (props) => {
  const handleSubmit = (res: NewUserRegistrationResult) => {
    props.actions.resolve(res);
  };

  return (
    <Formik<NewUserRegistrationResult>
      initialValues={{
        username: '',
        fullName: '',
        fullNameKana: '',
        authority: '',
        canReadManagementDashboard: false,
        sessionExpirationTime: 0,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}>
      <HospitalUserForm {...props} />
    </Formik>
  );
};
