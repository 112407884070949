import React from 'react';
import {Dropzone} from '@molecules/Dropzone';

type Props = {
  onUpload: (acceptedFile: File) => void;
};

export const ExcelFileDropZone: React.FC<Props> = ({onUpload}) => {
  const handleUpload = (acceptedFiles: File[]) => {
    onUpload(acceptedFiles[0]);
  };

  return (
    <Dropzone
      onDrop={handleUpload}
      accept={[
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ]}
    />
  );
};
