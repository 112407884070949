import {atom} from 'jotai';
import {ResultType, TextConditionValue} from '@components/molecules/Drawers/FilterDrawer/types';
import {FetchWholeProductsInspectionsPeriodProductsParam} from '@modules/inspection_setting/api';
import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {SelectOptionProps} from '@components/molecules/Buttons/PopperSelectBoxButton';
import {ViewInspectionType} from '@Apps/InspectionResultList/pc/types';
import {Order} from '@components/molecules/Table/props';
import {InspectionIndex} from '@modules/inspections/types';

const LOCAL_STORAGE_KEY_INSPECTION_PRODUCT = 'hitotsu/inspection_product_page_size_status';
export const searchFilterResultsAtom = atom<ResultType[]>([]);
export const searchNameAtom = atom<string | null>(null);
export const pageAtom = atomWithReset<number>(1);
export const searchRootCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchSubCategoriesAtom = atom<SelectOptionProps[]>([]);
export const inspectionTypeAtom = atom<ViewInspectionType>('pre_use');
export const pageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_INSPECTION_PRODUCT, 20);
export const selectedMakerAtom = atom<SelectOptionProps[]>([]);
export const orderKeyAtom = atom<Order | null>(null);
export const newInspectionAtom = atom<InspectionIndex | null>(null);

type DetailFilter = {
  name: string;
};

export const inspectionProductsVariables = atom<FetchWholeProductsInspectionsPeriodProductsParam>((get) => {
  const detailFilter = convertFilterResults(get(searchFilterResultsAtom));
  const rootCategories = get(searchRootCategoriesAtom);
  const subCategories = get(searchSubCategoriesAtom);
  const inspectionType = get(inspectionTypeAtom);
  const searchName = get(searchNameAtom);
  const makerName = get(selectedMakerAtom);
  const order = get(orderKeyAtom);

  const _p: FetchWholeProductsInspectionsPeriodProductsParam = {
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    inspectionName: searchName ? searchName : undefined,
    inspectionType: inspectionType === 'daily' ? 'pre_use,in_use,post_use' : inspectionType,
    categoryHashIds:
      subCategories.length > 0
        ? subCategories.map((item) => item.value).join(',')
        : rootCategories.length > 0
        ? rootCategories.map((item) => item.value).join(',')
        : undefined,
    makerHashIds: makerName.length > 0 ? makerName.map((item) => item.value).join(',') : undefined,
    order: order ? `${order.direction === 'desc' ? '-' : ''}${order.fieldId}` : 'rootCategory',
    ...detailFilter,
  };
  return _p;
});

const convertFilterResults = (filterResults: ResultType[]): Partial<DetailFilter> => {
  const detailFilter: Partial<DetailFilter> = {};
  filterResults.forEach((item) => {
    switch (item.key) {
      case 'name':
        detailFilter.name = item.resultValue as TextConditionValue;
        break;
      default:
        break;
    }
  });

  return detailFilter;
};
