import {useCallback} from 'react';
import {PapersType} from './types';

export type UpdatePaperType = PapersType | 'all';
export type DashboardUpdateObserver = (paperType: UpdatePaperType) => void | Promise<void>;

class UpdateDashboardSubject {
  public observers: DashboardUpdateObserver[];

  constructor() {
    this.observers = [];
  }

  public subscribe(func: DashboardUpdateObserver) {
    this.observers.push(func);
  }

  public unsubscribe(func: DashboardUpdateObserver) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notify(data: UpdatePaperType) {
    this.observers.forEach((observer) => observer(data));
  }
}

const updateDashboardSubject = new UpdateDashboardSubject();

export const useUpdateDashboardSubject = () => {
  const subscribe = useCallback((func: DashboardUpdateObserver) => {
    updateDashboardSubject.subscribe(func);
  }, []);
  const unsubscribe = useCallback((func: DashboardUpdateObserver) => {
    updateDashboardSubject.unsubscribe(func);
  }, []);
  const notify = useCallback((data: UpdatePaperType) => {
    updateDashboardSubject.notify(data);
  }, []);
  return {subscribe, unsubscribe, notify};
};
