import React, {useCallback, useMemo, useState} from 'react';
import {Typography, TextField, makeStyles, Radio, Grid} from '@material-ui/core';
import {Launch, Search} from '@material-ui/icons';
import {DEFAULT_INSPECTION_NAME} from '@Apps/Inspection/states';
import {openSnackBar} from '@molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FetchInspectionsParams, createInspection, useFetchInspectionsQuery} from '@modules/inspections/api';
import {InspectionStatus, InspectionType} from '@modules/inspections/enum';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {formatRFC3339Date, isNullish} from '@front-libs/helpers';
import {InspectionNameColumn} from '../../../common/InspectionNameColumn';
import {InspectionUpdatedByColumn} from '../../../common/InspectionUpdatedByColumn';
import {useDebounceState} from '@front-libs/core';
import {Column} from '@molecules/Table/props';
import {UserIndex} from '@modules/hospital_users/types';
import {Table} from '@molecules/Table';
import {InspectionIndex} from '@modules/inspections/types';
import {Pagination} from '@material-ui/lab';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {useAtom} from 'jotai';
import {newInspectionAtom} from '../../jotai';

type EditInspectionElement = {
  hashId: string;
  name: string;
  updatedAt: string;
  updatedBy: UserIndex;
  publishedAt: string;
  status: InspectionStatus;
  rawData: InspectionIndex;
};

type InspectionTableProps = {
  inspectionType: InspectionType;
  currentInspection?: InspectionIndex;
  distInspection?: InspectionIndex;
  onSelectInspection: (newInspection: InspectionIndex) => void;
  onClickInspectionName: (hashId: string) => void;
};

type CreateInspectionType = 'blank' | 'template';

const createInspectionOptions: {label: string; value: CreateInspectionType}[] = [
  {
    label: '新規で点検表を作成',
    value: 'blank',
  },
  {
    label: 'テンプレートを元に作成',
    value: 'template',
  },
];

export const InspectionTable: React.VFC<InspectionTableProps> = (props) => {
  const {currentInspection, distInspection, inspectionType, onSelectInspection} = props;
  const {myInfo} = useMyInfo();
  const classes = useStyles();

  const [searchInspectionName, setSearchInspectionName] = useDebounceState<string | undefined>(undefined, 300);
  const [page, setPage] = useState(1);
  const [orderKey, setOrderKey] = useState<string | null>(null);
  const PAGE_SIZE = 20;
  const [, setNewInspection] = useAtom(newInspectionAtom);

  const params = useMemo(() => {
    const _p: FetchInspectionsParams = {
      page: page - 1,
      perPage: PAGE_SIZE,
      type: inspectionType,
      name: searchInspectionName === '' ? undefined : searchInspectionName,
    };
    if (orderKey) {
      _p.order = orderKey;
    }

    _p.statuses = 'available,draft';
    return _p;
  }, [inspectionType, orderKey, page, searchInspectionName]);

  const query = useFetchInspectionsQuery(myInfo.hospitalHashId, params);
  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / PAGE_SIZE);
  }, [query.totalCount]);

  const handleRadioChange = useCallback(
    (inspection: InspectionIndex) => {
      onSelectInspection(inspection);
      setNewInspection(inspection);
    },
    [onSelectInspection, setNewInspection]
  );

  const handleChangePage = useCallback((_event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const [tableLayout] = useTableLayout('inspectionProductSettingDialogList');

  const listData = query.data.map((data) => {
    return {
      name: data.name || '',
      updatedAt: formatRFC3339Date(data.updatedAt, 'YYYY/MM/DD HH:mm') ?? '',
      updatedBy: data.updatedBy,
      publishedAt: data.publishedAt ? formatRFC3339Date(data.publishedAt, 'YYYY/MM/DD HH:mm') ?? '' : '',
      inspectionProducts: data,
      status: data.status,
      hashId: data.hashId,
      rawData: data,
    };
  });

  // 名前カラムのレンダリングロジック
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderNameColumn = (rowData: EditInspectionElement) => (
    <InspectionNameColumn
      name={{
        name: (
          <Typography className={classes.cell} style={{fontWeight: 'bold'}}>
            {rowData.name}
            <Launch fontSize="small" style={{marginBottom: -4, paddingLeft: 8}} />
          </Typography>
        ),
        status: rowData.status,
      }}
      onClick={() => props.onClickInspectionName(rowData.hashId)}
    />
  );

  // 更新日カラムのレンダリングロジック
  const renderUpdatedAtColumn = (rowData: EditInspectionElement) => (
    <InspectionUpdatedByColumn updatedAt={rowData.updatedAt} updatedBy={rowData.updatedBy} />
  );

  // 割当製品カラムのレンダリングロジック
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderAssignmentProductsColumn = (rowData: EditInspectionElement) => {
    const products = rowData.rawData.inspectionProducts;
    if (products && products.length > 0) {
      return (
        <Typography className={classes.cell} style={{fontWeight: 'bold'}}>
          {products[0].wholeProduct.displayName}
          <br />
          {products.length - 1 === 0 ? '' : `他${products.length - 1}件`}
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.cell} style={{fontWeight: 'bold'}}>
          未割当
        </Typography>
      );
    }
  };

  const serializedTableColumn = useMemo(() => {
    const tableColumn: Column<EditInspectionElement>[] = [
      {
        title: '',
        field: 'hashId',
        render: (rowData: EditInspectionElement) => {
          return (
            <Radio
              checked={
                (isNullish(distInspection) && currentInspection?.hashId === rowData.hashId) ||
                distInspection?.hashId === rowData.hashId
              }
              onChange={() => {
                handleRadioChange(rowData.rawData);
              }}
              value={rowData.hashId}
              color="default"
              name="inspectionId"
            />
          );
        },
      },
      ...tableLayout.currentLayout,
    ];

    return tableColumn.map<Column<EditInspectionElement>>((item) => {
      switch (item.field) {
        case 'name':
          item.render = renderNameColumn;
          break;
        case 'updatedAt':
          item.render = renderUpdatedAtColumn;
          break;
        case 'assignmentProducts':
          item.sorting = false;
          item.render = renderAssignmentProductsColumn;
          break;
      }
      return item;
    });
  }, [
    currentInspection?.hashId,
    distInspection,
    handleRadioChange,
    renderAssignmentProductsColumn,
    renderNameColumn,
    tableLayout.currentLayout,
  ]);

  const handleChangeOrder = useCallback(
    (columnIndex: number, orderDirection: 'desc' | 'asc') => {
      if (columnIndex === -1) {
        setOrderKey(null);
      } else {
        setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(serializedTableColumn[columnIndex].field)}`);
      }
    },
    [serializedTableColumn]
  );

  const handleClickNewInspection = useCallback(async () => {
    try {
      const res = await createInspection(myInfo.hospitalHashId, {
        name: DEFAULT_INSPECTION_NAME,
        type: inspectionType,
        status: 'draft',
      });
      props.onClickInspectionName(res.data.hashId);
      onSelectInspection(res.data);
      query.refetch();
    } catch (e) {
      openSnackBar('点検表作成に失敗しました', 'left', 'bottom', 'error');
    }
  }, [inspectionType, myInfo.hospitalHashId, onSelectInspection, props, query]);

  const handleClickInspectionTemplate = useCallback(() => {
    window.open(`/inspection/templates`, '_blank');
  }, []);

  const handleMenuClick = useCallback(
    (item: MenuItemType, event: React.MouseEvent<Document, MouseEvent>) => {
      switch (item.value) {
        case 'blank':
          handleClickNewInspection();
          break;
        case 'template':
          handleClickInspectionTemplate();
          break;
      }
    },
    [handleClickInspectionTemplate, handleClickNewInspection]
  );

  return (
    <>
      <Grid container item justifyContent="space-between" style={{marginTop: 24}}>
        <TextField
          className={classes.searchBox}
          label="点検表の名前を検索"
          variant="outlined"
          size="small"
          InputProps={{
            className: classes.searchInput,
            endAdornment: <Search />,
          }}
          defaultValue={''}
          onChange={(e) => setSearchInspectionName(e.target.value)}
        />
        <Grid item xs container justifyContent="flex-end" alignItems="center" className={classes.topActions}>
          <PopperMenuButton
            itemsDirection="column"
            placement="bottom-start"
            hiddenArrow={false}
            buttonProps={{variant: 'text', style: {width: '100%', color: 'white', backgroundColor: '#0A52CC'}}}
            menuItemList={createInspectionOptions}
            itemProps={{style: {minWidth: '100px', display: 'block'}}}
            onMenuClick={handleMenuClick}>
            点検表を作成
          </PopperMenuButton>
        </Grid>
      </Grid>
      <Grid container className={classes.table}>
        <Table<EditInspectionElement>
          stickyHeader={true}
          showSelection={false}
          columns={serializedTableColumn}
          data={listData}
          onOrderChange={handleChangeOrder}
          isLoading={query.isLoading}
          tableSize="small"
        />
      </Grid>
      <Grid container className={classes.pagination} justifyContent={'center'}>
        <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    marginTop: '24px',
  },
  searchBox: {
    width: '300px',
  },
  searchInput: {
    background: 'white',
  },
  cell: {
    color: '#2a96e8',
  },
  pagination: {
    marginTop: '16px',
  },
  topActions: {
    marginLeft: 'auto',
    height: 'auto',
  },
}));
