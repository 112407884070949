import React, {useMemo} from 'react';
import {createStyles, makeStyles, Theme, Typography, Grid} from '@material-ui/core';
import clsx from 'clsx';

const useStyle = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    status: {
      flex: '0 0 65px',
      borderRadius: '100px',
      padding: '2px 0px',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    rent: {
      background: '#DEEBFF',
      color: '#2A96E8',
      borderColor: '#2A96E8',
    },
    returned: {
      background: '#EAF5EA',
      color: '#3C9E58',
      borderColor: '#3C9E58',
    },
  })
);

type RentalStatusBadgeProps = {
  isReturned: boolean;
};

export const RentalStatusBadge: React.VFC<RentalStatusBadgeProps> = (props) => {
  const {isReturned} = props;
  const classes = useStyle();

  const text = useMemo(() => (isReturned ? '返却済み' : '貸出中'), [isReturned]);
  const textClass = useMemo(
    () => (isReturned ? clsx(classes.status, classes.returned) : clsx(classes.status, classes.rent)),
    [classes.returned, classes.status, classes.rent, isReturned]
  );

  return (
    <Grid className={classes.root}>
      <Typography className={textClass} variant="inherit">
        {text}
      </Typography>
    </Grid>
  );
};
