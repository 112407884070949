import React from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBoxContainer: {},
    searchBox: {
      width: '270px',
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
  })
);

type SearchBarProps = {
  defaultSearchName: string;
  defaultType: string | null;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
};

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  const classes = useStyles();
  const {defaultSearchName, onChangeSearchName} = props;

  return (
    <Grid container className={classes.searchBoxContainer}>
      <TextField
        label="点検表の名前を検索"
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: <Search />,
        }}
        InputLabelProps={{
          style: {
            fontSize: 14,
          },
        }}
        defaultValue={defaultSearchName}
        className={classes.searchBox}
        onChange={onChangeSearchName}
      />
    </Grid>
  );
};
