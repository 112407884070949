import React, {useMemo} from 'react';
import _ from 'lodash';
import {
  makeStyles,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import {CategoryFormatter} from '@modules/categories/helpers';
import {HospitalProductIndex} from '@modules/hospital_products/types';

export type TableProps = {
  rentingHospitalProducts: HospitalProductIndex[];
  rentRoom: string;
  onClickCancel: (rowIndex: number) => void;
};

export const RentProductTable: React.VFC<TableProps> = ({rentingHospitalProducts, rentRoom, onClickCancel}) => {
  const classes = useStyles();

  const tableRows = useMemo<Partial<HospitalProductIndex & {rentRoom: string}>[]>(() => {
    return [
      ...rentingHospitalProducts.map((item) => ({
        ...item,
        rentRoom,
      })),
      ...(rentingHospitalProducts.length < 4 ? Array(4 - rentingHospitalProducts.length).fill({}) : []),
    ];
  }, [rentRoom, rentingHospitalProducts]);

  return (
    <Grid container direction="column" style={{fontFamily: 'Roboto'}}>
      <Grid item>
        <div className={classes.tableCaption}>貸出する医療機器リスト</div>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
          <Table className={classes.table} aria-label="simple table" stickyHeader>
            {/* captionのスタイルは詳細度の問題でclassにすると適用されないのでstyleに直指定 */}
            <caption
              style={{
                textAlign: 'right',
                color: '#374659',
                fontSize: 24,
                position: 'sticky',
                bottom: 0,
                left: 0,
                zIndex: 2,
                backgroundColor: '#fff',
              }}>
              {`合計${rentingHospitalProducts.length}点`}
            </caption>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.managementIdHeadCell}>管理番号</TableCell>
                <TableCell align="left" className={classes.rentRoomHeadCell}>
                  貸出場所
                </TableCell>
                <TableCell align="left" className={classes.categoryHeadCell}>
                  小分類
                </TableCell>
                <TableCell align="left" className={classes.displayNameHeadCell}>
                  機種名
                </TableCell>
                <TableCell align="left" className={classes.cancelBtnHeadCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell component="th" scope="row" className={classes.managementIdBodyCell}>
                    {_.truncate(row.managementId, {length: 12})}
                  </TableCell>
                  <TableCell className={classes.rentRoomBodyCell}>{row.rentRoom}</TableCell>
                  <TableCell className={classes.categoryBodyCell}>
                    {CategoryFormatter.getNarrowCategory(row.categories ?? [])?.name ?? ''}
                  </TableCell>
                  <TableCell className={classes.displayNameBodyCell}>
                    {_.truncate(row.displayName, {length: 22})}
                  </TableCell>
                  <TableCell className={classes.cancelBtnCell}>
                    {row.managementId && (
                      <Button variant="outlined" className={classes.cancelButton} onClick={() => onClickCancel(index)}>
                        キャンセル
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: 10,
    maxHeight: 360,
  },
  table: {
    minWidth: 650,
  },
  tableCaption: {
    textAlign: 'center',
    marginBottom: 16,
    fontSize: 24,
  },
  managementIdHeadCell: {
    minWidth: 100,
    fontSize: 24,
    backgroundColor: theme.palette.common.white,
    padding: '0px 8px 0px 8px',
    [theme.breakpoints.up('laptop')]: {
      padding: '8px 16px 8px 32px',
    },
  },
  tableRow: {
    height: 48,
    [theme.breakpoints.up('laptop')]: {
      height: 56,
    },
  },
  managementIdBodyCell: {
    width: 120,
    fontWeight: 700,
    color: '#2a96e8',
    fontSize: 28,
    padding: '0px 8px 0px 8px',
    [theme.breakpoints.up('laptop')]: {
      fontSize: 32,
      padding: '0px 16px 0px 32px',
    },
  },

  rentRoomHeadCell: {
    backgroundColor: theme.palette.common.white,
    padding: '0px 8px 0px 0px',
    [theme.breakpoints.up('laptop')]: {
      padding: '0 16px 0 0',
    },
  },
  rentRoomBodyCell: {
    padding: '0px 8px 0px 0px',
    maxWidth: 96,
    wordBreak: 'break-all',
    [theme.breakpoints.up('laptop')]: {
      padding: '0 16px 0 0',
    },
  },

  returnRoomHeadCell: {
    backgroundColor: theme.palette.common.white,
    padding: '0px 8px 0px 0px',
    [theme.breakpoints.up('laptop')]: {
      padding: '0 16px 0 0',
    },
  },
  returnRoomBodyCell: {
    padding: '0px 8px 0px 0px',
    wordBreak: 'break-all',
    [theme.breakpoints.up('laptop')]: {
      padding: '0 16px 0 0',
    },
  },

  categoryHeadCell: {
    backgroundColor: theme.palette.common.white,
    width: 86,
    padding: '0px 8px 0px 0px',
    [theme.breakpoints.up('laptop')]: {
      padding: '0 16px 0 0',
    },
  },
  categoryBodyCell: {
    padding: '0px 8px 0px 0px',
    maxWidth: 120,
    wordBreak: 'break-all',
    [theme.breakpoints.up('laptop')]: {
      padding: '0 16px 0 0',
    },
  },

  displayNameHeadCell: {
    backgroundColor: theme.palette.common.white,
    padding: '0px 8px 0px 0px',
    [theme.breakpoints.up('laptop')]: {
      padding: '0 16px 0 0',
    },
  },
  displayNameBodyCell: {
    padding: '0px 8px 0px 0px',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 120,
    [theme.breakpoints.up('laptop')]: {
      width: 160,
      padding: '0 16px 0 0',
    },
  },

  arrowCell: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },

  cancelBtnHeadCell: {
    backgroundColor: theme.palette.common.white,
  },
  cancelBtnCell: {
    padding: '0px 16px 0px 8px',
    textAlign: 'right',
  },

  cancelButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.rent.main}`,
    borderRadius: 10,
    color: theme.palette.rent.main,
    boxSizing: 'border-box',
    padding: '10px 8px',
    width: '98px',
    height: '36px',
  },
}));
