import React, {useCallback, useState} from 'react';
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  ClickAwayListener,
  Popper,
  Paper,
  Grow,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {ViewInspectionTypeToText} from '@components/organisms/TypeMenu/consts';
import {ViewInspectionType} from '@components/organisms/TypeMenu/type';

const useMenusStyle = makeStyles((theme) => ({
  popper: {
    zIndex: 100000,
    maxHeight: 360,
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '200px',
    background: '#FAFBFC',
    border: '2px solid #DFE1E5',
    padding: '4px 16px',
  },
  paper: {
    border: `1px solid ${theme.palette.grey.A100}`,
  },
  menuList: {
    '& li': {
      width: '200px',
    },
  },
  menuItemHovered: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

type TypeMenusProps = {
  type: ViewInspectionType;
  onChange: (t: ViewInspectionType) => void;
};

export const TypeMenus = ({type, onChange}: TypeMenusProps): JSX.Element => {
  const classes = useMenusStyle();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const toggleMenu = () => setOpen((_open) => !_open);

  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    toggleMenu();
  };

  const handleClose = (e: React.MouseEvent<Document, MouseEvent>): void => {
    e.stopPropagation();
    if (!open) return;
    setOpen(false);
  };

  const handleMenuClick = useCallback(
    // eslint-disable-next-line no-shadow
    (type: ViewInspectionType) => {
      onChange(type);
      setOpen(false);
    },
    [onChange]
  );

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <ClickAwayListener onClickAway={open ? handleClose : () => {}}>
      <div className={classes.container}>
        <Button className={classes.button} onClick={handleClickButton}>
          <Grid container justifyContent="space-between">
            <Typography>{ViewInspectionTypeToText[type]}</Typography>
            <ArrowDropDown fontSize="small" />
          </Grid>
        </Button>

        <Popper open={open} transition placement="bottom" anchorEl={anchorEl} className={classes.popper}>
          {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} style={{transformOrigin: placement}}>
              <Paper id="menu-list-grow" elevation={2} className={classes.paper}>
                <MenuList className={classes.menuList}>
                  <MenuItem
                    className={classes.menuItemHovered}
                    onClick={() => {
                      handleMenuClick('pre_use');
                    }}>
                    使用前点検
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemHovered}
                    onClick={() => {
                      handleMenuClick('in_use');
                    }}>
                    使用中点検
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemHovered}
                    onClick={() => {
                      handleMenuClick('post_use');
                    }}>
                    使用後点検
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemHovered}
                    onClick={() => {
                      handleMenuClick('periodic');
                    }}>
                    定期点検
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
