import React from 'react';
import {Grid, Paper, styled} from '@material-ui/core';
import dayjs from 'dayjs';
import {MoveTargetBuildingSVG} from '@atoms/Icons/moveTargetBuildingSVG';

type MobileMoveTargetBuildingHistoryCardProps = {
  displayDate: Date;
  hospitalRoom: string;
  registerFullName: string;
  movedAt: string;
};

export const MobileMoveTargetBuildingHistoryCard = (props: MobileMoveTargetBuildingHistoryCardProps) => {
  const {displayDate, registerFullName: deviceLenderUserName, movedAt, hospitalRoom} = props;
  return (
    <RootPaper elevation={0}>
      <Header container>
        <Grid item>
          <StyledGrid container>
            <Grid item>
              <IconContainer>
                <MoveTargetBuildingSVG />
              </IconContainer>
            </Grid>
            <StyledTitleGrid item>
              <StyledSpan>転棟</StyledSpan>の実施
            </StyledTitleGrid>
          </StyledGrid>
        </Grid>
        <Grid item>{dayjs(displayDate).format('YYYY/M/D')}</Grid>
      </Header>
      <ContentContainer container>
        <ContentRow item>
          <CardText>転棟先{hospitalRoom}</CardText>
          <CardText>転棟日{movedAt}</CardText>
          <CardText>登録者{deviceLenderUserName}</CardText>
        </ContentRow>
      </ContentContainer>
    </RootPaper>
  );
};

const Header = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 8,
});

const StyledSpan = styled('span')({
  color: '#0052CC',
});

const StyledTitleGrid = styled(Grid)({
  fontSize: 16,
});
const StyledGrid = styled(Grid)({
  gap: 8,
  alignItems: 'center',
});
const ContentContainer = styled(Grid)({
  alignItems: 'center',
  paddingLeft: 40,
});

const ContentRow = styled(Grid)({
  width: '100%',
});

const RootPaper = styled(Paper)({
  borderRadius: 0,
  borderBottom: '0.5px solid #DDE0E4',
  padding: 16,
  marginLeft: '-16px',
  marginRight: '-16px',
});

const CardText = styled('div')({
  fontSize: '14px',
  lineHeight: '16px',

  color: '#172B4D',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});
