import React, {useCallback, useMemo} from 'react';
import {
  createStyles,
  Paper,
  Grid,
  Tabs,
  Tab,
  Divider,
  Button,
  makeStyles,
  Theme,
  Typography,
  InputAdornment,
  OutlinedInput,
  useMediaQuery,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {useAtom} from 'jotai';
import {withFormik, WithFormikProps, useBackPrevious} from '@front-libs/core';
import {InspectionStatus, InspectionType, InspectionTypeMap} from '@modules/inspections/enum';
import {DEFAULT_INSPECTION_NAME, tabAtom} from '../states';
import {tabs, TabKey, FormValue} from '../types';
import {getErrorSummaries} from '../validator';
import {SubmitButton} from './SubmitButton';

type HeaderProps = {
  inspectionType: InspectionType;
  inspectionStatus: InspectionStatus;
  onChangeToDraft: () => void;
  onSaveDraft: () => void;
};

export const _Header: React.VFC<WithFormikProps<FormValue, FormValue, HeaderProps>> = (props) => {
  const {
    isValid,
    inspectionType,
    inspectionStatus,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    submitForm,
    onChangeToDraft,
    onSaveDraft,
  } = props;
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1024px)');
  const [tab, setTab] = useAtom(tabAtom);
  const goBackToSetting = useBackPrevious('/inspection/setting');

  const handleClickBackToInspections = useCallback(
    (e: React.MouseEvent) => {
      goBackToSetting();
    },
    [goBackToSetting]
  );

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key as TabKey);
    },
    [setTab]
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('name', e.target.value, true);
    },
    [setFieldValue]
  );

  const handleFocusName = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (DEFAULT_INSPECTION_NAME === e.target.value) {
        setFieldValue('name', '');
        setFieldTouched('name', true);
      }
    },
    [setFieldTouched, setFieldValue]
  );

  const handleBlurName = useCallback(
    (e: React.FocusEvent) => {
      setFieldTouched('name', false);
    },
    [setFieldTouched]
  );

  const handleItemClick = useCallback(
    (sectionIndex: number, fieldIndex: number | null) => {
      setTab('items');
    },
    [setTab]
  );

  const onClickPublish = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const filteredTabs = useMemo(
    () =>
      tabs.filter((t) => {
        // 定期点検のみすべてのタブを表示
        // それ以外の点検タイプは日付設定タブを隠す
        return inspectionType === 'periodic' || t.value !== 'start_dates';
      }),
    [inspectionType]
  );

  const errorSummaries = useMemo(() => getErrorSummaries(errors, values), [errors, values]);

  return (
    <Paper elevation={2} square className={classes.root}>
      <Grid container>
        {/* Upper Header */}
        <Grid container className={classes.navigations}>
          <Grid item xs container justifyContent="flex-start" alignItems="center">
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleClickBackToInspections}>
                終了
              </Button>
            </Grid>
            <Grid item style={{marginLeft: 16}}>
              <Typography variant="h6" className={classes.inspectionType}>
                点検タイプ: {matches && <br />}
                {`${InspectionTypeMap[inspectionType]?.label ?? ''}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container alignItems="center" className={classes.nameInputContainer}>
            <OutlinedInput
              error={!!errors.name}
              className={classes.nameInput}
              placeholder="点検表名"
              value={values.name}
              onFocus={handleFocusName}
              onChange={handleChangeName}
              onBlur={handleBlurName}
              endAdornment={
                <InputAdornment position="end">
                  <EditIcon style={{fontSize: '18px'}} />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs container className={classes.rightAction}>
            {props.inspectionStatus === 'available' && (
              <Grid className={classes.publishButtonContainer}>
                <Button variant="outlined" color="primary" onClick={onChangeToDraft}>
                  下書きに戻す
                </Button>
              </Grid>
            )}
            {props.inspectionStatus === 'draft' && (
              <Grid className={classes.publishButtonContainer}>
                <Button variant="outlined" color="primary" onClick={onSaveDraft}>
                  下書き保存
                </Button>
              </Grid>
            )}
            <Grid>
              {/* eslint-disable-next-line no-shadow */}
              <SubmitButton
                showsBadge={errorSummaries.length > 0}
                badgeCount={errorSummaries.length}
                status={inspectionStatus}
                disabled={!isValid || inspectionStatus === 'disabled'}
                errors={errorSummaries}
                onClick={onClickPublish}
                onItemClick={handleItemClick}
              />
            </Grid>
          </Grid>
        </Grid>

        <Divider variant="middle" className={classes.divider} />

        {/* Lower Header */}
        <Grid item container justifyContent="center" className={classes.tabsContainer}>
          <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
            {filteredTabs.map((item) => (
              <Tab key={item.value} label={item.label} value={item.value} />
            ))}
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const Header = withFormik<FormValue, FormValue, HeaderProps>({
  displayName: 'Header',
  getNames: () => ['name', 'type', 'sections'],
})(_Header);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
    navigations: {
      padding: '16px 24px 16px',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '16px 32px',
      },
    },
    nameInputContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    nameInput: {
      minWidth: '320px',
      align: 'center',
      textAlign: 'center',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '& input': {
        textAlign: 'center',
        padding: '12px 14px',
      },
      '&:hover fieldset': {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '6px',
      },
      [theme.breakpoints.up('tabletH')]: {
        minWidth: '400px',
      },
    },
    rightAction: {
      minWidth: '200px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    inspectionType: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontColor: '#172B4D',
      marginRight: '24px',
    },
    publishButtonContainer: {
      marginRight: 16,
    },
    divider: {
      margin: '0px 0',
      width: '100%',
    },
    tabsContainer: {
      marginTop: '8px',
    },
  })
);
