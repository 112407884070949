import {Pagination} from '@material-ui/lab';
import React, {useMemo} from 'react';
import {TrainingTypeValue} from '@modules/training_plans/types';
import {useSearchVariables, useTrainingSchedulesQuery} from '../hooks';
import {Toolbar} from './Toolbar';
import {ScheduleStatus} from '@modules/training_schedules/types';
import {ScheduleTable} from './ScheduleTable';
import {TableViewLayout} from '@components/layouts/TableViewLayout';

export type ScheduleTableData = {
  hashId: string;
  status: ScheduleStatus;
  name: string;
  currentTimes: number;
  trainingTypes: TrainingTypeValue[];
  scheduleDateRange: [Date, Date];
  numberOfTrainees?: number;
  place: string;
};

export const ScheduleList = () => {
  const {variables, setPage} = useSearchVariables();
  const {data} = useTrainingSchedulesQuery();

  const totalPage = useMemo(
    () => Math.ceil((data?.totalCount ?? 1) / variables.perPage),
    [data?.totalCount, variables.perPage]
  );

  return (
    <TableViewLayout>
      <TableViewLayout.Header>
        <Toolbar />
      </TableViewLayout.Header>
      <TableViewLayout.Body>
        <ScheduleTable />
      </TableViewLayout.Body>
      <TableViewLayout.Footer container justifyContent="center">
        <Pagination
          page={variables.page + 1}
          count={totalPage}
          shape="rounded"
          color="primary"
          onChange={(_, newPage) => setPage(newPage - 1)}
        />
      </TableViewLayout.Footer>
    </TableViewLayout>
  );
};
