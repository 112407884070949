import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {createStyles, Grid, makeStyles, Paper, Theme, Typography} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Column} from '@molecules/Table/props';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {useDashboardRentalHistoryQuery} from '@modules/dashboard/api';
import {DashboardRentalHistory, DashboardRentalHistoryParam} from '@modules/dashboard/types';
import {pickFirstQuery} from '@front-libs/helpers';
import {useSearchParams} from '@front-libs/core';
import {useUpdateDashboardSubject, UpdatePaperType} from '../hooks';
import {Table} from '@molecules/Table';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {RentalStatusBadge} from '@components/atoms/RentalStatusBadge';
import {useNavigate} from 'react-router-dom';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {PaperHeader} from '../PaperHeader';
import {papers} from '../consts';

const pageSizeMenuItems = [
  {
    label: '20件',
    value: 20,
  },
  {
    label: '50件',
    value: 50,
  },
  {
    label: '100件',
    value: 100,
  },
];

export const RentalHistoryTable = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const queryParams = useSearchParams();
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState<number>(20);
  const [orderKey, setOrderKey] = useState<string | null>(pickFirstQuery(queryParams.order) ?? '-checkoutAt');

  const [page, setPage] = useState<number>(1);

  const paper = papers.rentalHistory;
  const [tableLayout, setTableLayout] = useTableLayout('dashboardRentalHistory');
  const {subscribe, unsubscribe} = useUpdateDashboardSubject();

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const params = useMemo(() => {
    const _p: DashboardRentalHistoryParam = {
      page: page - 1,
      perPage: pageSize,
      withinHours: 48,
    };
    if (orderKey) {
      _p.order = orderKey;
    }
    return _p;
  }, [page, pageSize, orderKey]);

  const query = useDashboardRentalHistoryQuery(myInfo.hospitalHashId, params);
  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [query.totalCount, page, pageSize]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<DashboardRentalHistory>[], TableLayout[]>([], tableLayout?.currentLayout);
    return tableColumn.map<Column<DashboardRentalHistory>>((item) => {
      switch (item.field) {
        case 'managementId':
          item.render = ({rentalHospitalProduct}: DashboardRentalHistory) => {
            return (
              <Typography
                className={classes.managementId}
                onClick={() => {
                  navigate(`/products/${rentalHospitalProduct.hashId}`);
                }}>
                {rentalHospitalProduct.managementId}
              </Typography>
            );
          };
          break;
        case 'narrowCategory':
          item.render = ({rentalHospitalProduct}: DashboardRentalHistory) =>
            rentalHospitalProduct.categories.find((cate) => cate.depth === 1)?.name || '';
          break;
        case 'rentalRoom':
          item.render = ({rentalRoom}: DashboardRentalHistory) =>
            rentalRoom ? HospitalRoomFormatter.getFullRoom(rentalRoom) : '';
          break;
        case 'checkoutBy':
          item.render = ({checkoutBy}: DashboardRentalHistory) => checkoutBy.lastName;
          break;
        case 'returnAt':
          item.render = ({returnAt}: DashboardRentalHistory) =>
            !returnAt ? RentalStatusBadge({isReturned: false}) : RentalStatusBadge({isReturned: true});
          break;
        case 'displayName':
          item.render = ({rentalHospitalProduct}: DashboardRentalHistory) => rentalHospitalProduct.displayName;
          break;
        default:
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [classes.managementId, navigate, tableLayout]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrderKey(null);
      } else {
        setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
      }
    },
    [tableLayout?.currentLayout]
  );

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      if (paperType !== 'all' && paperType !== 'rental_history') return;
      query.refetch();
    },
    [query]
  );

  const handleChangeRowsPerPage = useCallback((item: MenuItemType) => {
    setPageSize(item.value);
    setPage(1);
  }, []);

  const handleChangeLayout = useCallback(async () => {
    const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
      tableColumns: tableLayout?.tableLayout ?? [],
      defaultOptions: tableLayout?.currentLayout ?? [],
      forceValue: {managementId: true},
    });
    const newTableLayout = {
      tableLayout: tableLayout?.tableLayout ?? [],
      currentLayout: currentLayout,
    };

    setTableLayout(newTableLayout);
  }, [setTableLayout, tableLayout]);

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
  }, [onUpdateDashboardSubject, query, subscribe, unsubscribe]);

  return (
    <Paper className={classes.paper}>
      <PaperHeader
        title={paper.label}
        onClickSettingMenu={handleChangeLayout}
        settingMenuItems={paper.settingMenuItems}
      />
      <Grid container className={classes.root}>
        <Grid container>
          <Table<DashboardRentalHistory>
            stickyHeader={true}
            columns={serializedTableColumn}
            isLoading={query.isLoading}
            data={query.data}
            showSelection={false}
            paperProps={{className: classes.tableContainer}}
            onOrderChange={handleOrderChange}
          />
        </Grid>
        <Grid container justifyContent={'space-between'}>
          <Grid item className={classes.pageDescription}>
            {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
          </Grid>
          <Grid item className={classes.paginationContainer}>
            <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
            1ページあたり
            <PopperMenuButton
              placement="bottom"
              hiddenArrow={true}
              buttonProps={{variant: 'text'}}
              menuItemList={pageSizeMenuItems}
              onMenuClick={handleChangeRowsPerPage}>
              <span className={classes.pageSize}>{pageSize}件</span>
              <ArrowDropDownIcon />
            </PopperMenuButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '0px 16px 16px',
      borderTop: `4px solid ${theme.palette.primary.dark}`,
      height: 'calc(100% - 20px)',
    },
    hide: {
      display: 'none',
    },
    root: {
      padding: '32px 24px',
    },
    tableContainer: {
      marginBottom: '24px',
      maxHeight: '360px',
      overflowY: 'scroll',
    },
    managementId: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    pageDescription: {
      margin: 'auto 0px',
    },
    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
    },
    pageSize: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  })
);
