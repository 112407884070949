import React from 'react';
import {styled} from '@material-ui/styles';

const SVGContainer = styled('div')({
  flexShrink: 0,
});

const HomeIndicator = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39" height="4" viewBox="0 0 39 4" fill="none">
    <rect y="0.123962" width="39" height="3.4214" rx="1.7107" fill="#D5D8DD" />
  </svg>
);

const CenteredContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

/**
 * iOSのHome Indicator(灰色の横棒)を模した画像
 * @returns
 */
export const HomeIndicatorImage = () => {
  return (
    <CenteredContainer>
      <SVGContainer>
        <HomeIndicator />
      </SVGContainer>
    </CenteredContainer>
  );
};
