import {useQuery} from 'react-query';
import {getHospitalProductFilesKey} from '@constants/api';
import {FetchHospitalProductFilesParams, getHospitalProductFiles} from '@modules/hospital_products/api';

export const useFetchHospitalProductFiles = (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductFilesParams
) => {
  return useQuery([getHospitalProductFilesKey, hospitalHashId, hospitalProductHashId, params], () =>
    getHospitalProductFiles(hospitalHashId, hospitalProductHashId, params)
  );
};
