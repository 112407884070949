import {dexieDB} from '@indexedDB/database';
import {InspectionIndex} from '@modules/inspections/types';

// indexedDBの値を更新する
export const upsertInspections = async (inspections: InspectionIndex[]) => {
  await dexieDB?.inspections.bulkPut(inspections);
};

// 病院IDに紐づくデータを削除
export const deleteInspectionsByHospitalHashId = async (hospitalHashId: string) => {
  await dexieDB?.inspections.where('hospitalHashId').equals(hospitalHashId).delete();
};
