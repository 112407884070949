import React, {useCallback} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography, IconButton} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import CloseIcon from '@material-ui/icons/Close';
import barcode from '@assets/barcode.png';
import {useBarcodeReader} from '@front-libs/core';
import {openSnackBar} from '@molecules/SnackBar';

export type NewRegistrationDialogProps = DialogProps;

export type NewRegistrationDialogResult = {
  gs1Barcode: string | null;
};

export const NewRegistrationGS1Dialog: React.FC<NewRegistrationDialogProps> = (props) => {
  const {open} = props;
  const classes = useStyles();

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  const handleInputBarcode = async (inputBarcode: string) => {
    // バーコードがこういう形式で飛んでくる (01)4580211772348(10)2104K504GR
    if (!inputBarcode.includes('(01)')) {
      openSnackBar('バーコードの形式がGS1-128ではありません', 'left', 'bottom', 'error');
      return;
    }
    props.actions.resolve({gs1Barcode: inputBarcode});
  };

  const {BarcodeInput} = useBarcodeReader({
    forcedFocus: open,
    onInputData: handleInputBarcode,
  });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.title} disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{`GS1-128のバーコードを読み込み`}</Typography>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img src={barcode} alt="バーコードの使い方" />
      </DialogContent>
      {BarcodeInput}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '24px 32px 0px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  closeButton: {
    padding: '0px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px',
    padding: '0px 32px 0px',
    '& > div:not(:first-child)': {
      marginTop: '24px',
    },
  },
}));
