import {Box, Typography, makeStyles} from '@material-ui/core';
import React from 'react';

export const ResultSummaryHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.txt}>点検結果</Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    margin: '16px 16px 0px 0px',
    justifyContent: 'center',
  },
  txt: {
    display: 'flex',
    justifyContent: 'center',
    color: '#172B4D',
    fontWeight: 'bold',
  },
}));
