import React, {useCallback} from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';
import {ProductListElement} from '../types';
import {
  useUnassignWholeProductRequestDispatcherContext,
  useDisplayHospitalProductRequestDispatcherContext,
} from '../contexts';

const CLASS_VISIBLE_ON_ROW_HOVER = 'visible-on-row-hover';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '500px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    actions: {
      marginLeft: '32px',
      width: 'auto',
      alignItems: 'center',
      '& > button:not(:first-child)': {
        marginLeft: '8px',
      },
      visibility: 'hidden',
    },
    touchableActions: {
      visibility: 'visible',
    },
    action: {
      background: 'rgba(9, 30, 66, 0.04)',
    },
  })
);

export const MakerNameColumn: React.VFC<ProductListElement> = (props) => {
  const {hashId, makerName, inspectionType} = props;
  const classes = useStyle();

  const displayHospitalProduct = useDisplayHospitalProductRequestDispatcherContext();
  const unassignProduct = useUnassignWholeProductRequestDispatcherContext();

  const handleClickDisplayHospitalProduct = useCallback(() => {
    displayHospitalProduct(hashId);
  }, [displayHospitalProduct, hashId]);

  const handleClickUnassign = useCallback(() => {
    unassignProduct(hashId);
  }, [hashId, unassignProduct]);

  return (
    <Grid container className={classes.root}>
      <Typography variant="inherit">{makerName}</Typography>
      <Grid
        item
        container
        className={clsx(classes.actions, CLASS_VISIBLE_ON_ROW_HOVER, isMobile && classes.touchableActions)}>
        {inspectionType === 'periodic' && (
          <Button className={classes.action} variant="outlined" onClick={handleClickDisplayHospitalProduct}>
            点検開始日を編集
          </Button>
        )}
        <Button className={classes.action} variant="outlined" onClick={handleClickUnassign}>
          対象機種を削除
        </Button>
      </Grid>
    </Grid>
  );
};
