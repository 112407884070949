import React, {useMemo} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Dialog} from '@atoms/Dialog/Dialog';
import {PlanType, TrainingTypeValue} from '@modules/training_plans/types';
import {yup} from '@front-libs/core';
import {Form, Formik, useFormikContext} from 'formik';
import {Button, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField} from '@material-ui/core';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import Select, {ValueType} from 'react-select';
import {InferType} from 'yup';
import {theme} from '@atoms/theme';
import {isNullish} from '@front-libs/helpers';
import {trainingTypeOptions, planTypeOptions} from '@modules/training_plans/constants';

const validationSchema = yup.object({
  name: yup.string().required(),
  trainingTypes: yup
    .array(
      yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .required()
    .min(1),
  planType: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  scheduleTimes: yup.number().test('scheduleTimes', '回数を入力してください', (value, context) => {
    if (context.parent.planType.value === 'yearly_plan') {
      return !isNullish(value);
    }
    return true;
  }),
});

type TrainingFormData = InferType<typeof validationSchema>;

export type TrainingPlanDialogResult = {
  name: string;
  trainingTypes: TrainingTypeValue[];
  planType: PlanType;
  scheduleTimes?: number;
};
export type TrainingPlanDialogProps = DialogProps<TrainingPlanDialogResult> & {
  isEdit: boolean;
  initialValues?: TrainingFormData;
};

type TrainingPlanFormProps = {
  onCancel: VoidFunction;
  isEdit: boolean;
};

const TrainingPlanForm: React.FC<TrainingPlanFormProps> = ({onCancel, isEdit}) => {
  const {setFieldValue, values, isValid, handleSubmit, handleChange} = useFormikContext<TrainingFormData>();

  return (
    <>
      <DialogContent>
        <Form style={{width: '100%'}}>
          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>研修の名称</RequiredLabel>
            <TextField
              fullWidth
              id="name"
              name="name"
              type="text"
              variant="outlined"
              size={'small'}
              value={values.name}
              onChange={handleChange}
            />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>研修の内容</RequiredLabel>
            <Select
              menuPortalTarget={document.body}
              isMulti={true}
              name="trainingTypes"
              styles={{
                menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 10000000}),
              }}
              value={values.trainingTypes}
              options={trainingTypeOptions}
              closeMenuOnSelect={false}
              onChange={(val: ValueType<{label: string; value: string}, true>) => {
                setFieldValue('trainingTypes', val);
              }}
            />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>予定回数</RequiredLabel>
            <Grid container>
              <Grid item xs={3} style={{marginRight: 16}}>
                <Select
                  menuPortalTarget={document.body}
                  name="planType"
                  placeholder={'予定の種類'}
                  value={values.planType}
                  styles={{
                    menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
                  }}
                  options={planTypeOptions}
                  onChange={(val: ValueType<{label: string; value: string}, false>) => {
                    setFieldValue('planType', val);
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  disabled={values.planType.value === 'as_needed'}
                  id="scheduleTimes"
                  name="scheduleTimes"
                  type="number"
                  variant="outlined"
                  size={'small'}
                  value={values.scheduleTimes}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">回</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={() => handleSubmit()}>
          {isEdit ? '更新' : '登録'}
        </Button>
        <Button onClick={() => onCancel()} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </>
  );
};

export const TrainingPlanDialog: React.FC<TrainingPlanDialogProps> = ({isEdit, initialValues, ...props}) => {
  const handleSubmit = (values: TrainingFormData) => {
    props.actions.resolve({
      ...values,
      trainingTypes: values.trainingTypes.map((item) => item.value as TrainingTypeValue),
      planType: values.planType.value as PlanType,
    });
  };
  const initialData = useMemo<TrainingFormData>(() => {
    if (!isNullish(initialValues)) {
      return initialValues;
    } else {
      return {
        name: '',
        trainingTypes: [],
        planType: planTypeOptions[0],
        scheduleTimes: undefined,
      };
    }
  }, [initialValues]);

  return (
    <Formik<TrainingFormData>
      initialValues={initialData}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={handleSubmit}>
      <Dialog
        open={props.open}
        onClose={() => props.actions.reject()}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}>
        <DialogTitle>{isEdit ? '計画を更新' : '新規計画'}</DialogTitle>
        <TrainingPlanForm onCancel={props.actions.reject} isEdit={isEdit} />
      </Dialog>
    </Formik>
  );
};
