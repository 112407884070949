import {Pagination} from '@material-ui/lab';
import React, {useMemo} from 'react';
import {PlanStatus, TrainingTypeValue} from '@modules/training_plans/types';
import {useSearchVariables, useTrainingPlansQuery} from '../hooks';
import {PlanTable} from './PlanTable';
import {Toolbar} from './Toolbar';
import {TableViewLayout} from '@components/layouts/TableViewLayout';

export type PlanTableData = {
  hashId: string;
  status: PlanStatus | null;
  name: string;
  trainingTypes: TrainingTypeValue[];
  scheduleTimes: number;
  reportTimes: number;
  planType: 'as_needed' | 'yearly_plan';
};

export const PlanList = () => {
  const {variables, setPage} = useSearchVariables();
  const {data} = useTrainingPlansQuery();

  const totalPage = useMemo(
    () => Math.ceil((data?.totalCount ?? 1) / variables.perPage),
    [data?.totalCount, variables.perPage]
  );

  return (
    <TableViewLayout>
      <TableViewLayout.Header>
        <Toolbar />
      </TableViewLayout.Header>
      <TableViewLayout.Body>
        <PlanTable />
      </TableViewLayout.Body>
      <TableViewLayout.Footer container justifyContent="center">
        <Pagination
          page={variables.page + 1}
          count={totalPage}
          shape="rounded"
          color="primary"
          onChange={(_, newPage) => setPage(newPage - 1)}
        />
      </TableViewLayout.Footer>
    </TableViewLayout>
  );
};
