import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {PageHeader} from './PageHeader';
import {useBackPrevious, useDebounceCallback, withSuspense} from '@front-libs/core';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {createInspection} from '@modules/inspections/api';
import {
  NewInspectionDialog,
  NewInspectionDialogProps,
  NewInspectionDialogResult,
} from '@molecules/Dialogs/NewInspectionDialog';
import {DEFAULT_INSPECTION_NAME} from '@Apps/Inspection/states';
import {InspectionType} from '@modules/inspections/enum';
import {openSnackBar} from '@components/molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {ToolBar} from './Toolbar';
import {InspectionProductSettingList} from './InspectionProduct';
import {useAtom} from 'jotai';
import {FilterDrawer} from '@components/molecules/Drawers/FilterDrawer';
import {InspectionProductFilterDrawerOptions} from './constants';
import {ResultType} from '@components/molecules/Drawers/FilterDrawer/types';
import {useWholeProductsInspectionsPeriodProductsQuery} from './hooks';
import {pageAtom, pageSizeAtom, searchFilterResultsAtom, searchNameAtom} from './jotai';
import {updateInspectionPeriod} from '@modules/inspection_setting/api';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {TableViewLayout} from '@components/layouts/TableViewLayout';

const newInspection = async (hospitalHashId: string): Promise<string | null> => {
  let type: InspectionType;
  try {
    const res = await dialogHandler.open<NewInspectionDialogProps, NewInspectionDialogResult>(NewInspectionDialog, {});
    type = res.type;
  } catch (_e) {
    return null;
  }

  try {
    const res = await createInspection(hospitalHashId, {
      name: DEFAULT_INSPECTION_NAME,
      type: type,
      status: 'draft',
    });

    return res.data.hashId;
  } catch (e) {
    console.error(e);
    openSnackBar('点検表作成に失敗しました', 'left', 'bottom', 'error');
  }

  return null;
};

const _InspectionProductSetting: React.VFC = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [page, setPage] = useAtom(pageAtom);
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const [, setOrderKey] = useState<string>('name');
  const goBackToResultsPage = useBackPrevious('/inspection/results');
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [searchFilterResults, setSearchFilterResults] = useAtom(searchFilterResultsAtom);
  const [pageSize] = useAtom(pageSizeAtom);

  const query = useWholeProductsInspectionsPeriodProductsQuery(myInfo.hospitalHashId);

  const handleClickDrawer = useCallback(() => {
    setIsOpenDrawer((x) => !x);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsOpenDrawer(false);
  }, []);

  const handleFilterChange = useCallback(
    (values: ResultType[]) => {
      setPage(1);
      setSearchFilterResults(values);
    },
    [setPage, setSearchFilterResults]
  );

  const handleClickNewInspection = useCallback(async () => {
    const hashId = await newInspection(myInfo.hospitalHashId);
    if (hashId !== null) {
      navigate(`/inspections/${hashId}`);
    }
  }, [myInfo.hospitalHashId, navigate]);

  const handleClickPrevButton = useCallback(() => {
    goBackToResultsPage();
  }, [goBackToResultsPage]);

  const handleSearchName = useDebounceCallback(
    (name: string) => {
      setPage(1);
      setSearchName(name);
    },
    300,
    [setPage, setSearchName]
  );

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const handleOrderChange = useCallback((orderStr: string) => {
    setOrderKey(orderStr);
  }, []);

  const handleChangePage = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [page, pageSize, query.totalCount]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const handleChangePeriod = useCallback(
    async (wholeProductHashId: string, inspectionType: InspectionType, periodicInspectionPeriod: number) => {
      try {
        await updateInspectionPeriod(myInfo.hospitalHashId, {
          wholeProductHashId: wholeProductHashId,
          periodicInspectionPeriod: periodicInspectionPeriod,
          periodicInspectionPeriodUnit: 'month',
        });
        openSnackBar('点検間隔を更新しました。', 'center', 'top', 'success');
        query.refetch();
      } catch (error) {
        console.error(error);
        openSnackBar('点検間隔の更新に失敗しました', 'center', 'top', 'error');
      }
    },
    [myInfo.hospitalHashId, query]
  );

  return (
    <ListPageLayout page="inspection_setting_products">
      <ListPageLayout.Header>
        <PageHeader onClickPrevButton={handleClickPrevButton} onClickNewInspection={handleClickNewInspection} />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <TableViewLayout>
          <TableViewLayout.Header>
            <ToolBar
              searchName={searchName ?? ''}
              onChangeSearchName={handleSearchName}
              onClickDrawer={handleClickDrawer}
            />
          </TableViewLayout.Header>
          <InspectionProductSettingList
            isLoading={query.isLoading}
            hospitalProducts={query.data}
            page={page}
            totalPage={totalPage}
            totalCount={query.totalCount}
            startDisplayPosition={startDisplayPosition}
            endDisplayPosition={endDisplayPosition}
            onChangeOrder={handleOrderChange}
            onChangePage={handleChangePage}
            onChangePeriod={handleChangePeriod}
            refetch={query.refetch}
          />
        </TableViewLayout>
      </ListPageLayout.Content>
      <FilterDrawer
        open={isOpenDrawer}
        title={'点検機器の検索'}
        onDrawerClose={handleDrawerClose}
        currentCount={query.totalCount}
        filterOptions={InspectionProductFilterDrawerOptions}
        onFilterChange={handleFilterChange}
        defaultFilterResults={searchFilterResults}
      />
    </ListPageLayout>
  );
};

export const InspectionProductSetting = withSuspense(_InspectionProductSetting, null);
