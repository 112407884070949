import React, {useState, useCallback} from 'react';
import {createStyles, makeStyles, Theme, Typography, Grid, TextField} from '@material-ui/core';
import {PeriodUnit} from '@modules/inspection_setting/types';
import {InlineEditMenu} from '@Apps/InspectionSetting/common/InlineEditMenu';
import {InspectionType} from '@modules/inspections/enum';

const formatPeriod = (period: number, periodUnit: PeriodUnit) => {
  switch (periodUnit) {
    case 'day':
      return `${period}日`;
    case 'month':
      return `${period}ヶ月`;
    case 'year':
      return `${period}年`;
    default:
      // should not reach here
      break;
  }
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    period: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    dialog: {
      minWidth: '250px',
      padding: '24px',
    },
    createButton: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
    },
    actionButton: {
      background: 'rgba(9, 30, 66, 0.04)',
      '&:not(:first-of-type)': {
        marginLeft: '8px',
      },
    },
  })
);

type InspectionPeriodicColumnProps = {
  period?: number;
  periodUnit?: PeriodUnit;
  wholeProductID: string;
  onSave: (wholeProductID: string, inspectionType: InspectionType, periodicInspectionPeriod: number) => void;
};

export const InspectionPeriodicColumn: React.VFC<InspectionPeriodicColumnProps> = (props) => {
  const {period, periodUnit, onSave, wholeProductID} = props;
  const classes = useStyle();
  const isSetValue = period && periodUnit;

  const [value, setValue] = useState<number | null>(period ?? 1);
  const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number.parseInt(e.target.value));
  }, []);

  const [menuAnchor, setMenuAnchor] = React.useState<Element | null>(null);
  const handleClickText = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleSave = useCallback(() => {
    handleCloseMenu();
    if (value != null) {
      onSave(wholeProductID, 'periodic', value);
    }
  }, [handleCloseMenu, onSave, value, wholeProductID]);

  return (
    <Grid>
      <Typography className={classes.period} onClick={handleClickText}>
        {isSetValue ? formatPeriod(period, periodUnit) : '点検間隔を設定'}
      </Typography>
      <InlineEditMenu
        menuAnchor={menuAnchor}
        isDisabledSave={value === null}
        onSave={handleSave}
        onClose={handleCloseMenu}
        onCancel={handleCloseMenu}>
        <Grid item style={{width: '80px'}}>
          <TextField
            type="number"
            variant={'outlined'}
            fullWidth
            size={'small'}
            defaultValue={period ?? ''}
            value={value}
            InputProps={{inputProps: {min: 1, max: 12}}}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item style={{width: '50px', marginLeft: '8px'}}>
          <Typography onClick={handleClickText}>ヶ月</Typography>
        </Grid>
      </InlineEditMenu>
    </Grid>
  );
};
