const responsiveOptions = {
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 2400,
          minHeight: 500,
        },
        chartOptions: {
          legend: {
            floating: false,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            x: 0,
            y: 0,
          },
          yAxis: [
            {
              labels: {
                align: 'right',
                x: 0,
                y: -6,
              },
              showLastLabel: true,
            },
            {
              labels: {
                align: 'left',
                x: 0,
                y: -6,
              },
              showLastLabel: true,
            },
            {
              visible: false,
            },
          ],
        },
      },
    ],
  },
};

export const chartOptions = {
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  legend: {
    layout: 'vertical',
    align: 'left',
    x: 80,
    verticalAlign: 'top',
    y: 55,
    floating: true,
    backgroundColor: 'rgba(255,255,255,0.25)',
  },
  tooltip: {
    shared: true,
  },
  ...responsiveOptions,
};

export const rentalRatioYAxis = {
  // Primary yAxis
  max: 100,
  min: 0,
  allowDecimals: false,
  labels: {
    format: '{value}%',
    style: {
      color: '#616C82',
    },
  },
  title: {
    text: '使用率',
    style: {
      color: '#616C82',
    },
  },
  opposite: true,
};

export const hospitalProductYAxis = {
  // Secondary yAxis
  min: 0,
  gridLineWidth: 0,
  ceiling: 200,
  allowDecimals: false,
  title: {
    text: '機器台数',
    style: {
      color: '#616C82',
    },
  },
  labels: {
    format: '{value} 台',
    style: {
      color: '#616C82',
    },
  },
};
