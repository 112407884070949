import {useQuery} from 'react-query';
import {getTrainingScheduleMaterialsKey} from '@constants/api';
import {getTrainingScheduleMaterials} from '@modules/training_schedules/api';

export const useFetchTrainingScheduleMaterials = (
  hospitalHashId: string,
  trainingScheduleHashId: string,
  order?: string
) => {
  return useQuery([getTrainingScheduleMaterialsKey, hospitalHashId, trainingScheduleHashId, order], () =>
    getTrainingScheduleMaterials(hospitalHashId, trainingScheduleHashId, order)
  );
};
