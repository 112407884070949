import {TextField, makeStyles, createStyles, Theme} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {useSetAtom} from 'jotai';
import React, {useCallback} from 'react';
import {onFocusAtom} from './jotai';

type ToolBarProp = {
  searchName: string | null;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
};

export const ToolBar: React.VFC<ToolBarProp> = (props) => {
  const {searchName, onChangeSearchName} = props;
  const classes = useStyles();
  const setIsFocus = useSetAtom(onFocusAtom);

  const onFocus = useCallback(
    (e: React.FocusEvent) => {
      // NOTE:呼び出し元で他の要素のFocus処理前にOnBlurが発火するのでタイミングをずらす
      setTimeout(() => {
        setIsFocus(e.type === 'focus');
      }, 100);
    },
    [setIsFocus]
  );
  return (
    <TextField
      className={classes.searchName}
      variant={'outlined'}
      onFocus={onFocus}
      onBlur={onFocus}
      fullWidth
      size={'small'}
      placeholder="名称・型式・管理番号で検索"
      InputProps={{
        startAdornment: <Search className={classes.searchIcon} />,
        style: {
          color: '65676B', // テキストの色を濃くする
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 14,
        },
      }}
      defaultValue={searchName}
      onChange={onChangeSearchName}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchName: {
      backgroundColor: '#F3F4F6',
      fontSize: '14px',
      '& input::placeholder': {
        color: theme.palette.grey[600],
      },
    },
    searchIcon: {
      color: theme.palette.grey[600],
    },
  })
);
