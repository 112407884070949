import React, {memo, useCallback, useMemo} from 'react';
import {createStyles, Paper, Grid, Tabs, Tab, Button, makeStyles, Theme, Box, Typography} from '@material-ui/core';
import {useAtom} from 'jotai';
import {FastField, FieldInputProps, FieldMetaProps, FormikProps} from 'formik';
import {tabAtom} from '@Apps/InspectionResult/pc/EditInspectionResult/states';
import {tabs, TabKey} from '@Apps/InspectionResult/pc/common/types';
import {InspectionIndex} from '@modules/inspections/types';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {FormValue} from '../../common/types';
import {getEmptyAnswers} from '../../common/validator';
import {SubmitButton} from './SubmitButton';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {isNullish} from '@front-libs/helpers';
import {openSnackBar} from '@components/molecules/SnackBar';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {MessageDialog} from '@components/molecules/Dialogs/MessageDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {useLatestInspectionResult} from '@Apps/InspectionResult/pc/common/hooks';
import {useSubmitDispatcher} from '../contexts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '8px 0px 0px',
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      top: 0,
    },
    topBar: {
      padding: '8px 24px 0px 72px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    name: {
      color: theme.palette.grey[700],
    },
    productName: {
      color: theme.palette.grey[600],
    },
    managementNumber: {
      color: theme.palette.grey[600],
    },
    saveButtonContainer: {
      marginLeft: '8px',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    actionBtn: {
      position: 'absolute',
      right: 0,
      margin: '0px 24px 12px',
    },
  })
);

type HeaderFieldProps = {
  field: FieldInputProps<FormValue>;
  meta: FieldMetaProps<FormValue>;
  form: FormikProps<FormValue>;
};

type HeaderInnerProps = {
  inspection: InspectionIndex;
  hospitalProduct: HospitalProductIndex | null;
  onSaveAsDraft: (e: React.MouseEvent, values: FormValue) => Promise<void>;
  onDiscardChanges: () => Promise<void>;
};

export const HeaderInner: React.VFC<HeaderFieldProps & HeaderInnerProps> = memo((props) => {
  const {inspection, hospitalProduct, form, onSaveAsDraft, onDiscardChanges} = props;
  const onSubmit = useSubmitDispatcher();

  const classes = useStyles();
  const [tab, setTab] = useAtom(tabAtom);
  const {data} = useLatestInspectionResult();
  const hasNoPastInspection = useMemo(() => (data === null ? true : false), [data]);
  const isInspectionChanged = useMemo(
    () => (data?.inspectionTable.hashId !== inspection.table.hashId ? true : false),
    [data, inspection.table]
  );
  const isPresetDisabled = useMemo(
    () => hasNoPastInspection || isInspectionChanged,
    [hasNoPastInspection, isInspectionChanged]
  );

  const actionMenuItems: MenuItemType[] = useMemo(
    () => [
      {
        label: '前回の点検結果を反映',
        value: 'preset',
        disableMenuItem: isPresetDisabled,
        tooltipOption: {
          enableTooltip: isPresetDisabled,
          title: isInspectionChanged
            ? '点検表が前回から変更されているため\n前回の点検結果を反映できません'
            : '点検結果が存在しないため\n使用できません。',
          arrow: true,
        },
      },
    ],
    [isPresetDisabled, isInspectionChanged]
  );

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key as TabKey);
    },
    [setTab]
  );

  const handleClickSave = useCallback(
    (e: React.MouseEvent) => {
      onSaveAsDraft(e, form.values);
    },
    [form.values, onSaveAsDraft]
  );

  const handleClickDiscard = useCallback(() => {
    onDiscardChanges();
  }, [onDiscardChanges]);

  const handleClickComplete = useCallback(
    (e: React.MouseEvent) => {
      onSubmit(e, form.values);
    },
    [form.values, onSubmit]
  );

  const emptyAnswers = useMemo(() => {
    form.validateForm();
    return getEmptyAnswers(form.errors, inspection.table.items);
  }, [form, inspection.table.items]);

  const saveButtonDisabled = form.isSubmitting;
  const submitButtonDisabled = useMemo(
    () => emptyAnswers.length > 0 || form.isSubmitting,
    [emptyAnswers, form.isSubmitting]
  );

  const handleClickPreset = useCallback(() => {
    form.resetForm();

    (data?.items ?? []).forEach((resultItem) => {
      // FIX ME: formのhash keyと最新の点検結果のhash keyのcaseが一致しないため、暫定的にlowercaseにして特定する
      const searchKey = resultItem.id.toLowerCase();
      const formKey = Object.keys(form.values.items).find((k) => k.toLowerCase() === searchKey);

      if (isNullish(formKey)) return;

      if (resultItem.type === 'multi-select') {
        // multi-selectの場合、formのvalueのtype({ [key: string]: boolean; })が最新の点検結果のvalueのtype(string[])と一致しないため、
        // resultItem.values(array)をloopで回してチェックされているものにtrueを付与。
        // Type 'MultiSelectResultItem' is not assignable to type 'MultiSelectField'.
        resultItem.values?.forEach((resultValue) => {
          form.setFieldValue(`items.${formKey}.values.${resultValue}`, true);
        });
      } else if (resultItem.type === 'time') {
        // 時間型の場合、formには時・分までしか入らないが、resultItemのvalueは時・分・秒まで入っているため、秒を削除
        form.setFieldValue(`items.${formKey}.value`, resultItem.value?.slice(0, -3) ?? '');
      } else {
        form.setFieldValue(`items.${formKey}`, resultItem);
      }
    });

    openSnackBar('前回の点検結果を反映しました。');
  }, [data, form]);

  const handleActionClick = useCallback(
    async (item: MenuItemType) => {
      switch (item.value) {
        case 'preset':
          if (form.dirty) {
            await dialogHandler.open(MessageDialog, {
              title: '前回の点検結果を反映',
              content:
                '点検表に値が入力されています。\n点検結果を反映した場合、現在入力している内容は消失し、\n元に戻すことができません。\n\n点検の記録を反映してよろしいですか？',
              positiveButtonLabel: '実行',
            });
          }
          if (data?.result === 'failed') {
            await dialogHandler.open(AlertDialog, {
              title: '異常値を含んだ点検を反映しますか？',
              content: '前回の点検記録には異常値が含まれます。\n点検の記録を反映してよろしいですか？',
              positiveButtonLabel: 'OK',
            });
          }
          handleClickPreset();
          break;
        default:
          break;
      }
    },
    [form.dirty, handleClickPreset, data]
  );

  return (
    <Paper elevation={2} square className={classes.root}>
      <Grid container direction="column">
        <Grid container className={classes.topBar} direction="row">
          <Grid direction="column">
            <Box className={classes.name} fontWeight={700} fontSize={18}>
              {inspection.name}
            </Box>
            <Typography variant={'body2'}>機器名: {hospitalProduct?.name ?? ''}</Typography>
            <Typography variant={'body2'}>管理番号: {hospitalProduct?.managementId ?? ''}</Typography>
          </Grid>
          <Grid className={classes.saveButtonContainer}>
            <Button variant="outlined" color="primary" onClick={handleClickDiscard} style={{marginRight: '16px'}}>
              保存せずに終了
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={saveButtonDisabled}
              onClick={handleClickSave}
              style={{marginRight: '16px'}}>
              途中保存して終了
            </Button>
            <Grid>
              <SubmitButton
                disabled={submitButtonDisabled}
                showsBadge={emptyAnswers.length > 0}
                emptyAnswers={emptyAnswers}
                onClick={handleClickComplete}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
            {tabs.map((item) => (
              <Tab key={item.value} label={item.label} value={item.value} />
            ))}
          </Tabs>
          <PopperMenuButton
            buttonProps={{variant: 'contained', disableElevation: true, className: classes.actionBtn}}
            menuItemList={actionMenuItems}
            onMenuClick={handleActionClick}>
            アクション
          </PopperMenuButton>
        </Grid>
      </Grid>
    </Paper>
  );
});

export const Header: React.FC<HeaderInnerProps> = memo((props) => {
  return (
    <FastField name="">
      {({field, form, meta}: HeaderFieldProps) => <HeaderInner {...props} field={field} meta={meta} form={form} />}
    </FastField>
  );
});
