import React from 'react';
import {
  ClickAwayListener,
  createStyles,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
} from '@material-ui/core';

const settingMenuItems = [
  {
    label: '端末設定',
    value: 'terminalSetting',
  },
  {
    label: 'エリアボタンの並び替え',
    value: 'customize',
  },
  {
    label: 'ログアウト',
    value: 'logout',
  },
] as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      borderTop: `4px solid ${theme.palette.primary.dark}`,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    informationContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: '0px',
    },
    paperTitle: {
      fontWeight: 'bold',
      padding: '4px',
    },
    fullPaperSpace: {
      height: 'calc(100% - 36px)',
    },
    tooltip: {
      cursor: 'pointer',
      padding: '4px',
    },
    info: {
      cursor: 'pointer',
      padding: '10px 4px 0px 0px',
      width: '15px',
      height: '15px',
    },
    popper: {
      zIndex: 1000,
    },
    dragIcon: {
      height: '100%',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  })
);

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  onClickSettingMenu: (value: (typeof settingMenuItems)[number]['value']) => void;
};

export const PopperMenu: React.FC<Props> = (props) => {
  const {open, onClose, onClickSettingMenu, anchorEl} = props;

  const classes = useStyles();

  const handleMenuItemClick = (value: (typeof settingMenuItems)[number]['value']) => {
    onClickSettingMenu(value);
    onClose();
  };

  return (
    <Popper className={classes.popper} open={open} anchorEl={anchorEl} transition placement="bottom">
      {({TransitionProps}) => (
        <Grow {...TransitionProps} style={{transformOrigin: 'placement'}}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>
                {settingMenuItems.map((item) => (
                  <MenuItem onClick={() => handleMenuItemClick(item.value)} data-testid={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
