import React, {useCallback, Suspense} from 'react';
import {useResetAtom} from 'jotai/utils';
import {useSetAtom} from 'jotai';
import {useOnlyOnce} from '@front-libs/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {InnerLoading} from '@molecules/Loading';
import {DialogInner} from './DialogInner';
import {searchWordAtom, rootCategoryAtom, narrowCategoryAtom, selectedProductsAtom, ProductElement} from './states';
import {InspectionType} from '@modules/inspections/enum';
import {InspectionPeriodProductWithCount} from '@modules/inspection_setting/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';

export type EditProductsDialogProps = {
  hospitalHashId: string;
  inspectionHashId: string;
  inspectionType: InspectionType;
  defaultValues?: InspectionPeriodProductWithCount[];
} & DialogProps;

export const EditProductsDialog: React.FC<EditProductsDialogProps> = (props) => {
  const {hospitalHashId, inspectionHashId, inspectionType, defaultValues = []} = props;

  const handleSubmit = useCallback(
    async (periods: InspectionPeriodProductWithCount[]) => {
      const assignedPeriods = periods.filter((p) => p.otherInspectionPeriodCount > 0);

      if (assignedPeriods.length > 0) {
        try {
          await dialogHandler.open(MessageDialog, {
            title: '点検表を更新しますか',
            content: `既に割り当てられている機種が含まれますが、追加しますか？`,
            positiveButtonLabel: '追加',
            warning: true,
          });
        } catch (_e) {
          return;
        }
      }

      props.actions.resolve(periods);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  const resetSearchWord = useResetAtom(searchWordAtom);
  const resetRootCategoryAtom = useResetAtom(rootCategoryAtom);
  const resetNarrowCategoryAtom = useResetAtom(narrowCategoryAtom);
  const setSelectedProductsAtom = useSetAtom(selectedProductsAtom);

  useOnlyOnce(() => {
    resetSearchWord();
    resetRootCategoryAtom();
    resetNarrowCategoryAtom();
    setSelectedProductsAtom(defaultValues as ProductElement[]);
  });

  return (
    <Suspense fallback={<InnerLoading />}>
      <DialogInner
        hospitalHashId={hospitalHashId}
        inspectionHashId={inspectionHashId}
        inspectionType={inspectionType}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </Suspense>
  );
};
