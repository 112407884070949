import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {getTrainingPlans} from './api';

export const useTrainingPlanOptions = () => {
  const {data: trainingPlans} = useRawTrainingPlansQuery();
  const trainingPlanOptions = useMemo(
    () => (trainingPlans?.data ?? []).map((item) => ({value: item.hashId, label: item.name})),
    [trainingPlans?.data]
  );
  return {trainingPlanOptions};
};

const useRawTrainingPlansQuery = () => {
  const {myInfo} = useMyInfo();
  const query = useQuery(['useRawTrainingPlansQuery', myInfo], () =>
    getTrainingPlans(myInfo.hospitalHashId, {perPage: 100})
  );
  return {...query, data: query.data};
};
