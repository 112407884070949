import {createBrowserHistory} from 'history';

// react-router-dom v6.4.0 バージョンアップ で React.useContext(UNSAFE_NavigationContext).navigator を history として使用できなくなりました。
// (History インターフェースの実装がなされなくなりました。)
// よって ブラウザ履歴オブジェクト を 生成してキャッシュしておきます。
// 主に、モーダル制御時 に 履歴遷移/操作 を不可能 にするための block メソッドの定義で使用します。
// ref) https://stackoverflow.com/questions/74106591/getting-navigator-block-is-not-a-function-while-navigating-to-other-page
// ref) https://stackoverflow.com/questions/69871987/react-router-v6-navigate-outside-of-components/70000286
// ref) https://github.com/remix-run/react-router/issues/8139#issuecomment-1262630360
// ref) https://github.com/remix-run/react-router/issues/9262

export const cachedBrowserHistory = createBrowserHistory();
