import React, {useCallback} from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {useNavigate} from 'react-router-dom';
import {TypeMenus} from './TypeMenus';
import {inspectionTypeAtom} from './jotai';
import {useAtom} from 'jotai';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prevButton: {
      width: '200px',
      padding: '0px',
      justifyContent: 'flex-start',
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    pageTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontColor: theme.palette.primary.dark,
    },
    topActions: {
      marginLeft: 'auto',
      height: 'auto',
    },
  })
);

type CreateInspectionType = 'blank' | 'template';

const createInspectionOptions: {label: string; value: CreateInspectionType}[] = [
  {
    label: '新規で点検表を作成',
    value: 'blank',
  },
  {
    label: 'テンプレートを元に作成',
    value: 'template',
  },
];

type HeaderProps = {
  onClickPrevButton: React.MouseEventHandler;
  onClickNewInspection: React.MouseEventHandler;
};

export const PageHeader: React.VFC<HeaderProps> = (props) => {
  const {onClickPrevButton, onClickNewInspection} = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [InspectionType, setInspectionType] = useAtom(inspectionTypeAtom);

  const handleChangeType = useCallback(
    (type) => {
      return setInspectionType(type);
    },
    [setInspectionType]
  );

  const handleMenuClick = useCallback(
    (item: MenuItemType, event: React.MouseEvent<Document, MouseEvent>) => {
      switch (item.value) {
        case 'blank':
          onClickNewInspection(event as never);
          break;
        case 'template':
          navigate(`/inspection/templates`);
          break;
      }
    },
    [navigate, onClickNewInspection]
  );

  const actionMenuItems = [
    {
      label: '点検表の設定',
      value: '',
    },
    {
      label: '点検開始日の設定',
      value: 'start_date',
    },
  ];
  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      if (item.value) {
        navigate(`/inspection/setting/${item.value}`);
      } else {
        navigate('/inspection/setting');
      }
    },
    [navigate]
  );
  return (
    <Grid container>
      <Grid item>
        <Grid container>
          <Grid item xs container direction="column">
            <Button color="inherit" className={classes.prevButton} onClick={onClickPrevButton}>
              <ChevronLeft />
              <span>点検一覧に戻る</span>
            </Button>
            <Typography variant="h1" className={classes.pageTitle}>
              点検対象製品の設定
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <TypeMenus type={InspectionType} onChange={handleChangeType} />
      <Grid item xs container justifyContent="flex-end" alignItems="center" className={classes.topActions}>
        <PopperMenuButton
          buttonProps={{variant: 'outlined', color: 'primary', disableElevation: true}}
          menuItemList={actionMenuItems}
          onMenuClick={handleActionMenuClick}
          containerProps={{style: {background: '#FFFFFF', marginRight: '8px'}}}>
          点検設定
        </PopperMenuButton>
        <PopperMenuButton
          placement="bottom-start"
          hiddenArrow={false}
          buttonProps={{variant: 'text', style: {width: '100%', color: 'white', backgroundColor: '#0A52CC'}}}
          menuItemList={createInspectionOptions}
          itemProps={{style: {minWidth: '100px'}}}
          onMenuClick={handleMenuClick}>
          点検表を作成
        </PopperMenuButton>
      </Grid>
    </Grid>
  );
};
