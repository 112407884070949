import React, {useMemo} from 'react';
import {Table, TableCell, TableCellProps, TableProps} from '@material-ui/core';

export const AnnualPlanTableHeadCell: React.FC<TableCellProps> = ({children, ...props}) => {
  const style = useMemo<React.CSSProperties>(
    () => ({
      borderBottom: '1.5px solid #DFE1E5',
      fontSize: '14px',
      color: '#6F798D',
      background: '#FFF',
      lineHeight: '24px',
      padding: 8,
      width: '72px',
      whiteSpace: 'nowrap',
    }),
    []
  );
  return (
    <TableCell {...props} style={style}>
      {children}
    </TableCell>
  );
};

export const AnnualPlanTableCategoryHeadCell: React.FC<TableCellProps> = ({children, ...props}) => {
  const style = useMemo<React.CSSProperties>(
    () => ({
      borderBottom: '1.5px solid #DFE1E5',
      fontSize: '16px',
      color: '#6F798D',
      lineHeight: '24px',
      padding: 8,
      width: '200px',
      whiteSpace: 'nowrap',
      background: '#FFF',
      position: 'sticky',
      left: 0,
    }),
    []
  );
  return (
    <TableCell {...props} style={style}>
      {children}
    </TableCell>
  );
};

type TableBodyCellProps = {
  hasCount: boolean;
} & TableCellProps;

export const AnnualPlanTableBodyCell: React.FC<TableBodyCellProps> = ({children, hasCount, ...props}) => {
  const style = useMemo<React.CSSProperties>(
    () => ({
      lineHeight: '24px',
      border: 'none',
      color: hasCount ? '#2A96EB' : '#616C82',
      fontWeight: hasCount ? 700 : 400,
      padding: 8,
      width: '72px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    }),
    [hasCount]
  );
  return (
    <TableCell {...props} style={style}>
      {children}
    </TableCell>
  );
};

export const AnnualPlanTableCategoryBodyCell: React.FC<TableCellProps> = ({children, ...props}) => {
  const style = useMemo<React.CSSProperties>(
    () => ({
      lineHeight: '24px',
      border: 'none',
      color: '#616C82',
      padding: 8,
      width: '132px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 0,
      background: '#FFF',
    }),
    []
  );
  return (
    <TableCell {...props} style={{...style, ...props.style}}>
      {children}
    </TableCell>
  );
};

export const AnnualPlanTable: React.FC<TableProps> = ({children, ...props}) => {
  const style = useMemo<React.CSSProperties>(
    () => ({
      tableLayout: 'fixed',
      borderCollapse: 'separate',
    }),
    []
  );
  return <Table style={style}>{children}</Table>;
};
