import React, {useCallback} from 'react';
import {createStyles, makeStyles, Theme, FormControl, Select} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selector: {
      minWidth: '250px',
    },
  })
);

type PullDownFieldProps = {
  value: string;
  options: {label: string; value: string}[];
  error?: React.ReactNode;
  isEditable: boolean;
  onChange?: (value: string) => void;
};

export const PullDownField: React.VFC<PullDownFieldProps> = (props) => {
  const {value, options, error, isEditable, onChange} = props;
  const classes = useStyles();

  const handleChange = useCallback(
    (e: React.ChangeEvent<{name?: string | undefined; value: unknown}>) => {
      if (isEditable === true && onChange) {
        onChange(e.target.value as string);
      }
    },
    [onChange, isEditable]
  );

  return (
    <FormControl variant="outlined">
      <Select
        native
        className={classes.selector}
        value={value}
        onChange={handleChange}
        inputProps={{
          readOnly: !isEditable,
        }}>
        <option aria-label="None" value="" />
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {error}
    </FormControl>
  );
};
