import {convertDateToSlashDateWithoutYear, getLastDay} from '@front-libs/helpers';

export const formatPercentage = (value: number) => Math.round(value * 100);

export const newHospitalProductSeries = (data: number[]) => {
  return {
    name: '機器台数',
    type: 'column',
    yAxis: 1,
    data,
    tooltip: {
      valueSuffix: '台',
    },
    color: '#BDBDBD',
  };
};

export const newAverageRentalRatioSeries = (data: number[]) => {
  return {
    name: '平均使用率',
    type: 'line',
    data,
    tooltip: {
      valueSuffix: ' %',
    },
    marker: {
      enabled: true,
      symbol: 'diamond',
    },
    color: '#0A52CC',
  };
};

export const newMaxRentalRatioSeries = (data: number[]) => {
  return {
    name: '最大使用率',
    type: 'line',
    data,
    tooltip: {
      valueSuffix: ' %',
    },
    marker: {
      enabled: true,
      symbol: 'circle',
    },
    dashStyle: 'shortdot',
    color: '#0E3870',
  };
};

export const newDailyAxisCategories = (lastHour: number) => {
  const categories = [];
  for (let i = 0; i < lastHour; i++) {
    categories.push(`${i}:00`);
  }
  return categories;
};

export const newWeeklyAxisCategories = (startDate: Date) => {
  const duration = 7;
  const categories = [];
  for (let i = 0; i < duration; i++) {
    const d = new Date(startDate);
    d.setDate(d.getDate() + i);
    categories.push(convertDateToSlashDateWithoutYear(d));
  }
  return categories;
};

export const newMonthlyAxisCategories = (year: number, month: number) => {
  const duration = getLastDay(year, month);
  const categories = [];
  for (let i = 1; i <= duration; i++) {
    const d = new Date(year, month - 1, i);
    categories.push(convertDateToSlashDateWithoutYear(d));
  }
  return categories;
};

export const newYearlyAxisCategories = (year: number) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const lastMonth = year === currentYear ? today.getMonth() + 1 : 12;

  const categories = [];
  for (let i = 1; i <= lastMonth; i++) {
    categories.push(`${i}月`);
  }
  return categories;
};
