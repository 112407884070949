import React, {useCallback, useMemo} from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import {DropResult, ResponderProvided, DragDropContext} from 'react-beautiful-dnd';
import {useUpdateKanbanStatusSubject} from '../hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFaultRepairPropertyRequirements, useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {updateFaultRepair, getFaultRepair} from '@modules/repairs/api';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {PropertyProposalDialog} from '@organisms/FaultRepairs/dialogs/PropertyProposalDialog';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {StatusPane} from './StatusPane';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {isNullish} from '@front-libs/helpers';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type RepairKanbanProp = {
  currentCardLayout: TableLayout[] | undefined;
};

export const RepairKanban: React.VFC<RepairKanbanProp> = (props) => {
  const {currentCardLayout} = props;

  const classes = useStyles();
  const {notify} = useUpdateKanbanStatusSubject();
  const {myInfo} = useMyInfo();
  const {hospitalUsers} = useHospitalUsers();
  const {data: faultRepairPropertyRequirements} = useFaultRepairPropertyRequirements();
  const statusQuery = useFetchFaultRepairStatuses(myInfo.hospitalHashId);
  const repairStatuses = useMemo(() => statusQuery.data.sort((a, b) => a.sequence - b.sequence), [statusQuery.data]);
  const {isReadOnly} = useMyRole();

  const handleDropRepairCard = useCallback(
    async (result: DropResult, provided: ResponderProvided) => {
      if (isNullish(faultRepairPropertyRequirements)) return;
      if (isReadOnly) return;
      if (result.destination && result.source.droppableId !== result.destination.droppableId) {
        const faultRepair = await getFaultRepair(myInfo.hospitalHashId, result.draggableId);
        const repairStatus = repairStatuses.find((item) => item.hashId === result.destination?.droppableId);
        const proposalProps = faultRepairPropertyRequirements
          .filter((item) => item.faultRepairStatusHashId === result.destination?.droppableId)
          .sort((a, b) => a.sequence - b.sequence);

        const validatedRepair = await dialogHandler.open(PropertyProposalDialog, {
          statusName: repairStatus?.statusName ?? '',
          propertyRequirements: proposalProps,
          defaultFaultRepair: faultRepair,
          hospitalUsers,
        });

        await updateFaultRepair(myInfo.hospitalHashId, faultRepair.hospitalProductHashId, result.draggableId, {
          ...validatedRepair,
          statusHashId: result.destination?.droppableId,
        });
        notify([result.source.droppableId, result.destination.droppableId]);
      }
    },
    [faultRepairPropertyRequirements, isReadOnly, myInfo.hospitalHashId, repairStatuses, hospitalUsers, notify]
  );

  return (
    <div className={classes.root}>
      <div className={classes.stageContainer}>
        <DragDropContext onDragEnd={handleDropRepairCard}>
          {repairStatuses.map((item, index) => {
            return (
              <StatusPane
                currentCardLayout={currentCardLayout}
                key={index}
                status={item}
                isFirst={index === 0}
                isLast={index === repairStatuses.length - 1}
              />
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'scroll',
      flexGrow: 1,
      display: 'flex',
      height: 0,
    },
    stageContainer: {
      flex: 2,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      userSelect: 'none',
      flexGrow: 1,
      flexShrink: 0,
    },
  })
);
