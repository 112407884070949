import {axios} from '@front-libs/core';
import {
  CreateFaultRepairPropertyRequrementsParams,
  FetchFaultRepairPropertyRequrementsParams,
  PropertyRequirements,
  ResequenceFaultRepairPropertyRequrementsParams,
  UpdateFaultRepairPropertyRequrementsParams,
} from '../types';

export const getFaultRepairPropertyRequirements = async (
  hospitalHashId: string,
  params: FetchFaultRepairPropertyRequrementsParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair_settings/property_requirements`;
  const {data} = await axios.get<PropertyRequirements[]>(baseUrl, {params});
  return data;
};

export const updateFaultRepairPropertyRequirement = (
  hospitalHashId: string,
  propertyRequirementHashId: string,
  data: UpdateFaultRepairPropertyRequrementsParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair_settings/property_requirements/${propertyRequirementHashId}`;
  return axios.put<PropertyRequirements>(baseUrl, data);
};

export const resequenceFaultRepairPropertyRequirements = (
  hospitalHashId: string,
  propertyRequirementHashId: string,
  data: ResequenceFaultRepairPropertyRequrementsParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair_settings/property_requirements/${propertyRequirementHashId}/sequence`;
  return axios.put<PropertyRequirements[]>(baseUrl, data);
};

export const createFaultRepairPropertyRequirement = (
  hospitalHashId: string,
  data: CreateFaultRepairPropertyRequrementsParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair_settings/property_requirements`;
  return axios.post<PropertyRequirements>(baseUrl, data);
};

export const deleteFaultRepairPropertyRequirement = (hospitalHashId: string, propertyRequirementHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair_settings/property_requirements/${propertyRequirementHashId}`;
  return axios.delete(baseUrl);
};
