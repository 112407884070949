import {Box, Typography} from '@material-ui/core';
import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {InspectionResultEvaluation} from '@modules/inspection_results/enum';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const InspectionResultEvaluationRow: React.FC<InspectionResultEvaluationRowProps> = (props) => {
  const {inspectionResultEvaluation} = props;
  return inspectionResultEvaluation === 'failed' ? <InspectionFailed /> : <InspectionPassed />;
};

type InspectionResultEvaluationRowProps = {
  inspectionResultEvaluation: InspectionResultEvaluation;
};

const InspectionPassed: React.FC = () => {
  return (
    <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <CheckCircleOutlineIcon style={{color: '#3C9E58', fontSize: '16px', alignContent: 'center'}} />
      <Typography style={{color: '#3C9E58', paddingLeft: '4px'}}>異常なし</Typography>
    </Box>
  );
};

const InspectionFailed: React.FC = () => {
  return (
    <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <ErrorOutlineIcon style={{color: '#C7243A', fontSize: '16px', alignContent: 'center'}} />
      <Typography style={{color: '#C7243A', paddingLeft: '4px'}}>異常あり</Typography>
    </Box>
  );
};
