import {useMemo} from 'react';
import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {HospitalWardLayoutSetting, UpdateHospitalWardLayoutSettingParams, customSequence} from './types';
import {getHospitalWardLayoutSettingsKey} from '@constants/api';

export type createHospitalWardLayoutSettingParam = {
  customSequence: customSequence[];
};

export const fetchHospitalWardLayoutSettings = async (hospitalHashId: string, userHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${userHashId}/hospital_ward_layout_setting`;
  const {data} = await axios.get<HospitalWardLayoutSetting>(baseUrl);
  return data;
};

export const updateHospitalWardLayoutSetting = async (
  hospitalHashId: string,
  userHashId: string,
  wardHashId: string,
  data: UpdateHospitalWardLayoutSettingParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${userHashId}/hospital_ward_layout_setting/${wardHashId}`;
  return await axios.put<HospitalWardLayoutSetting>(baseUrl, data);
};

export const useFetchHospitalWardLayoutSetting = (hospitalHashId: string, userHashId: string) => {
  const query = useQuery([getHospitalWardLayoutSettingsKey, hospitalHashId, userHashId], () =>
    fetchHospitalWardLayoutSettings(hospitalHashId, userHashId)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data,
    }),
    [query]
  );
};

export const createHospitalWardLayoutSetting = async (
  hospitalHashId: string,
  userHashId: string,
  data: createHospitalWardLayoutSettingParam
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${userHashId}/hospital_ward_layout_setting`;
  return await axios.post<HospitalWardLayoutSetting>(baseUrl, data);
};
