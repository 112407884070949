import React from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Section} from '@components/molecules/InspectionTableFormItems/Section';
import {useAtomValue} from 'jotai';
import {selectedTemplateAtom} from '../states';
import {Item} from './Item';
import {ResultItem} from '@modules/inspection_results/types';
import {InspectionItem} from '@modules/inspections/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: ' calc(100vh - 56px - 48px - 36px - 120px - 24px)',
    },
    items: {
      '& > div': {
        marginBottom: '28px',
      },
      '& > div:last-child': {
        marginBottom: '0px',
      },
    },
  })
);

export const MainContent: React.VFC = () => {
  const classes = useStyles();
  const selectedTemplate = useAtomValue(selectedTemplateAtom);

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid container direction="column" className={classes.items}>
        {selectedTemplate?.table.items.map((item, i) => {
          if (item.type === 'section') {
            return (
              <Section key={i} name={item.name}>
                {item.items.map((sectionItem, j) => (
                  <Item
                    key={j}
                    item={sectionItem as InspectionItem & {id: string}}
                    resultItem={
                      {
                        id: sectionItem.id + '',
                        type: sectionItem.type + '',
                        value: null,
                      } as ResultItem
                    }
                  />
                ))}
              </Section>
            );
          } else {
            return (
              <Item
                key={i}
                item={item as InspectionItem & {id: string}}
                resultItem={{id: item.id + '', type: item.type + '', value: null} as ResultItem}
              />
            );
          }
        })}
      </Grid>
    </Grid>
  );
};
