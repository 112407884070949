import React from 'react';
import ReactDOM from 'react-dom';
import {StylesProvider} from '@material-ui/styles';
import _ from 'lodash';
import {Theme} from '@material-ui/core';
import {QueryClient, QueryClientProvider} from 'react-query';
import {queryClient} from '@constants/api';

const mountPoint = document.getElementById('dialog');
const containerKey = 'hitotsu-dialog-container';
const dialogKey = 'hitotsu-dialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DialogProps<T = any, K = any> = {
  open: boolean;
  actions: {
    resolve: (res?: T) => Promise<K>;
    reject: (res?: T) => Promise<K>;
  };
};

type Option = {
  theme: Theme;
  queryClient: QueryClient;
};

export const dialogHandler = {
  /**
   * ダイアログを開く
   *
   * @param Component - 表示するコンポーネント
   * @param props - コンポーネントに渡すプロパティ`DialogProps` のキーは除外される
   * @param option - オプション
   * @returns ダイアログが閉じると解決される Promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open: function <P = any, Q = any>(
    Component: React.FC<P>,
    props: Omit<P, keyof DialogProps>,
    option?: Partial<Option>
  ) {
    return new Promise<Q>((resolve, reject) => {
      const handleResolve = async (res: Q) => {
        await this.close(Component, props, option);
        resolve(res);
      };
      const handleReject = async (res: Q) => {
        await this.close(Component, props, option);
        reject(res);
      };
      const actions = {resolve: handleResolve, reject: handleReject};
      let dialogProps = {...props, open: false, actions} as P & DialogProps;
      ReactDOM.render(
        <ProviderContainer option={option}>
          <Component {...dialogProps} key={dialogKey} />
        </ProviderContainer>,
        mountPoint,
        () => {
          dialogProps = {...props, open: true, actions} as P & DialogProps;
          ReactDOM.render(
            <ProviderContainer option={option}>
              <Component {...dialogProps} key={dialogKey} />
            </ProviderContainer>,
            mountPoint
          );
        }
      );
    });
  },
  /**
   * ダイアログを閉じる
   *
   * @param Component - 表示するコンポーネント
   * @param props - コンポーネントに渡すプロパティ
   * @param option - オプション
   * @returns ダイアログが閉じると解決される Promise
   */
  close: function (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: any,
    props: {
      // empty param
    },
    option?: Partial<Option>
  ) {
    const actions = {
      resolve: () => {
        // empty function
      },
      reject: () => {
        // empty function
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve) => {
      ReactDOM.render(
        <ProviderContainer option={option}>
          <Component {...props} actions={actions} key={dialogKey} />
        </ProviderContainer>,
        mountPoint,
        () => {
          _.delay(() => {
            resolve(null);
            // eslint-disable-next-line react/jsx-no-useless-fragment
            ReactDOM.render(<></>, mountPoint);
          }, 300);
        }
      );
    });
  },
};

function ProviderContainer({children}: {children: React.ReactNode; option?: Partial<Option>}) {
  return (
    <StylesProvider injectFirst key={containerKey}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </StylesProvider>
  );
}
