import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {RentalHeaderHeight} from '@Apps/BaseSharedMenu/Header';
import {RentalFooterHeightLaptop, RentalFooterHeightTabletH} from '@Apps/BaseSharedMenu/Footer';

export const useTemplate = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.grey[50],
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      placeContent: 'center',
      padding: '32px 32px',
      height: `calc(var(--outerHeight) - ${RentalFooterHeightTabletH} - ${RentalHeaderHeight})`,
      '@media screen and (orientation: portrait)': {
        height: `calc(var(--outerWidth) - ${RentalFooterHeightTabletH} - ${RentalHeaderHeight})`,
      },
      [theme.breakpoints.up('laptop')]: {
        height: `calc(100vh - ${RentalFooterHeightLaptop} - ${RentalHeaderHeight})`,
      },
    },
  })
);

export const defaultHeightWidth = {
  '--outerHeight': '100vh',
  '--outerWidth': '100vw',
} as React.CSSProperties;

export const setOuterHeight = () => {
  const element = document.querySelector('#mainContent') as HTMLElement;
  element.style.setProperty('--outerHeight', `${window.outerHeight}px`);
};

export const setOuterWidth = () => {
  const element = document.querySelector('#mainContent') as HTMLElement;
  element.style.setProperty('--outerWidth', `${window.outerWidth}px`);
};
