import React from 'react';
import {DateField, DateFieldValue} from '@molecules/DateField';

type DateColumnProps = {
  hashId: string;
  startDate: string | undefined;
  handleChangeStartDate: (productHashId: string, date: string) => Promise<void>;
};

export const DateColumn = ({hashId, startDate, handleChangeStartDate}: DateColumnProps) => {
  return (
    <DateField
      inputVariant="standard"
      value={startDate || null}
      onChange={(data: DateFieldValue) => {
        handleChangeStartDate(hashId, data as string);
      }}
    />
  );
};
