import MDialog, {DialogProps as MDialogProps} from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/styles';

export type DialogProps = MDialogProps;

export const FullSizeDialog = withStyles({
  paper: {
    height: 'calc(100% - 96px)',
  },
})(MDialog);

export const Dialog = MDialog;
