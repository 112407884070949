import React from 'react';
import {Button, Theme, styled} from '@material-ui/core';
import {Close} from '@material-ui/icons';

const StyledButton = styled(Button)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.grey[400],
  borderRadius: '2px',
  background: '#DAE5F7',
  color: theme.palette.rent.main,
  display: 'flex',
  padding: '2px 4px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
}));
const StyledCloseIcon = styled(Close)({
  fontSize: '12px',
  marginLeft: '2px',
});

type Props = {
  label: string;
  onClick: () => void;
};
export const ToolBarApplyFilterButton = ({label, onClick}: Props) => {
  return (
    <StyledButton onClick={onClick}>
      {label}
      <StyledCloseIcon />
    </StyledButton>
  );
};
