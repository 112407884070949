import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getHospitalKey} from '@constants/api';
import {HospitalDetail, HospitalIndex} from './types';
import {isNullish} from '@front-libs/helpers';

export type FetchHospitalResult = {
  totalCount: number;
  page: number;
  data: HospitalIndex;
};

export type UpdateHospitalParams = {
  name?: string;
  zip?: string;
  address?: string;
  adminUser?: string;
  initialized?: boolean;
  assetInitialized?: boolean;
  termStartMonth?: number;
};

const getHospital = async (hospitalHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}`;
  const data = await axios.get<HospitalIndex>(baseUrl);
  return data.data;
};

export const useFetchHospitalQuery = (hospitalHashId?: string) => {
  return useQuery([getHospitalKey, hospitalHashId], () => getHospital(hospitalHashId ?? ''), {
    enabled: !isNullish(hospitalHashId),
  });
};

export const updateHospital = async (hospitalHashId: string, data: UpdateHospitalParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}`;
  return axios.put<HospitalDetail>(baseUrl, data);
};
