import React, {createContext, useContext, useMemo, useState} from 'react';
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Formik, FormikHelpers, useFormikContext} from 'formik';
import {UpdatePasswordParams, updateUserPassword} from '@modules/auth/api';
import * as Yup from 'yup';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@components/molecules/SnackBar';
import {DialogInnerProps, DialogProps, FormikProps} from './types';
import {MuiDialogTitle} from './DialogTitle';
import {useFetchMyInfo} from '@modules/hospital_users/api';
import {InnerLoading} from '@components/molecules/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: '100%',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'end',
      padding: '16px 24px 24px',
    },
    textFieldTitle: {
      marginTop: 0,
      marginBottom: 8,
    },
    checkCircleIconSuccess: {
      color: theme.palette.success.main,
    },
    checkCircleIconFailure: {
      color: theme.palette.grey[400],
    },
  })
);

type PasswordConditionGridItemProps = {
  text: string;
  state: boolean;
};

const PasswordConditionGridItem: React.FC<PasswordConditionGridItemProps> = (props) => {
  const {text, state} = props;
  const classes = useStyles();

  return (
    <Grid container item direction="row" alignItems="center" xs={12}>
      <Grid item xs={1}>
        <CheckCircleIcon className={state ? classes.checkCircleIconSuccess : classes.checkCircleIconFailure} />
      </Grid>
      <Grid item xs={11}>
        {text}
      </Grid>
    </Grid>
  );
};

const EditPasswordDialogInner: React.FC<DialogInnerProps> = (props) => {
  const {open, onClose, formikProps} = props;
  const classes = useStyles();
  const {submitForm} = useFormikContext();
  const validationConditions = useContext(ValidationContext);

  const handleSubmit = () => {
    submitForm();
  };
  const dialogPaperProps = useMemo(() => ({style: {maxWidth: 440} as const}), []);

  return (
    <Dialog
      className={classes.dialog}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onClose}
      PaperProps={dialogPaperProps}
      aria-labelledby="edit-password-dialog-title"
      open={open}>
      <MuiDialogTitle id="edit-password-dialog-title" onClose={onClose}>
        パスワードを変更
      </MuiDialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className={classes.textFieldTitle}>現在のパスワード</p>
            <TextField
              fullWidth
              id="oldPassword"
              name="oldPassword"
              type="text"
              variant="outlined"
              size={'small'}
              value={formikProps.values.oldPassword}
              onChange={formikProps.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.textFieldTitle}>新しいパスワード</p>
            <TextField
              fullWidth
              id="newPassword"
              name="newPassword"
              type="text"
              variant="outlined"
              size={'small'}
              value={formikProps.values.newPassword}
              onChange={formikProps.handleChange}
            />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            {validationConditions.map((validationCondition, index) => (
              <PasswordConditionGridItem
                text={validationCondition.title}
                state={validationCondition.condition}
                key={index}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleSubmit} variant="contained">
          パスワードを変更
        </Button>
        <Button onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </Dialog>
  );
};

type conditionType = {
  title: string;
  condition: boolean;
};

type passwordsType = {oldPassword: string; newPassword: string};

type hundleSubmitValueType = {newPassword: string};

const ValidationContext = createContext<conditionType[]>([]);

export const EditPasswordDialog: React.FC<DialogProps> = (props) => {
  const {open, onClose} = props;
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useFetchMyInfo(myInfo.hashId);

  const validationConditions: conditionType[] = [{title: '半角英数字、半角記号で8文字以上', condition: false}];
  const [conditionState, setConditionState] = useState(validationConditions);

  const initialValidationConditionStates = [false];
  let validConditionNum = 0;

  const updateConditionState = (newValidationConditionStatesProp: boolean[]) => {
    const newValidationConditions = [...conditionState];
    newValidationConditionStatesProp.forEach((newValidationConditionState: boolean, index: number) => {
      const mutatingElement = {...newValidationConditions[index]};
      mutatingElement.condition = newValidationConditionState;
      newValidationConditions[index] = mutatingElement;
      setConditionState(newValidationConditions);
      if (newValidationConditionState === true) {
        validConditionNum++;
      }
    });
  };

  const initialValues = {
    oldPassword: '',
    newPassword: '',
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(),
    newPassword: Yup.string()
      .required()
      .test('passwordValidation', '', (value) => {
        if (value === undefined) {
          updateConditionState(initialValidationConditionStates);
          return false;
        }

        const hasEightCharactor = /^(?=.{8,})/.test(value);

        const newValidationConditionStates = [hasEightCharactor];

        updateConditionState(newValidationConditionStates);

        if (validConditionNum === newValidationConditionStates.length) {
          return true;
        }
        return false;
      }),
  });

  if (!data) {
    return null;
  }

  const handleSubmit = async (values: hundleSubmitValueType, {resetForm}: FormikHelpers<passwordsType>) => {
    try {
      const updateData: UpdatePasswordParams = {
        hashId: data.hashId,
        password: values.newPassword,
      };
      await updateUserPassword(updateData);
      onClose();
      resetForm();
      openSnackBar('パスワードを更新しました');
      updateConditionState(initialValidationConditionStates);
    } catch (error) {
      openSnackBar('パスワードの更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  if (isLoading) {
    return <InnerLoading />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(formik: FormikProps) => (
        <form onSubmit={formik.handleSubmit}>
          <ValidationContext.Provider value={conditionState}>
            <EditPasswordDialogInner open={open} onClose={onClose} formikProps={formik} />
          </ValidationContext.Provider>
        </form>
      )}
    </Formik>
  );
};
