import {CompanyIndex} from './types';

export const CompanyFormatter = {
  getOptions(companies: CompanyIndex[]) {
    return companies.map((company) => ({
      label: company.name,
      value: company.hashId,
    }));
  },
};
