import React, {memo, useMemo} from 'react';
import {FormikErrors, FormikTouched, useFormikContext, FormikContextType, getIn} from 'formik';

function objectShallowEqual<T>(a: T, b: T) {
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function pickField<T extends object, K extends keyof T>(fields: T, keys: K[]): Pick<T, K> {
  const values = {} as Pick<T, K>;
  for (const key of keys) {
    values[key] = getIn(fields, key as string);
  }
  return values;
}

export type WithFormikProps<F, V, P> = formikProps<F, V> & P;

type WithFormikConfigs<F, V, P> = {
  displayName: string;
  getNames: (props: P) => (keyof V)[];
  // メモ化コンポーネントの比較関数をオーバーラップする用
  compare?: (prev: WithFormikProps<F, V, P>, next: WithFormikProps<F, V, P>) => boolean;
};

type formikProps<F, V> = Omit<FormikContextType<F>, 'values' | 'errors' | 'touched'> & {
  values: V;
  errors: FormikErrors<V>;
  touched: FormikTouched<V>;
};

// formik + memo化用のHoC
// 複数フィールドを参照したい時に主に使う
// configsのgetNamesで取得した名前のフィールドのみコンポーネントに渡す, それ以外のフィールドが更新された場合は描画を抑制する
// eslint-disable-next-line @typescript-eslint/ban-types
export function withFormik<F extends V, V extends object, P>(config: WithFormikConfigs<F, V, P>) {
  type WP = WithFormikProps<F, V, P>;
  return (Component: React.FunctionComponent<WP>): React.ComponentType<P> => {
    const MemoizedComponent = memo<WP>(Component as React.FunctionComponent<WP>, (prev: WP, next: WP) => {
      const {values: pvalues, errors: perrors, touched: ptouched} = prev;
      const {values: nvalues, errors: nerrors, touched: ntouched} = next;

      if (config.compare) {
        return config.compare(prev, next);
      }
      return (
        objectShallowEqual(pvalues, nvalues) &&
        objectShallowEqual(perrors, nerrors) &&
        objectShallowEqual(ptouched, ntouched)
      );
    });
    MemoizedComponent.displayName = config.displayName;

    return (props: P) => {
      const {values, errors, touched, ...rest} = useFormikContext<F>();
      const names = useMemo(() => config.getNames(props), [props]);

      return (
        <MemoizedComponent
          {...props}
          {...rest}
          values={pickField(values, names)}
          errors={pickField(errors, names)}
          touched={pickField(touched, names)}
        />
      );
    };
  };
}
