import {useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useMemo} from 'react';
import {rentOrReturnAtom, rentWardAtom, defaultBarcodeScannerTypeAtom, rentOrReturnSettingAtom} from '../states';
import {useNavigate} from 'react-router-dom';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {rentalSettings} from '@modules/hospital_settings/types';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalWards} from '@modules/hospital_places/api';
import {useAtom} from 'jotai';

const nextLinks = {
  lender: '/shared/lenderSelection',
  returnByBarcodeReader: '/shared/return/product/barcode_reader',
  returnByCamera: '/shared/return/product/camera',
  ward: '/shared/rental/wardSelection',
  room: '/shared/rental/roomSelection',
};

export const useNavigateRentOrReturn = () => {
  const setRentOrReturn = useSetAtom(rentOrReturnAtom);
  const setRentalWard = useSetAtom(rentWardAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);

  const {myInfo} = useMyInfo();
  const {data: hospitalSettings} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  const {data: wardsData} = useFetchHospitalWards(myInfo.hospitalHashId);
  const navigate = useNavigate();

  const hospitalRentalSetting = useMemo(
    () => hospitalSettings?.data.find((setting) => setting.key === rentalSettings.use_device_lender.key),
    [hospitalSettings?.data]
  );

  const proceedRent = useCallback(() => {
    setRentOrReturn('rent');
    if (isNullish(hospitalRentalSetting) || hospitalRentalSetting?.value === 'not_need') {
      if (wardsData.length > 1) {
        navigate(nextLinks.ward);
      } else {
        setRentalWard(wardsData[0]);
        navigate(nextLinks.room);
      }
    } else {
      navigate(nextLinks.lender);
    }
  }, [hospitalRentalSetting, navigate, setRentOrReturn, setRentalWard, wardsData]);

  const proceedReturn = useCallback(() => {
    setRentOrReturn('return');
    if (isNullish(hospitalRentalSetting) || hospitalRentalSetting?.value === 'not_need') {
      defaultBarcodeScannerType === 'barcode_reader'
        ? navigate(nextLinks.returnByBarcodeReader)
        : navigate(nextLinks.returnByCamera);
    } else {
      navigate(nextLinks.lender);
    }
  }, [defaultBarcodeScannerType, hospitalRentalSetting, navigate, setRentOrReturn]);
  return {proceedRent, proceedReturn};
};

export const useRentOrReturnDeviceSetting = () => {
  const [rentOrReturnSetting, setRentOrReturnSetting] = useAtom(rentOrReturnSettingAtom);
  return {rentOrReturnSetting, setRentOrReturnSetting};
};
