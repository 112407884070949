import React, {useCallback, useMemo} from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  TextField,
  FormControlLabel,
  Typography,
  Checkbox,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {CheckboxField as CheckBoxFieldType} from '@Apps/Inspection/types';
import {FormikErrors, getIn} from 'formik';
import {theme} from '@atoms/theme';
import Select from 'react-select';
import {FormError} from '../FormError';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    inputOptionContainer: {
      height: '62px',
    },
    option: {
      display: 'flex',
      alignItems: 'center',
    },
    optionLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    inputOption: {
      width: '100%',
    },
    clearIcon: {
      padding: '0px',
    },
    optionError: {
      marginTop: '0px',
      marginLeft: '32px',
    },
    newOption: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    newOptionLabel: {
      color: '#7A869A',
    },
    summaryErrorContainer: {
      marginTop: '12px',
    },
  })
);

type CheckboxFieldProps = {
  field: CheckBoxFieldType;
  errors: FormikErrors<CheckBoxFieldType> & {_options?: string};
  focused: boolean;
  placeholder: string;
  onChangeField: (fieldName: string | '', value: unknown, validate: boolean) => void;
};

export const CheckboxField: React.VFC<CheckboxFieldProps> = (props) => {
  const {field, errors, focused, placeholder, onChangeField} = props;
  const classes = useStyles();
  const {validators} = field;

  const handleChangeOptionLabel = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
      if (field.options[index]) {
        onChangeField(
          `options[${index}]`,
          {
            label: e.target.value,
            value: e.target.value,
          },
          true
        );
      }
    },
    [field.options, onChangeField]
  );

  const handleDeleteOption = useCallback(
    (e: React.MouseEvent, index: number) => {
      const newField = {
        ...field,
        options: [...field.options],
      };

      const deletedOption = newField.options[index];
      newField.options.splice(index, 1);

      if (!!newField?.validators?.values && deletedOption.value === field.validators.values[0]) {
        newField.validators.values = [];
      }

      onChangeField('', newField, true);
    },
    [field, onChangeField]
  );

  const handleClickNewOption = useCallback(
    (e: React.MouseEvent) => {
      const newIndex = field.options.length;
      onChangeField(
        `options[${newIndex}]`,
        {
          label: '',
          value: '',
        },
        false
      );
    },
    [field.options.length, onChangeField]
  );

  const handleChangeValidator = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      onChangeField(
        `validators`,
        {
          values: [e.value],
        },
        true
      );
    },
    [onChangeField]
  );

  const validatorValue = useMemo(
    () => field.options.find((o) => o.value === validators.values[0]) ?? null,
    [field.options, validators.values]
  );

  const optionInputErrors = useMemo(() => {
    return field.options.map(({label}, index) => (
      label === '' ? `選択肢${index + 1}が入力されていません` : null
    )).filter((msg): msg is string => msg !== null)
  }, [field.options]);

  return focused === true ? (
    <Grid container direction="column">
      {field.options.map(({label, value: v}, index) => {
        const error = getIn(errors, `options[${index}].label`);

        return (
          <Grid key={index} container direction="column" className={classes.inputOptionContainer}>
            <Grid container className={classes.option}>
              <FormControlLabel
                key={index}
                className={classes.optionLabel}
                control={<Checkbox />}
                label={
                  <TextField
                    className={classes.inputOption}
                    variant="standard"
                    InputProps={{
                      className: classes.inputOption,
                      disableUnderline: true,
                    }}
                    error={!!error}
                    value={label}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOptionLabel(e, index)}
                  />
                }
              />
              <IconButton className={classes.clearIcon} onClick={(e) => handleDeleteOption(e, index)}>
                <Clear />
              </IconButton>
            </Grid>
            {focused === true && (
              <div className={classes.optionError}>
                <FormError error={error} reserveSpace={true} />
              </div>
            )}
          </Grid>
        );
      })}
      <FormControlLabel
        className={classes.newOption}
        onClick={handleClickNewOption}
        control={<Checkbox disabled={true} />}
        label={
          <Typography className={classes.newOptionLabel} variant="inherit">
            {placeholder}
          </Typography>
        }
      />
      {(optionInputErrors.length > 0 || errors._options) && (
        <Grid container direction="column" className={classes.summaryErrorContainer}>
          {optionInputErrors.map((message, index) => (
            <FormHelperText key={index} error={true}>{message}</FormHelperText>
          ))}
          <FormError error={errors._options} />
        </Grid>
      )}
      {field.settings.showsValidator && (
        <Grid container style={{marginLeft: '24px', marginTop: '16px'}}>
          <Grid item container alignItems="center">
            <Grid>エラー値の設定：次の値を含む</Grid>
            <Grid item style={{marginLeft: '16px', width: '150px'}}>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
                }}
                name="validator.values[0]"
                value={validatorValue}
                options={field.options.filter((o) => !!o.label)}
                onChange={handleChangeValidator}
              />
            </Grid>
          </Grid>
          <FormError error={errors?.validators?.values as string | undefined} />
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid container direction="column">
      <Grid>
        {field.options.map(({label}, index) => (
          <FormControlLabel
            className={classes.optionLabel}
            control={<Checkbox />}
            label={
              <TextField
                className={classes.inputOption}
                variant="standard"
                InputProps={{
                  className: classes.inputOption,
                  disableUnderline: true,
                }}
                value={label}
              />
            }
          />
        ))}
      </Grid>
      {(optionInputErrors.length > 0 || errors._options || errors?.validators?.values) && (
        <Grid container direction="column" className={classes.summaryErrorContainer}>
          {optionInputErrors.map((message, index) => (
            <FormHelperText key={index} error={true}>{message}</FormHelperText>
          ))}
          <FormError error={errors._options} />
          <FormError error={errors?.validators?.values as string | undefined} />
        </Grid>
      )}
    </Grid>
  );
};
