import React from 'react';
import {NotificationSettingsListResult} from '@modules/notification_settings/api';
import {NotificationSettingsFormType} from '.';

type FormSettingText = {
  type: keyof NotificationSettingsListResult;
  title: string;
  description: React.ReactNode;
  formKey: keyof NotificationSettingsFormType;
};

export const texts: FormSettingText[] = [
  {
    type: 'faultRepairPicNotification',
    title: '修理の担当者として割り当てられました',
    description: '修理対応があなたに割り当てられたときに通知を受け取ります。',
    formKey: 'isEnabledFaultRepairPic',
  },
  {
    type: 'inspectionReminder',
    title: '点検リマインダー',
    description: (
      <span>
        リマインダーを設定した場合、点検の期限が来たときに通知を受け取ります。
        <br />
        通知は午前6時ころに送られます。
      </span>
    ),
    formKey: 'isEnabledInspectionReminder',
  },
  {
    type: 'rentNotification',
    title: '機器の貸出',
    description: '機器の貸出登録が行われたときに通知を受け取ります。',
    formKey: 'isEnabledRentNotification',
  },
  {
    type: 'returnNotification',
    title: '機器の返却',
    description: '機器の返却登録が行われたときに通知を受け取ります。',
    formKey: 'isEnabledReturnNotification',
  },
  {
    type: 'pmdaUploadNotification',
    title: '添付文書の更新通知',
    description: '利用している機種に添付文書が更新された時に通知を受け取ります。',
    formKey: 'isEnabledPmdaUploadNotification',
  },
  {
    type: 'pmdaRecallNotification',
    title: '回収情報の発出通知',
    description: 'PMDAが医療機器に関する回収情報を発出した場合に通知を受け取ります。',
    formKey: 'isEnabledPmdaRecallNotification',
  },
  // {
  //   type: 'rentalAmountNotification',
  //   title: '貸出可能機器数の不足',
  //   description: '貸出可能な機器数を設定した場合、貸出可能機器数が設定値を下回ったときに通知を受け取ります。',
  //   formKey: 'isEnabledRentalAmountNotification',
  // },
];
