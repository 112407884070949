import {Box, Grid, Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import {ProductStatusIcon} from './ProductStatusIcon';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {useNavigate} from 'react-router-dom';

export const Product: React.FC<ProductProps> = (props) => {
  const {hospitalProduct} = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const handleActionMenuClick = () => {
    navigate(`/sp/products/${hospitalProduct.hashId}`);
  };
  // スタイルの幅や高さを調整する際はそれに合わせて文字数も変更必要
  const MAX_TEXT_LENGTH = 20;

  const changeText = (text: string, maxLength: number) =>
    text.length > maxLength ? text.substring(0, maxLength - 1) + '…' : text;
  return (
    <Grid item className={classes.root} onClick={handleActionMenuClick}>
      <Grid container item className={classes.leftContainer}>
        <ProductStatusIcon status={hospitalProduct.status} />
        <Box
          overflow="hidden"
          width="50px"
          height="100%"
          style={{marginLeft: '8px', whiteSpace: 'normal', wordBreak: 'break-all'}}>
          {changeText(hospitalProduct.managementId, MAX_TEXT_LENGTH)}
        </Box>
      </Grid>
      <Grid container item className={classes.rightContainer}>
        <Typography className={classes.displayNameTxt}>
          {hospitalProduct.displayName !== '' ? hospitalProduct.displayName : <br />}
        </Typography>
        <Typography className={classes.name}>{hospitalProduct.name !== '' ? hospitalProduct.name : <br />}</Typography>
        <Typography className={classes.makerName}>
          {hospitalProduct.maker.name !== '' ? hospitalProduct.maker.name : <br />}
        </Typography>
      </Grid>
    </Grid>
  );
};

type ProductProps = {
  hospitalProduct: HospitalProductIndex;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '82px',
    display: 'flex',
    margin: '8px 24px 8px 24px',
    borderBottom: '1px solid #DDE0E4',
  },
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#3C9E58',
  },
  leftContainer: {
    height: '75px',
    width: '68px',
    marginRight: '24px',
  },
  rightContainer: {
    height: '75px',
    width: '250px',
    paddingRight: '8px',
    flexDirection: 'column',
    textOverflow: 'ellipsis',
  },
  displayNameTxt: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    maxWidth: '100%',
  },
  name: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  makerName: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
}));
