import React, {useCallback} from 'react';
import {Grid, makeStyles, Theme, Paper, Avatar, Divider} from '@material-ui/core';
import {TrainingReport} from '@modules/training_schedules/types';
import {convertDateToJapaneseDateTime, extractTimeFromDate, StrUtil} from '@front-libs/helpers';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';

export type TrainingReportCardProps = {
  isAdmin: boolean;
  trainingReport: TrainingReport;
  onDeleteTrainingReport: (hashId: string) => void;
};

const actionMenuItems = [
  // {
  //   label: '詳細を確認',
  //   value: 'detail',
  // },
  {
    label: '実施記録を削除',
    value: 'delete',
  },
];

export const TrainingReportCard: React.FC<TrainingReportCardProps> = ({
  isAdmin,
  trainingReport,
  onDeleteTrainingReport,
}) => {
  const classes = useHistoryCardStyles();

  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      if (item.value === 'delete') onDeleteTrainingReport(trainingReport.hashId);
    },
    [onDeleteTrainingReport, trainingReport.hashId]
  );

  return (
    <Paper className={classes.root}>
      <Grid container alignItems={'center'}>
        <Grid item className={classes.avatarContainer}>
          <Avatar
            src={trainingReport.reportedHospitalUser && trainingReport.reportedHospitalUser.thumbnailFile?.url}
            className={classes.avatar}
          />
        </Grid>
        <Grid item className={classes.title}>
          {UserFormatter.getFullName(trainingReport.reportedHospitalUser)}さんが研修を実施しました。
        </Grid>
        <div className={classes.flex} />
        <Grid item>
          {isAdmin && (
            <PopperMenuButton
              buttonProps={{color: 'inherit', className: classes.actionMenu}}
              menuItemList={actionMenuItems}
              onMenuClick={handleActionMenuClick}>
              アクション
            </PopperMenuButton>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.contentsContainer}>
        <Grid item xs={4}>
          <p className={classes.propertyLabel}>報告者：</p>
          <p className={classes.property}>{UserFormatter.getFullName(trainingReport.reportedHospitalUser)}</p>
        </Grid>
        <Grid item xs={4}>
          <p className={classes.propertyLabel}>受講人数：</p>
          <p className={classes.property}>{trainingReport.numberOfAttendants}名</p>
        </Grid>
        <Grid item xs={4}>
          <p className={classes.propertyLabel}>開催 / 受講日時：</p>
          <p className={classes.property}>
            {convertDateToJapaneseDateTime(trainingReport.startedAt)}〜{extractTimeFromDate(trainingReport.finishedAt)}
          </p>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid className={classes.contentsContainer}>
        <p className={classes.propertyLabel}>研修内容の報告：</p>
        <p>{StrUtil.nl2br(trainingReport.description)}</p>
      </Grid>
    </Paper>
  );
};

const useHistoryCardStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
    width: '100%',
  },
  divider: {
    margin: '12px',
    marginLeft: '4px',
  },
  avatarContainer: {
    paddingRight: '16px',
  },
  contentsContainer: {
    margin: '12px',
  },
  avatar: {
    width: 32,
    height: 32,
  },
  actionMenu: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  flex: {
    flexGrow: 1,
  },
  title: {
    maxWidth: 'calc(100% - 160px)',
  },
  property: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.common.black,
  },
  propertyLabel: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.grey[600],
  },
}));
