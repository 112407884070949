import React, {useCallback, useMemo} from 'react';
import {useAtom, useAtomValue} from 'jotai';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {searchVariablesAtom} from './states/state';
import {Column} from '@molecules/Table/props';

import {PlanTableData} from './PlanList';
import {useMutation, useQuery} from 'react-query';
import {
  createTrainingPlan,
  CreateTrainingPlanParam,
  getTrainingPlans,
  updateTrainingPlan,
  UpdateTrainingPlanParam,
} from '@modules/training_plans/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {PlanStatus, TrainingTypeValue} from '@modules/training_plans/types';
import {isNullish, StrUtil} from '@front-libs/helpers';
import {createTrainingSchedule, CreateTrainingScheduleParam} from '@modules/training_schedules/api';
import {statusOptions, trainingTypeOptions} from '@modules/training_plans/constants';
import {StatusBadge} from '@molecules/StatusBadge';

const SortingDisableMap: Record<string, boolean> = {
  trainingCategory: true,
};

export const useSearchVariables = () => {
  const [variables, setVariables] = useAtom(searchVariablesAtom);

  const setName = useCallback(
    (name: string) => {
      setVariables({...variables, name: name === '' ? null : name});
    },
    [setVariables, variables]
  );
  const setStatus = useCallback(
    (status: PlanStatus | null) => {
      setVariables({...variables, status: status});
    },
    [setVariables, variables]
  );

  const setTrainingCategories = useCallback(
    (trainingTypes: TrainingTypeValue[]) => {
      setVariables({...variables, trainingTypes: trainingTypes.length === 0 ? null : trainingTypes});
    },
    [setVariables, variables]
  );

  const setPage = useCallback(
    (newPage: number) => {
      setVariables({...variables, page: newPage});
    },
    [setVariables, variables]
  );

  const setOrder = useCallback(
    (field: string, orderDirection: 'asc' | 'desc') => {
      setVariables({...variables, order: `${orderDirection === 'desc' ? '-' : ''}${field}`});
    },
    [setVariables, variables]
  );

  return {variables, setName, setStatus, setTrainingCategories, setPage, setOrder};
};

export const useTableData = () => {
  const {data, isLoading} = useTrainingPlansQuery();
  const [tableLayout] = useTableLayout('trainingPlanList');

  // Column
  const tableColumns = useMemo(() => {
    return (tableLayout?.currentLayout ?? []).map<Column<PlanTableData>>((item) => {
      const column: Column<PlanTableData> = {
        title: item.title,
        field: item.field,
        noBodyWrap: true,
        sorting: SortingDisableMap[item.field] === true ? false : true,
      };

      switch (item.field) {
        case 'status':
          column.render = ({status}: PlanTableData) => {
            if (status === null) return <StatusBadge status={'info'} text={'随時'} />;

            const statusOption = statusOptions.find((stOption) => stOption.value === status);
            const statusColor = status === 'complete' ? 'success' : 'primary';
            return <StatusBadge status={statusColor} text={statusOption?.label ?? ''} />;
          };
          break;
        case 'trainingCategory':
          column.render = ({trainingTypes}: PlanTableData) =>
            StrUtil.nl2br(
              trainingTypes
                .map((category) => {
                  const displayCategory = trainingTypeOptions.find(
                    (categoryOption) => categoryOption.value === category
                  );
                  if (isNullish(displayCategory)) {
                    return '';
                  } else {
                    return `■ ${displayCategory.label}`;
                  }
                })
                .join('\n')
            );
          break;
        case 'trainingProgress':
          column.render = ({scheduleTimes, reportTimes, planType}: PlanTableData) =>
            `${reportTimes}回 / ${planType === 'as_needed' ? '随時' : `年${scheduleTimes}回`}`;
          break;
        default:
          break;
      }
      return column;
    });
  }, [tableLayout?.currentLayout]);

  // TableData
  const planTableData = useMemo<PlanTableData[]>(() => {
    return (
      data?.data.map((item) => ({
        hashId: item.hashId,
        status: item.status,
        name: item.name,
        trainingTypes: item.trainingTypes,
        scheduleTimes: item.scheduleTimes ?? 0,
        reportTimes: item.reportTimes,
        planType: item.planType,
      })) ?? []
    );
  }, [data?.data]);

  return {tableColumns, planTableData, isLoading};
};

export const useTrainingPlansQuery = () => {
  const {myInfo} = useMyInfo();
  const searchVariables = useAtomValue(searchVariablesAtom);
  const query = useQuery(['useTrainingPlansQuery', searchVariables], () =>
    getTrainingPlans(myInfo.hospitalHashId, {
      name: searchVariables.name ?? undefined,
      status: searchVariables.status ?? undefined,
      trainingTypes: searchVariables.trainingTypes ?? undefined,
      page: searchVariables.page,
      perPage: searchVariables.perPage,
      order: searchVariables.order ?? undefined,
    })
  );
  return {...query, data: query.data};
};

export const useCreateTrainingPlanMutation = () => {
  const {refetch} = useTrainingPlansQuery();
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, CreateTrainingPlanParam>(
    (variables) => createTrainingPlan(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => refetch(),
    }
  );

  return mutation;
};

export const useUpdateTrainingPlanMutation = () => {
  const {refetch} = useTrainingPlansQuery();
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, UpdateTrainingPlanParam>(
    (variables) => updateTrainingPlan(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => refetch(),
    }
  );

  return mutation;
};

export const useCreateTrainingScheduleMutation = () => {
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, CreateTrainingScheduleParam>(
    (variables) => createTrainingSchedule(myInfo.hospitalHashId, variables),
    {}
  );

  return mutation;
};
