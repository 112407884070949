import {Grid, makeStyles} from '@material-ui/core';
import React, {useCallback} from 'react';
import {theme} from '@components/atoms/theme';
import {useNavigate} from 'react-router-dom';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {Footer} from '@Apps/BaseSharedMenu/Footer';
import camera from '../../../assets/camera.png';
import barcode2 from '../../../assets/barcode2.png';
import {ActivateCameraButton} from '../../../ActivateCameraButton';
import clsx from 'clsx';
import {defaultHeightWidth, useTemplate} from '@components/templates/RentalTemplate';
import {useSetAtom} from 'jotai';
import {scannedProductAtom} from '../states';
import {defaultBarcodeScannerTypeAtom} from '@Apps/BaseSharedMenu/states';

export const ConfirmScanReturnDevice: React.FC = () => {
  const classes = useStyles();
  const templateClasses = useTemplate();
  const navigate = useNavigate();
  const setRentHospitalProducts = useSetAtom(scannedProductAtom);
  const setDefaultBarcodeScannerType = useSetAtom(defaultBarcodeScannerTypeAtom);

  const handleOnClick = useCallback(() => {
    setRentHospitalProducts([]);
    navigate('/shared/return/product/camera/scan');
  }, [navigate, setRentHospitalProducts]);

  const handleClickSwitchBarcodeReader = useCallback(() => {
    setDefaultBarcodeScannerType('barcode_reader');
    navigate('/shared/return/product/barcode_reader');
  }, [navigate, setDefaultBarcodeScannerType]);

  return (
    <>
      <Header title={'返却'} />
      <Grid
        item
        id={'mainContent'}
        className={clsx(templateClasses.content, classes.contentContainer)}
        style={defaultHeightWidth}>
        <Grid container alignItems="center" justifyContent="space-around" className={classes.gridContainer}>
          <Grid item className={classes.cameraImgContainer}>
            <img src={camera} alt="カメラ読み取り" className={classes.cameraImg} />
          </Grid>
          <Grid item className={classes.rightSideContainer}>
            <img src={barcode2} alt="バーコードの場所" className={classes.barcodeImg2} />
            <ActivateCameraButton label={'読み取り開始'} onClick={handleOnClick} />
          </Grid>
        </Grid>
      </Grid>
      <Footer
        text={'返却機器のバーコードを\nカメラで読み取ります'}
        nextButtonLabel={'リーダーで読み取る'}
        onClickNextButton={handleClickSwitchBarcodeReader}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  cameraImgContainer: {
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '20px 80px',
    display: 'flex',
    justifyContent: 'center',
  },
  rightSideContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cameraImg: {
    maxWidth: '80%',
  },
  barcodeImg2: {
    maxWidth: '80%',
    marginBottom: '32px',
    [theme.breakpoints.up('tabletH')]: {
      marginBottom: '48px',
    },
  },
  contentContainer: {
    maxHeight: 'fit-content',
    overflow: 'scroll',
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.up('tabletH')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
  rentalTableContainer: {
    width: '65%',
    [theme.breakpoints.up('laptop')]: {
      width: '70%',
    },
  },
  barcodeInput: {
    position: 'absolute',
    top: 20,
    marginTop: '-550px',
    '@media screen and (orientation: portrait)': {
      marginTop: '0px',
      marginLeft: '-5500px',
    },
  },
  operationContainer: {
    width: '25%',
    height: 426,
    paddingBottom: 16,
  },
  gridContainer: {
    flexWrap: 'nowrap',
    gap: '40px',
  },
}));
