import React, {ComponentType, useCallback, useMemo, useState} from 'react';
import {FieldProps, useFormikContext} from 'formik';
import wrapField from './wrapField';
import FormControl from '@material-ui/core/FormControl';
import Select, {InputActionMeta, Props as ReactSelectProps} from 'react-select';
import {theme} from '@atoms/theme';

type Option = {
  label: string;
  value: string | number | boolean;
};

type Props = {
  label: string;
  options: Option[];
  placeholder?: string;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent) => void;
} & FieldProps &
  ReactSelectProps;

export function Selector(props: Props) {
  const {field, options, onChange, onInputChange, isDisabled, ...rest} = props;
  const [inputValue, setInputValue] = useState(rest.inputValue);
  const {setFieldValue} = useFormikContext();
  const value = useMemo(
    () =>
      field.value !== undefined && field.value !== null ? options.find((option) => option.value === field.value) : '',
    [field.value, options]
  );

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any) => {
      if (onChange) onChange(option);
      setFieldValue(field.name, option.value);
    },
    [field.name, onChange, setFieldValue]
  );

  return (
    <div>
      <FormControl variant="outlined" size={'small'} fullWidth>
        <Select
          menuPortalTarget={document.body}
          menuPlacement={'auto'}
          styles={{
            menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
          }}
          name={field.name}
          inputValue={inputValue}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value={value as any}
          options={options}
          onChange={handleChange}
          placeholder={props.placeholder ?? '検索...'}
          isDisabled={isDisabled}
          onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
            setInputValue(newValue);
            if (onInputChange) onInputChange(newValue, actionMeta);
          }}
          {...rest}
        />
      </FormControl>
    </div>
  );
}

export default wrapField(Selector as ComponentType<FieldProps>);
