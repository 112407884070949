import React, {useCallback, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {PopperSelectBoxProps, SelectOptionProps} from '.';
import {ClickAwayListener, Button, makeStyles} from '@material-ui/core';
import {ArrowDropDown, Close} from '@material-ui/icons';

type Props = {
  value: SelectOptionProps | SelectOptionProps[] | null;
  onClear?: () => void;
  onOpenSelectBox: (anchorEl: HTMLElement) => void;
  onClose: (event?: React.MouseEvent<Document, MouseEvent>) => void;
} & Omit<PopperSelectBoxProps, 'onChange' | 'value' | 'onOpen' | 'onClose'>;

export const OpenerButton: React.FC<Props> = ({
  children,
  buttonLabel,
  isMulti,
  value,
  buttonProps,
  hiddenArrow = false,
  onClear = () => {
    // empty function
  },
  onOpenSelectBox,
  onClose,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open && onClose) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // 複数選択可の場合は配列になってることを想定するため、valueのlengthで確認
  // 単一項目のみの選択の場合、valueがなければtrueだが、nullで流れるので論理的には疎通不可なロジック
  const isValueEmpty = useMemo(() => {
    if (value === null) return true;
    if (isMulti && (value as Array<SelectOptionProps>).length === 0) return true;
    if (!isMulti && !value) return true;
    return false;
  }, [value, isMulti]);

  const dispButtonLabel = useMemo(() => {
    const result = buttonLabel;
    if (isValueEmpty) {
      return result;
    } else {
      return isMulti
        ? `（${(value as Array<SelectOptionProps>).filter((val) => val.value !== 'all').length}）${buttonLabel}`
        : `${(value as SelectOptionProps)?.label ?? '表示不可'}`;
    }
  }, [buttonLabel, isValueEmpty, isMulti, value]);

  const toggleMenu = () => setOpen((_open) => !_open);
  const handleClose = useCallback(
    (e: React.MouseEvent<Document, MouseEvent>) => {
      e.stopPropagation();
      if (!open) return;
      setOpen(false);
    },
    [open]
  );

  const handleClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      toggleMenu();
      onOpenSelectBox(e.currentTarget);
    },
    [onOpenSelectBox]
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.container}>
        <Button
          {...buttonProps}
          onClick={handleClickButton}
          className={!isValueEmpty ? clsx(classes.popperBtnActive, classes.popperBtn) : classes.popperBtn}>
          {dispButtonLabel}
          {!hiddenArrow && <ArrowDropDown fontSize="small" />}
          {!isValueEmpty && (
            <Close
              titleAccess="clear"
              fontSize="small"
              onClick={(e) => {
                onClear();
                e.stopPropagation();
              }}
            />
          )}
        </Button>
        {children}
      </div>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles((theme) => ({
  popperBtn: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    marginLeft: '8px',
  },
  popperBtnActive: {
    backgroundColor: theme.palette.primary.light,
    '&&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  container: {
    display: 'inline',
  },
}));
