import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {createStyles, makeStyles, Avatar} from '@material-ui/core';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {useLogout} from '@modules/auth/hooks/useLogout';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    linkedButtonIcon: {
      height: '100%',
      paddingRight: '8px',
      borderRadius: 0,
      paddingLeft: '8px',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  })
);

export const ProfileMenu: React.VFC = () => {
  const {user} = useAuth0();
  const logout = useLogout();
  const navigate = useNavigate();
  const classes = useStyles();

  const profileMenuItems = [
    {
      label: 'プロフィール',
      value: 'profile',
    },
    {
      label: 'ログアウト',
      value: 'logout',
    },
  ];

  const handleActionMenuClick = (item: MenuItemType) => {
    switch (item.value) {
      case 'profile':
        navigate('/account/profile');
        break;

      case 'logout':
        logout();
        break;
    }
  };

  return (
    <PopperMenuButton
      hiddenArrow={true}
      buttonProps={{color: 'inherit', className: classes.linkedButtonIcon}}
      menuItemList={profileMenuItems}
      onMenuClick={handleActionMenuClick}>
      <Avatar src={user?.picture} />
    </PopperMenuButton>
  );
};
