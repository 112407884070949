import React from 'react';
import {Typography, Grid, makeStyles, Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  box: {
    fontSize: '14px',
    color: '#172B4D',
  },
}));

export const SettingColumn: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.fields}>
      <Box>
        <Typography variant="h2" style={{fontWeight: 'bold'}}>
          点検表を機種に紐づけました。
        </Typography>
        <Typography variant="h2" style={{fontWeight: 'bold'}}>
          次に、点検表詳細画面にて「点検間隔」と「点検開始日」を設定してください。
        </Typography>
      </Box>
      <Box sx={{color: '#172B4D'}}>
        <Typography style={{fontSize: '14px'}}>後で設定する場合は、以下の工程で設定することができます。</Typography>
        <Typography style={{fontSize: '14px'}}>点検間隔の設定:</Typography>
        <Typography style={{fontSize: '14px'}}>①右上の「点検設定▼」ボタンをクリック</Typography>
        <Typography style={{fontSize: '14px'}}>②「点検設定」ボタンをクリック</Typography>
        <Typography style={{fontSize: '14px'}}>
          ③「定期点検」を選択した上で、対象の点検表をクリックし、点検表編集画面へ移動
        </Typography>
        <Typography style={{fontSize: '14px'}}>④点検間隔の設定：「対象機種」タブから設定</Typography>
        <Typography style={{fontSize: '14px', marginLeft: '14px'}}>点検開始日:「点検開始日」タブから設定</Typography>
      </Box>
    </Grid>
  );
};
