import React, {useMemo} from 'react';
import {useCategoryTreesQuery} from '@modules/categories/hooks/useCategoryTrees';
import TableForm from './TableForm';
import {CategoryIndex} from '@modules/categories/types';

export const WholeProductTable: React.FC = () => {
  const {data} = useCategoryTreesQuery();

  const rootCategories = useMemo(() => data.map((item) => item.rootCategory), [data]);

  const narrowCategories = useMemo(() => {
    const tmpCategories: CategoryIndex[] = [];
    data.forEach((item) => {
      tmpCategories.push(...item.narrowCategories);
    });
    return tmpCategories;
  }, [data]);

  return <TableForm rootCategories={rootCategories} subCategories={narrowCategories} categoryTrees={data} />;
};
