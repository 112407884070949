import React from 'react';
import {Column as ColumnType, RowAction as RowActionType} from '../props';
import {Row} from './Row';

type ColumnsProps<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columns: ColumnType<T>[];
  rowActions: RowActionType<T>[];
  rowSize: 'medium' | 'small';
  showsCheckbox: boolean;
  onRowClick?: (e: React.MouseEvent, rawData: T) => void;
  onSelectRowChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const Columns = <T extends {}>(props: ColumnsProps<T>) => {
  const {data, columns, rowActions, rowSize, showsCheckbox, onRowClick, onSelectRowChange} = props;

  return (
    <>
      {data.map((rowData: T, index) => (
        <Row
          key={index}
          index={index}
          data={rowData}
          columns={columns}
          rowActions={rowActions}
          rowSize={rowSize}
          showsCheckbox={showsCheckbox}
          onClick={onRowClick ? (e) => onRowClick(e, rowData) : undefined}
          onSelect={(e) => onSelectRowChange(e, index)}
        />
      ))}
    </>
  );
};
