import {FetchTrainingReportsParam, getTrainingReports, getTrainingSchedule} from '../api';
import {useQuery} from 'react-query';
import {getTrainingReportsKey, getTrainingScheduleKey} from '@constants/api';
import {useMemo} from 'react';

export const useFetchTrainingScheduleQuery = (hospitalHashId: string, trainingScheduleHashId: string) => {
  return useQuery([getTrainingScheduleKey, hospitalHashId, trainingScheduleHashId], () =>
    getTrainingSchedule(hospitalHashId, trainingScheduleHashId)
  );
};

export const useFetchTrainingReportsQuery = (hospitalHashId: string, params: FetchTrainingReportsParam) => {
  const query = useQuery([getTrainingReportsKey, hospitalHashId, params], () =>
    getTrainingReports(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
