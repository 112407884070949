import React from 'react';
import {makeStyles, Grid, Typography, Theme, Button} from '@material-ui/core';
import {Close} from '@material-ui/icons';

type Props = {
  onClose: (event: unknown) => void;
  drawerTitle: string;
};

export const DrawerHeader: React.VFC<Props> = ({onClose, drawerTitle}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.headerContainer} justifyContent={'space-between'} alignItems={'center'}>
      <Grid item>
        <Typography variant={'h6'} style={{fontWeight: 'bold'}}>
          {drawerTitle}
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={onClose}>
          <Close />
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    padding: '24px 20px',
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
  },
}));
