import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'jotai';
import {QueryClientProvider} from 'react-query';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import localeJa from 'dayjs/locale/ja';
import dayjs from 'dayjs';
import {queryClient} from '@constants/api';
import {initializeDatadogRum} from './configs/datadog';
import {initializeSentry} from './configs/sentry';
import {HelmetProvider} from 'react-helmet-async';
import {initIndexedDB} from './indexedDB/database';
import {setServiceWorker} from './serviceWorker/serviceWorkerRegistration';

initializeSentry();
initializeDatadogRum();

// indexedDBの初期化
initIndexedDB();

dayjs.locale('ja');

// ServiceWorkerの登録
setServiceWorker();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <MuiPickersUtilsProvider utils={DayjsUtils} locale={localeJa}>
            <App />
          </MuiPickersUtilsProvider>
        </Provider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
