import {axios, BaseListResult} from '@front-libs/core';
import {convertDateToRFC3339, isNullish} from '@front-libs/helpers';
import {TrainingReport} from './types';

export type CreateTrainingReportParam = {
  trainingScheduleHashId: string;
  reportHospitalUserHashId: string;
  startedAt: Date;
  finishedAt: Date;
  description: string;
  numberOfAttendants: number;
  isCompletedSchedulePlan: boolean;
};

export type FetchTrainingReportsParam = {
  name?: string;
  trainingScheduleHashIds?: string[];
  trainingPlanHashIds?: string[];
  page?: number;
  perPage?: number;
  order?: string;
};

export type UpdateTrainingReportParam = {
  hashId: string;
  reportHospitalUserHashId?: string;
  startedAt?: Date;
  finishedAt?: Date;
  description?: string;
  numberOfAttendants?: number;
  isCompletedSchedulePlan?: boolean;
};

export type FetchTrainingReportsResult = BaseListResult<TrainingReport>;

export const createTrainingReport = async (hospitalHashId: string, data: CreateTrainingReportParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/training_managements/reports`;
  return await axios.post<TrainingReport>(baseURL, {
    ...data,
    startedAt: convertDateToRFC3339(data.startedAt),
    finishedAt: convertDateToRFC3339(data.finishedAt),
  });
};

export const getTrainingReports = async (hospitalHashId: string, params: FetchTrainingReportsParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/training_managements/reports`;
  const {data} = await axios.get<FetchTrainingReportsResult>(baseURL, {
    params: {
      ...params,
      trainingScheduleHashIds: params.trainingScheduleHashIds?.join(','), // 配列をカンマ区切りのstringに変換
      trainingPlanHashIds: params.trainingPlanHashIds?.join(','), // 配列をカンマ区切りのstringに変換
    },
  });
  return data;
};

export const updateTrainingReport = async (hospitalHashId: string, data: UpdateTrainingReportParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/training_managements/reports/${
    data.hashId
  }`;
  return await axios.put<TrainingReport>(baseURL, {
    ...data,
    startedAt: !isNullish(data.startedAt) ? convertDateToRFC3339(data.startedAt) : undefined,
    finishedAt: !isNullish(data.finishedAt) ? convertDateToRFC3339(data.finishedAt) : undefined,
  });
};

export const deleteTrainingReport = async (hospitalHashId: string, hashId: string) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/reports/${hashId}`;
  return await axios.delete(baseURL);
};
