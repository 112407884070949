import React, {useCallback, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import useWindowSize from 'react-use/lib/useWindowSize';

type Props = {
  src: string;
};

/**
 * スマートフォン向けにPDFドキュメントを表示するComponent
 * @param `src` (string): 表示するPDFファイルのURLまたはパス
 * @example
 * ```jsx
 * <PDFDisplay src="path/to/your/document.pdf" />
 * ```
 */
export const PDFDisplay: React.FC<Props> = ({src}) => {
  const [numPages, setNumPages] = useState<number>();
  const {width} = useWindowSize();

  const renderAllPages = useCallback(() => {
    if (!numPages) return null;
    return Array.from({length: numPages}, (_v, index) => (
      <div key={`grid_${index + 1}`}>
        <Page renderTextLayer={false} renderAnnotationLayer={false} pageNumber={index + 1} width={width} />
      </div>
    ));
  }, [numPages, width]);

  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
  };

  return (
    <Document
      file={src}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={''}
      options={{
        cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
      }}>
      {renderAllPages()}
    </Document>
  );
};
