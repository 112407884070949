import React, {useCallback, useEffect, useMemo} from 'react';
import {RepairStatuses} from '@modules/repairs/types';
import {useFaultRepairsQuery, useUpdateKanbanStatusSubject} from '../hooks';
import {RepairStatusStage} from '../components/RepairStatusStage';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';
import dayjs from 'dayjs';

type StatusPaneProps = {
  status: RepairStatuses;
  isFirst: boolean;
  isLast: boolean;
  currentCardLayout: TableLayout[] | undefined;
};

const HIDDEN_DURATION = -14;

export const StatusPane = ({currentCardLayout, status, isFirst, isLast}: StatusPaneProps) => {
  const updatedAtFrom = useMemo(
    () =>
      status.statusType === 'complete' || status.statusType === 'archive'
        ? dayjs().add(HIDDEN_DURATION, 'day').toDate()
        : undefined,
    [status.statusType]
  );
  const {data, refetch, totalCount} = useFaultRepairsQuery({
    statusHashId: status.hashId,
    updatedAtFrom: updatedAtFrom,
  });

  const {subscribe, unsubscribe} = useUpdateKanbanStatusSubject();

  const onUpdateStatusPane = useCallback(
    (hashIds: string[]) => {
      if (!hashIds.some((item) => item === status.hashId)) return;
      refetch();
    },
    [refetch, status.hashId]
  );

  useEffect(() => {
    subscribe(onUpdateStatusPane);
    return () => unsubscribe(onUpdateStatusPane);

    // NOTE: 無限ループを誘発するので、将来的にexhaustive-deps付けても対応しないようにする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RepairStatusStage
      currentCardLayout={currentCardLayout}
      hashId={status.hashId}
      title={status.statusName}
      count={totalCount}
      sequence={status.sequence}
      faultRepairs={data}
      isFirst={isFirst}
      isLast={isLast}
    />
  );
};
