import {useMemo, useCallback} from 'react';
import {tableLayoutFields} from '../constants';
import {TableLayoutKey} from '../types';
import {useTableLayoutResult} from './useTableLayoutResult';
import {isNullish} from '@front-libs/helpers';
import _ from 'lodash';

export type TableLayout = {
  title: string;
  field: string;
  disabledSelect?: boolean;
};

export type TableLayoutResult = {
  tableLayout: TableLayout[];
  currentLayout: TableLayout[];
  forceValue?: {[key: string]: boolean};
};

/** テーブルのレイアウトの取得・保存 */
export const useTableLayout = (
  key: TableLayoutKey,
  forceValue?: {[key: string]: boolean}
): [TableLayoutResult, (layout: TableLayoutResult | undefined) => void] => {
  // 定義されたカラム一覧
  const tableLayout = useMemo<TableLayout[]>(
    () => tableLayoutFields[key].map(({title, field}) => ({title, field})),
    [key]
  );

  // 保存されたテーブルレイアウト設定
  const [value, setValue] = useTableLayoutResult(key);

  const setTableLayout = useCallback(
    (layout: TableLayoutResult | undefined) => {
      if (layout !== undefined) {
        setValue(layout.currentLayout.map((v) => v.field));
      }
    },
    [setValue]
  );

  /** 現在のレイアウトを返す.
   * レイアウトが未設定の場合はデフォルトのレイアウトを返す
   */
  const currentLayout = useMemo(() => {
    if (!value || !Array.isArray(value)) return [];

    const currentLayoutMap = _.chain(value)
      .map((field, index) => ({field, index}))
      .keyBy('field')
      .value();

    return _.chain(tableLayout)
      .filter((layout) => {
        const field = layout.field;
        if (!isNullish(forceValue) && forceValue[field] !== undefined) {
          return forceValue[field];
        }

        return currentLayoutMap[field] !== undefined;
      })
      .sortBy((layout, originalIndex) => currentLayoutMap[layout.field]?.index ?? originalIndex)
      .value();
  }, [forceValue, tableLayout, value]);

  return [
    {
      tableLayout,
      currentLayout: currentLayout,
      forceValue,
    },
    setTableLayout,
  ];
};
