import React, {useCallback, useMemo, useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  makeStyles,
  TextField,
} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Clear, MoreVert, Search} from '@material-ui/icons';
import {DndContext, DndDraggable} from '@atoms/dnd';
import {DragStart, DropResult, ResponderProvided} from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import clsx from 'clsx';
import {isNullish} from '@front-libs/helpers';

export type TableColumns = {
  /**
   * テーブルの列に対する表示名
   */
  title: string;
  /**
   * テーブルの列に対するフィールドのキー値
   */
  field: string;
  /**
   * 選択不可にするかどうか
   */
  disabledSelect?: boolean;
};

export type TableLayoutDialogProps = {
  /**
   * テーブルの列
   * @default []
   */
  tableColumns: TableColumns[];
  defaultOptions?: TableColumns[];
  forceValue?: {[key: string]: boolean};
} & DialogProps;

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1,
  },

  columnOrderItem: {
    backgroundColor: theme.palette.grey[100],
    border: '1px solid #42526E',
    boxSizing: 'border-box',
    marginTop: 16,
    borderRadius: 4,
  },
  active: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[200],
  },
}));

export const TableLayoutDialog: React.FC<TableLayoutDialogProps> = ({
  tableColumns,
  defaultOptions,
  forceValue = {},
  ...props
}) => {
  const classes = useStyles();
  // NOTE:undefinedが代入されることがあり、ConsoleErrorが出るので暫定対応
  const isOpen = isNullish(props.open) ? false : props.open;
  const [selectedOptions, setSelectedOptions] = useState<TableColumns[]>(() => {
    const options = (defaultOptions || []).filter((o) => forceValue[o.field] !== false);

    // force to append to list
    const isInOptions: Record<string, boolean> = {};
    options.forEach((o) => {
      isInOptions[o.field] = true;
    });

    Object.entries(forceValue).forEach(([key, value]) => {
      if (value !== true || isInOptions[key]) return;
      const targetColumn = tableColumns.find((item) => item.field === key);
      if (targetColumn !== undefined) {
        options.push(targetColumn);
      }
    });
    return options;
  });
  const [searchText, setSearchText] = useState<string>('');
  const [onDragFieldIdx, setOnDragFieldIdx] = useState<number | null>(null);

  const handleSubmit = useCallback(
    async (e: React.MouseEvent) => {
      await props.actions.resolve(selectedOptions);
    },
    [props.actions, selectedOptions]
  );

  const handleChangeLayoutSelector = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const targetColumn = tableColumns.find((item) => item.field === e.target.value);
    if (!targetColumn) return;

    const newSelectedOptions = checked
      ? selectedOptions.concat([targetColumn])
      : selectedOptions.filter((item) => item.field !== e.target.value);

    setSelectedOptions(newSelectedOptions);
  };

  const handleChangeLayoutOrder = useCallback(
    (result: DropResult) => {
      setOnDragFieldIdx(null);
      if (!result.destination) return;
      const startIndex = result.source.index;
      const endIndex = result.destination.index;
      const newSelectedOptions = arrayMove(selectedOptions, startIndex, endIndex);
      setSelectedOptions(newSelectedOptions);
    },
    [selectedOptions]
  );

  const handleStartChangeLayout = (initial: DragStart, provided: ResponderProvided) => {
    setOnDragFieldIdx(Number(initial.draggableId));
  };

  const handleChangeLayoutSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleCancel = (target: TableColumns) => {
    const newSelectedOptions = selectedOptions.filter((item) => target.field !== item.field);
    setSelectedOptions(newSelectedOptions);
  };

  const searchColumns = useMemo(() => {
    return tableColumns.filter((item) => item.title.indexOf(searchText) !== -1);
  }, [searchText, tableColumns]);

  return (
    <Dialog open={isOpen} onClose={props.actions.reject} aria-labelledby="form-dialog-title" fullWidth maxWidth={'md'}>
      <DialogTitle>表示項目を変更する</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <TextField
                label={'検索'}
                variant={'outlined'}
                fullWidth
                size={'small'}
                InputProps={{
                  endAdornment: <Search />,
                }}
                onChange={handleChangeLayoutSearch}
              />
            </div>
            <div>
              {searchColumns.map((item, index) => {
                const disabled = item.disabledSelect || forceValue[item.field] !== undefined;
                const checked =
                  forceValue[item.field] ??
                  (item.disabledSelect || selectedOptions.some((_item) => _item.field === item.field));
                return (
                  <Grid key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          style={{padding: 9}}
                          onChange={handleChangeLayoutSelector}
                          value={item.field}
                          disabled={disabled}
                          checked={checked}
                        />
                      }
                      label={item.title}
                    />
                  </Grid>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>表示している項目（{selectedOptions.length}）</div>
            <div>
              <DndContext onDragStart={handleStartChangeLayout} onDragEnd={handleChangeLayoutOrder}>
                {selectedOptions.map((item, index) => (
                  <DndDraggable isDragDisabled={item.disabledSelect} index={index} draggableId={index} key={index}>
                    <ListItem
                      className={
                        index === onDragFieldIdx
                          ? clsx(classes.active, classes.columnOrderItem)
                          : classes.columnOrderItem
                      }
                      style={{
                        paddingLeft: 0,
                      }}>
                      <Grid container alignItems="center">
                        <MoreVert />
                        <Grid>{item.title}</Grid>
                        <div className={classes.flex} />
                        {forceValue[item.field] !== true && (
                          <IconButton onClick={() => handleCancel(item)} style={{padding: 0}}>
                            <Clear />
                          </IconButton>
                        )}
                      </Grid>
                    </ListItem>
                  </DndDraggable>
                ))}
              </DndContext>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} onClick={handleSubmit} color="primary">
          適用
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
