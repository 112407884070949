import React, {useState} from 'react';
import {Accordion, Box, styled} from '@material-ui/core';
import {SectionHeader} from './SectionHeader';
import {SectionContent} from './SectionContent';
import {SectionStatus} from '@Apps/InspectionResult/sp/common/validator';

const StyledRoot = styled(Box)({
  margin: '8px 16px',
  display: 'flex',
  flexDirection: 'column',
});

const StyledAccordion = styled(Accordion)({
  margin: '8px 0px',
  padding: '0px',
  minHeight: 'none',
});

type SectionProps = {
  name: string;
  inputStatus: SectionStatus;
};

/**
 * アコーディオン機能を備えたセクションコンポーネント
 * @param props.name - セクション名
 * @param props.children - 子要素
 * @param props.inputStatus - ステータス
 */
export const Section: React.FC<SectionProps> = (props) => {
  const {name, children, inputStatus} = props;

  const [expanded, setExpanded] = useState(true);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledRoot>
      <StyledAccordion expanded={expanded} onChange={toggleAccordion}>
        <SectionHeader name={name} expanded={expanded} inputStatus={inputStatus} />
        <SectionContent>{children}</SectionContent>
      </StyledAccordion>
    </StyledRoot>
  );
};
