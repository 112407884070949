import {Typography, styled} from '@material-ui/core';
import React from 'react';
import {ChatBubbleOutline} from '@material-ui/icons';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const LabelTypography = styled(Typography)({
  paddingLeft: '8px',
  color: '#0052CC',
  fontSize: '14px',
  cursor: 'pointer',
});

type Props = {
  onAddComment: () => void;
};

export const AddCommentIconButton = ({onAddComment}: Props) => {
  return (
    <RootContainer>
      <ChatBubbleOutline style={{color: '#0052CC', cursor: 'pointer'}} onClick={onAddComment} />
      <LabelTypography onClick={onAddComment}>コメントを追加</LabelTypography>
    </RootContainer>
  );
};
