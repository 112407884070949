import React, {useCallback} from 'react';
import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {InspectionResultListElement} from '@Apps/InspectionResultList/pc/types';
import {useNavigate} from 'react-router-dom';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    managementId: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  })
);

export const ManagementIDColumn: React.VFC<InspectionResultListElement> = (props) => {
  const {managementId, hospitalProductHashId} = props;
  const classes = useStyle();
  const navigate = useNavigate();

  const onClickID = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(`/products/${hospitalProductHashId}`);
    },
    [navigate, hospitalProductHashId]
  );

  return (
    <Typography className={classes.managementId} onClick={onClickID}>
      {managementId}
    </Typography>
  );
};
