import React, {useCallback, useMemo} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {yup} from '@front-libs/core';
import {InferType} from 'yup';
import {Form, Formik, useFormikContext} from 'formik';
import {useCategoryQuery} from '@modules/categories/hooks/useCategoryTrees';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Selector, Option} from '@molecules/Selector';
import {TextField} from '@molecules/Formik/fields';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {useFetchCompaniesQuery} from '@modules/companies/api';
import {ValueType} from 'react-select';
import {classNameOpts} from '@Apps/ProductRegistration/constants';
import {formatApprovalNumber, formatJANCode} from '@modules/hospital_products/utls';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

const validationSchema = yup.object({
  rawBarcode: yup.string().required(),
  rootCategory: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required()
    .nullable(false),
  subCategory: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required()
    .nullable(false),
  makerHashId: yup.string(),
  janCode: yup.string(),
  makerName: yup.string(),
  name: yup.string(),
  displayName: yup.string(),
  approvalNumber: yup.string(),
  className: yup.object({
    label: yup.string(),
    value: yup.string().oneOf(['one', 'two', 'three', 'four']),
  }),
});

type UnknownGS1ProductForm = InferType<typeof validationSchema>;

export type UnknownGS1ProductDialogProps = DialogProps & {
  hospitalHashId: string;
  gs1Barcode: string;
};

export type UnknownGS1ProductDialogResult = UnknownGS1ProductForm;

export const UnknownGS1ProductDialog: React.FC<UnknownGS1ProductDialogProps> = ({hospitalHashId, gs1Barcode, ...props}) => {
  return (
    <Formik<UnknownGS1ProductForm>
      initialValues={{
        rawBarcode: gs1Barcode,
        rootCategory: {
          label: '',
          value: '',
        },
        subCategory: {
          label: '',
          value: '',
        },
        makerHashId: undefined,
        makerName: undefined,
        name: undefined,
        displayName: undefined,
        approvalNumber: undefined,
        className: {
          label: '',
          value: '',
        },
        janCode: undefined,
      }}
      onSubmit={(values) => props.actions.resolve(values)}
      isInitialValid={false}>
      <Dialog
        open={props.open}
        onClose={props.actions.reject}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'}>
        <DialogTitle>機器を新規に登録する</DialogTitle>
        <UnknownGS1ProductDialogForm hospitalHashId={hospitalHashId} onCancel={props.actions.reject} />
      </Dialog>
    </Formik>
  );
};

type UnknownGS1ProductDialogFormProps = {
  hospitalHashId: string;
  onCancel: () => void;
};

const UnknownGS1ProductDialogForm: React.FC<UnknownGS1ProductDialogFormProps> = ({hospitalHashId, onCancel}) => {
  const {rootCategoryQuery, descendantCategoriesQueries, selectRootCategory} = useCategoryQuery();
  const {data: companies} = useFetchCompaniesQuery(hospitalHashId, {perPage: 100});
  const {setFieldValue, values, isValid, handleSubmit, handleChange} = useFormikContext<UnknownGS1ProductForm>();

  const rootCategoryOptions = useMemo(() => {
    return rootCategoryQuery.data.map((item) => ({
      value: item.hashId,
      label: item.name,
    }));
  }, [rootCategoryQuery.data]);

  const subCategoryOptions = useMemo(() => {
    return (descendantCategoriesQueries.data ?? []).map((item) => ({
      value: item.hashId,
      label: item.name,
    }));
  }, [descendantCategoriesQueries.data]);

  const companyOptions = useMemo(() => {
    return (companies?.data ?? []).map((c) => ({
      label: c.name,
      value: c.hashId,
    }));
  }, [companies]);

  const handleChangeRootCategory = useCallback(
    async (e: Option) => {
      selectRootCategory(e.value);
      setFieldValue('rootCategory', e);
      setFieldValue('subCategory', null);
    },
    [selectRootCategory, setFieldValue]
  );

  const handleChangeSubCategory = useCallback(
    (e: Option) => {
      setFieldValue('subCategory', e);
    },
    [setFieldValue]
  );

  return (
    <>
      <DialogContent>
        <Form style={{width: '100%'}}>
          <div style={{width: '100%', marginBottom: '16px'}}>
            読み込み頂いたGS1バーコードは、HITOTSUでは未登録の機種でした。
            <br />
            不明機種の情報をご入力いただき、機種情報を追加してください。 ここで追加頂いた情報は即時利用可能となります。
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>GS1-128バーコード</label>
            <TextField name="rawBarcode" disabled fullWidth size="small" />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>大分類</RequiredLabel>
            <Selector
              name="rootCategory"
              size={'small'}
              options={rootCategoryOptions}
              onChange={handleChangeRootCategory}
              value={values.rootCategory.value}
            />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>小分類</RequiredLabel>
            <Selector
              name="subCategory"
              size={'small'}
              value={values.subCategory?.value ?? null}
              options={subCategoryOptions}
              onChange={handleChangeSubCategory}
            />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>メーカー名</label>
            <Selector
              placeholder="テルモ"
              name="makerHashId"
              size={'small'}
              value={values.makerHashId ?? null}
              options={companyOptions}
              onChange={(val: Option) => {
                setFieldValue('makerHashId', val.value);
              }}
            />
          </div>
          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>型式</label>
            <TextField
              fullWidth
              id="name"
              name="name"
              type="text"
              variant="outlined"
              size={'small'}
              value={values.name}
              onChange={handleChange}
            />
          </div>
          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>機種名</label>
            <TextField
              fullWidth
              id="displayName"
              name="displayName"
              type="text"
              variant="outlined"
              size={'small'}
              value={values.displayName}
              onChange={handleChange}
            />
          </div>
          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>JANコード</label>
            <TextField
              fullWidth
              id="janCode"
              name="janCode"
              type="text"
              variant="outlined"
              size={'small'}
              value={values.janCode}
              onChange={handleChange}
              onBlur={() => {
                if (values.janCode) {
                  setFieldValue('janCode', formatJANCode(values.janCode));
                }
              }}
            />
          </div>
          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>クラス分類</label>
            <Selector
              name="className"
              size={'small'}
              value={values.className.value ?? null}
              options={classNameOpts}
              onChange={(val: ValueType<{label: string; value: string}, true>) => {
                setFieldValue('className', val);
              }}
            />
          </div>
          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>承認番号</label>
            <TextField
              fullWidth
              id="approvalNumber"
              name="approvalNumber"
              type="text"
              variant="outlined"
              size={'small'}
              value={values.approvalNumber}
              onChange={handleChange}
              onBlur={() => {
                if (values.approvalNumber) {
                  setFieldValue('approvalNumber', formatApprovalNumber(values.approvalNumber));
                }
              }}
            />
          </div>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={() => handleSubmit()}>
          登録
        </Button>
        <Button onClick={() => onCancel()} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </>
  );
};
