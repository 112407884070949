import {useMemo} from 'react';
import {axios} from '@front-libs/core';
import {
  CreateNoticeBoardData,
  CreateOrUpdateDashboardLayoutSettings,
  DashboardLayoutSetting,
  DashboardProductPinnedCommentsParam,
  DashboardProductPinnedCommentsResult,
  DashboardRepairPinnedCommentsParam,
  DashboardRepairPinnedCommentsResult,
  NoticeBoard,
  RentalCapabilityNarrowCategory,
  RentalCapabilitySummary,
  UncompletedInspectionsByCategoryResult,
} from '@modules/dashboard/types';
import {
  getProductPinnedCommentsKey,
  getRentalCapabilityNarrowCategoryKey,
  getRentalCapabilitySummaryKey,
  getRepairPinnedCommentsKey,
  getUncompletedInspectionsByNarrowCategoryKey,
  getUncompletedInspectionsByRootCategoryKey,
} from '@constants/api';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {CategoryIndex} from '../categories/types';
import {
  DashboardMonthlyInspectionParam,
  DashboardMonthlyInspectionResult,
  DashboardRentalHistoryParam,
  DashboardRentalHistoryResult,
} from './types';
import {getDashboardRentalHistoryKey, getDashboardMonthlyInspectionKey} from '@constants/api';

export const getRentalCapabilitySummary = async (hospitalHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/dashboard/rental_capabilities/summary`;
  const {data} = await axios.get<RentalCapabilitySummary>(baseUrl);
  return data;
};

export const useFetchRentalCapabilitySummaryQuery = (hospitalHashId: string) => {
  return useQuery([getRentalCapabilitySummaryKey, hospitalHashId], () => getRentalCapabilitySummary(hospitalHashId));
};

export const getRentalCapabilityNarrowCategory = async (hospitalHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/dashboard/rental_capabilities/narrow_category`;
  const {data} = await axios.get<RentalCapabilityNarrowCategory>(baseUrl);
  return data;
};

export const useFetchRentalCapabilityNarrowCategoryQuery = (hospitalHashId: string) => {
  return useQuery([getRentalCapabilityNarrowCategoryKey, hospitalHashId], () =>
    getRentalCapabilityNarrowCategory(hospitalHashId)
  );
};

const baseUrl = (hospitalHashId: string) =>
  `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/dashboard`;

export const getDashboardRentalHistory = async (hospitalHashId: string, params: DashboardRentalHistoryParam) => {
  const {data} = await axios.get<DashboardRentalHistoryResult>(`${baseUrl(hospitalHashId)}/rental/history`, {
    params,
  });
  return data;
};

export const useDashboardRentalHistoryQuery = (hospitalHashId: string, params: DashboardRentalHistoryParam) => {
  const query = useQuery([getDashboardRentalHistoryKey, hospitalHashId, params], () =>
    getDashboardRentalHistory(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export type UncompletedInspectionsByInspectionTypeResult = {
  inspectionType: 'periodic' | 'post_use';
  numberOfUncompletedInspection: number;
  numberOfTotalInspection: number;
}[];

export const getUncompletedInspectionsByInspectionType = async (
  hospitalHashId: string,
  durationType: 'monthly' | 'yearly'
) => {
  const {data} = await axios.get<UncompletedInspectionsByInspectionTypeResult>(
    `${baseUrl(hospitalHashId)}/inspections/uncompleted/inspection_type/${durationType}`
  );
  return data;
};

export type PeriodicInspectionsAnnualPlanResult = {
  category: CategoryIndex;
  inspectionPlans: {
    month: number;
    numberOfInspection: number;
  }[];
}[];

export const getPeriodicInspectionsAnnualPlan = async (hospitalHashId: string) => {
  const {data} = await axios.get<PeriodicInspectionsAnnualPlanResult>(
    `${baseUrl(hospitalHashId)}/inspections/annual_plan`
  );
  return data;
};

export const useGetUncompletedInspectionsByInspectionTypeQuery = (
  hospitalHashId: string,
  durationType: 'monthly' | 'yearly',
  options?: Omit<
    UseQueryOptions<
      UncompletedInspectionsByInspectionTypeResult,
      unknown,
      UncompletedInspectionsByInspectionTypeResult,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<UncompletedInspectionsByInspectionTypeResult>(
    ['getUncompletedInspectionsByInspectionType', hospitalHashId, durationType],
    () => getUncompletedInspectionsByInspectionType(hospitalHashId, durationType),
    options
  );
};

export const useGetPeriodicInspectionsAnnualPlanQuery = (
  hospitalHashId: string,
  options?: Omit<
    UseQueryOptions<PeriodicInspectionsAnnualPlanResult, unknown, PeriodicInspectionsAnnualPlanResult, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<PeriodicInspectionsAnnualPlanResult>(
    ['getPeriodicInspectionsAnnualPlan', hospitalHashId],
    () => getPeriodicInspectionsAnnualPlan(hospitalHashId),
    options
  );
};

export const getDashboardMonthlyInspection = async (
  hospitalHashId: string,
  params: DashboardMonthlyInspectionParam
) => {
  const {data} = await axios.get<DashboardMonthlyInspectionResult>(
    `${baseUrl(hospitalHashId)}/inspections/uncompleted/all/monthly`,
    {
      params,
    }
  );
  return data;
};

export const useDashboardMonthlyInspectionQuery = (hospitalHashId: string, params: DashboardMonthlyInspectionParam) => {
  const query = useQuery([getDashboardMonthlyInspectionKey, hospitalHashId, params], () =>
    getDashboardMonthlyInspection(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const getUncompletedInspectionsByNarrowCategory = async (hospitalHashId: string) => {
  const {data} = await axios.get<UncompletedInspectionsByCategoryResult>(
    `${baseUrl(hospitalHashId)}/inspections/uncompleted/narrow_category`
  );
  return data;
};

export const useUncompletedInspectionsByNarrowCategoryQuery = (hospitalHashId: string) => {
  return useQuery([getUncompletedInspectionsByNarrowCategoryKey, hospitalHashId], () =>
    getUncompletedInspectionsByNarrowCategory(hospitalHashId)
  );
};

export const getProductPinnedComments = async (hospitalHashId: string, params: DashboardProductPinnedCommentsParam) => {
  const {data} = await axios.get<DashboardProductPinnedCommentsResult>(
    `${baseUrl(hospitalHashId)}/products/logs/pinned`,
    {
      params,
    }
  );
  return data;
};

export const getUncompletedInspectionsByRootCategory = async (hospitalHashId: string) => {
  const {data} = await axios.get<UncompletedInspectionsByCategoryResult>(
    `${baseUrl(hospitalHashId)}/inspections/uncompleted/root_category`
  );
  return data;
};

export const useProductPinnedCommentsQuery = (hospitalHashId: string, params: DashboardProductPinnedCommentsParam) => {
  const query = useQuery([getProductPinnedCommentsKey, hospitalHashId, params], () =>
    getProductPinnedComments(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useUncompletedInspectionsByRootCategoryQuery = (hospitalHashId: string) => {
  return useQuery([getUncompletedInspectionsByRootCategoryKey, hospitalHashId], () =>
    getUncompletedInspectionsByRootCategory(hospitalHashId)
  );
};

export const createNoticeBoard = (hospitalHashId: string, data: CreateNoticeBoardData) => {
  return axios.post<CreateNoticeBoardData, NoticeBoard>(`${baseUrl(hospitalHashId)}/notice_board`, data);
};

export const getCurrentNoticeBoard = async (hospitalHashId: string) => {
  const res = await axios.get(`${baseUrl(hospitalHashId)}/notice_board/current`);
  return res.data;
};

export const getDashboardLayoutSettings = async (hospitalHashId: string) => {
  const res = await axios.get<DashboardLayoutSetting>(`${baseUrl(hospitalHashId)}/layout/settings`);
  return res.data;
};

export const useDashboardLayoutSettingsQuery = (hospitalHashId: string) => {
  return useQuery(['getDashboardLayoutSettingsKey', hospitalHashId], () => getDashboardLayoutSettings(hospitalHashId));
};

export const createOrUpdateDashboardLayoutSettings = async (
  hospitalHashId: string,
  data: CreateOrUpdateDashboardLayoutSettings
) => {
  return axios.post<CreateOrUpdateDashboardLayoutSettings, DashboardLayoutSetting>(
    `${baseUrl(hospitalHashId)}/layout/settings`,
    data
  );
};

export const getRepairPinnedComments = async (hospitalHashId: string, params: DashboardRepairPinnedCommentsParam) => {
  const {data} = await axios.get<DashboardRepairPinnedCommentsResult>(
    `${baseUrl(hospitalHashId)}/repairs/logs/pinned`,
    {
      params,
    }
  );
  return data;
};

export const useRepairPinnedCommentsQuery = (hospitalHashId: string, params: DashboardRepairPinnedCommentsParam) => {
  const query = useQuery([getRepairPinnedCommentsKey, hospitalHashId, params], () =>
    getRepairPinnedComments(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
