import React, {useCallback, useEffect} from 'react';
import {Box, Button, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {Header} from '../Header';
import {CheckCircle} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {useAtomValue} from 'jotai';
import {rentOrReturnAtom} from '../states';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {defaultHeightWidth, setOuterHeight, setOuterWidth} from '@components/templates/RentalTemplate';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: '100%',
    height: 'calc(var(--outerHeight) - 104px)',
    '@media screen and (orientation: portrait)': {
      height: 'calc(var(--outerWidth) - 104px)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    paddingBottom: '80px',
  },
  checkCircle: {
    width: '256px',
    height: '256px',
    color: '#3C9E58',
  },
  textContainer: {
    paddingLeft: '32px',
  },
  title: {
    fontSize: '42px',
    fontWeight: 'bold',
  },
  text: {
    marginTop: '24px',
    fontSize: '20px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    width: '256px',
    height: '84px',
    borderRadius: '20px',
    boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
    fontSize: '24px',
    fontWeight: 'bold',
    color: theme.palette.rent.main,
  },
}));

export const Complete: React.VFC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const timespanReturnHome = 5 * 1000;
  const rentOrReturn = useAtomValue(rentOrReturnAtom);

  let type: '貸出' | '返却';
  if (rentOrReturn === 'rent') {
    type = '貸出';
  } else type = '返却';

  const handleClick = useCallback(() => {
    navigate('/shared');
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClick();
    }, timespanReturnHome);
    return () => clearTimeout(timer);
  }, [handleClick, timespanReturnHome]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  return (
    <>
      <Header />
      <Box id={'mainContent'} className={classes.content} style={defaultHeightWidth}>
        <Grid container alignItems={'center'} justifyContent={'center'} className={classes.container}>
          <Grid item>
            <CheckCircle className={classes.checkCircle} />
          </Grid>
          <Grid item className={classes.textContainer}>
            <Typography variant="h1" className={classes.title}>
              {type}登録が完了しました
            </Typography>
            <Typography className={classes.text}>
              正常に{type}情報が登録されました。
              <br />
              しばらく時間が経つと、最初の画面に戻ります。
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.btnContainer}>
          <Button className={classes.button} onClick={handleClick} data-testid={'return-top-button'}>
            <ChevronLeftIcon style={{paddingRight: 8}} />
            はじめに戻る
          </Button>
        </Box>
      </Box>
    </>
  );
};
