import {makeStyles} from '@material-ui/core';
import React from 'react';

interface ProductStatusIconProps {
  status: string;
}

export const ProductStatusIcon: React.FC<ProductStatusIconProps> = ({status}) => {
  const classes = useStyles({status});
  return <div className={classes.circle} />;
};

const useStyles = makeStyles((theme) => ({
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: (props: {status: string}) => {
      switch (props.status) {
        case 'ready':
          return '#3C9E58';
        case 'working':
          return '#C7243A';
        case 'uninspected':
          return '#826149';
        case 'repairing':
          return theme.palette.primary.dark;
        case 'disabled':
          return '#727272';
        default:
          return '#3C9E58';
      }
    },
  },
}));
