export type HospitalSettingsIndex = {
  data: {
    hospitalHashId: string;
    key: HospitalSettingKey;
    value: string;
  }[];
};

export type HospitalSettingsDetail = HospitalSettingsIndex;

export type HospitalSettingKey =
  | typeof allowTypeStatusKey
  | typeof automationCreateStatusKey
  | typeof allowUpdateCompletedInspectionResultStatusKey
  | typeof rentalSettings['use_device_lender']['key']
  | typeof rentalSettings['allow_undefined_room']['key']
  | typeof rentalSettings['skip_floor_selection']['key']
  | typeof rentalSettings['pre_inspection_rental_restriction_period']['key']
  | typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings['daily']['key']
  | typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings['pre_use']['key']
  | typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings['in_use']['key']
  | typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings['post_use']['key']
  | typeof inspectionTypeTokAllowApplyPreviousInspectionResultSettings['periodic']['key']
  | typeof allowJointingPostUseInspectionResult
  | typeof periodForJointingPostUseInspectionResultKey;

export type UpdateHospitalSettingsParams = {
  hospitalHashId: string;
  key: HospitalSettingKey;
  value: string;
};

export type HospitalRentalSetting = 'not_need' | 'by_barcode' | 'by_direct_input';

export const prefixAllowApplyPreviousInspectionResult = 'allow_apply_previous_inspection_result_';

export const inspectionTypeTokAllowApplyPreviousInspectionResultSettings = {
  daily: {
    field: 'allowApplyPreviousInspectionResultDaily',
    key: 'allow_apply_previous_inspection_result_daily',
  },
  pre_use: {
    field: 'allowApplyPreviousInspectionResultPreUse',
    key: 'allow_apply_previous_inspection_result_pre_use',
  },
  in_use: {
    field: 'allowApplyPreviousInspectionResultInUse',
    key: 'allow_apply_previous_inspection_result_in_use',
  },
  post_use: {
    field: 'allowApplyPreviousInspectionResultPostUse',
    key: 'allow_apply_previous_inspection_result_post_use',
  },
  periodic: {
    field: 'allowApplyPreviousInspectionResultPeriodic',
    key: 'allow_apply_previous_inspection_result_periodic',
  },
} as const;

export const rentalSettings = {
  use_device_lender: {
    field: 'needManageMedicalDeviceLenderStatus',
    key: 'need_manage_medical_device_lender_status',
  },
  allow_undefined_room: {
    field: 'allowRentalRoomUndefined',
    key: 'allow_rental_room_undefined',
  },
  skip_floor_selection: {
    field: 'allowSkipRentalFloorSelection',
    key: 'allow_skip_rental_floor_selection',
  },
  enable_pre_inspection_rental_restriction: {
    field: 'enableInspectionRentalRestriction',
  },
  pre_inspection_rental_restriction_period: {
    field: 'preInspectionRentalRestrictionPeriod',
    key: 'pre_inspection_rental_restriction_period',
  },
} as const;

export const allowTypeStatusKey = 'allow_type_status' as const;
export const automationCreateStatusKey = 'automation_create_status' as const;
export const allowUpdateCompletedInspectionResultStatusKey = 'allow_update_completed_inspection_result' as const;
export const allowJointingPostUseInspectionResult = 'allow_jointing_post_use_inspection_result' as const;
export const periodForJointingPostUseInspectionResultKey = 'period_for_jointing_post_use_inspection_result' as const;
