import {withStyles} from '@material-ui/core';
import MDialogActions from '@material-ui/core/DialogActions';

export const DialogActions = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 12,
    margin: 0,
  },
})(MDialogActions);
