import React, {useMemo, useState, useEffect, useCallback} from 'react';
import {makeStyles, Grid, Button, Typography, Drawer, Theme} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {EditCondition} from './EditCondition';
import {FilterResultBody} from './FilterResultBody';
import {ConditionKeyList} from './ConditionKeyList';
import {
  ConditionValue,
  DateRangeConditionValue,
  DefaultResultType,
  DefaultValueType,
  FilterOption,
  ResultType,
  TextConditionValue,
  NumberConditionValue,
} from './types';
import _ from 'lodash';
import {convertDateToSimpleDate} from '@front-libs/helpers';

export type FilterDrawerProps = {
  open: boolean;
  title?: string;
  filterOptions: FilterOption[];
  currentCount: number;
  defaultFilterResults?: ResultType[];
  onFilterChange: (filterResults: ResultType[]) => void;
  onDrawerClose?: () => void;
};

const getFilterLabel = (
  filterOption: FilterOption,
  value: {label: string[]} | DateRangeConditionValue | ConditionValue | TextConditionValue | NumberConditionValue
) => {
  switch (filterOption.optionType) {
    case 'text':
      return value as TextConditionValue;
    case 'selector':
      return (value as {label: string}[]).map((item) => item.label).join(', ');
    case 'dateRange': {
      const typedValue = value as DateRangeConditionValue;

      let text = '';
      if (typedValue.from !== undefined) {
        text += convertDateToSimpleDate(typedValue.from);
      }
      text += ' から ';
      if (typedValue.to !== undefined) {
        text += convertDateToSimpleDate(typedValue.to);
      }

      return text;
    }
    case 'number': {
      const unitLabel = filterOption.unitLabel ?? '';
      const typedValue = value as NumberConditionValue;

      let text = '';
      if (typedValue.from !== undefined) {
        text += typedValue.from + unitLabel;
      }
      text += ' から';
      if (typedValue.to != undefined) {
        text += typedValue.to + unitLabel;
      }
      return text;
    }
  }
};

const getResultValue = (
  option: FilterOption | undefined,
  defaultValue: [DefaultValueType, DefaultValueType]
): ConditionValue => {
  if (!option) throw new Error('FilterOption undefined');
  if (option.optionType === 'selector') {
    return option.options.filter((item) => item.value === defaultValue);
  } else if (option.optionType === 'dateRange' || option.optionType === 'date' || option.optionType === 'number') {
    return {from: defaultValue[0], to: defaultValue[1]} as DateRangeConditionValue;
  } else if (option.optionType === 'text') {
    return defaultValue + '';
  } else {
    throw new Error('Invalid optionType');
  }
};

export const getResultTypesFromDefaultTypes = (
  defaultValues: DefaultResultType[],
  filterOptions: FilterOption[]
): ResultType[] => {
  return defaultValues.map<ResultType>((item) => {
    const filterOpt = filterOptions.find((option) => option.value === item.key);
    const resultValue = getResultValue(filterOpt, item.resultValue);
    return {
      key: item.key,
      displayLabel: filterOpt?.label ?? '',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filterLabel: getFilterLabel(filterOpt!, resultValue) ?? '',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      resultValue: resultValue,
    };
  });
};

export const FilterDrawer: React.VFC<FilterDrawerProps> = ({
  open,
  title,
  filterOptions,
  currentCount,
  defaultFilterResults = [],
  onFilterChange,
  onDrawerClose,
}) => {
  const classes = useStyles();
  const [result, setResult] = useState<ResultType[]>(defaultFilterResults);
  const [showResult, setShowResult] = useState<boolean>(result.length > 0);
  const [selectedCondition, setSelectedCondition] = useState<FilterOption | null>(null);
  const filteredKeys = useMemo(() => result.map((item) => item.key), [result]);
  const [firstCount, setFirstCount] = useState(-1);

  useEffect(() => {
    if (firstCount === -1 && open) setFirstCount(currentCount);
    if (!open) setFirstCount(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, currentCount]);

  useEffect(() => {
    if (result.length === 0) setShowResult(false);
  }, [result]);

  const updateResult = useCallback(
    (data: ResultType[]) => {
      setResult(data);
      onFilterChange(data);
    },
    [onFilterChange]
  );

  const handleClickClose = useCallback(() => {
    if (onDrawerClose) onDrawerClose();
    if (result.length > 0) setShowResult(true);
  }, [result, onDrawerClose]);

  const handleClickCondition = useCallback((condition) => setSelectedCondition(condition), []);

  const handleReturnResultBody = useCallback(() => {
    setShowResult(true);
    setSelectedCondition(null);
  }, []);

  const handleSubmit = useCallback(() => {
    setShowResult(true);
    setSelectedCondition(null);
  }, []);

  const handleClickReturn = useCallback(() => {
    setSelectedCondition(null);
  }, []);

  const handleAddFilter = useCallback(
    (key, value) => {
      const idx = result.findIndex((item) => item.key === key);
      const filterOption = filterOptions.find((i) => i.value === key);
      if (filterOption === undefined) {
        return;
      }

      const resultValue = {
        key,
        displayLabel: filterOption?.label || '',
        filterLabel: getFilterLabel(filterOption, value),
        resultValue: value,
      } as ResultType;

      if (idx === -1) {
        updateResult(result.concat([resultValue]));
      } else {
        const cloneResult = _.cloneDeep(result);
        cloneResult[idx] = resultValue;
        updateResult(cloneResult);
      }
    },
    [result, filterOptions, updateResult]
  );

  const defaultCondition = useMemo(
    () => result.find((item) => item.key === selectedCondition?.value)?.resultValue,
    [result, selectedCondition]
  );

  const handleNavigateCard = useCallback(
    (key: string) => {
      setShowResult(false);
      setSelectedCondition(filterOptions.find((item) => item.value === key) || null);
    },
    [filterOptions]
  );

  const handleReqAdditionalFilter = useCallback(() => setShowResult(false), []);

  const handleRemoveFilterByKey = useCallback(
    (key: string) => {
      updateResult(result.filter((i) => i.key !== key));
      setSelectedCondition(null);
    },
    [result, updateResult]
  );

  return (
    <Drawer anchor={'right'} open={open} variant="persistent" PaperProps={{className: classes.root, elevation: 4}}>
      <Grid container className={classes.headerContainer} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant={'h6'} style={{fontWeight: 'bold'}}>
            詳細で絞り込む
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={handleClickClose}>
            <Close fontSize={'large'} />
          </Button>
        </Grid>
      </Grid>

      {/* フィルター項目を選択するリスト */}
      {!showResult && selectedCondition === null && (
        <ConditionKeyList
          title={title}
          filterOptions={filterOptions}
          filteredOptionKeys={filteredKeys}
          onClickCondition={handleClickCondition}
          onReturnResultBody={handleReturnResultBody}
        />
      )}

      {/* フィルター項目を選択した後のフィルタ設定用コンポーネント */}
      {!showResult && selectedCondition !== null && (
        <EditCondition
          selectedOption={selectedCondition}
          defaultCondition={defaultCondition}
          onSubmit={handleSubmit}
          onClickReturn={handleClickReturn}
          onAddFilter={handleAddFilter}
        />
      )}

      {/* フィルターの設定結果リスト */}
      {showResult && (
        <FilterResultBody
          results={result}
          totalCount={firstCount}
          filteredCount={currentCount}
          onNavigateCard={handleNavigateCard}
          onReqAdditionalFilter={handleReqAdditionalFilter}
          onRemoveFilterByKey={handleRemoveFilterByKey}
        />
      )}
    </Drawer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: 400,
  },
  headerContainer: {
    padding: '32px 24px 0 32px',
  },
  searchContainer: {
    padding: '32px 32px 0',
  },
  contentContainer: {
    padding: '32px',
  },
  property: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  searchText: {
    marginTop: '16px',
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
  },
}));
