// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {useCallback, useState} from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {Button, createStyles, Grid, makeStyles, Theme, TextField, useMediaQuery} from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {Search, Tune} from '@material-ui/icons';
// import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
// import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
// import {UserFormatter} from '@modules/hospital_users/helpers';
// import {TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';
// import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
// import {IconButton} from '@material-ui/core';
// import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
// import {CategoryFormatter} from '@modules/categories/helpers';
// import {useGetHospitalCategories, useGetHospitalDescendantCategories} from '@modules/categories/api';
// import {
//   useInspectionResultStatus,
//   useRootCategory,
//   useNarrowCategory,
//   useInspector,
//   useIsDetailFilterActive,
// } from './states/states';
// import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
// import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flexGrow: 1,
    },
    root: {
      // '& > *:not(:first-child)': {
      //   marginLeft: 0,
      // },
    },
    searchNameContainer: {
      minWidth: '270px',
    },
    searchName: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
      // [theme.breakpoints.up('tabletH')]: {},
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    tableContainer: {
      margin: '24px 0px',
    },
    rentalHistoryMenu: {
      width: '204px',
      height: '32px',
      backgroundColor: '#FAFBFC',
      marginLeft: '40px',
    },
    dateSelector: {
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      margin: '0px 8px',
    },
    dateMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    dateDivider: {
      margin: '0px 8px',
    },
    filterBtnActive: {
      backgroundColor: theme.palette.primary.light,
      '&&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

/* const actionMenus4Tablet = [
  {
    label: '詳細で絞り込む',
    value: 'filterDetailCondition',
  },
  {
    label: '表示項目を変更',
    value: 'changeTableLayout',
  },
]; */

type ToolBarProp = {
  searchName: string;
  // tableLayout: TableLayoutResult | undefined;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
  // onChangeTableLayout: (layout: TableLayoutResult) => void;
  // onClickDrawer: () => void;
};

export const ToolBar: React.VFC<ToolBarProp> = (props) => {
  const {searchName, onChangeSearchName} = props;
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item sm={4} md={3} className={classes.searchNameContainer}>
        <TextField
          className={classes.searchName}
          label={'機種名・型式・管理番号で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={searchName}
          onChange={onChangeSearchName}
        />
      </Grid>
    </Grid>
  );
};
