import React, {useCallback, useMemo, useState} from 'react';
import {CategoryTrees} from '@modules/categories/hooks/useCategoryTrees';
import {CategoryIndex} from '@modules/categories/types';
import {Option, Selector} from '@molecules/Selector';
import {Box} from '@material-ui/core';

type Props = {
  categoryTrees: CategoryTrees;
  rootCategories: CategoryIndex[];
  narrowCategories: CategoryIndex[];
  defaultRootCategoryHashId?: string;
  defaultNarrowCategoryHashId?: string;
  onChange: (option: [string | null, string | null]) => void;
};

export const CategorySelector: React.FC<Props> = ({
  categoryTrees,
  narrowCategories,
  rootCategories,
  defaultRootCategoryHashId,
  defaultNarrowCategoryHashId,
  onChange,
}) => {
  const [rootCategoryHashId, setRootCategoryHashId] = useState(defaultRootCategoryHashId);
  const [narrowCategoryHashId, setNarrowCategoryHashId] = useState(defaultNarrowCategoryHashId);
  const narrowCategoryOptions = useMemo(() => {
    if (!rootCategoryHashId) return narrowCategories.map((item) => ({value: item.hashId, label: item.name}));

    const tree = categoryTrees.find((item) => item.rootCategory.hashId === rootCategoryHashId);
    return (tree?.narrowCategories ?? narrowCategories).map((item) => ({value: item.hashId, label: item.name}));
  }, [categoryTrees, narrowCategories, rootCategoryHashId]);

  const handleChangeRootCategory = useCallback(
    (value: Option) => {
      setRootCategoryHashId(value.value);
      const tree = categoryTrees.find((item) => item.rootCategory.hashId === value.value);
      const includeNarrowCategoryOptions = (tree?.narrowCategories ?? narrowCategories).some(
        (item) => item.hashId === narrowCategoryHashId
      );
      const narrowCategoryValue = includeNarrowCategoryOptions ? narrowCategoryHashId : undefined;
      setNarrowCategoryHashId(narrowCategoryValue);

      onChange([value.value, narrowCategoryValue ?? null]);
    },
    [categoryTrees, narrowCategories, narrowCategoryHashId, onChange]
  );

  const handleNarrowCategory = useCallback(
    (value: Option) => {
      setNarrowCategoryHashId(value.value);

      const rootCategory =
        rootCategoryHashId ??
        categoryTrees.find((item) => item.narrowCategories.some((narrow) => narrow.hashId === value.value))
          ?.rootCategory.hashId;

      onChange([rootCategory ?? null, value.value]);
    },
    [categoryTrees, onChange, rootCategoryHashId]
  );

  return (
    <>
      <Box style={{marginBottom: 16}}>
        <div>大分類</div>
        <Selector
          value={rootCategoryHashId ?? null}
          options={rootCategories.map((item) => ({value: item.hashId, label: item.name}))}
          onChange={handleChangeRootCategory}
          menuPosition={'fixed'}
        />
      </Box>
      <Box>
        <div>小分類</div>
        <Selector
          value={narrowCategoryHashId ?? null}
          options={narrowCategoryOptions}
          onChange={handleNarrowCategory}
          menuPosition={'fixed'}
        />
      </Box>
    </>
  );
};
