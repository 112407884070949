import {FetchHospitalProductsParams, getHospitalProducts} from '@modules/hospital_products/api';
import {deleteHospitalProductsByHospitalHashId, upsertHospitalProducts} from './repository';

// 初回フルデータ取得
export const initHospitalProducts = async (hospitalHashId: string) => {
  const NUM_PER_PAGE = 100;
  const params: FetchHospitalProductsParams = {
    page: 0,
    perPage: NUM_PER_PAGE,
  };
  const initialData = await getHospitalProducts(hospitalHashId, params);
  const totalPage = Math.ceil(initialData.totalCount / NUM_PER_PAGE);
  if (initialData.data.length !== 0) {
    await upsertHospitalProducts(initialData.data);
  }

  await upsertHospitalProducts(initialData.data);

  // 2ページ目以降のデータを取得
  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await getHospitalProducts(hospitalHashId, params);
    if (data.data.length !== 0) {
      await upsertHospitalProducts(data.data);
    }
  }
};

// 差分データ取得
export const updateHospitalProducts = async (hospitalHashId: string, updatedAt: string) => {
  const NUM_PER_PAGE = 100;
  const params: FetchHospitalProductsParams = {
    page: 0,
    perPage: NUM_PER_PAGE,
    updatedAtFrom: updatedAt,
  };
  const updateData = await getHospitalProducts(hospitalHashId, params);
  const totalPage = Math.ceil(updateData.totalCount / NUM_PER_PAGE);
  if (updateData.data.length !== 0) {
    await upsertHospitalProducts(updateData.data);
  }
  // 2ページ目以降のデータを取得
  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await getHospitalProducts(hospitalHashId, params);
    if (data.data.length !== 0) {
      await upsertHospitalProducts(data.data);
    }
  }
};

// 病院IDに紐づくデータを削除
export const deleteHospitalProducts = async (hospitalHashId: string) => {
  await deleteHospitalProductsByHospitalHashId(hospitalHashId);
};
