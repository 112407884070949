import React from 'react';
import {SimpleDialog, SimpleDialogProps} from '@molecules/Dialogs/BaseDialog';
import {DialogTitleProps} from '@atoms/Dialog/DialogTitle';

const defaultProps: Partial<SimpleDialogProps> = {
  positiveButtonLabel: '完了する',
  negativeButtonLabel: 'キャンセル',
  dialogTitleProps: {} as DialogTitleProps,
  warning: false,
};

const injectDialogProps = {disabledCancel: true};
type Props = SimpleDialogProps;

export const MessageDialog: React.FC<Props> = (props) => {
  const {title, dialogTitleProps, ...rest} = {
    ...defaultProps,
    ...props,
  };

  return <SimpleDialog title={title} {...rest} dialogTitleProps={{...injectDialogProps, ...dialogTitleProps}} />;
};
