import React, {useState, useCallback} from 'react';
import {Grid, TextField, makeStyles} from '@material-ui/core';
import {NumberConditionValue} from '../types';

type Props = {
  label: string;
  value: string;
  optionType: 'number';
  min?: number;
  max?: number;
  onlyInteger?: boolean;
  defaultValues?: {
    from?: number;
    to?: number;
  };
  onSubmit: (options: NumberConditionValue) => void;
  onChange: (options: NumberConditionValue) => void;
};

export const FilterDrawerNumberRangeField: React.VFC<Props> = (props) => {
  const {min, max, defaultValues, onChange} = props;
  const classes = useStyles();
  const [selected, setSelected] = useState<NumberConditionValue>({});

  const handleChangeCompletedAtFrom = useCallback(
    (d: number | null) => {
      // eslint-disable-next-line no-shadow
      const newValue = {
        ...selected,
        from: d ?? undefined,
      };

      onChange(newValue);
      setSelected(newValue);
    },
    [onChange, selected]
  );

  const handleChangeCompletedAtTo = useCallback(
    (d: number | null) => {
      // eslint-disable-next-line no-shadow
      const newValue = {
        ...selected,
        to: d ?? undefined,
      };

      onChange(newValue);
      setSelected(newValue);
    },
    [onChange, selected]
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={5}>
        <TextField
          variant="outlined"
          type="number"
          size="small"
          defaultValue={defaultValues?.from}
          inputProps={{
            min,
            max,
          }}
          placeholder={''}
          onChange={(e) => handleChangeCompletedAtFrom(Number(e.target.value))}
        />
      </Grid>
      <Grid item>
        <span className={classes.dateDivider}> 〜 </span>
      </Grid>
      <Grid item xs={5}>
        <TextField
          variant="outlined"
          type="number"
          size="small"
          defaultValue={defaultValues?.to}
          placeholder={''}
          inputProps={{
            min,
            max,
          }}
          onChange={(e) => handleChangeCompletedAtTo(Number(e.target.value))}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  dateDivider: {
    margin: '0px 8px',
  },
}));
