import React from 'react';
import {Button, Grid, Typography} from '@material-ui/core';
import {Sidebar} from '@components/organisms/Sidebar';
import {Formik} from 'formik';
import {InnerLoading} from '@molecules/Loading';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {withSuspense} from '@front-libs/core';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {UpdateHospitalParams, updateHospital, useFetchHospitalQuery} from '@modules/hospital/api';
import {openSnackBar} from '@molecules/SnackBar';
import {styled} from '@material-ui/styles';

const HospitalInfoForm: React.VFC = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <>
      <Grid container className={templateClasses.grid}>
        <Grid item className={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item className={templateClasses.content}>
          <PageTitleContainerGrid container>
            <Grid item>
              <Typography variant={'h5'} className={templateClasses.pageTitle}>
                病院情報
              </Typography>
            </Grid>
            <div className={templateClasses.flex} />
          </PageTitleContainerGrid>
          <ItemGrid>病院情報はHITOTSU Hospital で閲覧、変更できます。</ItemGrid>
          <Grid>
            <a href={import.meta.env.VITE_ACCOUNT_SERVICE_URL} target="_blank" rel="noreferrer">
              <Button variant="contained" color="primary">
                HITOTSU Hospitalへ移動
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <FormikFormSubmitDrawer />
    </>
  );
};

const PageTitleContainerGrid = styled(Grid)({
  marginBottom: '32px',
});
const ItemGrid = styled(Grid)({
  marginRight: '32px',
  color: '#172B4D',
  fontSize: '20px',
  marginBottom: '24px',
});

const HospitalInfoContainer: React.FC = ({children}) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchHospitalQuery(myInfo.hospitalHashId);

  if (isLoading) {
    return <InnerLoading />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (res: any) => {
    try {
      const updateData: UpdateHospitalParams = {
        name: res?.name,
        zip: res?.zip,
        address: res?.address,
        adminUser: res?.adminUser,
        termStartMonth: res?.termStartMonth,
      };
      await updateHospital(res.hashId, updateData);
      await refetch();
      openSnackBar('病院情報を更新しました');
    } catch (error) {
      openSnackBar('病院情報の更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <Formik initialValues={data!} onSubmit={handleSubmit} enableReinitialize={true}>
      {children}
    </Formik>
  );
};

export const _SettingsHospitalInfo: React.FC = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <HospitalInfoContainer>
        <HospitalInfoForm />
      </HospitalInfoContainer>
    </Grid>
  );
};

export const SettingsHospitalInfo = withSuspense(_SettingsHospitalInfo, null);
