import {Button, Grid, Paper, styled} from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import {MakerInspectionResultIndex} from '@modules/maker_inspection_results/types';

type Props = {
  makerInspectionResult: MakerInspectionResultIndex;
  onClickComplete: () => void;
  onAbort: () => void;
};

export const MakerInspectionHeader: React.FC<Props> = ({makerInspectionResult, onClickComplete, onAbort}) => {
  const today = dayjs().format('YYYY/MM/DD');
  return (
    <Paper elevation={2} square={true} style={{zIndex: 1}}>
      <Grid container justifyContent="space-between" style={{padding: 16}}>
        <Grid item>
          <div style={{marginBottom: 8}}>
            <Title>{today} メーカー保守点検</Title>
          </div>
          <Content>機器名： {makerInspectionResult.hospitalProduct.displayName}</Content>
          <Content>管理番号： {makerInspectionResult.hospitalProduct.managementId}</Content>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item style={{marginRight: 16}}>
              <Button color="primary" variant="outlined" onClick={onAbort}>
                中断
              </Button>
            </Grid>
            <Grid>
              <Button color="primary" variant="contained" onClick={onClickComplete}>
                完了
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Title = styled('span')({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '21px',
  color: '#172B4D',
});

const Content = styled('div')({
  fontSize: 14,
  lineHeight: '20px',
  color: '#172B4D',
});
