import React from 'react';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {Box, Theme, makeStyles} from '@material-ui/core';
import {Header} from './Header';
import {ResultDetail} from './ResultDetail';
import {ResultSummary} from './ResultSummary';

export const ViewInspectionResult: React.FC<ViewInspectionResultProps> = (props) => {
  const {inspection, inspectionTable, inspectionResult} = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Header />
      <ResultSummary inspectionResult={inspectionResult} inspection={inspection} />
      <ResultDetail inspection={inspection} inspectionResult={inspectionResult} inspectionTable={inspectionTable} />
    </Box>
  );
};

type ViewInspectionResultProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
