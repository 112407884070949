import React, {useMemo} from 'react';
import {Table} from '@molecules/Table';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {ProductStatus} from '@molecules/ProductStatus';
import {Column} from '@molecules/Table/props';
import {Dayjs} from 'dayjs';
import {getWaysOfPurchaseOptions} from '@Apps/ProductsList/pc/constants';
import {Pagination} from '@material-ui/lab';
import {hospitalProductPerPage} from '../hooks';
import {Grid} from '@material-ui/core';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // 0-based index
  page: number;
  totalCount: number;
  onPageChange: (newPage: number) => void;
};

export const HospitalProductTable: React.FC<Props> = ({totalCount, data, page, onPageChange}) => {
  const [layout] = useTableLayout('signupProductList');
  const totalPage = useMemo(() => Math.ceil(totalCount / hospitalProductPerPage), [totalCount]);

  const tableLayout = useMemo(() => {
    const l = (layout?.tableLayout ?? []).map<Column<TableLayout>>((item) => {
      const tableColumn: Column<TableLayout> = {...item, noBodyWrap: true, render: undefined};
      if (item.field === 'status') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableColumn.render = ({status}: any) => <ProductStatus productStatus={status} />;
      }
      if (item.field === 'waysOfPurchase') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableColumn.render = ({waysOfPurchase}: any) => getWaysOfPurchaseOptions(waysOfPurchase);
      }
      if (item.field === 'dateOfDisposal') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableColumn.render = ({dateOfDisposal}: any) => (dateOfDisposal as Dayjs)?.toString() ?? '';
      }
      if (item.field === 'dateOfPurchase') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableColumn.render = ({dateOfPurchase}: any) => (dateOfPurchase as Dayjs)?.format('YYYY/MM/DD') ?? '';
      }
      return tableColumn;
    });
    return l;
  }, [layout]);

  return (
    <>
      <Table
        data={data}
        columns={tableLayout}
        showSelection={false}
        stickyHeader
        paperProps={{style: {maxHeight: 'calc(100vh - 180px)'}}}
      />
      <Grid container justifyContent="center" style={{marginTop: 24}}>
        <Pagination
          page={page}
          count={totalPage}
          shape="rounded"
          color="primary"
          onChange={(_, newPage) => onPageChange(newPage)}
        />
      </Grid>
    </>
  );
};
