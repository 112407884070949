import React from 'react';
import {Card, CardContent, makeStyles} from '@material-ui/core';

type Props = {
  onClick: () => void;
};

export const DialogButton: React.FC<Props> = ({onClick, children}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} onClick={onClick} data-testid={'rental-error-dialog-button'}>
      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    borderRadius: '20px',
    boxShadow: `0px 8px 0px 0px ${theme.palette.rent.dark}`,
    backgroundColor: theme.palette.rent.main,
    height: 84,
    width: 256,
    margin: '0 auto',
  },
  content: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 21,
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
}));
