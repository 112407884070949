import {atom} from 'jotai';
import {selectAtom} from 'jotai/utils';
import {MakerInspectionResultStatus} from './types';

// constants
const LOCAL_STORAGE_KEY_MAKER_INSPECTION_TYPE_STATUS = 'hitotsu/maker_inspection_status';

const getMakerInspectionResultStatus = (): MakerInspectionResultStatus => {
  const inspectionTypeStatus: MakerInspectionResultStatus =
    (localStorage.getItem(LOCAL_STORAGE_KEY_MAKER_INSPECTION_TYPE_STATUS) as MakerInspectionResultStatus) ?? 'all';
  return inspectionTypeStatus;
};

const _makerInspectionResultStatusAtom = atom<MakerInspectionResultStatus>(getMakerInspectionResultStatus());

export const currentMakerInspectionResultStatusAtom = atom<MakerInspectionResultStatus, MakerInspectionResultStatus>(
  (get) => get(_makerInspectionResultStatusAtom),
  (_get, set, newStr) => {
    set(_makerInspectionResultStatusAtom, newStr);
    localStorage.setItem(LOCAL_STORAGE_KEY_MAKER_INSPECTION_TYPE_STATUS, newStr);
  }
);

export type SearchVariable = {
  name: string | null;
  page: number;
  perPage: number;
  order: string | null;
};

export const nameAtom = atom<string | null>(null);
export const pageAtom = atom<number>(0);
export const orderAtom = atom<string | null>(null);

export const searchVariablesAtom = atom((get) => {
  return {
    name: get(nameAtom),
    page: get(pageAtom),
    order: get(orderAtom),
    perPage: 20,
  };
});

export const pageSizeSelectAtom = selectAtom(searchVariablesAtom, ({perPage}) => perPage);
