import React from 'react';
import {HospitalProductFileList} from '@organisms/HospitalProductFileList';
import {styled} from '@material-ui/core/styles';

type FileContentProps = {
  hospitalProductHashId: string;
  elementHeight: number;
};
const PaddingDiv = styled('div')({
  padding: '16px',
});

/**
 * ファイルリストのラッパー
 */
export const FileContent = (props: FileContentProps) => {
  const {hospitalProductHashId, elementHeight} = props;
  return (
    <PaddingDiv>
      <HospitalProductFileList hospitalProductHashId={hospitalProductHashId} elementHeight={elementHeight} />
    </PaddingDiv>
  );
};
