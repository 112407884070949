import React, {useState, useCallback, useMemo} from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';
import {Search, Tune} from '@material-ui/icons';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {CategoryFormatter} from '@modules/categories/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSetAtom} from 'jotai';
import {selectedMakerAtom} from './jotai';
import {CompanyFormatter} from '@modules/companies/helpers';
import {FetchCompaniesParam, useFetchCompaniesQuery} from '@modules/companies/api';
import {useCategoryQuery, useIsDetailFilterActive} from './hooks';
import {useAtom} from 'jotai';
import {searchRootCategoriesAtom, searchSubCategoriesAtom} from './jotai';
import {useDebounceCallback} from '@front-libs/core';
import {CategoryIndex} from '@modules/categories/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flexGrow: 1,
    },
    searchNameContainer: {},
    searchName: {
      width: '270px',
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    actionMenus: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    filterBtnActive: {
      backgroundColor: theme.palette.primary.light,
      '&&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

type ToolBarProps = {
  searchName: string;
  onChangeSearchName: (name: string) => void;
  onClickDrawer: () => void;
};

export const ToolBar = ({searchName, onChangeSearchName, onClickDrawer}: ToolBarProps) => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const setSelectedMaker = useSetAtom(selectedMakerAtom);
  const [fetchCompaniesQueryParam, setFetchCompaniesQueryParam] = useState<FetchCompaniesParam>({});
  const {data: companies} = useFetchCompaniesQuery(myInfo.hospitalHashId, fetchCompaniesQueryParam);
  const {rootCategoryQuery, narrowCategoryQuery, selectRootCategory, descendantCategoriesQueries} = useCategoryQuery(
    myInfo.hospitalHashId
  );
  const [searchRootCategories, setSearchRootCategories] = useAtom(searchRootCategoriesAtom);
  const [searchSubCategories, setSearchSubCategories] = useAtom(searchSubCategoriesAtom);

  const isDetailFilterActive = useIsDetailFilterActive();

  const handleChangeRootCategories = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      selectRootCategory(values && values.length > 0 ? values.map((i) => i.value) : null);
      setSearchRootCategories(values ?? []);
    },
    300,
    [setSearchRootCategories]
  );

  const handleChangeSubCategories = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      setSearchSubCategories(values ?? []);
    },
    300,
    [setSearchSubCategories]
  );

  const descendantCategoryOptions = useMemo(() => {
    if (descendantCategoriesQueries.length > 0) {
      let catQuery: CategoryIndex[] = [];
      for (const descendantCategoriesQuery of descendantCategoriesQueries) {
        const filteredCategories = (descendantCategoriesQuery.data?.data ?? []).filter((item) => item.depth === 1);
        catQuery = [...catQuery, ...filteredCategories];
      }
      return CategoryFormatter.getOptions(catQuery);
    } else {
      return CategoryFormatter.getOptions(narrowCategoryQuery.data);
    }
  }, [descendantCategoriesQueries, narrowCategoryQuery.data]);

  const handleChangeMaker = useDebounceCallback(
    (values: SelectOptionProps[] | null) => {
      setSelectedMaker(values ?? []);
    },
    300,
    [setSelectedMaker]
  );

  const onInputChange = useCallback((inputValue: string) => {
    setFetchCompaniesQueryParam({name: inputValue === '' ? undefined : inputValue});
  }, []);

  return (
    <Grid item container justifyContent="space-between">
      <Grid item>
        <Grid container>
          <TextField
            className={classes.searchName}
            variant={'outlined'}
            fullWidth
            size={'small'}
            InputProps={{
              endAdornment: <Search />,
            }}
            InputLabelProps={{
              style: {
                fontSize: 14,
              },
            }}
            label={'機種名を検索'}
            defaultValue={searchName}
            onChange={(e) => onChangeSearchName(e.target.value)}
          />
          <PopperSelectBoxButton
            buttonLabel={'大分類'}
            options={CategoryFormatter.getOptions(rootCategoryQuery.data)}
            isMulti={true}
            onChange={handleChangeRootCategories}
            searchable={true}
            initialOption={searchRootCategories}
          />
          <PopperSelectBoxButton
            buttonLabel="小分類"
            options={descendantCategoryOptions}
            isMulti={true}
            onChange={handleChangeSubCategories}
            searchable={true}
            initialOption={searchSubCategories}
          />
          <PopperSelectBoxButton
            buttonLabel="メーカー"
            options={CompanyFormatter.getOptions(companies?.data ?? [])}
            isMulti={true}
            onChange={handleChangeMaker}
            searchable={true}
            onInputChange={onInputChange}
          />
          <Button
            color="inherit"
            onClick={onClickDrawer}
            className={isDetailFilterActive ? classes.filterBtnActive : undefined}>
            <Tune />
            <span className={classes.actionMenus}>詳細で絞り込む</span>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
