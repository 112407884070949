import React, {useState} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {Grid, makeStyles} from '@material-ui/core';
import {Selector} from '@molecules/Selector';
import {sortKey} from '../constants';

type SortFormProps = {
  orderColumn: string;
  direction: 'asc' | 'desc';
};

type SortDialogProps = {
  value: SortFormProps;
} & Omit<DialogProps, 'value' | 'onChange'>;

export const KanbanSortDialog = ({value, ...props}: SortDialogProps) => {
  const classes = useStyles();
  const [sortValue, setSortValue] = useState(value);
  return (
    <SimpleDialog
      {...props}
      title={'並べ替え'}
      actions={{
        resolve: async (res) => {
          props.actions.resolve(sortValue);
          return res;
        },
        reject: async (res) => {
          props.actions.reject(res);
          return res;
        },
      }}>
      <Grid container className={classes.fields} direction="column">
        <div style={{width: '100%', marginBottom: '16px'}}>
          <label>並べ替え条件</label>
          <Selector
            name="sortColumn"
            size={'small'}
            options={sortKey}
            value={sortValue.orderColumn}
            onChange={(orderColumn: {label: string; value: string}) =>
              setSortValue({...sortValue, orderColumn: orderColumn.value})
            }
          />
        </div>
        <div style={{width: '100%', marginBottom: '16px'}}>
          <label>表示順序</label>
          <Selector
            name="direction"
            size={'small'}
            options={[
              {label: '昇順', value: 'asc'},
              {label: '降順', value: 'desc'},
            ]}
            value={sortValue.direction}
            onChange={(direction: {label: string; value: 'asc' | 'desc'}) =>
              setSortValue({...sortValue, direction: direction.value})
            }
          />
        </div>
      </Grid>
    </SimpleDialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    padding: '24px 48px 24px',
  },
  divider: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
  },
  content: {
    padding: '24px 48px 0px',
  },
  fields: {
    '& > div': {
      marginTop: '16px',
    },
  },
  fileInput: {
    visibility: 'hidden',
  },
  inputName: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '0px 48px 24px',
  },
}));
