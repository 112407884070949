import React, {useCallback} from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField, Typography} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';
import {NumericInspectionItem} from '@modules/inspections/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
    inputContainer: {
      width: '200px',
    },
    unit: {
      marginLeft: '24px',
    },
  })
);

const StyledTextField = styled(TextField)({
  '& input[type="number"]::-webkit-inner-spin-button': {
    position: 'relative',
    left: '6px',
  },
  '& input[type="number"]': {
    paddingRight: '6px',
  },
});

type NumericFieldProps = {
  value: string;
  unit: string;
  error?: React.ReactNode;
  isEditable: boolean;
  showsError?: boolean;
  onChange?: (value: string) => void;
  validator: NumericInspectionItem['validator'] | null;
};

export const NumericField: React.VFC<NumericFieldProps> = (props) => {
  const {value, unit, error, isEditable, showsError = false, onChange} = props;
  const classes = useStyles();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isEditable === true && onChange) {
        onChange(e.target.value);
      }
    },
    [onChange, isEditable]
  );

  return (
    <Grid container direction="column">
      <Grid className={classes.field}>
        <StyledTextField
          className={classes.inputContainer}
          type="number"
          variant="standard"
          placeholder="数値"
          error={showsError === true && error !== undefined}
          value={value}
          onChange={handleChange}
          inputProps={{style: {textAlign: 'right'}}}
          InputProps={{
            readOnly: !isEditable,
          }}
        />
        <Typography className={classes.unit} variant="inherit">
          {unit}
        </Typography>
      </Grid>
      {error}
    </Grid>
  );
};
