import React, {useCallback, useMemo} from 'react';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {SelectedViewType} from '../types';

const menuItemList: MenuItemType[] = [
  {
    label: '機器関連コスト推移（費目別）',
    value: 'costCategory',
  },
  {
    label: '機器関連コスト推移（大分類別）',
    value: 'rootCategory',
  },
  {
    label: '機器関連コスト推移（小分類別）',
    value: 'narrowCategory',
  },
];

export type ContentTitleProps = {
  currentSelectedViewType: string;
  onChangeContent: (value: SelectedViewType) => void;
};

export const ContentTitle: React.FC<ContentTitleProps> = ({currentSelectedViewType, onChangeContent}) => {
  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      onChangeContent(item.value as SelectedViewType);
    },
    [onChangeContent]
  );
  const currentTitle = useMemo(
    () => menuItemList.find((item) => item.value === currentSelectedViewType)?.label ?? '',
    [currentSelectedViewType]
  );
  return (
    <PopperMenuButton
      menuItemList={menuItemList}
      onMenuClick={handleMenuClick}
      buttonProps={{color: 'primary', variant: 'text', style: {fontWeight: 700}}}>
      {currentTitle}
    </PopperMenuButton>
  );
};
