export type PapersType =
  | 'notice_board'
  | 'rental_history'
  | 'inspection_list'
  | 'rentables'
  | 'rental_ratio'
  | 'monthly_inspection_percent'
  | 'yearly_inspection_percent'
  | 'inspection_percent_by_product'
  | 'inspection_calendar'
  | 'product_comment_list'
  | 'non_returned_rental_products_table'
  | 'repair_comment_list';

export type SettingMenuItemType = 'changeLayout';

export type PapersObjType = {
  [key: string]: {
    label: string;
    value: PapersType;
    component?: JSX.Element;
    description?: string;
    settingMenuItems?: SettingMenuItemType[];
  };
};

export const SettingMenuItemTypesToLabel: {[key in SettingMenuItemType]: string} = {
  changeLayout: '表示する列を変更',
};

export type DashboardLayoutVisibility = {
  noticeBoard:
    | 'rental_history'
    | 'inspection_list'
    | 'rentables'
    | 'rental_ratio'
    | 'monthly_inspection_percent'
    | 'yearly_inspection_percent'
    | 'inspection_percent_by_product'
    | 'inspection_calendar'
    | 'product_comment_list'
    | 'non_returned_rental_products_table'
    | 'repair_comment_list';
};
