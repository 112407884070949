import {makeStyles} from '@material-ui/core';
import React, {useCallback, useState} from 'react';

export type BarcodeReaderProps = {
  forcedFocus: boolean;
  onInputData: (barcode: string) => void;
};

export const useBarcodeReader = ({forcedFocus, onInputData}: BarcodeReaderProps) => {
  const classes = useStyles();
  const [inputData, setInputData] = useState('');
  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Enter以外のキーを検知したらinputDataに連結する
      if (event.key !== 'Enter') {
        setInputData(inputData + event.key);
        return;
      } else if (event.key === 'Enter' && inputData.length === 0) {
        return;
      }

      setInputData('');
      onInputData(inputData);
    },
    [inputData, onInputData]
  );
  const focusInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (forcedFocus) e.target.focus();
    },
    [forcedFocus]
  );

  const BarcodeInput = (
    <input
      type="text"
      autoFocus
      readOnly
      onBlur={focusInput}
      value={inputData}
      onKeyPress={handleBarcodeReaderInput}
      className={classes.barcodeInput}
    />
  );

  return {
    BarcodeInput,
  };
};

const useStyles = makeStyles(() => ({
  barcodeInput: {
    position: 'absolute',
    top: 20,
    marginTop: '-550px',
    '@media screen and (orientation: portrait)': {
      marginTop: '0px',
      marginLeft: '-5500px',
    },
  },
}));
