import React, {useCallback, useState} from 'react';
import {Tab, Tabs, styled} from '@material-ui/core';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {InspectionTab} from '@organisms/InspectionTab';
import {RepairTab} from '@organisms/RepairTab';
import {useFetchHospitalProductFaultRepairHistoriesQuery} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useQuery} from 'react-query';
import {getInspectionResults} from '@modules/inspection_results/api';

type Props = {
  hospitalProduct: HospitalProductIndex;
};

/**
 * 機器詳細右側の点検・修理予定タブ
 */
export const InspectionAndRepairTab = ({hospitalProduct}: Props) => {
  const [tab, setTab] = useState('inspection');
  const {myInfo} = useMyInfo();
  const faultQuery = useFetchHospitalProductFaultRepairHistoriesQuery(myInfo.hospitalHashId, hospitalProduct.hashId);
  const query = useQuery(['hospitalProx', myInfo.hospitalHashId], async () => {
    if (!myInfo.hospitalHashId) return {totalCount: 0, page: 1, data: []};
    return await getInspectionResults(myInfo.hospitalHashId, 'hoge', {
      hospitalProductHashId: hospitalProduct.hashId,
      statuses: 'unplanned,uncompleted',
      order: 'scheduledTime',
    });
  });

  const tabs = [
    {
      label: '点検予定',
      count: query.data?.totalCount ?? 0,
      value: 'inspection',
    },
    {
      label: '修理予定',
      count: faultQuery.data?.totalCount ?? 0,
      value: 'repair',
    },
  ];

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key);
    },
    [setTab]
  );

  return (
    <div>
      <Tabs
        value={tab}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#172B4D'
          }
        }}
        onChange={handleChangeTab}
        style={{borderBottom: 'solid 1px #F0F2F5'}}
      >
        {tabs.map((item, index) => (
          <Tab label={
            <div>
              <span style={item.value === tab ? {color: '#172B4D', fontWeight: '600'}:{}}>{item.label}</span>
              {item.count > 0 && (<StyledBadge>{item.count}</StyledBadge>)}
            </div>
          } value={item.value} key={index} />
        ))}
      </Tabs>
      {tab === 'inspection' && (
        <InspectionTab
        // eslint-disable-next-line
        wholeProductHashId={hospitalProduct?.wholeProductHashId!}
        hospitalProductHashId={hospitalProduct.hashId}
        query={query} />
      )}
      {tab === 'repair' && (
        <RepairTab
          hospitalProductHashId={hospitalProduct.hashId}
          faultQuery={faultQuery} />
      )}
    </div>
  );
};

const StyledBadge = styled('span')({
  backgroundColor: '#172B4D',
  color: 'white',
  marginLeft: '8px',
  padding: '1px 6px',
  borderRadius: '16px'
});

