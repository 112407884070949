import React, {useCallback} from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {Theme} from '@material-ui/core';
import {styled} from '@material-ui/styles';

/** SEE:他の箇所でも使うのであればPropsに含める */
const pageSizeMenuItems = [
  {
    label: '20件',
    value: 20,
  },
  {
    label: '50件',
    value: 50,
  },
  {
    label: '100件',
    value: 100,
  },
];

const PageSizeSpan = styled('span')(({theme}: {theme: Theme}) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

type props = {
  pageSize: number;
  update: (selectNum: number) => void;
};

/**
 *
 * 件数選択メニュー
 * @param pageSize - 表示件数
 * @param update -件数選択時に選択した数値を返すコールバック
 * @returns
 */
export const DisplayNumberSelect = ({pageSize, update}: props) => {
  const handleChangeRowsPerPage = useCallback(
    (item: MenuItemType) => {
      update(Number(item.value));
    },
    [update]
  );

  return (
    <PopperMenuButton
      placement="bottom"
      hiddenArrow={true}
      buttonProps={{variant: 'text'}}
      menuItemList={pageSizeMenuItems}
      onMenuClick={handleChangeRowsPerPage}>
      <PageSizeSpan>{pageSize}件</PageSizeSpan>
      <ArrowDropDownIcon />
    </PopperMenuButton>
  );
};
