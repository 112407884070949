import {styled, Typography} from '@material-ui/core';
import React from 'react';

export const PageHeader: React.FC = ({children}) => {
  return <StyledHeader variant="h5">{children}</StyledHeader>;
};

export const BodyText: React.FC = ({children}) => {
  return <StyledBody variant="body1">{children}</StyledBody>;
};

export const SectionHeader = styled(Typography)(({theme}) => ({
  color: theme.palette.common.black,
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 16,
}));

const StyledHeader = styled(Typography)(({theme}) => ({
  color: theme.palette.common.black,
  marginBottom: 24,
}));

const StyledBody = styled(Typography)(({theme}) => ({
  color: theme.palette.common.black,
  fontSize: 14,
}));
