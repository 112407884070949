import React from 'react';
import {useBackPrevious} from '@front-libs/core';
import {ChevronLeft} from '@material-ui/icons';
import {NavigationArea, NavigationButtonContainer, NavigationButton} from '@Apps/ProductDetail/styled';

/**
 * 画面上部のナビゲーション(機器一覧へ戻る、前の機器、次の機器)
 */
export const NavigationContainer = () => {
  const backPrevious = useBackPrevious('/products');
  const handleClickProductsLink = () => {
    backPrevious();
  };
  return (
    <NavigationArea>
      <NavigationButtonContainer>
        <NavigationButton onClick={handleClickProductsLink}>
          <ChevronLeft />
          機器一覧
        </NavigationButton>
      </NavigationButtonContainer>
      {/* 実装時期未定のため、コメントアウト */}
      {/* <NavigationButtonContainer>
        <NavigationButton onClick={handleClickProductsLink}>
          <ChevronLeft />
          前の機器
        </NavigationButton>
        <NavigationButton onClick={handleClickProductsLink}>
          次の機器
          <ChevronRight />
        </NavigationButton>
      </NavigationButtonContainer> */}
    </NavigationArea>
  );
};
