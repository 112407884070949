import {makeStyles} from '@material-ui/core';
import React from 'react';

export const PageHeader: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>ファイル一覧</div>
      <div className={classes.subtitle}>HITOTSUにアップロードされたファイルの一覧を表示します。</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 24,
    marginBottom: 16,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 14,
  },
}));
