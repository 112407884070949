import {Button, Grid, styled, Typography} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import React from 'react';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';

type PageHeaderProps = {
  onRegisterPlan: VoidFunction;
  onExportPlan: VoidFunction;
  isReadOnly: boolean;
};

export const PageHeader: React.FC<PageHeaderProps> = ({isReadOnly, onRegisterPlan, onExportPlan}) => {
  return (
    <Grid container>
      <Grid item>
        <PageRoutingMenu>
          <PageTitle variant={'h5'}>研修計画マスタ</PageTitle>
          <ArrowDropDown />
        </PageRoutingMenu>
      </Grid>
      <Grow />
      <Grid item>
        <Button variant={'outlined'} color={'primary'} onClick={onExportPlan} style={{marginRight: 8}}>
          研修計画マスタを出力
        </Button>
        {!isReadOnly && (
          <Button variant={'contained'} color={'primary'} onClick={onRegisterPlan}>
            計画を新規登録
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const PageTitle = styled(Typography)(({theme}) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.primary.dark,
}));

const Grow = styled('div')({
  flexGrow: 1,
});
