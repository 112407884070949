import React from 'react';
import {useAtom} from 'jotai';
import {createStyles, Divider, Grid, makeStyles, Tabs, Tab, Theme, Typography} from '@material-ui/core';
import {Sidebar} from '@components/organisms/Sidebar';
import {withSuspense} from '@front-libs/core';
import AppBar from '@material-ui/core/AppBar';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {HospitalWards} from './HospitalWards';
import {HospitalRooms} from './HospitalRooms';
import {useNavigate} from 'react-router-dom';
import {tabAtom} from '@Apps/Settings/Place/state';

interface TabPanelProps {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  index: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classStyle: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, classStyle, ...other} = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classStyle}
      {...other}>
      {value === index && children}
    </span>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%',
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    tableContainer: {
      marginBottom: '24px',
    },
    appBar: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px',
    },
  })
);

const _SettingsHospitalPlace: React.VFC = () => {
  const templateClasses = useSettingsContentTemplate();
  const classes = useStyles();
  const navigate = useNavigate();

  const [value, setValue] = useAtom(tabAtom);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (
    event: React.ChangeEvent<{
      // empty param
    }>,
    newValue: number
  ) => {
    setValue(newValue);
    navigate({search: ''}, {replace: true});
  };

  return (
    <Grid container className={templateClasses.grid}>
      <div className={templateClasses.form}>
        <Grid container className={templateClasses.grid}>
          <Grid item className={templateClasses.sideBar}>
            <Sidebar />
          </Grid>
          <Grid item className={templateClasses.content}>
            <Grid container style={{marginBottom: '32px'}}>
              <Grid item>
                <Typography variant={'h5'} className={templateClasses.pageTitle}>
                  建物・場所
                </Typography>
              </Grid>
              <div className={templateClasses.flex} />
            </Grid>
            <p>建物・場所を大エリア・小エリアとして、ユーザーの共通設定を管理します。</p>
            <AppBar position="static" className={classes.appBar}>
              <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="simple tabs">
                <Tab label="大エリア" {...a11yProps(0)} />
                <Tab label="小エリア" {...a11yProps(1)} />
              </Tabs>
              <Divider variant="middle" style={{width: '98%', marginBottom: '16px'}} />
            </AppBar>
            <TabPanel value={value} index={0} classStyle={classes.table}>
              <HospitalWards />
            </TabPanel>
            <TabPanel value={value} index={1} classStyle={classes.table}>
              <HospitalRooms />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export const SettingsHospitalPlace = withSuspense(_SettingsHospitalPlace, null);
