import React from 'react';
import {Navigate} from 'react-router-dom';
import {BaseProductManager} from '@Apps/BaseProductManger';
import {ProductsList} from '@Apps/ProductsList/pc';
import {Step2} from '@Apps/ProductsList/pc/ProductsRegistrationDialog/Step2/Step2';
import {ProductDetail} from '@Apps/ProductDetail';
import {InspectionSetting} from '@Apps/InspectionSetting';
import {RentalList} from '@Apps/RentalList';
import {BaseSharedMenu} from '@Apps/BaseSharedMenu';
import {RentOrReturn} from '@Apps/BaseSharedMenu/RentOrReturn';
import {LenderSelection} from '@Apps/BaseSharedMenu/LenderSelection';
import {WardSelection} from '@Apps/BaseSharedMenu/WardSelection';
import {FloorSelection} from '@Apps/BaseSharedMenu/FloorSelection';
import {RoomSelection} from '@Apps/BaseSharedMenu/RoomSelection';
import {RentByBarcodeReader} from '@Apps/BaseSharedMenu/RentDevices/RentByBarcodeReader';
import {ConfirmScanRentDevice} from '@Apps/BaseSharedMenu/RentDevices/RentByCamera/ConfirmScan';
import {ScanRentDeviceBarcode} from '@Apps/BaseSharedMenu/RentDevices/RentByCamera/ScanBarcode';
import {ConfirmCheckoutRentDevice} from '@Apps/BaseSharedMenu/RentDevices/RentByCamera/ConfirmCheckout';
import {ReturnByBarcodeReader} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByBarcodeReader';
import {ConfirmScanReturnDevice} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByCamera/ConfirmScan';
import {ScanReturnDeviceBarcode} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByCamera/ScanBarcode';
import {ConfirmReturn} from '@Apps/BaseSharedMenu/ReturnDevices/ReturnByCamera/ConfirmReturn';
import {Complete} from '@Apps/BaseSharedMenu/Complete';
import {CardsPositionSetting} from '@Apps/BaseSharedMenu/CardsPositionSetting';

import {Dashboard} from '@Apps/Dashboard';
import {Channel} from '@Apps/Channel';
import {SettingsHospitalDocument} from '@Apps/Settings/Document';
import {SettingsHospitalRental} from '@Apps/Settings/Rental';
import {SettingsHospitalInspection} from '@Apps/Settings/Inspection';
import {SettingsHospitalInfo} from '@Apps/Settings/Hospital';
import {SettingsHospitalPlace} from '@Apps/Settings/Place';
import {SettingsHospitalUser} from '@Apps/Settings/User';
import {AccountProfile} from '@Apps/Account/Profile';
import {AccountSecurity} from '@Apps/Account/Security';
import {AccountNotificationSetting} from '@Apps/Account/NotificationSetting';
import {AccountStatus} from '@Apps/Account/Status';
import {Inspection} from '@Apps/Inspection';
import {InspectionResult} from '@Apps/InspectionResult/pc';
import {InspectionResultsList} from '@Apps/InspectionResultList/pc';
import {RepairList} from '@Apps/RepairList';
import {RepairDetail} from '@Apps/RepairDetail';
import {UserSignUp} from './Apps/UserSignUp';
import {HospitalSignUp} from './Apps/HospitalSignUp';
import {ProductRegistration} from '@Apps/ProductRegistration';
import {ProductImport} from './Apps/ProductImport';
import {TrainingManagementPlan} from '@Apps/TrainingManagementPlans';
import {TrainingManagementSchedules} from './Apps/TrainingManagementSchedules';
import {MakerInspectionResult} from './Apps/MakerInspectionResult';
import {TrainingManagementScheduleDetail} from '@Apps/TrainingManagementScheduleDetail';
import {TrainingManagementReports} from './Apps/TrainingManagementReports';
import {InspectionTemplates} from '@Apps/InspectionTemplates';
import {GeneralFileList} from './Apps/GeneralFileList';
import {ManagementDashboard} from './Apps/ManagementDashboard';
import {RentalRatio} from './Apps/ManagementDashboard/RentalRatio';
import {CostManagement} from './Apps/ManagementDashboard/CostManagement';
import {FaultRatio} from './Apps/ManagementDashboard/FaultRatio';
import {SettingsHospitalRepair} from '@Apps/Settings/Repair';
import {InspectionProductSetting} from '@Apps/InspectionSetting/InspectionProduct';
import {InspectionSettingStartDate} from '@Apps/InspectionSetting/InspectionStartDate';
import {InspectionPreview} from '@Apps/InspectionSetting/InspectionStartDate/InspectionPreview';
import {SettingsManagementDepartment} from '@Apps/Settings/Management';
import {SettingsDealer} from '@Apps/Settings/Dealer/index';

export const rentalRoutes = {
  path: '/shared',
  element: <BaseSharedMenu />,
  children: [
    {
      key: 'rentOrReturn',
      exact: true,
      path: '',
      element: <RentOrReturn />,
    },
    {
      key: 'lenderSelection',
      exact: true,
      path: '/shared/lenderSelection',
      element: <LenderSelection />,
    },
    {
      key: 'wardSelection',
      exact: true,
      path: '/shared/rental/wardSelection',
      element: <WardSelection />,
    },
    {
      key: 'floorSelection',
      exact: true,
      path: '/shared/rental/floorSelection',
      element: <FloorSelection />,
    },
    {
      key: 'roomSelection',
      exact: true,
      path: '/shared/rental/roomSelection',
      element: <RoomSelection />,
    },
    {
      key: 'rentProductByBarcodeReader',
      exact: true,
      path: '/shared/rental/product/barcode_reader',
      element: <RentByBarcodeReader />,
    },
    {
      key: 'confirmScanByCamera',
      exact: true,
      path: '/shared/rental/product/camera',
      element: <ConfirmScanRentDevice />,
    },
    {
      key: 'scanBarcodeByCamera',
      exact: true,
      path: '/shared/rental/product/camera/scan',
      element: <ScanRentDeviceBarcode />,
    },
    {
      key: 'confirmCheckout',
      exact: true,
      path: '/shared/rental/product/camera/checkout',
      element: <ConfirmCheckoutRentDevice />,
    },
    {
      key: 'returnProductByBarcodeReader',
      exact: true,
      path: '/shared/return/product/barcode_reader',
      element: <ReturnByBarcodeReader />,
    },
    {
      key: 'confirmScanReturnDevice',
      exact: true,
      path: '/shared/return/product/camera',
      element: <ConfirmScanReturnDevice />,
    },
    {
      key: 'scanReturnDeviceBarcode',
      exact: true,
      path: '/shared/return/product/camera/scan',
      element: <ScanReturnDeviceBarcode />,
    },
    {
      key: 'confirmReturn',
      exact: true,
      path: '/shared/return/product/camera/checkout',
      element: <ConfirmReturn />,
    },
    {
      key: 'complete',
      exact: true,
      path: '/shared/complete',
      element: <Complete />,
    },
    {
      key: 'cardsPositionSetting',
      exact: true,
      path: '/shared/cards_position_setting',
      element: <CardsPositionSetting />,
    },
  ],
};

export const nonHeaderRoutes = {
  path: '/',
  children: [
    {
      key: 'inspectionDetail',
      path: '/inspections/:hashId',
      element: <Inspection />,
    },
    {
      key: 'inspectionPreview',
      path: '/inspectionPreview/:hashId',
      element: <InspectionPreview />,
    },
    {
      key: 'productRegistration',
      path: '/products/registration',
      element: <ProductRegistration />,
    },
  ],
};

export const mainRoutes = {
  path: '/',
  element: <BaseProductManager />,
  children: [
    {
      key: 'hospitalSignUp',
      path: '/signup/hospital',
      element: <HospitalSignUp />,
    },
    {
      key: 'userSignUp',
      path: '/signup/user',
      element: <UserSignUp />,
    },
    {
      key: 'home',
      path: '/',
      element: <Navigate to="/dashboard" />,
    },
    {
      key: 'dashboard',
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      key: 'channel',
      path: '/channels',
      element: <Channel />,
    },
    {
      key: 'products',
      path: '/products',
      element: <ProductsList />,
    },
    {
      key: 'productDetail',
      path: '/products/:hashId',
      element: <ProductDetail />,
    },
    {
      key: 'productsRegistration',
      path: '/products/registration/Step2/:hashId',
      element: <Step2 />,
    },
    {
      key: 'productsImport',
      path: '/products/registration/import',
      element: <ProductImport />,
    },
    {
      key: 'inspectionSetting',
      path: '/inspection/setting',
      element: <InspectionSetting />,
    },
    {
      key: 'inspectionProductSetting',
      path: '/inspection/setting/products',
      element: <InspectionProductSetting />,
    },
    {
      key: 'inspectionSettingStartDate',
      path: '/inspection/setting/start_date',
      element: <InspectionSettingStartDate />,
    },
    {
      key: 'inspectionResult',
      path: '/inspections/:inspectionHashId/result/:inspectionResultHashId',
      element: <InspectionResult />,
    },
    {
      key: 'inspectionResults',
      path: '/inspection/results',
      element: <InspectionResultsList />,
    },
    {
      key: 'inspectionTemplates',
      path: '/inspection/templates',
      element: <InspectionTemplates />,
    },
    {
      key: 'makerInspectionResult',
      path: '/maker_inspections/results/:hashId',
      element: <MakerInspectionResult />,
    },
    {
      key: 'rentals',
      path: '/rentals',
      element: <RentalList />,
    },
    {
      key: 'repairs',
      path: '/repairs',
      element: <RepairList />,
    },
    {
      key: 'repairDetail',
      path: '/repairs/:hashId',
      element: <RepairDetail />,
    },
    {
      key: 'settingsHospitalDocument',
      path: '/settings/document',
      element: <SettingsHospitalDocument />,
    },
    {
      key: 'settingsHospitalRental',
      path: '/settings/rental',
      element: <SettingsHospitalRental />,
    },
    {
      key: 'settingsHospitalInspection',
      path: '/settings/inspection',
      element: <SettingsHospitalInspection />,
    },
    {
      key: 'settingsHospitalRepair',
      path: '/settings/repair',
      element: <SettingsHospitalRepair />,
    },
    {
      key: 'settingsHospitalInfo',
      path: '/settings/hospital',
      element: <SettingsHospitalInfo />,
    },
    {
      key: 'settingsHospitalPlace',
      path: '/settings/place',
      element: <SettingsHospitalPlace />,
    },
    {
      key: 'settingsHospitalUser',
      path: '/settings/user',
      element: <SettingsHospitalUser />,
    },
    {
      key: 'settingsManagementDepartment',
      path: '/settings/management',
      element: <SettingsManagementDepartment />,
    },
    {
      key: 'settingsHospitalDealer',
      path: '/settings/dealer',
      element: <SettingsDealer />,
    },
    {
      key: 'accountProfile',
      path: '/account/profile',
      element: <AccountProfile />,
    },
    {
      key: 'accountSecurity',
      path: '/account/security',
      element: <AccountSecurity />,
    },
    {
      key: 'accountNotificationSetting',
      path: '/account/notification',
      element: <AccountNotificationSetting />,
    },
    {
      key: 'accountStatus',
      path: '/account/status',
      element: <AccountStatus />,
    },
    {
      key: 'trainingManagementPlan',
      path: '/training_managements/plans',
      element: <TrainingManagementPlan />,
    },
    {
      key: 'trainingManagementSchedule',
      path: '/training_managements/schedules',
      element: <TrainingManagementSchedules />,
    },
    {
      key: 'trainingManagementScheduleDetail',
      path: '/training_managements/schedules/:hashId',
      element: <TrainingManagementScheduleDetail />,
    },
    {
      key: 'trainingManagementReports',
      path: '/training_managements/reports',
      element: <TrainingManagementReports />,
    },
    {
      key: 'generalFileList',
      path: '/general_menu/file_list',
      element: <GeneralFileList />,
    },
    {
      key: 'managementDashboard',
      path: '/general_menu/management_dashboard',
      element: <ManagementDashboard />,
      children: [
        {
          key: 'costManagement',
          exact: true,
          path: '/general_menu/management_dashboard/cost_management',
          element: <CostManagement />,
        },
        {
          key: 'rentalRatio',
          exact: true,
          path: '/general_menu/management_dashboard/rental_ratio',
          element: <RentalRatio />,
        },
        {
          key: 'faultRatio',
          exact: true,
          path: '/general_menu/management_dashboard/repair_ratio',
          element: <FaultRatio />,
        },
      ],
    },
  ],
};
