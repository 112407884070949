import {Paper} from '@material-ui/core';
import React from 'react';
import {Footer} from './Footer';
import {Header} from './Header';
import {ProductCardList} from './ProductCardList';

export const ProductRegistration = () => {
  return (
    <Paper>
      <Header />
      <ProductCardList />
      <Footer />
    </Paper>
  );
};
