import React, {useEffect, useMemo} from 'react';
import {useCategoryQuery} from '@modules/categories/hooks/useCategories';
import {CategoryFormatter} from '@modules/categories/helpers';
import {isNullish} from '@front-libs/helpers';
import {Autocomplete} from '@material-ui/lab';
import {TextField, Theme, makeStyles} from '@material-ui/core';
import {fieldToTextField} from '@molecules/Formik/fields/StandardTextField';
import {CategoryIndex} from '@modules/categories/types';
import {useFormikContext} from 'formik';
import {HospitalProductDetail} from '@modules/hospital_products/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CategorySelect: React.FC = (props: any) => {
  const {setFieldValue, values} = useFormikContext<HospitalProductDetail>();

  const {label} = props;
  const {name} = fieldToTextField(props);

  // isSharedの場合はフォームをdisabledにする
  const disabled = useMemo(() => values.isShared, [values.isShared]);

  const isRootCategory = name === 'rootCategory';
  const classes = useStyles();

  const {rootCategoryQuery, subCategoryQuery, descendantCategoriesQueries, selectRootCategory} = useCategoryQuery();

  const isLoading = useMemo(
    () => rootCategoryQuery.isLoading || subCategoryQuery.isLoading || descendantCategoriesQueries.isLoading,
    [descendantCategoriesQueries.isLoading, rootCategoryQuery.isLoading, subCategoryQuery.isLoading]
  );

  const filedValue = useMemo(() => {
    if (isLoading) return {label: '', value: ''};

    const depth = isRootCategory ? 0 : 1;
    const catValue = values.categories?.find((category: CategoryIndex) => category.depth === depth);

    if (isNullish(catValue)) return {label: '', value: ''};

    return {label: catValue.name, value: catValue.hashId};
  }, [isLoading, isRootCategory, values.categories]);

  // 大分類が変化した際に、小分類のリストを変更する
  useEffect(() => {
    if (isNullish(values.categories)) return;
    const rootCategory = CategoryFormatter.getRootCategory(values.categories);
    if (isNullish(rootCategory)) return;

    selectRootCategory(rootCategory.hashId);
  }, [selectRootCategory, values.categories]);

  const options = useMemo(() => {
    const tmpOptions = isRootCategory
      ? CategoryFormatter.getOptions(rootCategoryQuery?.data ?? [])
      : CategoryFormatter.getOptions(descendantCategoriesQueries?.data ?? []);

    // フォームが再計算されているが、QueryがFetchされていない場合に値が入らないことを暫定的に回避
    if (tmpOptions.length === 0 && !isNullish(values.categories)) {
      const categoryValue = isRootCategory
        ? CategoryFormatter.getRootCategory(values.categories)
        : CategoryFormatter.getNarrowCategory(values.categories);
      if (!isNullish(categoryValue)) {
        tmpOptions.push({label: categoryValue.name, value: categoryValue.hashId});
      }
    }

    return tmpOptions;
  }, [descendantCategoriesQueries?.data, isRootCategory, rootCategoryQuery?.data, values.categories]);

  return isLoading ? null : (
    <Autocomplete
      disableClearable
      fullWidth
      disabled={disabled}
      options={options}
      getOptionSelected={(option, val) => option.value === val?.value}
      getOptionLabel={(option) => option.label}
      loading={isLoading}
      value={filedValue}
      classes={{
        option: classes.option,
      }}
      renderInput={(inputProps) => {
        return (
          <TextField
            variant={'standard'}
            {...inputProps}
            label={label}
            InputLabelProps={{
              ...inputProps.InputLabelProps,
              classes: {
                root: classes.inputText,
                shrink: classes.shrink,
              },
            }}
            InputProps={{
              ...inputProps.InputProps,
              classes: {
                root: classes.inputRoot,
                input: classes.inputText,
                underline: classes.underline,
              },
              readOnly: props.readOnly,
            }}
          />
        );
      }}
      onChange={(e, value) => {
        let newCategories = [];
        if (isRootCategory) {
          newCategories = [rootCategoryQuery.data.find((item) => item.hashId === value?.value)];
        } else {
          newCategories = [values.categories[0], subCategoryQuery.data.find((item) => item.hashId === value?.value)];
        }
        setFieldValue('categories', newCategories);
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  underline: {
    '&&&:before': {
      borderBottom: '0px solid',
    },
    '&&:after': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  option: {
    fontSize: theme.typography.fontSize,
  },
}));
