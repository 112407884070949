import {axios, BaseListResult} from '@front-libs/core';
import {PlanStatus, TrainingTypeValue, TrainingPlan, PlanType} from './types';

export type CreateTrainingPlanParam = {
  name: string;
  planType: PlanType;
  trainingTypes: TrainingTypeValue[];
  scheduleTimes?: number;
  isAdhoc?: boolean;
};

export type FetchTrainingPlansParam = {
  name?: string;
  status?: PlanStatus;
  trainingTypes?: TrainingTypeValue[];
  page?: number;
  perPage?: number;
  order?: string;
};

export type FetchTrainingPlansResult = BaseListResult<TrainingPlan>;

export type UpdateTrainingPlanParam = {
  hashId: string;
  name?: string;
  planType?: PlanType;
  trainingTypes?: TrainingTypeValue[];
  scheduleTimes?: number;
  isAdhoc?: boolean;
};

export const createTrainingPlan = async (hospitalHashId: string, data: CreateTrainingPlanParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/training_managements/plans`;
  return await axios.post<TrainingPlan>(baseURL, data);
};

export const getTrainingPlans = async (hospitalHashId: string, params: FetchTrainingPlansParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/training_managements/plans`;
  const {data} = await axios.get<FetchTrainingPlansResult>(baseURL, {
    params: {
      ...params,
      trainingTypes: params.trainingTypes?.join(','), // 配列をカンマ区切りのstringに変換
    },
  });
  return data;
};

export const updateTrainingPlan = async (hospitalHashId: string, data: UpdateTrainingPlanParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/training_managements/plans/${
    data.hashId
  }`;
  return await axios.put<TrainingPlan>(baseURL, data);
};

export const requestExportTrainingPlan = async (hospitalHashId: string) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/training_managements/plans/export`;
  return await axios.post(baseURL);
};
