import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '400px',
      minHeight: '65px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1',
      alignItems: 'center',
      display: 'flex',
      color: '#2A96E8',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    tableContainer: {
      marginBottom: '24px',
    },
    appBar: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px',
    },
    actionContainer: {
      fontSize: theme.typography.fontSize,
    },
    action: {
      background: 'rgba(9, 30, 66, 0.04)',
      marginRight: '8px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      visibility: 'hidden',
    },
    touchableActions: {
      visibility: 'visible',
    },
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  })
);
