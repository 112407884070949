import React, {useLayoutEffect} from 'react';
import {Dialog} from '@atoms/Dialog/Dialog';
import {DialogProps as DialogHandlerProps} from '@components/molecules/Dialogs/DialogHandler';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import {styled} from '@material-ui/core/styles';
import {TransitionProps} from '@material-ui/core/transitions';
import {SearchText} from './searchText';
import {useAtom, useSetAtom} from 'jotai';
import {filterCheckListAtom, searchTextChangeAtom} from '@Apps/ProductsList/sp/jotai';
import {HomeIndicatorImage} from '@components/molecules/MobileUnderMenu/HomeIndicatorImage';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<typeof Dialog, typeof Dialog>},
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledContainerDiv = styled('div')({
  padding: '16px',
});
const StyledFlexDiv = styled('div')({
  display: 'flex',
});

const PaperCss: React.CSSProperties = {
  width: '100%',
  position: 'fixed',
  bottom: '-30px',
};
export const StyledCompleteButton = styled(Button)({
  fontSize: '16px',
});

export type SearchBoxProps = {
  content: React.ReactNode;
  checkList: {[value: string]: boolean};
} & DialogHandlerProps;

/**
 * スマホ用検索ボックスコンポーネント
 *
 * @example
 * ```tsx
 * const SearchContents = () => {
 *   return <div>検索内容</div>;
 * };
 * // Check項目がValue Checkが入っているかがBooleanで入る
 * const checkList = useRef<{[value: string]: boolean}>({});
 * const onClickButton = useCallback(async (e?: React.MouseEvent) => {
 *    await dialogHandler.open(SearchBox, {
 *   // Childrenを渡す
 *   content: <SearchContents />
 *   // Checkを入れている箇所のObject
 *   checkList:checkList
 * );
 * }, []);
 * ```
 *
 * @param  props - open、actions、children、およびsearchCallbackを含むSearchBoxProps
 * @returns
 */
export const SearchBox = ({open, actions, content, checkList}: SearchBoxProps) => {
  const setSearchText = useSetAtom(searchTextChangeAtom);

  // Childrenからチェックしている箇所を受け取るためのリスト
  const [filterCheckList, setFilterCheckList] = useAtom(filterCheckListAtom);
  const onClickCompletionButton = () => {
    actions?.resolve(filterCheckList);
  };

  useLayoutEffect(() => {
    // 呼び出し元のチェック状況を反映する
    setFilterCheckList(checkList);
  }, [checkList, setFilterCheckList]);

  return (
    <Dialog
      open={Boolean(open)}
      onClose={actions?.reject}
      PaperProps={{style: PaperCss}}
      TransitionComponent={Transition}>
      <HomeIndicatorImage />
      <StyledContainerDiv>
        <StyledFlexDiv>
          <SearchText
            searchCallback={(text: string) => {
              setSearchText(text);
            }}
          />
          <StyledCompleteButton color="primary" onClick={onClickCompletionButton}>
            完了
          </StyledCompleteButton>
        </StyledFlexDiv>
        <StyledContainerDiv>{content}</StyledContainerDiv>
      </StyledContainerDiv>
    </Dialog>
  );
};
