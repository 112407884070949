import React, {useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Grid,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import {EditProductsForms} from './EditProductsForms';
import CloseIcon from '@material-ui/icons/Close';
import {useAtomValue} from 'jotai';
import {selectedProductsAtom} from '@Apps/Inspection/ProductsForm/EditProductsDialog/states';
import {InspectionType} from '@modules/inspections/enum';
import {InspectionPeriodProductWithCount} from '@modules/inspection_setting/types';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#172B4D',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  closeButton: {
    padding: '0px',
  },
  content: {
    padding: '8px 0px 0px',
    height: '500px',
    margin: '0px',
    overflow: 'hidden',
  },
}));

type DialogInnerProps = {
  hospitalHashId: string;
  inspectionHashId: string;
  inspectionType: InspectionType;
  onSubmit: (periods: InspectionPeriodProductWithCount[]) => void;
  onClose: React.MouseEventHandler;
};

export const DialogInner: React.VFC<DialogInnerProps> = (props) => {
  const {hospitalHashId, inspectionHashId, inspectionType, onSubmit, onClose} = props;
  const classes = useStyles();

  const products = useAtomValue(selectedProductsAtom);
  const handleSubmit = useCallback(
    (e: React.MouseEvent) => {
      onSubmit(products);
    },
    [onSubmit, products]
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      disableEnforceFocus={true}>
      <DialogTitle disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <span className={classes.title}>対象機種を選択</span>
          <Grid item>
            <IconButton onClick={onClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <EditProductsForms
          hospitalHashId={hospitalHashId}
          inspectionHashId={inspectionHashId}
          inspectionType={inspectionType}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          保存
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
