import React, {useMemo} from 'react';
import {Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import {ExpandMore} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {openAtom} from './hooks';
import {useAtom} from 'jotai';

type Props = {
  sectionName: string;
  url?: string;
  children: {
    label: string;
    url: string;
  }[];
};

/**
 * セクションコンポーネント。
 * 指定されたセクション名、URL、および子リンクを持つナビゲーションセクションを表示します。
 * 子リンクがある場合は折りたたみ可能なリストアイテムを、ない場合は通常のリストアイテムを表示します。
 * アクティブなリンクはスタイルが異なり、現在のルートに基づいて自動的に決定されます。
 *
 * @param {Props} props - セクションのプロパティ
 * @param {string} props.sectionName - セクションの名前
 * @param {string} [props.url] - セクションのURL（オプション）
 * @param {Array<{ label: string; url: string }>} props.children - セクション内の子リンクの配列
 * @returns JSX.Element - セクションコンポーネント
 */
export const Section: React.FC<Props> = ({sectionName, url, children}) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useAtom(openAtom);

  const activeChildrenLink = useMemo(
    () => children.find((child) => location.pathname.startsWith(child.url)),
    [children, location]
  );

  const isActiveLink = url ? location.pathname.startsWith(url) : false;

  /**
   * 子リンクのリストをレンダリングする関数。
   * 各子リンクは`Collapse`コンポーネント内に表示され、
   * アクティブなリンクはスタイルが異なります。
   *
   * @returns JSX.Element - 子リンクのリストを含むReactコンポーネント
   */
  const renderChildren = () => (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {children.map((child) => (
          <Link key={child.label} to={child.url} className={classes.link}>
            <ListItem
              button
              className={clsx(classes.collapseListItem, {
                [classes.activeCollapseListItem]: activeChildrenLink?.url === child.url,
              })}>
              <ListItemText
                primary={child.label}
                classes={{
                  primary: activeChildrenLink?.url === child.url ? classes.activeText : '',
                  root: classes.listItemTextRoot,
                }}
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </Collapse>
  );
  /**
   * 単一のリストアイテムをレンダリングする関数。
   * この関数は、子リンクがない場合に使用されます。
   * アクティブなリンクはスタイルが異なります。
   *
   * @returns JSX.Element - 単一のリストアイテムを含むReactコンポーネント
   */
  const renderListItem = () => (
    <Link to={url ?? '#'} className={classes.link}>
      <ListItem button className={clsx(classes.listItem, {[classes.activeListItem]: isActiveLink})}>
        <ListItemText
          primary={sectionName}
          classes={{primary: isActiveLink ? classes.activeText : '', root: classes.listItemTextRoot}}
        />
      </ListItem>
    </Link>
  );

  return children.length ? (
    <>
      <ListItem button className={classes.collapseControl} onClick={() => setOpen(!open)}>
        <ListItemText primary={sectionName} classes={{root: classes.listItemTextRoot}} />
        <ListItemIcon className={classes.listIcon}>
          <ExpandMore />
        </ListItemIcon>
      </ListItem>
      {renderChildren()}
    </>
  ) : (
    renderListItem()
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  collapseControl: {
    paddingTop: '10px',
    paddingBottom: ' 10px',
    marginBottom: '12px',
    '&:hover': {
      borderRadius: '4px',
      background: '#E3E7EA',
    },
  },
  collapseListItem: {
    padding: '10px 32px 10px 32px',
    marginBottom: '12px',
    '&:hover': {
      borderRadius: '4px',
      background: '#E3E7EA',
    },
  },
  activeCollapseListItem: {
    backgroundColor: '#E3E7EA',
    borderRadius: '4px',
    '&:hover': {
      background: '#E3E7EA',
    },
  },
  listItem: {
    padding: '10px 16px',
    marginBottom: '12px',
    '&:hover': {
      borderRadius: '4px',
      background: '#E3E7EA',
    },
  },
  activeListItem: {
    padding: '10px 0px 10px 16px',
    backgroundColor: '#E3E7EA',
    borderRadius: '4px',
    '&:hover': {
      background: '#E3E7EA',
    },
  },
  listIcon: {
    minWidth: 0,
    paddingRight: '32px',
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
  linkItemActive: {
    paddingBottom: '2px',
    borderBottom: `4px solid ${theme.palette.primary.dark}`,
  },
  activeText: {
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  listItemTextRoot: {
    flex: '1 1 auto',
    minWidth: 0,
    margin: 0,
  },
}));
