import React from 'react';
import {Backdrop, CircularProgress, makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'absolute',
  },
}));

export const Loader = () => {
  const classes = useStyle();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
