import React, {useCallback} from 'react';
import {createStyles, Grid, makeStyles, IconButton, Theme, TextField, Menu, MenuItem, Card} from '@material-ui/core';
import {MoreVert} from '@material-ui/icons';
import clsx from 'clsx';
import {FormError} from '../FormError';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      borderTopWidth: '6px',
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.primary.main,
      padding: '16px 24px 16px 30px',
    },
    focusedCard: {
      padding: '16px 24px 16px 24px',
      borderLeftWidth: '6px',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.primary.main,
    },
    container: {
      padding: '8px 0px',
      display: 'flex',
    },
    nameInputContainer: {
      flexGrow: 1,
    },
    nameInput: {
      width: '100%',
    },
    nameErrorContainer: {
      height: '22px',
      marginTop: '4px',
    },
    nameError: {
      marginTop: '0px',
    },
  })
);

type SectionViewProps = {
  name: string;
  error: string | undefined;
  focused: boolean;
  onForcusName: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurName: React.FocusEventHandler;
  onClickDuplicate: (e: React.MouseEvent<HTMLLIElement>) => void;
  onClickDelete: (e: React.MouseEvent<HTMLLIElement>) => void;
};

export const SectionView: React.VFC<SectionViewProps> = (props) => {
  const {name, error, focused, onForcusName, onChangeName, onBlurName, onClickDuplicate, onClickDelete} = props;
  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = React.useState<Element | null>(null);
  const handleClickMore = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleClickDuplicate = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      onClickDuplicate(e);
      handleCloseMenu();
    },
    [handleCloseMenu, onClickDuplicate]
  );

  const handleClickDelete = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      onClickDelete(e);
      handleCloseMenu();
    },
    [handleCloseMenu, onClickDelete]
  );

  return (
    <Card elevation={2} className={clsx(classes.card, focused ? classes.focusedCard : null)}>
      <Grid className={classes.container} alignItems="center">
        <Grid direction="column" className={classes.nameInputContainer}>
          <TextField
            className={classes.nameInput}
            placeholder="点検項目を入力（例：外観点検、機能点検、性能点検、電気的安全性点検）"
            error={!!error}
            value={name}
            onFocus={onForcusName}
            onChange={onChangeName}
            onBlur={onBlurName}
          />
          <FormError error={error} reserveSpace={true} />
        </Grid>
        <IconButton onClick={handleClickMore}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'center', horizontal: 'center'}}
          open={Boolean(menuAnchor)}
          onClose={handleCloseMenu}>
          <MenuItem onClick={handleClickDuplicate}>点検項目の複製</MenuItem>
          <MenuItem onClick={handleClickDelete}>点検項目の削除</MenuItem>
        </Menu>
      </Grid>
    </Card>
  );
};
