import React, {useMemo} from 'react';
import {Table} from '@molecules/Table';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Column} from '@molecules/Table/props';
import dayjs from 'dayjs';
import {Pagination} from '@material-ui/lab';
import {hospitalProductPerPage} from '../hooks';
import {Grid} from '@material-ui/core';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // 0-based index
  page: number;
  totalCount: number;
  onPageChange: (newPage: number) => void;
};

export const HospitalProductTable: React.FC<Props> = ({totalCount, data, page, onPageChange}) => {
  const [layout] = useTableLayout('signupProductList');
  const totalPage = useMemo(() => Math.ceil(totalCount / hospitalProductPerPage), [totalCount]);

  const tableLayout = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const l = (layout?.tableLayout ?? []).map<Column<any>>((item) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tableColumn: Column<any> = {...item, noBodyWrap: true, render: undefined};
      if (item.field === 'dateOfDisposal') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableColumn.render = ({dateOfDisposal}: any) =>
          dateOfDisposal !== '' ? dayjs(dateOfDisposal).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'dateOfPurchase') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableColumn.render = ({dateOfPurchase}: any) =>
          dateOfPurchase !== '' ? dayjs(dateOfPurchase).format('YYYY/MM/DD') ?? '' : '';
      }
      if (item.field === 'isSpecificMaintain') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableColumn.render = ({isSpecificMaintained}: any) => isSpecificMaintained;
      }
      return tableColumn;
    });
    return l;
  }, [layout]);

  return (
    <>
      <Table
        data={data}
        columns={tableLayout}
        showSelection={false}
        stickyHeader
        paperProps={{style: {maxHeight: 'calc(100vh - 180px)'}}}
      />
      <Grid container justifyContent="center" style={{marginTop: 24}}>
        <Pagination
          page={page}
          count={totalPage}
          shape="rounded"
          color="primary"
          onChange={(_, newPage) => onPageChange(newPage)}
        />
      </Grid>
    </>
  );
};
