import {useMemo} from 'react';
import {useGetHospitalCategories} from '@modules/categories/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

export const useRootCategories = () => {
  const {myInfo} = useMyInfo();
  const query = useGetHospitalCategories(myInfo.hospitalHashId, {
    depth: 0,
  });

  const rootCategoryOptions = useMemo(() => {
    return query.data.map((item) => ({
      label: item.name,
      value: item.hashId,
    }));
  }, [query.data]);

  return {query, rootCategoryOptions};
};
