import {atomWithReset} from 'jotai/utils';
import {PlanStatus, TrainingTypeValue} from '@modules/training_plans/types';

export type SearchVariable = {
  name: string | null;
  status: PlanStatus | null;
  trainingTypes: TrainingTypeValue[] | null;
  page: number;
  perPage: number;
  order: string | null;
};

export const searchVariablesAtom = atomWithReset<SearchVariable>({
  name: null,
  status: null,
  trainingTypes: null,
  page: 0,
  perPage: 20,
  order: null,
});
