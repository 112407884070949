import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles, Grid, TextField, Typography, Box} from '@material-ui/core';
import {ErrorOutline, Search} from '@material-ui/icons';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Column} from '@molecules/Table/props';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {CategoryFormatter} from '@modules/categories/helpers';
import {
  FetchInspectionPeriodProductsByAssignedParam,
  useFetchInspectionPeriodProductsByAssignedQuery,
} from '@modules/inspection_setting/api';
import {useDebounceState} from '@front-libs/core';
import {
  ProductElement,
  ProductNameColumnProps,
  selectedProductMapAtom,
  updateSelectedElementsAtom,
  updateTableElementsAtom,
} from '@Apps/Inspection/ProductsForm/EditProductsDialog/states';
import {InspectionType} from '@modules/inspections/enum';
import {Table} from '@molecules/Table';
import {
  useGetHospitalCategories,
  useGetHospitalDescendantCategories,
} from '@modules/categories/api/hospitalCategoryApi';
import {useAtomValue, useSetAtom} from 'jotai';
import {isNullish} from '@front-libs/helpers';

const ProductNameColumn: React.FC<ProductNameColumnProps> = (props) => {
  const {name, displayName, assignedInspection} = props;

  const productName = (
    <Typography style={{fontSize: 14}}>
      {!isNullish(name) && (
        <>
          {name}
          <br />
        </>
      )}
      {displayName}
    </Typography>
  );

  if (!assignedInspection) {
    return productName;
  }

  return (
    <Grid container direction="column">
      {productName}
      <Grid item container alignItems="center" style={{marginTop: '4px'}}>
        <ErrorOutline style={{color: '#C7243A', fontSize: '10px'}} />
        <Typography style={{fontSize: '10px', marginLeft: '4px'}}>すでに他の点検表に紐付いています</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  selectProductsHeader: {
    padding: '0px 40px 0px',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 1,
  },
  tableContainer: {
    paddingTop: '32px',
    flex: '1 1',
    overflow: 'hidden',
  },
  tableScrollable: {
    height: '100%',
    overflowY: 'auto',
  },
}));

type SearchProductsProps = {
  hospitalHashId: string;
  inspectionHashId: string;
  inspectionType: InspectionType;
};

export const SearchProducts: React.VFC<SearchProductsProps> = (props) => {
  const {hospitalHashId, inspectionHashId, inspectionType} = props;
  const classes = useStyles();

  const [searchName, setSearchName] = useDebounceState<string>('', 500);
  const [rootCategory, setRootCategory] = useDebounceState<string | null>(null, 500);
  const [narrowCategory, setNarrowCategory] = useDebounceState<string | null>(null, 500);
  const [resetNarrowCategoryValue, setResetNarrowCategoryValue] = useState<boolean>(false);

  const [broadCategoryHashId, setBroadCategoryHashId] = useState<null | string>(null);
  const {data: descendantCategories} = useGetHospitalDescendantCategories(
    hospitalHashId,
    broadCategoryHashId ?? undefined
  );
  const {data: allRootCategory} = useGetHospitalCategories(hospitalHashId, {depth: 0});
  const {data: allNarrowCategory} = useGetHospitalCategories(hospitalHashId, {depth: 1});

  const [tableLayout] = useTableLayout('newInspectionProductsList');
  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<ProductElement>[], TableLayout[]>([], tableLayout?.currentLayout);
    return tableColumn.map((item) => {
      if (item.field === 'productName') {
        item.render = ProductNameColumn;
      }

      return item;
    });
  }, [tableLayout?.currentLayout]);

  const params = useMemo(() => {
    const p: FetchInspectionPeriodProductsByAssignedParam = {
      page: 0,
      perPage: 100,
      inspectionHashId: inspectionHashId,
      inspectionType: inspectionType,
    };

    if (searchName) {
      p.name = searchName;
    }

    if (narrowCategory !== null) {
      p.categoryHashIds = narrowCategory;
    } else if (rootCategory !== null) {
      p.categoryHashIds = rootCategory;
    }

    return p;
  }, [inspectionHashId, inspectionType, searchName, rootCategory, narrowCategory]);

  const productsQuery = useFetchInspectionPeriodProductsByAssignedQuery(hospitalHashId, params);

  const selectedProductMap = useAtomValue(selectedProductMapAtom);

  const tableElements = useMemo<ProductElement[]>(
    () =>
      (productsQuery.data ?? []).map((p) => {
        return {
          ...p,
          hashId: p.hashId,
          name: p.name,
          displayName: p.displayName,
          makerName: p?.maker.name,
          assignedInspection: p.otherInspectionPeriodCount > 0,
          tableData: {
            checked: selectedProductMap[p.hashId] !== undefined,
          },
        };
      }),
    [productsQuery.data, selectedProductMap]
  );

  const updateTableElements = useSetAtom(updateTableElementsAtom);
  const updateSelectedElements = useSetAtom(updateSelectedElementsAtom);

  useEffect(() => {
    updateTableElements(tableElements);
  }, [tableElements, updateTableElements]);

  const handleSelectionChange = useCallback(
    (elems: ProductElement[]) => {
      updateSelectedElements(elems);
    },
    [updateSelectedElements]
  );

  const handleChangeSearchName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
    },
    [setSearchName]
  );

  const handleChangeRootCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setRootCategory(row?.value ?? null);
      setBroadCategoryHashId(row?.value ?? null);
      setNarrowCategory(null);
      setResetNarrowCategoryValue(true);
    },
    [setNarrowCategory, setRootCategory]
  );

  const handleChangeNarrowCategory = useCallback(
    (row: SelectOptionProps | undefined) => {
      setNarrowCategory(row?.value ?? null);
      setResetNarrowCategoryValue(false);
    },
    [setNarrowCategory]
  );

  return (
    <Grid container wrap="nowrap" className={classes.container}>
      <Grid item container className={classes.selectProductsHeader}>
        <Grid item>
          <TextField
            label="型式・機種名で検索"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: <Search />,
            }}
            defaultValue={searchName}
            onChange={handleChangeSearchName}
            style={{minWidth: '240px'}}
          />
        </Grid>
        <Grid item style={{marginLeft: '40px'}}>
          <PopperSelectBoxButton
            buttonLabel="大分類"
            options={CategoryFormatter.getOptions(allRootCategory)}
            isMulti={false}
            onChange={handleChangeRootCategory}
            searchable={true}
          />
        </Grid>
        <Grid item>
          <PopperSelectBoxButton
            buttonLabel="小分類"
            options={CategoryFormatter.getOptions(descendantCategories ?? allNarrowCategory)}
            isMulti={false}
            onChange={handleChangeNarrowCategory}
            searchable={true}
            resetValue={resetNarrowCategoryValue}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Box className={classes.tableScrollable}>
          <Table<ProductElement>
            columns={serializedTableColumn}
            showSelection={true}
            selectionButtons={[]}
            isLoading={productsQuery.isLoading}
            data={tableElements}
            onSelectionChange={handleSelectionChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
