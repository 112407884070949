import React, {memo} from 'react';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {
  InspectionStatusBarProps,
  InspectionStatusMenuItemList,
  StyledExpandMoreIcon,
  StyledMenuButtonContainer,
} from './InspectionStatusBar';

/**
 * 点検ステータス切り替えセレクトボックス'
 * スクロール時に
 * @param props.status 現在選択されている点検ステータス
 * @param props.onChangeType 項目選択時のコールバック関数
 */
export const InspectionStatusSelectBox = memo(({status, onChangeStatus}: InspectionStatusBarProps) => {
  const onClick = (item: MenuItemType, e: React.MouseEvent<Document, MouseEvent>) => {
    onChangeStatus(item.value);
  };

  /** 選択されている項目の背景色を変更する対応 */
  const selectedMenuItemList = InspectionStatusMenuItemList.map((v) => ({
    ...v,
    selectedMenuItem: v.value === status,
  }));

  return (
    <PopperMenuButton
      onMenuClick={onClick}
      containerProps={{style: {display: 'inline-flex'}}}
      buttonProps={{color: 'primary'}}
      menuItemList={selectedMenuItemList}
      hiddenArrow={true}>
      <StyledMenuButtonContainer>
        {InspectionStatusMenuItemList.find((v) => v.value === status)?.label || '未選択'}
        <StyledExpandMoreIcon />
      </StyledMenuButtonContainer>
    </PopperMenuButton>
  );
});
