import React, {useCallback, useMemo, useState} from 'react';
import {makeStyles, List, Button, Card, CardActions, ClickAwayListener} from '@material-ui/core';
import {CheckedMap, CheckedMapKey} from './DrawerAction';
import {NotificationOptionFilter} from '@modules/notifications/types';
import {FilterPopperListItem} from './FilterPopperListItem';

type Props = {
  checkedMap: CheckedMap;
  onSubmit: (checkedValues: CheckedMap) => void;
  onCancel?: () => void;
  optionFilters: NotificationOptionFilter[];
};

export const FilterPopper: React.VFC<Props> = ({checkedMap, onSubmit, onCancel, optionFilters}) => {
  const classes = useStyles();
  const [checkedValues, setCheckedValues] = useState<CheckedMap>(checkedMap);

  const handleCheckedChange = (value: string, checked: boolean) => {
    setCheckedValues({...checkedValues, [value]: checked});
  };

  const handleSubmit = useCallback(() => {
    onSubmit(checkedValues);
  }, [checkedValues, onSubmit]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const createOnChangeHandler = useCallback(
    (value: string, checked: boolean) => {
      if (value === 'all') {
        const allChecked = optionFilters.reduce<{[key: string]: boolean}>((acc, curr) => {
          acc[curr.id] = checked;
          return acc;
        }, {});

        setCheckedValues(allChecked as CheckedMap);
      } else {
        setCheckedValues((prevValues) => ({
          ...prevValues,
          [value]: checked,
        }));
      }
    },
    [optionFilters]
  );

  const filters = useMemo(
    () =>
      optionFilters.map((filter) => {
        return {
          label: filter.label,
          value: filter.id,
          onChange: createOnChangeHandler,
        };
      }),
    [optionFilters, createOnChangeHandler]
  );

  return (
    <ClickAwayListener onClickAway={handleCancel}>
      <Card elevation={4}>
        <List className={classes.root}>
          {filters.map((filterProps, index) => (
            <FilterPopperListItem
              key={index}
              {...filterProps}
              onChange={filterProps.onChange ? filterProps.onChange : handleCheckedChange}
              checked={checkedValues[filterProps.value as CheckedMapKey]}
            />
          ))}
        </List>
        <CardActions style={{paddingRight: 24, paddingLeft: 24}}>
          <Button variant={'contained'} disableElevation color={'primary'} onClick={handleSubmit}>
            保存
          </Button>
          <Button variant="outlined" disableElevation color={'primary'} onClick={handleCancel}>
            キャンセル
          </Button>
        </CardActions>
      </Card>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));
