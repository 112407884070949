import {useMemo} from 'react';
import {useMyInfo} from './useMyInfo';
import {defaultRoleMap} from '../constants';

export const useMyRole = () => {
  const {myRole} = useMyInfo();
  const isAdmin = useMemo(() => myRole?.role.name === defaultRoleMap.admin, [myRole?.role.name]);
  const isRentalOnly = useMemo(() => myRole?.role.name === defaultRoleMap.lendingAndReturnOnly, [myRole?.role.name]);
  const isReadOnly = useMemo(() => myRole?.role.name === defaultRoleMap.deviceUse, [myRole?.role.name]);
  const isGeneral = useMemo(() => myRole?.role.name === defaultRoleMap.general, [myRole?.role.name]);
  return {isAdmin, isRentalOnly, isReadOnly, isGeneral};
};
