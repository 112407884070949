import React, {useCallback} from 'react';
import {Box, Button, makeStyles} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {PRODUCT_REGISTRATION_FOOTER_HEIGHT, WEB_CHAT_ICON_WIDTH} from './constants';
import {useAtom, useSetAtom} from 'jotai';
import {
  searchNameAtom,
  selectedCategoryAtom,
  selectedMakerAtom,
  selectedNarrowCategoryAtom,
  selectedProductAtom,
  selectedRootCategoryAtom,
} from './state';
import {isNullish} from '@front-libs/helpers';

export const Footer: React.VFC = React.memo(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useAtom(selectedProductAtom);
  const setSearchName = useSetAtom(searchNameAtom);
  const setSelectedMaker = useSetAtom(selectedMakerAtom);
  const setSelectedRootCategory = useSetAtom(selectedRootCategoryAtom);
  const setSelectedNarrowCategory = useSetAtom(selectedNarrowCategoryAtom);
  const setSelectedCategoryCategory = useSetAtom(selectedCategoryAtom);

  const handleClickTop = useCallback(() => {
    setSearchName('');
    setSelectedProduct(null);
    setSelectedMaker(null);
    setSelectedRootCategory(null);
    setSelectedNarrowCategory(null);
    setSelectedCategoryCategory(null);
    navigate('/products');
  }, [
    navigate,
    setSearchName,
    setSelectedCategoryCategory,
    setSelectedMaker,
    setSelectedNarrowCategory,
    setSelectedProduct,
    setSelectedRootCategory,
  ]);

  const handleClick = useCallback(() => {
    navigate(`/products/registration/Step2/${selectedProduct}`);
  }, [navigate, selectedProduct]);

  return (
    <Box className={classes.container}>
      <Button variant="outlined" color="primary" className={classes.leftButton} onClick={handleClickTop}>
        一覧に戻る
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.rightButton}
        disabled={isNullish(selectedProduct)}
        onClick={handleClick}>
        確定
      </Button>
    </Box>
  );
});

const useStyles = makeStyles(() => ({
  container: {
    height: PRODUCT_REGISTRATION_FOOTER_HEIGHT,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#f0f2f5',
    zIndex: 1000,
  },
  leftButton: {
    margin: '0px 0px 0px 24px',
    width: '118px',
    height: '40px',
    variant: 'outlined',
    color: 'primary',
  },
  rightButton: {
    margin: `0px calc(24px + ${WEB_CHAT_ICON_WIDTH}) 0px 0px`,
    width: '118px',
    height: '40px',
  },
}));
