import React from 'react';
import {Card, CardActionArea, CardContent, makeStyles} from '@material-ui/core';
import {LenderNameCardProps} from './types';

export const LenderNameCard: React.FC<LenderNameCardProps> = ({userName, onSubmitInput}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardActionArea onClick={() => onSubmitInput(userName)}>
        <CardContent className={classes.cardContent}>{userName}</CardContent>
      </CardActionArea>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: '120px',
    height: '64px',
    display: 'flex',
    borderRadius: '20px',
    boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
    overflow: 'auto',
    wordBreak: 'break-all',
    [theme.breakpoints.up('tabletH')]: {
      width: '128px',
    },
  },
  cardContent: {
    color: theme.palette.rent.main,
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '4px 16px',
  },
}));
