import React from 'react';
import {Card, CardHeader, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '10px 10px 0px 0px',
      background: 'white',
    },
    header: {
      padding: '16px 32px',
      color: '#ffffff',
      backgroundColor: '#65676B',
      fontSize: '16px',
      fontWeight: 700,
    },
    sections: {
      padding: '24px 32px',
      '& > div:not(:first-child)': {
        marginTop: '16px',
      },
    },
  })
);

type SectionProps = {
  name: string;
};

export const Section: React.FC<SectionProps> = (props) => {
  const {name, children} = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header} title={name} />
      <Grid container className={classes.sections} direction="column">
        {children}
      </Grid>
    </Card>
  );
};
