import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, TextField, Typography} from '@material-ui/core';
import dayjs from 'dayjs';
import {openSnackBar} from '@molecules/SnackBar';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {NewRegistrationDialog, NewUserRegistrationResult} from '../../Settings/User/Dialogs/NewUserRegistrationDialog';
import {EditUserDialog} from '../../Settings/User/Dialogs/EditUserDialog';
import {Sidebar} from '@components/organisms/Sidebar';
import {Search} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {Column, RowAction} from '@molecules/Table/props';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {withSuspense} from '@front-libs/core';
import {useSearchParams} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useDebounceState} from '@front-libs/core';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {
  HospitalUserParams,
  useFetchHospitalUsers,
  updateHospitalUser,
  createHospitalUser,
  deleteHospitalUser,
} from '@modules/hospital_users/api';
import {UserIndex} from '@modules/hospital_users/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {Table} from '@molecules/Table';
import {isNullish} from '@front-libs/helpers';
import {v4 as uuidv4} from 'uuid';
import {PasswordCopyDialog} from './Dialogs/PasswordCopyDialog';
import {useUserPermissions} from '@modules/auth/hooks/useUserPermissions';
import {HospitalUserPermission} from '@modules/auth/consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '32px 24px',
    },
    grid: {
      minWidth: '300px',
      minHeight: '65px',
      display: 'flex',
    },
    title: {
      flex: '1 1',
      alignItems: 'center',
      display: 'flex',
    },
    pageTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    flex: {
      flexGrow: 1,
    },
    divider: {
      margin: '16px 0',
      width: '100%',
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    tableContainer: {
      marginBottom: '24px',
    },
    table: {
      width: '100%',
    },
    statusColor: {
      width: '10px',
      top: '6px',
      marginRight: '8px',
      position: 'relative',
      color: theme.palette.success.main,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      visibility: 'hidden',
    },
    touchableActions: {
      visibility: 'visible',
    },
    link: {
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
      margin: theme.spacing(3),
      alignItems: 'baseline',
    },
    actionButton: {
      border: '1px solid #C6CBD4',
      marginLeft: 'auto',
      backgroundColor: '#F5F6F7',
      marginRight: 8,
    },
    text: {
      marginRight: '32px',
      color: '#172B4D',
      fontSize: '20px',
      marginBottom: '24px',
    },
  })
);
const _SettingsHospitalUser: React.FC = (props) => {
  const {myInfo} = useMyInfo();
  const classes = useStyles();
  const templateClasses = useSettingsContentTemplate();
  const queryParams = useSearchParams();
  const [tableLayout] = useTableLayout('userList');
  const {isPermitted} = useUserPermissions();

  const [searchName, setSearchName] = useDebounceState<string | null>((queryParams.name as string) || null, 500);

  useEffect(() => {
    setSearchName((queryParams.name as string) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.name]);

  const [orderKey, setOrderKey] = useState<string | null>(null);
  const [page, setPage] = useState<number>(Number(queryParams.page) || 1);
  const pageSize = 20;

  const params = useMemo(() => {
    const _p: HospitalUserParams = {
      perPage: 20,
      page: page - 1,
    };
    if (orderKey) _p.order = orderKey;
    if (searchName) _p.name = searchName;

    return _p;
  }, [page, orderKey, searchName]);

  const query = useFetchHospitalUsers(myInfo.hospitalHashId, params);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [query.totalCount, pageSize]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  };

  const handleClickRegistration = async () => {
    const showGrantManagementDashboard = isPermitted([HospitalUserPermission.GrantManagementDashboard]);
    const newUser: NewUserRegistrationResult = await dialogHandler.open(NewRegistrationDialog, {
      showGrantManagementDashboard,
    });
    const initialPassword = uuidv4().replace(/-|1|0/g, '').substring(0, 8);
    const initialEmail = `${uuidv4().replace(/-/g, '')}@example.com`;
    // eslint-disable-next-line no-irregular-whitespace
    const splitFullName = newUser.fullName.split(/ |　/);
    // eslint-disable-next-line no-irregular-whitespace
    const splitFullNameKana = newUser.fullNameKana?.split(/ |　/) ?? [];

    try {
      await createHospitalUser({
        email: initialEmail,
        username: newUser.username,
        lastName: splitFullName[0],
        firstName: splitFullName[1] ?? '',
        lastNameKana: splitFullNameKana[0] ?? '',
        firstNameKana: splitFullNameKana[1] ?? '',
        hospitalHashId: myInfo.hospitalHashId,
        isShared: newUser.authority === 'rental',
        isUserAdmin: newUser.authority === 'userAdmin',
        initialized: false,
        password: initialPassword,
        permissions: newUser.canReadManagementDashboard ? [HospitalUserPermission.ReadManagementDashboard] : [],
        sessionExpirationTimeSec: newUser.authority !== 'rental' ? newUser.sessionExpirationTime : 0,
      });
      await query.refetch();
      openSnackBar('ユーザーを作成しました。');

      await dialogHandler.open(PasswordCopyDialog, {
        username: newUser.username,
        password: initialPassword,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const errMsg =
        e.response.status === 409 ? 'ユーザーIDが他のユーザーと重複しています' : 'ユーザーの作成に失敗しました。';
      openSnackBar(errMsg, 'left', 'bottom', 'error');
    }
  };

  const handleUpdateUser = useCallback(
    async (_, user: UserIndex) => {
      const editHospitalUser = await dialogHandler.open(EditUserDialog, {
        defaultHospitalUser: user,
        showGrantManagementDashboard: isPermitted([HospitalUserPermission.GrantManagementDashboard]),
      });

      // 権限の更新。権限がない場合は削除する
      const permissions = editHospitalUser.canReadManagementDashboard
        ? [...(user.permissions ?? []), HospitalUserPermission.ReadManagementDashboard]
        : [...(user.permissions ?? []).filter((item) => item !== HospitalUserPermission.ReadManagementDashboard)];

      try {
        await updateHospitalUser(myInfo.hospitalHashId, user.hashId, {
          ...editHospitalUser,
          isShared: isNullish(editHospitalUser.authority) ? undefined : editHospitalUser.authority === 'rental',
          isUserAdmin: isNullish(editHospitalUser.authority) ? undefined : editHospitalUser.authority === 'userAdmin',
          permissions: permissions,
          sessionExpirationTimeSec:
            editHospitalUser.authority !== 'rental' ? editHospitalUser.sessionExpirationTime : 0,
        });
        openSnackBar('ユーザーを更新しました。');
      } catch (e) {
        openSnackBar('ユーザーの更新に失敗しました。', 'left', 'bottom', 'error');
      }
      await query.refetch();
    },
    [isPermitted, myInfo.hospitalHashId, query]
  );

  const handleDeleteUser = useCallback(
    async (_, user: UserIndex) => {
      await dialogHandler.open(AlertDialog, {
        title: 'ユーザーを削除しますか？',
        content: `${UserFormatter.getFullName(
          user
        )}さんのアカウントを削除しようとしています。\nこのアクションは元に戻せません。`,
        positiveButtonLabel: '削除',
      });
      try {
        await deleteHospitalUser(user.hospitalHashId, user.hashId);
        openSnackBar('ユーザーを削除しました。');
      } catch (e) {
        openSnackBar('ユーザーの削除に失敗しました。', 'left', 'bottom', 'error');
      }
      await query.refetch();
    },
    [query]
  );

  const serializedTableColumn = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableColumn = Object.assign<Column<any>[], TableLayout[]>([], tableLayout?.currentLayout ?? []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return tableColumn.map<Column<any>>((item) => {
      if (item.field === 'firstName') {
        item.render = (user) => <Grid item>{UserFormatter.getFullName(user)}</Grid>;
      }
      if (item.field === 'lastSignInAt') {
        item.render = ({lastSignInAt}: {lastSignInAt?: Date}) =>
          lastSignInAt ? dayjs(lastSignInAt).format('YYYY/MM/DD HH:mm:ss') : '';
      }
      if (item.field === 'authority') {
        item.render = ({isShared, isUserAdmin}: {isUserAdmin: boolean; isShared?: boolean}) =>
          !isNullish(isShared) ? (isShared ? '貸出・返却のみ' : isUserAdmin ? 'ユーザー管理者' : '一般ユーザー') : '';
      }

      item.noBodyWrap = true;

      return item;
    });
  }, [tableLayout]);

  const handleOrderChange = (columnIndex: number, orderDirection: 'asc' | 'desc') => {
    if (columnIndex === -1) {
      setOrderKey(null);
    } else {
      setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
    }
  };

  const rowActions = useMemo(() => {
    const actions: RowAction<UserIndex>[] = [
      {
        type: 'menu',
        label: 'アクション',
        items: [
          {
            label: '編集',
            onClick: handleUpdateUser,
          },
          {
            label: 'アカウントを削除',
            onClick: handleDeleteUser,
          },
        ],
      },
    ];

    return actions;
  }, [handleDeleteUser, handleUpdateUser]);

  return (
    <Grid container className={templateClasses.grid}>
      <div className={templateClasses.form}>
        <Grid container className={templateClasses.grid}>
          <Grid item className={templateClasses.sideBar}>
            <Sidebar />
          </Grid>
          <Grid item className={templateClasses.content}>
            <Grid container style={{marginBottom: '32px'}}>
              <Grid item>
                <Typography variant={'h5'} className={templateClasses.pageTitle}>
                  ユーザー管理
                </Typography>
              </Grid>
              <div className={templateClasses.flex} />
            </Grid>
            <Grid className={classes.text}>ユーザーの追加、削除、権限の変更はHITOTSU Hospitalで設定できます。</Grid>
            <Grid>
              <a href={import.meta.env.VITE_ACCOUNT_SERVICE_URL} target="_blank" rel="noreferrer">
                <Button variant="contained" color="primary">
                  HITOTSU Hospitalへ移動
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export const SettingsHospitalUser = withSuspense(_SettingsHospitalUser, null);
