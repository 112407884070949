import React, {useEffect, useCallback, useMemo, useState, createContext, useContext} from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {Search} from '@material-ui/icons';
import {Column} from '@molecules/Table/props';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionPeriodProduct} from '@modules/inspection_setting/types';
import {useFetchInspectionPeriodProductsQuery} from '@modules/inspection_setting/api';
import {
  bulkUpdateHospitalProducts,
  FetchHospitalProductsParams,
  getHospitalProduct,
  useFetchHospitalProductsQuery,
} from '@modules/hospital_products/api';
import {DateField, DateFieldValue} from '@molecules/DateField';
import {updateHospitalProduct} from '@modules/hospital_products/api';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {atom, useAtom} from 'jotai';
import {NoProducts} from './NoProducts';
import {CategoryFormatter} from '@modules/categories/helpers';
import {Table} from '@molecules/Table';
import TodayIcon from '@material-ui/icons/Today';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {BulkUpdateStartDateDialog} from './dialogs/BulkUpdateStartDateDialog';
import dayjs from 'dayjs';
import {openSnackBar} from '@molecules/SnackBar';
import {useDebounceState} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';

// TODO: move to other file
type UpdateHospitalProductStartDateRequestDispatcher = (
  hospitalProductHashId: string,
  startDate: string
) => Promise<void>;

const UpdateHospitalProductStartDateRequestDispatcherContext =
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createContext<UpdateHospitalProductStartDateRequestDispatcher>(async () => {});

type UpdateHospitalProductStartDateRequestDispatcherProviderProps = {
  handler: UpdateHospitalProductStartDateRequestDispatcher;
};

const UpdateHospitalProductStartDateRequestDispatcherProvider: React.FC<
  UpdateHospitalProductStartDateRequestDispatcherProviderProps
> = ({handler, children}) => {
  return (
    <UpdateHospitalProductStartDateRequestDispatcherContext.Provider value={handler}>
      {children}
    </UpdateHospitalProductStartDateRequestDispatcherContext.Provider>
  );
};

export const useUpdatePeriodRequestDispatcherContext = () => {
  return useContext(UpdateHospitalProductStartDateRequestDispatcherContext);
};

const DateColumn: React.VFC<ProductElement> = ({hashId, startDate, ...rest}) => {
  const dispatcher = useUpdatePeriodRequestDispatcherContext();

  return (
    <DateField
      inputVariant="standard"
      value={startDate || null}
      style={{minWidth: '150px'}}
      onChange={(data: DateFieldValue) => {
        dispatcher(hashId, data as string);
      }}
    />
  );
};

const NUM_PER_PAGE = 10;

type ProductElement = {
  hashId: string;
  managementId: string;
  rootCategory: string;
  narrowCategory: string;
  maker: string;
  displayName: string;
  startDate: string | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1 1 auto',
      width: '100%',
      padding: '0px 24px',
    },
    toolBar: {
      display: 'flex',
      alignItems: 'center',
      '& > *:not(:first-child)': {
        marginLeft: '16px',
      },
    },
    searchBox: {
      width: '300px',
    },
    searchInput: {
      background: 'white',
    },
    tableContainer: {
      marginTop: '24px',
      width: '100%',
      overflowX: 'auto',
    },
    pagination: {
      marginTop: '16px',
    },
  })
);

type HospitalProductsTableProps = {
  products: InspectionPeriodProduct[];
};

const HospitalProductsTable: React.VFC<HospitalProductsTableProps> = (props) => {
  const {products} = props;
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const [searchWord, setSearchWord] = useDebounceState<string>('', 500);
  const [productHashId, setProductHashId] = useAtom(productHashIdAtom);
  const [tableLayout] = useTableLayout('inspectionHospitalProductsList');
  const [page, setPage] = useState(1);
  const [orderKey, setOrderKey] = useState<string>('management_id');

  useEffect(() => {
    setPage(1);
  }, [searchWord]);

  const getHospitalProductsParam = useMemo((): FetchHospitalProductsParams => {
    const wholeProductHashIDs = productHashId ? [productHashId] : products.map((p) => p.hashId);

    return {
      name: searchWord,
      wholeProductHashIds: wholeProductHashIDs.join(','),
      page: page - 1,
      perPage: NUM_PER_PAGE,
      order: orderKey,
      statuses: 'ready,working,uninspected,repairing',
    };
  }, [productHashId, products, searchWord, page, orderKey]);

  const hospitalProductsQuery = useFetchHospitalProductsQuery(myInfo.hospitalHashId, getHospitalProductsParam);

  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<ProductElement>[], TableLayout[]>([], tableLayout?.currentLayout);

    return tableColumn.map((item) => {
      if (item.field === 'startDate') {
        item.render = DateColumn;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout?.currentLayout]);

  const listData = useMemo<ProductElement[]>(
    () =>
      (hospitalProductsQuery.data ?? []).map((hp) => ({
        hashId: hp.hashId,
        managementId: hp.managementId,
        rootCategory: CategoryFormatter.getRootCategory(hp.categories)?.name ?? '',
        narrowCategory: CategoryFormatter.getNarrowCategory(hp.categories)?.name ?? '',
        maker: hp.maker?.name,
        name: hp.name,
        displayName: hp?.displayName,
        startDate: hp?.periodicInspectionStartDate !== '0001-01-01' ? hp?.periodicInspectionStartDate : undefined,
      })),
    [hospitalProductsQuery.data]
  );

  const totalPage = useMemo(() => {
    return Math.ceil(hospitalProductsQuery.totalCount / NUM_PER_PAGE);
  }, [hospitalProductsQuery.totalCount]);

  const handleChangeSearchWord = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchWord(e.target.value);
    },
    [setSearchWord]
  );

  const handleChangeProductHashId = useCallback(
    (row: SelectOptionProps | undefined) => {
      setProductHashId(row?.value ?? null);
    },
    [setProductHashId]
  );

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const handleChangeStartDate = useCallback(
    // eslint-disable-next-line no-shadow
    async (productHashId: string, date: string) => {
      const hospitalProduct = await getHospitalProduct(myInfo.hospitalHashId, productHashId);
      hospitalProduct.periodicInspectionStartDate = date;
      await updateHospitalProduct(myInfo.hospitalHashId, productHashId, {
        ...hospitalProduct,
        maker: undefined,
        catalogPrice: undefined,
        deliveryPrice: undefined,
        taxRate: undefined,
        legalDurableYear: undefined,
        leaseFee: undefined,
        rentalFee: undefined,
      });
      hospitalProductsQuery.refetch();
    },
    [hospitalProductsQuery, myInfo.hospitalHashId]
  );

  const handleBulkChangeStartDates = useCallback(
    async (_unusedEvent, values: ProductElement[] = []) => {
      const updateHashIds = values.map((item) => item.hashId);
      const {startDate} = await dialogHandler.open(BulkUpdateStartDateDialog, {});

      await bulkUpdateHospitalProducts(myInfo.hospitalHashId, updateHashIds, {
        periodicInspectionStartDate: dayjs(startDate).toDate(), //YYYY-MM-DD
      });
      hospitalProductsQuery.refetch();

      openSnackBar('点検開始日を更新しました');
    },
    [hospitalProductsQuery, myInfo.hospitalHashId]
  );

  const handleOrderChange = (columnIndex: number, orderDirection: 'asc' | 'desc') => {
    if (columnIndex === -1) {
      setOrderKey('management_id');
    } else {
      setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
    }
  };

  const productOptions = useMemo(() => {
    return products.map((p) => ({
      label: `${!isNullish(p.name) && `${p.name} / `}${p.displayName}`,
      value: p.hashId,
    }));
  }, [products]);

  const initialValue = useMemo(
    () => productOptions.find((item) => item.value === productHashId),
    [productHashId, productOptions]
  );

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item className={classes.toolBar} direction="row">
        <TextField
          className={classes.searchBox}
          label="管理番号・型式・機種名で検索"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            className: classes.searchInput,
            endAdornment: <Search />,
          }}
          defaultValue={searchWord}
          onChange={handleChangeSearchWord}
        />
        <Grid item style={{marginLeft: '40px'}}>
          <PopperSelectBoxButton
            buttonLabel={'すべての機種'}
            options={productOptions}
            isMulti={false}
            onChange={handleChangeProductHashId}
            searchable={true}
            initialOption={initialValue}
          />
        </Grid>
      </Grid>
      <UpdateHospitalProductStartDateRequestDispatcherProvider handler={handleChangeStartDate}>
        <Grid item container direction="column">
          <Grid className={classes.tableContainer}>
            <Table<ProductElement>
              showSelection={true}
              selectionButtons={[
                {
                  label: '点検開始日を一括設定',
                  IconComponent: TodayIcon,
                  onClick: handleBulkChangeStartDates,
                },
              ]}
              isLoading={hospitalProductsQuery.isLoading}
              columns={serializedTableColumn}
              data={listData}
              onOrderChange={handleOrderChange}
            />
          </Grid>
          <Grid container className={classes.pagination} justifyContent={'center'}>
            <Pagination page={page} count={totalPage} color={'primary'} shape="rounded" onChange={handleChangePage} />
          </Grid>
        </Grid>
      </UpdateHospitalProductStartDateRequestDispatcherProvider>
    </Grid>
  );
};

type ProductsFormProps = {
  inspectionHashId: string;
};

export const productHashIdAtom = atom<string | null>(null);

export const HospitalProductsForm: React.VFC<ProductsFormProps> = (props) => {
  const {inspectionHashId} = props;
  const {myInfo} = useMyInfo();

  const getInspectionProductsQuery = useFetchInspectionPeriodProductsQuery(myInfo.hospitalHashId, {
    perPage: NUM_PER_PAGE,
    inspectionHashId: inspectionHashId,
    hasInspectionId: true,
    inspectionType: 'periodic',
  });

  if (getInspectionProductsQuery.totalCount === 0) {
    return (
      <Grid item container alignItems="center" justifyContent="center" style={{width: '100%', marginTop: '72px'}}>
        <Grid item>
          <NoProducts
            title="まだ機種が選択されていません。"
            message={`対象機種タブより、機種を選択してください。\n選択され機種がここに表示されます。`}
          />
        </Grid>
      </Grid>
    );
  }

  return <HospitalProductsTable products={getInspectionProductsQuery.data} />;
};
