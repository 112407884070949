import {Box, makeStyles} from '@material-ui/core';
import React, {memo, useMemo, useState} from 'react';
import {InspectionItem, MultiSelectInspectionItem} from '@modules/inspections/api';
import {ItemTitle} from './ItemTitle';
import {ItemDescription} from './ItemDescription';
import {FastField, FieldInputProps, FieldMetaProps, FormikProps, getIn} from 'formik';
import {
  EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
  FormValue,
  INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR,
  INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
} from '@Apps/InspectionResult/pc/common/types';
import * as Yup from 'yup';
import {useAsyncEffect} from '@front-libs/core';
import {ResultItem} from '@modules/inspection_results/types';
import {getItemSchema, getResultMultiSelectItemSchema} from '@Apps/InspectionResult/pc/common/validator';
import {ItemContent} from './ItemContent';
import _ from 'lodash';

export const Item: React.FC<ItemInnerProps> = memo((props) => {
  const id = props.item.id;

  return (
    <FastField name={`items[${id}]`}>
      {({field, form, meta}: ItemFieldProps) => <ItemInner {...props} field={field} meta={meta} form={form} />}
    </FastField>
  );
});

const ItemInner: React.VFC<ItemInnerProps & ItemFieldProps> = (props) => {
  const {item, resultItem, field} = props;
  const classes = useStyles();
  const [data, setData] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validation = (shape: Record<string, any>, value: Record<string, any>) => {
    const validator = Yup.object().shape(shape);
    validator
      .validate(value)
      .then(() => {
        setData('');
      })
      .catch((error) => {
        setData(error.message);
      });
  };

  const createShapeAndValue = (newValues?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shape: Record<string, any> = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: Record<string, any> = {};
    if (resultItem.type === 'multi-select') {
      shape[item.id] = getResultMultiSelectItemSchema(item as MultiSelectInspectionItem & {id: string}, false);
      if (newValues !== undefined) {
        value[resultItem.id] = {
          id: resultItem.id,
          values: newValues,
        };
      } else {
        value[resultItem.id] = {
          id: resultItem.id,
          values: resultItem.values,
        };
      }
    } else {
      shape[item.id] = getItemSchema(item as InspectionItem & {id: string}, false);
      if (newValues !== undefined) {
        value[resultItem.id] = {
          id: resultItem.id,
          value: newValues,
        };
      } else {
        value[resultItem.id] = {
          id: resultItem.id,
          value: resultItem.value,
        };
      }
    }
    return {shape, value};
  };

  useAsyncEffect(async () => {
    const {shape, value} = createShapeAndValue();
    validation(shape, value);
  }, [item, resultItem]);

  const rawError = data;

  const error = useMemo(() => {
    if (item.type === null) {
      return null;
    }
    switch (item.type) {
      case 'select':
      case 'multi-select':
        if (rawError === INSPECTION_SELECT_ITEM_VALIDATION_ERROR) {
          return 'error';
        }
        break;
      case 'numeric': {
        if (_.isString(rawError) && rawError !== EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
          try {
            const {error: errorCode} = JSON.parse(rawError);
            if (errorCode === INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR) {
              return 'error';
            }
          } catch (_e) {
            // empty
          }
        }
      }
    }
  }, [item, rawError]);

  const value = useMemo(() => {
    switch (item.type) {
      case 'select':
      case 'numeric':
      case 'text':
      case 'multiline-text':
      case 'date':
      case 'time':
        return getIn(field.value, 'value');
      // multiple values
      case 'multi-select':
        return getIn(field.value, 'values');
      case null:
        return null;
    }
  }, [item.type, field.value]);

  if (item.type === null || item.type === 'section' || item.type === 'bool') {
    // should not reach here
    return null;
  }
  return (
    <Box className={classes.root}>
      <ItemTitle item={item} />
      {item.description && <ItemDescription description={item.description} />}
      <ItemContent error={error} value={value} item={item} />
    </Box>
  );
};

type ItemInnerProps = {
  item: InspectionItem & {id: string};
  resultItem: ResultItem;
};

export type ItemFieldProps = {
  field: FieldInputProps<FormValue['items'][string]>;
  meta: FieldMetaProps<FormValue['items'][string]>;
  form: FormikProps<FormValue['items'][string]>;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '16px',
  },
}));
