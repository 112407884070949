import React, {useCallback, useState} from 'react';
import {
  createStyles,
  Grid,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  makeStyles,
  Theme,
  Divider,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import {Sidebar} from '@components/organisms/Sidebar';
import {InnerLoading} from '@molecules/Loading';
import {Form, Formik, useFormikContext} from 'formik';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {updateHospitalSettings, useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {Checkbox} from '@atoms/Checkbox';
import {openSnackBar} from '@molecules/SnackBar';
import {UpdateHospitalSettingsParams, rentalSettings} from '@modules/hospital_settings/types';
import {TextField} from '@molecules/Formik/fields';

export const SettingsHospitalRental: React.FC = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <RentalContainer>
        <RentalForm />
      </RentalContainer>
    </Grid>
  );
};

export type RentalSettings = {
  hashId: string;
  needManageMedicalDeviceLenderStatus: string;
  allowRentalRoomUndefined: boolean;
  allowSkipRentalFloorSelection: boolean;
  enableInspectionRentalRestriction: boolean;
  preInspectionRentalRestrictionPeriod: string;
};

const RentalContainer: React.FC = ({children}) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  if (isLoading) {
    return <InnerLoading />;
  }

  const initialData: RentalSettings = {
    hashId: myInfo.hospitalHashId,
    needManageMedicalDeviceLenderStatus:
      data?.data?.find((x) => x.key === rentalSettings.use_device_lender.key)?.value ?? 'not_need',
    allowRentalRoomUndefined:
      data?.data?.find((x) => x.key === rentalSettings.allow_undefined_room.key)?.value === 'true',
    allowSkipRentalFloorSelection:
      data?.data?.find((x) => x.key === rentalSettings.skip_floor_selection.key)?.value === 'true',
    enableInspectionRentalRestriction:
      (data?.data?.find((x) => x.key === rentalSettings.pre_inspection_rental_restriction_period.key)?.value || '0') !==
      '0',
    preInspectionRentalRestrictionPeriod:
      data?.data?.find((x) => x.key === rentalSettings.pre_inspection_rental_restriction_period.key)?.value || '',
  };

  const handleSubmit = async (res: RentalSettings) => {
    const keys: (keyof RentalSettings)[] = Object.keys(initialData) as (keyof RentalSettings)[];
    const updatedKeys = keys.filter((item: keyof RentalSettings) => {
      if (item === 'enableInspectionRentalRestriction') {
        return false;
      }

      return initialData[item] !== res[item];
    });

    try {
      const updatedData: UpdateHospitalSettingsParams[] = updatedKeys.map((key: string) => {
        let value = `${res[key as keyof RentalSettings]}`;
        if (
          key === rentalSettings.pre_inspection_rental_restriction_period.field &&
          res[rentalSettings.enable_pre_inspection_rental_restriction.field] === false
        ) {
          value = '0';
        }

        return {
          hospitalHashId: res.hashId,
          key: key.replace(/[A-Z]/g, (s) => `_${s.toLowerCase()}`) as typeof rentalSettings.use_device_lender.key,
          value: value,
        };
      });

      await Promise.all(
        updatedData.map((d) => {
          return updateHospitalSettings(res.hashId, d);
        })
      );
      await refetch();
      openSnackBar('貸出・返却の設定を更新しました');
    } catch (error) {
      openSnackBar('貸出・返却の設定の更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  return (
    <Formik initialValues={initialData} onSubmit={handleSubmit} enableReinitialize={true}>
      {children}
    </Formik>
  );
};

const menuItems = [
  {
    label: '持出者・返却者管理は有効ではありません',
    value: 'not_need',
    needManagement: false,
  },
  {
    label: 'バーコード入力',
    value: 'by_barcode',
    needManagement: true,
  },
  {
    label: '直接入力',
    value: 'by_direct_input',
    needManagement: true,
  },
];

const RentalForm: React.FC = () => {
  const classes = useStyles();
  const templateClasses = useSettingsContentTemplate();
  const context = useFormikContext<RentalSettings>();
  const [needManagement, setNeedManagement] = useState(
    context.values.needManageMedicalDeviceLenderStatus !== 'not_need'
  );

  return (
    <Form className={templateClasses.form}>
      <Grid container className={templateClasses.grid}>
        <Grid item className={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item className={templateClasses.content}>
          <Grid container>
            <Grid item>
              <Typography variant={'h5'} className={templateClasses.pageTitle}>
                貸出・返却
              </Typography>
              <p>貸出・返却に関するユーザー共通設定を管理します。</p>
            </Grid>
            <div className={templateClasses.flex} />
          </Grid>
          <Divider variant="middle" className={templateClasses.divider} />
          <Grid container>
            <Grid item>
              <Grid>
                <Typography variant={'h6'} className={templateClasses.pageSubTitle1}>
                  セットアップ
                </Typography>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      data-testid={'rental-settings-need-management'}
                      control={<Checkbox color={'secondary'} disableRipple={false} />}
                      checked={needManagement}
                      onChange={(_, checked) => {
                        setNeedManagement(checked);
                        if (checked) context.setFieldValue(rentalSettings.use_device_lender.field, 'by_barcode');
                        else context.setFieldValue(rentalSettings.use_device_lender.field, 'not_need');
                      }}
                      label="持出者・返却者の管理を有効にする"
                    />
                    <FormHelperText className={classes.formHelperText} style={{marginLeft: 32, marginBottom: 32}}>
                      貸出・返却時に持出者・返却者を登録できるようになります。
                    </FormHelperText>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl variant="outlined" disabled={!needManagement}>
                  <FormGroup>
                    <Typography>持出者・返却者の登録方法</Typography>
                    <Select
                      data-testid={'rental-settings-registration-device-lender-way'}
                      className={classes.selectBox}
                      value={context.values.needManageMedicalDeviceLenderStatus}
                      onChange={(e) => {
                        context.setFieldValue(rentalSettings.use_device_lender.field, e.target.value as string);
                      }}>
                      {menuItems
                        .filter((item) => needManagement === item.needManagement)
                        .map((item) => {
                          return <MenuItem value={item.value}>{item.label}</MenuItem>;
                        })}
                    </Select>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      data-testid={'rental-settings-allow-undefined-room'}
                      control={<Checkbox color={'secondary'} disableRipple={false} />}
                      checked={context.values.allowRentalRoomUndefined}
                      onChange={() =>
                        context.setFieldValue(
                          rentalSettings.allow_undefined_room.field,
                          !context.values.allowRentalRoomUndefined
                        )
                      }
                      label="「貸出先を選択せず貸出」を有効にする"
                    />
                    <FormHelperText className={classes.formHelperText} style={{marginLeft: 32, marginBottom: 32}}>
                      貸出先を選択せずに貸出できるようになります。
                    </FormHelperText>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      data-testid={'rental-settings-skip-floor-selection'}
                      control={<Checkbox color={'secondary'} disableRipple={false} />}
                      checked={context.values.allowSkipRentalFloorSelection}
                      onChange={() =>
                        context.setFieldValue(
                          rentalSettings.skip_floor_selection.field,
                          !context.values.allowSkipRentalFloorSelection
                        )
                      }
                      label="「貸出先の階数選択をスキップ」を有効にする"
                    />
                    <FormHelperText className={classes.formHelperText} style={{marginLeft: 32, marginBottom: 32}}>
                      貸出先の階数を選択せずに、小エリアを選択できるようになります。
                    </FormHelperText>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      data-testid={'rental-settings-enable-inspection-rental-restriction'}
                      control={
                        <Checkbox
                          color="secondary"
                          disableRipple={false}
                          checked={context.values.enableInspectionRentalRestriction}
                        />
                      }
                      onChange={useCallback(() => {
                        context.setFieldValue(
                          rentalSettings.enable_pre_inspection_rental_restriction.field,
                          !context.values.enableInspectionRentalRestriction
                        );

                        if (context.values.enableInspectionRentalRestriction) {
                          context.setFieldValue(rentalSettings.pre_inspection_rental_restriction_period.field, '');
                        }
                      }, [context])}
                      label="定期点検が近い機器の貸出を不可にする"
                    />
                    <FormHelperText className={classes.formHelperText} style={{marginLeft: 32, marginBottom: 16}}>
                      定期点検予定が近い機器の貸出をできないようにします
                    </FormHelperText>
                    <div style={{width: '150px', marginBottom: 32}}>
                      <TextField
                        data-testid={'rental-settings-pre-inspection-rental-restriction-period'}
                        disabled={!context.values.enableInspectionRentalRestriction}
                        name="preInspectionRentalRestrictionPeriod"
                        type="number"
                        size="small"
                        InputProps={{
                          inputProps: {
                            min: 1,
                          },
                          endAdornment: <InputAdornment position="end">日以内</InputAdornment>,
                        }}
                      />
                    </div>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormikFormSubmitDrawer />
    </Form>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: 24,
    },
    formHelperText: {
      fontSize: 14,
      color: theme.palette.grey[600],
    },
    selectBox: {
      height: 32,
      marginTop: 12,
      marginBottom: 32,
      width: 384,
    },
  })
);
