import React, {useCallback, useEffect, useState} from 'react';
import {useAsyncEffect} from '@front-libs/core';
import {
  createHospitalRoomLayoutSetting,
  fetchHospitalRoomLayoutSetting,
  updateHospitalRoomLayoutSetting,
} from '@modules/hospital_room_layout_setting/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FormValue, customSequence} from '@modules/hospital_room_layout_setting/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {openSnackBar} from '@components/molecules/SnackBar';
import {InnerLoading} from '@components/molecules/Loading';
import {Formik} from 'formik';
import {PositionSetting} from '../PositionSetting';
import {useAtomValue} from 'jotai';
import {layoutSettingRoomVariablesAtom, radioAtom} from '../states';
import {isNullish} from '@front-libs/helpers';
import {useNavigate} from 'react-router-dom';

type Option = {
  label: string;
  value: string;
};
type FloorOption = {
  label: string;
  value: string;
  floorNumber: string;
  isGroundFloor: boolean;
};
type HospitalRoomPositionProps = {
  wardOptions: Option[];
  floorOptions: FloorOption[];
  convertRoom: customSequence[];
  convertSkipFloorRoom: customSequence[];
};

export const HospitalRoomPosition = ({
  wardOptions,
  floorOptions,
  convertRoom,
  convertSkipFloorRoom,
}: HospitalRoomPositionProps) => {
  const {myInfo} = useMyInfo();
  const [initialValues, setInitialValues] = useState<FormValue | null>(null);
  const variables = useAtomValue(layoutSettingRoomVariablesAtom);
  const radio = useAtomValue(radioAtom);
  const [order, setOrder] = useState<customSequence[] | null>(null);
  const [cancelFlg, setCancelFlg] = useState(false);
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    try {
      // 小エリア並び順設定データ取得
      const layoutSetting = await fetchHospitalRoomLayoutSetting(
        myInfo.hospitalHashId,
        myInfo.hashId,
        variables.hospitalWardHashId ?? '',
        {page: 0, perPage: 100}
      );

      let settingData = layoutSetting.data[0]?.customSequence || [];
      let roomId = layoutSetting.data[0]?.hashId || '';

      // データがなければ作成
      if (isNullish(layoutSetting.data[0])) {
        const {data: responseData} = await createHospitalRoomLayoutSetting(
          myInfo.hospitalHashId,
          myInfo.hashId,
          variables.hospitalWardHashId,
          {
            customSequence: convertSkipFloorRoom,
          }
        );
        settingData = responseData.customSequence;
        roomId = responseData.hashId;
      }
      // 貸出画面に表示されている小エリアのみにフィルター
      const filterLayoutSetting = settingData.filter((item) => convertRoom.some((id) => id.id === item.id));

      setInitialValues({
        HashId: roomId,
        Setting: filterLayoutSetting,
      });
      setOrder(settingData);
    } catch (e: unknown) {
      console.error(e);
    }
  }, [variables.floorNumber, radio, variables.hospitalWardHashId]);

  const handleSubmit = useCallback(
    async (values: FormValue) => {
      const {hospitalHashId, hashId: userHashId} = myInfo;

      try {
        await dialogHandler.open(MessageDialog, {
          title: '変更内容を保存しますか？',
          positiveButtonLabel: '保存',
          negativeButtonLabel: '保存せずトップへ戻る',
          warning: false,
        });
      } catch (_e) {
        navigate('/shared', {replace: true});
        return;
      }

      try {
        // 初期値に含まれていて、submitされたデータに含まれていないデータを抽出
        const missingData = (order || []).filter((item) => !values.Setting.some((value) => value.id === item.id));
        const newSetting = missingData.length > 0 ? [...values.Setting, ...missingData] : values.Setting;

        await updateHospitalRoomLayoutSetting(hospitalHashId, userHashId, variables.hospitalWardHashId, values.HashId, {
          customSequence: newSetting,
        });
        setInitialValues({
          HashId: values.HashId,
          Setting: values.Setting,
        });
        openSnackBar('変更を保存しました。', 'left', 'bottom');
      } catch (e: unknown) {
        console.error(e);
        openSnackBar('更新に失敗しました。', 'center', 'top', 'error');
      }
    },
    [myInfo, navigate, order, variables.hospitalWardHashId]
  );

  const handleClickCancel = useCallback(async () => {
    try {
      await dialogHandler.open(MessageDialog, {
        title: '変更内容を破棄しますか？',
        positiveButtonLabel: '破棄',
        negativeButtonLabel: '破棄せず編集画面へ戻る',
        warning: true,
      });
    } catch (_e) {
      return;
    }
    try {
      setCancelFlg(true);
    } catch (_e) {
      return;
    }
  }, []);

  useEffect(() => {
    if (cancelFlg) {
      setCancelFlg(false);
    }
  }, [cancelFlg]);

  if (initialValues === null) {
    return <InnerLoading />;
  }
  let roomDataToUse = convertRoom;
  if (radio === 'customize') {
    roomDataToUse = initialValues?.Setting || [];
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      <PositionSetting
        data={roomDataToUse}
        wardOptions={wardOptions}
        floorOptions={floorOptions}
        handleClickCancel={handleClickCancel}
        resetFlag={cancelFlg}
      />
    </Formik>
  );
};
