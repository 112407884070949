import React, {useState, useMemo} from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions as MDialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Option, Selector} from '@molecules/Selector';
// import {productStatusOptions} from '@modules/hospital_products/constants';
import {DateField} from '@molecules/DateField';
import {waysOfPurchaseOptions} from '../../constants';
import {HospitalRoom} from '@modules/hospital_places/types';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {formatLotNumber} from '@modules/hospital_products/utls';
import {OptionStringList} from '../../ProductsRegistrationDialog/Step2/EditableColumnModal/EditableColumnModal';

type Props = {
  selected: string[];
  hospitalRooms: HospitalRoom[];
  hospitalDealerOptionsList: OptionStringList;
  hospitalProductMgmtSectionOptions: OptionStringList;
} & DialogProps;

/**
 * 選択された機器情報を編集するためのモーダルダイアログ。
 *
 * @param {Props} props - コンポーネントへのprops
 * @param {boolean} props.open - ダイアログが開いているかどうか
 * @param {string[]} props.selected - 選択された機器のIDリスト
 * @param {HospitalRoom[]} props.hospitalRooms - 病院の部屋のリスト
 * @param {DialogProps} props.actions - ダイアログのアクションハンドラ
 * @returns {React.VFC<Props>} 機器情報を一括編集するためのモーダルダイアログのReact関数型コンポーネント
 */
export const EditableColumnModal: React.VFC<Props> = ({
  open,
  selected,
  hospitalRooms,
  hospitalDealerOptionsList,
  hospitalProductMgmtSectionOptions,
  actions,
}) => {
  const [selectColumn, setColumn] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectValue, setValue] = useState<{[key: string]: any} | null>(null);

  const handleSave = async () => {
    await actions.resolve(selectValue);
  };

  const handleCancel = () => {
    actions.reject();
  };

  const hospitalRoomOptions = useMemo(
    () => hospitalRooms.map((item) => ({label: HospitalRoomFormatter.getFullRoom(item), value: item.hashId})),
    [hospitalRooms]
  );

  const targetColumn = useMemo<Column | undefined>(() => {
    return columns(hospitalRoomOptions, hospitalDealerOptionsList, hospitalProductMgmtSectionOptions).find(
      (col) => col.name === selectColumn
    );
  }, [hospitalDealerOptionsList, hospitalProductMgmtSectionOptions, hospitalRoomOptions, selectColumn]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (type: Column['type'], name: string) => (e: any) => {
    setValue(null);

    switch (type) {
      case 'text':
      case 'textarea':
        setValue({[name]: e.target.value});
        break;
      case 'number':
        setValue({[name]: Number(e.target.value)});
        break;
      case 'date':
        // Memo: DateFieldはdateのstringが渡される。
        setValue({[name]: e});
        break;
      case 'select':
        // Memo: Selectorは{value: any, label: any}が渡される。
        setValue({[name]: e.value});
        break;
    }
  };

  const handleChangeColumn = (option: Option) => {
    //Memo: 更新する情報を変更した場合は入っている値をNullにする。
    setValue(null);
    setColumn(option.value);
  };

  const canSubmit = useMemo(() => {
    return !!selectColumn;
  }, [selectColumn]);

  return (
    <Dialog open={open} fullWidth maxWidth={'md'} PaperProps={{style: {overflowY: 'visible', maxWidth: '500px'}}}>
      <DialogTitle id="scroll-dialog-title">
        <Title variant="h6">{selected.length}件の機器情報を一括情報編集</Title>
      </DialogTitle>
      <DialogContent style={{overflowY: 'visible'}}>
        <Box>
          <div>更新する情報</div>
          <Selector
            value={selectColumn}
            options={columns(hospitalRoomOptions, hospitalDealerOptionsList, hospitalProductMgmtSectionOptions).map(
              (column) => {
                return {
                  label: column.label,
                  value: column.name,
                };
              }
            )}
            onChange={handleChangeColumn}
            menuPosition={'fixed'}
          />
        </Box>
        {targetColumn && (
          <Box my={3}>
            <div>{targetColumn.label}</div>
            {React.createElement(targetColumn.InputComponent, {
              name: targetColumn.name,
              onChange: handleChange(targetColumn.type, targetColumn.name),
              onBlur: targetColumn.formatter
                ? () => {
                    if (selectValue && selectValue[targetColumn.name]) {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      setValue({[targetColumn.name]: targetColumn.formatter!(selectValue[targetColumn.name])});
                    }
                  }
                : undefined,
              value: selectValue ? selectValue[targetColumn.name] : null,
              ...targetColumn.props,
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box p={2} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <Box mr={2}>
            <Button disabled={!canSubmit} color="primary" variant="contained" onClick={handleSave}>
              保存
            </Button>
          </Box>
          <Box>
            <Button color="primary" variant="outlined" onClick={handleCancel}>
              キャンセル
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

type Column = {
  type: 'text' | 'textarea' | 'number' | 'date' | 'select';
  label: string;
  name: string;
  InputComponent: React.ElementType;
  formatter?: (raw: string) => string;
  props?: Record<string, unknown>;
};

const columns: (
  hospitalRoomOptions: OptionStringList,
  hospitalDealerOptionsList: OptionStringList,
  hospitalProductMgmtSectionOptions: OptionStringList
) => Column[] = (hospitalRoomOptions, hospitalDealerOptionsList, hospitalProductMgmtSectionOptions) => [
  {
    type: 'select',
    label: '貸出区分',
    name: 'permanentlyAssigned',
    InputComponent: Selector,
    props: {
      options: [
        {label: '貸出不可', value: true},
        {label: '貸出可', value: false},
      ],
    },
  },
  // {
  //   type: 'select',
  //   label: '稼働状況',
  //   name: 'status',
  //   InputComponent: Selector,
  //   props: {
  //     options: productStatusOptions,
  //   },
  // },
  {
    type: 'select',
    label: '管理部署',
    name: 'hospitalProductMgmtSectionHashId',
    InputComponent: Selector,
    props: {
      options: hospitalProductMgmtSectionOptions,
    },
  },
  {
    type: 'date',
    label: '購入日',
    name: 'dateOfPurchase',
    InputComponent: DateField,
    props: {
      style: {width: '100%'},
    },
  },
  {
    type: 'select',
    label: '購入区分',
    name: 'waysOfPurchase',
    InputComponent: Selector,
    props: {
      options: waysOfPurchaseOptions,
    },
  },
  {
    type: 'select',
    label: '機器管理場所',
    name: 'hospitalRoomHashID',
    InputComponent: Selector,
    props: {
      options: hospitalRoomOptions,
    },
  },
  {
    type: 'number',
    label: '院内耐用年数（年）',
    name: 'legalDurableYear',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
      type: 'number',
    },
  },
  {
    type: 'select',
    label: '保守契約',
    name: 'isMaintenanceContract',
    InputComponent: Selector,
    props: {
      options: [
        {label: '保守契約', value: true},
        {label: '保守契約外', value: false},
      ],
    },
  },
  {
    type: 'select',
    label: '親機・子機',
    name: 'isBaseUnit',
    InputComponent: Selector,
    props: {
      options: [
        {label: '親機', value: true},
        {label: '子機', value: false},
      ],
    },
  },
  {
    type: 'select',
    label: '担当代理店',
    name: 'hospitalDealerHashId',
    InputComponent: Selector,
    props: {
      options: hospitalDealerOptionsList,
    },
  },
  {
    type: 'text',
    label: '資産番号',
    name: 'assetRegisterNumber',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  {
    type: 'text',
    label: 'ロット番号',
    name: 'lotNumber',
    InputComponent: TextField,
    formatter: formatLotNumber,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  {
    type: 'date',
    label: '廃棄日',
    name: 'dateOfDisposal',
    InputComponent: DateField,
    props: {
      style: {width: '100%'},
    },
  },
  {
    type: 'textarea',
    label: '廃棄理由',
    name: 'reasonOfDisposal',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      style: {width: '100%'},
    },
  },
  {
    type: 'textarea',
    label: '備考1',
    name: 'notes',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      multiline: true,
      style: {width: '100%'},
    },
  },
  {
    type: 'textarea',
    label: '備考2',
    name: 'notes2',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      multiline: true,
      style: {width: '100%'},
    },
  },
  {
    type: 'textarea',
    label: '備考3',
    name: 'notes3',
    InputComponent: TextField,
    props: {
      variant: 'outlined',
      multiline: true,
      style: {width: '100%'},
    },
  },
];

const DialogActions = styled(MDialogActions)({
  background: '#FFF',
  padding: 'initial',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
});
