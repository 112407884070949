import {useCallback} from 'react';
import {atom, useAtomValue} from 'jotai';
import {useAtomCallback} from 'jotai/utils';
import {Column, Order} from './props';

// state atom
const initializedAtom = atom(false);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const columnsAtom = atom<Column<any>[]>([]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dataAtom = atom<any[]>([]);

export const numSelectedAtom = atom(0);

export const orderAtom = atom<Order | null>(null);

// selector atom
export const selectedAnyAtom = atom((get) => get(numSelectedAtom) > 0);

export const selectedAllAtom = atom((get) => {
  const num = get(dataAtom).length;
  const numSelected = get(numSelectedAtom);

  return num > 0 && num === numSelected;
});

export const numDataAtom = atom((get) => get(dataAtom).length);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initColumnsAtom = atom(null, (_, set, newData: Column<any>[]) => {
  set(columnsAtom, newData);
});

// setter atom
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initDataAtom = atom(null, (_, set, data: any[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newData: any[] = [];
  let newNumSelected = 0;

  data.forEach((e) => {
    const newItem = {
      ...e,
      tableData: {
        checked: false,
        ...(e?.tableData ?? {}),
      },
    };

    newData.push(newItem);

    newNumSelected += newItem.tableData.checked ? 1 : 0;
  });

  set(dataAtom, newData);
  set(numSelectedAtom, newNumSelected);
  set(initializedAtom, true);
});

export const useInitialized = () => {
  return useAtomValue(initializedAtom);
};

export const useGetCheckedData = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useAtomCallback<any[], boolean>(
    useCallback(
      (get, _set, checked: boolean) => get(dataAtom).filter((item) => item.tableData?.checked === checked),
      []
    )
  );
};

export const useSelectOne = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useAtomCallback<any[], [number, boolean]>(
    useCallback((get, set, [itemIndex, checked]: [number, boolean]) => {
      const data = get(dataAtom);
      const target = data[itemIndex];

      const newData = [
        ...data.slice(0, itemIndex),
        {
          ...target,
          tableData: {
            checked,
          },
        },
        ...data.slice(itemIndex + 1),
      ];

      set(dataAtom, newData);

      if ((target.tableData?.checked ?? false) !== checked) {
        set(numSelectedAtom, get(numSelectedAtom) + (checked ? 1 : -1));
      }

      return newData;
    }, [])
  );
};

export const useSelectAll = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useAtomCallback<any[], boolean>(
    useCallback((get, set, selected: boolean) => {
      const data = get(dataAtom);

      const newData = data.map((e) => ({
        ...e,
        tableData: {
          checked: selected,
        },
      }));

      set(dataAtom, newData);
      set(numSelectedAtom, selected ? data.length : 0);

      return newData;
    }, [])
  );
};
