import React from 'react';
import {HospitalProductWithInspectionProduct} from 'src/modules/hospital_products/types';
import {createStyles, Grid, makeStyles, Theme, Link} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  })
);

type InspectionNameColumnProps = {
  rowData: HospitalProductWithInspectionProduct;
  handleClickInspection: (_e: React.MouseEvent, data: HospitalProductWithInspectionProduct) => void;
};

export const InspectionNameColumn = ({rowData, handleClickInspection}: InspectionNameColumnProps) => {
  const classes = useStyles();
  return (
    <Grid item xs container direction="column">
      <Link onClick={(e) => handleClickInspection(e, rowData)} className={classes.name}>
        {rowData.inspectionName}
      </Link>
    </Grid>
  );
};
