import React, {useEffect, useMemo, useRef} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {chartOptions} from './constants';
import {useFaultRatioSeries, useXAxisCategories} from '../hooks';

export const Chart: React.FC = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const xAxisCategory = useXAxisCategories();
  const series = useFaultRatioSeries();

  useEffect(() => {
    const current = chartComponentRef.current;
    current?.chart.reflow();
  }, [series]);

  const options = useMemo(
    () => ({
      ...chartOptions,
      xAxis: {
        categories: xAxisCategory,
      },
      series,
    }),
    [series, xAxisCategory]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
};
