import {BaseListResult} from '@front-libs/core';
import {axios} from '@front-libs/core';
import {UserNotification, NotificationStatus, NotificationCategory} from './types';
import {convertDateToRFC3339} from '@front-libs/helpers';
import {PmdaRecallResultIndex} from '../pmda_recalls/types';

export type NotificationListResult = BaseListResult<UserNotification>;

type NotificationParams = {
  page: number;
  perPage: number;
  statuses?: string;
  categories?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
};

export const getNotifications = async (
  hospitalHashId: string,
  hospitalUserHashId: string,
  statuses: NotificationStatus[] | null = [],
  categories: NotificationCategory[] | null = [],
  createdAtFrom: Date | null = null,
  createdAtTo: Date | null = null,
  page = 0,
  perPage = 100
): Promise<NotificationListResult> => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${hospitalUserHashId}/notifications`;
  const params: NotificationParams = {
    page,
    perPage,
  };

  if (statuses && statuses.length > 0) params.statuses = statuses.join(',');
  if (categories && categories.length > 0) params.categories = categories.join(',');
  if (createdAtFrom) params.createdAtFrom = convertDateToRFC3339(createdAtFrom);
  if (createdAtTo) params.createdAtTo = convertDateToRFC3339(createdAtTo);
  const notifications = await axios.get<NotificationListResult>(baseUrl, {params});
  return notifications.data;
};

export type BulkUpdateNotificationsParams = {
  hospitalHashId: string;
  hospitalUserHashId: string;
  data: {
    status: NotificationStatus;
    notificationHashIds?: string[];
  };
};

export const bulkUpdateNotifications = async (param: BulkUpdateNotificationsParams): Promise<void> => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${param.hospitalHashId}/users/${
    param.hospitalUserHashId
  }/notifications`;

  await axios.put(baseUrl, {...param.data});
};

export type PmdaRecallParam = {
  recallHashId: string;
};

export const getPmdaRecall = async (param: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1${param}`;
  const {data} = await axios.get<PmdaRecallResultIndex>(baseUrl);
  return data;
};
