import {Box, Typography} from '@material-ui/core';
import React from 'react';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionIndex} from '@modules/inspections/types';
import {InspectionTypesToLabel} from '@modules/inspections/enum';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {formatRFC3339Date} from '@front-libs/helpers';
import {InspectionResultEvaluationRow} from './inspectionStatusRow';

export const ResultSummaryContent: React.FC<ResultSummaryContentProps> = (props) => {
  const {inspectionResult, inspection} = props;
  const inspectionTypeLabel = InspectionTypesToLabel[inspectionResult.type!] || '';
  const inspector = inspectionResult.inspector ? UserFormatter.getFullName(inspectionResult.inspector) : '';
  const updatedByFullName = inspectionResult.updatedBy ? UserFormatter.getFullName(inspectionResult.updatedBy) : '';
  const scheduledTime = inspectionResult.scheduledTime
    ? formatRFC3339Date(inspectionResult?.updatedAt, 'YYYY/MM/DD HH:mm')
    : '';
  const updatedAt = inspectionResult.updatedAt
    ? formatRFC3339Date(inspectionResult?.updatedAt, 'YYYY/MM/DD HH:mm')
    : '';

  return (
    <Box style={{display: 'flex', flexDirection: 'column', paddingLeft: '16px'}}>
      <Box>
        <Typography style={{color: '#C6CBD4', fontSize: '14px'}}>点検タイプ</Typography>
        <Typography>{inspectionTypeLabel}</Typography>
      </Box>
      <Box style={{paddingTop: '16px'}}>
        <Typography style={{color: '#C6CBD4', fontSize: '14px'}}>点検表</Typography>
        <Typography>{inspection.name}</Typography>
      </Box>
      <Box style={{paddingTop: '16px', alignContent: 'center'}}>
        <Typography style={{color: '#C6CBD4', fontSize: '14px'}}>点検結果</Typography>
        <InspectionResultEvaluationRow inspectionResultEvaluation={inspectionResult.result} />
      </Box>
      <Box style={{paddingTop: '16px'}}>
        <Typography style={{color: '#C6CBD4', fontSize: '14px'}}>点検者</Typography>
        <Typography>{inspector}</Typography>
      </Box>
      <Box style={{paddingTop: '16px'}}>
        <Typography style={{color: '#C6CBD4', fontSize: '14px'}}>点検日時</Typography>
        <Typography>{scheduledTime}</Typography>
      </Box>
      <Box style={{paddingTop: '16px'}}>
        <Typography style={{color: '#C6CBD4', fontSize: '14px'}}>最終編集者</Typography>
        <Typography>{updatedByFullName}</Typography>
      </Box>
      <Box style={{paddingTop: '16px'}}>
        <Typography style={{color: '#C6CBD4', fontSize: '14px'}}>最終編集日時</Typography>
        <Typography>{updatedAt}</Typography>
      </Box>
    </Box>
  );
};

type ResultSummaryContentProps = {
  inspectionResult: InspectionResultIndex;
  inspection: InspectionIndex;
};
