import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
  DragStart,
  ResponderProvided,
} from 'react-beautiful-dnd';

type ContextProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onDragStart?: (initial: DragStart, provided: ResponderProvided) => void;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
};

export const DndContext: React.FC<ContextProps> = ({
  children,
  onDragStart = () => {
    // empty function
  },
  onDragEnd,
  style = {},
}) => {
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId={'droppable'} direction={'vertical'}>
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps} style={style}>
              {children}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

type DraggableProps = {
  children: React.ReactNode;
  index: number;
  draggableId: number | string;
  isDragDisabled?: boolean;
};

const getItemStyle = (draggableStyle?: DraggingStyle | NotDraggingStyle) => ({
  ...draggableStyle,
});

export const DndDraggable = ({children, index, draggableId, isDragDisabled}: DraggableProps) => {
  return (
    <Draggable isDragDisabled={isDragDisabled} index={index} draggableId={String(draggableId)} key={draggableId}>
      {(provided) => (
        <div
          style={getItemStyle(provided.draggableProps.style)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};
