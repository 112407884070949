import React from 'react';
import {createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {
  DateInspectionItem,
  MultilineTextInspectionItem,
  MultiSelectInspectionItem,
  NumericInspectionItem,
  SelectInspectionItem,
  TextInspectionItem,
  TimeInspectionItem,
} from '@modules/inspections/api';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    required: {
      color: theme.palette.error.main,
    },
    nameContainer: {
      marginTop: '16px',
      fontSize: 18,
    },
    inspectionPointContainer: {
      marginTop: '4px',
      fontSize: 14,
    },
    inspectionPoint: {
      color: theme.palette.grey[600],
    },
    descriptionContainer: {
      marginTop: '4px',
      fontSize: 14,
    },
    description: {
      color: theme.palette.grey[600],
    },
    main: {
      marginTop: '24px',
    },
    warning: {
      marginTop: '26px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.error.main,
    },
    warningMessage: {
      marginLeft: '18px',
      fontWeight: 'bold',
    },
  })
);

export type BaseFieldProps = {
  item:
    | SelectInspectionItem
    | MultiSelectInspectionItem
    | NumericInspectionItem
    | TextInspectionItem
    | MultilineTextInspectionItem
    | DateInspectionItem
    | TimeInspectionItem;
};

export const BaseField: React.FC<BaseFieldProps> = (props) => {
  const {item, children} = props;
  const classes = useStyle();

  return (
    <Grid>
      <Grid className={classes.nameContainer}>
        {item.required === true ? (
          <RequiredLabel>{item.name}</RequiredLabel>
        ) : (
          <Typography variant="inherit">{item.name}</Typography>
        )}
      </Grid>
      {item.inspectionPoint && (
        <Grid className={classes.inspectionPointContainer}>
          <Typography className={classes.inspectionPoint} variant="inherit">
            {item.inspectionPoint}
          </Typography>
        </Grid>
      )}
      {item.description && (
        <Grid className={classes.descriptionContainer}>
          <Typography className={classes.description} variant="inherit">
            {item.description}
          </Typography>
        </Grid>
      )}
      <Grid className={classes.main}>{children}</Grid>
    </Grid>
  );
};
