import {useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useState} from 'react';
import {fetchCategories, fetchCategoriesDto, useDescendantCategoryQuery, useFetchRootCategoriesQuery} from '../api';

export const useCategories = () => {
  const categories = useAtomValue(fetchCategories);
  const setFetchCategoriesQuery = useSetAtom(fetchCategoriesDto);
  return {
    categories,
    setFetchCategoriesQuery,
  };
};

export const useCategoryQuery = () => {
  const [selectedRootCategory, setSelectedRootCategory] = useState<string>();
  const rootCategoryQuery = useFetchRootCategoriesQuery({depth: 0});
  const subCategoryQuery = useFetchRootCategoriesQuery({depth: 1});

  const descendantCategoriesQueries = useDescendantCategoryQuery(selectedRootCategory);
  const selectRootCategory = useCallback((categoryHashId: string | undefined) => {
    setSelectedRootCategory(categoryHashId);
  }, []);

  return {rootCategoryQuery, subCategoryQuery, descendantCategoriesQueries, selectRootCategory};
};

export const useDescendantCategories = () => {
  const categories = useAtomValue(fetchCategories);
  const setFetchCategoriesQuery = useSetAtom(fetchCategoriesDto);
  return {
    categories,
    setFetchCategoriesQuery,
  };
};
