import {upsertHospitalLastSync} from './repository';

export const createOrUpdateHospitalLastSync = async (hospitalHashId: string) => {
  const now = new Date();

  const hospitalLastSync = {
    hospitalHashId,
    updatedAt: now,
  };

  await upsertHospitalLastSync(hospitalLastSync);
};
