import React from 'react';
import {KeyboardArrowDown} from '@material-ui/icons';
import {Button, styled, Theme} from '@material-ui/core';

const StyledButton = styled(Button)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.grey[400],
  color: '#172B4D',
  display: 'flex',
  padding: '2px 4px',
}));
type Props = {
  label: string;
  tabindex: string;
  onClick: () => void;
};
export const ToolBarFilterButton = ({label, onClick, tabindex}: Props) => {
  return (
    <StyledButton onClick={onClick} tabIndex={tabindex}>
      {label}
      <KeyboardArrowDown />
    </StyledButton>
  );
};
