import {Grid, makeStyles, TextField} from '@material-ui/core';
import React, {useMemo, useState} from 'react';
import {useFetchHospitalDeviceLendersQuery} from '@modules/hospital_device_lenders/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FetchHospitalDeviceLendersParams} from '@modules/hospital_device_lenders/types';
import clsx from 'clsx';
import {DirectInputContentProps} from './types';
import {LenderNameCard} from './LenderNameCard';

const ITEMS_PER_PAGE = 12;
const PAGE = 0;

export const DirectInputContent: React.FC<DirectInputContentProps> = ({onSubmitInput}) => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [lenderName, setLenderName] = useState('');

  const params: FetchHospitalDeviceLendersParams = useMemo(
    () => ({
      userName: lenderName,
      page: PAGE,
      perPage: ITEMS_PER_PAGE,
    }),
    [lenderName]
  );
  const {data} = useFetchHospitalDeviceLendersQuery(myInfo.hospitalHashId, params);

  return (
    <div className={classes.root}>
      <div className={classes.fieldContainer}>
        <TextField
          data-testid={'lender-name-textbox'}
          id="outlined-full-width"
          placeholder="ここをタップして名前を入力"
          InputProps={{
            style: {
              fontSize: 32,
              fontWeight: 700,
            },
          }}
          fullWidth
          autoFocus
          margin="normal"
          variant="outlined"
          className={classes.textField}
          tabIndex={0}
          value={lenderName}
          onChange={(e) => setLenderName(e.target.value)}
        />
      </div>
      <div className={clsx(classes.fieldContainer, classes.nameContainer)}>
        <div className={classes.candidateSubTitle}>名前の候補</div>
        <div style={{width: '70%'}}>
          {lenderName !== '' && (
            <Grid container spacing={3} style={{overflow: 'scroll'}}>
              <Grid item xs={3}>
                <LenderNameCard userName={lenderName} onSubmitInput={onSubmitInput} />
              </Grid>
              {data.map(
                (d, index) =>
                  d.userName !== lenderName && (
                    <Grid key={index} item xs={3}>
                      <LenderNameCard userName={d.userName} onSubmitInput={onSubmitInput} />
                    </Grid>
                  )
              )}
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '40px',
    marginBottom: 16,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    width: '70%',
    paddingTop: '40px',
    margin: 8,
  },
  candidateSubTitle: {
    width: '70%',
    marginTop: 16,
    marginBottom: 8,
  },
  nameContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
