import React, {useCallback} from 'react';
import {Checkbox, Grid, FormControlLabel} from '@material-ui/core';
import {UploadDocumentDialogProps, UploadDocumentDialog} from './UploadDocumentDialog';

/**
 * 機種共通ファイル登録チェックボックス
 * @param setIsFileSharingAcrossProducts true時、同一機種にもファイルが共有される
 * @returns
 */
const BulkDocumentCheckBox = ({
  setIsFileSharingAcrossProducts,
}: {
  setIsFileSharingAcrossProducts: React.MutableRefObject<boolean>;
}) => {
  const handleChangeOptionLabel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsFileSharingAcrossProducts.current = e.target.checked;
    },
    [setIsFileSharingAcrossProducts]
  );
  return (
    <Grid container>
      <FormControlLabel
        control={<Checkbox onChange={handleChangeOptionLabel} />}
        label={'機種共通ファイルとして登録する'}
      />
    </Grid>
  );
};

/**
 * 機種共通ファイル登録チェックボックス付きファイルアップロードダイアログ
 * @param {UploadDocumentDialogProps} UploadDocumentDialogProps
 * @returns
 */
export const UploadBulkDocumentDialog = ({
  title = 'ファイルをアップロード',
  setDefaultFileName = true,
  acceptFile,
  setIsFileSharingAcrossProducts,
  ...props
}: UploadDocumentDialogProps & {setIsFileSharingAcrossProducts: React.MutableRefObject<boolean>}) => {
  return (
    <UploadDocumentDialog {...props}>
      <BulkDocumentCheckBox setIsFileSharingAcrossProducts={setIsFileSharingAcrossProducts} />
    </UploadDocumentDialog>
  );
};
