import {FetchInspectionResultsParam, getInspectionResults} from '@modules/inspection_results/api';
import {deleteInspectionResultsByHospitalHashId, upsertInspectionResults} from './repository';

export const initInspectionResults = async (hospitalHashId: string) => {
  const inspectionHashId = 'dummyHashId';
  const NUM_PER_PAGE = 100;

  const params: FetchInspectionResultsParam = {
    page: 0,
    perPage: NUM_PER_PAGE,
  };

  const initialData = await getInspectionResults(hospitalHashId, inspectionHashId, params);
  const totalPage = Math.ceil(initialData.totalCount / NUM_PER_PAGE);
  if (initialData.data.length !== 0) {
    await upsertInspectionResults(initialData.data);
  }

  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await getInspectionResults(hospitalHashId, inspectionHashId, params);
    if (data.data.length !== 0) {
      await upsertInspectionResults(data.data);
    }
  }
};

// 差分データ取得
export const updateInspectionResults = async (hospitalHashId: string, updatedAt: string) => {
  const inspectionHashId = 'dummyHashId';

  const NUM_PER_PAGE = 100;
  const params: FetchInspectionResultsParam = {
    page: 0,
    perPage: NUM_PER_PAGE,
    updatedAtFrom: updatedAt,
  };
  const updateData = await getInspectionResults(hospitalHashId, inspectionHashId, params);
  const totalPage = Math.ceil(updateData.totalCount / NUM_PER_PAGE);
  if (updateData.data.length !== 0) {
    await upsertInspectionResults(updateData.data);
  }

  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await getInspectionResults(hospitalHashId, inspectionHashId, params);
    if (data.data.length !== 0) {
      await upsertInspectionResults(data.data);
    }
  }
};

// 病院IDに紐づくデータを削除
export const deleteInspectionResults = async (hospitalHashId: string) => {
  await deleteInspectionResultsByHospitalHashId(hospitalHashId);
};
