import dayjs from 'dayjs';
import minmax from 'dayjs/plugin/minMax';

dayjs.extend(minmax);

export const RFC3339Format = 'YYYY-MM-DDTHH:mm:ssZ';

export const parseRFC3339 = (s: string) => {
  return dayjs(s, RFC3339Format);
};

export const convertDateToRFC3339 = (d: Date): string => {
  return dayjs(d).format(RFC3339Format);
};

export const convertDateToSimpleDate = (d: Date): string => {
  return dayjs(d).format('YYYY-MM-DD');
};

export const convertDateToSimpleDateWithoutYear = (d: Date): string => {
  return dayjs(d).format('MM-DD');
};

export const convertDateToSlashDateWithoutYear = (d: Date): string => {
  return dayjs(d).format('MM/DD');
};

export const convertDateToSimpleDateTime = (d: Date): string => {
  return dayjs(d).format('YYYY-MM-DD HH:mm');
};

export const convertDateToJapaneseDate = (d: Date): string => {
  return dayjs(d).format('YYYY年MM月DD日');
};

export const convertDateToJapaneseDateTime = (d: Date): string => {
  return dayjs(d).format('YYYY年MM月DD日 HH:mm');
};

export const extractTimeFromDate = (d: Date): string => {
  return dayjs(d).format('HH:mm');
};

export const convertDisplaySimpleDate = (d: Date, format = 'YYYY/MM/DD'): string => {
  return dayjs(d).format(format);
};

export const convertRFC3339ToDate = (s: string): Date | null => {
  const date = parseRFC3339(s);
  return date.isValid() ? date.toDate() : null;
};

export const formatRFC3339Date = (s: string, format: string = RFC3339Format): string | null => {
  const date = parseRFC3339(s);
  return date.isValid() ? date.format(format) : null;
};

export const getLastDay = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const isToday = (d: Date): boolean => {
  const today = dayjs().format('YYYY-MM-DD');
  const inputDate = dayjs(d).format('YYYY-MM-DD');

  return inputDate === today;
};

export const maxDate = (...ds: Date[]): Date => {
  const max = dayjs.max(ds.map((x) => dayjs(x)));
  if (max === null) {
    throw new Error('maxDate: invalid date');
  }
  return max.toDate();
};

export const minDate = (...ds: Date[]): Date => {
  const min = dayjs.min(ds.map((x) => dayjs(x)));
  if (min === null) {
    throw new Error('minDate: invalid date');
  }
  return min.toDate();
};

export const calcMiddleTime = (startDate: Date, endDate: Date) => {
  return new Date((startDate.getTime() + endDate.getTime()) / 2);
};

export const getTermOfThisYear = (): [Date, Date] => {
  const now = dayjs();
  return [now.startOf('year').toDate(), now.endOf('year').toDate()];
};

export const getTermOfThisQuater = (): [Date, Date] => {
  const now = dayjs();
  const month = now.get('month');

  const quarter = Math.floor(month / 3);
  const beginMonth = quarter * 3;
  const endMonth = (quarter + 1) * 3 - 1;

  return [now.set('month', beginMonth).startOf('month').toDate(), now.set('month', endMonth).endOf('month').toDate()];
};

export const getTermOfLastMonth = (): [Date, Date] => {
  const now = dayjs();
  const lastMonth = now.subtract(1, 'month');
  return [lastMonth.startOf('month').toDate(), lastMonth.endOf('month').toDate()];
};

export const getTermOfThisMonth = (): [Date, Date] => {
  const now = dayjs();
  return [now.startOf('month').toDate(), now.endOf('month').toDate()];
};

export const getTermOfLastWeek = (): [Date, Date] => {
  const now = dayjs();
  const lastWeek = now.subtract(1, 'week');
  return [lastWeek.startOf('week').toDate(), lastWeek.endOf('week').toDate()];
};

export const getTermOfYesterday = (): [Date, Date] => {
  const now = dayjs();
  const yesterday = now.subtract(1, 'day');
  return [yesterday.startOf('date').toDate(), yesterday.endOf('date').toDate()];
};

export const getTermOfThisWeek = (): [Date, Date] => {
  const now = dayjs();
  return [now.startOf('week').toDate(), now.endOf('week').toDate()];
};

export const getTermOfToday = (): [Date, Date] => {
  const now = dayjs();
  return [now.startOf('date').toDate(), now.endOf('date').toDate()];
};
