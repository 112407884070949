import React, {useRef, useCallback, useEffect} from 'react';

export const useMouseClickAndDragCallback = (
  onClick?: React.MouseEventHandler,
  onDragComplete?: React.MouseEventHandler
) => {
  const isClick = useRef(false);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    isClick.current = true;
  }, []);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    isClick.current = false;
  }, []);

  const handleMouseUp = useCallback(
    (e: React.MouseEvent) => {
      const wasClick = isClick.current;
      isClick.current = false;

      if (wasClick) {
        onClick && onClick(e);
      } else {
        onDragComplete && onDragComplete(e);
      }
    },
    [onClick, onDragComplete]
  );

  return {
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
  } as const;
};

// ローディング中に前のデータを表示し、チラツキを防ぐためのフック
// isLoadingがtrue  => refに保存した一つ前のデータを表示
// isLoadingがfalse => 現在のデータを表示・refに保存
// isLoadingがundefined => 現在のデータを表示
// eslint-disable-next-line @typescript-eslint/ban-types
export const useLoadingData = <T extends {}>(tableData: T[], isLoading: boolean | undefined) => {
  // loading中に前のデータを表示し続けるために前のデータの参照を保持する
  const prevDataRef = useRef<T[]>(tableData);

  useEffect(() => {
    // isLoadingが使われているときだけ使用
    // tableDataが準備できたら、前のデータとして保存
    if (isLoading === false) {
      prevDataRef.current = tableData;
    }
  }, [tableData, isLoading]);

  // ローディング中は前のデータを表示する
  if (isLoading === true) {
    return prevDataRef.current;
  }

  // ローディング完了後やisLoadingを使ってないときはtableDataをそのまま返す
  return tableData;
};
