import {Typography, styled} from '@material-ui/core';
import React from 'react';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import {UserIndex} from '@modules/hospital_users/types';
import dayjs from 'dayjs';

/**
 * 返却カードのProps
 */
export type ReturnCardProps = {
  title: React.ReactNode;
  user?: UserIndex;
  displayDate?: Date;
  contentSlot?: {label: string; value: string}[];
};

/**
 * 返却カードの表示
 * @param {ReturnCardProps} CommentCardProps
 * @param title - タイトル
 * @param user - 作成者のUserIndex
 * @param displayDate - 作成日のDate
 * @param contentSlot - コンテント内容(labelとvalueの配列)
 * @returns
 */
export const ReturnCard = ({title, user, displayDate, contentSlot}: ReturnCardProps) => {
  return (
    <RootContainer>
      <IconContainer>
        <AssignmentReturnedIcon style={{height: '22px'}} />
      </IconContainer>
      <Container>
        <Header>
          {title}
          <CommentDate>{dayjs(displayDate).format('MM/DD HH:mm')}</CommentDate>
        </Header>
        <Content>
          {contentSlot?.map((content, index) => (
            <Row key={index}>
              <Label>{content.label}</Label>
              <Data>{content.value}</Data>
            </Row>
          ))}
        </Content>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
});

const Container = styled('div')({
  paddingLeft: '8px',
  width: '100%',
});

const Content = styled('div')({});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
});

const Label = styled('div')({
  minWidth: '56px', // ：の開始位置を修正。返却日時が4文字のため、4文字分の56px分確保する。
  color: '#172B4D',
});

const Data = styled(Typography)({
  fontSize: '14px',
  color: '#172B4D',
});
