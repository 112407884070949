import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {SnackbarProvider, useSnackbar, OptionsObject, VariantType} from 'notistack';
import {StylesProvider} from '@material-ui/styles';

// render snackbar DOM into div element in index.html
const mountPoint = document.getElementById('snackbar');

const defaultEnqueueOptions = {
  autoHideDuration: 1500,
};

/**
 * スナックバーを表示する
 * @param msg - スナックバーに表示するメッセージ
 * @param variant - スナックバーのタイプ。大体successとかをよく使うはずなのでデフォルトはsuccess
 * @param horizontal - スナックバーの水平表示位置。大体左下のはずなのでleftがデフォルト
 * @param vertical - スナックバーの垂直表示位置。大体左下のはずなのでbottomがデフォルト
 * @param options - スナックバーのオプション。 OptionsObject を参照(variantは除く)
 */
export const openSnackBar = (
  msg: string,
  horizontal: 'left' | 'center' | 'right' = 'left',
  vertical: 'top' | 'bottom' = 'bottom',
  variant: VariantType = 'success',
  options: OptionsObject = {}
) => {
  const ShowSnackbar: React.VFC<{message: string; opts?: OptionsObject}> = ({message, ...opts}) => {
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
      enqueueSnackbar(message, {variant, ...defaultEnqueueOptions, ...opts});
    }, [message, opts, enqueueSnackbar]);
    return null;
  };

  ReactDOM.render(
    <StylesProvider injectFirst>
      <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal, vertical}}>
        <ShowSnackbar message={msg} {...options} />
      </SnackbarProvider>
    </StylesProvider>,
    mountPoint
  );
};
