import {axios} from '@front-libs/core';
import {CostTrendByCostCategory, CostTrendByNarrowCategory, CostTrendByRootCategory, TimePeriodType} from './type';

const baseUrl = (hospitalHashId: string) =>
  `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/product_costs`;

export type FetchCostTrendsByCostCategory = {
  displayTimePeriod: TimePeriodType;
  paymentDateTo: string;
};

export type FetchCostTrendsByCostCategoryResult = {
  data: CostTrendByCostCategory[];
  paymentDateFrom: Date;
  paymentDateTo: Date;
};

export const fetchCostTrendsByCostCategory = async (hospitalHashId: string, params: FetchCostTrendsByCostCategory) => {
  const costTrendsUrl = `${baseUrl(hospitalHashId)}/trends/cost_category`;
  const res = await axios.get<FetchCostTrendsByCostCategoryResult>(costTrendsUrl, {params});
  return res.data;
};

export type FetchCostTrendsByRootCategory = {
  displayTimePeriod: TimePeriodType;
  paymentDateTo: string;
};

export type FetchCostTrendsByRootCategoryResult = {
  data: CostTrendByRootCategory[];
  paymentDateFrom: Date;
  paymentDateTo: Date;
};

export const fetchCostTrendsByRootCategory = async (hospitalHashId: string, params: FetchCostTrendsByRootCategory) => {
  const costTrendsUrl = `${baseUrl(hospitalHashId)}/trends/root_category`;
  const res = await axios.get<FetchCostTrendsByRootCategoryResult>(costTrendsUrl, {params});
  return res.data;
};

export type FetchCostTrendsByNarrowCategory = {
  displayTimePeriod: TimePeriodType;
  paymentDateTo: string;
  rootCategoryHashId: string;
};

export type FetchCostTrendsByNarrowCategoryResult = {
  data: CostTrendByNarrowCategory[];
  paymentDateFrom: Date;
  paymentDateTo: Date;
};

export const fetchCostTrendsByNarrowCategory = async (
  hospitalHashId: string,
  params: FetchCostTrendsByNarrowCategory
) => {
  const costTrendsUrl = `${baseUrl(hospitalHashId)}/trends/narrow_category`;
  const res = await axios.get<FetchCostTrendsByNarrowCategoryResult>(costTrendsUrl, {params});
  return res.data;
};
