export type InspectionResultStatus = 'unplanned' | 'skipped' | 'uncompleted' | 'completed' | 'draft';

export type InspectionResultEvaluation = 'passed' | 'failed' | 'invalid';

export const InspectionResultStatuses = ['unplanned', 'skipped', 'uncompleted', 'completed'] as const;

export const InspectionResultEvaluationMenus: {value: InspectionResultEvaluation; label: string}[] = [
  {
    label: '問題なし',
    value: 'passed',
  },
  {
    label: '異常あり',
    value: 'failed',
  },
  {
    label: '無効',
    value: 'invalid',
  },
];
