import {axios} from '@front-libs/core';
import {BaseListResult} from '@front-libs/core';
import {CreateFaultRepairLogParams, FaultRepairLog, UpdateFaultRepairLogParams} from '../types';

export const createFaultRepairLog = async (
  hospitalHashId: string,
  faultRepairHashId: string,
  data: CreateFaultRepairLogParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}/logs`;
  const {data: d} = await axios.post<FaultRepairLog>(baseUrl, data);
  return d;
};

export const updateFaultRepairLog = async (
  hospitalHashId: string,
  faultRepairHashId: string,
  faultRepairLogHashId: string,
  data: UpdateFaultRepairLogParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}/logs/${faultRepairLogHashId}`;
  const {data: d} = await axios.put<FaultRepairLog>(baseUrl, data);
  return d;
};

export const deleteFaultRepairLog = async (
  hospitalHashId: string,
  faultRepairHashId: string,
  faultRepairLogHashId: string
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}/logs/${faultRepairLogHashId}`;
  const {data: d} = await axios.delete<FaultRepairLog>(baseUrl);
  return d;
};

export const listFaultRepairLog = async (
  hospitalHashId: string,
  faultRepairHashId: string,
  params: Partial<{
    page: number;
    perPage: number;
    order: string;
  }>
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}/logs`;
  const {data: d} = await axios.get<BaseListResult<FaultRepairLog>>(baseUrl, {params});
  return d;
};

export const listHospitalProductFaultRepairLog = async (
  hospitalHashId: string,
  params: Partial<{
    hospitalProductHashId: string;
    page: number;
    perPage: number;
    order: string;
  }>
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/fault_repair/logs`;
  const {data: d} = await axios.get<BaseListResult<FaultRepairLog>>(baseUrl, {params});
  return d;
};
