import {Badge as MuiBadge, BadgeProps as MuiBadgeProps, createStyles, makeStyles} from '@material-ui/core';
import React from 'react';

export type BadgeProps = MuiBadgeProps;

export const Badge: React.FC<BadgeProps> = (props) => {
  const classes = useStyles();
  return (
    // NOTE:overlap="rectangle"` was deprecated.のWarning対応としてoverlap="rectangular"を定義
    <MuiBadge classes={classes} {...props} overlap="rectangular">
      {props.children}
    </MuiBadge>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    badge: {
      border: `1px solid ${theme.palette.common.white}`,
    },
    colorPrimary: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
    colorSecondary: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.dark,
    },
    colorError: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
  })
);
