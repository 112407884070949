import React, {useMemo} from 'react';
import {UserIndex} from '@modules/hospital_users/types';
import {Avatar, Grid, Paper} from '@material-ui/core';
import {UserFormatter} from '@modules/hospital_users/helpers';
import dayjs from 'dayjs';
import {StrUtil} from '@front-libs/helpers';

export type MobileCommentCardProps = {
  user?: UserIndex;
  displayDate?: Date;
  comment: string;
  updateBy?: UserIndex;
  updateAt?: string;
  isArchived?: boolean;
};

export const MobileCommentCard = (props: MobileCommentCardProps) => {
  const {user, comment, displayDate, updateAt, isArchived, updateBy} = props;

  /** 編集済み表示 */
  const editedView = useMemo(() => {
    const currentUpdateAt = updateAt && new Date(updateAt);
    // NOTE:displayDateはcreatedAtから作られている +をつけることでミリ秒に変換できる
    if (!currentUpdateAt || !displayDate || +displayDate === +currentUpdateAt) return null;

    return <span>(編集済み)</span>;
  }, [displayDate, updateAt]);

  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 0,
        borderBottom: '0.5px solid #DDE0E4',
        padding: 16,
        marginLeft: '-16px',
        marginRight: '-16px',
      }}>
      <Grid container alignItems={'center'} justifyContent="space-between" style={{marginBottom: 8}}>
        <Grid item>
          <Grid container style={{gap: 8}} alignItems="center">
            <Grid item>
              <Avatar src={user && user.thumbnailFile?.url} style={{width: 32, height: 32}} />
            </Grid>
            <Grid item style={{fontSize: 16}}>
              {user ? `${UserFormatter.getFullName(user)}` : '不明なユーザー'}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{dayjs(displayDate).format('YYYY/M/D')}</Grid>
      </Grid>
      <Grid container alignItems={'center'} style={{paddingLeft: 40}}>
        <Grid item style={{width: '100%'}}>
          {isArchived
            ? `このコメントは${UserFormatter.getFullName(updateBy)}さんにより削除済みです`
            : StrUtil.nl2br(comment)}
          {editedView}
        </Grid>
      </Grid>
    </Paper>
  );
};
