import React, {useState, useCallback, useMemo, Suspense} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {
  convertDateToSimpleDate,
  convertDateToSimpleDateTime,
  convertDisplaySimpleDate,
  isNullish,
} from '@front-libs/helpers';
import dayjs from 'dayjs';
import {RequiredLabel} from '@molecules/FormRequiredLabel';

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
  fontBold: {
    fontWeight: 700,
  },
});

type FinishInspectionFormProps = {
  open: boolean;
  dueDateOfMakerInspection?: Date;
  onClose: React.MouseEventHandler;
};

type FinishInspectionFormValue = {
  completedAt: string;
  inspector: string;
  nextInspectionDate?: string;
  dueDateOfMakerInspection?: Date;
};

const FinishInspectionForm: React.VFC<FinishInspectionFormProps> = (props) => {
  const {open, onClose} = props;
  const isOpen = typeof open === 'boolean' ? open : false;
  const classes = useStyles();
  const {submitForm, isValid, values, handleChange, setFieldValue} = useFormikContext<FinishInspectionFormValue>();

  const {hospitalUsers} = useHospitalUsers();

  const userOptions = useMemo(
    () => UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}),
    [hospitalUsers]
  );

  const maxDateTime = useMemo(() => {
    // 当日の23時59分まで選択できるようにはしておく
    return convertDateToSimpleDateTime(dayjs().endOf('day').toDate());
  }, []);

  const maxNextInspectionDate = useMemo(() => {
    // 当日の23時59分まで選択できるようにはしておく
    return !isNullish(values.dueDateOfMakerInspection)
      ? convertDateToSimpleDateTime(dayjs(values.dueDateOfMakerInspection).endOf('day').toDate())
      : undefined;
  }, [values.dueDateOfMakerInspection]);

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  if (userOptions.length === 0) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
          点検結果を保存
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.fields} direction="column">
          <Grid>
            <RequiredLabel>点検完了日時</RequiredLabel>
            <TextField
              type="datetime-local"
              name="completedAt"
              value={values.completedAt}
              size="small"
              fullWidth
              InputProps={{
                inputProps: {
                  max: maxDateTime,
                  style: {
                    padding: '10.5px 14px',
                  },
                },
              }}
            />
          </Grid>
          <Grid>
            <label>次回点検予定日</label>
            <TextField
              variant="outlined"
              type="date"
              name="nextInspectionDate"
              value={values.nextInspectionDate}
              size="small"
              fullWidth
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <Close
                    onClick={() => {
                      setFieldValue('nextInspectionDate', '');
                    }}
                    style={{cursor: 'pointer'}}
                  />
                ),
                inputProps: {min: maxDateTime, max: maxNextInspectionDate},
              }}
            />
          </Grid>
          {isNullish(props.dueDateOfMakerInspection) ? (
            <>
              <Grid>
                <label>機器の保守期限</label>
                <TextField
                  variant="outlined"
                  type="date"
                  name="dueDateOfMakerInspection"
                  value={values.dueDateOfMakerInspection}
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  InputProps={{inputProps: {min: new Date()}}}
                />
              </Grid>
              <Grid>
                当機器のメーカー保守点検の期限が設定されていません。
                <br />
                保守期限を設定している場合、次回以降の点検時に保守期限の確認できます。
              </Grid>
            </>
          ) : (
            <Grid>
              保守契約の期限は{' '}
              <span className={classes.fontBold}>
                {convertDisplaySimpleDate(props.dueDateOfMakerInspection, 'YYYY年MM月DD日')}
              </span>{' '}
              です。
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isValid}>
          保存
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type FinishInspectionDialogProps = {
  completedAt: string;
  inspectorHashId: string;
  nextInspectionDate?: Date;
  dueDateOfMakerInspection?: Date;
} & DialogProps;

export type FinishInspectionDialogResult = {
  completedAt: Date;
  inspector: string;
  nextInspectionDate?: Date;
  dueDateOfMakerInspection?: Date;
};

export const FinishInspectionDialog: React.FC<FinishInspectionDialogProps> = (props) => {
  const {open, completedAt, inspectorHashId, dueDateOfMakerInspection, nextInspectionDate} = props;
  // NOTE:undefinedが代入されることがあり、ConsoleErrorが出るので暫定対応
  const isOpen = isNullish(open) ? false : open;
  const [validationSchema] = useState(() =>
    yup.object({
      completedAt: yup.string().required(),
      inspector: yup.string().required(),
      nextInspectionDate: yup.string().nullable(), // 非必須 かつ null値がある
      dueDateOfMakerInspection: yup.date().nullable(), // 非必須 かつ null値がある
    })
  );

  const handleSubmit = useCallback(
    async (data: FinishInspectionFormValue) => {
      // eslint-disable-next-line no-shadow
      const {completedAt, inspector} = data;
      props.actions.resolve({
        completedAt: completedAt,
        inspector: inspector,
        nextInspectionDate: data.nextInspectionDate === '' ? undefined : data.nextInspectionDate,
        dueDateOfMakerInspection: data.dueDateOfMakerInspection,
      });
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={{
          completedAt: completedAt,
          inspector: inspectorHashId,
          nextInspectionDate: convertDateToSimpleDate(nextInspectionDate ?? dayjs().add(1, 'year').toDate()),
          dueDateOfMakerInspection: dueDateOfMakerInspection,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={false}>
        <Form>
          <FinishInspectionForm
            open={isOpen}
            onClose={handleClose}
            dueDateOfMakerInspection={dueDateOfMakerInspection}
          />
        </Form>
      </Formik>
    </Suspense>
  );
};
