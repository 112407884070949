import {useMemo} from 'react';
import {useQuery} from 'react-query';
import dayjs from 'dayjs';
import {InspectionResultIndex} from './types';
import {getNextInspectionResultKey} from '@constants/api';
import {getInspectionResults} from './api';
import {convertDateToRFC3339} from '@front-libs/helpers';

export const useNextInspectionResult = (inspectionResult: InspectionResultIndex) => {
  const scheduledAtFrom = useMemo(() => {
    const base = inspectionResult.scheduledTime
      ? dayjs(inspectionResult.scheduledTime).add(1, 'second').toDate()
      : new Date();

    return convertDateToRFC3339(base);
  }, [inspectionResult.scheduledTime]);

  return useQuery([getNextInspectionResultKey, inspectionResult.hospitalHashId, inspectionResult.hashId], async () => {
    // XXX: とりあえず定期点検だけ
    if (inspectionResult.type !== 'periodic') {
        return null;
    }

    return getInspectionResults(inspectionResult.hospitalHashId, inspectionResult.inspectionHashId, {
      perPage: 1,
      order: 'scheduledTime',
      hospitalProductHashId: inspectionResult.hospitalProductHashId,
      types: inspectionResult.type,
      statuses: 'unplanned,uncompleted',
      scheduledAtFrom: scheduledAtFrom,
    });
  });
};
