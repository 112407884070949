import {useCallback, useEffect, useRef} from 'react';

export type UserActionOption = 'click' | 'mousemove';

export const useInactiveCallback = (
  thresholdInSec: number | null,
  cb: () => void,
  userActions: UserActionOption[] = ['click', 'mousemove']
) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const startTimer = useCallback(() => {
    if (thresholdInSec === null || thresholdInSec === 0) {
      return;
    }

    timerRef.current = setTimeout(cb, thresholdInSec * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdInSec]);

  const stopTimer = useCallback(() => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const restartTimer = useCallback(() => {
    stopTimer();
    startTimer();
  }, [startTimer, stopTimer]);

  useEffect(() => {
    restartTimer();
  }, [restartTimer]);

  useEffect(() => {
    const callback = () => {
      restartTimer();
    };

    userActions.forEach((event) => {
      window.addEventListener(event, callback);
    });

    return () => {
      userActions.forEach((event) => {
        window.removeEventListener(event, callback);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartTimer, userActions.join(',')]);
};
