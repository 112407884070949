import React from 'react';
import {Selector} from '@molecules/Selector';
import {Worksheet} from 'exceljs';

type Props = {
  worksheetOptions: {label: string; value: number}[];
  selectedWorksheet: Worksheet | undefined;
  onChangeWorksheet: (sheetOpt: {label: string; value: number}) => void;
};

export const WorksheetSelector: React.FC<Props> = ({worksheetOptions, selectedWorksheet, onChangeWorksheet}) => {
  return (
    <Selector
      size="small"
      placeholder="シート"
      options={worksheetOptions}
      value={selectedWorksheet?.id ?? -1}
      onChange={onChangeWorksheet}
    />
  );
};
