import {Box} from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Header, SelectedHeaderTab} from './Header';
import {Form, Formik} from 'formik';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {isNullish} from '@front-libs/helpers';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
// FIXME 共通化したい。時間がないので、pcから直接読み込む
import {recordToFormValue} from '../../pc/common/mapper';
import {getFormSchema} from '../../pc/common/validator';
import {InspectionResultContent} from './InspectionResultContent';
import {useLocation} from 'react-router-dom';
import {
  NextUpdateInspectionStatusParam,
  createInspectionResultFiles,
  useFetchInspectionResultFilesQuery,
} from '@modules/inspection_results/api';
import {FileIndex} from '@modules/files/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FileListContainer} from './FileListContainer';
import {styled} from '@material-ui/core/styles';

const StyledHeaderDiv = styled('div')({
  position: 'fixed',
  zIndex: 1,
  width: '100%',
  top: 0,
});

const StyledContainsDiv = styled(({top, ...otherProps}) => <div {...otherProps} />)(({top}: {top: number}) => ({
  height: `${top}px`,
  width: '100%',
}));

export const EditInspectionResult: React.FC<EditInspectionResultProps> = (props) => {
  const {inspection, inspectionTable, inspectionResult} = props;
  const location = useLocation();
  const nextUpdateInspectionStatusParam = location.state as NextUpdateInspectionStatusParam;
  const [currentDisplay, setCurrentDisplay] = useState<SelectedHeaderTab>('InspectionItems');
  const {myInfo} = useMyInfo();
  const divRef = useRef<HTMLDivElement>(null);
  const [margin, setMargin] = useState(125);

  useEffect(() => {
    if (divRef.current) {
      // NOTE:Dom生成のタイミングでHeightが適用されないことがあるので少しずらす
      setTimeout(() => {
        if (!divRef.current) return;
        setMargin(divRef.current.offsetHeight);
      }, 50);
    }
  }, []);

  const {data: files = [], refetch} = useFetchInspectionResultFilesQuery(
    myInfo.hospitalHashId,
    inspectionResult.inspectionHashId,
    inspectionResult.hashId
  );

  const handleUploadFile = useCallback(
    async (file: FileIndex) => {
      try {
        await createInspectionResultFiles(
          myInfo.hospitalHashId,
          inspectionResult.inspectionHashId,
          inspectionResult.hashId,
          {
            fileHashIds: [file.hashId],
          }
        );
        refetch();
      } catch (err) {
        console.error(err);
      }
    },
    [inspectionResult.hashId, inspectionResult.inspectionHashId, myInfo.hospitalHashId, refetch]
  );
  const handleTabChange = useCallback((selectedTab: SelectedHeaderTab) => {
    setCurrentDisplay(selectedTab);
  }, []);

  const initialValues = useMemo(() => {
    return recordToFormValue(inspectionTable, inspectionResult, inspection.status === 'draft');
  }, [inspection, inspectionTable, inspectionResult]);

  const validationSchema = useMemo(() => {
    if (!inspectionTable) return null;

    return getFormSchema(inspectionTable?.items ?? []);
  }, [inspectionTable]);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnMount={true}
        isInitialValid={false}
        validateOnBlur={true}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {}}>
        <Form>
          <StyledHeaderDiv ref={divRef}>
            <Header
              inspection={inspection}
              inspectionTable={inspectionTable}
              inspectionResult={inspectionResult}
              nextUpdateInspectionStatusParam={nextUpdateInspectionStatusParam}
              selectedCallBack={handleTabChange}
            />
          </StyledHeaderDiv>
          <StyledContainsDiv top={margin} />
          {!isNullish(inspectionResult) && currentDisplay === 'InspectionItems' ? (
            <InspectionResultContent inspectionTable={inspectionTable} />
          ) : (
            <FileListContainer files={files} handleUploadFile={handleUploadFile} />
          )}
        </Form>
      </Formik>
    </Box>
  );
};

type EditInspectionResultProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
};
