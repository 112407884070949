import {atom} from 'jotai';

const LOCAL_STORAGE_KEY_ZONE_FILTER_STATUS = 'hitotsu/zone_filter_status';
const getZoneFilterStatus = (): string[] => {
  let json = localStorage.getItem(LOCAL_STORAGE_KEY_ZONE_FILTER_STATUS);
  if (!json) {
    json = JSON.stringify([]);
    localStorage.setItem(LOCAL_STORAGE_KEY_ZONE_FILTER_STATUS, json);
  }
  return JSON.parse(json);
};

const setZoneFilterStatus = (newStatus: string[]) => {
  const json = JSON.stringify(newStatus);
  localStorage.setItem(LOCAL_STORAGE_KEY_ZONE_FILTER_STATUS, json);
};

const zoneFilterStatusAtom = atom<string[]>(getZoneFilterStatus());

export const zoneFilterStatus = atom(
  (get) => get(zoneFilterStatusAtom),
  (_, set, newStatus: string[]) => {
    set(zoneFilterStatusAtom, newStatus);
    setZoneFilterStatus(newStatus);
  }
);

export const defaultChannelHashIdAtom = atom<string>('');
export const dialogChannelNumberAtom = atom<number | null>(null);
export const isOpenChannelDialogAtom = atom<boolean>(false);
