import {DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {styled} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {PDFDisplay} from '@molecules/Utils/PDFDisplay/sp';
import React, {useMemo} from 'react';

type Props = {
  fileName: string;
  fileType: string;
  url: string;
  title: string;
} & DialogProps;

const StyledImg = styled('img')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
});
const StyledDiv = styled('div')({
  width: '100%',
  height: '100%',
});

const StyledDialogTitle = styled(DialogTitle)({
  padding: '6px',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiTypography-root ': {
    fontWeight: 'bold',
    textAlign: 'center',
    width: '82%',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  '& .MuiIconButton-root ': {
    color: '#172B4D', // FIXME:後ほどThemeから呼び出す
    position: 'absolute',
    top: '-2px',
    left: '-2px',
  },
});

const FullScreenDialog = styled(Dialog)({
  height: 'auto',
  '& .MuiDialog-paper': {
    width: '100%',
    minHeight: '100%',
    margin: 0,
    borderRadius: 0,
  },
});
const StyledScrollDiv = styled('div')({
  overflow: 'scroll',
  height: '100%',
  width: '100%',
});

const StyledImageContainer = styled('div')({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: '100%',
  padding: 0,
  overflow: 'hidden',
});
const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  height: '100vh',
});

/**
 *
 * スマートフォン用 画像とPDFのファイルプレビュー
 *
 * @see front-app-pc/src/components/molecules/Dialogs/FilePreviewDialog.tsx
 * @example
 * ```tsx
 * const data: FileIndex;
 * await dialogHandler.open(FilePreviewDialog, {
 *         fileName: data.fileName,
 *         fileType: data.fileType,
 *         title: data.fileName,
 *         url: data.url,
 *       });
 * ```
 * @param props
 * @returns
 */
export const FilePreviewDialog: React.FC<Props> = (props) => {
  const {
    open,
    actions: {reject},
    fileName,
    fileType,
    url,
    title,
    actions,
  } = props;

  const preview = useMemo(() => {
    if (fileType.match('application/pdf')) {
      return (
        <StyledScrollDiv>
          <PDFDisplay src={url} />
        </StyledScrollDiv>
      );
    } else if (fileType.match('image/*')) {
      return (
        <StyledImageContainer>
          <StyledImg src={url} alt={fileName} />
        </StyledImageContainer>
      );
    } else {
      return <StyledDiv>この形式のファイルはプレビューできません。</StyledDiv>;
    }
  }, [fileName, fileType, url]);

  return (
    <FullScreenDialog open={open} onClose={reject}>
      <StyledDialogTitle>
        <IconButton aria-label="close" onClick={actions.resolve}>
          <CloseIcon />
        </IconButton>
        {title}
      </StyledDialogTitle>
      <StyledDialogContent>{preview}</StyledDialogContent>
    </FullScreenDialog>
  );
};
