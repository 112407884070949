import {CostCategoryType} from '@modules/hospital_product_costs';

// グラフの表示は下から順番にスタックされるように表示される
export const costCategory: {[key in CostCategoryType]: {name: string; color: string}} = {
  disposal_cost: {name: '廃棄', color: '#AFBBCF'},
  repair_cost: {name: '修理', color: '#7D8FAD'},
  inspection_cost: {name: '点検', color: '#4A5D7D'},
  maintenance_cost: {name: '保守', color: '#253858'},
  lease_cost: {name: 'リース', color: '#A5C9FD'},
  rental_cost: {name: 'レンタル', color: '#689DEB'},
  installation_cost: {name: '設置', color: '#3A77D2'},
  purchase_cost: {name: '購入', color: '#0747A6'},
};
