import React from 'react';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {UserIndex} from '@modules/hospital_users/types';

export type InspectionUpdatedByColumnProps = {
  updatedAt: string;
  updatedBy?: UserIndex;
};

export const InspectionUpdatedByColumn: React.VFC<{updatedAt: string; updatedBy?: UserIndex}> = (props) => {
  const {updatedAt, updatedBy} = props;
  const fullName = updatedBy ? UserFormatter.getFullName(updatedBy) : '';

  return (
    <span>
      {updatedAt}
      <br />
      {fullName}
    </span>
  );
};
