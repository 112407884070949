import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePreviousLocation} from './usePreviousLocation';

// 前のページに戻るための関数を返すフック
// 直前のページが移動したいページと一致する場合は単純に戻る
// そうでない場合は通常のページ遷移
export const useBackPrevious = (pathname: string) => {
  const prevLocation = usePreviousLocation();
  const navigate = useNavigate();

  return useCallback(() => {
    // 前のページのパスが移動先パスと同じ場合は単に戻るだけ
    if (pathname === prevLocation?.pathname) {
      navigate(-1);
    } else {
      navigate(pathname);
    }
  }, [navigate, pathname, prevLocation?.pathname]);
};
