import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {createStyles, Grid, makeStyles, TextField, Theme, useMediaQuery} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {useAtom} from 'jotai';
import {
  searchSymptomCategoriesAtom,
  searchNameAtom,
  searchReq4RepairAtFromAtom,
  searchReq4RepairAtToAtom,
  searchPersonInChargeHashIDsAtom,
  repairListPageAtom,
} from '../jotai';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {openSnackBar} from '@molecules/SnackBar';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {DatePicker} from '@molecules/DatePicker';
import {symptomCategoryOptions} from '@modules/repairs/constants';
import {SymptomCategoryType} from '@modules/repairs/types';
import {useDebounceCallback} from '@front-libs/core';
import {useResetAtom} from 'jotai/utils';
import dayjs from 'dayjs';

const actionMenuItems = [
  // {
  //   label: 'エクスポート',
  //   value: 'export',
  // },
  {
    label: '表示項目を変更',
    value: 'changeLayout',
  },
];

type ListToolbarProp = {
  onChangeTableLayout: (layout: TableLayoutResult) => void;
};

export const ListToolbar: React.VFC<ListToolbarProp> = (prop) => {
  const {onChangeTableLayout} = prop;
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1024px)');
  const {hospitalUsers} = useHospitalUsers();
  const [tableLayout] = useTableLayout(
    'repairList',
    useMemo(
      () => ({
        managementId: true,
        symptomCategory: true,
      }),
      []
    )
  );

  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const resetRepairListPage = useResetAtom(repairListPageAtom);
  const [personInChargeHashIDs, setPersonInChargeHashIDs] = useAtom(searchPersonInChargeHashIDsAtom);
  const [initialPersonInChargeHashIDs, setInitialPersonInChargeHashIDs] = useState<SelectOptionProps[] | undefined>(
    undefined
  );
  const [req4RepairAtFrom, setReq4RepairAtFrom] = useAtom(searchReq4RepairAtFromAtom);
  const [req4RepairAtTo, setReq4RepairAtTo] = useAtom(searchReq4RepairAtToAtom);
  const [symptomCategories, setSymptomCategories] = useAtom(searchSymptomCategoriesAtom);

  const handleChangeName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) setSearchName(e.target.value);
      else setSearchName(undefined);
      resetRepairListPage();
    },
    300,
    [resetRepairListPage, setSearchName]
  );

  const handleChangePic = useCallback(
    (selectedValues?: SelectOptionProps[]) => {
      setPersonInChargeHashIDs(selectedValues?.map((item) => item.value) || []);
      resetRepairListPage();
    },
    [setPersonInChargeHashIDs, resetRepairListPage]
  );

  const handleChangeReq4RepairAtFrom = useCallback(
    (date: Date | null) => {
      setReq4RepairAtFrom(date ? dayjs(date).startOf('day').toDate() : date);
      resetRepairListPage();
    },
    [resetRepairListPage, setReq4RepairAtFrom]
  );

  const handleChangeReq4RepairAtTo = useCallback(
    (date: Date | null) => {
      setReq4RepairAtTo(date);
      resetRepairListPage();
    },
    [resetRepairListPage, setReq4RepairAtTo]
  );

  const handleChangeSymptomCategory = useCallback(
    (values?: SelectOptionProps[]) => {
      setSymptomCategories(values?.map((item) => item.value as SymptomCategoryType) || []);
      resetRepairListPage();
    },
    [resetRepairListPage, setSymptomCategories]
  );

  const handleExport = useCallback(() => {
    openSnackBar('ファイルのエクスポートが完了しました', 'center', 'top');
  }, []);

  const handleChangeLayout = useCallback(async () => {
    try {
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: tableLayout?.tableLayout ?? [],
        defaultOptions: tableLayout?.currentLayout ?? [],
        forceValue: tableLayout?.forceValue ?? {},
      });
      const newTableLayout = {
        tableLayout: tableLayout?.tableLayout ?? [],
        currentLayout: currentLayout,
      };
      onChangeTableLayout(newTableLayout);
    } catch (_e) {
      console.log(_e);
    }
  }, [onChangeTableLayout, tableLayout]);

  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'export':
          handleExport();
          break;

        case 'changeLayout':
          handleChangeLayout();
          break;
      }
    },
    [handleChangeLayout, handleExport]
  );

  useEffect(() => {
    if (personInChargeHashIDs.length > 0) {
      const initOptions = UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}).filter((i) =>
        personInChargeHashIDs.includes(i.value)
      );
      setInitialPersonInChargeHashIDs(initOptions);
    }
  }, [hospitalUsers, personInChargeHashIDs]);

  return (
    <Grid container>
      <Grid item sm={4} md={3} className={classes.filterContainer}>
        <TextField
          label={'機種名・型式・管理番号で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={searchName}
          className={classes.searchText}
          onChange={handleChangeName}
        />
      </Grid>
      <Grid item>
        <PopperSelectBoxButton
          buttonLabel={'担当者'}
          options={UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true})}
          initialOption={initialPersonInChargeHashIDs}
          isMulti={true}
          onChange={handleChangePic}
          searchable={true}
        />
      </Grid>
      {!matches && (
        <Grid item className={classes.dateSelector}>
          <span className={classes.date}>修理受付日時: </span>
          <DatePicker
            value={req4RepairAtFrom}
            placeholder="日付を選択"
            disableFuture={true}
            maxDate={req4RepairAtTo ?? undefined}
            onChange={handleChangeReq4RepairAtFrom}
          />
          <span>から</span>
          <DatePicker
            value={req4RepairAtTo}
            placeholder="日付を選択"
            disableFuture={true}
            minDate={req4RepairAtFrom ?? undefined}
            onChange={handleChangeReq4RepairAtTo}
          />
        </Grid>
      )}
      <Grid item>
        <PopperSelectBoxButton
          buttonLabel={'事象区分'}
          options={symptomCategoryOptions}
          initialOption={symptomCategoryOptions.filter((i) =>
            symptomCategories.includes(i.value as SymptomCategoryType)
          )}
          isMulti={true}
          onChange={handleChangeSymptomCategory}
          searchable={true}
        />
      </Grid>
      <div className={classes.flex} />
      {/* <Grid item className={classes.filterContainer}>
        <Button color="inherit">
          <Tune />
          <span className={classes.actionMenu}>詳細で絞り込む</span>
        </Button>
      </Grid> */}
      <Grid item className={classes.actionBtnContainer}>
        <PopperMenuButton
          buttonProps={{variant: 'contained', disableElevation: true, className: classes.actionBtn}}
          menuItemList={actionMenuItems}
          onMenuClick={handleActionMenuClick}>
          アクション
        </PopperMenuButton>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      marginRight: '32px',
    },
    flex: {
      flexGrow: 1,
    },
    actionMenu: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      marginLeft: '8px',
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    dateSelector: {
      marginTop: '-4px',
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      marginLeft: '16px',
      fontSize: 14,
    },
    actionBtnContainer: {
      minWidth: '150px',
    },
    actionBtn: {
      width: '100%',
      background: 'rgba(9, 30, 66, 0.04)',
      border: '1px solid #C6CBD4',
    },
  })
);
