import React, {useCallback} from 'react';
import {TimePeriodType} from '@modules/hospital_product_costs';
import {Grid, styled} from '@material-ui/core';
import {ContentTitle} from '../ContentTitle';
import {DurationButton} from '../../components/DurationButton';
import {SelectedViewType} from '../types';
import {NextPreviousButton} from '../NextPreviousButton';
import {useSelectedRootCategoryHashId} from '../states';
import {RootCategorySelector} from '../../components/RootCategorySelector';
import {useRootCategories} from '../../components/hooks';

const displayTimePeriodOptions: {value: TimePeriodType; label: string}[] = [
  {
    value: 'month',
    label: '月',
  },
  {
    value: 'quarter',
    label: '四半期',
  },
  {
    value: 'year',
    label: '年',
  },
];

export type ToolbarProps = {
  selectView: SelectedViewType;
  displayTimePeriod: TimePeriodType;
  paymentDateTo: Date;
  onChangeSelectView: (value: SelectedViewType) => void;
  onChangeDisplayTimePeriod: (value: TimePeriodType) => void;
  onChangePaymentDateTo: (direction: 'next' | 'previous') => void;
};

export const Toolbar: React.FC<ToolbarProps> = ({
  selectView,
  displayTimePeriod,
  onChangeSelectView,
  onChangeDisplayTimePeriod,
  onChangePaymentDateTo,
}) => {
  const onClickNextPreviousButton = useCallback(
    (buttonType: 'next' | 'previous') => {
      onChangePaymentDateTo(buttonType);
    },
    [onChangePaymentDateTo]
  );

  const {rootCategoryOptions} = useRootCategories();
  const [rootCategoryHashId, setRootCategoryHashId] = useSelectedRootCategoryHashId();

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Grid container>
          <Grid item>
            <ContentTitle currentSelectedViewType={selectView} onChangeContent={onChangeSelectView} />
          </Grid>
          {selectView === 'narrowCategory' && (
            <Grid item>
              <RootCategorySelector
                rootCategoryOptions={rootCategoryOptions}
                currentValue={rootCategoryHashId}
                onChangeHashId={setRootCategoryHashId}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <ToolBarGrid item>
            <DurationButton
              value={displayTimePeriod}
              buttonGroup={displayTimePeriodOptions}
              handleChange={(value) => onChangeDisplayTimePeriod(value as TimePeriodType)}
            />
          </ToolBarGrid>
          <ToolBarGrid item>
            <NextPreviousButton
              isDisabledNext={false}
              isDisabledPrevious={false}
              onClickButton={onClickNextPreviousButton}
            />
          </ToolBarGrid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ToolBarGrid = styled(Grid)({
  marginRight: 42,
});
