import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@material-ui/core';
import React, {Suspense, useCallback, useMemo, useState} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import {Checkbox, TextField} from '@molecules/Formik/fields';
import {yup} from '@front-libs/core';
import Selector from '@molecules/Formik/fields/Selector';
import {fetchHospitalWards} from '@modules/hospital_wards/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalWard} from '@modules/hospital_wards/types';
import {useAsyncEffect} from '@front-libs/core';
import {EditHospitalRoomType} from '@Apps/Settings/Place/types';
import {RequiredLabel} from '@molecules/FormRequiredLabel';

type OptionProps = {value: string; label: string};
type HospitalRoomFormProps = {
  onSelectHospitalRoom: (options: OptionProps) => void;
};

const RegistrationForm: React.VFC<HospitalRoomFormProps> = ({onSelectHospitalRoom}) => {
  const {myInfo} = useMyInfo();

  const [hospitalSettings, setHospitalSettings] = useState<HospitalWard[]>([]);
  useAsyncEffect(async () => {
    const res = await fetchHospitalWards(myInfo.hospitalHashId, {
      page: 0,
      perPage: 100,
    });
    setHospitalSettings(res.data);
  }, []);

  const hospitalRoomsOpts = useMemo(() => {
    return hospitalSettings.map((item) => ({
      value: item.hashId,
      label: item.name,
    }));
  }, [hospitalSettings]);

  const handleChangeWholeProduct = useCallback(
    (e: OptionProps) => {
      onSelectHospitalRoom(e);
    },
    [onSelectHospitalRoom]
  );

  return (
    <div style={{width: '100%', marginBottom: '16px'}}>
      <RequiredLabel>大エリア</RequiredLabel>
      <Selector
        name="hospitalWardHashId"
        size={'small'}
        options={hospitalRoomsOpts}
        onChange={handleChangeWholeProduct}
      />
    </div>
  );
};

const HospitalRoomForm: React.VFC<DialogProps> = (props) => {
  const {submitForm, setFieldValue, isValid} = useFormikContext();
  const {values}: {values: EditHospitalRoomType} = useFormikContext();

  const handleClickResist = useCallback(async () => {
    await submitForm();
    await props.actions.resolve(values);
  }, [props.actions, submitForm, values]);

  return (
    <Dialog
      open={props.open ?? false}
      onClose={props.actions.reject}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'md'}>
      <DialogTitle>小エリアを新規追加</DialogTitle>
      <DialogContent>
        <Grid container>
          <Form style={{width: '100%'}}>
            <Suspense fallback={null}>
              <RegistrationForm
                onSelectHospitalRoom={(options) => {
                  setFieldValue('hospitalWardHashId', options.value);
                }}
              />
            </Suspense>
            <Grid container style={{marginBottom: '16px'}} spacing={2} alignContent={'center'}>
              <Grid item xs={6}>
                <RequiredLabel>階</RequiredLabel>
                <Selector
                  name="isGroundFloor"
                  size={'small'}
                  options={[
                    {label: '地上', value: true},
                    {label: '地下', value: false},
                  ]}
                />
              </Grid>
              <Grid container style={{marginTop: '24px', display: 'flex'}} alignItems={'center'} xs={6}>
                <Grid item style={{width: 'calc(100% - 58px)'}}>
                  <TextField
                    type="number"
                    placeholder="2"
                    name="floorNumber"
                    size={'small'}
                    inputProps={{min: 1}}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Typography style={{paddingLeft: '20px', paddingRight: '20px'}}>階</Typography>
                </Grid>
              </Grid>
            </Grid>
            <div style={{marginBottom: '16px'}}>
              <RequiredLabel>小エリア名</RequiredLabel>
              <TextField placeholder="手術室" name="name" size={'small'} fullWidth />
            </div>
            <div style={{marginBottom: '16px'}}>
              <Checkbox name="showRentalPlace" label="無人貸出の貸出先に表示する" />
            </div>
          </Form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={handleClickResist}>
          登録
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NewRegistrationDialog: React.VFC<DialogProps> = (props) => {
  const validationSchema = yup.lazy(() => {
    return yup.object({
      name: yup.string().required(),
      floorNumber: yup.number().required().positive(),
      hospitalWardHashId: yup.string().required(),
    });
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (res: any) => {
    await props.actions.resolve(res);
  };
  const initialValues: EditHospitalRoomType = useMemo(
    () => ({
      name: '',
      showRentalPlace: false,
      isGroundFloor: true,
      floorNumber: 1,
      hospitalWardHashId: '',
    }),
    []
  );
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}>
        <HospitalRoomForm {...props} />
      </Formik>
    </Suspense>
  );
};
