import React from 'react';
import {DialogProps as MuiDialogProps} from '@molecules/Dialogs/DialogHandler';
import {yup} from '@front-libs/core';
import {Form, Formik, useFormikContext} from 'formik';
import {Dialog} from '@atoms/Dialog/Dialog';
import {Button, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField} from '@material-ui/core';
import {HospitalRentalSetting} from '@modules/hospital_settings/types';

type DialogProps = {
  showNotification: boolean;
  lenderSetting: HospitalRentalSetting;
} & MuiDialogProps;

type BulkReturnRentalProductsFormProps = {
  returnDeviceLenderName?: string;
};

type BulkReturnProductsFormTypeProps = {
  lenderSetting: HospitalRentalSetting;
};

const BulkReturnProductsForm: React.FC<BulkReturnProductsFormTypeProps> = ({lenderSetting}) => {
  const {values, handleChange} = useFormikContext<BulkReturnRentalProductsFormProps>();

  return (
    <Form>
      <Grid style={{marginBottom: 16}}>
        {lenderSetting !== 'not_need' ? (
          <>
            <label>返却者</label>
            <TextField
              fullWidth
              id="returnDeviceLenderName"
              name="returnDeviceLenderName"
              type="text"
              variant="outlined"
              size={'small'}
              value={values.returnDeviceLenderName}
              onChange={handleChange}
            />
          </>
        ) : (
          <span>返却処理を実行します。よろしいですか？</span>
        )}
      </Grid>
    </Form>
  );
};

export const BulkReturnRentalProductsDialog: React.VFC<DialogProps> = (props) => {
  const classes = useStyles();
  const validationSchema = yup.object({
    returnDeviceLenderName: yup.string(),
  });

  const handleSubmit = (res: BulkReturnRentalProductsFormProps) => {
    props.actions.resolve(res);
  };

  const handleClose = () => {
    props.actions.reject();
  };

  return (
    <Formik<BulkReturnRentalProductsFormProps>
      initialValues={{}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialValid={true}>
      {(formik) => (
        <Dialog {...props}>
          <DialogTitle id="bulk-rent-dialog-title">返却登録</DialogTitle>
          <DialogContent>
            <BulkReturnProductsForm lenderSetting={props.lenderSetting} />
            {props.showNotification && <Grid xs={12}>返却済みの行は無視されます。</Grid>}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={() => handleSubmit(formik.values)}
              color="primary"
              variant="contained">
              登録
            </Button>
            <Button disabled={formik.isSubmitting} onClick={handleClose}>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'start',
    padding: '16px 24px 24px',
  },
  textFieldTitle: {
    marginTop: 0,
    marginBottom: 8,
  },
  information: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: '8px',
  },
  placeText: {
    padding: '12px',
    color: theme.palette.secondary.dark,
    position: 'relative',
    bottom: '3px',
  },
  exclamation: {
    position: 'relative',
    top: '6px',
    paddingRight: '4px',
  },
}));
