import {useAtomValue} from 'jotai';
import {useQuery} from 'react-query';
import {getMakerInspectionResults} from '@modules/maker_inspection_results/api';
import {currentMakerInspectionResultStatusAtom, pageSizeSelectAtom, searchVariablesAtom} from './state';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {MakerInspectionResultIndex} from '@modules/maker_inspection_results/types';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {MakerInspectionResultListElement, MakerInspectionResultStatus} from './types';
import {useMemo} from 'react';
import {Column} from '@molecules/Table/props';
import {InspectionResultStatusBadge} from '@atoms/InspectionResultStatusBadge';
import {ManagementIDColumn} from './columns/ManagementIDColumn';
import {convertDisplaySimpleDate, isNullish, StrUtil} from '@front-libs/helpers';
import {CategoryFormatter} from '@modules/categories/helpers';

export const useFetchMakerInspectionResultsQuery = () => {
  const {myInfo} = useMyInfo();
  const searchVariable = useAtomValue(searchVariablesAtom);
  const currentStatus = useAtomValue(currentMakerInspectionResultStatusAtom);
  return useQuery(
    ['useFetchMakerInspectionResultsQuery', myInfo.hospitalHashId, searchVariable, currentStatus],
    async () => {
      const result = await getMakerInspectionResults(myInfo.hospitalHashId, {
        status: currentStatus === 'all' ? undefined : currentStatus,
        name: searchVariable.name || undefined,
        order:
          searchVariable.order === null
            ? currentStatus === 'completed'
              ? '-completedAt'
              : 'management_id'
            : searchVariable.order || undefined,
        page: searchVariable.page,
        perPage: searchVariable.perPage,
      });

      return result.data;
    }
  );
};

export const useTableData = (
  resultStatus: MakerInspectionResultStatus,
  queryData: MakerInspectionResultIndex[],
  totalCount: number
) => {
  const pageSize = useAtomValue(pageSizeSelectAtom);
  const [tableLayout] = useTableLayout(
    'makerInspectionResultList',
    useMemo(
      () => ({
        completedAt: resultStatus === 'completed' || resultStatus === 'all',
        skipReason: resultStatus === 'skipped' || resultStatus === 'all',
        inspector: resultStatus !== 'unplanned',
      }),
      [resultStatus]
    )
  );

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableColumn = Object.assign<Column<any>[], TableLayout[]>([], tableLayout?.currentLayout ?? []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return tableColumn.map<Column<any>>((item) => {
      switch (item.field) {
        case 'status':
          item.render = InspectionResultStatusBadge;
          break;
        case 'managementId':
          item.render = ManagementIDColumn;
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout?.currentLayout]);

  const rows = useMemo<MakerInspectionResultListElement[]>(() => {
    return (queryData ?? []).map(
      (i): MakerInspectionResultListElement => ({
        hashId: i.hashId,
        hospitalProductHashId: i.hospitalProduct.hashId,
        status: i.status,
        completedAt: !isNullish(i.completedAt) ? convertDisplaySimpleDate(i.completedAt, 'YYYY/MM/DD HH:mm') : '',
        scheduledTime: !isNullish(i.scheduledTime) ? convertDisplaySimpleDate(i.scheduledTime) : '',
        inspector: i.inspector ? `${i.createdBy?.lastName} ${i.createdBy?.firstName}` : '',
        makerName: i.hospitalProduct.maker.name,
        managementId: i.hospitalProduct.managementId,
        serialNumber: i.hospitalProduct.serialNumber,
        rootCategory: CategoryFormatter.getRootCategory(i.hospitalProduct.categories)?.name ?? '',
        narrowCategory: CategoryFormatter.getNarrowCategory(i.hospitalProduct.categories)?.name ?? '',
        displayName: i.hospitalProduct.displayName ?? '',
        name: i.hospitalProduct.name ?? '',
        purchasedDate: i.hospitalProduct?.dateOfPurchase ? i.hospitalProduct.dateOfPurchase.replace(/-/g, '/') : '',
        skipReason: i.skipReason ?? '',
        inspectionFee: !isNullish(i.inspectionFee) ? `${StrUtil.addComma(i.inspectionFee)}円` : '',
        comment: i.comment ?? '',
      })
    );
  }, [queryData]);

  const totalPage = useMemo(() => {
    return Math.ceil((totalCount ?? 0) / pageSize);
  }, [pageSize, totalCount]);

  return {totalPage, columns, rows, tableLayout};
};
