import React, {memo} from 'react';
import {
  BaseField,
  RadioField,
  PullDownField,
  CheckboxField,
  NumericField,
  SingleLineTextField,
  MultiLineTextField,
  DateField,
  TimeField,
} from '@components/molecules/InspectionTableFormItems';
import {ResultItem} from '@modules/inspection_results/types';
import {InspectionItem} from '@modules/inspections/api';

type ItemInnerProps = {
  item: InspectionItem & {id: string};
  resultItem: ResultItem;
};

export const Item: React.FC<ItemInnerProps> = memo((props) => {
  const {item, resultItem} = props;

  if (!item || item.type === null || item.type ==='section' ||item.type ==='bool' || !resultItem) return null;

  return (
    <BaseField item={item}>
      {(() => {
        switch (item.type) {
          case 'select':
            if (item.view === 'radio') {
              return <RadioField options={item.options} isEditable={false} value="" />;
            } else if (item.view === 'pull-down') {
              return <PullDownField options={item.options} isEditable={false} value="" />;
            }
            break;
          case 'multi-select':
            return <CheckboxField options={item.options} isEditable={false} values={{}} />;
          case 'numeric':
            return <NumericField unit={item.unit} isEditable={false} value="" validator={item.validator} />;
          case 'text':
            return <SingleLineTextField placeholder="" isEditable={false} showsError={false} value="" />;
          case 'multiline-text':
            return <MultiLineTextField placeholder="" isEditable={false} showsError={false} value="" />;
          case 'date':
            return <DateField isEditable={false} showsError={false} value="" />;
          case 'time':
            return <TimeField isEditable={false} showsError={false} value="" />;
          default:
            return null;
        }
      })()}
    </BaseField>
  );
});
