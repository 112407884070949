export const sortKey = [
  {label: '管理番号', value: 'managementId'},
  {label: '型式', value: 'name'},
  {label: '機種名', value: 'displayName'},
  {label: '依頼者', value: 'requestUserName'},
  {label: '依頼事項', value: 'requests'},
  {label: '修理受付日時', value: 'requestForRepairAt'},
  {label: '完了年月日', value: 'fixedAt'},
  {label: '担当者', value: 'personInCharge'},
  {label: '事象区分', value: 'symptomCategory'},
  {label: '事象詳細区分', value: 'symptomDetailCategory'},
  {label: '原因区分', value: 'rootCauseCategory'},
  {label: '原因の詳細', value: 'causeOfIssueDetail'},
  {label: '事務への申請内容', value: 'applicationToSuppliesDepartment'},
  {label: '事務への申請年月日', value: 'appliedAtToSuppliesDepartment'},
  {label: '代替の内容', value: 'substitutionDetail'},
  {label: '処理区分', value: 'dealtCategory'},
  {label: '修理金額（円）', value: 'costOfFix'},
  {label: 'メーカー担当者名', value: 'makerPicName'},
  {label: '担当者連絡先（電話番号）', value: 'makerContactTel'},
  {label: '担当者連絡先（メールアドレス）', value: 'makerContactEmail'},
  {label: 'メーカーへの連絡日', value: 'makerContactAt'},
];
