import React from 'react';
import {MobileProductFormField} from '@molecules/MobileProductFormField';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {CategoryFormatter} from '@modules/categories/helpers';
import {isNullish} from '@front-libs/helpers';
import {classNameOpts} from '@Apps/ProductRegistration/constants';
import {InnerLoading} from '@molecules/Loading';
import {styled} from '@material-ui/core/styles';
// const formSection: FormSection[] = [
//   {
//     sectionName: '機器情報',
//     fields: [
//       {
//         type: 'text',
//         label: '管理番号',
//         name: 'managementId',
//       },
//       {
//         type: 'text',
//         label: 'シリアル番号',
//         name: 'serialNumber',
//       },
//       {
//         type: 'text',
//         label: 'ロット番号',
//         name: 'lotNumber',
//       },
//       {
//         type: 'select',
//         label: '貸出区分',
//         name: 'permanentlyAssigned',
//         options: [
//           {label: '貸出不可', value: true},
//           {label: '貸出可', value: false},
//         ],
//       },
//       {
//         type: 'text',
//         label: '稼働状況',
//         name: 'status',
//         options: productStatusOptions,
//       },
//       {
//         type: 'text',
//         label: '機器管理場所',
//         name: 'hospitalRoomHashId',
//       },
//       {
//         type: 'text',
//         label: '貸出場所',
//         name: 'rentHospitalRoomHashId',
//       },
//       {
//         type: 'text',
//         label: '親機・子機',
//         name: 'isBaseUnit',
//         options: [
//           {label: '親機', value: true},
//           {label: '子機', value: false},
//         ],
//       },
//       {
//         type: 'text',
//         label: '購入日',
//         name: 'dateOfPurchase',
//       },
//       {
//         type: 'text',
//         label: '点検開始日',
//         name: 'periodicInspectionStartDate',
//       },
//       {
//         type: 'text',
//         label: 'バーコード読み取り値',
//         name: 'optionalBarcode',
//       },
//       {
//         type: 'text',
//         label: 'GS1バーコード',
//         name: 'gs1Barcode',
//       },
//       // FIXME: 販売代理店を追加する
//     ],
//   },
//   {
//     sectionName: '詳細情報',
//     fields: [
//       {
//         type: 'text',
//         label: '購入区分',
//         name: 'waysOfPurchase',
//         options: [
//           {label: '購入', value: 'purchase'},
//           {label: 'リース', value: 'lease'},
//           {label: 'レンタル', value: 'rental'},
//           {label: '代替品', value: 'alternative'},
//           {label: 'デモ機', value: 'demo'},
//           {label: '寄贈', value: 'gift'},
//           {label: '移管', value: 'transfer_control'},
//         ],
//       },
//       // FIXME: レンタル関連はここ
//       {
//         type: 'select',
//         label: '購入元',
//         name: 'purchasedNationalExpense',
//         InputComponent: StandardSelect,
//         options: [
//           {label: '国費', value: true},
//           {label: '院費', value: false},
//         ],
//       },
//       {
//         type: 'number',
//         label: '納入価',
//         name: 'deliveryPrice',
//         isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'select',
//         label: '税込／税抜き',
//         name: 'taxIncluded',
//         isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
//         InputComponent: StandardSelect,
//         options: [
//           {label: '税込', value: true},
//           {label: '税抜き', value: false},
//         ],
//       },
//       {
//         type: 'number',
//         label: '税率',
//         name: 'taxRate',
//         isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'text',
//         label: '資産番号',
//         name: 'assetRegisterNumber',
//         InputComponent: CreateStandardTextFieldWithFormatter('assetRegisterNumber', formatAssetRegisterNumber),
//       },
//       {
//         type: 'number',
//         label: '院内耐用年数（年）',
//         name: 'legalDurableYear',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'number',
//         label: '残存年数（年）',
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         name: 'remainingYear' as any,
//         readOnly: (product: HospitalProductDetail) => true,
//         getValue: (product: HospitalProductDetail) => {
//           const {legalDurableYear, dateOfPurchase} = product;
//           if (!legalDurableYear || !dateOfPurchase) return '';

//           const now = dayjs();
//           const diff = now.diff(dateOfPurchase, 'year');
//           return Math.max(0, legalDurableYear - diff);
//         },
//         InputComponent: StandardTextField,
//       },
//       // {
//       //   type: 'number',
//       //   label: '減価償却費（円）',
//       //   name: 'depreciationAmount',
//       //   InputComponent: StandardTextField,
//       // },
//       // {
//       //   type: 'number',
//       //   label: '帳簿価格（円）',
//       //   name: 'bookValue',
//       //   InputComponent: StandardTextField,
//       // },
//       {
//         type: 'text',
//         label: '廃棄日',
//         name: 'dateOfDisposal',
//         InputComponent: StandardDateField,
//         clearable: true,
//       },
//       {
//         type: 'textarea',
//         label: '廃棄理由',
//         name: 'reasonOfDisposal',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'textarea',
//         label: '備考1',
//         name: 'notes',
//         InputComponent: StandardTextField,
//         multiline: true,
//       },
//       {
//         type: 'textarea',
//         label: '備考2',
//         name: 'notes2',
//         InputComponent: StandardTextField,
//         multiline: true,
//       },
//       {
//         type: 'textarea',
//         label: '備考3',
//         name: 'notes3',
//         InputComponent: StandardTextField,
//         multiline: true,
//       },
//     ],
//   },
// ];

// const makerInspectionFormSection: FormSection = {
//   sectionName: 'メーカー保守点検設定',
//   fields: [
//     {
//       type: 'text',
//       label: '保守契約',
//       name: 'isMaintenanceContract',
//       InputComponent: StandardSelect,
//       options: [
//         {label: '保守契約', value: true},
//         {label: '保守契約外', value: false},
//       ],
//     },
//     {
//       type: 'text',
//       label: '保守契約期限',
//       name: 'makerInspectionSetting.dueDateOfMakerInspection',
//       InputComponent: StandardDateField,
//       clearable: false,
//     },
//     // {
//     //   type: 'number',
//     //   label: '保守点検間隔（同じ機種に反映されます）',
//     //   name: 'makerInspectionSetting.inspectionPeriod',
//     //   InputComponent: StandardTextField,
//     // },
//     {
//       type: 'text',
//       label: '次回点検予定日',
//       name: 'makerInspectionSetting.nextInspectionDate',
//       InputComponent: StandardDateField,
//     },
//   ],
// };

const fields = [
  {
    type: 'text',
    label: '大分類',
    name: 'rootCategory',
    getValue: (product: HospitalProductDetail) => CategoryFormatter.getRootCategory(product.categories)?.name ?? '',
  },
  {
    type: 'text',
    label: '小分類',
    name: 'narrowCategory',
    getValue: (product: HospitalProductDetail) => CategoryFormatter.getNarrowCategory(product.categories)?.name ?? '',
  },
  {
    type: 'text',
    label: 'メーカー名',
    name: 'maker',
    getValue: (product: HospitalProductDetail) => product.maker?.name ?? '',
  },
  {
    type: 'text',
    label: '機種名',
    name: 'displayName',
    getValue: (product: HospitalProductDetail) => product.displayName ?? '',
  },
  {
    type: 'text',
    label: '型式',
    name: 'name',
    getValue: (product: HospitalProductDetail) => product.name ?? '',
  },
  {
    type: 'text',
    label: '承認番号',
    name: 'approvalNumber',
    getValue: (product: HospitalProductDetail) => product.approvalNumber ?? '',
  },
  {
    type: 'text',
    label: 'JANコード',
    name: 'janCode',
    getValue: (product: HospitalProductDetail) => product.janCode ?? '',
  },
  {
    type: 'text',
    label: 'クラス分類',
    name: 'className',
    getValue: (product: HospitalProductDetail) =>
      classNameOpts.find((item) => item.value === product.className)?.label ?? '',
  },
  {
    type: 'number',
    label: '定価',
    name: 'catalogPrice',
    getValue: (product: HospitalProductDetail) =>
      !isNullish(product.catalogPrice) ? `${product.catalogPrice.toLocaleString()}円` : '',
  },
];

const OuterDiv = styled('div')({
  padding: '16px',
});

const ItemDiv = styled('div')({
  marginBottom: '24px',
});

type WholeProductInfoProps = {
  hospitalProductHashId: string;
};

export const WholeProductInfo = (props: WholeProductInfoProps) => {
  const {hospitalProductHashId} = props;
  const {myInfo} = useMyInfo();

  const {data, isLoading: isLoadingHospitalProduct} = useFetchHospitalProductQuery(
    myInfo.hospitalHashId,
    hospitalProductHashId
  );

  if (isLoadingHospitalProduct || isNullish(data)) {
    return <InnerLoading />;
  }

  return (
    <OuterDiv>
      {fields.map((item, index) => (
        <ItemDiv key={`${item.name}_${index}`}>
          <MobileProductFormField label={item.label} value={item.getValue(data)} />
        </ItemDiv>
      ))}
    </OuterDiv>
  );
};
