import React, {useMemo} from 'react';
import {InspectionIndex} from '@modules/inspections/types';
import {InspectionTypeMap} from '@modules/inspections/enum';
import Selector, {SingleValueProps, components, FormatOptionLabelMeta} from 'react-select';
import {makeStyles} from '@material-ui/styles';
import {theme} from '@atoms/theme';
import {FormControl} from '@material-ui/core';
import {isArray} from 'lodash';

type InspectionOption = {
  label: string;
  value: InspectionIndex;
};

const SingleValue: React.VFC<SingleValueProps<InspectionOption>> = ({children: _children, ...props}) => {
  return <components.SingleValue {...props}>{props.data.label}</components.SingleValue>;
};

const FormatOptionLabel = React.memo(
  ({
    option,
    labelMeta,
  }: {
    option: InspectionOption;
    labelMeta: FormatOptionLabelMeta<InspectionOption, true | false>;
  }) => {
    const classes = useStyles();
    const isActive = useMemo(() => {
      // NOTE: formatOptionLabelの型が実態と乖離している（本来）
      // FormatOptionLabelMeta<InspectionOption, false> の場合はselectValueは配列ではないが、実際には配列が入ってきてしまう。
      if (isArray(labelMeta.selectValue)) {
        return labelMeta.selectValue.some((item) => item.value.hashId === option.value.hashId);
      } else {
        return false;
      }
    }, [labelMeta.selectValue, option.value.hashId]);
    return (
      <div>
        <div>{option.label}</div>
        <div className={`${isActive ? classes.selectedOptionSubLabel : ''} ${classes.optionSubLabel}`}>
          {InspectionTypeMap[option.value.type].label}
        </div>
      </div>
    );
  }
);
type InspectionSelectorProps = {
  inspectionOptions: {
    label: string;
    options: InspectionOption[];
  }[];
  defaultInspectionHashId?: string;
  onSearchInspection: (name: string) => void;
  onSelectInspection: (value?: InspectionIndex) => void;
};

export const InspectionSelector: React.FC<InspectionSelectorProps> = ({
  inspectionOptions,
  defaultInspectionHashId,
  onSearchInspection,
  onSelectInspection,
}) => {
  const defaultValue = useMemo(() => {
    // 上のカテゴリから探索していく
    for (const subOptions of inspectionOptions) {
      const found = subOptions.options.find((i) => i.value.hashId === defaultInspectionHashId);
      if (found) {
        return found;
      }
    }

    return undefined;
  }, [defaultInspectionHashId, inspectionOptions]);

  return (
    <div>
      <FormControl variant="outlined" size={'small'} fullWidth>
        <Selector<InspectionOption, false>
          menuPortalTarget={document.body}
          size="small"
          placeholder="検索"
          styles={{
            menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal * 10}),
          }}
          defaultValue={defaultValue}
          options={inspectionOptions}
          onInputChange={onSearchInspection}
          onChange={(option: InspectionOption | null) => {
            onSelectInspection(option?.value);
          }}
          formatOptionLabel={(option, labelMeta) => <FormatOptionLabel option={option} labelMeta={labelMeta} />}
          components={{SingleValue}}
        />
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  optionSubLabel: {
    fontSize: 12,
    color: theme.palette.grey[600],
  },
  selectedOptionSubLabel: {
    color: theme.palette.common.white,
  },
}));
