import React, {useCallback} from 'react';
import {Dialog} from '@atoms/Dialog/Dialog';
import {DialogProps as DialogHandlerProps} from '@components/molecules/Dialogs/DialogHandler';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import {makeStyles, styled} from '@material-ui/core/styles';
import {TransitionProps} from '@material-ui/core/transitions';
import {HomeIndicatorImage} from '@components/molecules/MobileUnderMenu/HomeIndicatorImage';
import {Box, Typography} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<typeof Dialog, typeof Dialog>},
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledContainerDiv = styled('div')({
  padding: '8px',
});

const PaperCss: React.CSSProperties = {
  width: '100%',
  maxHeight: '80%',
  minHeight: '20%',
  position: 'fixed',
  bottom: '-30px',
  overflow: 'visible',
};
export const StyledCompleteButton = styled(Button)({
  fontSize: '16px',
});

export type Props = ActionBottomSheetDialogProps & DialogHandlerProps;

type ActionBottomSheetDialogProps = {
  onDiscardChanges: (e: React.MouseEvent) => void;
  onSaveAsDraft: (e: React.MouseEvent) => void;
};

export const ActionBottomDialog: React.FC<Props> = (props) => {
  const {open, actions, onDiscardChanges, onSaveAsDraft} = props;
  const classes = useStyles();
  const handleClickDiscard = useCallback(
    (e: React.MouseEvent) => {
      onDiscardChanges(e);
    },
    [onDiscardChanges]
  );

  const handleClickSaveAsDraft = useCallback(
    (e: React.MouseEvent) => {
      onSaveAsDraft(e);
    },
    [onSaveAsDraft]
  );

  return (
    <Dialog
      open={Boolean(open)}
      onClose={actions?.reject}
      PaperProps={{style: PaperCss}}
      TransitionComponent={Transition}>
      <HomeIndicatorImage />
      <StyledContainerDiv>
        <StyledContainerDiv>
          <Box className={classes.root}>
            <Box className={classes.header}>
              <Typography>メニューを選択</Typography>
              <Typography className={classes.closeBtn} onClick={actions.reject}>
                閉じる
              </Typography>
            </Box>
            <Box className={classes.menu}>
              <Typography style={{marginBottom: '16px'}} onClick={handleClickSaveAsDraft}>
                保存して中断
              </Typography>
              <Typography onClick={handleClickDiscard} className={classes.exitBtn}>
                保存せずに終了
              </Typography>
            </Box>
          </Box>
        </StyledContainerDiv>
      </StyledContainerDiv>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  closeBtn: {
    color: '#0A52CC',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
  },
  saveBtn: {
    marginBottom: '16px',
  },
  exitBtn: {
    color: '#C7243A',
  },
}));
