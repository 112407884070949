import {Pagination} from '@material-ui/lab';
import React, {useMemo} from 'react';
import {TrainingTypeValue} from '@modules/training_plans/types';
import {useSearchVariables, useTrainingReportsQuery} from '../hooks';
import {Toolbar} from './Toolbar';
import {ReportTable} from './ReportTable';
import {TableViewLayout} from '@components/layouts/TableViewLayout';

export type ReportTableData = {
  hashId: string;
  scheduleHashId: string;
  reportedUserHashId: string;
  scheduleName: string;
  name: string;
  trainingTypes: TrainingTypeValue[];
  trainingDateRange: [Date, Date];
  place: string;
  description: string;
  numberOfAttendants: number;
};

export const ReportList: React.FC = () => {
  const {variables, setPage} = useSearchVariables();
  const {data} = useTrainingReportsQuery();

  const totalPage = useMemo(
    () => Math.ceil((data?.totalCount ?? 1) / variables.perPage),
    [data?.totalCount, variables.perPage]
  );

  return (
    <TableViewLayout>
      <TableViewLayout.Header>
        <Toolbar />
      </TableViewLayout.Header>
      <TableViewLayout.Body>
        <ReportTable />
      </TableViewLayout.Body>
      <TableViewLayout.Footer container justifyContent="center">
        <Pagination
          page={variables.page + 1}
          count={totalPage}
          shape="rounded"
          color="primary"
          onChange={(_, newPage) => setPage(newPage - 1)}
        />
      </TableViewLayout.Footer>
    </TableViewLayout>
  );
};
