import {TableBody, TableCell, TableRow} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {getIndexedDBSize, dbInfo} from '@indexedDB/getIndexedDBSize';
import {StatusTable, StyledTableHead, StyledTableRow} from '.';

/**
 * IndexedDBのステータス
 * @returns JSXElement
 */
export const IndexedDBList = () => {
  const [info, setInfo] = useState<dbInfo>();

  useEffect(() => {
    (async () => {
      const data = await getIndexedDBSize();
      setInfo(data);
    })();
  }, []);

  return (
    <StatusTable>
      <StyledTableHead>
        <TableRow>
          <TableCell colSpan={2}>IndexedDB</TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {info &&
          Object.entries(info).map(([key, value]) => (
            <StyledTableRow key={key}>
              <TableCell component="th" scope="row">
                {key}
              </TableCell>
              <TableCell align="right">{value}</TableCell>
            </StyledTableRow>
          ))}
      </TableBody>
    </StatusTable>
  );
};
