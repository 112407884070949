import {Theme, Tooltip} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import React from 'react';

const StyledTooltip = styled(Tooltip)(({theme}: {theme: Theme}) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  '& .MuiTooltip-arrow': {
    color: theme.palette.common.black,
  },
}));

type ArrowTooltipComponentProps = {
  tooltipText: string;
  children: React.ReactElement;
};

const StyledSpan = styled('span')(() => ({
  padding: '1em',
  fontWeight: 700,
}));

/**
 *
 * 矢印付きのツールチップを表示する
 *
 * @param {ArrowTooltipComponentProps} - Props
 * @param {string} tooltipText - ツールチップに表示する文字列
 * @param {React.ReactElement} children - ツールチップを表示させたいReactElement
 * @returns
 */
export const ArrowTooltipComponent = ({tooltipText, children}: ArrowTooltipComponentProps) => {
  return (
    <StyledTooltip title={<StyledSpan>{tooltipText}</StyledSpan>} arrow placement="top">
      {children}
    </StyledTooltip>
  );
};
