import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import React, {useCallback, useMemo} from 'react';
import {NoData, Table} from '@molecules/Table';
import {StatusSelector} from './StatusSelector';
import {ToolBar} from './ToolBar';
import {useAtom} from 'jotai';
import {useSetAtom} from 'jotai';
import {currentMakerInspectionResultStatusAtom, nameAtom, orderAtom, pageAtom} from './state';
import {useFetchMakerInspectionResultsCountsQuery} from '@modules/maker_inspection_results/hooks';
import {MakerInspectionResultListElement, MakerInspectionResultStatus} from './types';
import {useFetchMakerInspectionResultsQuery, useTableData} from './hooks';
import {RowAction} from '@molecules/Table/props';
import {useNavigate} from 'react-router-dom';
import {
  StartInspectionDialog,
  StartInspectionDialogProps,
  StartInspectionDialogResult,
} from './dialogs/StartInspectionDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  SkipInspectionDialog,
  SkipInspectionDialogProps,
  SkipInspectionDialogResult,
} from './dialogs/SkipInspectionDialog';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {
  UpdateScheduledDateDialogProps,
  UpdateScheduledDateDialogResult,
  UpdateScheduledDateDialog,
} from './dialogs/UpdateScheduledDateDialog';
import {deleteMakerInspectionResult, updateMakerInspectionResult} from '@modules/maker_inspection_results/api';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';

export const MakerInspectionListBody: React.FC = () => {
  const classes = useBodyStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [inspectionResultStatus, setInspectionResultStatus] = useAtom(currentMakerInspectionResultStatusAtom);
  const countsQuery = useFetchMakerInspectionResultsCountsQuery();
  const [searchName, setSearchName] = useAtom(nameAtom);
  const [page, setPage] = useAtom(pageAtom);
  const setOrder = useSetAtom(orderAtom);

  const handleChangeCurrentInspectionResultStatus = useCallback(
    (status: MakerInspectionResultStatus | null) => {
      setInspectionResultStatus(status ?? 'all');
    },
    [setInspectionResultStatus]
  );

  const handleChangeSearchName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
    },
    [setSearchName]
  );

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, p: number) => {
      setPage(p - 1);
    },
    [setPage]
  );

  const {data, isLoading, refetch} = useFetchMakerInspectionResultsQuery();
  const {columns, rows, totalPage, tableLayout} = useTableData(
    inspectionResultStatus,
    data?.data ?? [],
    data?.totalCount ?? 0
  );

  const handleClickView = useCallback(
    (e: React.MouseEvent, row: MakerInspectionResultListElement) => {
      e.stopPropagation();

      navigate(`/maker_inspections/results/${row.hashId}`);
    },
    [navigate]
  );

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrder(null);
      } else {
        setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
      }
    },
    [setOrder, tableLayout?.currentLayout]
  );

  const handleClickStart = useCallback(
    async (e: React.MouseEvent, row: MakerInspectionResultListElement) => {
      e.stopPropagation();

      let inspector: string;

      try {
        const res = await dialogHandler.open<StartInspectionDialogProps, StartInspectionDialogResult>(
          StartInspectionDialog,
          {
            defaultInspectorHashId: myInfo.hashId,
          }
        );

        inspector = res.inspector;
      } catch (_e) {
        return;
      }

      try {
        // 詳細画面への状態の受け渡しのため ダイアログで入力された状態
        navigate(`/maker_inspections/results/${row.hashId}`, {
          state: {
            inspectorHashId: inspector,
          },
        });
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar('点検実績の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [navigate, myInfo]
  );

  const handleClickSkip = useCallback(
    async (e: React.MouseEvent, row: MakerInspectionResultListElement) => {
      let skipDate: Date;
      let operator: string;
      let reason: string | undefined;

      try {
        const res = await dialogHandler.open<SkipInspectionDialogProps, SkipInspectionDialogResult>(
          SkipInspectionDialog,
          {
            defaultOperatorHashId: myInfo.hashId,
            defaultSkipDate: convertDateToSimpleDate(new Date()),
          }
        );

        skipDate = res.skipDate;
        operator = res.operator;
        reason = res.reason;
      } catch (_e) {
        return;
      }

      try {
        await updateMakerInspectionResult(myInfo.hospitalHashId, {
          makerInspectionResultHashId: row.hashId,
          status: 'skipped',
          skippedByUserHashId: operator,
          skippedTime: convertDateToSimpleDate(skipDate),
          skipReason: reason,
        });

        openSnackBar('点検をスキップしました', 'left', 'bottom', 'success');
        countsQuery.refetch();
        refetch();
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar('点検実績の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [countsQuery, myInfo.hashId, myInfo.hospitalHashId, refetch]
  );

  const handleClickChangeDate = useCallback(
    async (e: React.MouseEvent, row: MakerInspectionResultListElement) => {
      let scheduledDate: Date;

      try {
        const res = await dialogHandler.open<UpdateScheduledDateDialogProps, UpdateScheduledDateDialogResult>(
          UpdateScheduledDateDialog,
          {}
        );

        scheduledDate = res.scheduledDate;
      } catch (_e) {
        return;
      }

      try {
        updateMakerInspectionResult(myInfo.hospitalHashId, {
          makerInspectionResultHashId: row.hashId,
          scheduledTime: convertDateToSimpleDate(scheduledDate),
        });

        openSnackBar('点検予定日を更新しました', 'left', 'bottom', 'success');
        refetch();
        countsQuery.refetch();
      } catch (_e) {
        console.error(_e);
        openSnackBar('点検予定日の更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [countsQuery, myInfo.hospitalHashId, refetch]
  );

  const handleClickDelete = useCallback(
    async (e: React.MouseEvent, row: MakerInspectionResultListElement) => {
      const message = getMessageFromStatus(row.status);

      try {
        const ok = await dialogHandler
          .open(AlertDialog, {
            title: message + 'を削除しますか？',
            content: message + 'を削除しようとしています。\n\nこのアクションは元に戻せません。',
            positiveButtonLabel: message + 'を削除',
          })
          .then(() => true)
          .catch(() => false);

        if (!ok) {
          return;
        }

        await deleteMakerInspectionResult(myInfo.hospitalHashId, row.hashId);
        openSnackBar(message + 'を削除しました', 'left', 'bottom', 'success');
        refetch();
        countsQuery.refetch();
      } catch (_e) {
        console.error(_e);
        openSnackBar(message + 'の削除に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [countsQuery, myInfo.hospitalHashId, refetch]
  );

  const getMessageFromStatus = (status: string): string => {
    switch (status) {
      case 'completed':
        return '点検結果';
      case 'unplanned':
        return '点検予定';
      default:
        return '';
    }
  };

  const rowActions = useMemo(() => {
    const actions: RowAction<MakerInspectionResultListElement>[] = [];

    if (inspectionResultStatus === 'completed') {
      actions.push(
        {
          type: 'button',
          label: '詳細',
          onClick: handleClickView,
        },
        {
          type: 'menu',
          label: 'アクション',
          items: [
            {
              label: '点検結果を削除',
              onClick: handleClickDelete,
            },
          ],
        }
      );
    }

    if (inspectionResultStatus === 'unplanned' || inspectionResultStatus === 'skipped') {
      actions.push({
        type: 'button',
        label: '点検記録',
        onClick: handleClickStart,
      });
    }

    if (inspectionResultStatus === 'unplanned') {
      actions.push({
        type: 'menu',
        label: 'アクション',
        items: [
          {
            label: '点検をスキップ',
            onClick: handleClickSkip,
          },
          {
            label: '点検予定日を変更',
            onClick: handleClickChangeDate,
          },
          {
            label: '点検予定を削除',
            onClick: handleClickDelete,
          },
        ],
      });
    }

    return actions;
  }, [
    inspectionResultStatus,
    handleClickView,
    handleClickStart,
    handleClickSkip,
    handleClickChangeDate,
    handleClickDelete,
  ]);

  return (
    <Grid container className={classes.body}>
      <Grid item className={classes.sideBar}>
        <StatusSelector
          status={inspectionResultStatus}
          counts={countsQuery.data ?? undefined}
          onSelectStatus={handleChangeCurrentInspectionResultStatus}
        />
      </Grid>
      <TableViewLayout className={classes.main}>
        <TableViewLayout.Header>
          <ToolBar searchName={searchName ?? ''} onChangeSearchName={handleChangeSearchName} />
        </TableViewLayout.Header>
        <TableViewLayout.Body>
          <Table<MakerInspectionResultListElement>
            columns={columns}
            isLoading={isLoading}
            data={rows}
            showSelection={false}
            noDataComponent={
              <NoData
                title={searchName ? '現在の検索条件に一致する点検はありません。' : '点検はありません。'}
                message={
                  searchName
                    ? '検索条件を変えて、再度検索してみてください。'
                    : 'ここで機器の点検予定と結果を管理します。'
                }
              />
            }
            // selectionButtons={selectionButtons}
            rowActions={rowActions}
            onOrderChange={handleOrderChange}
            // FIXME: ヘッダーが小さくなるためコメントアウト。一括機能が追加されたら、下記追加。
            // tableSize="small"
          />
        </TableViewLayout.Body>
        <TableViewLayout.Footer justifyContent="center">
          <Pagination page={page + 1} count={totalPage} color="primary" shape="rounded" onChange={handleChangePage} />
        </TableViewLayout.Footer>
      </TableViewLayout>
    </Grid>
  );
};

const SIDEBAR_WIDTH = 170;

const useBodyStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      flexWrap: 'nowrap',
      width: '100%',
      height: '100%',
    },
    sideBar: {
      flex: `0 0 ${SIDEBAR_WIDTH}px`,
      marginRight: '18px',
    },
    main: {
      minWidth: '0px',
      flex: 1,
    },
  })
);
