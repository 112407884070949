import React, {useState, useCallback, useMemo, Suspense} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import {Checkbox, TextField} from '@molecules/Formik/fields';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {convertDateToJapaneseDate, convertDateToSimpleDateTime, isNullish} from '@front-libs/helpers';
import dayjs from 'dayjs';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {useNextInspectionResult} from '@modules/inspection_results/hooks';
import {InspectionResultIndex} from '@modules/inspection_results/types';

const DEFAULT_SKIP_POST_USE_INSPECTION_REASON = '定期点検実施より';

const useStyles = makeStyles((_theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '32px',
    },
  },
  nextInspectionDateContainer: {
    marginTop: '24px',
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));

type FinishInspectionFormProps = {
  open: boolean;
  canSkipPostUseInspectionResults: boolean;
  inspectionResult: InspectionResultIndex;
  onClose: React.MouseEventHandler;
};

type FinishInspectionFormValue = {
  completedAt: string;
  shouldSkipPostUseInspections: boolean;
  skipPostUseInspectionsReason?: string;
};

const FinishInspectionForm: React.VFC<FinishInspectionFormProps> = (props) => {
  const {open, canSkipPostUseInspectionResults, inspectionResult, onClose} = props;
  const classes = useStyles();
  const {submitForm, isValid, values} = useFormikContext<FinishInspectionFormValue>();

  const {hospitalUsers} = useHospitalUsers();

  const userOptions = useMemo(
    () => UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}),
    [hospitalUsers]
  );

  const maxDateTime = useMemo(() => {
    // 当日の23時59分まで選択できるようにはしておく
    return convertDateToSimpleDateTime(dayjs().endOf('day').toDate());
  }, []);

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const nextInspectionResultQuery = useNextInspectionResult(inspectionResult);
  const nextScheduledDate = useMemo(() => {
    const nextResult = nextInspectionResultQuery.data?.data?.at(0);
    if (isNullish(nextResult?.scheduledTime)) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return convertDateToJapaneseDate(new Date(nextResult!.scheduledTime));
  }, [nextInspectionResultQuery.data]);

  if (userOptions.length === 0) return null;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
          点検結果を保存
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.fields} direction="column">
          <Grid>
            <RequiredLabel>点検日時</RequiredLabel>
            <TextField
              type="datetime-local"
              name="completedAt"
              value={values.completedAt}
              size="small"
              fullWidth
              InputProps={{
                inputProps: {
                  max: maxDateTime,
                  style: {
                    padding: '10.5px 14px',
                  },
                },
              }}
            />
          </Grid>
          {canSkipPostUseInspectionResults && (
            <Grid container direction="column">
              <FormControl>
                <FormControlLabel
                  style={{marginLeft: 0}}
                  label="使用後点検をスキップし製品を待機中にする"
                  control={
                    <Checkbox
                      color="primary"
                      name="shouldSkipPostUseInspections"
                      checked={values.shouldSkipPostUseInspections}
                    />
                  }
                />
              </FormControl>
              {values.shouldSkipPostUseInspections && (
                <FormControl style={{marginTop: '16px'}}>
                  <RequiredLabel>スキップ理由</RequiredLabel>
                  <TextField
                    type="text"
                    name="skipPostUseInspectionsReason"
                    fullWidth
                    defaultValue={DEFAULT_SKIP_POST_USE_INSPECTION_REASON}
                    InputProps={{
                      style: {
                        padding: '4px 14px',
                      },
                    }}
                  />
                </FormControl>
              )}
            </Grid>
          )}
        </Grid>

        {nextScheduledDate !== null && (
          <Grid className={classes.nextInspectionDateContainer}>
            <Typography component="span">次回の点検予定日は</Typography>
            <Typography component="span" style={{fontWeight: 'bold', margin: '0px 4px'}}>
              {nextScheduledDate}
            </Typography>
            <Typography component="span">です。</Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isValid}>
          保存
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type FinishInspectionDialogProps = {
  completedAt: string;
  canSkipPostUseInspectionResults: boolean;
  inspectionResult: InspectionResultIndex;
} & DialogProps;

export type FinishInspectionDialogResult =
  | {
      completedAt: string;
      shouldSkipPostUseInspections: false;
    }
  | {
      completedAt: string;
      shouldSkipPostUseInspections: true;
      skipReason: string;
    };

export const FinishInspectionDialog: React.FC<FinishInspectionDialogProps> = (props) => {
  const {open, inspectionResult, completedAt, canSkipPostUseInspectionResults} = props;

  const [validationSchema] = useState(() =>
    yup.object(
      canSkipPostUseInspectionResults
        ? {
            completedAt: yup.string().required(),
            shouldSkipPostUseInspections: yup.boolean(),
            skipPostUseInspectionsReason: yup.string(),
          }
        : {
            completedAt: yup.string().required(),
          }
    )
  );

  const [initialValues] = useState(() =>
    canSkipPostUseInspectionResults
      ? {
          completedAt: completedAt,
          shouldSkipPostUseInspections: false,
          skipPostUseInspectionsReason: DEFAULT_SKIP_POST_USE_INSPECTION_REASON,
        }
      : {
          completedAt: completedAt,
          shouldSkipPostUseInspections: false,
        }
  );

  const handleSubmit = useCallback(
    async (data: FinishInspectionFormValue) => {
      // eslint-disable-next-line no-shadow
      const {completedAt, shouldSkipPostUseInspections, skipPostUseInspectionsReason} = data;
      if (canSkipPostUseInspectionResults && shouldSkipPostUseInspections) {
        props.actions.resolve({
          completedAt: completedAt,
          shouldSkipPostUseInspections: shouldSkipPostUseInspections,
          skipReason: skipPostUseInspectionsReason || DEFAULT_SKIP_POST_USE_INSPECTION_REASON,
        });
      } else {
        props.actions.resolve({
          completedAt: completedAt,
          shouldSkipPostUseInspections: false,
        });
      }
    },
    [props.actions, canSkipPostUseInspectionResults]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Suspense fallback={null}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={true}>
        <Form>
          <FinishInspectionForm
            open={open}
            inspectionResult={inspectionResult}
            canSkipPostUseInspectionResults={canSkipPostUseInspectionResults}
            onClose={handleClose}
          />
        </Form>
      </Formik>
    </Suspense>
  );
};
