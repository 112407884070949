import React, {useEffect, useCallback, useMemo} from 'react';
import {Box, Grid, makeStyles, Theme} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {Header, RentalHeaderHeight} from '../Header';
import {Footer, RentalFooterHeightLaptop, RentalFooterHeightTabletH} from '../Footer';
import clsx from 'clsx';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useNavigate} from 'react-router-dom';
import {
  allowRentalRoomUndefinedDataAtom,
  defaultBarcodeScannerTypeAtom,
  rentFloorAtom,
  rentRoomAtom,
  rentStepperAtom,
  rentWardAtom,
} from '../states';
import {useAtomValue, useSetAtom} from 'jotai';
import {RentalCard} from '../RentalCard';
import {HospitalWard} from '@modules/hospital_wards/types';
import _ from 'lodash';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {HospitalRoom} from '@modules/hospital_places/types';

const MAX_WARDS_PER_PAGE = 12;

export const FloorSelection = () => {
  const classes = useStyles();
  const templateClasses = useTemplate();
  const navigate = useNavigate();
  const setRentalFloor = useSetAtom(rentFloorAtom);
  const setRentalRoom = useSetAtom(rentRoomAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);
  const allowRentalRoomUndefinedData = useAtomValue(allowRentalRoomUndefinedDataAtom);
  const ward = useAtomValue(rentWardAtom);
  const {myInfo} = useMyInfo();

  const {data: roomData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: ward?.hashId,
    showRentalPlace: true,
    order: 'floor_number',
  });

  const filteredRooms = useMemo(() => {
    const roomMap = new Map();

    roomData.forEach((room) => {
      const key = `${room.floorNumber}:${room.isGroundFloor}`;
      if (!roomMap.has(key)) {
        roomMap.set(key, room);
      }
    });

    return [...roomMap.values()];
  }, [roomData]);

  const chunkedRooms = useMemo(() => {
    return {data: _.chunk(filteredRooms, MAX_WARDS_PER_PAGE), length: filteredRooms.length};
  }, [filteredRooms]);

  const getFloorDescription = useCallback((room: HospitalRoom) => {
    if (room.isGroundFloor) {
      return `${room.floorNumber}階`;
    }
    return `地下${room.floorNumber}階`;
  }, []);

  const handleClickNextButton = useCallback(() => {
    setRentalRoom(null);
    defaultBarcodeScannerType === 'barcode_reader'
      ? navigate(`/shared/rental/product/barcode_reader`)
      : navigate(`/shared/rental/product/camera`);
  }, [defaultBarcodeScannerType, navigate, setRentalRoom]);

  const handleSelectChange = useCallback(
    (value: HospitalWard | HospitalRoom) => {
      if ('floorNumber' in value) {
        setRentalFloor({floorNumber: value.floorNumber, isGroundFloor: value.isGroundFloor});
        navigate(`/shared/rental/roomSelection`);
      }
    },
    [navigate, setRentalFloor]
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  useEffect(() => {
    setRentalStepper(1);
  }, [setRentalStepper]);

  return (
    <>
      <Header title={'貸出'} />
      <Box className={classes.wardFloorContainer}>{`${ward?.name}`}</Box>
      <Box id={'mainContent'} className={clsx(templateClasses.content, classes.content)} style={defaultHeightWidth}>
        {chunkedRooms.length <= 12 ? (
          <Grid
            container
            justifyContent={'center'}
            alignItems="center"
            className={clsx(classes.gridRoot, filteredRooms.length > 4 ? classes.gridRootSecondLine : undefined)}
            spacing={3}>
            {chunkedRooms.data[0] &&
              chunkedRooms.data[0].map((room, index) => (
                <Grid
                  item
                  key={index}
                  className={clsx(filteredRooms.length > 8 ? classes.grid6Items : classes.grid4Items)}>
                  <RentalCard
                    label={getFloorDescription(room)}
                    itemsCount={filteredRooms.length}
                    value={room}
                    onChange={handleSelectChange}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={24}
            slidesPerView={1}
            navigation
            pagination={{clickable: true}}
            className={classes.swiper}>
            {chunkedRooms.data.map((rooms, index) => (
              <SwiperSlide key={index}>
                <Grid
                  container
                  justifyContent={'center'}
                  alignItems="center"
                  className={clsx(classes.gridRoot, filteredRooms.length > 4 ? classes.gridRootSecondLine : undefined)}
                  spacing={3}>
                  {rooms.map((room, index2) => (
                    <Grid item key={index2} className={classes.grid6Items}>
                      <RentalCard
                        label={getFloorDescription(room)}
                        itemsCount={filteredRooms.length}
                        value={room}
                        onChange={handleSelectChange}
                      />
                    </Grid>
                  ))}
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
      <Footer
        text={'貸出先を選択してください'}
        nextButtonLabel={allowRentalRoomUndefinedData ? '貸出先を選択せず次へ' : undefined}
        onClickNextButton={handleClickNextButton}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wardFloorContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '56px',
    paddingTop: '37px',
    fontSize: '20px',
  },
  content: {
    overflowY: 'scroll',
    alignItems: 'center',
    height: `calc(var(--outerHeight) - ${RentalFooterHeightTabletH} - ${RentalHeaderHeight} - 67px)`, // 病棟ナビ分引いている
    '@media screen and (orientation: portrait)': {
      height: `calc(var(--outerWidth) - ${RentalFooterHeightTabletH} - ${RentalHeaderHeight} - 67px)`,
    },
    [theme.breakpoints.up('laptop')]: {
      height: `calc(100vh - ${RentalFooterHeightLaptop} - ${RentalHeaderHeight} - 67px)`,
    },
  },
  gridRoot: {
    margin: 'auto',
    flexWrap: 'nowrap',
    padding: '0px 80px 0px 56px', // swiperのspaceBetween分を引いている
  },
  gridRootSecondLine: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  grid4Items: {
    flexBasis: '25%',
  },
  grid6Items: {
    flexBasis: '16.66%',
  },
  swiper: {
    padding: '40px 0px',
  },
}));
