import {useQuery} from 'react-query';
import {getTrainingScheduleTraineeListsKey} from '@constants/api';
import {getTrainingScheduleTraineeLists} from '@modules/training_schedules/api';

export const useFetchTrainingScheduleTraineeLists = (
  hospitalHashId: string,
  trainingScheduleHashId: string,
  order?: string
) => {
  return useQuery([getTrainingScheduleTraineeListsKey, hospitalHashId, trainingScheduleHashId, order], () =>
    getTrainingScheduleTraineeLists(hospitalHashId, trainingScheduleHashId, order)
  );
};
