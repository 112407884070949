import {Typography, makeStyles} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {KeyboardArrowUp, KeyboardArrowRight} from '@material-ui/icons';
import React from 'react';
export const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  const {name, expanded} = props;
  const classes = useStyles();
  return (
    <AccordionSummary
      className={classes.accordionSummary}
      expandIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowRight />}>
      <Typography className={classes.sectionTitleTxt}>{name}</Typography>
    </AccordionSummary>
  );
};

type SectionHeaderProps = {
  name: string;
  expanded: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    margin: '0px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  accordionContainer: {
    margin: '8px 0px',
    padding: '0px',
    borderRadius: '8px',
  },
  accordionSummary: {
    margin: '0px 16px',
    minHeight: '0px',
    padding: 0,
    '& > div.MuiAccordionSummary-content': {
      margin: '0px',
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '0px',
    },
  },
  sectionContainer: {
    margin: '8px 0px',
    padding: '0px',
  },
  sectionTitleTxt: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#172B4D',
    margin: '0px',
  },
}));
