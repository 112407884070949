import {styled} from '@material-ui/core';
import {Apps} from '@material-ui/icons';
import React from 'react';

export const AppMenu: React.FC = () => (
  <Menu>
    <Apps />
    <br />
    <MenuTitle>MENU</MenuTitle>
  </Menu>
);

const Menu = styled('span')({
  lineHeight: '8px',
});

const MenuTitle = styled('span')({
  fontSize: '9px',
  fontWeight: 700,
});
