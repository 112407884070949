import {Sidebar} from '@components/organisms/Sidebar';
import {withSuspense} from '@front-libs/core';
import {Divider, Grid, Typography} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import React from 'react';
import {HospitalDepartmentTable} from './HospitalDepartmentTable';

const StyledGrid = styled(Grid)({width: '100%'});

/**
 * ページのタイトルを表示
 */
const PageTitle = ({title}: {title: string}) => {
  return (
    <Typography variant="h5" className={useSettingsContentTemplate().pageTitle}>
      {title}
    </Typography>
  );
};

/**
 * ページの説明テキストを表示
 */
const Description = ({text}: {text: string}) => <p>{text}</p>;

type ContentContainerProps = {
  children: React.ReactNode;
};

/**
 * コンテンツのコンテナを表示
 */
const ContentContainer: React.FC<ContentContainerProps> = ({children}) => (
  <StyledGrid container style={{marginBottom: '32px'}}>
    {children}
  </StyledGrid>
);

/**
 * 管理部署設定ページ
 */
const InnerSettingsManagementDepartment = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container>
      <div className={templateClasses.form}>
        <Grid container className={templateClasses.grid}>
          <Grid item className={templateClasses.sideBar}>
            <Sidebar />
          </Grid>
          <Grid item className={templateClasses.content}>
            <ContentContainer>
              <StyledGrid item>
                <PageTitle title="管理部署" />
              </StyledGrid>
              <div className={templateClasses.flex} />
            </ContentContainer>
            <Description text="機器・資産を管理する部署のリストを管理します" />
            <Divider variant="middle" className={templateClasses.divider} />
            <HospitalDepartmentTable />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export const SettingsManagementDepartment = withSuspense(InnerSettingsManagementDepartment, null);
