import React, {useCallback} from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {TabKey, RadioKey, tabs, DEFAULT_ORDER, CUSTOMIZE} from './consts';
import {useAtom} from 'jotai';
import {radioAtom, tabAtom} from '../states';
import {useNavigate} from 'react-router-dom';
import {updateHospitalUser, useFetchMyInfo} from '@modules/hospital_users/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@components/molecules/SnackBar';

export const HeaderBottom = () => {
  const {myInfo} = useMyInfo();
  const {refetch} = useFetchMyInfo(myInfo.hashId);
  const classes = useStyles();
  const [radioButton, setRadioButton] = useAtom(radioAtom);
  const [tab, setTab] = useAtom(tabAtom);
  const navigate = useNavigate();

  const handleChangeTab = useCallback(
    (_, key: string) => {
      setTab(key as TabKey);
    },
    [setTab]
  );

  const handleRadioChange = useCallback(
    async (_, key: string) => {
      try {
        await updateHospitalUser(myInfo.hospitalHashId, myInfo.hashId, {rentalLayoutPreference: key as RadioKey});
        setRadioButton(key as RadioKey);
        const msg = key === DEFAULT_ORDER ? '昇順' : 'カスタマイズ';
        openSnackBar(`並び順を${msg}に変更しました。`, 'left', 'bottom');
        refetch();
        navigate({search: ''}, {replace: true});
      } catch (_e) {
        return;
      }
    },
    [myInfo.hashId, myInfo.hospitalHashId, navigate, refetch, setRadioButton]
  );

  return (
    <Grid container className={classes.root}>
      <Grid item container justifyContent="flex-start">
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" />
          <RadioGroup
            row
            aria-label="tab"
            name="tab"
            defaultValue={radioButton}
            value={radioButton}
            className={classes.radioGroup}
            onChange={handleRadioChange}>
            <FormControlLabel value={DEFAULT_ORDER} control={<Radio color="primary" />} label="昇順" />
            <FormControlLabel value={CUSTOMIZE} control={<Radio color="primary" />} label="カスタマイズ" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item container justifyContent="flex-start">
        <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
          {tabs.map((item) => (
            <Tab key={item.value} label={item.label} value={item.value} />
          ))}
        </Tabs>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '24px 32px 0px 48px',
      backgroundColor: theme.palette.common.white,
    },
    formControl: {
      marginBottom: 32,
    },
    radioGroup: {
      gap: 40,
    },
  })
);
