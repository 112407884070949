export const SERVICE_WORKER_KEY = 'ServiceWorkerActivateDateKey';

const INIT_ACTIVATE_END = 'initActivate end';
export let serviceWorkerRegistration: ServiceWorkerRegistration | undefined = undefined;

export const setServiceWorker = () => {
  if (!('serviceWorker' in navigator)) {
    console.log('Service Workerはサポートされていません。');
    return;
  }

  try {
    navigator.serviceWorker.addEventListener('message', messageHandler);
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then((registration: ServiceWorkerRegistration) => {
        serviceWorkerRegistration = registration;
        console.log('Service Workerの登録が完了しました.');
      })
      .catch((reason) => {
        console.error('Service Workerの登録中にエラーが発生しました', reason);
      });
  } catch (e) {
    console.error('Service Workerの登録エラー', e);
  }
};

/**
 * メッセージの受け取り
 * @param e
 */
const messageHandler = (e: MessageEvent) => {
  if (e?.data === INIT_ACTIVATE_END) {
    // NOTE:ServiceWorkerの登録完了の日時を保存
    localStorage.setItem(SERVICE_WORKER_KEY, String(+new Date()));
  }
};
/**
 * ServiceWorkerの登録解除
 * @returns
 */
export const unregisterServiceWorker = async () => {
  if (!serviceWorkerRegistration) {
    console.log('Service Worker Registrationが取得できません');
    return;
  }
  const res = await serviceWorkerRegistration.unregister();
  if (res) {
    console.log('Service Workerを登録解除しました');
    localStorage.setItem(SERVICE_WORKER_KEY, '');
    // NOTE:setServiceWorkerを再度呼んでもうまく登録できてないのでリロードさせる
    window.location.reload();
  } else {
    console.log('Service Workerの登録解除ができません');
  }
};
