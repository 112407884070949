import {isNullish} from '@front-libs/helpers';
import {HospitalRoom} from './types';

export const HospitalRoomFormatter = {
  getFullRoom(room?: HospitalRoom) {
    if (!room) return '';
    const wardStr = !isNullish(room.hospitalWard)
      ? `（${room.hospitalWard.name} ${room.isGroundFloor ? '' : '地下'}${room.floorNumber}階）`
      : '';

    return `${room.name}${wardStr}`;
  },
};
