import React, {memo, useMemo, useCallback, useState} from 'react';
import {Grid} from '@material-ui/core';
import {
  BaseField,
  RadioField,
  PullDownField,
  CheckboxField,
  NumericField,
  SingleLineTextField,
  MultiLineTextField,
  DateField,
  TimeField,
  InvalidValueError,
} from '@components/molecules/InspectionTableFormItems';
import {FormError} from '../EditInspectionResult/Item';
import {
  ResultItem,
  SelectResultItem,
  MultiSelectResultItem,
  NumericResultItem,
  TextResultItem,
  MultilineTextResultItem,
  DateResultItem,
  TimeResultItem,
} from '@modules/inspection_results/types';
import {useFormikContext} from 'formik';
import {isNullish} from '@front-libs/helpers';
import {InspectionItem, MultiSelectInspectionItem} from '@modules/inspections/api';
import {
  EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR,
  INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR,
  INSPECTION_SELECT_ITEM_VALIDATION_ERROR,
} from '@Apps/InspectionResult/pc/common/types';
import {useAsyncEffect} from '@front-libs/core';
import {getResultMultiSelectItemSchema} from '../common/validator';
import * as Yup from 'yup';
import {getItemSchema} from '../common/validator';
import {isString} from 'lodash';

type CheckBoxWrapperProps = {
  item: MultiSelectInspectionItem & {id: string};
  resultItem: MultiSelectResultItem;
  isEditable: boolean;
  newValue?: string[];
  onChange?: (values: string[]) => void;
  error?: React.ReactNode;
};

const CheckBoxWrapper: React.VFC<CheckBoxWrapperProps> = (props) => {
  const {item, resultItem, isEditable, newValue, onChange, error} = props;
  const values = useMemo(() => {
    const checkedMap: {[key: string]: boolean} = {};

    if (newValue) {
      newValue.forEach((x) => {
        checkedMap[x] = true;
      });

      return checkedMap;
    }
    if (!isNullish(resultItem.values)) {
      item.options.forEach((option) => {
        checkedMap[option.value] = resultItem.values?.indexOf(option.value) !== -1;
      });
    }
    return checkedMap;
  }, [item.options, resultItem.values, newValue]);

  const handleChange = useCallback(
    // eslint-disable-next-line no-shadow
    (values: {[key: string]: boolean}) => {
      const data = Object.entries(values)
        .filter(([_, value]) => value)
        .map(([key]) => key);
      onChange && onChange(data);
    },
    [onChange]
  );

  return (
    <CheckboxField
      options={item.options}
      isEditable={isEditable}
      values={values}
      onChange={handleChange}
      error={error}
    />
  );
};

type ItemInnerProps = {
  item: InspectionItem & {id: string};
  resultItem: ResultItem;
  isEditable: boolean;
};

export const Item: React.FC<ItemInnerProps> = memo((props) => {
  const {item, resultItem, isEditable} = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {setFieldValue, setFieldTouched, values} = useFormikContext<{newValues: any}>();
  const newValue = values.newValues[item.id];
  const [data, setData] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validation = (shape: Record<string, any>, value: Record<string, any>) => {
    const validator = Yup.object().shape(shape);
    validator
      .validate(value)
      .then(() => {
        setData('');
      })
      .catch((error) => {
        setData(error.message);
      });
  };

  const onChange = useCallback(
    // eslint-disable-next-line no-shadow
    (values: string | string[]) => {
      setFieldValue(`newValues[${item.id}]`, values);
      setFieldTouched('newValues', true, false);
      const {shape, value} = createShapeAndValue(values as string);
      validation(shape, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  const createShapeAndValue = (newValues?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shape: Record<string, any> = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: Record<string, any> = {};
    if (resultItem.type === 'multi-select') {
      shape[item.id] = getResultMultiSelectItemSchema(item as MultiSelectInspectionItem & {id: string}, false);
      if (newValues !== undefined) {
        value[resultItem.id] = {
          id: resultItem.id,
          values: newValues,
        };
      } else {
        value[resultItem.id] = {
          id: resultItem.id,
          values: resultItem.values,
        };
      }
    } else {
      shape[item.id] = getItemSchema(item as InspectionItem & {id: string}, false);
      if (newValues !== undefined) {
        value[resultItem.id] = {
          id: resultItem.id,
          value: newValues,
        };
      } else {
        value[resultItem.id] = {
          id: resultItem.id,
          value: resultItem.value,
        };
      }
    }
    return {shape, value};
  };

  useAsyncEffect(async () => {
    const {shape, value} = createShapeAndValue();
    validation(shape, value);
  }, [item, resultItem]);

  const rawError = data;

  const error = useMemo(() => {
    if (item.type === null) {
      return null;
    }
    switch (item.type) {
      case 'select':
      case 'multi-select':
        return rawError === INSPECTION_SELECT_ITEM_VALIDATION_ERROR ? (
          <InvalidValueError />
        ) : (
          <FormError error={rawError} />
        );
      case 'numeric': {
        // rawErrorが文字列でかつEMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERRORでない場合に処理を行う
        if (isString(rawError) && rawError !== EMPTY_REQUIRED_INSPECTION_ITEM_VALUE_ERROR) {
          try {
            const {error: errorCode, customError} = JSON.parse(rawError);
            if (errorCode === INSPECTION_NUMERIC_ITEM_VALIDATION_ERROR) {
              const validationErrorText = (customError ?? '').length > 0 ? customError : undefined;

              return <Grid style={{height: '32px'}}>{<InvalidValueError text={validationErrorText} />}</Grid>;
            }
          } catch (_e) {
            // empty
          }
        }
        return <FormError error={rawError} />;
      }
      case 'text':
      case 'multiline-text':
      case 'date':
      case 'time':
        return <FormError error={rawError} />;
    }
  }, [item, rawError]);

  if (!item || item.type === null || !resultItem) return null;
  if (item.type === 'section' || item.type === 'bool') return null; // should not reach here

  return (
    <BaseField item={item}>
      {(() => {
        switch (item.type) {
          case 'select':
            if (item.view === 'radio') {
              return (
                <RadioField
                  options={item.options}
                  isEditable={isEditable}
                  value={newValue ? newValue : (resultItem as SelectResultItem).value}
                  error={error}
                  onChange={onChange}
                />
              );
            } else if (item.view === 'pull-down') {
              return (
                <PullDownField
                  options={item.options}
                  isEditable={isEditable}
                  value={newValue ? newValue : (resultItem as SelectResultItem).value}
                  error={error}
                  onChange={onChange}
                />
              );
            }
            break;
          case 'multi-select':
            return (
              <CheckBoxWrapper
                item={item as MultiSelectInspectionItem & {id: string}}
                resultItem={resultItem as MultiSelectResultItem}
                isEditable={isEditable}
                newValue={newValue}
                onChange={onChange}
                error={error}
              />
            );
          case 'numeric':
            return (
              <NumericField
                unit={item.unit}
                isEditable={isEditable}
                value={newValue ? newValue : (resultItem as NumericResultItem).value}
                validator={item.validator}
                onChange={onChange}
                error={error}
              />
            );
          case 'text':
            return (
              <SingleLineTextField
                placeholder="空欄"
                isEditable={isEditable}
                showsError={false}
                value={newValue ? newValue : (resultItem as TextResultItem).value}
                onChange={onChange}
              />
            );
          case 'multiline-text':
            return (
              <MultiLineTextField
                placeholder="空欄"
                isEditable={isEditable}
                showsError={false}
                value={newValue ? newValue : (resultItem as MultilineTextResultItem).value}
                onChange={onChange}
                error={error}
              />
            );
          case 'date':
            return (
              <DateField
                isEditable={isEditable}
                showsError={false}
                value={newValue ? newValue : (resultItem as DateResultItem).value}
                onChange={onChange}
                error={error}
              />
            );
          case 'time':
            return (
              <TimeField
                isEditable={isEditable}
                showsError={false}
                value={newValue ? newValue : (resultItem as TimeResultItem).value}
                onChange={onChange}
                error={error}
              />
            );
          default:
            return null;
        }
      })()}
    </BaseField>
  );
});
