import * as jose from 'jose';
import {UseJwtProps} from '../types';

export const useJwt = async (props: UseJwtProps) => {
  if (!props.secret) return '';
  const secret = new TextEncoder().encode(props.secret);

  const jwt = await new jose.SignJWT(props.payload).setProtectedHeader(props.header).setIssuedAt().sign(secret);

  return jwt;
};
