import {getHospitalRoomsKey, getHospitalWardsKey} from '@constants/api';
import {axios} from '@front-libs/core';
import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {HospitalRoom, HospitalWard} from './types';

export type FetchHospitalWardResult = {
  totalCount: number;
  page: number;
  data: HospitalWard[];
};

export type FetchHospitalRoomResult = {
  totalCount: number;
  page: number;
  data: HospitalRoom[];
};

export type HospitalWardsParams = {
  page: number;
  perPage: number;
  name?: string;
  onlyRentableRooms?: boolean;
  order?: string;
};

export type HospitalRoomsParams = {
  page: number;
  perPage: number;
  hospitalWardHashId?: string;
  name?: string;
  showRentalPlace?: boolean;
  isGroundFloor?: boolean;
  floorNumber?: number;
  order?: string;
};

const fetchHospitalWards = async (hospitalHashId: string, params: HospitalWardsParams) => {
  const hospitalUserUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/wards`;
  const res = await axios.get<FetchHospitalWardResult>(hospitalUserUrl, {params});
  return res.data;
};

const fetchHospitalRooms = async (hospitalHashId: string, params: HospitalRoomsParams) => {
  const hospitalUserUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms`;
  const res = await axios.get<FetchHospitalRoomResult>(hospitalUserUrl, {params});
  return res.data;
};

export const createHospitalRoom = async (hospitalHashId: string, hospitalRoom: Partial<HospitalRoom>) => {
  const hospitalRoomUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms`;
  const res = await axios.post(hospitalRoomUrl, hospitalRoom);
  return res.data;
};

export const bulkCreateHospitalRooms = async (hospitalHashId: string, hospitalRooms: Partial<HospitalRoom>[]) => {
  const hospitalRoomUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms/jobs`;
  const res = await axios.post(hospitalRoomUrl, {hospitalHashId, hospitalRooms});
  return res.data;
};

export const bulkUpdateHospitalRooms = async (hospitalHashId: string, hospitalRooms: Partial<HospitalRoom>[]) => {
  const hospitalRoomUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms/jobs`;
  const res = await axios.put(hospitalRoomUrl, {hospitalRooms});
  return res.data;
};

export const bulkDeleteHospitalRooms = async (hospitalHashId: string, hospitalRoomHashIds: string[]) => {
  const hospitalRoomUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms/jobs`;
  const res = await axios.delete<void>(hospitalRoomUrl, {data: {hospitalRoomHashIds}});
  return res.data;
};

export const useFetchHospitalWards = (
  hospitalHashId: string,
  params: HospitalWardsParams = {page: 0, perPage: 100}
) => {
  const query = useQuery([getHospitalWardsKey, hospitalHashId, params], () =>
    fetchHospitalWards(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchHospitalRooms = (
  hospitalHashId: string,
  params: HospitalRoomsParams = {page: 0, perPage: 100}
) => {
  const query = useQuery([getHospitalRoomsKey, hospitalHashId, params], () =>
    fetchHospitalRooms(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
