import {Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {theme} from '@components/atoms/theme';
import barcode from '@assets/barcode.png';
import barcode2 from './assets/barcode2.png';

export const NoRowsContent: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="space-around" className={classes.root}>
      <Grid item className={classes.img1Container}>
        <img src={barcode} alt="バーコードの使い方" className={classes.barcodeImg} />
      </Grid>
      <Grid item className={classes.img2Container}>
        <img src={barcode2} alt="バーコードの場所" className={classes.barcodeImg2} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '40px',
    paddingTop: '80px',
    flexWrap: 'nowrap',
    gap: '40px',
  },
  img1Container: {
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '40px 26px',
    display: 'flex',
    justifyContent: 'center',
  },
  barcodeImg: {
    maxWidth: '80%',
    [theme.breakpoints.up('laptop')]: {
      maxWidth: '70%',
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: '60%',
    },
  },
  img2Container: {
    display: 'flex',
    justifyContent: 'center',
  },
  barcodeImg2: {
    maxWidth: '80%',
    paddingTop: '56px',
    [theme.breakpoints.up('laptop')]: {
      maxWidth: '70%',
      paddingTop: '64px',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingTop: '80px',
      maxWidth: '60%',
    },
  },
}));
