import React from 'react';
import {TextField, styled} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {useAtomValue} from 'jotai';
import {searchTextChangeAtom} from '@Apps/ProductsList/sp/jotai';
export type SearchTextProps = {
  searchCallback: (text: string) => void;
  placeholderText?: string;
};
const StyledTextField = styled(TextField)(({theme}) => {
  return {
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
  };
});
/**
 * スマホ用検索ボックスを表示
 */
export const SearchText = ({searchCallback, placeholderText}: SearchTextProps) => {
  const searchText = useAtomValue(searchTextChangeAtom);
  return (
    <StyledTextField
      variant="outlined"
      fullWidth={true}
      size="small"
      InputProps={{
        startAdornment: <Search />,
      }}
      placeholder={placeholderText && '検索'}
      defaultValue={searchText || ''}
      onChange={(e) => {
        searchCallback(e.target.value);
      }}
    />
  );
};
