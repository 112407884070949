import React, {useCallback} from 'react';
import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {
  FormError,
  EMPTY_INSPECTION_NAME_ERROR,
  ErrorTypeToFormError,
  INVALID_INSPECTION_TYPE_ERROR,
  EMPTY_SECTIONS_ERROR,
  EMPTY_SECTION_NAME_ERROR,
  EMPTY_FIELDS_ERROR,
  EMPTY_FIELD_NAME_ERROR,
  EMPTY_FIELD_TYPE_ERROR,
  INVALID_FIELD_TYPE_ERROR,
  EMPTY_FIELD_OPTIONS_ERROR,
  DUPLICATE_FIELD_OPTIONS_ERROR,
  EMPTY_FIELD_OPTION_VALUE_ERROR,
  EMPTY_FIELD_VALIDATORS_ERROR,
  EMPTY_FIELD_VALIDATOR_ERROR,
  EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR,
  INVALID_NUMERIC_VALIDATOR_TYPE_ERROR,
  EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR,
  INVALID_NUMERIC_VALIDATOR_RANGE_ERROR,
} from '../types';

const FieldName: React.VFC<{name?: string; onClick: React.MouseEventHandler}> = ({name, onClick}) => {
  const classes = useStyles();

  if (!name) {
    <Typography display="inline" variant="body2" className={classes.link} onClick={onClick}>
      点検内容
    </Typography>;
  }

  return (
    <>
      <Typography display="inline" variant="body2">
        点検内容
      </Typography>
      <Typography display="inline" variant="body2" className={classes.link} onClick={onClick}>
        {name}
      </Typography>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      fontWeight: 'bold',
      color: theme.palette.info.dark,
      cursor: 'pointer',
    },
  })
);

type FormErrorElementProps = {
  error: FormError;
  onItemClick: (sectionIndex: number, fieldIndex: number | null) => void;
};

export const FormErrorItem: React.VFC<FormErrorElementProps> = (props) => {
  const {error, onItemClick} = props;
  const classes = useStyles();

  const handleClickLink = useCallback(
    (sectionIndex: number, fieldIndex: number | null) => {
      onItemClick(sectionIndex, fieldIndex);
    },
    [onItemClick]
  );

  switch (error.type) {
    case EMPTY_INSPECTION_NAME_ERROR:
    case INVALID_INSPECTION_TYPE_ERROR:
    case EMPTY_SECTIONS_ERROR:
      return (
        <Typography display="inline" variant="body2">
          {ErrorTypeToFormError[error.type]}
        </Typography>
      );
    case EMPTY_SECTION_NAME_ERROR:
      return (
        <Typography
          display="inline"
          variant="body2"
          className={classes.link}
          onClick={() => handleClickLink(error.sectionIndex, null)}>
          点検項目は必須です
        </Typography>
      );
    case EMPTY_FIELDS_ERROR:
      return (
        <>
          <Typography display="inline" variant="body2">
            点検項目{error.sectionName || ''}
          </Typography>
          <Typography
            display="inline"
            variant="body2"
            className={classes.link}
            onClick={() => handleClickLink(error.sectionIndex, null)}>
            点検内容
          </Typography>
          <Typography display="inline" variant="body2">
            を含める必要があります
          </Typography>
          ,
        </>
      );
    case EMPTY_FIELD_NAME_ERROR:
      return (
        <Typography
          display="inline"
          variant="body2"
          className={classes.link}
          onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)}>
          点検内容は必須です
        </Typography>
      );
    case EMPTY_FIELD_TYPE_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            には点検タイプが必要です
          </Typography>
        </>
      );
    case INVALID_FIELD_TYPE_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            には不正な点検タイプが設定されています
          </Typography>
        </>
      );
    case EMPTY_FIELD_OPTIONS_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            には選択肢が必要です
          </Typography>
        </>
      );
    case DUPLICATE_FIELD_OPTIONS_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            には重複する選択肢があります
          </Typography>
        </>
      );
    case EMPTY_FIELD_OPTION_VALUE_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            には空の選択肢があります
          </Typography>
        </>
      );
    case EMPTY_FIELD_VALIDATORS_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            の回答の検証が空白です
          </Typography>
        </>
      );
    case EMPTY_FIELD_VALIDATOR_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            には空白のエラー値の設定項目があります
          </Typography>
          ,
        </>
      );
    case EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            の検証項目に条件タイプが必要です
          </Typography>
        </>
      );
    case INVALID_NUMERIC_VALIDATOR_TYPE_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            の検証項目の条件タイプが不正です
          </Typography>
        </>
      );
    case EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            の検証項目の条件値が空白です
          </Typography>
        </>
      );
    case INVALID_NUMERIC_VALIDATOR_RANGE_ERROR:
      return (
        <>
          <FieldName name={error.fieldName} onClick={() => handleClickLink(error.sectionIndex, error.fieldIndex)} />
          <Typography display="inline" variant="body2">
            の検証項目の条件範囲が不正です
          </Typography>
        </>
      );
  }
};
