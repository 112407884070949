import React, {useCallback, useEffect} from 'react';
import {DialogContent, makeStyles, createStyles, Dialog} from '@material-ui/core';
import {Header} from './Header';
import {useSteps} from './hooks';
import {HospitalProductsImport} from './Step3HospitalProductsImport';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useNavigate} from 'react-router-dom';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {updateHospital, useFetchHospitalQuery} from '@modules/hospital/api';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {HospitalRoomImport} from './Step2HospitalRoomImport/HospitalRoomImport';

export const HospitalSignUp: React.FC = () => {
  const {currentStep, next} = useSteps();

  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {refetch} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const {hospitalUsers} = useHospitalUsers();
  const navigate = useNavigate();

  useEffect(() => {
    if (myInfo.initialized) navigate('/', {replace: true});
  }, [myInfo.initialized, navigate]);

  // FIXME: 暫定的にhospitalUsersが1人以上の場合は次に進める
  useEffect(() => {
    if (hospitalUsers.length > 1) next(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteImportProduct = useCallback(async () => {
    await updateHospital(myInfo.hospitalHashId, {assetInitialized: true});
    await dialogHandler.open(MessageDialog, {
      title: '初期設定の完了',
      content: '初期設定が完了しました。ダッシュボード画面に遷移します。',
      negativeButtonLabel: '',
    });
    await refetch();
    navigate('/');
  }, [myInfo.hospitalHashId, navigate, refetch]);

  return (
    <Dialog open={true} fullScreen>
      <DialogContent>
        <Header />
        <div className={classes.pageContainer}>
          {currentStep === 0 ? (
            <HospitalRoomImport />
          ) : currentStep === 1 ? (
            <HospitalProductsImport onCompleteImportProduct={handleCompleteImportProduct} />
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    pageContainer: {
      margin: '16px 72px',
    },
  })
);
