import {atom, useAtomValue, useSetAtom} from 'jotai';
import {useEffect, useRef} from 'react';
import {useLocation, Location} from 'react-router-dom';

const previousLocationAtom = atom<Location | null>(null);

// 前ページLocation情報を読むためのフック
export const usePreviousLocation = () => {
  return useAtomValue(previousLocationAtom);
};

// 前ページのLocationを保存するためのフック
export const useSavePreviousLocation = () => {
  const location = useLocation();
  const currentLocation = useRef<Location | null>(null);
  const setPreviousLocation = useSetAtom(previousLocationAtom);

  // ロケーションに変更があったときに処理実行
  useEffect(() => {
    setPreviousLocation(currentLocation.current);

    currentLocation.current = location;
  }, [location, setPreviousLocation]);
};
