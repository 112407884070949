import React from 'react';
import Button from '@material-ui/core/Button';
import {Dialog, DialogProps} from '@atoms/Dialog/Dialog';
import {DialogActions} from '@atoms/Dialog/DialogActions';
import {DialogContent} from '@atoms/Dialog/DialogContent';
import {DialogProps as DialogHandlerProps} from './DialogHandler';
import {makeStyles} from '@material-ui/styles';
import {DialogTitle, Typography, Grid} from '@material-ui/core';
import {theme} from '@atoms/theme';
import parse from 'html-react-parser';

const defaultProps = {
  maxWidth: 'sm' as DialogProps['maxWidth'],
};

export type RecallDialogProps = {
  title?: string | React.ReactNode;
  content?: string;
  maxWidth?: DialogProps['maxWidth'];
  dialogProps?: Omit<DialogProps, 'open'>;
} & DialogHandlerProps;

export const RecallDialog: React.FC<RecallDialogProps> = (props) => {
  const {open, title, content, actions} = {...defaultProps, ...props};
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={'md'}
      open={open}
      PaperProps={{className: classes.paper, style: {overflow: 'visible', minWidth: 400}}}>
      {title && (
        <DialogTitle>
          <Typography style={{fontSize: '20px', fontWeight: 'bold'}}>PMDAからの回収通知:{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Grid>PMDAから回収情報が発出されました。</Grid>
        <Grid>詳細はpmdaのサイトをご確認ください。</Grid>
        <Grid className={classes.content}>{parse(content || '')}</Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.resolve} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((_) => ({
  paper: {
    '& > div:first-child:not(.MuiDialogTitle-root)': {
      paddingTop: '16px',
    },
  },
  warningSubmitButton: {
    backgroundColor: `#C7243A !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
  },
  warningCancelButton: {
    color: `${theme.palette.error.main} !important`,
    backgroundColor: '#fff',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    marginTop: '24px',
  },
}));
