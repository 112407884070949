import React, {useCallback, useEffect, useState} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  createHospitalWardLayoutSetting,
  fetchHospitalWardLayoutSettings,
  updateHospitalWardLayoutSetting,
} from '@modules/hospital_ward_layout_setting/api';
import {FormValue, customSequence} from '@modules/hospital_ward_layout_setting/types';
import {useAsyncEffect} from '@front-libs/core';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {InnerLoading} from '@molecules/Loading';
import {Formik} from 'formik';
import {useAtomValue} from 'jotai';
import {radioAtom, tabAtom} from '../states';
import {isNullish} from '@front-libs/helpers';
import {useNavigate} from 'react-router-dom';
import {PositionSetting} from '../PositionSetting';

type Option = {
  label: string;
  value: string;
};
type FloorOption = {
  label: string;
  value: string;
  floorNumber: string;
  isGroundFloor: boolean;
};
type HospitalWardPositionProps = {
  filterWard: customSequence[];
  wardOptions: Option[];
  floorOptions: FloorOption[];
};

export const HospitalWardPosition = ({filterWard, wardOptions, floorOptions}: HospitalWardPositionProps) => {
  const {myInfo} = useMyInfo();
  const [initialValues, setInitialValues] = useState<FormValue | null>(null);
  const tab = useAtomValue(tabAtom);
  const radio = useAtomValue(radioAtom);
  // layoutSettingの初期値の並び
  const [order, setOrder] = useState<customSequence[] | null>(null);
  const [cancelFlg, setCancelFlg] = useState(false);
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    try {
      // 大エリア並び順設定データ取得
      const layoutSetting = await fetchHospitalWardLayoutSettings(myInfo.hospitalHashId, myInfo.hashId);

      let settingData = layoutSetting?.customSequence || [];
      let wardId = layoutSetting?.hashId || '';

      // データがなければ作成
      if (isNullish(layoutSetting)) {
        const {data: responseData} = await createHospitalWardLayoutSetting(myInfo.hospitalHashId, myInfo.hashId, {
          customSequence: filterWard,
        });
        settingData = responseData.customSequence;
        wardId = responseData.hashId;
      }

      // 貸出画面に表示されている大エリアのみにフィルター
      const filterLayoutSetting = settingData.filter((item) => filterWard.some((id) => id.id === item.id));

      setInitialValues({
        HashId: wardId,
        Setting: filterLayoutSetting,
      });
      setOrder(settingData);
    } catch (e: unknown) {
      console.error(e);
    }
  }, [tab, filterWard]);

  const handleSubmit = useCallback(
    async (values: FormValue) => {
      const {hospitalHashId, hashId: userHashId} = myInfo;

      try {
        await dialogHandler.open(MessageDialog, {
          title: '変更内容を保存しますか？',
          positiveButtonLabel: '保存',
          negativeButtonLabel: '保存せずトップへ戻る',
        });
      } catch (_e) {
        navigate('/shared', {replace: true});
        return;
      }

      try {
        // 初期値に含まれていて,submitされたデータに含まれていないデータを抽出
        const missingData = (order || []).filter((item) => !values.Setting.some((value) => value.id === item.id));
        const newSetting = missingData.length > 0 ? [...values.Setting, missingData[0]] : values.Setting;

        await updateHospitalWardLayoutSetting(hospitalHashId, userHashId, values.HashId, {
          customSequence: newSetting,
        });
        setInitialValues({
          HashId: values.HashId,
          Setting: values.Setting,
        });
        openSnackBar('変更を保存しました。', 'left', 'bottom');
      } catch (e: unknown) {
        console.error(e);
        openSnackBar('更新に失敗しました。', 'center', 'top', 'error');
      }
    },
    [myInfo, navigate, order]
  );

  const handleClickCancel = useCallback(async () => {
    try {
      await dialogHandler.open(MessageDialog, {
        title: '変更内容を破棄しますか？',
        positiveButtonLabel: '破棄',
        negativeButtonLabel: '破棄せず編集画面へ戻る',
        warning: true,
      });
    } catch (_e) {
      return;
    }
    try {
      setCancelFlg(true);
    } catch (_e) {
      return;
    }
  }, []);

  useEffect(() => {
    if (cancelFlg) {
      setCancelFlg(false);
    }
  }, [cancelFlg]);

  let wardDataToUse = filterWard;
  if (radio === 'customize') {
    wardDataToUse = initialValues?.Setting || [];
  } else {
    wardDataToUse = filterWard;
  }

  if (!filterWard || initialValues === null || order === null) {
    return <InnerLoading />;
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      <PositionSetting
        data={wardDataToUse}
        wardOptions={wardOptions}
        floorOptions={floorOptions}
        handleClickCancel={handleClickCancel}
        resetFlag={cancelFlg}
      />
    </Formik>
  );
};
