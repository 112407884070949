import {Box, Typography, makeStyles} from '@material-ui/core';
import React, {useCallback} from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import {useNavigate} from 'react-router-dom';

export const Header: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate('/sp/inspection/results');
  }, [navigate]);
  return (
    <Box className={classes.root}>
      <Box className={classes.iconBox}>
        <IconButton onClick={handleClick} style={{padding: '0px'}}>
          <ArrowBackIosIcon style={{fontSize: '16px', padding: '0px', color: 'black'}} />
        </IconButton>
        <Typography style={{color: 'black'}}>点検対象機器一覧</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    height: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 16px',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));
