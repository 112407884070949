import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useBackPrevious, withSuspense} from '@front-libs/core';
import {InspectionList} from '@Apps/InspectionSetting/InspectionList';
import {Header} from './Header';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {createInspection} from '@modules/inspections/api';
import {
  NewInspectionDialog,
  NewInspectionDialogProps,
  NewInspectionDialogResult,
} from '@molecules/Dialogs/NewInspectionDialog';
import {DEFAULT_INSPECTION_NAME} from '@Apps/Inspection/states';
import {InspectionType} from '@modules/inspections/enum';
import {openSnackBar} from '@components/molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {ListPageLayout} from '@components/layouts/ListPageLayout';

const newInspection = async (hospitalHashId: string): Promise<string | null> => {
  let type: InspectionType;
  try {
    const res = await dialogHandler.open<NewInspectionDialogProps, NewInspectionDialogResult>(NewInspectionDialog, {});
    type = res.type;
  } catch (_e) {
    return null;
  }

  try {
    const res = await createInspection(hospitalHashId, {
      name: DEFAULT_INSPECTION_NAME,
      type: type,
      status: 'draft',
    });

    return res.data.hashId;
  } catch (e) {
    console.error(e);
    openSnackBar('点検表作成に失敗しました', 'left', 'bottom', 'error');
  }

  return null;
};

const _InspectionSetting: React.VFC = () => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const goBackToResultsPage = useBackPrevious('/inspection/results');

  const handleClickNewInspection = useCallback(async () => {
    const hashId = await newInspection(myInfo.hospitalHashId);
    if (hashId !== null) {
      navigate(`/inspections/${hashId}`);
    }
  }, [myInfo.hospitalHashId, navigate]);

  const handleClickPrevButton = useCallback(() => {
    goBackToResultsPage();
  }, [goBackToResultsPage]);

  return (
    <ListPageLayout page="inspection_setting">
      <ListPageLayout.Header>
        <Header onClickPrevButton={handleClickPrevButton} onClickNewInspection={handleClickNewInspection} />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <InspectionList />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};

export const InspectionSetting = withSuspense(_InspectionSetting, null);
