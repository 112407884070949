import {useQuery} from 'react-query';
import {getHospitalProductFaultRepairHistoiesKey, getHospitalProductRentalHistoiesKey} from '@constants/api';
import {
  FetchHospitalProductFaultRepairHistoriesParams,
  FetchHospitalProductRentalHistoriesParams,
} from '@modules/hospital_products/api';
import {
  getHospitalProductFaultRepairHistories,
  getHospitalProductRentalHistories,
} from '@modules/hospital_products/api/historyApi';
import {isNullish} from '@front-libs/helpers';

export const useFetchHospitalProductRentalHistoriesQuery = (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductRentalHistoriesParams = {}
) => {
  return useQuery(
    [getHospitalProductRentalHistoiesKey, hospitalHashId, hospitalProductHashId, params],
    () => getHospitalProductRentalHistories(hospitalHashId, hospitalProductHashId, params),
    {
      enabled: !isNullish(hospitalProductHashId),
    }
  );
};

export const useFetchHospitalProductFaultRepairHistoriesQuery = (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductFaultRepairHistoriesParams = {}
) => {
  return useQuery(
    [getHospitalProductFaultRepairHistoiesKey, hospitalHashId, hospitalProductHashId, params],
    async () => {
      const {data} = await getHospitalProductFaultRepairHistories(hospitalHashId, hospitalProductHashId, params);
      return data;
    }
  );
};
