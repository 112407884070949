import React, {useState, useCallback} from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {DragIndicator} from '@material-ui/icons';
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Section} from '@Apps/Inspection/types';
import {isNullish} from '@front-libs/helpers';

const BorderWidth = '2px';

const useSortableSectionsStyles = makeStyles((theme) => ({
  list: {
    padding: '0px',
    margin: '0px',
  },
  row: {
    width: '100%',
    listStyle: 'none',
    borderTop: `${BorderWidth} solid ${theme.palette.grey[300]}`,
    borderBottom: `${BorderWidth} solid ${theme.palette.grey[300]}`,
    '&:not(:first-child)': {
      marginTop: `-${BorderWidth}`,
    },
  },
  section: {
    height: '80px',
    flexWrap: 'nowrap',
    paddingLeft: '4px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  selected: {
    paddingLeft: '0px',
    borderLeft: '4px solid #2A96E8',
  },
  dragHandle: {
    width: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dragIcon: {
    color: theme.palette.grey[300],
  },
}));

type SortableSectionsProps = {
  sections: Section[];
  onSectionsChange: (sections: Section[]) => void;
};

const SortableSections: React.VFC<SortableSectionsProps> = (props) => {
  const {sections, onSectionsChange} = props;
  const classes = useSortableSectionsStyles();

  const [focusedIndex, setFocusedIndex] = useState(0);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const {source, destination} = result;
      if (!isNullish(destination)) {
        const newSections = [...sections];
        [newSections[source.index], newSections[destination.index]] = [
          newSections[destination.index],
          newSections[source.index],
        ];
        onSectionsChange(newSections);

        setFocusedIndex(destination.index);
      }
    },
    [onSectionsChange, sections]
  );

  // TODO: 後でWrapper Atomを使うようにする
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="sections">
        {(provided) => (
          <ul className={classes.list} {...provided.droppableProps} ref={provided.innerRef}>
            {sections.map((section, index) => (
              <Draggable key={index} index={index} draggableId={`${index}`}>
                {(innerProvided) => (
                  <li className={classes.row} ref={innerProvided.innerRef} {...innerProvided.draggableProps}>
                    {/* Section */}
                    <Grid container className={clsx(classes.section, index === focusedIndex ? classes.selected : null)}>
                      <Grid item className={classes.dragHandle} {...innerProvided.dragHandleProps}>
                        <DragIndicator className={classes.dragIcon} fontSize="large" />
                      </Grid>
                      <Grid item xs={12} sm container direction="column" justifyContent="center">
                        <Typography variant="subtitle1">{section.name}</Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {`点検内容: ${section.fields.length}件`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px',
  },
}));

export type ReorderSectionsDialogProps = {
  sections: Section[];
} & DialogProps;

export const ReorderSectionsDialog: React.FC<ReorderSectionsDialogProps> = (props) => {
  const {actions, sections: defaultSections} = props;
  const classes = useStyles();

  const [sections, setSections] = useState(defaultSections);

  const onClose = useCallback(() => {
    actions.reject();
  }, [actions]);

  const handleSubmit = useCallback(() => {
    actions.resolve(sections);
  }, [actions, sections]);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle>点検項目の並び替え</DialogTitle>
      <DialogContent className={classes.content}>
        <SortableSections sections={sections} onSectionsChange={setSections} />
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} onClick={handleSubmit} color="primary">
          適用
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
