import {FetchInspectionsParams, getInspections} from '@modules/inspections/api';
import {deleteInspectionsByHospitalHashId, upsertInspections} from './repository';

export const initInspections = async (hospitalHashId: string) => {
  const NUM_PER_PAGE = 100;
  const params: FetchInspectionsParams = {
    page: 0,
    perPage: NUM_PER_PAGE,
  };

  const initialData = await getInspections(hospitalHashId, params);
  const totalPage = Math.ceil(initialData.totalCount / NUM_PER_PAGE);
  if (initialData.data.length !== 0) {
    await upsertInspections(initialData.data);
  }

  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await getInspections(hospitalHashId, params);
    if (data.data.length !== 0) {
      await upsertInspections(data.data);
    }
  }
};

// 差分データ取得
export const updateInspections = async (hospitalHashId: string, updatedAt: string) => {
  const NUM_PER_PAGE = 100;
  const params: FetchInspectionsParams = {
    page: 0,
    perPage: NUM_PER_PAGE,
    updatedAtFrom: updatedAt,
  };
  const updateData = await getInspections(hospitalHashId, params);
  const totalPage = Math.ceil(updateData.totalCount / NUM_PER_PAGE);
  if (updateData.data.length !== 0) {
    await upsertInspections(updateData.data);
  }

  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await getInspections(hospitalHashId, params);
    if (data.data.length !== 0) {
      await upsertInspections(data.data);
    }
  }
};

// 病院IDに紐づくデータを削除
export const deleteInspections = async (hospitalHashId: string) => {
  await deleteInspectionsByHospitalHashId(hospitalHashId);
};
