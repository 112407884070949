import React, {useMemo} from 'react';
import {createStyles, makeStyles, Theme, Typography, Grid} from '@material-ui/core';
import clsx from 'clsx';

const useStyle = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    status: {
      flex: '0 0 65px',
      borderRadius: '100px',
      padding: '2px 0px',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    error: {
      background: '#feeaed',
      color: '#C7243A',
      borderColor: '#C7243A',
    },
    info: {
      background: '#E4E6EC',
      color: '#65676B',
      borderColor: '#65676B',
    },
    primary: {
      background: '#DEEBFF',
      color: '#2A96E8',
      borderColor: '#2A96E8',
    },
    success: {
      background: '#EAF5EA',
      color: '#3C9E58',
      borderColor: '#3C9E58',
    },
  })
);

type StatusColor = 'error' | 'info' | 'success' | 'primary';

type StatusBadgeProps = {
  status: StatusColor;
  text: string;
};

export const StatusBadge: React.FC<StatusBadgeProps> = (props) => {
  const {status, text} = props;
  const classes = useStyle();

  const textClass = useMemo(
    () => (status && status in classes ? clsx(classes.status, classes[status]) : clsx(classes.status)),
    [classes, status]
  );

  return (
    <Grid className={classes.root}>
      <Typography className={textClass} variant="inherit">
        {text}
      </Typography>
    </Grid>
  );
};
