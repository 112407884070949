import React, {Component, ReactNode, Suspense} from 'react';
import {LinearProgress} from '@material-ui/core';

type ComponentProps = Record<string, unknown>;

/**
 * 引数のコンポーネントをローディングが終わるまでレンダリングしない
 * @param WrapComponent 対象のコンポーネント
 * @param FallbackComponent 省略可 サスペンドしたらこのコンポーネントが表示される 省略時はLinerProgressが表示される
 * @returns
 */
export const withSuspense = <P extends ComponentProps>(
  WrapComponent: React.FC<P> | React.VFC<P>,
  FallbackComponent: NonNullable<ReactNode> | null = null
) => {
  return class extends Component<P> {
    render() {
      if (!FallbackComponent) FallbackComponent = <LinearProgress />;
      return (
        <Suspense fallback={FallbackComponent}>
          <WrapComponent {...this.props} />
        </Suspense>
      );
    }
  };
};
