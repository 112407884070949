import {isNullish} from '@front-libs/helpers';
import {RootCauseCategoryType, SymptomCategoryType, SymptomDetailCategoryType} from './types';

export const dealtCategoryOptions = [
  {
    label: '異常なし',
    value: 'no_abnormality',
  },
  {
    label: '院内修理',
    value: 'internal_repair',
  },
  {
    label: '業者依頼',
    value: 'request_to_maker',
  },
  {
    label: '修理不能',
    value: 'unrepairable',
  },
];

const dealtCategoryMap = Object.fromEntries(dealtCategoryOptions.map((item) => [item.value, item.label]));

export function getDealtCategoryByValue(value?: string): string {
  if (isNullish(value)) return '';
  return dealtCategoryMap[value] ?? '';
}

export const symptomCategoryOptions: {label: string; shortLabel: string; value: SymptomCategoryType}[] = [
  {
    label: '外装破損',
    shortLabel: '外装破損',
    value: 'external_damage',
  },
  {
    label: '内部故障',
    shortLabel: '内部故障',
    value: 'internal_failure',
  },
  {
    label: '要点検の表示あり',
    shortLabel: '要点検',
    value: 'maintenance_required',
  },
  {
    label: '部品の不備・不足',
    shortLabel: '部品不備',
    value: 'component_deficiency',
  },
  {
    label: '紛失',
    shortLabel: '紛失',
    value: 'lost',
  },
  {
    label: '異常なし',
    shortLabel: '異常なし',
    value: 'no_issue',
  },
  {
    label: 'その他',
    shortLabel: 'その他',
    value: 'others',
  },
];

const symptomCategoryMap = Object.fromEntries(
  symptomCategoryOptions.map((item) => [item.value, {label: item.label, shortLabel: item.shortLabel}])
);

export function getSymptomCategoryShortLabel(value?: string): string {
  if (isNullish(value)) return '';
  return symptomCategoryMap[value]?.shortLabel ?? '';
}

export function getSymptomCategoryLabel(value?: string): string {
  if (isNullish(value)) return '';
  return symptomCategoryMap[value]?.label ?? '';
}

export const symptomDetailCategoryOptions: {label: string; value: SymptomDetailCategoryType}[] = [
  {
    label: '電源が入らない・作動しない',
    value: 'power_failure',
  },
  {
    label: 'セルフチェックが通らない・エラー表示',
    value: 'self_check_failure',
  },
  {
    label: '出力しない・測定できない',
    value: 'no_output',
  },
  {
    label: '誤動作・測定値がおかしい',
    value: 'malfunction',
  },
  {
    label: '空気漏れ・圧不足・異音',
    value: 'abnormal_behavior',
  },
  {
    label: '充電異常・電源が落ちる',
    value: 'charging_issue',
  },
  {
    label: 'その他',
    value: 'others',
  },
];
const symptomDetailCategoryMap = Object.fromEntries(
  symptomDetailCategoryOptions.map((item) => [item.value, item.label])
);
export function getSymptomDetailCategoryLabel(value?: string): string {
  return isNullish(value) ? '' : symptomDetailCategoryMap[value] ?? '';
}

export const rootCauseCategoryOptions: {label: string; value: RootCauseCategoryType}[] = [
  {
    label: '劣化',
    value: 'degradation',
  },
  {
    label: '人的要因（職員）',
    value: 'staff_related_cause',
  },
  {
    label: '人的要因（患者）',
    value: 'patient_related_cause',
  },
  {
    label: '点検未実施',
    value: 'inspection_not_performed',
  },
  {
    label: '原因不明',
    value: 'unknown_cause',
  },
  {
    label: 'その他',
    value: 'others',
  },
];
const rootCauseCategoryMap = Object.fromEntries(rootCauseCategoryOptions.map((item) => [item.value, item.label]));
export function getRootCauseCategoryByValue(value?: string): string {
  return isNullish(value) ? '' : rootCauseCategoryMap[value] ?? '';
}

export const repairLogTypeEnum = {
  statusChange: 'status_change',
  comment: 'comment',
  rental: 'rental',
  return: 'return',
  moveTargetBuilding: 'move_target_building',
} as const;

export const necessityOfSubstituteOptions = [
  {label: '有り', value: true},
  {label: '無し', value: false},
];
