import React, {useEffect} from 'react';
import {Grid, makeStyles, Theme} from '@material-ui/core';
import {Outlet, useNavigate} from 'react-router-dom';
import {withSuspense} from '@front-libs/core';
import {Helmet} from 'react-helmet-async';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalQuery} from '@modules/hospital/api';

export type ChildCommonProps = {
  onEnableNextLink: () => void;
  onDisableNextLink: () => void;
  onSetNextLink: (path: string) => void;
};

const _BaseSharedMenu: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {data} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNullish(data) && !data.initialized) {
      navigate('/signup/hospital', {replace: true});
      return;
    }
    if (!isNullish(myInfo.initialized) && !myInfo.initialized) {
      navigate('/signup/user', {replace: true});
      return;
    }
  }, [data, myInfo.initialized, navigate]);

  return (
    <Grid container className={classes.root}>
      <Helmet>
        <style>
          {`
            iframe {
              display: none !important;
            }
        `}
        </style>
      </Helmet>
      <Outlet />
    </Grid>
  );
};

export const BaseSharedMenu = withSuspense(_BaseSharedMenu);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    overflow: 'hidden',
    '@media screen and (orientation: portrait)': {
      height: '100vw !important',
      width: '100vh !important',
      minHeight: '100vw !important',
      transform: 'rotate(90deg)',
      position: 'absolute',
      top: 'calc((100vh - 100vw) / 2)',
      right: 'calc(0px - (100vh - 100vw) / 2)',
    },
  },
}));
