import {MakerInspectionResultStatus} from './types';

export const MakerInspectionStatusMenus: {value: MakerInspectionResultStatus; label: string}[] = [
  {
    label: 'すべての点検',
    value: 'all',
  },
  {
    label: '点検予定',
    value: 'unplanned',
  },
  {
    label: '点検結果',
    value: 'completed',
  },
  {
    label: 'スキップ',
    value: 'skipped',
  },
];
