export const chartOptions = {
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
    align: 'left',
  },
  // 年間の半分の期間も故障している機器はないので、最大値を50%に設定
  yAxis: {
    min: 0,
    max: 50,
    title: {
      text: '故障率(%)',
    },
    stackLabels: {
      enabled: true,
    },
  },
  legend: {
    reversed: true,
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}%<br/>Total: {point.stackTotal}%',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
    },
  },
};
