export const trainingTypeOptions = [
  {
    value: 'effectiveness_and_safety',
    label: '有効性・安全性',
  },
  {
    value: 'how_to_use',
    label: '使用方法',
  },
  {
    value: 'maintenance_and_inspection',
    label: '保守点検',
  },
  {
    value: 'incident',
    label: '不具合等への対応',
  },
  {
    value: 'compliance',
    label: '法令上の遵守事項',
  },
];

export const planTypeOptions = [
  {label: '年間', value: 'yearly_plan'},
  {label: '随時', value: 'as_needed'},
];

export const statusOptions = [
  {label: '完了', value: 'complete'},
  {label: '未完了', value: 'incomplete'},
];
