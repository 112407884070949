import React, {useMemo, useState} from 'react';
import {makeStyles, Theme, Grid, Button, Paper, MenuList, Popper, MenuItem, ClickAwayListener} from '@material-ui/core';
import {NavLink, useLocation} from 'react-router-dom';
import {KeyboardArrowDown} from '@material-ui/icons';
import {NavLinkType} from '.';
import clsx from 'clsx';
import Grow from '@material-ui/core/Grow';
import {HospitalIndex} from '@modules/hospital/types';
import {UserIndex} from '@modules/hospital_users/types';
import {isNullish} from '@front-libs/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  linkItem: {
    height: 'inherit',
  },
  linkItemActive: {
    fontWeight: 'bolder',
    color: theme.palette.primary.dark,
    paddingBottom: '2px',
    borderBottom: `4px solid ${theme.palette.primary.dark}`,
  },
  linkContent: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
  linkIcon: {
    color: theme.palette.grey[700],
  },
  linkedButton: {
    height: '100%',
    borderRadius: 0,
    paddingLeft: '12px',
    paddingRight: '12px',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    [theme.breakpoints.up('tabletH')]: {
      paddingRight: '8px',
      paddingLeft: '8px',
      minWidth: 64,
    },
  },
  popper: {
    zIndex: 100000,
    maxHeight: 360,
    overflow: 'auto',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuListRow: {
    display: 'flex',
  },
  menuListColumn: {
    display: 'block',
  },
  menuItemHovered: {
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

type Props = {
  navLinkItems: NavLinkType[];
  user?: UserIndex;
  hospital?: HospitalIndex;
};

export const HeaderMenu: React.VFC<Props> = ({navLinkItems, user, hospital}) => {
  const classes = useStyles();
  const location = useLocation();

  const [openedChild, setOpenedChild] = useState<string>('');
  const [count, setCount] = useState(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: NavLinkType) => {
    if ((item.childLinks ?? []).length === 0) return;
    event.preventDefault();
    setOpenedChild(item.label);
  };

  const handleClose = (e: React.MouseEvent<Document, MouseEvent>): void => {
    setCount(count + 1);
    if (count === 0) return;
    e.stopPropagation();

    if (!openedChild) return;
    setOpenedChild('');
    setCount(0);
  };

  const handleMenuClick = () => {
    setOpenedChild('');
    setCount(0);
  };

  const activeNavLink = useMemo(() => {
    return navLinkItems.find((item) => {
      let isActive;
      if (item.to !== '') isActive = location.pathname.startsWith(item.to);
      item.childLinks?.forEach((child) => {
        if (location.pathname.startsWith(child.to)) isActive = true;
      });
      return isActive;
    });
  }, [navLinkItems, location]);

  return (
    <Grid container className={classes.root} id="tutorial-nav-container">
      {navLinkItems
        .filter((item) => {
          if (isNullish(item.hasPermission)) return true;
          if (isNullish(hospital) || isNullish(user)) return false;
          return item.hasPermission(hospital, user);
        })
        .map((item, index) => (
          <Grid item className={classes.linkItem} key={`nav-${index}`} id={`tutorial-nav-${index}`}>
            <NavLink
              to={item.to}
              className={classes.linkContent}
              key={`nav-${index}`}
              data-testid={`global-nav-${item.to}`}>
              <Button
                color="inherit"
                id={`button-${index}`}
                onClick={(e) => {
                  handleClick(e, item);
                }}
                className={
                  activeNavLink && activeNavLink.to === item.to
                    ? clsx(classes.linkedButton, classes.linkItemActive)
                    : classes.linkedButton
                }>
                {item.label}
                {item.childLinks && <KeyboardArrowDown className={classes.linkIcon} />}
              </Button>
              <ClickAwayListener onClickAway={handleClose}>
                <Popper
                  open={item.label === openedChild}
                  transition
                  placement={'bottom-start'}
                  anchorEl={document.getElementById(`button-${index}`)}
                  className={classes.popper}>
                  {({TransitionProps, placement}) => (
                    <Grow {...TransitionProps} style={{transformOrigin: placement}}>
                      <Paper id="menu-list-grow">
                        <MenuList className={classes.menuListColumn}>
                          {item.childLinks
                            ?.filter((childItem) => {
                              if (isNullish(childItem.hasPermission)) return true;
                              if (isNullish(hospital) || isNullish(user)) return false;
                              return childItem.hasPermission(hospital, user);
                            })
                            .map((i, childIndex) => {
                              return (
                                <NavLink to={i.to} className={classes.linkContent} key={`childMenu-${childIndex}`}>
                                  <MenuItem
                                    key={childIndex}
                                    value={i.label}
                                    className={classes.menuItemHovered}
                                    onClick={() => {
                                      handleMenuClick();
                                    }}>
                                    {i.label}
                                  </MenuItem>
                                </NavLink>
                              );
                            })}
                        </MenuList>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </ClickAwayListener>
            </NavLink>
          </Grid>
        ))}
    </Grid>
  );
};
