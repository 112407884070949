import React from 'react';

export const MoveTargetBuildingSVG = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 7.55556V4H4V20H13V7.55556ZM7.6 18.2222H5.8V16.4444H7.6V18.2222ZM7.6 14.6667H5.8V12.8889H7.6V14.6667ZM7.6 11.1111H5.8V9.33333H7.6V11.1111ZM7.6 7.55556H5.8V5.77778H7.6V7.55556ZM11.2 18.2222H9.4V16.4444H11.2V18.2222ZM11.2 14.6667H9.4V12.8889H11.2V14.6667ZM11.2 11.1111H9.4V9.33333H11.2V11.1111ZM11.2 7.55556H9.4V5.77778H11.2V7.55556Z"
        fill="#172B4D"
      />
      <path d="M14 10L16.625 10L16.625 7L21 12L16.625 17L16.625 14L14 14L14 10Z" fill="#172B4D" />
    </svg>
  );
};
