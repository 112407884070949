export const occupationOptions = [
  {value: 'clinical_engineer', label: '臨床工学技士'},
  {value: 'radiological_technologist', label: '診療放射線技師'},
  {value: 'clinical_laboratory_technician', label: '臨床検査技師'},
  {value: 'physical_therapist', label: '理学療法士'},
  {value: 'occupational_therapist', label: '作業療法士'},
  {value: 'doctor', label: '医師'},
  {value: 'nurse', label: '看護師'},
  {value: 'pharmacist', label: '薬剤師'},
  {value: 'general_affairs_department', label: '事務課'},
  {value: 'other', label: 'その他'},
];
