import {Atom, atom, useAtomValue} from 'jotai';
import {SelectedViewType} from './types';
import {selectAtom, useReducerAtom} from 'jotai/utils';
import {useCallback, useMemo} from 'react';

// State
export type FaultRatioState = {
  initialized: boolean;
  selectView: SelectedViewType;
  selectedRootCategoryHashId: string | null;
};

export const initialState: FaultRatioState = {
  initialized: false,
  selectView: 'symptomCategory',
  selectedRootCategoryHashId: null,
};

export const faultRatioStateAtom = atom<FaultRatioState>({...initialState});

// ActionCreator
export const createSetInitializedAction = (initialized: boolean) => ({
  type: 'SET_INITIALIZED' as const,
  initialized,
});

export const createChangeSelectViewAction = (selectView: SelectedViewType) => ({
  type: 'CHANGE_SELECT_VIEW' as const,
  selectView,
});

export const createChangeSelectedRootCategoryHashId = (rootCategoryHashId: string | null) => ({
  type: 'CHANGE_SELECT_ROOT_CATEGORY_HASH_ID' as const,
  rootCategoryHashId,
});

export type Actions =
  | ReturnType<typeof createSetInitializedAction>
  | ReturnType<typeof createChangeSelectViewAction>
  | ReturnType<typeof createChangeSelectedRootCategoryHashId>;

// Reducer
const costTrendsByCostCategoryStateReducer = (prev: FaultRatioState, action: Actions): FaultRatioState => {
  switch (action.type) {
    case 'SET_INITIALIZED':
      return {...prev, initialized: action.initialized};
    case 'CHANGE_SELECT_VIEW':
      return {...prev, selectView: action.selectView};
    case 'CHANGE_SELECT_ROOT_CATEGORY_HASH_ID':
      return {...prev, selectedRootCategoryHashId: action.rootCategoryHashId};
    default:
      console.warn('undefined action is dispatched', action);
      return prev;
  }
};

// helper
export const useFaultRatioState = () => useReducerAtom(faultRatioStateAtom, costTrendsByCostCategoryStateReducer);

const useMemoizedDispatch = () => {
  const [, dispatch] = useFaultRatioState();
  return useMemo(() => dispatch, [dispatch]);
};

const useSelectState = <T, U = T>(valueAtom: Atom<T>, actionCreator: (value: U) => Actions) => {
  const value = useAtomValue(valueAtom);
  const dispatch = useMemoizedDispatch();
  const update = useCallback((x: U) => dispatch(actionCreator(x)), [actionCreator, dispatch]);
  return [value, update] as const;
};

// hooks
const _initializedAtom = selectAtom(faultRatioStateAtom, (state) => state.initialized);
export const useInitialized = () =>
  useSelectState(_initializedAtom, (initialized: boolean) => createSetInitializedAction(initialized));

const _selectViewAtom = selectAtom(faultRatioStateAtom, (state) => state.selectView);
export const useSelectView = () =>
  useSelectState(_selectViewAtom, (selectView) => createChangeSelectViewAction(selectView));

const _selectedRootCategoryHashIdAtom = selectAtom(faultRatioStateAtom, (state) => state.selectedRootCategoryHashId);
export const useSelectedRootCategoryHashId = () =>
  useSelectState(_selectedRootCategoryHashIdAtom, (selectedRootCategoryHashId) =>
    createChangeSelectedRootCategoryHashId(selectedRootCategoryHashId)
  );
