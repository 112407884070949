import {Grid, styled, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import React, {useMemo} from 'react';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {useSearchVariables, useTrainingPlansQueryOptions} from '../hooks';
import {useDebounceCallback} from '@front-libs/core';

import {TrainingTypeValue} from '@modules/training_plans/types';
import {trainingTypeOptions} from '@modules/training_plans/constants';

export const Toolbar: React.FC = () => {
  const {variables, setName, setTrainingPlanHashIds, setTrainingCategories} = useSearchVariables();
  const {trainingPlanOptions} = useTrainingPlansQueryOptions();

  const initialTrainingPlanOptions = useMemo(
    () => trainingPlanOptions.filter((item) => variables.trainingPlanHashIds?.includes(item.value)),
    [trainingPlanOptions, variables.trainingPlanHashIds]
  );

  const initialTrainingCategoryOptions = useMemo(
    () => trainingTypeOptions.filter((item) => variables.trainingTypes?.includes(item.value as TrainingTypeValue)),
    [variables.trainingTypes]
  );

  const handleSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    300,
    [setName]
  );

  const handleChangeTrainingPlans = (selectValue?: SelectOptionProps[]) => {
    setTrainingPlanHashIds(selectValue?.map((item) => item.value) ?? []);
  };

  const handleTrainingCategoryOptions = (selectValue?: SelectOptionProps[]) => {
    setTrainingCategories(selectValue?.map((item) => item.value as TrainingTypeValue) ?? []);
  };

  return (
    <ToolBarContainer container>
      <SearchNameContainer item sm={4} md={3}>
        <SearchNameTextField
          label={'研修名で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={variables.name}
          onChange={handleSearchName}
        />
      </SearchNameContainer>
      <Grid item>
        <Grid container>
          <Grid item>
            <PopperSelectBoxButton
              buttonLabel={'研修計画マスタ'}
              options={trainingPlanOptions}
              isMulti={true}
              onChange={handleChangeTrainingPlans}
              searchable={false}
              initialOption={initialTrainingPlanOptions}
            />
          </Grid>
          <Grid item>
            <PopperSelectBoxButton
              buttonLabel={'研修内容'}
              options={trainingTypeOptions}
              isMulti={true}
              onChange={handleTrainingCategoryOptions}
              searchable={false}
              initialOption={initialTrainingCategoryOptions}
            />
          </Grid>
        </Grid>
      </Grid>
    </ToolBarContainer>
  );
};

const ToolBarContainer = styled(Grid)({});

const SearchNameContainer = styled(Grid)({
  marginRight: 32,
});

const SearchNameTextField = styled(TextField)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));
