import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {MobileProductDetailHeader} from '@organisms/MobileProductDetailHeader';
import {Divider, Tab, Tabs, styled} from '@material-ui/core';
import {
  BasicHospitalProductInfo,
  DetailHospitalProductInfo,
  FileContent,
  TimelineContent,
  WholeProductInfo,
} from './MobileContent';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@components/molecules/Loading';
import {useWindowSize} from 'react-use';

const tabs = [
  {label: '機種情報', value: 'wholeProduct'},
  {label: '機器情報', value: 'hospitalProduct'},
  {label: '詳細情報', value: 'hospitalProductDetail'},
  // {label: 'メーカー保守設定情報', value: 'makerInspectionSetting'},
  {label: 'タイムライン', value: 'timeline'},
  {label: 'ファイル', value: 'files'},
];

const Container = styled('div')({
  // backgroundColor: '#cdbeed',
  overflow: 'auto',
});

const StyledContainsDiv = styled(({height, ...otherProps}) => <div {...otherProps} />)(
  ({height}: {height: number}) => ({
    marginTop: `${height + 0}px`,
    height: `auto`,
    width: '100%',
    backgroundColor: 'white',
  })
);

const StyledTabs = styled(({width, ...otherProps}) => <Tabs {...otherProps} />)(({width}: {width: number}) => ({
  width: width,
  backgroundColor: 'white',
}));

const FixedDiv = styled('div')({
  position: 'fixed',
  zIndex: 1,
  top: 0,
  left: 0,
});

export const MobileProductDetail = () => {
  const {hashId} = useParams();
  const [tab, setTab] = React.useState('wholeProduct');
  const {myInfo} = useMyInfo();
  const divRef = useRef<HTMLDivElement | null>(null);
  const query = useFetchHospitalProductQuery(myInfo.hospitalHashId, hashId ?? '');
  const [elementHeight, setElementHeight] = useState<number>(220);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!query.data || !divRef.current) return;
    const observedElement = divRef.current;
    // ResizeObserverでElementのサイズ変更を検知
    // https://developer.mozilla.org/ja/docs/Web/API/ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setElementHeight(entry.target.clientHeight);
      }
    });
    resizeObserver.observe(observedElement);

    return () => {
      if (observedElement) {
        resizeObserver.unobserve(observedElement);
      }
    };
  }, [query.data]);

  if (query.isLoading || query.data === undefined) {
    return <InnerLoading />;
  }
  return (
    <Container>
      <FixedDiv ref={divRef}>
        <MobileProductDetailHeader hospitalProductDetail={query.data} />
        <StyledTabs
          value={tab}
          variant="scrollable"
          scrollButtons="on"
          onChange={(_e: React.ChangeEvent, value: string) => setTab(value)}
          indicatorColor="primary"
          textColor="primary"
          width={windowSize.width}>
          {tabs.map((item, index) => (
            <Tab key={`tabs_${index}`} label={item.label} value={item.value} />
          ))}
        </StyledTabs>
        <Divider />
      </FixedDiv>
      <StyledContainsDiv height={elementHeight}>
        {tab === 'wholeProduct' && <WholeProductInfo hospitalProductHashId={hashId ?? ''} />}
        {tab === 'hospitalProduct' && <BasicHospitalProductInfo hospitalProductHashId={hashId ?? ''} />}
        {tab === 'hospitalProductDetail' && <DetailHospitalProductInfo hospitalProductHashId={hashId ?? ''} />}
        {tab === 'timeline' && <TimelineContent hospitalProductHashId={hashId ?? ''} />}
        {tab === 'files' && <FileContent hospitalProductHashId={hashId ?? ''} elementHeight={elementHeight} />}
        {/* <MobileProductContent /> */}
      </StyledContainsDiv>
    </Container>
  );
};
