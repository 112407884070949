import React, {Suspense, useMemo, useEffect} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Dialog} from '@atoms/Dialog/Dialog';
import {TrainingTypeValue} from '@modules/training_plans/types';
import {yup} from '@front-libs/core';
import {Form, Formik, useFormikContext} from 'formik';
import {Button, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField} from '@material-ui/core';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import Select, {ValueType} from 'react-select';
import {InferType} from 'yup';
import {theme} from '@atoms/theme';
import {convertDisplaySimpleDate, isNullish} from '@front-libs/helpers';
import {useTrainingPlanOptions} from '@modules/training_plans/hooks';
import dayjs from 'dayjs';

const validationSchema = yup.object({
  trainingPlanHashId: yup.mixed().when([], {
    is: (value: unknown) => typeof value === 'string',
    then: yup.string().nullable(),
    otherwise: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable(),
  }),
  trainingPlanName: yup.string(),
  trainingTypes: yup.mixed().when([], {
    is: (value: TrainingTypeValue[]) => !isNullish(value) && typeof value[0] === 'string',
    then: yup.array().nullable(),
    otherwise: yup
      .array(
        yup.object({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      )
      .nullable(),
  }),
  wholeProductHashIds: yup.mixed().when([], {
    is: (value: string[]) => !isNullish(value) && typeof value[0] === 'string',
    then: yup.array().of(yup.string()).nullable(),
    otherwise: yup
      .array(
        yup.object({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      )
      .nullable(),
  }),
  place: yup.string(),
  numberOfTrainees: yup.number().min(1),
  startAt: yup.string().required(),
  finishAt: yup.string().required(),
});

type TrainingFormData = InferType<typeof validationSchema>;

export type TrainingScheduleDialogResult = {
  trainingPlanHashId?: string;
  trainingPlanName?: string;
  trainingTypes?: TrainingTypeValue[];
  wholeProductHashIds?: string[];
  place?: string;
  startAt: Date;
  finishAt: Date;
  numberOfTrainees?: number;
};
export type TrainingScheduleDialogProps = DialogProps<TrainingFormData> & {
  isEdit: boolean;
  sourceTrainingPlanHashId?: string;
  initialValues?: TrainingFormData;
};

type TrainingScheduleFormProps = {
  onCancel: VoidFunction;
  sourceTrainingPlanHashId?: string;
  isEdit: boolean;
};

const TrainingScheduleForm: React.FC<TrainingScheduleFormProps> = ({onCancel, isEdit, sourceTrainingPlanHashId}) => {
  const {setFieldValue, values, isValid, handleSubmit, handleChange} = useFormikContext<TrainingFormData>();
  const {trainingPlanOptions} = useTrainingPlanOptions();

  useEffect(() => {
    if (isNullish(sourceTrainingPlanHashId)) return;
    const initialTrainingPlan = trainingPlanOptions.find((item) => item.value === sourceTrainingPlanHashId);
    if (isNullish(initialTrainingPlan)) return;

    setFieldValue('trainingPlanHashId', initialTrainingPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceTrainingPlanHashId, trainingPlanOptions]);

  return (
    <>
      <DialogContent>
        <Form style={{width: '100%'}}>
          {isNullish(sourceTrainingPlanHashId) && (
            <div style={{width: '100%', marginBottom: '16px'}}>
              <RequiredLabel>紐づく研修計画マスタ</RequiredLabel>
              <Select
                menuPortalTarget={document.body}
                name="trainingPlanHashId"
                styles={{
                  menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 10000000}),
                }}
                value={values.trainingPlanHashId}
                options={trainingPlanOptions}
                onChange={(val: ValueType<{label: string; value: string}, false>) => {
                  setFieldValue('trainingPlanHashId', val);
                }}
              />
            </div>
          )}

          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>研修場所</label>
            <TextField
              fullWidth
              name="place"
              type="text"
              variant="outlined"
              size={'small'}
              value={values.place}
              onChange={handleChange}
            />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <label>対象人数</label>
            <TextField
              fullWidth
              name="numberOfTrainees"
              type="number"
              variant="outlined"
              size={'small'}
              value={values.numberOfTrainees}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">名</InputAdornment>,
              }}
            />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>予定日時</RequiredLabel>
            <Grid container alignItems="center">
              <Grid item>
                <TextField
                  variant="outlined"
                  type="date"
                  name="startAt"
                  value={convertDisplaySimpleDate(dayjs(values.startAt).toDate(), 'YYYY-MM-DD')}
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  InputProps={{inputProps: {max: values.finishAt}}}
                />
              </Grid>
              <Grid item style={{marginLeft: 16, marginRight: 16}}>
                〜
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  type="date"
                  name="finishAt"
                  value={convertDisplaySimpleDate(dayjs(values.finishAt).toDate(), 'YYYY-MM-DD')}
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  InputProps={{inputProps: {min: values.startAt}}}
                />
              </Grid>
            </Grid>
          </div>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={() => handleSubmit()}>
          {isEdit ? '更新' : '登録'}
        </Button>
        <Button onClick={() => onCancel()} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </>
  );
};

const InnerTrainingScheduleDialog: React.FC<TrainingScheduleDialogProps> = ({
  isEdit,
  sourceTrainingPlanHashId,
  initialValues,
  ...props
}) => {
  const handleSubmit = (values: TrainingFormData) => {
    props.actions.resolve({
      ...values,
      startAt: convertDisplaySimpleDate(dayjs(values.startAt).toDate(), 'YYYY-MM-DD'),
      finishAt: convertDisplaySimpleDate(dayjs(values.finishAt).toDate(), 'YYYY-MM-DD'),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      wholeProductHashIds: (values.wholeProductHashIds ?? []).map((item: any) => item.value), //FIXME: 型が難しい
      trainingPlanHashId: values.trainingPlanHashId?.value ?? '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      trainingTypes: (values.trainingTypes ?? []).map((item: any) => item.value as TrainingTypeValue), //FIXME: 型が難しい
    });
  };
  const initialData = useMemo<TrainingFormData>(() => {
    if (!isNullish(initialValues)) {
      return {
        trainingPlanName: initialValues.trainingPlanName,
        trainingPlanHashId: initialValues.trainingPlanHashId,
        wholeProductHashIds: [],
        trainingTypes: [],
        startAt: initialValues.startAt,
        finishAt: initialValues.finishAt,
        numberOfTrainees: initialValues.numberOfTrainees,
        place: initialValues.place,
      };
    } else {
      return {
        trainingPlanName: undefined,
        trainingPlanHashId: null,
        wholeProductHashIds: [],
        trainingTypes: [],
        startAt: dayjs().toString(),
        finishAt: dayjs().toString(),
        numberOfTrainees: undefined,
        place: '',
      };
    }
  }, [initialValues]);

  return (
    <Formik<TrainingFormData>
      initialValues={initialData}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={handleSubmit}>
      <Dialog
        open={props.open}
        onClose={() => props.actions.reject()}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}>
        <DialogTitle>{isEdit ? '予定を更新' : '新規予定'}</DialogTitle>
        <TrainingScheduleForm
          onCancel={props.actions.reject}
          isEdit={isEdit}
          sourceTrainingPlanHashId={sourceTrainingPlanHashId}
        />
      </Dialog>
    </Formik>
  );
};

export const TrainingScheduleDialog: React.FC<TrainingScheduleDialogProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <InnerTrainingScheduleDialog {...props} />
    </Suspense>
  );
};
