import {IconButton, TableRow} from '@material-ui/core';
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import React from 'react';
import {AnnualPlanTableBodyCell, AnnualPlanTableCategoryBodyCell} from './AnnualPlanTableCell';

type CollapsedTableRowProps = {
  categoryHashId: string;
  categoryName: string;
  monthlyCount: number[];
  narrowCategories: {
    categoryHashId: string;
    categoryName: string;
    monthlyCount: number[];
  }[];

  onClickCount: (month: number, categoryHashId: string, isRootCategory: boolean) => void;
};

export const CollapsedTableRow: React.FC<CollapsedTableRowProps> = (item) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow key={item.categoryName}>
        <AnnualPlanTableCategoryBodyCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{marginLeft: -8}}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          {item.categoryName}
        </AnnualPlanTableCategoryBodyCell>
        {item.monthlyCount.map((monthCount, idx) => (
          <AnnualPlanTableBodyCell key={idx} hasCount={monthCount > 0}>
            {monthCount === 0 ? (
              '-'
            ) : (
              <span style={{cursor: 'pointer'}} onClick={() => item.onClickCount(idx + 1, item.categoryHashId, true)}>
                {monthCount}
              </span>
            )}
          </AnnualPlanTableBodyCell>
        ))}
      </TableRow>
      {open &&
        item.narrowCategories.map((narrowCategory, index) => (
          <TableRow key={narrowCategory.categoryName} style={index === 0 ? {paddingTop: '0 !important'} : undefined}>
            <AnnualPlanTableCategoryBodyCell style={{paddingLeft: 40}}>
              {narrowCategory.categoryName}
            </AnnualPlanTableCategoryBodyCell>
            {narrowCategory.monthlyCount.map((monthCount, idx) => (
              <AnnualPlanTableBodyCell
                key={idx}
                hasCount={monthCount > 0}
                style={index === 0 ? {paddingTop: '0 !important'} : undefined}>
                {monthCount === 0 ? (
                  '-'
                ) : (
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={() => item.onClickCount(idx + 1, narrowCategory.categoryHashId, false)}>
                    {monthCount}
                  </span>
                )}
              </AnnualPlanTableBodyCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};
