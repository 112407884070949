import React, {useMemo, useState, useCallback} from 'react';
import {Avatar, Button, makeStyles, Theme, TextField, Typography} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import dayjs from 'dayjs';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {StrUtil} from '@front-libs/helpers';
import {UserIndex} from '@modules/hospital_users/types';
import {ArrowTooltipComponent} from '@components/atoms/ArrowTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {DeletedCommentCard} from './DeletedCommentCard';
import {PinIcon} from '@atoms/Icons/pin';

/**
 * コメントカードのProps
 */
export type CommentCardProps = {
  user?: UserIndex;
  displayDate?: Date;
  description?: string;
  updateBy?: UserIndex;
  updateAt?: string;
  isArchived?: boolean;
  pinned?: boolean;
  isPinnedArea?: boolean;
  canDelete: boolean;
  isReadOnly: boolean;
  /**
   * コメントが更新された時に呼び出される関数
   * @param comment - 更新されたコメント。
   */
  onUpdateComment: (comment: string) => void;
  /**
   * ピンどめが更新された時に呼び出される関数
   */
  onUpdatePinned: () => void;

  /**
   * コメントが削除された時に呼び出される関数
   */
  onDeleteComment: () => void;
};

const AvatarIcon = styled(Avatar)({
  width: '32px',
  height: '32px',
});

/**
 * コメントカードの表示
 * @param {CommentCardProps} CommentCardProps
 * @param user - 作成者のUserIndex
 * @param displayDate - 作成日のDate
 * @param description - コメント内容
 * @param updateBy - 編集者のUserIndex
 * @param updateAt - 変更日
 * @param isArchived - 削除されているか
 * @param pinned - ピンどめされているか
 * @param canDelete - 削除操作を表示するか
 * @param isReadOnly - アクションを表示するか
 * @returns
 */
// FIXME: useMyInfoの呼び出しを消す
export const ProductDetailCommentCard = ({
  user,
  updateAt,
  updateBy,
  displayDate,
  description,
  pinned,
  isArchived,
  isPinnedArea,
  canDelete,
  isReadOnly,

  onUpdateComment,
  onUpdatePinned,
  onDeleteComment,
}: CommentCardProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [commentUpdateAt, setCommentUpdateAt] = useState<Date>();
  const [editingComment, setEditingComment] = useState(description ?? '');

  const comment = useMemo(() => {
    return description ? description : '';
  }, [description]);

  const {myInfo} = useMyInfo();
  const classes = useHistoryCardStyles();

  /** コメントを保存し、編集モードを終了、親コンポーネントに更新を通知する関数 */
  const handleSaveComment = useCallback(() => {
    setIsEditing(false);
    // 編集済み表示判別に利用
    setCommentUpdateAt(new Date());
    onUpdateComment(editingComment);
  }, [editingComment, onUpdateComment]);

  /** コメントを削除し、編集モードを終了、親コンポーネントに削除を通知する関数 */
  const handleCancelEditComment = useCallback(() => {
    setIsEditing(false);
    setEditingComment(comment);
  }, [comment]);

  /** アクションボタン押下時 */
  const handleActionMenuClick = useCallback(
    async (item: MenuItemType) => {
      switch (item.value) {
        case 'edit':
          setIsEditing(true);
          break;
        case 'pin':
          onUpdatePinned();
          break;
        case 'delete':
          onDeleteComment();
          break;
        default:
          break;
      }
    },
    [onUpdatePinned, onDeleteComment]
  );

  /**
   * アクションボタンを使える権限があるか
   * 自身のコメントもしくは管理者権限があればtrueを返す
   **/
  const canActionButton = useMemo(() => {
    if (isReadOnly) return false;
    if (!user) return false;
    if (user.hashId === myInfo.hashId) return true;
    return myInfo.isUserAdmin;
  }, [isReadOnly, user, myInfo.hashId, myInfo.isUserAdmin]);

  /** コメントを編集可能か */
  const isEditableComment = useMemo(() => {
    if (!user) return false;
    return user.hashId === myInfo.hashId;
  }, [user, myInfo]);

  /** 編集済み表示 */
  const editedView = useMemo(() => {
    const currentUpdateAt = commentUpdateAt || (updateAt && new Date(updateAt));
    // NOTE:displayDateはcreatedAtから作られている +をつけることでミリ秒に変換できる
    if (!currentUpdateAt || !displayDate || +displayDate === +currentUpdateAt) return null;

    const dayText = () => {
      const today = dayjs();
      const update = dayjs(currentUpdateAt);
      if (today.isSame(update, 'day')) return '今日';
      if (today.subtract(1, 'day').isSame(update, 'day')) return '昨日';
      return update.format('YYYY年M月D日 HH:mm');
    };

    return (
      <ArrowTooltipComponent tooltipText={dayText()}>
        <StyledEditedText>{'(編集済み)'}</StyledEditedText>
      </ArrowTooltipComponent>
    );
  }, [displayDate, updateAt, commentUpdateAt]);

  /** 削除コメント表示 */
  const deleteCard = useMemo(() => {
    if (!updateBy || !updateAt || !displayDate) return null;
    return (
      <DeletedCommentCard
        displayDate={displayDate}
        updateBy={updateBy}
        updateAt={commentUpdateAt || new Date(updateAt)}
      />
    );
  }, [updateBy, updateAt, displayDate, commentUpdateAt]);

  const actionMenuItems = useMemo(() => {
    const items = [
      {
        label: (
          <MenuItem>
            <PinIcon style={{paddingRight: '8px'}} />
            <MenuItemLabel>{pinned ? 'ピンどめを解除' : 'コメントをピンどめ'}</MenuItemLabel>
          </MenuItem>
        ),
        value: 'pin',
      },
    ];
    if (!isPinnedArea && canDelete) {
      items.push({
        label: (
          <MenuItem style={{color: '#C7243A'}}>
            <DeleteIcon style={{paddingRight: '8px'}} />
            <MenuItemLabel>コメントを削除</MenuItemLabel>
          </MenuItem>
        ),
        value: 'delete',
      });
    }
    if (isEditableComment && !isPinnedArea) {
      items.unshift({
        label: (
          <MenuItem>
            <CreateIcon style={{paddingRight: '8px'}} />
            <MenuItemLabel>コメントを編集</MenuItemLabel>
          </MenuItem>
        ),
        value: 'edit',
      });
    }
    return items;
  }, [pinned, isPinnedArea, canDelete, isEditableComment]);
  // 削除済み表示
  if (isArchived) return deleteCard;

  return (
    <RootContainer style={isPinnedArea ? {marginTop: '8px'} : {}}>
      <AvatarIcon />
      <CommentContentContainer>
        <CommentContentHeader>
          <UserName>{user ? UserFormatter.getFullName(user) : '不明なユーザー'}</UserName>
          <div style={{display: 'flex'}}>
            {!isPinnedArea && pinned && (
              <PinIcon style={{width: '18px', height: '18px', color: '#2A96E8', padding: '7px'}} />
            )}
            {canActionButton && (
              <PopperMenuButton
                hiddenArrow
                menuItemList={actionMenuItems}
                placement={'bottom-end'}
                buttonProps={{size: 'small', color: 'inherit', style: {minWidth: '14px', marginRight: '8px'}}}
                itemProps={{style: {fontSize: '14px'}}}
                onMenuClick={handleActionMenuClick}>
                <MoreVertIcon />
              </PopperMenuButton>
            )}
            <CommentDate>{dayjs(displayDate).format('MM/DD HH:mm')}</CommentDate>
          </div>
        </CommentContentHeader>
        {isEditing ? (
          <>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              className={classes.commentEditor}
              value={editingComment}
              onChange={(e) => setEditingComment(e.target.value)}
              style={{marginBottom: '16px'}}
            />
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button className={classes.editCommentBtn} style={{marginRight: '8px'}} onClick={handleSaveComment}>
                保存
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCancelEditComment}>
                キャンセル
              </Button>
            </div>
          </>
        ) : (
          <StyledCommentButton disabled fullWidth>
            {StrUtil.nl2br(comment)}
          </StyledCommentButton>
        )}
        {editedView}
      </CommentContentContainer>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const CommentContentContainer = styled('div')({
  marginLeft: '8px',
  width: '100%',
});

const CommentContentHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const UserName = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '14px',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
  lineHeight: '32px',
});

const MenuItem = styled('span')({
  display: 'flex',
});

const MenuItemLabel = styled('span')({
  lineHeight: '24px',
});

const StyledCommentButton = styled(Button)(({theme}: {theme: Theme}) => ({
  color: theme.palette.common.black,
  padding: '0',
  boxSizing: 'border-box',
  justifyContent: 'flex-start',
  textAlign: 'left',
  '&.Mui-disabled': {
    color: theme.palette.common.black,
  },
}));

const StyledEditedText = styled('span')(({theme}: {theme: Theme}) => ({
  padding: '0px 8px',
}));

const useHistoryCardStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
  },
  cardHeader: {
    paddingTop: 8,
    paddingLeft: 16,
  },
  actionMenu: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  flex: {
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 700,
  },
  displayDate: {
    color: theme.palette.grey[600],
  },
  propertyContainer: {
    paddingTop: 16,
    paddingLeft: 8,
    paddingBottom: 8,
  },
  property: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.common.black,
  },
  propertyLabel: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.grey[600],
  },
  commentBtnIcon: {
    visibility: 'hidden',
    transition: 'visibility',
    transitionDelay: '100ms',
  },
  commentEditor: {
    '& .MuiOutlinedInput-multiline': {
      padding: '8px 16px',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  cancelBtn: {
    fontSize: '14px',
    borderRadius: '3px',
    border: 'none',
    padding: '4px 16px',
    color: '#42526E',
  },
  editCommentBtn: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    borderRadius: '3px',
    padding: '4px 16px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
}));
