import {atom} from 'jotai';
import {MultipleAuth0Cache} from './auth0-cache';
import {MODE_KEY, MODE_NEW_SIGNIN} from './consts';
import {AuthnUser} from './types';

export const authnUser = atom<AuthnUser | null>(null);
export const accessToken = atom<string | null>(null);

const shouldSignIn = new URLSearchParams(window.location.search).get(MODE_KEY) !== MODE_NEW_SIGNIN;

export const auth0CacheAtom = atom<MultipleAuth0Cache>(new MultipleAuth0Cache(shouldSignIn));
