import React, {useCallback, useState} from 'react';
import {Button, Grid, makeStyles, Paper, Theme, Typography} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {StandardTextField} from '@molecules/Formik/fields/StandardTextField';
import {StandardSelect} from '@molecules/Formik/fields/StandardSelectorField';
import {openSnackBar} from '@molecules/SnackBar';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

import {useBackPrevious} from '@front-libs/core';
import {TrainingSchedule} from '@modules/training_schedules/types';
import {updateTrainingSchedule} from '@modules/training_schedules/api';
import {FormFieldSection, FormSection} from '@Apps/TrainingManagementScheduleDetail/SideNav/FormFieldSection';
import {
  TrainingScheduleFormFieldSection,
  TrainingScheduleFormSection,
} from '@Apps/TrainingManagementScheduleDetail/SideNav/TrainingScheduleFormFieldSection';
import {trainingTypeOptions} from '@modules/training_plans/constants';
import {isNullish} from '@front-libs/helpers';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const trainingPlanFormSection: FormSection[] = [
  {
    sectionName: '研修計画マスタ',
    fields: [
      {
        type: 'text',
        label: '研修名称',
        name: 'trainingPlan',
        getValue: (schedule: TrainingSchedule) => (schedule ? schedule?.trainingPlan.name : ''),
        InputComponent: StandardTextField,
        readOnly: true,
      },
      {
        type: 'select',
        label: '研修の項目',
        name: 'hashId',
        InputComponent: StandardSelect,
        options: trainingTypeOptions,
        getValue: (schedule: TrainingSchedule) => (schedule ? schedule?.trainingPlan.trainingTypes : ''),
        multiple: true,
        readOnly: true,
      },
    ],
  },
];
const trainingScheduleFormSection: Omit<TrainingScheduleFormSection, 'isReadOnly'>[] = [
  {
    sectionName: '研修（予定）',
    fields: [
      // FIXME: 後から項目増やす？
      // {
      //   type: 'text',
      //   label: '講師',
      //   name: 'hashId',
      //   InputComponent: StandardTextField,
      // },
      {
        type: 'number',
        label: '研修対象人数',
        name: 'numberOfTrainees',
        InputComponent: StandardTextField,
        required: false,
      },
      {
        type: 'text',
        label: '研修実施場所',
        name: 'place',
        InputComponent: StandardTextField,
        required: false,
      },
    ],
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const actionMenuItems = [
  {
    label: '削除',
    value: 'delete',
  },
];

const statusActionMenuItems = [
  {
    label: '実施中',
    value: 'incomplete',
  },
  {
    label: '完了',
    value: 'done',
  },
];

export function getStatusLabelByValue(value: string): string {
  const option = statusActionMenuItems.find((item) => item.value === value);
  return option ? option.label : '';
}

type Props = {
  trainingSchedule: TrainingSchedule | null;
  onUpdateComplete?: () => void;
};

export const SideNav: React.VFC<Props> = ({trainingSchedule, onUpdateComplete}) => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const backPrevious = useBackPrevious('/training_managements/schedules');

  const handleClickSchedulesLink = useCallback(() => {
    backPrevious();
  }, [backPrevious]);

  const handleDeleteTrainingSchedule = async () => {
    openSnackBar('実装中です。');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleActionMenuClick = (item: MenuItemType) => {
    switch (item.value) {
      case 'delete':
        setOpenDeleteDialog(true);
        break;
    }
  };

  // status変更
  const handleStatusActionMenuClick = useCallback(
    async (item: MenuItemType) => {
      await updateTrainingSchedule(myInfo.hospitalHashId, {
        trainingScheduleHashId: trainingSchedule?.hashId ?? '',
        status: item.value,
      });
      if (onUpdateComplete) onUpdateComplete();
      openSnackBar('ステータスを更新しました');
    },

    [myInfo.hospitalHashId, onUpdateComplete, trainingSchedule?.hashId]
  );

  return (
    <Paper className={classes.root}>
      <AlertDialog
        title={'予定を削除しますか？'}
        positiveButtonLabel={'予定を削除'}
        content={
          <div>
            予定を削除しようとしています。
            <br />
            削除するとこの予定に紐付いた研修受講者の情報や報告の内容は今後復元できなくなります。ご注意ください。
          </div>
        }
        open={openDeleteDialog}
        actions={{
          resolve: async () => {
            await handleDeleteTrainingSchedule();
          },
          reject: async () => {
            setOpenDeleteDialog(false);
          },
        }}
      />
      <Grid container justifyContent={'space-between'} className={classes.actionMenuContainer}>
        <Grid item>
          <Button color="inherit" className={classes.actionMenu} onClick={handleClickSchedulesLink}>
            <ChevronLeft />
            <span>研修（予定）一覧</span>
          </Button>
        </Grid>
        <Grid item>
          {/* <PopperMenuButton
            buttonProps={{color: 'inherit', className: classes.actionMenu}}
            menuItemList={actionMenuItems}
            onMenuClick={handleActionMenuClick}>
            アクション
          </PopperMenuButton> */}
        </Grid>
      </Grid>
      <Grid container className={classes.scheduleTitle} wrap="nowrap" direction={'column'}>
        <Grid item className={classes.categories}>
          <Typography className={classes.attachedTitleText}>研修（予定）</Typography>
          <Typography className={classes.titleText}>
            {`${trainingSchedule?.trainingPlan.name} ${trainingSchedule?.currentTimes}回目`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>
            ステータス：
            {isReadOnly ? (
              !isNullish(trainingSchedule) ? (
                getStatusLabelByValue(trainingSchedule.status)
              ) : (
                ''
              )
            ) : (
              <PopperMenuButton
                onMenuClick={handleStatusActionMenuClick}
                containerProps={{style: {display: 'inline-flex'}}}
                buttonProps={{color: 'inherit', className: classes.actionMenu}}
                menuItemList={statusActionMenuItems}>
                {trainingSchedule ? getStatusLabelByValue(trainingSchedule.status) : ''}
              </PopperMenuButton>
            )}
          </Typography>
        </Grid>
      </Grid>
      {trainingPlanFormSection.map((section, index) => (
        <FormFieldSection sectionName={section.sectionName} fields={section.fields} key={section.sectionName + index} />
      ))}
      {trainingScheduleFormSection.map((section, index) => (
        <TrainingScheduleFormFieldSection
          sectionName={section.sectionName}
          fields={section.fields}
          key={section.sectionName + index}
          isReadOnly={isReadOnly}
        />
      ))}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 82px)',
    paddingBottom: '82px',
    overflowY: 'auto',
  },
  attachedTitleText: {
    fontSize: '14px',
  },
  titleText: {
    fontWeight: 700,
    fontSize: '18px',
  },
  actionMenuContainer: {
    paddingTop: '8px',
  },
  actionMenu: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  scheduleTitle: {
    padding: '8px 16px',
  },
  categories: {
    '& > :not(:first-child)': {
      marginTop: '4px',
    },
  },
}));
