import {axios} from '@front-libs/core';
import {atom, useAtom} from 'jotai';

import {useEffect, useRef} from 'react';
import {HospitalDealer} from '../hospital_products/types';

export type HospitalDealers = HospitalDealer[];

export type HospitalDealerResponse = {
  page: number;
  totalCount: number;
  data: HospitalDealers;
};

export type HospitalDealerParams = {
  page: number;
  perPage: number;
  name?: string;
  order?: string;
};

/**
 * 担当代理店の一覧を取得
 * @param hospitalHashId
 * @returns
 */
export const getHospitalDealer = async (hospitalHashId: string, params?: HospitalDealerParams) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_dealer`;
  const response = await axios.get<HospitalDealerResponse>(url, params && {params});
  return response.data;
};

/**
 * 担当代理店の新規追加
 * @param hospitalHashId
 * @param params
 * @returns
 */
export const createHospitalDealer = async (hospitalHashId: string, params: HospitalDealerParams) => {
  const url = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_dealer`;
  const response = await axios.post<HospitalDealerResponse>(url, {name: params.name});
  return response.data;
};

/**
 * 担当代理店の名称変更
 * @param hospitalHashId
 * @param hospitalDealerHashId
 * @param params
 * @returns
 */
export const updateHospitalDealer = async (
  hospitalHashId: string,
  hospitalDealerHashId: string,
  params: HospitalDealerParams
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_dealer/${hospitalDealerHashId}`;
  const response = await axios.put<HospitalDealerResponse>(url, {name: params.name});
  return response.data;
};

/**
 * 担当代理店の削除
 * @param hospitalHashId
 * @param hospitalDealerHashId
 * @param params
 * @returns
 */
export const deleteHospitalDealer = async (
  hospitalHashId: string,
  hospitalDealerHashId: string,
  params: HospitalDealerParams
) => {
  const url = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/hospital_dealer/${hospitalDealerHashId}`;
  const response = await axios.delete<HospitalDealerResponse>(url, {params});
  return response.data;
};

const hospitalDealerAtom = atom<HospitalDealerResponse | undefined>(undefined);
export const useHospitalDealer = (hospitalHashId: string) => {
  const [hospitalDealer, setHospitalDealer] = useAtom(hospitalDealerAtom);

  const isLoading = useRef(true);

  useEffect(() => {
    (async () => {
      if (!isLoading.current) return;

      const response = await getHospitalDealer(hospitalHashId);

      // NOTE:deletedByに値が入っていれば削除済みとして表示させない
      response.data = response.data.filter((v) => v.deletedBy === null);
      setHospitalDealer(response);
    })();

    return () => {
      isLoading.current = false;
    };
  }, [hospitalHashId, hospitalDealer, setHospitalDealer]);

  return hospitalDealer;
};
/**
 * 指定された病院のハッシュIDに基づいて、病院製品管理セクションのオプションを管理するためのフック
 *
 * このフックは、病院の製品管理セクションをフェッチし、削除されたものをフィルタリングし、
 * アクティブなセクションで管理部門の状態を設定します。フックは管理部門の現在の状態を返します
 *
 * @param hospitalHashId - 製品管理セクションを管理する病院のハッシュID
 * @returns - 管理部門の現在の状態
 *
 * @example
 * const managementDepartment = useHospitalDealerOption('病院のハッシュID');
 */
export const useHospitalDealerOption = (hospitalHashId: string, params?: HospitalDealerParams) => {
  const hospitalDealer = useHospitalDealer(hospitalHashId);
  return hospitalDealer
    ? hospitalDealer.data.map((v) => ({
        label: v.name,
        value: v.hashId,
      }))
    : new Array<{
        label: string;
        value: string;
      }>();
};
