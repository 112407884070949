import {Typography, styled} from '@material-ui/core';
import React from 'react';
import dayjs from 'dayjs';
import {UserIndex} from '@modules/hospital_users/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {Build} from '@material-ui/icons';

export type RepairStatusChangeCardProps = {
  faultRepairHashId: string;
  displayDate: Date;
  fromStatusName: string;
  toStatusName: string;
  executeUser?: UserIndex;
};

/**
 * `修理情報のステータス変更を表示するカードを返す
 *
 * @param {RepairStatusChangeCardProps} props - コンポーネントに渡されるProps
 * @param {string} props.faultRepairHashId - 故障修理のハッシュID
 * @param {string} props.displayDate - ステータス変更が表示される日付
 * @param {string} props.fromStatusName - ステータス変更の起点となるステータスの名前
 * @param {string} props.toStatusName - ステータス変更の終点となるステータスの名前
 * @param {User} props.executeUser - ステータス変更を実行したユーザー
 *
 * @example
 * return <RepairStatusChangeCard faultRepairHashId="123" displayDate="2023-01-01" fromStatusName="Repaired" toStatusName="Shipped" executeUser={user} />
 *
 * @returns {ReactElement} 修理情報のステータス変更を表示するカードを返す
 */

export const RepairStatusChangeCard = ({
  faultRepairHashId,
  displayDate,
  fromStatusName,
  toStatusName,
  executeUser,
}: RepairStatusChangeCardProps) => {
  return (
    <RootContainer>
      <IconContainer>
        <Build style={{height: '18px'}} />
      </IconContainer>
      <Container>
        <Header>
          <Title>
            <Link href={`/repairs/${faultRepairHashId}`} target="_blank" rel="noreferrer">
              修理情報
            </Link>
            の変更
          </Title>
          <CommentDate>{dayjs(displayDate).format('MM/DD HH:mm')}</CommentDate>
        </Header>
        <Content>
          <ContentTypography>
            変更内容：ステータスを「{fromStatusName}」から「{toStatusName}」に変更
          </ContentTypography>
          <ContentTypography>
            <ShortLabel>変更者</ShortLabel>：{executeUser ? `${UserFormatter.getFullName(executeUser)}` : '不明なユーザー'}
          </ContentTypography>
        </Content>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
});

const ContentTypography = styled(Typography)({
  fontSize: '14px',
  color: '#172B4D',
});

const Container = styled('div')({
  paddingLeft: '8px',
  width: '100%',
});

const Content = styled('div')({});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const Title = styled('div')({
  fontWeight: 600,
  color: '#172B4D',
});

const Link = styled('a')({
  color: '#0052CC',
  fontWeight: 700,
  textDecoration: 'none',
});

const ShortLabel = styled('span')({
  marginRight: '14px',
});
