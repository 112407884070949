import {useMemo} from 'react';
import {useAtom} from 'jotai';
import {JwtPayload} from '../types';
import {accessToken as accessTokenAtom} from '../jotai';

export const useAuthz = () => {
  const [accessToken] = useAtom(accessTokenAtom);
  return useMemo<Partial<JwtPayload>>(() => {
    if (accessToken !== null && accessToken.includes('.')) {
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
    }
  }, [accessToken]);
};
