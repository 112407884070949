import React, {useCallback} from 'react';
import {Table} from '@molecules/Table';
import {RowAction} from '@molecules/Table/props';
import {
  useDeleteTrainingReportMutation,
  useSearchVariables,
  useTableData,
  useUpdateTrainingReportMutation,
} from '../hooks';
import {ReportTableData} from '.';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  TrainingReportDialog,
  TrainingReportDialogProps,
  TrainingReportDialogResult,
} from '../dialogs/TrainingReportDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

export const ReportTable: React.FC = () => {
  const {tableColumns, reportData, isLoading} = useTableData();
  const {setOrder} = useSearchVariables();
  const {mutate} = useUpdateTrainingReportMutation();
  const {mutate: deleteTrainingReportMutation} = useDeleteTrainingReportMutation();
  const {myInfo} = useMyInfo();
  const {isReadOnly, isAdmin} = useMyRole();

  const handleEditReport = useCallback(
    async (_e: React.MouseEvent, data: ReportTableData) => {
      const res = await dialogHandler.open<TrainingReportDialogProps, TrainingReportDialogResult>(
        TrainingReportDialog,
        {
          isEdit: true,
          initialValues: {
            trainingScheduleHashId: data.scheduleHashId,
            reportHospitalUserHashId: data.reportedUserHashId,
            startedAt: data.trainingDateRange[0],
            finishedAt: data.trainingDateRange[1],
            description: data.description,
            isCompletedSchedulePlan: false,
            numberOfAttendants: data.numberOfAttendants,
          },
          currentUser: myInfo,
        }
      );
      mutate({...res, hashId: data.hashId});
    },
    [mutate, myInfo]
  );

  const handleDeleteReport = async (_e: React.MouseEvent, data: ReportTableData) => {
    await dialogHandler.open(AlertDialog, {
      title: '実施記録を削除しますか？',
      content: 'この実施記録を削除しようとしています。\n\nこのアクションは元に戻せません。',
      positiveButtonLabel: '実施記録を削除',
    });
    deleteTrainingReportMutation(data.hashId);
    openSnackBar('実施記録を削除しました');
  };

  const rowsActions: RowAction<ReportTableData>[] = [
    {
      type: 'button',
      label: '編集',
      onClick: handleEditReport,
    },
    ...(isAdmin ? [{type: 'button' as const, label: '削除', onClick: handleDeleteReport}] : []),
  ];
  return (
    <Table<ReportTableData>
      data={reportData}
      isLoading={isLoading}
      columns={tableColumns}
      showSelection={false}
      rowActions={!isReadOnly ? rowsActions : undefined}
      onOrderChange={(columnIndex: number, orderDirection: 'desc' | 'asc') => {
        setOrder(tableColumns[columnIndex].field, orderDirection);
      }}
    />
  );
};
