import React, {useCallback, useState} from 'react';
import {Grid, makeStyles, Divider, Typography, TextField, Box, Button} from '@material-ui/core';
import {Sidebar} from '@components/organisms/Sidebar';
import {withSuspense} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {useFetchMyInfo} from '@modules/hospital_users/api';
import {EditUsernameDialog} from './Dialogs/EditUsernameDialog';
import {EditPasswordDialog} from './Dialogs/EditPasswordDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {resetUserPassword} from '@modules/auth/api';
import {UserIndex} from '@modules/hospital_users/types';

const useStyles = makeStyles({
  pageTitle: {
    fontSize: 28,
  },
  gridContainer: {
    marginBottom: 40,
  },
  usernameMargin: {
    marginBottom: 8,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 16,
  },
  passwordEditBtn: {
    padding: 0,
  },
  passwordResetBtn: {
    padding: 0,
  },
  twoStepVerificationSwitch: {
    marginBottom: 8,
  },
  twoStepVerificationText: {
    fontSize: 14,
  },
  ml8: {
    marginLeft: '-8px',
  },
  mb8: {
    marginBottom: 8,
  },
});

const SecurityForm = ({myInfo, userName}: {myInfo: UserIndex; userName: string}) => {
  const templateClasses = useSettingsContentTemplate();
  const classes = useStyles();

  const [openEditUsernameDialog, setOpenEditUsernameDialog] = useState(false);
  const [openEditPasswordDialog, setOpenEditPasswordDialog] = useState(false);

  const handleClickPasswordReset = useCallback(async () => {
    await dialogHandler.open(MessageDialog, {
      title: 'パスワード再設定メールの確認',
      content:
        '登録されているメールアドレスにパスワード再設定用の\nURLを記載したメールを送信します。\nよろしいですか？',
      positiveButtonLabel: '送信',
    });
    await resetUserPassword({hashId: myInfo.hashId});
    openSnackBar('パスワード再設定メールを送付しました。');
  }, [myInfo.hashId]);

  // const [state, setState] = useState({
  //   TwoFASwitch: true,
  // });

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({...state, [event.target.name]: event.target.checked});
  // };

  return (
    <Box className={templateClasses.form}>
      <Grid container className={templateClasses.grid}>
        <Grid item className={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item className={templateClasses.content}>
          <Grid container>
            <Grid item>
              <Typography variant={'h1'} className={classes.pageTitle}>
                セキュリティ
              </Typography>
              <p>これらの設定は自分のみに適用されます。</p>
            </Grid>
          </Grid>
          <Divider variant="middle" className={templateClasses.divider} />
          <Grid container className={classes.gridContainer}>
            <Grid item xs={4}>
              <p className={classes.usernameMargin}>ユーザーID</p>
              <TextField
                fullWidth
                disabled
                id="username"
                name="username"
                type="text"
                variant="outlined"
                size={'small'}
                value={userName}
                className={classes.usernameMargin}
              />
              <Button variant="contained" onClick={() => setOpenEditUsernameDialog(true)}>
                ユーザーIDを編集
              </Button>
              <EditUsernameDialog open={openEditUsernameDialog} onClose={() => setOpenEditUsernameDialog(false)} />
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer}>
            <Grid item>
              <Typography variant="h2" className={classes.sectionTitle}>
                パスワード
              </Typography>
              <Grid item className={classes.ml8}>
                <div className={classes.mb8}>
                  <Button color="primary" onClick={() => setOpenEditPasswordDialog(true)}>
                    パスワードを変更
                  </Button>
                </div>
                <div>
                  <Button color="primary" onClick={handleClickPasswordReset}>
                    パスワードをリセット
                  </Button>
                </div>
                <EditPasswordDialog open={openEditPasswordDialog} onClose={() => setOpenEditPasswordDialog(false)} />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container className={classes.gridContainer}>
            <Grid item xs={4}>
              <Typography variant="h2" className={classes.sectionTitle}>
                2要素認証
              </Typography>
              <FormGroup row className={classes.twoStepVerificationSwitch}>
                <FormControlLabel
                  control={
                    <Switch checked={state.TwoFASwitch} onChange={handleChange} name="TwoFASwitch" color="primary" />
                  }
                  label="2要素認証（2FA）をセットアップ"
                />
              </FormGroup>
              <Typography variant="body1" className={classes.twoStepVerificationText}>
                2要素認証はセキュリティーの強化対策です。有効にするとHitotsuへのログイン時に2段階のIDを求められます。
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export const _SettingsAccountSecurity: React.FC = () => {
  const templateClasses = useSettingsContentTemplate();
  const {myInfo} = useMyInfo();
  const {data} = useFetchMyInfo(myInfo.hashId);
  return (
    <Grid container className={templateClasses.grid}>
      <SecurityForm myInfo={myInfo} userName={data ? data.username : ''} />
    </Grid>
  );
};

export const AccountSecurity = withSuspense(_SettingsAccountSecurity, null);
