import React from 'react';
import {styled} from '@material-ui/core/styles';
import {HomeIndicatorImage} from './HomeIndicatorImage';
import {Box} from '@material-ui/core';

const StyledDialogBox = styled(Box)({
  backgroundColor: 'white',
  margin: '0px',
  zIndex: 2,
  position: 'fixed',
  left: '0',
  bottom: '0',
  maxHeight: '80%',
  width: '100%',
  borderRadius: '16px 16px 0px 0px',
});

const BackgroundBlack = styled('div')({
  backgroundColor: 'black',
  opacity: '.5',
  position: 'absolute',
  width: '100%',
  height: '100vh',
  overflow: 'visible',
  left: '0',
  top: '0',
  zIndex: 1,
});

/** iOSの画面下部ホームバーを想定してマージン追加 */
const MarginDiv = styled('div')({
  width: '100%',
  height: '16px',
});

export type MobileUnderBoxProps = {
  children: React.ReactNode;
  close: () => void;
};

/**
 * `MobileUnderBox` コンポーネントはモバイル画面の下部に表示されるダイアログボックス
 *
 * @param {MobileUnderBoxProps} props - このコンポーネントのプロパティ
 * @returns モバイル画面下部に配置されるダイアログボックスを返します。
 *
 * `MobileUnderBoxProps`:
 * @param  `children` (React.ReactNode): このコンポーネント内に表示される子要素。ユーザーが定義した任意のReactノードを渡すことができます。
 * @example
 * ```jsx
 * <MobileUnderBox children={<div>TEST</div>} close={closeFunc} />
 * ```
 */
export const MobileUnderBox = ({children, close}: MobileUnderBoxProps) => {
  return (
    <>
      <StyledDialogBox>
        <HomeIndicatorImage />
        {children}
        <MarginDiv />
      </StyledDialogBox>
      <BackgroundBlack onClick={close} />
    </>
  );
};
