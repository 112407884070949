import React from 'react';
import {Grid, Typography, makeStyles, Card, CardContent} from '@material-ui/core';
import clsx from 'clsx';
import {Launch} from '@material-ui/icons';
import {InspectionIndex} from '@modules/inspections/types';
import {isNullish} from '@front-libs/helpers';

export type InspectionCardProps = {
  cardTitle: string;
  inspection?: InspectionIndex;
  onClickInspectionName?: () => void;
  currentTitle?: string;
};

export const InspectionCard: React.FC<InspectionCardProps> = (props) => {
  const {cardTitle, inspection, currentTitle} = props;
  const classes = useStyles();

  const inspectionName = inspection?.name || currentTitle || '未設定';

  return (
    <Card variant="elevation">
      <CardContent>
        <Typography className={classes.title}>{cardTitle}</Typography>
        {isNullish(inspection) ? (
          <Typography className={classes.inspectionName} style={{fontWeight: 'bold'}}>
            {inspectionName}
          </Typography>
        ) : (
          <Grid
            container
            alignItems="center"
            className={clsx(classes.inspectionName, classes.activeInspectionName)}
            onClick={props.onClickInspectionName}>
            <span>{inspectionName}</span>
            <Launch fontSize="small" />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#172B4D',
  },
  inspectionName: {
    color: '#2a96e8',
    fontWeight: 'bold',
  },
  activeInspectionName: {
    cursor: 'pointer',
  },
}));
