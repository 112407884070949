import {useCallback} from 'react';
import {useLocalStorage} from 'react-use';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

const LOCAL_STORAGE_KEY_PREFIX_ACCORDION = 'hitotsu/accordion';

export const useStoredAccordionSetting = (key: string) => {
  const {myInfo} = useMyInfo();
  const storageKey = `${LOCAL_STORAGE_KEY_PREFIX_ACCORDION}_${myInfo.hashId}` + key ? `_${key}` : '';

  const [defaultOpen = true, setDefaultOpen] = useLocalStorage(storageKey, true);

  const onChange = useCallback((open) => {
    setDefaultOpen(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    defaultOpen,
    onChange,
  };
};
