import React, {useEffect, useState, useCallback, Suspense} from 'react';
import request from 'axios';
import {yup} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Form, Formik, useFormikContext} from 'formik';
import {
  UpdateHospitalProductParams,
  updateHospitalProduct,
  useFetchHospitalProductQuery,
} from '@modules/hospital_products/api';
import {InnerLoading} from '@molecules/Loading';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {openSnackBar} from '@molecules/SnackBar';
import {useParams} from 'react-router-dom';
import {isNullish} from '@front-libs/helpers';
import {isNull} from 'lodash';
import {createMakerInspectionSetting, updateMakerInspectionSetting} from '@modules/maker_inspection_settings/api';
import dayjs from 'dayjs';
import {getNextStatus} from './helper';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {getInspectionResults} from '@modules/inspection_results/api';
import {useMutation} from 'react-query';
import {ProductInfoSections} from './TwoColumn/ProductInfoSections';
import {ProductAppendix} from './TwoColumn/ProductAppendix';
import {HospitalProductIndex, HospitalProductDetail} from '@modules/hospital_products/types';
import {ProductThumbnail} from '@components/atoms/Thumbnail';
import {NavigationContainer} from './TwoColumn/NavigationContainer';
import {ProductDetailHeader} from '@components/organisms/ProductDetailHeader';
import {
  ProductContentContainer,
  ProductContentColumn,
  PRODUCT_TABLET_APPENDIX_WIDTH,
  PRODUCT_PC_APPENDIX_WIDTH,
  APPENDIX_WIDTH_BREAK_POINT,
} from '@Apps/ProductDetail/styled';
import {Grid, useMediaQuery} from '@material-ui/core';
import {useFetchMakerInspectionSettingsQuery} from '@modules/maker_inspection_settings/hooks';
import {use3ColumnsLayoutTemplate} from '@components/templates/ContentLayout/InnerSidebarContentLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

export type ProductDetailFormType = Partial<Omit<HospitalProductIndex, 'maker'>> & {
  maker: {
    hashId?: string;
    name?: string;
    isNew: boolean;
  };
  makerInspectionSetting: {
    hashId: string | null;
    dueDateOfMakerInspection: string | null;
    inspectionPeriod: number | null;
    nextInspectionDate: string | null;
  };
};

const ProductDetailForm: React.FC = () => {
  const matches = useMediaQuery(`(max-width:${APPENDIX_WIDTH_BREAK_POINT})`);
  // タイムラインや添付文書等の補足情報を表示する横幅
  const appendixWidth = matches ? PRODUCT_TABLET_APPENDIX_WIDTH : PRODUCT_PC_APPENDIX_WIDTH;
  const hospitalContext = useFormikContext<HospitalProductDetail>();
  const {isReadOnly} = useMyRole();

  if (!hospitalContext) {
    return <InnerLoading />;
  }

  return (
    <Form style={{width: '100%', maxHeight: '100vh', overflow: 'hidden'}}>
      <ProductThumbnail hospitalProduct={hospitalContext.initialValues} />
      <NavigationContainer />
      <ProductDetailHeader hospitalProduct={hospitalContext.initialValues} />
      <ProductContentContainer container spacing={0} wrap="nowrap">
        <ProductContentColumn item style={{width: `calc(100vw - ${appendixWidth})`}}>
          <ProductInfoSections hospitalProduct={hospitalContext.initialValues} />
        </ProductContentColumn>
        <ProductContentColumn item style={{width: appendixWidth}}>
          <ProductAppendix hospitalProduct={hospitalContext.initialValues} />
        </ProductContentColumn>
      </ProductContentContainer>
      {!isReadOnly && <FormikFormSubmitDrawer />}
    </Form>
  );
};

const ProductDetailContainer: React.FC = ({children}) => {
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const {
    data,
    isLoading: isLoadingHospitalProduct,
    refetch: refetchHospitalProduct,
  } = useFetchHospitalProductQuery(myInfo.hospitalHashId, hashId ?? '');

  const {
    data: makerInspectionSetting,
    isLoading: isLoadingMakerInspectionSetting,
    refetch: refetchMakerInspectionSetting,
  } = useFetchMakerInspectionSettingsQuery(myInfo.hospitalHashId, hashId ?? '');

  const [initialValues, setInitialValues] = useState<ProductDetailFormType>({
    ...data,
    maker: {
      hashId: data?.maker?.hashId,
      name: data?.maker?.name,
      isNew: false,
    },
    makerInspectionSetting: {
      hashId: makerInspectionSetting?.data.at(0)?.hashId ?? null,
      inspectionPeriod:
        makerInspectionSetting?.data.at(0)?.makerInspectionWholeProductSetting?.inspectionPeriod ?? null,
      dueDateOfMakerInspection: makerInspectionSetting?.data.at(0)?.dueDateOfMakerInspection?.toString() ?? null,
      nextInspectionDate: makerInspectionSetting?.data.at(0)?.nextInspectionDate?.toString() ?? null,
    },
  });

  const validationSchema = yup.object({
    managementId: yup.string().required(),
    depreciationAmount: yup.number().min(0),
  });

  const {mutate: updateMutate} = useMutation<unknown, unknown, UpdateHospitalProductParams>(
    (variables) => updateHospitalProduct(myInfo.hospitalHashId, hashId ?? '', variables),
    {
      onSuccess: async () => {
        openSnackBar('機器情報を更新しました');
        await Promise.all([refetchHospitalProduct(), refetchMakerInspectionSetting()]);
      },
      onError: (error) => {
        if (request.isAxiosError(error) && error.response) {
          const errorMessage = error.response.data.message;
          if (errorMessage.includes('conflicting management ID')) {
            openSnackBar('管理番号の保存に失敗しました', 'left', 'bottom', 'error');
            throw error;
          }
        }
        openSnackBar('機器情報の更新に失敗しました', 'left', 'bottom', 'error');
      },
    }
  );

  useEffect(() => {
    setInitialValues({
      ...data,
      maker: {
        hashId: data?.maker?.hashId,
        name: data?.maker?.name,
        isNew: false,
      },
      makerInspectionSetting: {
        hashId: makerInspectionSetting?.data.at(0)?.hashId ?? null,
        inspectionPeriod:
          makerInspectionSetting?.data.at(0)?.makerInspectionWholeProductSetting?.inspectionPeriod ?? null,
        dueDateOfMakerInspection: makerInspectionSetting?.data.at(0)?.dueDateOfMakerInspection?.toString() ?? null,
        nextInspectionDate: makerInspectionSetting?.data.at(0)?.nextInspectionDate?.toString() ?? null,
      },
    });
  }, [data, makerInspectionSetting?.data]);

  const handleSubmit = useCallback(
    async (values: ProductDetailFormType) => {
      try {
        const updateValues = {
          ...values,
          catalogPrice: !isNullish(values.catalogPrice) ? values.catalogPrice.toString() : values.catalogPrice,
          deliveryPrice: !isNullish(values.deliveryPrice) ? values.deliveryPrice.toString() : values.deliveryPrice,
          taxRate: !isNullish(values.taxRate) ? values.taxRate.toString() : values.taxRate,
          legalDurableYear: !isNullish(values.legalDurableYear) ? values.legalDurableYear.toString() : values.legalDurableYear,
          leaseFee: !isNullish(values.leaseFee) ? values.leaseFee.toString() : values.leaseFee,
          rentalFee: !isNullish(values.rentalFee) ? values.rentalFee.toString() : values.rentalFee,
          dateOfPurchase:
            !isNullish(initialValues?.dateOfPurchase) && isNull(values.dateOfPurchase) ? '' : values.dateOfPurchase,
          dateOfDisposal:
            !isNullish(initialValues?.dateOfDisposal) && isNull(values.dateOfDisposal) ? '' : values.dateOfDisposal,
          periodicInspectionStartDate:
            !isNullish(initialValues?.periodicInspectionStartDate) && isNull(values.periodicInspectionStartDate)
              ? ''
              : values.periodicInspectionStartDate,
          rawBarcode: values.gs1Barcode ?? undefined,
        };

        const {willDisposed, wontDisposed, isNextDisabled} = getNextStatus(initialValues, values);

        if (isNextDisabled) {
          const initialData = await getInspectionResults(myInfo.hospitalHashId, 'dummy', {
            hospitalProductHashId: initialValues.hashId,
            statuses: 'unplanned',
          });

          try {
            if (initialData.totalCount > 0) {
              await dialogHandler.open(SimpleDialog, {
                title: '機器を廃棄済みに変更しますか？',
                content: '未実施の点検予定が削除されます。\n削除したデータは復元できなくなります。',
                positiveButtonLabel: 'OK',
                negativeButtonLabel: 'キャンセル',
              });
            }
          } catch (e) {
            return;
          }
        }
        if (willDisposed) {
          try {
            await dialogHandler.open(SimpleDialog, {
              title: 'ステータスを変更しますか？',
              content: '廃棄情報が入力されました。\n機器の稼働状況を「廃棄」に変更しますか？',
              positiveButtonLabel: '変更する',
              negativeButtonLabel: '変更せずに更新',
            });
            updateValues.status = 'disabled';
          } catch (e) {
            // ban
          }
        } else if (wontDisposed) {
          try {
            await dialogHandler.open(SimpleDialog, {
              title: 'ステータスを変更しますか？',
              content: '廃棄情報が削除されました。\n機器の稼働状況を「待機中」に変更しますか？',
              positiveButtonLabel: '変更する',
              negativeButtonLabel: '変更せずに更新',
            });
            updateValues.status = 'ready';
          } catch (e) {
            // ban
          }
        }

        // 購入区分が購入の場合のみ, deliveryPrice,taxIncluded,taxRateは更新できる
        const isPurchase = (updateValues.waysOfPurchase ?? initialValues.waysOfPurchase) === 'purchase';

        if (isNullish(values.makerInspectionSetting?.hashId)) {
          createMakerInspectionSetting(myInfo.hospitalHashId, {
            hospitalProductHashId: hashId ?? '',
            dueDateOfMakerInspection: !isNullish(values.makerInspectionSetting?.dueDateOfMakerInspection)
              ? dayjs(values.makerInspectionSetting.dueDateOfMakerInspection).toDate()
              : undefined,
            nextInspectionDate: !isNullish(values.makerInspectionSetting?.nextInspectionDate)
              ? dayjs(values.makerInspectionSetting.nextInspectionDate).toDate()
              : undefined,
            inspectionPeriod: values.makerInspectionSetting?.inspectionPeriod ?? 1,
            inspectionPeriodUnit: 'year',
          });
        } else {
          updateMakerInspectionSetting(myInfo.hospitalHashId, values.makerInspectionSetting.hashId, {
            dueDateOfMakerInspection: !isNullish(values.makerInspectionSetting?.dueDateOfMakerInspection)
              ? dayjs(values.makerInspectionSetting.dueDateOfMakerInspection).toDate()
              : undefined,
            nextInspectionDate: !isNullish(values.makerInspectionSetting?.nextInspectionDate)
              ? dayjs(values.makerInspectionSetting.nextInspectionDate).toDate()
              : undefined,
          });
        }

        updateMutate({
          ...updateValues,
          maker: undefined,
          maker_hash_id: !updateValues.maker.isNew ? updateValues.maker.hashId : undefined,
          new_maker_name: updateValues.maker.isNew ? updateValues.maker.name : undefined,
          catalogPrice: updateValues.catalogPrice ?? undefined,
          deliveryPrice: isPurchase ? updateValues.deliveryPrice : undefined,
          taxIncluded: isPurchase ? updateValues.taxIncluded : undefined,
          taxRate: isPurchase ? updateValues.taxRate : undefined,
        });
      } catch (error) {
        openSnackBar('機器情報の更新に失敗しました', 'left', 'bottom', 'error');
        throw error;
      }
    },
    [initialValues, updateMutate, myInfo.hospitalHashId, hashId]
  );

  useEffect(() => {
    setInitialValues({
      ...data,
      maker: {
        hashId: data?.maker?.hashId,
        name: data?.maker?.name,
        isNew: false,
      },
      makerInspectionSetting: {
        hashId: makerInspectionSetting?.data.at(0)?.hashId ?? null,
        inspectionPeriod:
          makerInspectionSetting?.data.at(0)?.makerInspectionWholeProductSetting?.inspectionPeriod ?? null,
        dueDateOfMakerInspection: makerInspectionSetting?.data.at(0)?.dueDateOfMakerInspection?.toString() ?? null,
        nextInspectionDate: makerInspectionSetting?.data.at(0)?.nextInspectionDate?.toString() ?? null,
      },
    });
  }, [data, makerInspectionSetting?.data]);

  if (isLoadingHospitalProduct || isLoadingMakerInspectionSetting || !initialValues) {
    return <InnerLoading />;
  }

  return (
    <Formik<ProductDetailFormType>
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}>
      {children}
    </Formik>
  );
};

export const ProductDetail: React.FC = () => {
  const templateClasses = use3ColumnsLayoutTemplate();

  return (
    <Grid container className={templateClasses.root}>
      <Suspense fallback={null}>
        <ProductDetailContainer>
          <ProductDetailForm />
        </ProductDetailContainer>
      </Suspense>
    </Grid>
  );
};
