import {dexieDB} from '@indexedDB/database';
import {GetWholeProductsInspectionProductsResult} from '@modules/products/api';

export const deleteAndCreatewholeProductsInspectionProducts = async (
  inspectionProducts: GetWholeProductsInspectionProductsResult[]
) => {
  await dexieDB?.wholeProductInspectionProducts.clear();
  await dexieDB?.wholeProductInspectionProducts.bulkPut(inspectionProducts);
};

export const deleteWholeProductsInspectionProductsByHospitalHashId = async (hospitalHashId: string) => {
  await dexieDB?.wholeProductInspectionProducts.where('hospitalHashId').equals(hospitalHashId).delete();
};
