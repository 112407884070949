import React from 'react';
import {styled} from '@material-ui/core';
import Icon from '@assets/multiple_folder.svg';

const DEFAULT_MARGIN = 18;
const ICON_WIDTH = 24;

const StyedImg = styled('img')({
  width: `${ICON_WIDTH}px`,
  marginRight: `${DEFAULT_MARGIN}px`,
});

/** フォルダーアイコン */
export const MultipleFolderIcon = () => {
  return <StyedImg src={Icon} alt="MultipleFolder" />;
};
