import {useMutation, useQuery} from 'react-query';
import {createNoticeBoard, getCurrentNoticeBoard} from '@modules/dashboard/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {CreateNoticeBoardData} from '@modules/dashboard/types';

export const useNoticeBoard = () => {
  const {myInfo} = useMyInfo();
  const currentNoticeBoardQuery = useQuery(['getCurrentNoticeBoard', myInfo.hospitalHashId], () =>
    getCurrentNoticeBoard(myInfo.hospitalHashId)
  );
  const {mutate: noticeBoardMutation} = useMutation<unknown, unknown, CreateNoticeBoardData>(
    (variables) => createNoticeBoard(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => currentNoticeBoardQuery.refetch(),
    }
  );
  return {currentNoticeBoardQuery, noticeBoardMutation} as const;
};
