import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {Button, Paper, Popper, makeStyles, ClickAwayListener, Grid, Typography} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import {ArrowDropDown} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import dayjs from 'dayjs';
import _ from 'lodash';
import {DatePicker} from './DatePicker';

const useStyles = makeStyles((theme) => ({
  popper: {
    maxHeight: 360,
    overflow: 'auto',
    zIndex: 10,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: `1px solid ${theme.palette.grey.A100}`,
    padding: '24px 0px',
  },
  button: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  body: {
    width: '320px',
    padding: '0px 24px',
    '& > :not(:first-child)': {
      marginTop: '16px',
    },
  },
  selectorButton: {
    width: '270px',
    height: '32px',
    backgroundColor: '#FAFBFC',
  },
}));

type MenuItem = {
  label: string;
  value: string;
};

type TermPickerProps<T extends MenuItem> = {
  open: boolean;
  options: ReadonlyArray<T>;
  value: T['value'];
  from?: Date;
  to?: Date;
  showsDatePickers?: boolean;
  dataTestId?: string;

  onSelectType?: (type: T['value']) => void;
  onChangeFrom?: (from: Date | null) => void;
  onChangeTo?: (to: Date | null) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

export const TermPicker = <T extends MenuItem>({
  open,
  options,
  value,
  from,
  to,
  showsDatePickers = false,
  dataTestId,
  onSelectType,
  onChangeFrom,
  onChangeTo,
  onOpen,
  onClose,
}: TermPickerProps<T>) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);

  const handleClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (open) {
        onClose && onClose();
      } else {
        setAnchorEl(e.currentTarget);
        onOpen && onOpen();
      }
    },
    [open, onOpen, onClose]
  );

  const handleSelectType = useCallback(
    (item: MenuItemType, e: React.MouseEvent<Document, MouseEvent>) => {
      onSelectType && onSelectType(item.value as T['value']);
    },
    [onSelectType]
  );

  const handleClickAway = useCallback(
    (e: React.MouseEvent<Document, MouseEvent>): void => {
      e.stopPropagation();

      onClose && onClose();
    },
    [onClose]
  );

  const optionMap = useMemo(() => _.keyBy(options, 'value'), [options]);

  const outerButtonLabel = useMemo(() => {
    if (!showsDatePickers) {
      return optionMap[value]?.label ?? '';
    }

    const fromText = from ? dayjs(from).format('YYYY/MM/DD') : '';
    const toText = to ? dayjs(to).format('YYYY/MM/DD') : '';
    return `${fromText} - ${toText}`;
  }, [showsDatePickers, optionMap, value, from, to]);

  const innerButtonLabel = useMemo(() => {
    return optionMap[value]?.label ?? '';
  }, [optionMap, value]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.container}>
        <Button className={classes.button} onClick={handleClickButton} data-testid={dataTestId}>
          {outerButtonLabel}
          <ArrowDropDown fontSize="small" />
        </Button>
        <Popper open={open} transition anchorEl={anchorEl} className={classes.popper}>
          {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} style={{transformOrigin: placement}}>
              <Paper id="menu-list-grow" elevation={2} className={classes.paper}>
                <Grid container className={classes.body} direction="column" justifyContent="center" alignItems="center">
                  <PopperMenuButton
                    buttonProps={{variant: 'outlined', className: classes.selectorButton}}
                    menuItemList={options as T[]}
                    dataTestId={dataTestId}
                    onMenuClick={handleSelectType}>
                    {innerButtonLabel}
                  </PopperMenuButton>
                  {showsDatePickers && (
                    <Grid item container alignItems="center" justifyContent="space-between">
                      <DatePicker date={from} max={to} onChange={onChangeFrom} />
                      <Typography variant="inherit" style={{marginLeft: '8px', marginRight: '8px'}}>
                        から
                      </Typography>
                      <DatePicker date={to} min={from} onChange={onChangeTo} />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
