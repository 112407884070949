import React, {useMemo} from 'react';
import {createStyles, Grid, Button, makeStyles, Theme, Typography, Tooltip, Card} from '@material-ui/core';
import {InspectionStatus} from '@modules/inspections/enum';
import {Badge} from '@atoms/Badge';
import {Error} from '@material-ui/icons';
import {FormError} from '../types';
import {FormErrorItem} from './FormErrorItem';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    card: {
      position: 'absolute',
      padding: '32px',
      minWidth: '300px',
      maxWidth: '600px',
      right: '0px',
      zIndex: 5,
    },
    errorIcon: {
      color: '#C7243A',
    },
    errorText: {
      marginLeft: '8px',
      fontWeight: 'bold',
    },
    errors: {
      width: '100%',
      '& > ul': {
        width: '100%',
        margin: '24px 0px 0px',
        padding: '0px 0px 0px 24px',
        // li改行時の点のずれを修正
        listStyleType: 'disc',
        listStylePosition: 'outside',
      },
      '& > ul > li:not(:first-child)': {
        marginTop: '16px',
      },
    },
    error: {
      fontSize: '14px',
      overflowWrap: 'break-word',
    },
  })
);

type SubmitButtonProps = {
  showsBadge: boolean;
  status: InspectionStatus;
  disabled: boolean;
  badgeCount: number;
  errors: FormError[];
  onClick: React.MouseEventHandler;
  onItemClick: (sectionIndex: number, fieldIndex: number | null) => void;
};

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const {showsBadge, status, disabled, badgeCount, errors, onClick, onItemClick} = props;
  const classes = useStyle();

  const buttonText = useMemo(() => {
    switch (status) {
      case 'available':
        return '更新';
      case 'disabled':
        return '削除済み';
      case 'draft':
        return '公開';
    }

    return '';
  }, [status]);

  const ButtonComponent = (
    <Button variant="contained" color="primary" disabled={disabled} onClick={onClick}>
      {buttonText}
    </Button>
  );

  if (!showsBadge) {
    return ButtonComponent;
  }

  return (
    <Tooltip
      title={false}
      placement="bottom-start"
      // eslint-disable-next-line no-shadow
      PopperComponent={(props) =>
        props.open ? (
          <div className={classes.root}>
            <Card className={classes.card}>
              <Grid container direction="column">
                <Grid item container direction="row">
                  <Error className={classes.errorIcon} />
                  <Typography className={classes.errorText}>修正が必要なエラー</Typography>
                </Grid>
                <Grid item className={classes.errors}>
                  <ul>
                    {errors.map((error, idx) => (
                      <li key={idx} className={classes.error}>
                        <FormErrorItem error={error} onItemClick={onItemClick} />
                      </li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Card>
          </div>
        ) : null
      }>
      <Grid>
        <Badge badgeContent={badgeCount} color="error">
          {ButtonComponent}
        </Badge>
      </Grid>
    </Tooltip>
  );
};
