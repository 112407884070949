import {FetchHospitalProductLogsParams, getHospitalProductLogs} from '../api';
import {useQuery} from 'react-query';
import {getHospitalProductLogsKey} from '@constants/api';
import {useMemo} from 'react';
import {createHospitalProductLog, updateHospitalProductLog} from '@modules/hospital_products/api/hospitalProductLog';

export const useHospitalProductLog = () => {
  return {
    createHospitalProductLog,
    updateHospitalProductLog,
  };
};

/**
 * 機器詳細のログ取得Hook
 * @param hospitalHashId
 * @param hospitalProductHashId
 * @param {FetchHospitalProductLogsParams} params
 * @returns
 */
export const useFetchHospitalProductLogsQuery = (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductLogsParams
) => {
  const query = useQuery([getHospitalProductLogsKey, hospitalProductHashId, hospitalHashId, params], () =>
    getHospitalProductLogs(hospitalHashId, hospitalProductHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
