import {TextField, Theme, Typography, makeStyles, styled} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {ProductStatus} from '@components/molecules/ProductStatus';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {openSnackBar} from '@molecules/SnackBar';
import {deleteHospitalProduct} from '@modules/hospital_products/api';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isNullish} from '@front-libs/helpers';
import {useBackPrevious} from '@front-libs/core';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const ContentsArea = styled('div')({
  backgroundColor: 'white',
  width: 'calc(100% - 128px)',
  height: '44px',
  paddingLeft: '136px',
  borderBottom: '1px solid #D1D5DB',
  borderRadius: '20px 20px 0 0',
});

const ContentContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});

const HospitalProductInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ManagementID = styled(Typography)({
  paddingLeft: '16px',
  fontWeight: 'bold',
});
const DisplayName = styled(Typography)({
  paddingLeft: '16px',
  fontWeight: 'bold',
});

const AlertTextField = styled(TextField)({
  width: '100%',
  marginTop: '6px',
});

type ProductDetailHeaderProps = {
  hospitalProduct: HospitalProductDetail;
  onDeleteProduct?: () => Promise<void>;
};

const actionMenuItems = [
  {
    label: '削除',
    value: 'delete',
  },
];

/**
 * 機器詳細情報のHeader部分
 * 構成要素：ステータス、管理ID、機器名、ポッパーメニューボタン
 */
export const ProductDetailHeader: React.FC<ProductDetailHeaderProps> = (props) => {
  const {hospitalProduct} = props;
  const styles = usePopperMenuStyles();
  const {myInfo} = useMyInfo();
  const {isAdmin} = useMyRole();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  const expectedConfirmationInput = myInfo.username;

  const backPrevious = useBackPrevious('/products');

  const handleDeleteProduct = async () => {
    if (isNullish(hospitalProduct)) return;

    await deleteHospitalProduct(myInfo.hospitalHashId, hospitalProduct.hashId);
    openSnackBar('機器を削除しました');
    backPrevious();
  };

  const handleActionMenuClick = (item: MenuItemType) => {
    switch (item.value) {
      case 'delete':
        setOpenDeleteDialog(true);
        break;
      default:
        break;
    }
  };

  const handleDisabled = useCallback(() => {
    return expectedConfirmationInput !== fieldValue && true;
  }, [fieldValue, expectedConfirmationInput]);

  return (
    <ContentsArea>
      <AlertDialog
        title={'機器を削除しますか？'}
        positiveButtonLabel={'機器を削除'}
        disabled={handleDisabled()}
        content={
          <>
            <div>1件の機器を削除しようとしています。削除したデータは削除すると復元できなくなります。</div>
            <br />
            <div>
              ユーザ名(<span style={{fontWeight: 'bold'}}>{expectedConfirmationInput}</span>)を下に入力してください{' '}
              <br />
              <AlertTextField
                label={expectedConfirmationInput}
                variant="outlined"
                onChange={(e) => setFieldValue(e.target.value)}
              />
            </div>
          </>
        }
        open={openDeleteDialog}
        actions={{
          resolve: handleDeleteProduct,
          reject: async () => {
            setOpenDeleteDialog(false);
          },
        }}
      />
      <ContentContainer>
        <HospitalProductInfo>
          <ProductStatus productStatus={hospitalProduct.status} marginTop={'0px'} />
          <ManagementID>{hospitalProduct.managementId}</ManagementID>
          <DisplayName>{hospitalProduct.displayName}</DisplayName>
        </HospitalProductInfo>
        {isAdmin && (
          <PopperMenuButton
            hiddenArrow={true}
            buttonProps={{size: 'small', color: 'inherit', className: styles.actionMenu}}
            menuItemList={actionMenuItems}
            onMenuClick={handleActionMenuClick}>
            <MoreVertIcon />
          </PopperMenuButton>
        )}
      </ContentContainer>
    </ContentsArea>
  );
};

const usePopperMenuStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
    width: '100%',
  },
  actionMenu: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  flex: {
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 700,
  },
  displayDate: {
    color: theme.palette.grey[600],
  },
}));
