import React, {useCallback, useState} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
  DialogActions,
  Button,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {isNullish} from '@front-libs/helpers';
import {PeriodsColumn} from './PeriodsColumn';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));

export type EditInspectionPeriodsResult = {
  periods: number;
};

export type EditInspectionPeriodsDialogProps = DialogProps & {
  period?: number;
};

export const EditInspectionPeriodsDialog: React.FC<EditInspectionPeriodsDialogProps> = (props) => {
  const {period, open} = props;
  const [newPeriod, setNewPeriod] = useState<number | undefined>(period);
  const classes = useStyles();

  const isOpen = typeof open === 'boolean' ? open : false;

  const handleClickSelect = useCallback(() => {
    if (isNullish(newPeriod)) return;

    props.actions.resolve({
      periods: newPeriod,
    });
  }, [newPeriod, props.actions]);

  const handleChangePeriod = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPeriod(Number(e.target.value));
  }, []);

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  const isDisabled = isNullish(newPeriod);

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2">次に、点検間隔の設定をしてください。</Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <PeriodsColumn period={period} newPeriod={newPeriod} handleChange={handleChangePeriod} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleClickSelect} disabled={isDisabled}>
          保存
        </Button>
        <Button color="primary" onClick={handleClose}>
          後で設定する
        </Button>
      </DialogActions>
    </Dialog>
  );
};
