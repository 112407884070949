import React, {useCallback, useState, useEffect, useRef} from 'react';
import {Grid, TextField, Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@molecules/SnackBar';
import {uploadFile} from '@modules/files/api';
import {FileCategory, FileIndex} from '@modules/files/types';
import {UploadBulkDocumentDialog} from './UploadBulkDocumentDialog';
import {UploadDocumentDialog} from './UploadDocumentDialog';

export type FileListToolBarProps = {
  searchFileName: boolean;
  fileCategory: FileCategory;
  disableUpload: boolean;
  onChangeFileNameInput: (fileName: string) => void;
  onUploadFile: (newFile: FileIndex, isFileSharingAcrossProducts?: boolean) => Promise<void>;
  uploadButtonTitle?: string;
  isDarkButton?: boolean;
  isBulkUploadDialog?: boolean;
};

export const FileListToolBar: React.VFC<FileListToolBarProps> = ({
  searchFileName,
  fileCategory,
  isBulkUploadDialog,
  isDarkButton,
  uploadButtonTitle,
  disableUpload,
  onUploadFile,
  onChangeFileNameInput,
}) => {
  const classes = useStyles();
  const [fileName, setFileName] = useState<string>('');
  const isFileSharingAcrossProducts = useRef(false);

  /** アップロードダイアログの表示 */
  const uploadDocument = useCallback(async () => {
    // NOTE:ダイアログの種類を切り替える
    const fileData = isBulkUploadDialog
      ? await dialogHandler.open(UploadBulkDocumentDialog, {
          setIsFileSharingAcrossProducts: isFileSharingAcrossProducts,
        })
      : await dialogHandler.open(UploadDocumentDialog, {});

    try {
      const newFile = await uploadFile({
        ...fileData,
        category: fileCategory,
      });
      await onUploadFile(newFile.data, isFileSharingAcrossProducts.current);
      openSnackBar('ファイルをアップロードしました');
    } catch (_e) {
      openSnackBar('ファイルのアップロードに失敗しました', 'left', 'bottom', 'error');
    }
  }, [isFileSharingAcrossProducts, onUploadFile, fileCategory, isBulkUploadDialog]);

  useEffect(() => {
    onChangeFileNameInput(fileName);
  }, [fileName, onChangeFileNameInput]);

  return (
    <Grid container className={classes.toolBar}>
      {searchFileName && (
        <Grid item className={classes.searchTextContainer}>
          <TextField
            label={'ファイル名を検索'}
            variant={'outlined'}
            fullWidth
            size={'small'}
            InputProps={{
              endAdornment: <Search />,
            }}
            className={classes.searchText}
            onChange={(e) => setFileName(e.target.value)}
          />
        </Grid>
      )}
      <Grid item>
        {!disableUpload &&
          (isDarkButton ? (
            <Button variant="contained" onClick={uploadDocument} className={classes.darkBtn}>
              {uploadButtonTitle ?? 'ファイルをアップロード'}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={uploadDocument}>
              {uploadButtonTitle ?? 'ファイルをアップロード'}
            </Button>
          ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      minWidth: '700px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    searchTextContainer: {
      minWidth: '360px',
      paddingRight: '8px',
    },
    searchText: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
    },
    darkBtn: {
      backgroundColor: '#323F4E',
      borderRadius: '2px',
      padding: '8px 22px',
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: '#323F4E',
      },
    },
  })
);
