import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getHospitalRoomKey} from '@constants/api';
import {HospitalRoom, HospitalRoomDetail} from './types';

interface StandardListResponse<T> {
  totalCount: number;
  page: number;
  data: T[];
}

export type HospitalRoomParams = {
  page?: number;
  perPage?: number;
  name?: string;
  hospitalWardHashId?: string;
};

export type CreateHospitalRoomParams = {
  hospitalWardHashId: string;
  name: string;
  isGroundFloor?: boolean;
  floorNumber?: number;
  showRentalPlace?: boolean;
};

type UpdateHospitalRoomParams = CreateHospitalRoomParams;

export const fetchHospitalRooms = async (hospitalHashId: string, params: HospitalRoomParams) => {
  const hospitalRoomUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms`;
  const res = await axios.get<StandardListResponse<HospitalRoom>>(hospitalRoomUrl, {params});
  return res.data;
};

export const fetchHospitalRoomDetail = async (hospitalHashId: string, hospitalRoomHashId: string) => {
  const hospitalRoomUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/rooms/${hospitalRoomHashId}`;
  const res = await axios.get<HospitalRoom>(hospitalRoomUrl);
  return res.data;
};

export const createHospitalRoom = async (hospitalHashId: string, data: CreateHospitalRoomParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms`;
  return await axios.post<HospitalRoomDetail>(baseUrl, data);
};

export const updateHospitalRoom = async (
  hospitalHashId: string,
  hospitalRoomHashId: string,
  data: UpdateHospitalRoomParams
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms/${hospitalRoomHashId}`;
  return axios.put<HospitalRoomDetail>(baseUrl, data);
};

export const deleteHospitalRoom = async (hospitalHashId: string, hospitalRoomHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rooms/${hospitalRoomHashId}`;
  return axios.delete(baseUrl);
};

export const useFetchHospitalRoom = (hospitalHashId: string, hospitalRoomHashId: string) => {
  const query = useQuery([getHospitalRoomKey, hospitalHashId, hospitalRoomHashId], () =>
    fetchHospitalRoomDetail(hospitalHashId, hospitalRoomHashId)
  );
  return query;
};
