import {dexieDB} from '@indexedDB/database';
import {useLiveQuery} from 'dexie-react-hooks';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {WhereIndexType} from '../types';

export function useAllInspectionResults() {
  const allInspectionResults =
    useLiveQuery(async () => {
      return await dexieDB?.inspectionResults.toArray();
    }, []) || [];

  return allInspectionResults;
}

// indexedDBの値を更新する
export const upsertInspectionResults = async (inspectionResults: InspectionResultIndex[]) => {
  await dexieDB?.inspectionResults.bulkPut(inspectionResults);
};

export const getInspectionResultByIndex = async (whereIndex: WhereIndexType) => {
  return dexieDB?.inspectionResults.where(whereIndex).first();
};

export const getInspectionResultsByIndex = async (whereIndex: WhereIndexType) => {
  return dexieDB?.inspectionResults.where(whereIndex).toArray();
};

// プライマリーキー更新(削除の上追加する)
export const updateInspectionResultByPrimaryKey = async (inspectionResult: InspectionResultIndex, hashId: string) => {
  await dexieDB?.transaction('rw', dexieDB.inspectionResults, async () => {
    await dexieDB?.inspectionResults.delete(inspectionResult.hashId);
    inspectionResult.hashId = hashId;
    await dexieDB?.inspectionResults.add(inspectionResult);
  });
};

export const deleteInspectionResultsByHospitalHashId = async (hospitalHashId: string) => {
  await dexieDB?.inspectionResults.where('hospitalHashId').equals(hospitalHashId).delete();
};
