import {axios} from '@front-libs/core';
import {
  FetchHospitalProductFaultRepairHistoriesParams,
  FetchHospitalProductFaultRepairHistoriesResult,
  FetchHospitalProductRentalHistoriesParams,
  FetchHospitalProductRentalHistoriesResult,
} from '@modules/hospital_products/api/index';

export const getHospitalProductRentalHistories = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductRentalHistoriesParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/histories/rentals`;
  return await axios.get<FetchHospitalProductRentalHistoriesResult>(baseUrl, {params});
};

export const getHospitalProductFaultRepairHistories = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductFaultRepairHistoriesParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/histories/fault_repair`;
  return await axios.get<FetchHospitalProductFaultRepairHistoriesResult>(baseUrl, {params});
};
