import React from 'react';
import {CardContent, makeStyles} from '@material-ui/core';
import {Card, RadioProps, CardActionArea} from '@material-ui/core';
import {theme} from '@components/atoms/theme';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '45px',
    boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
    width: '100%',
  },
  content: {
    maxWidth: '100%',
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: theme.palette.rent.main,
    [theme.breakpoints.up('tabletV')]: {
      fontSize: '26px',
      padding: '20px 40px',
    },
    [theme.breakpoints.up('tabletH')]: {
      fontSize: '36px',
    },
  },
}));

type Props = {
  label: string;
  onClick: (value: string) => void;
} & Omit<RadioProps, 'onChange'>;

export const ActivateCameraButton: React.VFC<Props> = ({label, ...props}) => {
  const classes = useStyles();
  const handleClick = () => {
    props.onClick(props.value as string);
  };
  return (
    <Card className={classes.card}>
      <CardActionArea onClick={handleClick}>
        <CardContent className={classes.content}>{label}</CardContent>
      </CardActionArea>
    </Card>
  );
};
