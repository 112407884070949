import {atomWithReset} from 'jotai/utils';
import {TrainingTypeValue} from '@modules/training_plans/types';

export type SearchVariable = {
  name: string | null;
  trainingPlanHashIds: string[] | null;
  trainingTypes: TrainingTypeValue[] | null;
  page: number;
  perPage: number;
  order: string | null;
};

export const searchVariablesAtom = atomWithReset<SearchVariable>({
  name: null,
  trainingPlanHashIds: null,
  trainingTypes: null,
  page: 0,
  perPage: 20,
  order: 'scheduleDateRange',
});
