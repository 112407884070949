import {styled} from '@material-ui/core';

export const PageTitle = styled('p')({
  fontSize: 24,
  marginBottom: 24,
});

export const PageDescription = styled('p')({
  fontSize: 14,
  marginBottom: 32,
});

export const ProcessTitle = styled('p')({
  fontSize: 18,
  fontWeight: 700,
  marginTop: 16,
  marginBottom: 16,
});
