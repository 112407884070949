import {Button, Grid, styled, Typography} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import React from 'react';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';

type PageHeaderProps = {
  onRegisterReport: VoidFunction;
  canCreateReport: boolean;
};

export const PageHeader: React.FC<PageHeaderProps> = ({onRegisterReport, canCreateReport}) => {
  return (
    <Grid container>
      <Grid item>
        <PageRoutingMenu>
          <PageTitle variant={'h5'}>研修（実施済み）</PageTitle>
          <ArrowDropDown />
        </PageRoutingMenu>
      </Grid>
      <Grow />
      <Grid item>
        {canCreateReport && (
          <Button variant={'contained'} color={'primary'} onClick={onRegisterReport}>
            実施記録を作成
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const PageTitle = styled(Typography)(({theme}) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.primary.dark,
}));

const Grow = styled('div')({
  flexGrow: 1,
});
