import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getHospitalDeviceLendersKey} from '@constants/api';
import {FetchHospitalDeviceLendersResult, FetchHospitalDeviceLendersParams} from './types';
import {useMemo} from 'react';

const getHospitalDeviceLenders = async (hospitalHashId: string, params: FetchHospitalDeviceLendersParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_device_lenders`;
  return await axios.get<FetchHospitalDeviceLendersResult>(baseUrl, {params});
};

export const useFetchHospitalDeviceLendersQuery = (
  hospitalHashId: string,
  params: FetchHospitalDeviceLendersParams
) => {
  const query = useQuery([getHospitalDeviceLendersKey, hospitalHashId, params], () =>
    getHospitalDeviceLenders(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data.data ?? [],
      totalCount: query.data?.data.totalCount ?? 0,
    }),
    [query]
  );
};
