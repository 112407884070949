import React, {useCallback} from 'react';
import {Table} from '@molecules/Table';
import {
  useCreateTrainingScheduleMutation,
  useSearchVariables,
  useTableData,
  useUpdateTrainingPlanMutation,
} from '../hooks';
import {RowAction} from '@molecules/Table/props';
import {PlanTableData} from '.';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TrainingPlanDialogProps, TrainingPlanDialogResult, TrainingPlanDialog} from '../dialogs/TrainingPlanDialog';
import {planTypeOptions, trainingTypeOptions} from '@modules/training_plans/constants';
import {useNavigate} from 'react-router-dom';
import {
  TrainingScheduleDialog,
  TrainingScheduleDialogProps,
  TrainingScheduleDialogResult,
} from '@organisms/TrainingManagements/TrainingScheduleDialog';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

export const PlanTable: React.FC = () => {
  const navigate = useNavigate();
  const {tableColumns, planTableData, isLoading} = useTableData();
  const {mutate} = useUpdateTrainingPlanMutation();
  const {mutate: createTrainingScheduleMutate} = useCreateTrainingScheduleMutation();
  const {setOrder} = useSearchVariables();
  const {isReadOnly} = useMyRole();

  const handleEdit = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      const result = await dialogHandler.open<TrainingPlanDialogProps, TrainingPlanDialogResult>(TrainingPlanDialog, {
        isEdit: true,
        initialValues: {
          name: data.name,
          // optionの方は決まっているので、基本的にここに存在しないパラメータが来ることはない。
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          trainingTypes: data.trainingTypes.map((item) => trainingTypeOptions.find((option) => option.value === item)!),
          planType: planTypeOptions.find((item) => item.value === data.planType) ?? planTypeOptions[0],
          scheduleTimes: data.scheduleTimes,
        },
      });
      mutate({...result, hashId: data.hashId});
    },
    [mutate]
  );

  const handleNavigateTrainingSchedule = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      navigate('/training_managements/schedules', {
        state: {
          trainingPlanHashId: data.hashId,
        },
      });
    },
    [navigate]
  );

  const handleNavigateTrainingReport = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      navigate('/training_managements/reports', {
        state: {
          trainingPlanHashId: data.hashId,
        },
      });
    },
    [navigate]
  );

  const handleRegisterSchedule = useCallback(
    async (_e: React.MouseEvent, data: PlanTableData) => {
      const result = await dialogHandler.open<TrainingScheduleDialogProps, TrainingScheduleDialogResult>(
        TrainingScheduleDialog,
        {
          isEdit: false,
          sourceTrainingPlanHashId: data.hashId,
        }
      );
      createTrainingScheduleMutate({
        ...result,
        trainingPlanTrainingTypes: data.trainingTypes,
        wholeProductHashIds: [],
      });
    },
    [createTrainingScheduleMutate]
  );

  const rowsActions: RowAction<PlanTableData>[] = [
    {
      type: 'menu',
      label: 'アクション',
      items: isReadOnly
        ? [
            {label: '予定一覧を表示', onClick: handleNavigateTrainingSchedule},
            {label: '実施済み一覧を表示', onClick: handleNavigateTrainingReport},
          ]
        : [
            {label: '編集', onClick: handleEdit},
            {label: '予定を登録', onClick: handleRegisterSchedule},
            {label: '予定一覧を表示', onClick: handleNavigateTrainingSchedule},
            {label: '実施済み一覧を表示', onClick: handleNavigateTrainingReport},
          ],
    },
  ];
  return (
    <Table<PlanTableData>
      data={planTableData}
      isLoading={isLoading}
      columns={tableColumns}
      showSelection={false}
      rowActions={rowsActions}
      onOrderChange={(columnIndex: number, orderDirection: 'desc' | 'asc') => {
        setOrder(tableColumns[columnIndex].field, orderDirection);
      }}
    />
  );
};
