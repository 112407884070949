import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {Box} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {openSnackBar} from '@molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import no_image from '@assets/no_image.svg';
import {resizeImage} from '@front-libs/helpers';
import {uploadFile} from '@modules/files/api';
import {updateProduct} from '@modules/products/api';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const StyledBox = styled(Box)({
  position: 'absolute',
  top: `112px`,
  left: '32px',
  '& > img': {
    backgroundColor: 'white',
    objectFit: 'contain',
    width: '74px',
    height: '74px',
    borderRadius: '10px',
    border: '1px solid #D1D5DB',
  },
});

const UPLOADED_THUMBNAIL_MAX_WIDTH = 200;

type Props = {
  hospitalProduct: HospitalProductDetail;
};

export const ProductThumbnail = ({hospitalProduct}: Props) => {
  // 画像取得失敗時のFollBack処理
  const [hasError, setHasError] = useState(false);
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const [thumbnailURL, setThumbnailURL] = useState<string | undefined>(hospitalProduct?.thumbnailUrl);
  const thumbnailImageInputRef = React.useRef<HTMLInputElement | null>(null);
  const canUploadThumbnail = useMemo(
    () => hospitalProduct?.isShared === false && !isReadOnly,
    [hospitalProduct?.isShared, isReadOnly]
  );

  useEffect(() => {
    setThumbnailURL(hospitalProduct?.thumbnailUrl);
  }, [hospitalProduct]);

  const onClickNoImageThumbnail = useCallback(
    (e: React.MouseEvent) => {
      if (canUploadThumbnail) {
        thumbnailImageInputRef.current?.click();
      }
    },
    [canUploadThumbnail]
  );
  const onInputThumbnailImage = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        hospitalProduct === undefined ||
        !canUploadThumbnail ||
        e.target.files === null ||
        e.target.files.length === 0
      ) {
        return;
      }

      const newFile = await resizeImage(e.target.files[0], UPLOADED_THUMBNAIL_MAX_WIDTH);
      const fileRecord = await uploadFile({
        file: newFile,
        fileName: newFile.name,
        category: 'whole_product_thumbnail',
      });

      if (fileRecord.status !== 201) {
        console.warn('API calls successful but file may be not uploaded, data=', +JSON.stringify(fileRecord.data));

        return;
      }

      const url = fileRecord.data.url;
      const res = await updateProduct(myInfo.hospitalHashId, hospitalProduct.wholeProductHashId, {
        thumbnailUrl: url,
      });

      openSnackBar('画像を設定しました');

      setThumbnailURL(res.thumbnailUrl);
    },
    [canUploadThumbnail, hospitalProduct, myInfo.hospitalHashId]
  );

  const handleImageError = () => {
    setHasError(true);
  };

  const isNoImage = !thumbnailURL || hasError;

  return (
    <StyledBox>
      {isNoImage ? (
        <img
          src={no_image}
          alt="not found product thumbnail"
          width={76}
          object-fit="contain"
          loading="lazy"
          decoding="async"
          onClick={canUploadThumbnail ? onClickNoImageThumbnail : undefined}
          style={{cursor: canUploadThumbnail ? 'pointer' : 'inherit'}}
        />
      ) : (
        <img
          src={thumbnailURL}
          alt="product thumbnail"
          width={76}
          object-fit="contain"
          onClick={canUploadThumbnail ? onClickNoImageThumbnail : undefined}
          style={{cursor: canUploadThumbnail ? 'pointer' : 'inherit'}}
          onError={handleImageError}
        />
      )}
      <input
        hidden
        ref={thumbnailImageInputRef}
        type="file"
        accept="image/*"
        onChange={canUploadThumbnail ? onInputThumbnailImage : undefined}
      />
    </StyledBox>
  );
};
