import React, {useMemo} from 'react';
import {TableCell, TableRow, makeStyles} from '@material-ui/core';
import {Checkbox} from '@atoms/Checkbox';
import {NotificationSettingsListResult} from '@modules/notification_settings/api';
import {texts} from './consts';

type NotificationSettingTableRowType = {
  typeKey: keyof NotificationSettingsListResult;
  checked: boolean;
  additionalConditionChild: React.ReactNode;
  onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onCheckboxClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const NotificationSettingTableRow: React.VFC<NotificationSettingTableRowType> = ({
  typeKey,
  additionalConditionChild,
  ...props
}) => {
  const classes = useStyles();
  const rowConfiguration = useMemo(() => texts.find((item) => item.type === typeKey), [typeKey]);

  return (
    <TableRow>
      <TableCell align="left">
        <Checkbox
          name={rowConfiguration?.formKey}
          checked={props.checked}
          onChange={props.onCheckboxChange}
          onClick={props.onCheckboxClick}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {rowConfiguration?.title}
        <br />
        <span className={classes.description}>{rowConfiguration?.description}</span>
      </TableCell>
      <TableCell align="left">{additionalConditionChild}</TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles({
  description: {
    color: '#4A576B',
  },
});
