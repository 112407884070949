import React, {useCallback, useMemo} from 'react';
import {Box, Button, createStyles, Drawer, Grid, makeStyles, Theme} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {copyInspection, FetchInspectionsParams, useFetchInspectionsQuery} from '@modules/inspections/api';
import {TemplateSelector} from './TemplateSelector';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {useAtomValue} from 'jotai';
import {InnerLoading} from '@components/molecules/Loading';
import {MainContent} from './MainContent';
import {pageAtom, selectedTemplateAtom} from '../states';
import SearchIcon from '@material-ui/icons/Search';
import {
  NewInspectionDialog,
  NewInspectionDialogProps,
  NewInspectionDialogResult,
} from '@molecules/Dialogs/NewInspectionDialog';
import {inspectionTypeAtom, searchNameAtom, rootCategoryAtom, narrowCategoryAtom} from '../states';

const PAGE_SIZE = 10;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexWrap: 'nowrap',
    },
    description: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 20,
    },
    subDescription: {
      fontWeight: 100,
      fontSize: 14,
    },
    searchIcon: {
      fontSize: '160px',
      color: '#9AAABE',
    },
    sideBar: {
      marginRight: '40px',
    },
    main: {
      display: 'flex',
      alignItems: 'center',
      maxHeight: 'calc(100vh - 56px - 48px - 36px - 120px - 24px)',
      overflowY: 'scroll',
    },
    drawerRoot: {
      backgroundColor: theme.palette.secondary.light,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    contentContainer: {
      padding: '16px 52px',
    },
    drawerBtn: {
      marginLeft: '12px',
      marginRight: '32px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
      '&:disabled': {
        color: theme.palette.common.black,
        backgroundColor: 'rgba(0, 0, 0, 0.26)',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);

export const TemplateList: React.VFC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {myInfo} = useMyInfo();
  const searchName = useAtomValue(searchNameAtom);
  const inspectionType = useAtomValue(inspectionTypeAtom);
  const rootCategory = useAtomValue(rootCategoryAtom);
  const narrowCategory = useAtomValue(narrowCategoryAtom);
  const page = useAtomValue(pageAtom);

  const params = useMemo(() => {
    const _p: FetchInspectionsParams = {
      page: page - 1,
      perPage: PAGE_SIZE,
      order: 'name',
    };

    if (searchName) {
      _p.name = searchName;
    }

    if (inspectionType !== null) {
      _p.type = inspectionType;
    }

    if (narrowCategory !== null) {
      _p.categoryHashIds = narrowCategory;
    } else if (rootCategory !== null) {
      _p.categoryHashIds = rootCategory;
    }

    _p.statuses = 'available';
    _p.isShared = true;

    return _p;
  }, [page, searchName, inspectionType, narrowCategory, rootCategory]);

  const {data, isLoading, totalCount, refetch} = useFetchInspectionsQuery(myInfo.hospitalHashId, params);
  const selectedTemplate = useAtomValue(selectedTemplateAtom);
  const totalPageNumber = useMemo(() => {
    return Math.ceil(totalCount / PAGE_SIZE);
  }, [totalCount]);

  const handleClickCopy = useCallback(
    async () => {
      try {
        // eslint-disable-next-line no-shadow
        const res = await dialogHandler.open<NewInspectionDialogProps, NewInspectionDialogResult>(
          NewInspectionDialog,
          {}
        );

        const newInspection = await copyInspection(myInfo.hospitalHashId, selectedTemplate?.hashId + '', {
          type: res.type,
          name: `${selectedTemplate?.name}のコピー`,
          migrateProducts: false,
        });

        refetch();

        if (newInspection.data.hashId !== null) {
          navigate(`/inspections/${newInspection.data.hashId}`);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-shadow
      } catch (e: any) {
        if (e) {
          console.error(e);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, selectedTemplate]
  );

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={3} className={classes.sideBar}>
          {isLoading ? <InnerLoading /> : <TemplateSelector inspectionsQueryData={data} totalCount={totalPageNumber} />}
        </Grid>
        <Grid item xs={9} className={classes.main} style={selectedTemplate !== null ? {alignItems: 'flex-start'} : {}}>
          {selectedTemplate !== null ? (
            <MainContent />
          ) : (
            <Grid container>
              <Grid item xs={7} className={classes.description}>
                <Descriptions hasData={isLoading || data.length !== 0} />
              </Grid>
              <Grid item xs={5}>
                <SearchIcon className={classes.searchIcon} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Drawer
        anchor={'bottom'}
        open={true}
        variant="persistent"
        PaperProps={{className: classes.drawerRoot, elevation: 4}}>
        <Grid container className={classes.contentContainer}>
          <Grid item>
            <Button
              variant={'outlined'}
              color={'primary'}
              size={'large'}
              disabled={selectedTemplate !== null ? false : true}
              className={classes.drawerBtn}
              onClick={handleClickCopy}>
              選択
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

type DescriptionProps = {
  hasData: boolean;
};

const Descriptions: React.VFC<DescriptionProps> = ({hasData}) => {
  const classes = useStyles();

  if (!hasData) {
    return (
      <Box>
        該当する点検表が存在しません。
        <br />
        <Box component="span" className={classes.subDescription}>
          検索情報を変更して、再度検索してください。
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        左のカードを選択すると点検表の
        <br />
        プレビューが表示されます
        <br />
        <Box component="span" className={classes.subDescription}>
          お持ちの機種に基づいてテンプレートを検索できます。
        </Box>
      </Box>
    );
  }
};
