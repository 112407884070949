import React, {useCallback, useState} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {FormValue} from '@Apps/Inspection/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionIndex} from '@modules/inspections/types';
import {useAsyncEffect, useBackPrevious, withSuspense} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';
import {getInspection} from '@modules/inspections/api';
import {inspectionTableToSections} from '@Apps/Inspection/mapper';
import {getDefaultSection} from '@Apps/Inspection/states';
import {Preview} from '@Apps/Inspection/Preview';
import {Formik} from 'formik';
import {InnerLoading} from '@molecules/Loading';
import {Header} from './Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'flex-start',
    },
    pageTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    flex: {
      flexGrow: 1,
    },
    mainContent: {
      height: '100%',
      width: '100%',
      padding: '24px 0px',
    },
  })
);

const InspectionInner: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid className={classes.mainContent}>
        <Preview />
      </Grid>
    </Grid>
  );
};

const _InspectionPreview: React.FC = () => {
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const [inspection, setInspection] = useState<InspectionIndex | null>(null);
  const [initialValues, setInitialValues] = useState<FormValue | null>(null);
  const classes = useStyles();
  const goBackToStartDate = useBackPrevious('/inspection/setting/start_date');

  const handleClickPrevButton = useCallback(() => {
    goBackToStartDate();
  }, [goBackToStartDate]);

  useAsyncEffect(async () => {
    if (isNullish(hashId)) return;

    try {
      // eslint-disable-next-line no-shadow
      const inspection = await getInspection(myInfo.hospitalHashId, hashId);

      const sections =
        inspection.table.items.length > 0 ? inspectionTableToSections(inspection.table) : [getDefaultSection()];

      setInitialValues({
        name: inspection.name,
        type: (inspection.type as 'periodic' | 'post_use') ?? 'periodic',
        sections: sections,
      });

      setInspection(inspection);
    } catch (e: unknown) {
      console.error(e);
    }
  }, []);
  if (!hashId || inspection === null || initialValues === null) {
    return <InnerLoading />;
  }

  return (
    <Grid container className={classes.root}>
      <Header name={inspection.name} onClickPrevButton={handleClickPrevButton} />
      <Formik
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {}}
        initialValues={initialValues}>
        <InspectionInner />
      </Formik>
    </Grid>
  );
};

export const InspectionPreview = withSuspense(_InspectionPreview, null);
