import React from 'react';
import {Box, styled} from '@material-ui/core';

const StyledDiv = styled('div')(({height}: {height: number}) => ({
  height: `calc(100vh - ${height + 110 / 2}px)`, // 110はテキスト全体の高さ 中央にするために2で割る
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const MobileNotFileText = ({height}: {height: number}) => {
  return (
    <StyledDiv height={height}>
      <Box>
        <Box fontWeight="fontWeightBold" padding={2} fontSize={20}>
          ファイルが登録されていません
        </Box>
        <Box textAlign="center">
          右上のアップロードアイコンをタップすると
          <br />
          ファイルをアップロードできます
        </Box>
      </Box>
    </StyledDiv>
  );
};
