import {dexieDB} from '@indexedDB/database';
import {UserIndex} from '@modules/hospital_users/types';

// indexedDBの値を更新する
export const upsertHospitalUsers = async (hospitalUsers: UserIndex[]) => {
  await dexieDB?.hospitalUsers.bulkPut(hospitalUsers);
};

// 病院IDに紐づくデータを削除
export const deleteHospitalUsersByHospitalHashId = async (hospitalHashId: string) => {
  await dexieDB?.hospitalUsers.where('hospitalHashId').equals(hospitalHashId).delete();
};
