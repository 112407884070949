import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'fs';
import * as ExcelJs from 'exceljs';
import {convertDateToSimpleDate, isNullish} from '@front-libs/helpers';
import {Column} from '@molecules/Table/props';
import {PredictableOriginSystem, WholeProductCandidate, WholeProductCandidateKeys} from './types';
import {hitotsuPropertyOptions, mavinFormatKey, mavinFormatMap, wholeProductCandidateColumns} from './constants';
import {bulkInsertWholeProducts, getCandidateWholeProducts} from '@modules/products/api';
import {WholeProductIndex} from '@modules/products/types';
import {bulkCreateHospitalProducts} from '@modules/hospital_products/api';
import {PropertySelectorColumn} from './components/PropertySelectorColumn';
import _, {isNumber} from 'lodash';
import {parse, ParseResult} from 'papaparse';
import {useCategoryQuery} from '@modules/categories/hooks/useCategories';
import dayjs, {Dayjs} from 'dayjs';
import {useAtom} from 'jotai';
import {Product, productsAtom} from './state';
import {v4} from 'uuid';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {CategoryFormatter} from '@modules/categories/helpers';

const status = [
  'initialized',
  'uploaded',
  'selectedSheet',
  'matchedColumn',
  'createdWholeProduct',
  'completed',
] as const;

type ImportProgressStatus = (typeof status)[number];

type SelectedValuesType = {
  value: string;
  priority: number;
  columnHeaderName: string;
  colIndex: number;
};

type ExcelRowsType = {
  wholeProductHashId: string;
  makerName: string;
  rootCategory: string;
  narrowCategory: string;
  hashId?: string | undefined;
  name?: string | undefined;
  displayName?: string | undefined;
  uuid?: string | undefined;
  searchKey?: string | undefined;
  catalogPrice?: number | undefined;
  optionalBarcode?: string | number | boolean | Date | Dayjs | null | undefined;
  managementId?: string | number | boolean | Date | Dayjs;
  serialNumber?: string | number | boolean | Date | Dayjs | null | undefined;
  status?: string | number | boolean | Date | Dayjs;
};

type ExcelRowType = {
  [key: string]: string | number | boolean | Date | dayjs.Dayjs | null | undefined;
};

export type WholeProduct = WholeProductIndex & {
  searchKey: WholeProductCandidate;
};

export const hospitalProductPerPage = 20;

export const useImportProgress = () => {
  const [currentImportStatus, setCurrentImportStatus] = useState<ImportProgressStatus>();
  const [completeImport, setCompleteImport] = useState(false);

  const completeCurrentStatus = useCallback(() => {
    if (completeImport) return;
    const currIndex = status.findIndex((item) => item === currentImportStatus);
    const newStatus = status[currIndex + 1];
    setCurrentImportStatus(newStatus);
    if (currIndex + 1 === status.length - 1) setCompleteImport(true);
  }, [completeImport, currentImportStatus]);

  return {currentImportStatus, completeCurrentStatus};
};

/* 
〜〜HospitalProductsをインポートできるまでの遠い道のり〜
1. Excel か CSVを読み込む

2-1a-1. Excelの場合、シートを選択する
2-1a-2. 選択されたシートの1行目をヘッダーとして読み込む。20行表示する
2-1a-1. CSVの場合、1行目をヘッダーとして読み込む。20行表示する
2-2-1. ヘッダーの内容を読み取りシステム予測する。システム予測ができた場合、SelectValueを埋める
2-2-2. システム予測ができない場合、ヘッダの名前からカラム予測する
2-3-1. ユーザーがカラム内容を設定
2-3-2. カラム内容を確定するボタンクリック

3-1. WholeProductの一覧を作成
3-2. WholeProductsの曖昧予測APIコール
3-3. WholeProductsのリストを作成
3-4. WholeProductsを確定するボタンクリック

4-1. 最終的なHospitalProducts一覧を表示
4-2. インポート処理実行
*/
export const useImportHospitalProductExcel = (hospitalHashId: string) => {
  const wb = useMemo(() => new ExcelJs.Workbook(), []);
  const reader = useMemo(() => new FileReader(), []);

  const {rootCategoryQuery, subCategoryQuery} = useCategoryQuery();
  const {data: hospitalRooms} = useFetchHospitalRooms(hospitalHashId, {
    page: 0,
    perPage: 100,
  });

  const hospitalRoomMap = useMemo(() => {
    const map = new Map<string, string>();
    hospitalRooms.forEach((item) => {
      map.set(item.name, item.hashId);
    });
    return map;
  }, [hospitalRooms]);

  const [selectedWorkSheet, setSelectedWorkSheet] = useState<ExcelJs.Worksheet>();
  const [fileType, setFileType] = useState<'csv' | 'xlsx'>();
  const [workbook, setWorkbook] = useState<ExcelJs.Workbook>();
  const [matchedColumns, setMatchedColumns] = useState<{[columnIndex: number]: string}>({});
  const [confirmedMatchColumn, setConfirmedMatchColumn] = useState(false);
  const [confirmedWholeProduct, setConfirmedWholeProduct] = useState(false);
  const sampleDisplayPage = 0;
  const [hospitalProductsPage, setHospitalProductsPage] = useState<number>(0);
  const [prepareAllHospitalProducts, setPrepareAllHospitalProducts] = useState(false);
  const [products, setProducts] = useAtom(productsAtom);
  const disabledConfirmWholeProduct = useMemo(() => {
    return products.some(
      (item) => (item.makerName ?? '') === '' || (item.rootCategory ?? '') === '' || (item.narrowCategory ?? '') === ''
    );
  }, [products]);

  // 1. ファイルアップロード
  const uploadFile = useCallback(
    (acceptedFile: File) => {
      const mimeType =
        acceptedFile.type === 'text/csv'
          ? 'csv'
          : acceptedFile.type === 'application/vnd.ms-excel' ||
            acceptedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ? 'xlsx'
          : undefined;

      if (mimeType === 'xlsx') {
        reader.readAsArrayBuffer(acceptedFile);
      } else if (mimeType === 'csv') {
        // FIXME: とりあえずShift_JISで読み込むけど、これは自動検知したい
        reader.readAsText(acceptedFile, 'Shift_JIS');
      }

      // reader.readAsArrayBuffer(acceptedFile);
      reader.onload = async () => {
        const buffer = reader.result;
        if (isNullish(buffer)) return;

        if (isNullish(mimeType)) return;
        setFileType(mimeType);

        if (mimeType === 'csv') {
          parse(buffer as string, {
            complete(results: ParseResult<string>) {
              const worksheet = wb.addWorksheet(acceptedFile.name);
              worksheet.addRows(results.data);
              setSelectedWorkSheet(worksheet);
            },
          });
        } else {
          const tmpWorkbook = await wb.xlsx.load(buffer as ArrayBuffer);
          setWorkbook(tmpWorkbook);
        }
      };
    },
    [reader, wb]
  );

  const workSheetOptions = useMemo(() => {
    return (workbook?.worksheets ?? []).map((sheet) => ({value: sheet.id, label: sheet.name}));
  }, [workbook?.worksheets]);

  const selectXlsxWorksheet = useCallback(
    (sheetOption: {value: number; label: string}) => {
      if (isNullish(workbook)) return;

      const selectedSheet = workbook.getWorksheet(sheetOption.value);
      if (isNullish(selectedSheet)) return;

      for (let i = selectedSheet.columnCount; i >= 1; i--) {
        const column = selectedSheet.getColumn(i);
        if (column.hidden) {
          selectedSheet.spliceColumns(i, 1);
        }
      }

      setSelectedWorkSheet(selectedSheet);
    },
    [workbook]
  );

  const totalHospitalProductsCount = useMemo<number>(() => {
    return (selectedWorkSheet?.actualRowCount ?? 0) - 1;
  }, [selectedWorkSheet?.actualRowCount]);

  // 読み込んだファイルのデータ。table用のfieldにはc0から始まるデータになる
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headerRow = useMemo<Column<any>[]>(() => {
    const headerValues = (selectedWorkSheet?.getRow(1).values ?? []) as ExcelJs.CellValue[];
    return (
      headerValues.map((item, index) => {
        return {
          title: getCellStringFromCellValue(item) ?? '',
          field: `c${index}`,
          noBodyWrap: true,
          sorting: false,
        };
      }) ?? []
    );
  }, [selectedWorkSheet]);

  const predictableOriginSystem: PredictableOriginSystem = useMemo(() => {
    if (
      mavinFormatKey.every((item) => {
        if (headerRow.length - 1 < item.columnIndex) return false;
        return headerRow[item.columnIndex]?.title === item.columnName;
      })
    ) {
      return 'mavin';
    }
    return undefined;
  }, [headerRow]);

  // ヘッダーとシステム予測が完了したら各カラムの予測をする
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initMatchedColumnMap: Record<string, string> = {};

    if (predictableOriginSystem === 'mavin') {
      // Mavinの予測
      headerRow.forEach((_cellVal, colIndex) => {
        const matchedMavinCol = mavinFormatMap.find((item) => item.columnIndex === colIndex);
        if (matchedMavinCol) {
          initMatchedColumnMap[colIndex + 1] = matchedMavinCol.hitotsuProperty;
        } else {
          initMatchedColumnMap[colIndex + 1] = 'notImport';
        }
      });
    } else {
      const selectedValues: Record<string, SelectedValuesType> = {};
      // その他の場合の予測
      headerRow.forEach((cellVal, colIndex) => {
        const predictiveLabel = predictFromLabelAlias(cellVal.title);
        const value = selectedValues[predictiveLabel.value];

        if (isNullish(value)) {
          selectedValues[predictiveLabel.value] = {...predictiveLabel, colIndex};
        } else {
          if (value && value.priority < predictiveLabel.priority) {
            selectedValues[predictiveLabel.value] = {...predictiveLabel, colIndex};
          }
        }
      });
      const columnMapValues = Object.values<SelectedValuesType>(selectedValues);
      columnMapValues.forEach((colValue) => {
        initMatchedColumnMap[colValue.colIndex] = colValue.value;
      });
    }

    setMatchedColumns(initMatchedColumnMap);
  }, [headerRow, predictableOriginSystem]);

  // 初期で読み込んだファイルについてのサンプル行表示
  const selectablePropertyOptions = useMemo(() => {
    return [{label: '（インポート対象外列）', value: 'notImport'}, ...hitotsuPropertyOptions];
  }, []);

  // サンプルで表示するインプットしたExcelのデータ
  // TODO: ページネーション（今じゃない）
  const displayedSampleRows = useMemo(() => {
    if (isNullish(fileType)) return [];
    const sampleRowPerPage = 10;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const firstRow: any = {};
    headerRow.forEach((_item, index) => {
      firstRow[`c${index}`] = (
        <PropertySelectorColumn
          columnOptions={selectablePropertyOptions}
          selectValue={matchedColumns[index] ?? 'notImport'}
          onChange={({value}) => {
            setMatchedColumns({...matchedColumns, [index]: value});
          }}
        />
      );
    });
    const rowValues: unknown[] = [firstRow];

    // for 1-based index and ignore header row
    const startRow = sampleRowPerPage * sampleDisplayPage + 2;
    const sampleRows = selectedWorkSheet?.getRows(startRow, sampleRowPerPage) ?? [];
    for (const sampleRow of sampleRows) {
      const rowValue: Record<string, string | number | boolean | undefined> = {};
      sampleRow.eachCell((cell, index) => {
        rowValue[`c${index}`] = getCellValue(cell, fileType);
      });
      rowValues.push(rowValue);
    }

    return rowValues;
  }, [fileType, headerRow, matchedColumns, sampleDisplayPage, selectablePropertyOptions, selectedWorkSheet]);

  const editMatchColumns = useCallback(
    (columns: {[columnIndex: number]: string}) => {
      if (confirmedMatchColumn) return;
      setMatchedColumns(columns);
    },
    [confirmedMatchColumn]
  );

  const confirmMatchColumn = useCallback(() => {
    setConfirmedMatchColumn(true);

    let candidates: WholeProductCandidate[] = [];
    const wholeProductColumns = Object.entries(matchedColumns).filter((item) =>
      wholeProductCandidateColumns.includes(item[1])
    );

    selectedWorkSheet?.eachRow((row, index) => {
      // 先頭行はヘッダーなのでスキップ. 1-based indexなので先頭が1なことに超注意
      if (index === 1) return;

      const wp: WholeProductCandidate = {};
      wholeProductColumns.forEach((col) => {
        if (isNullish(col[1]) || isNullish(fileType)) return;
        if (!row?.hasValues) return;

        const cell = row?.getCell(Number(col[0]));
        const value = getCellValue(cell, fileType);
        wp[col[1] as WholeProductCandidateKeys] = value as string;
      });

      if (Object.values(wp).every((val) => isNullish(val))) return;
      if (candidates.some((candidate) => _.isEqual(candidate, wp))) return;

      candidates.push(wp);
    });
    candidates = candidates.map((item) => ({...item, uuid: v4()}));

    const requestPromise: Promise<Product>[] = [];
    candidates.forEach((candidate) => {
      const candidatePromise = getCandidateWholeProducts(hospitalHashId, candidate).then((wpResult) => {
        const rootCategory = !isNullish(candidate.rootCategory)
          ? CategoryFormatter.getCategoryByName(rootCategoryQuery.data, candidate.rootCategory)
          : (wpResult.categories ?? [])[0];
        const narrowCategory = !isNullish(candidate.narrowCategory)
          ? CategoryFormatter.getCategoryByName(subCategoryQuery.data, candidate.narrowCategory)
          : (wpResult.categories ?? [])[1];

        return {
          hashId: wpResult.hashId,
          uuid: candidate.uuid,
          name: candidate.name ?? '',
          displayName: candidate.displayName ?? '',
          makerName: candidate.makerName ?? '',
          rootCategory: rootCategory?.hashId ?? null,
          narrowCategory: narrowCategory?.hashId ?? null,
          searchKey: getSearchKey(candidate.name, candidate.displayName),
        };
      });
      requestPromise.push(candidatePromise);
    });

    Promise.all(requestPromise).then((reqData) => {
      setProducts(reqData);
    });
  }, [
    fileType,
    hospitalHashId,
    matchedColumns,
    rootCategoryQuery.data,
    selectedWorkSheet,
    setProducts,
    subCategoryQuery.data,
  ]);

  const productMap = useMemo(() => {
    const map = new Map<string, Product>();
    products.forEach((product) => {
      map.set(product.searchKey, product);
    });
    return map;
  }, [products]);

  const confirmWholeProduct = useCallback(async () => {
    setConfirmedWholeProduct(true);

    const result = await bulkInsertWholeProducts(
      hospitalHashId,
      products.map((product) => ({
        ...product,
        isShared: false,
        hashId: null,
        rootCategoryHashId: product.rootCategory ?? undefined,
        narrowCategoryHashId: product.narrowCategory ?? undefined,
      }))
    );

    const confirmedProducts = result.map<Product>((item) => {
      const product = productMap.get(getSearchKey(item.name, item.displayName));
      if (isNullish(product))
        return {
          ...item,
          uuid: v4(),
          searchKey: getSearchKey(item.name, item.displayName),
          rootCategory: null,
          narrowCategory: null,
          makerName: '',
        };
      return {...product, hashId: item.hashId};
    });

    setProducts(confirmedProducts);
  }, [hospitalHashId, productMap, products, setProducts]);

  const hospitalProducts = useMemo(() => {
    // ファイルがインプットされてない or WholeProductが確認されてないならReturn
    if (isNullish(fileType) || !confirmedWholeProduct) return [];
    const keys = Object.keys(matchedColumns);

    // for 1-based index and ignore header row
    const startRow = hospitalProductPerPage * hospitalProductsPage + 2;
    const displayRows = selectedWorkSheet?.getRows(startRow, hospitalProductPerPage) ?? [];
    const rows: ExcelRowsType[] = [];
    for (const displayRow of displayRows) {
      if (!displayRow.hasValues) continue;

      const row: ExcelRowType = {};
      displayRow.eachCell((cell, index) => {
        const key = keys.find((k) => index === Number(k));
        if (isNullish(key) || matchedColumns[Number(key)] === 'notImport') return;
        const fieldName = matchedColumns[Number(key)];
        const cellValue = getCellValue(cell, fileType);
        row[fieldName] = convertCellValue(fieldName, cellValue);
      });
      const product = products.find((wp) => wp.searchKey === getSearchKey(row.name + '', row.displayName + ''));

      rows.push({
        ...row,
        ...product,
        wholeProductHashId: product?.hashId ?? '',
        makerName: product?.makerName ?? '',
        rootCategory: !isNullish(product?.rootCategory)
          ? rootCategoryQuery.data.find((item) => item.hashId === product?.rootCategory)?.name ?? ''
          : '',
        narrowCategory: !isNullish(product?.narrowCategory)
          ? subCategoryQuery.data.find((item) => item.hashId === product?.narrowCategory)?.name ?? ''
          : '',
      });
    }
    return rows;
  }, [
    confirmedWholeProduct,
    fileType,
    hospitalProductsPage,
    matchedColumns,
    products,
    rootCategoryQuery.data,
    selectedWorkSheet,
    subCategoryQuery.data,
  ]);

  const allHospitalProducts = useMemo(() => {
    // ファイルがインプットされてない or WholeProductが確認されてないならReturn
    if (isNullish(fileType) || !confirmedWholeProduct) return [];
    const keys = Object.keys(matchedColumns);

    // for 1-based index and ignore header row
    const displayRows = selectedWorkSheet?.getRows(2, (selectedWorkSheet.actualRowCount ?? 2) - 1) ?? [];
    const rows: ExcelRowsType[] = [];
    for (const displayRow of displayRows) {
      if (!displayRow.hasValues) continue;

      const row: ExcelRowType = {};
      displayRow.eachCell((cell, index) => {
        const key = keys.find((k) => index === Number(k));
        if (isNullish(key) || matchedColumns[Number(key)] === 'notImport') return;
        const fieldName = matchedColumns[Number(key)];
        const cellValue = getCellValue(cell, fileType);
        // if (isNullish(cellValue)) return;
        row[fieldName] = convertCellValue(fieldName, cellValue);
      });
      const product = products.find((wp) => wp.searchKey === getSearchKey(row.name + '', row.displayName + ''));
      rows.push({
        ...row,
        ...product,
        catalogPrice:
          isNullish(row.catalogPrice) || row.catalogPrice === ''
            ? undefined
            : Number((row.catalogPrice as string).replace(/[^0-9]/g, '')),
        optionalBarcode: row.optionalBarcode === '' ? undefined : row.optionalBarcode,
        managementId: isNullish(row.managementId) || row.managementId === '' ? '-' : row.managementId,
        serialNumber: row.serialNumber === '' ? undefined : row.serialNumber,
        wholeProductHashId: product?.hashId ?? '',
        makerName: product?.makerName ?? '',
        rootCategory: product?.rootCategory ?? '',
        narrowCategory: product?.narrowCategory ?? '',
        status: isNullish(row.status) ? 'ready' : row.status,
      });
    }
    setPrepareAllHospitalProducts(rows.length > 0);
    return rows;
  }, [confirmedWholeProduct, fileType, matchedColumns, products, selectedWorkSheet]);

  const confirmHospitalProducts = useCallback(async () => {
    await bulkCreateHospitalProducts(hospitalHashId, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      hospital_products: allHospitalProducts.map((hp: any) => {
        return {
          ...hp,
          optionalBarcode: hp.optionalBarcode === '' ? undefined : hp.optionalBarcode,
          dateOfPurchase: isNullish(hp.dateOfPurchase)
            ? undefined
            : convertDateToSimpleDate((hp.dateOfPurchase as Dayjs).toDate()),
          dateOfDisposal: isNullish(hp.dateOfDisposal)
            ? undefined
            : convertDateToSimpleDate((hp.dateOfDisposal as Dayjs).toDate()),
          legalDurableYear:
            isNullish(hp.legalDurableYear) && isNumber(hp.legalDurableYear) ? undefined : Number(hp.legalDurableYear),
          leaseFee: isNullish(hp.leaseFee) && isNumber(hp.leaseFee) ? undefined : Number(hp.leaseFee),
          rentalFee: isNullish(hp.rentalFee) && isNumber(hp.rentalFee) ? undefined : Number(hp.rentalFee),
          deliveryPrice:
            isNullish(hp.deliveryPrice) && isNumber(hp.deliveryPrice) ? undefined : Number(hp.deliveryPrice),
          hospitalRoomHashId: hospitalRoomMap.get(hp.hospitalRoomHashId) ?? undefined,
        };
      }),
    });
    setProducts([]);
  }, [allHospitalProducts, hospitalHashId, hospitalRoomMap, setProducts]);

  return {
    uploadFile,
    selectXlsxWorksheet,
    editMatchColumns,
    confirmMatchColumn,
    confirmWholeProduct,
    confirmHospitalProducts,
    setHospitalProductsPage,
    fileType,
    confirmedMatchColumn,
    workSheetOptions,
    headerRow,
    displayedSampleRows,
    selectedWorkSheet,
    disabledConfirmWholeProduct,
    prepareAllHospitalProducts,
    hospitalProducts,
    totalHospitalProductsCount,
    hospitalProductsPage,
  };
};

export const getCellValue = (cell: ExcelJs.Cell, fileType: 'xlsx' | 'csv') => {
  if (fileType === 'csv') return cell.value?.toString().replace(/\s+/g, '');

  switch (cell.type) {
    case ExcelJs.ValueType.Number:
    case ExcelJs.ValueType.String:
    case ExcelJs.ValueType.Hyperlink:
    case ExcelJs.ValueType.Boolean:
      return cell.value?.toString().trim();
    case ExcelJs.ValueType.Date:
      return cell.value?.toLocaleString().trim();
    case ExcelJs.ValueType.Formula: {
      const formulaResult = cell.result as ExcelJs.CellFormulaValue['result'];
      if (isNullish(formulaResult)) return '';
      if (typeof formulaResult !== 'object') return formulaResult;
      if (formulaResult instanceof Date) {
        return formulaResult.toLocaleString().trim();
      } else {
        return formulaResult.error as unknown as string;
      }
    }
    case ExcelJs.ValueType.RichText:
      return (cell.value as ExcelJs.CellRichTextValue).richText
        .map((item) => item.text.trim().replace(/\s+/g, ''))
        .join('');
    default:
      return undefined;
  }
};

const getCellStringFromCellValue = (cell: ExcelJs.CellValue): string | undefined => {
  if (isNullish(cell)) return undefined;

  if (!isNullish((cell as ExcelJs.CellRichTextValue).richText))
    return (cell as ExcelJs.CellRichTextValue).richText.map((item) => item.text).join();
  if (!isNullish((cell as ExcelJs.CellHyperlinkValue).text))
    return (cell as ExcelJs.CellHyperlinkValue).text.toString() ?? '';
  if (!isNullish((cell as ExcelJs.CellFormulaValue).formula))
    return (cell as ExcelJs.CellFormulaValue).result?.toString() ?? '';

  return cell.toString() ?? '';
};

const convertCellValue = (propName: string, cellValue: string | number | Date | boolean | undefined) => {
  const propertySetting = mavinFormatMap.find((item) => item.hitotsuProperty === propName);
  if (!isNullish(propertySetting?.dateFormat)) {
    if (isNullish(cellValue) || cellValue === '') return undefined;

    // NOTE: dateFormatが入力されている場合はDateとみなす
    const dayjsDate = dayjs(cellValue as Date, propertySetting?.dateFormat);
    if (!dayjsDate.isValid()) {
      return undefined;
    }

    return dayjsDate;
  }
  if (!isNullish(propertySetting) && !isNullish(propertySetting?.optionMap)) {
    const optionKeys = Object.keys(propertySetting.optionMap);
    const key = optionKeys.find((item) => item === cellValue) ?? 'default';
    const value = propertySetting.optionMap[key as '資産'];
    return isNullish(value) ? propertySetting.optionMap.default : value;
  }
  return cellValue;
};

const getSearchKey = (name?: string, displayName?: string) => {
  return `${name ?? ''}${displayName ?? ''}`;
};

const predictFromLabelAlias = (
  columnHeaderName: string
): {value: string; priority: number; columnHeaderName: string} => {
  const primary = hitotsuPropertyOptions.find((property) => property.label === columnHeaderName);
  if (!isNullish(primary)) return {value: primary.value, priority: 1, columnHeaderName};

  const secondary = hitotsuPropertyOptions.find((property) => columnHeaderName.includes(property.label));
  if (!isNullish(secondary)) return {value: secondary.value, priority: 2, columnHeaderName};

  const tertiary = hitotsuPropertyOptions.find((property) =>
    property.alias.some((alias) => columnHeaderName.includes(alias))
  );
  if (!isNullish(tertiary)) return {value: tertiary.value, priority: 3, columnHeaderName};

  return {value: 'notImport', priority: 4, columnHeaderName};
};
