// 汎用オプション (呼び出し側が必要なオプションを選択してpropsから与える)
export const TermAll = 'all';
export const TermYesterday = 'yesterday';
export const TermToday = 'today';
export const TermLastWeek = 'last_week';
export const TermThisWeek = 'this_week';
export const TermLastMonth = 'last_month';
export const TermThisMonth = 'this_month';
export const TermNextMonth = 'next_month';
export const TermThisQuarter = 'this_quarter';
export const TermThisYear = 'this_year';
export const TermCustom = 'custom';

export const TermAllOption = {label: '全期間', value: TermAll} as const;
export const TermYesterdayOption = {label: '昨日', value: TermYesterday} as const;
export const TermTodayOption = {label: '今日', value: TermToday} as const;
export const TermThisWeekOption = {label: '今週', value: TermThisWeek} as const;
export const TermLastWeekOption = {label: '先週', value: TermLastWeek} as const;
export const TermLastMonthOption = {label: '先月', value: TermLastMonth} as const;
export const TermThisMonthOption = {label: '今月', value: TermThisMonth} as const;
export const TermNextMonthOption = {label: '来月', value: TermNextMonth} as const;
export const TermThisQuaterOption = {label: '今四半期', value: TermThisQuarter} as const;
export const TermThisYearOption = {label: '今年', value: TermThisYear} as const;
export const TermCustomOption = {label: 'カスタム日付範囲', value: TermCustom} as const;
