import React from 'react';
import {Step, StepLabel, Stepper as MStepper, styled} from '@material-ui/core';
import {stepLabels, useSteps} from './hooks';

export const Header: React.FC = () => {
  const {currentStep, completedSteps, changeStep} = useSteps();
  return (
    <Stepper activeStep={currentStep} alternativeLabel>
      {stepLabels.map((label, index) => (
        <Step key={label} completed={completedSteps.includes(index)} onClick={() => changeStep(index)}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const Stepper = styled(MStepper)({
  width: 800,
  marginLeft: 'auto',
  marginRight: 'auto',
  cursor: 'pointer',
});
