import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Dialog} from '@atoms/Dialog/Dialog';
import {DialogProps as DialogHandlerProps} from '@components/molecules/Dialogs/DialogHandler';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import {styled} from '@material-ui/core/styles';
import {TransitionProps} from '@material-ui/core/transitions';
import {Avatar, Box, Typography} from '@material-ui/core';
import {User} from '@auth0/auth0-react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<typeof Dialog, typeof Dialog>},
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledContainerDiv = styled('div')({
  padding: '16px',
});

const PaperCss: React.CSSProperties = {
  width: '100%',
  height: '80%',
  minHeight: '20%',
  position: 'fixed',
  bottom: '-30px',
  overflow: 'visible',
  margin: '0px',
};

type MobileCommentDialogProps = {
  userIndex?: User;
};

type CommentTextAreaProps = {
  height: number;
};

const CommentTextArea = styled('textarea')(({height}: CommentTextAreaProps) => ({
  borderBottom: 'none',
  width: '100%',
  fontSize: '16px',
  height: `${height}px`,
  border: 'none',
  outline: 'none',
}));

const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '12px',
  alignItems: 'center',
});

const Content = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
});

const AvatarIcon = styled(Avatar)({
  width: '30px',
  height: '30px',
  marginRight: '8px',
});

const StyledButton = styled(({color, ...otherProps}) => <Button {...otherProps} />)(({color}: {color: string}) => ({
  // NOTE: && を使用してMuiのButtonのCSSをオーバーライドしている
  '&&': {
    backgroundColor: `${color}`,
    borderRadius: '24px',
    padding: '4px 18px',
    '& span': {
      color: 'white',
      fontSize: '16px',
    },
  },
}));

/**
 * コメント入力ダイアログ
 * @param props.userIndex? {UserIndex} /
 * @returns
 */
export const MobileCommentDialog: React.FC<MobileCommentDialogProps & DialogHandlerProps> = (props) => {
  const {open, actions, userIndex} = props;
  const [comment, setComment] = useState('');
  const [textareaHeight, setTextareaHeight] = useState(40);
  const paperRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const DIALOG_HEADER_HEIGHT = 136;

  const isButtonDisable = useCallback(() => Boolean(!comment), [comment]);

  useEffect(() => {
    // NOTE: ソフトウェアキーボート込のHeightを取得する
    const handleResize = () => {
      if (!window.visualViewport) return;
      // PaperCssでHeightを80％にしているのでTextAreaも80％に変更
      setTextareaHeight(window.visualViewport.height * 0.8 - DIALOG_HEADER_HEIGHT - 10);
    };
    window.visualViewport?.addEventListener('resize', handleResize);
    return () => window.visualViewport?.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const preventScroll = (e: TouchEvent) => {
      // TextAreaのみスクロールを許可
      if (
        textAreaRef.current &&
        textAreaRef.current.contains(e.target as Node) &&
        textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight
      ) {
        e.stopPropagation();
        return;
      }
      e.preventDefault();
    };
    window.addEventListener('touchmove', preventScroll, {passive: false});
    return () => {
      window.removeEventListener('touchmove', preventScroll);
    };
  }, []);

  const handleCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value),
    [setComment]
  );

  const handleCommentSubmit = () => {
    if (actions?.resolve) {
      actions.resolve(comment);
    }
  };
  const handleDialogEntered = () => {
    if (!paperRef.current) return;
    // トランジッション後のサイズを適用する
    setTextareaHeight(paperRef.current.scrollHeight - DIALOG_HEADER_HEIGHT);
  };
  return (
    <Dialog
      open={Boolean(open)}
      onClose={actions?.reject}
      PaperProps={{style: PaperCss, ref: paperRef}}
      TransitionComponent={Transition}
      TransitionProps={{
        onEntered: handleDialogEntered, // トランジッション終了後のコールバック
      }}>
      <StyledContainerDiv>
        <Header>
          <Typography onClick={actions.reject}>キャンセル</Typography>
          <StyledButton
            color={isButtonDisable() ? '#A2BBDE' : '#0052CC'}
            disabled={isButtonDisable()}
            onClick={handleCommentSubmit}>
            コメントする
          </StyledButton>
        </Header>
        <Content>
          <AvatarIcon src={userIndex?.picture} />
          <CommentTextArea
            id="comment"
            ref={textAreaRef}
            placeholder="コメント入力"
            value={comment}
            onChange={handleCommentChange}
            height={textareaHeight}
            autoFocus
          />
        </Content>
      </StyledContainerDiv>
    </Dialog>
  );
};
