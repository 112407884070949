import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Grid, makeStyles} from '@material-ui/core';
import dayjs from 'dayjs';
import {LongPressEvent, useLongPress} from 'use-long-press';
import {RentalStepper} from './Stepper';
import {useAtomValue} from 'jotai';
import {HITOTSULogo} from '@atoms/HITOTSULogo';
import {rentOrReturnSettingAtom, rentStepperAtom} from '../states';
import {useLocation, useNavigate} from 'react-router-dom';
import {PopperMenu} from './PopperMenu';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {TerminalSettingDialog} from './TerminalSettingDialog';
import {useAtom} from 'jotai';
import {useSignOut} from '@modules/auth/hooks';

type Props = {
  title?: string;
  showLogo?: boolean;
};

export const RentalHeaderHeight = '104px';

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    height: RentalHeaderHeight,
    width: '100%',
    padding: '0px 32px 0px 32px',
  },
  title: {
    color: '#374659',
    fontSize: '40px',
    fontWeight: 'bold',
    paddingLeft: '24px',
  },
  date: {
    textAlign: 'right',
  },
  gridItem: {
    paddingTop: '32px',
  },
}));

export const Header: React.VFC<Props> = React.memo(({title, showLogo = false}) => {
  const classes = useStyles();
  const activeStep = useAtomValue(rentStepperAtom);
  const [rentOrReturnSetting, setRentOrReturnSetting] = useAtom(rentOrReturnSettingAtom);
  const path = useLocation().pathname;
  const [now, setNow] = useState<Date>(new Date());
  const [isOpeningMenu, setIsOpeningMenu] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const logout = useSignOut();
  const logoutOnLongPressed = useCallback(() => {
    logout();
  }, [logout]);

  const handleOpenMenu = useCallback((e: LongPressEvent<Element>) => {
    setAnchorEl(e.currentTarget as HTMLElement);
    setIsOpeningMenu(true);
    setOpen(true);
  }, []);

  const handleLongPress = useLongPress(handleOpenMenu, {
    threshold: 2000, // 2秒にしてるとどうも3秒くらいで発火するっぽい
  });

  const handleClickMenu = useCallback(
    async (value: 'logout' | 'terminalSetting' | 'customize') => {
      if (value === 'logout') {
        logoutOnLongPressed();
      }
      if (value === 'terminalSetting') {
        const settingValue = await dialogHandler.open(TerminalSettingDialog, {
          defaultValue: {
            rentalMenuSetting: rentOrReturnSetting,
          },
        });
        setRentOrReturnSetting(settingValue.rentalMenuSetting);
      }
      if (value === 'customize') {
        navigate('/shared/cards_position_setting');
      }
    },
    [logoutOnLongPressed, navigate, rentOrReturnSetting, setRentOrReturnSetting]
  );

  const handleCloseMenu = useCallback(() => {
    // NOTE: 長押し時にクリックイベントが発火してしまうので、メニューを閉じようとしてしまう。
    // そのため、メニューを開いたままにするために、メニューを開いた直後のイベントでは閉じないようにする。
    if (isOpeningMenu) {
      setIsOpeningMenu(false);
      return;
    }

    setOpen(false);
  }, [isOpeningMenu]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newDate = new Date();
      if (now.getMinutes() === newDate.getMinutes()) return;
      setNow(newDate);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const date = useMemo(() => {
    return dayjs(now).format('YYYY年M月D日 (ddd) HH:mm');
  }, [now]);

  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid container item>
          {showLogo && (
            <Grid
              data-testid="rentalMenu"
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              {...handleLongPress()}
              item
              xs={2}
              className={classes.gridItem}>
              <HITOTSULogo />
              <PopperMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                onClickSettingMenu={handleClickMenu}
              />
            </Grid>
          )}
          <Grid item xs={showLogo ? 10 : 12} className={classes.gridItem}>
            <div className={classes.date}>{date}</div>
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.title}>{title}</div>
        </Grid>
        {path !== '/shared' && path !== '/shared/' && (
          <Grid item>
            <RentalStepper activeStep={activeStep} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
});
