import {createTheme} from '@material-ui/core';

// Material UIのテーマ上書き
export const theme = createTheme({
  // フォントサイズ
  typography: {
    fontSize: 14,
  },

  // カラーパレット追加
  palette: {
    type: 'light',
    common: {
      black: '#050505',
      white: '#ffffff',
    },
    primary: {
      light: '#D6E8FC',
      main: '#0A52CC',
      dark: '#003FB5',
    },
    secondary: {
      light: '#E2F4FB',
      main: '#6DC0FF',
      dark: '#2A96E8',
    },
    grey: {
      50: '#F0F2F5',
      100: '#E4E6EC',
      300: '#E0E0E0',
      400: '#D9DFE6',
      600: '#65676B',
      700: '#46484B',
    },
    info: {
      light: '#E2F4FB',
      main: '#6DC0FF',
      dark: '#2A96E8',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#C7243A',
    },
    rent: {
      light: '#2A96E8',
      main: '#0052CC',
      dark: '#0E3870',
    },
    green: {
      light: '#4CAF50',
      main: '#3C9E58',
    },
  },
  zIndex: {
    tooltip: 21474836476, //modalのzIndex＋１
  },
  // classのstyleを上書き
  overrides: {
    MuiDialog: {
      root: {
        zIndex: 21474836475, //hubspotのzIndex＋１
      },
    },
    MuiButton: {
      root: {
        // ボタン内アルファベット文字を大文字変換しない
        textTransform: 'none',
      },
    },
  },
  breakpoints: {
    values: {
      sm: 600,
      tabletV: 768,
      tabletH: 1024,
      laptop: 1280,
      desktop: 1536,
      largeDesktop: 2008,
    },
  },
});

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: true;
    md: false;
    lg: false;
    xl: false;
    tabletV: true; // adds the `tablet` breakpoint
    tabletH: true;
    laptop: true;
    desktop: true;
    largeDesktop: true;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    rent: Palette['primary'];
    green: Palette['primary'];
  }
  interface PaletteOptions {
    rent: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
  }
}
