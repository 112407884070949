import React, {useCallback, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {useAtomValue, useSetAtom} from 'jotai';
import {useNavigate} from 'react-router-dom';
import {Grid, makeStyles} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {useAsyncEffect} from '@front-libs/core';
import {bulkCreateRentals, DeviceLender} from '@modules/rentals/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {lenderNameAtom, rentalSettingAtom, rentRoomAtom, rentStepperAtom} from '@Apps/BaseSharedMenu/states';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {RentProductTable} from '@Apps/BaseSharedMenu/RentDevices/RentProductTable';
import {OperatorTable} from '@Apps/BaseSharedMenu/RentDevices/OperatorTable';
import {RentButton} from '@Apps/BaseSharedMenu/RentDevices/RentButton';
import {scannedProductAtom} from '../states';
import {useAtom} from 'jotai';

export const ConfirmCheckoutRentDevice: React.FC = () => {
  const templateClasses = useTemplate();
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [rentHospitalProducts, setRentHospitalProducts] = useAtom(scannedProductAtom);
  const rentRoom = useAtomValue(rentRoomAtom);
  const lenderName = useAtomValue(lenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? lenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? lenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, lenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setRentHospitalProducts(rentHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [rentHospitalProducts, setRentHospitalProducts]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };

  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    const rentData = rentHospitalProducts.map((item) => ({
      hospitalProductHashId: item.hashId,
      hospitalRoomHashId: rentRoom?.hashId,
      checkoutUserHashId: myInfo.hashId,
      forceRental: true,
      checkoutDeviceLender: deviceLender,
    }));
    await bulkCreateRentals(myInfo.hospitalHashId, rentData);
    navigate('/shared/complete');
    setRentalStepper(3);
    setRentHospitalProducts([]);
  }, [isSubmitting]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  useEffect(() => {
    setRentalStepper(2);
  }, [setRentalStepper]);

  return (
    <>
      <Header title={'貸出'} />
      <Grid
        item
        id={'mainContent'}
        className={clsx(templateClasses.content, classes.contentContainer)}
        style={defaultHeightWidth}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item className={classes.rentalTableContainer}>
            <RentProductTable
              rentRoom={rentRoom?.name ?? '未定'}
              rentingHospitalProducts={rentHospitalProducts}
              onClickCancel={handleClickCancel}
            />
          </Grid>
          <Grid item container alignItems="flex-end" className={classes.operationContainer}>
            {lenderName && (
              <Grid item className={classes.operatorTableGridItem}>
                <OperatorTable operator={lenderName} />
              </Grid>
            )}
            <Grid item style={{width: '100%'}}>
              <RentButton label={'貸出する'} onChange={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer
        text={'貸出する医療機器を\n確認してください'}
        backButtonText={'読み取り画面へ戻る'}
        backButtonSize="large"
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.up('tabletH')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
  rentalTableContainer: {
    maxHeight: '100%',
    overflow: 'scroll',
    height: 'fit-content',
    width: '65%',
    [theme.breakpoints.up('laptop')]: {
      width: '70%',
    },
  },
  barcodeInput: {
    position: 'absolute',
    top: 20,
    marginTop: '-550px',
    '@media screen and (orientation: portrait)': {
      marginTop: '0px',
      marginLeft: '-5500px',
    },
  },
  operationContainer: {
    width: '25%',
    height: 408,
    paddingBottom: 24,
    overflow: 'scroll',
    maxHeight: '100%',
    gap: '20px',
    [theme.breakpoints.up('laptop')]: {
      paddingBottom: 16,
      paddingTop: 16,
    },
  },
  operatorTableGridItem: {
    width: '100%',
    marginTop: '48px',
    [theme.breakpoints.up('laptop')]: {
      marginTop: '28px',
    },
  },
}));
