import React from 'react';
import {
  Grid,
  Divider,
  Typography,
  Box,
  Button,
  TableRow,
  Theme,
  Paper,
  Table,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {Sidebar} from '@components/organisms/Sidebar';
import {styled} from '@material-ui/styles';
import {unregisterServiceWorker} from '@serviceWorker/serviceWorkerRegistration';
import {IndexedDBList} from './IndexedDBList';
import {ServiceWorkerList} from './ServiceWorkerList';
import {CacheStorageList} from './CacheStorageList';
import {UserAgentList} from './UserAgentList';

export const StyledTableHead = styled(TableHead)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.action.selected,
  '& th': {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

export const StyledTableRow = styled(TableRow)(({theme}: {theme: Theme}) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // 最後のborderを消す
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export const StyledButton = styled(Button)({
  margin: '10px',
});

const TitleText = styled(Typography)({
  fontSize: 28,
});

const DivContainer = styled('div')({
  maxWidth: '600px',
  marginBottom: '10px',
});

/** ステータス共通表示テーブル */
export const StatusTable = ({children}: {children: React.ReactNode}) => {
  return (
    <DivContainer>
      <TableContainer component={Paper}>
        <Table>{children}</Table>
      </TableContainer>
    </DivContainer>
  );
};

/** ServiceWorkerの登録解除 */
const onClickUnregister = () => {
  unregisterServiceWorker();
};

/** 各種情報描画 */
const Contents = () => {
  return (
    <div>
      <ServiceWorkerList />
      <IndexedDBList />
      <CacheStorageList />
      <UserAgentList />
      <Grid container>
        <StyledButton variant="contained" onClick={onClickUnregister}>
          ServiceWorker再登録
        </StyledButton>
      </Grid>
    </div>
  );
};

/**
 * タイトルの描画
 * @param dividerClassName: string
 * @returns
 */
const Title = ({dividerClassName}: {dividerClassName: string}) => {
  return (
    <>
      <Grid container>
        <Grid item>
          <TitleText variant={'h1'}>ステータス</TitleText>
          <p>端末に紐付けられた情報を表示しています</p>
        </Grid>
      </Grid>
      <Divider variant="middle" className={dividerClassName} />
    </>
  );
};

/** ステータス描画 */
const StatusView = () => {
  const templateClasses = useSettingsContentTemplate();
  return (
    <Box className={templateClasses.form}>
      <Grid container className={templateClasses.grid}>
        <Grid item className={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item className={templateClasses.content}>
          <Title dividerClassName={templateClasses.divider} />
          <Contents />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * LocalStorageやIndexedDBの情報表示
 * @returns JS.Element
 */
export const AccountStatus = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <StatusView />
    </Grid>
  );
};
