import React, {useMemo} from 'react';
import {makeStyles, Grid, Button, Typography, Drawer, Theme} from '@material-ui/core';
import {FormikContextType, useFormikContext} from 'formik';
import {usePrompt} from '@front-libs/core';

const useFormSubmitDrawerStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  contentContainer: {
    padding: '16px 52px',
  },
  property: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
}));

export type SubmitDrawerProps = {
  resetOnSubmitError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countUpdatedValues?: (context: FormikContextType<any>) => number;
};

export const FormikFormSubmitDrawer: React.VFC<SubmitDrawerProps> = ({
  resetOnSubmitError = false,
  countUpdatedValues,
}) => {
  const classes = useFormSubmitDrawerStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const context = useFormikContext<any>();

  const updatedCount = useMemo(() => {
    const keys = Object.keys(context.values);

    return countUpdatedValues
      ? countUpdatedValues(context)
      : keys.filter((item) => {
          return context.initialValues[item] !== context.values[item];
        }).length;
  }, [context, countUpdatedValues]);

  usePrompt(updatedCount > 0, '更新された項目が未保存ですが、別のページへ移動しますか？');

  return (
    <Drawer
      anchor={'bottom'}
      open={updatedCount > 0}
      variant="persistent"
      PaperProps={{className: classes.root, elevation: 4}}>
      <Grid container className={classes.contentContainer}>
        <Grid item>
          <Button
            data-testid={'drawer-submit-button'}
            variant={'contained'}
            color={'primary'}
            size={'large'}
            disabled={context.isSubmitting || !context.isValid || context.isValidating}
            onClick={async () => {
              try {
                await context.submitForm();
                context.resetForm({});
              } catch (error) {
                console.warn(error);
                if (resetOnSubmitError) context.resetForm({});
              }
            }}>
            保存
          </Button>
          <Button
            data-testid={'drawer-cancel-button'}
            variant={'outlined'}
            color={'primary'}
            size={'large'}
            disabled={context.isSubmitting}
            style={{marginLeft: '12px', marginRight: '32px'}}
            onClick={async () => {
              context.resetForm({});
            }}>
            キャンセル
          </Button>
        </Grid>
        <Grid item className={classes.property}>
          <Typography>{updatedCount}件のプロパティーを保存しますか？</Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};
