import {UserIndex} from './types';

type UserTemp = {
  label: string;
  value: string;
  searchAlias: string[];
  subLabel?: string;
};

export const UserFormatter = {
  getOptions(users: UserIndex[], options?: {withSubLabel?: boolean; withAlias?: boolean}) {
    return users.map((user) => {
      const tmp: UserTemp = {
        label: UserFormatter.getFullName(user),
        value: user.hashId,
        searchAlias: [user.username, user.email],
      };
      if (options && options.withSubLabel) tmp.subLabel = UserFormatter.getFullNameKana(user);
      if (options && options.withAlias)
        tmp.searchAlias = [UserFormatter.getFullNameKana(user), user.username, user.email];
      return tmp;
    });
  },

  getFullName(user?: UserIndex) {
    return (user?.lastName ?? '') + ' ' + (user?.firstName ?? '');
  },

  getFullNameKana(user?: UserIndex) {
    return (user?.lastNameKana ?? '') + ' ' + (user?.firstNameKana ?? '');
  },

  getFullNameFromHashID(users: UserIndex[], hashId?: string) {
    const user = users.find((u) => u.hashId === hashId);
    return (user?.lastName ?? '') + ' ' + (user?.firstName ?? '');
  },
};
