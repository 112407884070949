import {dexieDB} from '@indexedDB/database';
import {
  getHospitalLastSync,
  getHospitalProductsArray,
  getInspectionsArray,
  getInspectionResultsArray,
  getMeArray,
  getHospitalUsersArray,
  getSubmitInspectionResultsArray,
  getWholeProductInspectionProductsArray,
} from '@indexedDB/hospitalLastSync/repository';
import {fetchMyInfoForCache} from '@modules/hospital_users/api';
import dayjs from 'dayjs';

export type dbInfo = {
  [key: string]: string;
};

const getDBSize = (array: Array<unknown>): number => {
  // オブジェクトをJSON形式の文字列に変換
  const jsonString = JSON.stringify(array);
  return new Blob([jsonString]).size;
};

/**
 * IndexedDBの各種テーブルの件数とサイズを取得
 * @returns
 */
export const getIndexedDBSize = async (): Promise<dbInfo | undefined> => {
  if (!dexieDB) return undefined;

  const data = await fetchMyInfoForCache();
  const hospitalHashId = data.data.hospitalHashId;
  // 更新日時
  const hospitalLastSync = await getHospitalLastSync(hospitalHashId);
  const hospitalProducts = await getHospitalProductsArray(hospitalHashId);
  const hospitalUsers = await getHospitalUsersArray(hospitalHashId);
  const inspectionResults = await getInspectionResultsArray(hospitalHashId);
  const inspections = await getInspectionsArray(hospitalHashId);
  const me = await getMeArray(hospitalHashId);
  const submitInspectionResults = await getSubmitInspectionResultsArray(hospitalHashId);
  const wholeProductInspectionProducts = await getWholeProductInspectionProductsArray(hospitalHashId);

  // 推定サイズ
  const indexedDbSize =
    getDBSize(hospitalProducts) +
    getDBSize(hospitalUsers) +
    getDBSize(inspectionResults) +
    getDBSize(inspections) +
    getDBSize(me) +
    getDBSize(submitInspectionResults) +
    getDBSize(wholeProductInspectionProducts);

  return {
    最終更新日時: hospitalLastSync ? dayjs(hospitalLastSync.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '未取得',
    ストレージサイズ: `${indexedDbSize.toLocaleString()} bytes`,
    利用ユーザ保存件数: me.length + '件',
    ユーザ保存件数: hospitalUsers.length + '件',
    機器一覧件数: hospitalProducts.length + '件',
    点検に紐づく製品情報件数: wholeProductInspectionProducts.length + '件',
    点検結果一覧件数: inspectionResults.length + '件',
    点検表登録件数: inspections.length + '件',
    点検結果送信件数: submitInspectionResults.length + '件',
  };
};
