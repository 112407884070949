import React from 'react';
import {Button, ButtonGroup, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

export type DurationButtonProps = {
  buttonGroup: {
    value: string;
    label: string;
  }[];
  value?: string;
  handleChange: (value: string) => void;
};

export const DurationButton: React.FC<DurationButtonProps> = ({buttonGroup, value, handleChange}) => {
  const classes = useStyles();
  return (
    <ButtonGroup className={classes.buttonGroup} variant="outlined">
      {buttonGroup.map((button) => (
        <Button
          key={button.value}
          onClick={() => handleChange(button.value)}
          className={clsx(classes.button, value === button.value && classes.selectedButton)}>
          {button.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    border: 'none',
    height: '32px',
    marginRight: '8px',
    marginLeft: '4px',
  },
  button: {
    width: '6em',
    color: 'rgba(0, 125, 255, 1)',
    border: '1px solid rgba(0, 125, 255, 0.5)',
  },
  selectedButton: {
    backgroundColor: 'rgba(0, 125, 255, 0.08)',
  },
}));
