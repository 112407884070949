import React, {useMemo, useState} from 'react';
import {ButtonProps, Grid, makeStyles, TextField} from '@material-ui/core';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';

type EditFileNameDialogProps = {
  fileName: string;
};

type Props = {
  title?: string;
  setDefaultFileName?: boolean;
  defaultFileName: string;
} & Omit<DialogProps<EditFileNameDialogProps>, 'content'>;

export const EditFileNameDialog: React.VFC<Props> = ({
  defaultFileName,
  title = 'ファイル名を変更',
  setDefaultFileName = true,
  ...props
}) => {
  const classes = useStyles();

  const [fileName, setFileName] = useState<string>(defaultFileName);

  const disabledButton = useMemo(() => {
    return fileName === '';
  }, [fileName]);

  const positiveButtonProps: ButtonProps = {
    disabled: disabledButton,
  };

  return (
    <SimpleDialog
      {...props}
      positiveButtonProps={positiveButtonProps}
      actions={{
        resolve: async (res) => {
          props.actions.resolve({fileName});
          return res;
        },
        reject: async (res) => {
          props.actions.reject(res);
          return res;
        },
      }}
      title={title}
    >
      <Grid container className={classes.fields} direction="column">
        <Grid>
          <TextField
            fullWidth
            variant={'outlined'}
            className={classes.inputName}
            label={'ファイル名'}
            name="name"
            size="small"
            value={fileName}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </SimpleDialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    padding: '24px 48px 24px',
  },
  divider: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
  },
  content: {
    padding: '24px 48px 0px',
  },
  fields: {
    '& > div': {
      marginTop: '16px',
    },
  },
  fileInput: {
    visibility: 'hidden',
  },
  inputName: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '0px 48px 24px',
  },
}));
