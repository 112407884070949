import React from 'react';
import {MobileProductFormField} from '@molecules/MobileProductFormField';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {convertDateToJapaneseDate, convertRFC3339ToDate, isNullish} from '@front-libs/helpers';
import {InnerLoading} from '@molecules/Loading';
import {waysOfPurchaseOptions} from '@Apps/ProductsList/pc/constants';
import dayjs from 'dayjs';

// const formSection: FormSection[] = [
//   {
//     sectionName: '詳細情報',
//     fields: [
//       {
//         type: 'text',
//         label: '購入区分',
//         name: 'waysOfPurchase',
//         options: [
//           {label: '購入', value: 'purchase'},
//           {label: 'リース', value: 'lease'},
//           {label: 'レンタル', value: 'rental'},
//           {label: '代替品', value: 'alternative'},
//           {label: 'デモ機', value: 'demo'},
//           {label: '寄贈', value: 'gift'},
//           {label: '移管', value: 'transfer_control'},
//         ],
//       },
//       // FIXME: レンタル関連はここ
//       {
//         type: 'select',
//         label: '購入元',
//         name: 'purchasedNationalExpense',
//         InputComponent: StandardSelect,
//         options: [
//           {label: '国費', value: true},
//           {label: '院費', value: false},
//         ],
//       },
//       {
//         type: 'number',
//         label: '納入価',
//         name: 'deliveryPrice',
//         isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'select',
//         label: '税込／税抜き',
//         name: 'taxIncluded',
//         isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
//         InputComponent: StandardSelect,
//         options: [
//           {label: '税込', value: true},
//           {label: '税抜き', value: false},
//         ],
//       },
//       {
//         type: 'number',
//         label: '税率',
//         name: 'taxRate',
//         isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'text',
//         label: '資産番号',
//         name: 'assetRegisterNumber',
//         InputComponent: CreateStandardTextFieldWithFormatter('assetRegisterNumber', formatAssetRegisterNumber),
//       },
//       {
//         type: 'number',
//         label: '院内耐用年数（年）',
//         name: 'legalDurableYear',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'number',
//         label: '残存年数（年）',
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         name: 'remainingYear' as any,
//         readOnly: (product: HospitalProductDetail) => true,
//         getValue: (product: HospitalProductDetail) => {
//           const {legalDurableYear, dateOfPurchase} = product;
//           if (!legalDurableYear || !dateOfPurchase) return '';

//           const now = dayjs();
//           const diff = now.diff(dateOfPurchase, 'year');
//           return Math.max(0, legalDurableYear - diff);
//         },
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'text',
//         label: '廃棄日',
//         name: 'dateOfDisposal',
//         InputComponent: StandardDateField,
//         clearable: true,
//       },
//       {
//         type: 'textarea',
//         label: '廃棄理由',
//         name: 'reasonOfDisposal',
//         InputComponent: StandardTextField,
//       },
//       {
//         type: 'textarea',
//         label: '備考1',
//         name: 'notes',
//         InputComponent: StandardTextField,
//         multiline: true,
//       },
//       {
//         type: 'textarea',
//         label: '備考2',
//         name: 'notes2',
//         InputComponent: StandardTextField,
//         multiline: true,
//       },
//       {
//         type: 'textarea',
//         label: '備考3',
//         name: 'notes3',
//         InputComponent: StandardTextField,
//         multiline: true,
//       },
//     ],
//   },
// ];

// const makerInspectionFormSection: FormSection = {
//   sectionName: 'メーカー保守点検設定',
//   fields: [
//     {
//       type: 'text',
//       label: '保守契約',
//       name: 'isMaintenanceContract',
//       InputComponent: StandardSelect,
//       options: [
//         {label: '保守契約', value: true},
//         {label: '保守契約外', value: false},
//       ],
//     },
//     {
//       type: 'text',
//       label: '保守契約期限',
//       name: 'makerInspectionSetting.dueDateOfMakerInspection',
//       InputComponent: StandardDateField,
//       clearable: false,
//     },
//     // {
//     //   type: 'number',
//     //   label: '保守点検間隔（同じ機種に反映されます）',
//     //   name: 'makerInspectionSetting.inspectionPeriod',
//     //   InputComponent: StandardTextField,
//     // },
//     {
//       type: 'text',
//       label: '次回点検予定日',
//       name: 'makerInspectionSetting.nextInspectionDate',
//       InputComponent: StandardDateField,
//     },
//   ],
// };

const fields = [
  {
    type: 'text',
    label: '購入区分',
    name: 'waysOfPurchase',
    getValue: (product: HospitalProductDetail) =>
      waysOfPurchaseOptions.find((item) => item.value === product.waysOfPurchase)?.label ?? '',
  },
  // FIXME: レンタル関連はここ
  {
    type: 'select',
    label: '購入元',
    name: 'purchasedNationalExpense',
    options: [
      {label: '国費', value: true},
      {label: '院費', value: false},
    ],
    getValue: ({purchasedNationalExpense}: HospitalProductDetail) =>
      isNullish(purchasedNationalExpense) ? '' : purchasedNationalExpense ? '国費' : '院費',
  },
  {
    type: 'number',
    label: '納入価',
    name: 'deliveryPrice',
    isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
    getValue: ({deliveryPrice}: HospitalProductDetail) =>
      !isNullish(deliveryPrice) ? `${deliveryPrice.toLocaleString()}円` : '',
  },
  {
    type: 'select',
    label: '税込／税抜き',
    name: 'taxIncluded',
    isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
    getValue: ({taxIncluded}: HospitalProductDetail) => (isNullish(taxIncluded) ? '' : taxIncluded ? '税込' : '税抜き'),
  },
  {
    type: 'number',
    label: '税率',
    name: 'taxRate',
    isVisible: (values: HospitalProductDetail) => values.waysOfPurchase === 'purchase',
    getValue: ({taxRate}: HospitalProductDetail) => (isNullish(taxRate) ? '' : `${taxRate}%`),
  },
  {
    type: 'text',
    label: '資産番号',
    name: 'assetRegisterNumber',
    getValue: ({assetRegisterNumber}: HospitalProductDetail) => assetRegisterNumber ?? '',
  },
  {
    type: 'number',
    label: '院内耐用年数',
    name: 'legalDurableYear',
    getValue: ({legalDurableYear}: HospitalProductDetail) =>
      isNullish(legalDurableYear) ? '' : `${legalDurableYear}年`,
  },
  {
    type: 'number',
    label: '残存年数（年）',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: 'remainingYear' as any,
    getValue: (product: HospitalProductDetail) => {
      const {legalDurableYear, dateOfPurchase} = product;
      if (!legalDurableYear || !dateOfPurchase) return '';

      const now = dayjs();
      const diff = now.diff(dateOfPurchase, 'year');
      return `${Math.max(0, legalDurableYear - diff)}年`;
    },
  },
  {
    type: 'text',
    label: '廃棄日',
    name: 'dateOfDisposal',
    getValue: ({dateOfDisposal}: HospitalProductDetail) => {
      const date = convertRFC3339ToDate(dateOfDisposal ?? '');
      if (isNullish(date)) return '';
      return convertDateToJapaneseDate(date) ?? '';
    },
  },
  {
    type: 'textarea',
    label: '廃棄理由',
    name: 'reasonOfDisposal',
    getValue: ({reasonOfDisposal}: HospitalProductDetail) => reasonOfDisposal ?? '',
  },
  {
    type: 'textarea',
    label: '備考1',
    name: 'notes',
    getValue: ({notes}: HospitalProductDetail) => notes ?? '',
  },
  {
    type: 'textarea',
    label: '備考2',
    name: 'notes2',
    getValue: ({notes2}: HospitalProductDetail) => notes2 ?? '',
  },
  {
    type: 'textarea',
    label: '備考3',
    name: 'notes3',
    getValue: ({notes3}: HospitalProductDetail) => notes3 ?? '',
  },
];

type DetailHospitalProductInfoProps = {
  hospitalProductHashId: string;
};

export const DetailHospitalProductInfo = (props: DetailHospitalProductInfoProps) => {
  const {hospitalProductHashId} = props;
  const {myInfo} = useMyInfo();

  const {data, isLoading: isLoadingHospitalProduct} = useFetchHospitalProductQuery(
    myInfo.hospitalHashId,
    hospitalProductHashId
  );

  if (isLoadingHospitalProduct || isNullish(data)) {
    return <InnerLoading />;
  }

  return (
    <div style={{padding: '16px'}}>
      {fields
        .filter((item) => isNullish(item.isVisible) || item.isVisible(data))
        .map((item) => (
          <div style={{marginBottom: 24}} key={item.name}>
            <MobileProductFormField label={item.label} value={item.getValue(data)} />
          </div>
        ))}
    </div>
  );
};
