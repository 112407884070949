import React, {useCallback, useState} from 'react';
import {
  Accordion as MUIAccordion,
  AccordionSummary as MUIAccordionSummary,
  AccordionDetails as MUIAccordionDetails,
  Divider,
  makeStyles,
} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  accordion: {
    margin: '0px',
    width: '100%',
    height: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionSummary: {
    padding: '0px',
    height: '0px',
    minHeight: '0px',
    '&.Mui-expanded': {
      height: '0px',
      minHeight: '0px',
    },
    '& > div.MuiAccordionSummary-content': {
      margin: '0px',
    },
  },
  accordionDetail: {
    padding: '0px',
  },
  dividerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  leftDivider: {
    width: '24px',
    backgroundColor: '#c6cbd4',
  },
  dividerIconContainer: {
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: 'white',
    border: '1px solid #C6CBD4',
    '& > svg': {
      color: '#172B4D',
      width: '18px',
    },
    '&:hover': {
      background: '#2A96E8',
    },
    '&:hover > svg': {
      color: 'white',
      width: '18px',
    },
  },
  rightDivider: {
    flex: '1 1',
    backgroundColor: '#c6cbd4',
  },
});

type Props = {
  children: React.ReactNode;
  defaultOpen?: boolean;
  onChange?: (open: boolean) => void;
};

export const Accordion: React.FC<Props> = (props) => {
  const {children, defaultOpen = false, onChange} = props;

  const classes = useStyles();

  const [expanded, setExpanded] = useState(defaultOpen);
  const handleClickDivider = useCallback(() => {
    const newExpaned = !expanded;

    setExpanded(newExpaned);
    onChange && onChange(newExpaned);
  }, [expanded, onChange]);

  return (
    <div className={classes.container}>
      <MUIAccordion className={classes.accordion} expanded={expanded}>
        <MUIAccordionSummary className={classes.accordionSummary} />
        <MUIAccordionDetails className={classes.accordionDetail}>{children}</MUIAccordionDetails>
      </MUIAccordion>

      <div className={classes.dividerContainer}>
        <Divider className={classes.leftDivider} />
        <div className={classes.dividerIconContainer} onClick={handleClickDivider}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </div>
        <Divider className={classes.rightDivider} />
      </div>
    </div>
  );
};
