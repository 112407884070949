import React, {Suspense, useCallback} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import {Form, Formik, useFormikContext} from 'formik';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';

type SuspendInspectionFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type SuspendInspectionFormValue = {};

const SuspendInspectionForm = (props: SuspendInspectionFormProps) => {
  const {open, onClose} = props;
  const {submitForm} = useFormikContext<SuspendInspectionFormValue>();
  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: 'auto',
          height: 'auto',
          margin: 0,
          borderRadius: '8px',
        },
      }}>
      <DialogContent style={{margin: '16px 32px', padding: '0px'}}>
        <Typography style={{fontSize: '16px', textAlign: 'center'}}>
          点検結果を保存して、
          <br />
          中断しますか？
          <br />
          <br />
          入力した内容は保存され、
          <br />
          実施途中として記録されます。
        </Typography>
      </DialogContent>
      <DialogActions style={{display: 'flex', padding: '0px', height: '52px'}}>
        <Button
          variant="contained"
          onClick={onClose}
          style={{width: '50%', height: '52px', color: '#0052CC', border: '0.5px solid #C6CBD4'}}>
          <Typography style={{color: '#0052CC'}}>キャンセル</Typography>
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{marginLeft: '0px', width: '50%', height: '52px', color: '#0052CC', border: '0.5px solid #C6CBD4'}}>
          <Typography style={{color: '#0052CC'}}>保存して中断</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type SuspendInspectionDialogProps = DialogProps;

export const SuspendInspectionDialog: React.FC<SuspendInspectionDialogProps> = (props) => {
  const {open} = props;

  const handleSubmit = useCallback(
    async (_: SuspendInspectionFormValue) => {
      props.actions.resolve({});
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  const initialValues = {};

  return (
    <Suspense fallback={null}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={true}>
        <Form>
          <SuspendInspectionForm open={open} onClose={handleClose} />
        </Form>
      </Formik>
    </Suspense>
  );
};
