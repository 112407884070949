export const colorList = ['#0747A6', '#3A77D2', '#689DEB', '#A5C9FD', '#253858', '#4A5D7D', '#7D8FAD', '#AFBBCF'];

export const chartOptions: Highcharts.Options = {
  chart: {},
  title: {
    text: '',
  },
  yAxis: {
    min: 0,
    title: {
      text: '(百万円)',
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color: 'gray',
        textOutline: 'none',
      },
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    floating: false,
    shadow: false,
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}百万円',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
    },
  },
  //   series: [
  //     {
  //       name: '購入',
  //       color: '#0747A6',
  //       data: [3, 5, 1, 13],
  //     },
  //     {
  //       name: '設置',
  //       color: '#3A77D2',
  //       data: [3, 5, 1, 13],
  //     },
  //     {
  //       name: 'レンタル',
  //       color: '#689DEB',
  //       data: [14, 8, 8, 12],
  //     },
  //     {
  //       name: 'リース',
  //       color: '#A5C9FD',
  //       data: [0, 2, 6, 3],
  //     },
  //     {
  //       name: '保守',
  //       color: '#253858',
  //       data: [0, 2, 6, 3],
  //     },
  //     {
  //       name: '点検',
  //       color: '#4A5D7D',
  //       data: [0, 2, 6, 3],
  //     },
  //     {
  //       name: '修理',
  //       color: '#7D8FAD',
  //       data: [0, 2, 6, 3],
  //     },
  //     {
  //       name: '廃棄',
  //       color: '#AFBBCF',
  //       data: [0, 2, 6, 3],
  //     },
  //   ],
};
