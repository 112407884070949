import {Grid, IconButton, styled} from '@material-ui/core';
import React from 'react';
import {UserIndex} from '@modules/hospital_users/types';
import {MoreVert} from '@material-ui/icons';
import {UserFormatter} from '@modules/hospital_users/helpers';
import dayjs from 'dayjs';
import {isNullish} from '@front-libs/helpers';

const FileTitle = styled('div')({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#172B4D',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
const FileMetaInfo = styled('div')({
  fontSize: '12px',
  lineHeight: '14px',
  color: '#172B4D',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
const FileGrid = styled(Grid)({
  borderBottom: '1px solid #DDE0E4',
  padding: '10px 10px 4px',
});

export type MobileFileListItemProps = {
  fileHashId: string;
  fileName: string;
  createdAt: string;
  createdBy?: UserIndex;

  onClickMenu?: (fileHashId: string) => void;
  onClickDownload?: (fileHashId: string) => void;
};

export const MobileFileListItem = (props: MobileFileListItemProps) => {
  const {fileHashId, fileName, createdAt, createdBy, onClickMenu} = props;
  const displayCreatedAt = dayjs(createdAt).format('YYYY.MM.DD');
  const fullName = UserFormatter.getFullName(createdBy);

  return (
    <FileGrid container justifyContent="space-between">
      <Grid item style={{width: 'calc(100% - 48px)'}}>
        <FileTitle>{fileName}</FileTitle>
        <FileMetaInfo>{`${displayCreatedAt}${!isNullish(fullName) ? `、${fullName}さんが共有` : ''}`}</FileMetaInfo>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => {
            if (!onClickMenu) return;
            onClickMenu(fileHashId);
          }}>
          <MoreVert />
        </IconButton>
      </Grid>
    </FileGrid>
  );
};
