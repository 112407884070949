import {styled} from '@material-ui/core';
import React from 'react';

const Section = styled('div')({
  paddingTop: '16px',
  fontSize: '16px',
  color: '#172B4D',
  lineHeight: '14px',
});

type MobileTimelineSectionProps = {
  children: React.ReactNode;
};

export const MobileTimelineSection = (props: MobileTimelineSectionProps) => {
  const {children} = props;
  return <Section>{children}</Section>;
};
