import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Grid, Paper} from '@material-ui/core';
import {
  useGetDailyRentalRatioQuery,
  useGetMonthlyRentalRatioQuery,
  useGetWeeklyRentalRatioQuery,
  useGetYearlyRentalRatioQuery,
} from '@modules/rental_ratio/api';

import {RentalRatioType} from './types';

import {useGetHospitalCategories} from '@modules/categories/api';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {useStyles} from './styles';
import {Chart} from './Chart';
import {Toolbar} from './Toolbar';

export const RentalRatio: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const [date, setDate] = useState<Date>(new Date());
  const handleChangeDate = useCallback((d: Date | null) => {
    if (!d) return;
    setDate(d);
  }, []);

  const handleChangeYear = useCallback(
    (year: number) => {
      const d = new Date(date);
      d.setFullYear(year);
      setDate(d);
    },
    [date]
  );

  const handleChangeMonth = useCallback(
    (month: number) => {
      const d = new Date(date);
      d.setMonth(month);
      setDate(d);
    },
    [date]
  );

  const [rentalRatioType, setRentalRatioType] = useState<RentalRatioType>('daily');
  const handleChangeRentalRatioType = useCallback((type) => {
    setRentalRatioType(type);
  }, []);

  const [categoryHashId, setCategoryHashId] = useState('');
  const handleChangeCategory = useCallback((category: string) => {
    setCategoryHashId(category);
  }, []);

  const dailyRentalRatioQuery = useGetDailyRentalRatioQuery(
    myInfo.hospitalHashId,
    convertDateToSimpleDate(date),
    categoryHashId,
    {enabled: categoryHashId !== ''}
  );
  const weeklyRentalRatioQuery = useGetWeeklyRentalRatioQuery(
    myInfo.hospitalHashId,
    convertDateToSimpleDate(date),
    categoryHashId,
    {enabled: categoryHashId !== ''}
  );
  const monthlyRentalRatioQuery = useGetMonthlyRentalRatioQuery(
    myInfo.hospitalHashId,
    date.getFullYear(),
    date.getMonth() + 1,
    categoryHashId,
    {enabled: categoryHashId !== ''}
  );
  const yearlyRentalRatioQuery = useGetYearlyRentalRatioQuery(
    myInfo.hospitalHashId,
    date.getFullYear(),
    categoryHashId,
    {enabled: categoryHashId !== ''}
  );

  const hospitalCategoriesQuery = useGetHospitalCategories(myInfo.hospitalHashId, {
    depth: 1,
  });

  const actionMenuItems = useMemo(() => {
    if (!hospitalCategoriesQuery.isSuccess || hospitalCategoriesQuery.isLoading) return [{label: '取得中', value: ''}];

    return hospitalCategoriesQuery.data.map((c) => ({
      label: c.name,
      value: c.hashId,
    }));
  }, [hospitalCategoriesQuery.data, hospitalCategoriesQuery.isLoading, hospitalCategoriesQuery.isSuccess]);

  // 非同期にカテゴリーが取得されたら、最初のカテゴリーを選択状態にする
  useEffect(() => {
    if (hospitalCategoriesQuery.isSuccess && hospitalCategoriesQuery.data.length > 0) {
      setCategoryHashId(hospitalCategoriesQuery.data[0].hashId);
    }
  }, [hospitalCategoriesQuery.data, hospitalCategoriesQuery.isSuccess]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container alignItems="center">
          <Toolbar
            date={date}
            rentalRatioType={rentalRatioType}
            actionMenuItems={actionMenuItems}
            categoryHashId={categoryHashId}
            onChangeRentalRatioType={handleChangeRentalRatioType}
            onChangeDate={handleChangeDate}
            onChangeMonth={handleChangeMonth}
            onChangeYear={handleChangeYear}
            onChangeCategory={handleChangeCategory}
          />
        </Grid>
        <Grid className={classes.highChartContainer}>
          <Chart
            date={date}
            rentalRatioType={rentalRatioType}
            dailyRentalData={dailyRentalRatioQuery.data?.data}
            weeklyRentalData={weeklyRentalRatioQuery.data?.data}
            monthlyRentalData={monthlyRentalRatioQuery.data?.data}
            yearlyRentalData={yearlyRentalRatioQuery.data?.data}
          />
        </Grid>
      </div>
    </Paper>
  );
};
