import {atom} from 'jotai';
import {DateFieldValue} from '@molecules/DateField';

export type Product = {
  managementId: string | null;
  permanentlyAssigned: boolean;
  status: string;
  hospitalRoomHashId: string | null;
  dateOfPurchase: string | null;
  isBaseUnit: boolean;
  waysOfPurchase: string;
  legalDurableYear?: number;
  isMaintenanceContract: boolean;
  // depreciationAmount: number | null;
  // bookValue: number | null;
  serialNumber: string | null;
  assetRegisterNumber: string | null;
  hospitalDealerHashId: string | null;
  hospitalProductMgmtSectionHashId: string | null;
  dateOfDisposal: DateFieldValue | null;
  reasonOfDisposal: string | null;
  notes: string | null;
  notes2: string | null;
  notes3: string | null;
  uuid?: string;
  lotNumber: string | null;
  optionalBarcode: string | null;
  rawBarcode: string | null;
  wholeProductHashID?: string;
  periodicInspectionStartDate: DateFieldValue | null;
};

export const InitialValue: Product = {
  managementId: null,
  permanentlyAssigned: false,
  status: 'ready',
  hospitalRoomHashId: null,
  dateOfPurchase: null,
  isBaseUnit: true,
  waysOfPurchase: 'purchase',
  legalDurableYear: undefined,
  isMaintenanceContract: true,
  // depreciationAmount: null,
  // bookValue: null,
  serialNumber: null,
  assetRegisterNumber: null,
  hospitalDealerHashId: null,
  hospitalProductMgmtSectionHashId: null,
  dateOfDisposal: null,
  reasonOfDisposal: null,
  periodicInspectionStartDate: null,
  notes: null,
  notes2: null,
  notes3: null,
  lotNumber: null,
  optionalBarcode: null,
  rawBarcode: null,
};

export const productsAtom = atom<Product[]>([]);
export const productCountAtom = atom<number>(1);
