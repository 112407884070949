import {snakeCase} from 'lodash';

export const getQueryOrderKey = (fieldOrderKey: string) => {
  const queryOrderKey = snakeCase(fieldOrderKey);
  // recover direction
  return fieldOrderKey[0] !== '-' ? queryOrderKey : `-${queryOrderKey}`;
};

export const pickFirstQuery = (query: string | (string | null)[] | null) => {
  if (Array.isArray(query)) {
    return query[0];
  } else {
    return query;
  }
};
