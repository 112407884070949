import {dexieDB} from '@indexedDB/database';
import {WhereIndexType} from '../types';
import {SubmitInspectionResultsIndex} from '@indexedDB/SubmitInspectionResults/types';

// FIXME:汎用的に作れてない。filterの部分は今後改善必要。
export const getSubmitInspectionResultByIndex = async (
  whereIndex: WhereIndexType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: (item: any) => boolean
) => {
  return dexieDB?.submitInspectionResults.where(whereIndex).filter(filter).toArray();
};

export const upsertSubmitInspectionResult = async (submitInspectionResult: SubmitInspectionResultsIndex) => {
  return dexieDB?.submitInspectionResults.put(submitInspectionResult);
};
