import React, {useCallback, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {useAtomValue, useSetAtom} from 'jotai';
import {useNavigate} from 'react-router-dom';
import {Grid, makeStyles} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {useAsyncEffect} from '@front-libs/core';
import {FetchHospitalProductsParams, getHospitalProducts} from '@modules/hospital_products/api';
import {bulkCreateRentals, DeviceLender} from '@modules/rentals/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  defaultBarcodeScannerTypeAtom,
  canUseDeviceCameraAtom,
  lenderNameAtom,
  rentalSettingAtom,
  rentRoomAtom,
  rentStepperAtom,
} from '@Apps/BaseSharedMenu/states';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {RentalErrorDialog} from '@Apps/BaseSharedMenu/RentalErrorDialog';
import {RentProductTable} from '@Apps/BaseSharedMenu/RentDevices/RentProductTable';
import {OperatorTable} from '@Apps/BaseSharedMenu/RentDevices/OperatorTable';
import {RentButton} from '@Apps/BaseSharedMenu/RentDevices/RentButton';
import {UnacceptedStatus} from '@Apps/BaseSharedMenu/RentDevices/types';
import {NoRowsContent} from '../../NoRowsContent';
import {StrUtil} from '@front-libs/helpers';
import {useValidateToRent} from './hooks';

export const RentByBarcodeReader: React.FC = () => {
  const templateClasses = useTemplate();
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [rentHospitalProducts, setRentHospitalProducts] = useState<HospitalProductIndex[]>([]);
  const rentRoom = useAtomValue(rentRoomAtom);
  const lenderName = useAtomValue(lenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [managementId, setManagementId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState<UnacceptedStatus>(null);
  const navigate = useNavigate();
  const {validateToRent} = useValidateToRent();
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);
  const canUseDeviceCamera = useAtomValue(canUseDeviceCameraAtom);
  const setDefaultBarcodeScannerType = useSetAtom(defaultBarcodeScannerTypeAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? lenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? lenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, lenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setRentHospitalProducts(rentHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [rentHospitalProducts]
  );

  const handleClickSwitchDeviceCamera = useCallback(() => {
    if (canUseDeviceCamera) {
      setDefaultBarcodeScannerType('camera');
      navigate('/shared/rental/product/camera');
    }
  }, [canUseDeviceCamera, navigate, setDefaultBarcodeScannerType]);

  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Enter以外のキーを検知したらmanagementIdに連結する
      if (event.key !== 'Enter') {
        setManagementId(managementId + event.key);
        return;
      } else if (event.key === 'Enter' && managementId.length === 0) {
        return;
      }

      setManagementId('');

      // Enterを検知したら、managementIdをもとに機器情報を検索する
      let parameterManagementId = StrUtil.toHankaku(managementId);
      if (parameterManagementId[0] === '!') {
        parameterManagementId = parameterManagementId.slice(0, -1);
        const index = parameterManagementId.indexOf('('); // `(` のインデックスを取得
        if (index !== -1) {
          // `(` が見つかった場合
          parameterManagementId = parameterManagementId.slice(index); // `(` の次の文字から末尾までを取得
        }
      }

      const params: FetchHospitalProductsParams = {
        managementId: parameterManagementId,
        permanentlyAssigneds: false,
        statuses: 'ready,working,uninspected,repairing',
      };

      const {data, totalCount} = await getHospitalProducts(myInfo.hospitalHashId, params);

      if (totalCount === 0) {
        setStatus(null);
        setOpenDialog(true);
      } else {
        const validateStatus = validateToRent(data);
        if (validateStatus !== null) {
          setStatus(validateStatus);
          setOpenDialog(true);
          return;
        }
        setRentHospitalProducts([
          ...rentHospitalProducts,
          ...data.filter((item) => !rentHospitalProducts.some((d) => d.hashId === item.hashId)),
        ]);
      }
    },
    [managementId, myInfo.hospitalHashId, rentHospitalProducts, validateToRent]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };
  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    const rentData = rentHospitalProducts.map((item) => ({
      hospitalProductHashId: item.hashId,
      hospitalRoomHashId: rentRoom?.hashId,
      checkoutUserHashId: myInfo.hashId,
      forceRental: true,
      checkoutDeviceLender: deviceLender,
    }));
    await bulkCreateRentals(myInfo.hospitalHashId, rentData);
    navigate('/shared/complete');
    setRentalStepper(3);
  }, [isSubmitting]);

  const focusInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!openDialog) e.target.focus();
    },
    [openDialog]
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  useEffect(() => {
    setRentalStepper(2);
  }, [setRentalStepper]);

  return (
    <>
      <Header title={'貸出'} />
      <Grid
        item
        id={'mainContent'}
        className={clsx(templateClasses.content, classes.contentContainer)}
        style={defaultHeightWidth}>
        <input
          type="text"
          autoFocus
          readOnly
          onBlur={focusInput}
          value={managementId}
          onKeyPress={handleBarcodeReaderInput}
          className={classes.barcodeInput}
          aria-label="barcode-input"
        />

        {rentHospitalProducts.length === 0 ? (
          <NoRowsContent />
        ) : (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={classes.rentalTableContainer}>
              <RentProductTable
                rentRoom={rentRoom?.name ?? '未定'}
                rentingHospitalProducts={rentHospitalProducts}
                onClickCancel={handleClickCancel}
              />
            </Grid>
            <Grid item container alignItems="flex-end" className={classes.operationContainer}>
              {lenderName && (
                <Grid item className={classes.operatorTableGridItem}>
                  <OperatorTable operator={lenderName} />
                </Grid>
              )}
              <Grid item style={{width: '100%'}}>
                <RentButton label={'貸出する'} onChange={handleSubmit} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Footer
        text={'貸出する医療機器の\nバーコードを読み取って下さい'}
        nextButtonLabel={canUseDeviceCamera ? 'カメラで読み取る' : undefined}
        onClickNextButton={handleClickSwitchDeviceCamera}
      />
      <RentalErrorDialog
        open={openDialog}
        onClickButton={() => setOpenDialog(false)}
        status={status as UnacceptedStatus}
        type="rent"
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.up('tabletH')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
  rentalTableContainer: {
    maxHeight: '100%',
    overflow: 'scroll',
    height: 'fit-content',
    width: '65%',
    [theme.breakpoints.up('laptop')]: {
      width: '70%',
    },
  },
  barcodeInput: {
    position: 'absolute',
    top: 20,
    marginTop: '-550px',
    '@media screen and (orientation: portrait)': {
      marginTop: '0px',
      marginLeft: '-5500px',
    },
  },
  operationContainer: {
    width: '25%',
    height: 408,
    paddingBottom: 24,
    overflow: 'scroll',
    maxHeight: '100%',
    gap: '20px',
    [theme.breakpoints.up('laptop')]: {
      paddingBottom: 16,
      paddingTop: 16,
    },
  },
  operatorTableGridItem: {
    width: '100%',
    marginTop: '48px',
    [theme.breakpoints.up('laptop')]: {
      marginTop: '28px',
    },
  },
}));
