import {FileCategory} from './types';

export const toFileLabel = (category: FileCategory) => {
  switch (category) {
    case 'general':
      return '指定なし';
    case 'profile':
      return 'プロフィール画像';
    case 'inspection_result':
      return '点検結果ファイル';
    case 'whole_product_thumbnail':
      return '機種サムネイル画像';
    case 'hospital_product':
      return '機器ファイル';
    case 'fault_repair':
      return '修理ファイル';
    case 'hospital_user_import_file':
      return '初期ユーザー登録';
    case 'hospital_product_import_file':
      return '初期機器台帳登録';
    case 'training_schedule_material':
      return '研修資材ファイル';
    case 'training_schedule_trainee_list':
      return '研修対象者・受講者ファイル';
    case 'training_report_name_list':
      return '研修対象者・受講者ファイル';
    case 'maker_inspection_result':
      return 'メーカー点検結果ファイル';
    default:
      return '不明';
  }
};
