import React, {useCallback, useMemo} from 'react';
import {PDFDisplay} from '@molecules/Utils/PDFDisplay';
import {Button, DialogContent, DialogActions, DialogTitle} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Dialog} from '@atoms/Dialog/Dialog';
import {isChrome, isIOS} from 'react-device-detect';

type Props = {
  fileName: string;
  fileType: string;
  url: string;
  title: string;
} & DialogProps;

export const FilePreviewDialog: React.FC<Props> = (props) => {
  const {
    open,
    actions: {reject},
    fileName,
    fileType,
    url,
    title,
    actions,
  } = props;

  const download = useCallback(async () => {
    const reader = new FileReader();
    reader.onloadend = function (e) {
      let dataUrl = (reader.result && reader.result.toString()) || '';
      if (isChrome && isIOS) {
        dataUrl = dataUrl.replace(/^data:[^;]*;/, 'data:attachment/file;');
      }
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', dataUrl);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    const res = await fetch(url);
    reader.readAsDataURL(await res.blob());
  }, [fileName, url]);

  const preview = useMemo(() => {
    if (fileType.match('application/pdf')) {
      return <PDFDisplay src={url} />;
    } else if (fileType.match('image/*')) {
      return (
        <img
          src={url}
          alt={fileName}
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}>
          この形式のファイルはプレビューできません。
        </div>
      );
    }
  }, [fileName, fileType, url]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg" PaperProps={{style: {height: '100%'}}} title={title} onClose={reject}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          height: '100%',
          padding: 0,
          overflow: 'hidden',
        }}>
        {preview}
      </DialogContent>
      <DialogActions style={{justifyContent: 'flex-end'}}>
        <Button onClick={download} color={'primary'} style={{whiteSpace: 'nowrap'}}>
          ダウンロード
        </Button>
        <Button onClick={actions.resolve} color={'primary'} style={{whiteSpace: 'nowrap'}}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
