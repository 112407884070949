import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {FiberManualRecord} from '@material-ui/icons';
import {InspectionStatus} from '@modules/inspections/enum';
import {inspectionStatusType} from '@constants/constants';

const statuses: Status[] = [
  {
    value: 'available',
    label: '利用中',
    indicator: <FiberManualRecord style={{color: inspectionStatusType.available.color}} />,
  },
  {
    value: 'unassigned',
    label: '未割当',
    indicator: <FiberManualRecord />,
  },
  {
    value: 'draft',
    label: '下書き',
    indicator: <FiberManualRecord />,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    items: {
      padding: '0px',
      '& > *:not(:first-child)': {
        marginTop: '8px',
      },
    },
    item: {
      '&.Mui-selected': {
        backgroundColor: 'rgba(42, 150, 232, 0.1) !important',
        borderRadius: '3px',
      },
    },
  })
);

export type Status = {
  value: InspectionStatus;
  label: string;
  indicator: React.ReactNode;
};

type StatusSelectorProps = {
  selectedStatus: InspectionStatus | null;
  counts: Record<InspectionStatus, number | null>;
  onSelectStatus: (status: InspectionStatus | null) => void;
};

export const StatusSelector: React.VFC<StatusSelectorProps> = (props) => {
  const {selectedStatus, counts, onSelectStatus} = props;
  const classes = useStyles();

  return (
    <List className={classes.items}>
      <ListItem className={classes.item} button onClick={() => onSelectStatus(null)} selected={selectedStatus === null}>
        <ListItemText primary="すべての点検表" />
      </ListItem>
      {statuses.map(({value, label, indicator}) => (
        <ListItem
          key={value}
          button
          onClick={() => onSelectStatus(value)}
          selected={selectedStatus === value}
          className={classes.item}>
          {indicator !== null && <ListItemIcon>{indicator}</ListItemIcon>}
          <ListItemText primary={label} />
          {counts[value] !== null && (
            <ListItemSecondaryAction>
              <ListItemText primary={`${counts[value]}`} />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};
