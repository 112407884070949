import {axios} from '@front-libs/core';
import {convertDateToSimpleDate, isNullish} from '@front-libs/helpers';
import {MakerInspectionSetting} from './types';

export type GetMakerInspectionSettingsParams = {
  hospitalProductHashId: string;
};

export type UpdateMakerInspectionSettingParams = {
  dueDateOfMakerInspection?: Date;
  nextInspectionDate?: Date;
  inspectionPeriod?: number;
  inspectionPeriodUnit?: 'year';
};

export type CreateMakerInspectionSettingParams = {
  hospitalProductHashId: string;
  dueDateOfMakerInspection?: Date;
  nextInspectionDate?: Date;
  inspectionPeriod?: number;
  inspectionPeriodUnit?: 'year';
};

export const getMakerInspectionSettings = async (hospitalHashId: string, params: GetMakerInspectionSettingsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/maker_inspections/settings`;
  return await axios.get<MakerInspectionSetting[]>(`${baseUrl}`, {params});
};

export const createMakerInspectionSetting = async (
  hospitalHashId: string,
  data: CreateMakerInspectionSettingParams
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/maker_inspections/settings`;
  return await axios.post<MakerInspectionSetting>(`${baseUrl}`, {
    ...data,
    nextInspectionDate: !isNullish(data.nextInspectionDate)
      ? convertDateToSimpleDate(data.nextInspectionDate)
      : undefined,
    dueDateOfMakerInspection: !isNullish(data.dueDateOfMakerInspection)
      ? convertDateToSimpleDate(data.dueDateOfMakerInspection)
      : undefined,
  });
};

export const updateMakerInspectionSetting = async (
  hospitalHashId: string,
  settingHashId: string,
  data: UpdateMakerInspectionSettingParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/maker_inspections/settings/${settingHashId}`;
  return await axios.put<MakerInspectionSetting>(`${baseUrl}`, {
    ...data,
    nextInspectionDate: !isNullish(data.nextInspectionDate)
      ? convertDateToSimpleDate(data.nextInspectionDate)
      : undefined,
    dueDateOfMakerInspection: !isNullish(data.dueDateOfMakerInspection)
      ? convertDateToSimpleDate(data.dueDateOfMakerInspection)
      : undefined,
  });
};
