import {Grid, InputAdornment, makeStyles, TextField} from '@material-ui/core';
import React from 'react';

type Props = {
  onChangeInspectionFee: (inspectionFee: number) => void;
  onChangeComment: (comment: string) => void;
};

export const SideNav: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Grid container style={{padding: 16, background: '#FFF'}} direction="column">
      <Grid item>
        <div
          style={{
            fontWeight: 700,
            fontSize: 14,
            marginBottom: 24,
          }}>
          この点検について
        </div>
      </Grid>
      <Grid item className={classes.fieldContainer}>
        <TextField
          style={{
            fontSize: 14,
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputText,
              shrink: classes.shrink,
            },
          }}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.inputText,
              underline: classes.underline,
            },
            inputProps: {min: 0},
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          type={'number'}
          label={'保守契約料'}
          fullWidth
          onChange={(e) => props.onChangeInspectionFee(Number(e.target.value))}
        />
      </Grid>
      <Grid item className={classes.fieldContainer}>
        <TextField
          style={{
            fontSize: 14,
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputText,
              shrink: classes.shrink,
            },
          }}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.inputText,
              underline: classes.underline,
            },
          }}
          type={'text'}
          label={'コメント'}
          fullWidth
          multiline
          onChange={(e) => props.onChangeComment(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 14,
  },
  inputRoot: {
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  inputText: {
    fontSize: theme.typography.fontSize,
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  underline: {
    '&&&:before': {
      borderBottom: '0px solid',
    },
    '&&:after': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));
