import {Atom, atom, useAtomValue} from 'jotai';
import {TimePeriodType} from '@modules/hospital_product_costs';
import {SelectedViewType} from './types';
import {selectAtom, useReducerAtom} from 'jotai/utils';
import {useCallback, useMemo} from 'react';
import dayjs from 'dayjs';

// State
export type CostTrendsByCostCategoryState = {
  initialized: boolean;
  selectView: SelectedViewType;
  paymentDateTo: Date;
  displayTimePeriod: TimePeriodType;
  selectedRootCategoryHashId: string | null;
};

export const initialState: CostTrendsByCostCategoryState = {
  initialized: false,
  selectView: 'costCategory',
  paymentDateTo: dayjs().endOf('year').toDate(),
  displayTimePeriod: 'year',
  selectedRootCategoryHashId: null,
};

export const costTrendsByCostCategoryStateAtom = atom<CostTrendsByCostCategoryState>({...initialState});

// ActionCreator
export const createSetInitializedAction = (initialized: boolean) => ({
  type: 'SET_INITIALIZED' as const,
  initialized,
});

export const createChangeSelectViewAction = (selectView: SelectedViewType) => ({
  type: 'CHANGE_SELECT_VIEW' as const,
  selectView,
});

export const createChangePaymentDateTo = (paymentDateTo: Date) => ({
  type: 'CHANGE_PAYMENT_DATE_TO' as const,
  paymentDateTo,
});

export const createChangePaymentDateRange = (direction: 'next' | 'previous') => ({
  type: 'CHANGE_PAYMENT_DATE_RANGE' as const,
  direction,
});

export const createChangeDisplayTimePeriod = (timePeriod: TimePeriodType) => ({
  type: 'CHANGE_TIME_PERIOD' as const,
  timePeriod,
});

export const createChangeSelectedRootCategoryHashId = (rootCategoryHashId: string | null) => ({
  type: 'CHANGE_SELECT_ROOT_CATEGORY_HASH_ID' as const,
  rootCategoryHashId,
});

export type Actions =
  | ReturnType<typeof createSetInitializedAction>
  | ReturnType<typeof createChangeSelectViewAction>
  | ReturnType<typeof createChangePaymentDateTo>
  | ReturnType<typeof createChangePaymentDateRange>
  | ReturnType<typeof createChangeDisplayTimePeriod>
  | ReturnType<typeof createChangeSelectedRootCategoryHashId>;

// Reducer
const costTrendsByCostCategoryStateReducer = (
  prev: CostTrendsByCostCategoryState,
  action: Actions
): CostTrendsByCostCategoryState => {
  switch (action.type) {
    case 'SET_INITIALIZED':
      return {...prev, initialized: action.initialized, paymentDateTo: dayjs().endOf('year').toDate()};
    case 'CHANGE_SELECT_VIEW':
      return {...prev, selectView: action.selectView};
    case 'CHANGE_PAYMENT_DATE_TO':
      return {...prev, paymentDateTo: action.paymentDateTo};
    case 'CHANGE_PAYMENT_DATE_RANGE': {
      // 移動する月数を取得
      // 1ヶ月前に移動する場合は、1ヶ月前の月末日を取得する
      const timePeriod = prev.displayTimePeriod === 'quarter' ? 3 : prev.displayTimePeriod === 'year' ? 12 : 1;
      const paymentDateTo =
        action.direction === 'next'
          ? dayjs(prev.paymentDateTo).add(timePeriod, 'month').endOf('month')
          : dayjs(prev.paymentDateTo).subtract(timePeriod, 'month').endOf('month');
      console.log(paymentDateTo.format('YYYY-MM-DD'));
      return {...prev, paymentDateTo: paymentDateTo.toDate()};
    }
    case 'CHANGE_TIME_PERIOD':
      return {...prev, displayTimePeriod: action.timePeriod};
    case 'CHANGE_SELECT_ROOT_CATEGORY_HASH_ID':
      return {...prev, selectedRootCategoryHashId: action.rootCategoryHashId};
    default:
      console.warn('undefined action is dispatched', action);
      return prev;
  }
};

// helper
export const useCostTrendsByCostCategoryState = () =>
  useReducerAtom(costTrendsByCostCategoryStateAtom, costTrendsByCostCategoryStateReducer);

const useMemoizedDispatch = () => {
  const [, dispatch] = useCostTrendsByCostCategoryState();
  return useMemo(() => dispatch, [dispatch]);
};

const useSelectState = <T, U = T>(valueAtom: Atom<T>, actionCreator: (value: U) => Actions) => {
  const value = useAtomValue(valueAtom);
  const dispatch = useMemoizedDispatch();
  const update = useCallback((x: U) => dispatch(actionCreator(x)), [actionCreator, dispatch]);
  return [value, update] as const;
};

// hooks
const _initializedAtom = selectAtom(costTrendsByCostCategoryStateAtom, (state) => state.initialized);
export const useInitialized = () =>
  useSelectState(_initializedAtom, (initialized: boolean) => createSetInitializedAction(initialized));

const _selectViewAtom = selectAtom(costTrendsByCostCategoryStateAtom, (state) => state.selectView);
export const useSelectView = () =>
  useSelectState(_selectViewAtom, (selectView) => createChangeSelectViewAction(selectView));

const _paymentDateToAtom = selectAtom(costTrendsByCostCategoryStateAtom, (state) => state.paymentDateTo);
export const usePaymentDateRange = () =>
  useSelectState<Date, 'next' | 'previous'>(_paymentDateToAtom, (direction) => createChangePaymentDateRange(direction));

export const usePaymentDateTo = () =>
  useSelectState(_paymentDateToAtom, (paymentDateTo) => createChangePaymentDateTo(paymentDateTo));

const _displayTimePeriodAtom = selectAtom(costTrendsByCostCategoryStateAtom, (state) => state.displayTimePeriod);
export const useDisplayTimePeriod = () =>
  useSelectState(_displayTimePeriodAtom, (displayTimePeriod) => createChangeDisplayTimePeriod(displayTimePeriod));

const _selectedRootCategoryHashIdAtom = selectAtom(
  costTrendsByCostCategoryStateAtom,
  (state) => state.selectedRootCategoryHashId
);
export const useSelectedRootCategoryHashId = () =>
  useSelectState(_selectedRootCategoryHashIdAtom, (selectedRootCategoryHashId) =>
    createChangeSelectedRootCategoryHashId(selectedRootCategoryHashId)
  );
