import React, {useCallback} from 'react';
import {Chip, Divider, useMediaQuery, styled} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {FormFieldsSection} from '@Apps/ProductDetail/TwoColumn/FormFieldsSection';
import {
  deviceTypes,
  devices,
  deviceDetails,
  rentalSection,
  leaseSection,
  makerInspectionFormSection,
} from './FormFieldDefinitions';
import {
  APPENDIX_WIDTH_BREAK_POINT,
  ChannelLabel,
  ChannelLabelTypography,
  ChannelNoTypography,
  FormFieldsSectionContainer,
  FormFieldsSections,
  FormFieldsSectionsInTablet,
  StatusLabel,
} from '@Apps/ProductDetail/styled';
import {useNavigate} from 'react-router-dom';
import {useUserPermissions} from '@modules/auth/hooks';
import {HospitalUserPermission} from '@modules/auth/consts';

const FormFieldsDivider = styled(Divider)({
  margin: '0 16px 16px',
});
const StyledErrorIcon = styled(ErrorIcon)({
  color: '#C2000A',
  width: '18px',
  height: '18px',
});

const StyledChip = styled(Chip)({
  backgroundColor: '#FFEAED',
  color: '#C7243A',
  borderRadius: '4px',
  fontSize: '14px',
  height: '28px',
  marginRight: '24px',
});

const StyledDiv = styled('div')({
  height: '28px',
});
const StyledChannelLabel = styled(ChannelLabel)({
  margin: '16px 32px',
  height: '21px',
});

type Props = {
  hospitalProduct?: HospitalProductIndex;
};

/**
 * セクション情報を管理。
 * 機種情報、機器情報、詳細情報の3つのセクションを表示する。
 * ※製品のサムネイル画像がヘッダー、セクションの上部を横断する関係で、例外的にステータス情報を含めている
 */
export const ProductInfoSections = ({hospitalProduct}: Props) => {
  const {isPermitted} = useUserPermissions();
  const isTransmitter = hospitalProduct?.isTransmitter ? true : false;
  const isPermittedChannelManagement = isPermitted([HospitalUserPermission.ChannelManagement]);
  const isWorking = hospitalProduct?.status === 'working';
  const isDisplayStatusLabel = isWorking || isTransmitter;
  const roomName = hospitalProduct?.rentHospitalRoom?.name ?? '不明';
  const channelNumberToDisplay = hospitalProduct?.channelNumber ?? '未割り当て';
  const navigate = useNavigate();

  // タブレットの様に縦使用時のディスプレイ表示
  const isTabletDisplay = useMediaQuery(`(max-width:${APPENDIX_WIDTH_BREAK_POINT})`);

  const handleChannelClick = useCallback(
    (channelNumber) => {
      const query = channelNumber ? `?channelNumber=${channelNumber}` : '';
      navigate(`/channels${query}`, {replace: true});
    },
    [navigate]
  );

  return (
    <FormFieldsSectionContainer>
      <StatusLabel visible={isDisplayStatusLabel}>
        <StyledDiv>{isWorking && <StyledChip icon={<StyledErrorIcon />} label={`貸出先：${roomName}`} />}</StyledDiv>
        {!isTabletDisplay && isTransmitter && isPermittedChannelManagement && (
          <ChannelLabel>
            <ChannelLabelTypography>チャンネル：</ChannelLabelTypography>
            <ChannelNoTypography onClick={() => handleChannelClick(hospitalProduct?.channelNumber)}>
              {channelNumberToDisplay}
            </ChannelNoTypography>
          </ChannelLabel>
        )}
      </StatusLabel>
      {isTabletDisplay && (
        <StyledChannelLabel>
          {isTransmitter && isPermittedChannelManagement && (
            <>
              <ChannelLabelTypography>チャンネル：</ChannelLabelTypography>
              <ChannelNoTypography onClick={() => handleChannelClick(hospitalProduct?.channelNumber)}>
                {channelNumberToDisplay}
              </ChannelNoTypography>
            </>
          )}
        </StyledChannelLabel>
      )}
      {isTabletDisplay ? (
        <FormFieldsSectionsInTablet>
          <FormFieldsSection sectionName={deviceTypes.sectionName} fields={deviceTypes.fields} />
          <FormFieldsDivider />
          <FormFieldsSection sectionName={devices.sectionName} fields={devices.fields} />
          <FormFieldsDivider />
          <FormFieldsSection sectionName={deviceDetails.sectionName} fields={deviceDetails.fields} />
          {hospitalProduct?.waysOfPurchase === 'rental' ? (
            <>
              <FormFieldsDivider />
              <FormFieldsSection sectionName={rentalSection.sectionName} fields={rentalSection.fields} />
            </>
          ) : hospitalProduct?.waysOfPurchase === 'lease' ? (
            <>
              <FormFieldsDivider />
              <FormFieldsSection sectionName={leaseSection.sectionName} fields={leaseSection.fields} />
            </>
          ) : (
            false
          )}
          <FormFieldsDivider />
          <FormFieldsSection
            sectionName={makerInspectionFormSection.sectionName}
            fields={makerInspectionFormSection.fields}
          />
        </FormFieldsSectionsInTablet>
      ) : (
        <FormFieldsSections>
          <FormFieldsSection sectionName={deviceTypes.sectionName} fields={deviceTypes.fields} />
          <FormFieldsDivider />
          <FormFieldsSection sectionName={devices.sectionName} fields={devices.fields} />
          <FormFieldsDivider />
          <FormFieldsSection sectionName={deviceDetails.sectionName} fields={deviceDetails.fields} />
          {hospitalProduct?.waysOfPurchase === 'rental' ? (
            <>
              <FormFieldsDivider />
              <FormFieldsSection sectionName={rentalSection.sectionName} fields={rentalSection.fields} />
            </>
          ) : hospitalProduct?.waysOfPurchase === 'lease' ? (
            <>
              <FormFieldsDivider />
              <FormFieldsSection sectionName={leaseSection.sectionName} fields={leaseSection.fields} />
            </>
          ) : (
            false
          )}
          <FormFieldsDivider />
          <FormFieldsSection
            sectionName={makerInspectionFormSection.sectionName}
            fields={makerInspectionFormSection.fields}
          />
        </FormFieldsSections>
      )}
    </FormFieldsSectionContainer>
  );
};
