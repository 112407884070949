import React from 'react';
import {UserIndex} from '@modules/hospital_users/types';
import {styled} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import dayjs from 'dayjs';
import {ArrowTooltipComponent} from '@components/atoms/ArrowTooltip';
import {UserFormatter} from '@modules/hospital_users/helpers';

type DeleteCardProps = {
  displayDate: Date;
  updateAt: Date;
  updateBy: UserIndex;
};
/**
 * 削除済みコメントカードの表示
 * @param {DeleteCardProps} DeleteCardProps
 * @param updateBy - 編集者のUserIndex
 * @param updateAt - 変更日のDate
 * @returns
 */
export const DeletedCommentCard = ({updateBy, updateAt, displayDate}: DeleteCardProps) => {
  return (
    <RootContainer>
      <Container>
        <Header>
          <ArrowTooltipComponent tooltipText={`${dayjs(updateAt).format('YYYY年M月D日 HH:mm')}にコメント削除`}>
            <div>
              <Title>コメントの削除</Title>
              実行者：{UserFormatter.getFullName(updateBy)}
            </div>
          </ArrowTooltipComponent>
          <CommentDate>
            {dayjs(displayDate).format('MM/DD HH:mm')}
          </CommentDate>
        </Header>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
});

const Container = styled('div')({
  width: '100%',
});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  justifyContent: 'space-between',
});

const Title = styled('span')({
  marginRight: '14px',
});
