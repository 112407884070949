import {useCallback, useMemo} from 'react';
import {isNullish} from '@front-libs/helpers';
import {updateInspection, useFetchInspectionsQuery} from '../inspections/api';
import {InspectionIndex} from '../inspections/types';
import {getInspectionResults} from '../inspection_results/api';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@components/molecules/Dialogs/MessageDialog';

const commonFetchInspectionsQueryParam = {
  statuses: 'available',
  perPage: 100,
};

const inspectionToOption = (inspection: InspectionIndex) => ({value: inspection, label: inspection.name});

export type ProductInspectionsOptionsType = {
  label: string;
  options: {
    value: InspectionIndex;
    label: string;
  }[];
};
export const useInspectionSelectorOptions = (
  hospitalHashId: string,
  searchName: string,
  wholeProductHashId: string | null
) => {
  const allInspectionsQuery = useFetchInspectionsQuery(
    hospitalHashId,
    useMemo(
      () => ({
        ...commonFetchInspectionsQueryParam,
        name: searchName !== '' ? searchName : undefined,
      }),
      [searchName]
    )
  );
  const productInspectionsQuery = useFetchInspectionsQuery(
    hospitalHashId,
    useMemo(
      () => ({
        ...commonFetchInspectionsQueryParam,
        name: searchName !== '' ? searchName : undefined,
        wholeProductHashId: wholeProductHashId ?? undefined,
      }),
      [searchName, wholeProductHashId]
    )
  );

  const options: ProductInspectionsOptionsType[] = useMemo(() => {
    const allInspectionOptions = {
      label: '全ての点検表',
      options: (allInspectionsQuery.data ?? []).map(inspectionToOption),
    };

    if (wholeProductHashId === null || isNullish(productInspectionsQuery.data)) {
      return [allInspectionOptions];
    }

    // productInspectionに含まれているか判定するためのマップ
    // key: InspectionHashId
    // value: true (含まれている場合), undefined (含まれていない場合)
    const productInspectionMap = productInspectionsQuery.data.reduce((m: Record<string, boolean>, i) => {
      m[i.hashId] = true;
      return m;
    }, {});

    return [
      {
        label: '機種に紐づく点検表',
        options: productInspectionsQuery.data.map(inspectionToOption),
      },
      {
        label: allInspectionOptions.label,
        // productInspectionsにリストされていないものだけ表示
        options: allInspectionOptions.options.filter((o) => productInspectionMap[o.value.hashId] !== true),
      },
    ];
  }, [allInspectionsQuery.data, productInspectionsQuery, wholeProductHashId]);

  return {
    options,
    isLoading: allInspectionsQuery.isLoading || productInspectionsQuery.isLoading,
  };
};

export const useChangeToDraft = () => {
  return useCallback(async (hospitalHashId: string, inspection: InspectionIndex) => {
    const relatedResults = await getInspectionResults(hospitalHashId, inspection.hashId, {
      inspectionHashId: inspection.hashId,
      statuses: 'uncompleted',
      perPage: 1,
    });

    if (relatedResults.totalCount > 0) {
      await dialogHandler.open(MessageDialog, {
        content: '実施途中の点検予定と紐づいている点検表は下書きに戻せません',
        positiveButtonLabel: '閉じる',
        isHiddenNegativeButton: true,
      });

      return false;
    }

    await dialogHandler.open(AlertDialog, {
      title: `点検表「${inspection.name}」を下書きに戻す`,
      content: `「${inspection.name}」の点検表を下書きに戻そうとしています`,
      positiveButtonLabel: '下書きに戻す',
      warning: false,
    });

    await updateInspection(hospitalHashId, inspection.hashId, {
      status: 'draft',
    });

    return true;
  }, []);
};
