import {Box, Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import {RequiredLabelBadge} from '@components/atoms/RequiredLabelBadge';
import {
  SelectInspectionItem,
  MultiSelectInspectionItem,
  NumericInspectionItem,
  TextInspectionItem,
  MultilineTextInspectionItem,
  DateInspectionItem,
  TimeInspectionItem,
} from '@modules/inspections/api';

export const ItemTitle: React.FC<ItemTitleProps> = (props) => {
  const {item} = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.titleTxt}>{item.name}</Typography>
      {item.required === true && <RequiredLabelBadge className={classes.badge} />}
    </Box>
  );
};
export type ItemTitleProps = {
  item:
    | SelectInspectionItem
    | MultiSelectInspectionItem
    | NumericInspectionItem
    | TextInspectionItem
    | MultilineTextInspectionItem
    | DateInspectionItem
    | TimeInspectionItem;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: '4px 0px',
  },
  titleTxt: {
    fontSize: '16px',
    paddingRight: '8px',
  },
  badge: {
    padding: '6px',
    borderRadius: '8px',
  },
}));
