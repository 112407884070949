import React from 'react';
import {Typography, Grid, styled} from '@material-ui/core';
import warningExclamation from '@assets/warning_exclamation.svg';
import {InspectionResultEvaluation, InspectionResultEvaluationMenus} from '@modules/inspection_results/enum';

type InspectionResultBadgeProps = {
  inspectionResult: InspectionResultEvaluation;
};

const AlertText: React.FC = ({children}) => (
  <>
    <img src={warningExclamation} alt="警告" />
    {children}
  </>
);

export const InspectionResultBadge: React.VFC<InspectionResultBadgeProps> = (props) => {
  const {inspectionResult} = props;

  const text = InspectionResultEvaluationMenus.find((i) => i.value === inspectionResult)?.label ?? '';

  return (
    <StyledGrid>
      {inspectionResult === 'failed' ? (
        <StyledTypographyFailed variant="inherit">
          <AlertText>{text}</AlertText>
        </StyledTypographyFailed>
      ) : (
        <StyledTypographyPassed variant="inherit">{text}</StyledTypographyPassed>
      )}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)({
  minWidth: '84px',
  display: 'flex',
});

const StyledTypographyFailed = styled(Typography)(({theme}) => {
  return {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  };
});

const StyledTypographyPassed = styled(Typography)({
  textAlign: 'center',
  width: '100%',
});
