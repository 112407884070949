import {useMemo} from 'react';
import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {
  HospitalRoomLayoutSetting,
  HospitalRoomLayoutSettingsResult,
  UpdateHospitalRoomLayoutSettingParams,
  customSequence,
} from './types';
import {getHospitalRoomLayoutSettingsKey} from '@constants/api';

export type HospitalRoomLayoutSettingParams = {
  page: number;
  perPage: number;
  isGroundFloor?: boolean;
  floorNumber?: number;
};

export type createHospitalRoomLayoutSettingParam = {
  customSequence: customSequence[];
  isGroundFloor?: boolean;
  floorNumber?: number;
};

export const fetchHospitalRoomLayoutSetting = async (
  hospitalHashId: string,
  userHashId: string,
  wardHashId: string,
  params: HospitalRoomLayoutSettingParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${userHashId}/hospital_wards/${wardHashId}/hospital_room_layout_setting`;
  const {data} = await axios.get<HospitalRoomLayoutSettingsResult>(baseUrl, {params});
  return data;
};

export const useHospitalRoomLayoutSettingQuery = (
  hospitalHashId: string,
  userHashId: string,
  wardHashId: string,
  params: HospitalRoomLayoutSettingParams = {page: 0, perPage: 100}
) => {
  const query = useQuery([getHospitalRoomLayoutSettingsKey, hospitalHashId, userHashId, wardHashId], () =>
    fetchHospitalRoomLayoutSetting(hospitalHashId, userHashId, wardHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const updateHospitalRoomLayoutSetting = async (
  hospitalHashId: string,
  userHashId: string,
  wardHashId: string,
  roomHashId: string,
  data: UpdateHospitalRoomLayoutSettingParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${userHashId}/hospital_wards/${wardHashId}/hospital_room_layout_setting/${roomHashId}`;
  return await axios.put<HospitalRoomLayoutSetting>(baseUrl, data);
};

export const createHospitalRoomLayoutSetting = async (
  hospitalHashId: string,
  userHashId: string,
  wardHashId: string,
  data: createHospitalRoomLayoutSettingParam
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/users/${userHashId}/hospital_wards/${wardHashId}/hospital_room_layout_setting`;
  return await axios.post<HospitalRoomLayoutSetting>(baseUrl, data);
};
