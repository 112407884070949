import React from 'react';
import {RentalDnDCard} from '../RentalDnDCard';
import {Droppable} from 'react-beautiful-dnd';
import {makeStyles} from '@material-ui/core/styles';
import {Divider, Typography} from '@material-ui/core';
import {ROW_SIZE, COLUMN_SIZE, SECTION_SIZE} from '../consts';
import {LayoutSequenceType} from '..';

type SectionProps = {
  section: LayoutSequenceType[];
  sectionIndex: number;
  isDnDEnabled: boolean;
  isTablet: boolean;
  itemCount: number;
};

type StyleProps = {
  itemCount: number;
  isTablet: boolean;
};

export const RentalDnDSection: React.FC<SectionProps> = ({
  section,
  sectionIndex,
  isDnDEnabled,
  isTablet,
  itemCount,
}) => {
  const classes = useStyles({itemCount, isTablet});

  return (
    <div key={`section-${sectionIndex}`} className={classes.sectionContainer}>
      <Typography className={classes.sectionTitle}>{sectionIndex + 1}ページ目</Typography>
      <Divider className={classes.devider} />
      {section.map((row, rowIndex) => (
        <Droppable droppableId={`row-${sectionIndex * COLUMN_SIZE + rowIndex}`} key={rowIndex} direction="horizontal">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className={classes.cardsContainer}>
              {row.map((item, index) => (
                <RentalDnDCard
                  key={item.name}
                  title={item.name}
                  index={sectionIndex * SECTION_SIZE + rowIndex * ROW_SIZE + index}
                  isDnDEnabled={isDnDEnabled}
                  itemCount={itemCount}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  sectionContainer: (isTablet) => ({
    marginBottom: '2rem',
    width: isTablet ? 'fit-content' : '48%',
    marginTop: '40px',
  }),
  cardsContainer: (props: StyleProps) => ({
    padding: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginBottom: '1rem',
    width: 642,
  }),
  sectionTitle: {
    color: '#172B4D',
    marginLeft: 12,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  devider: {
    background: '#172B4D',
    margin: '0px 12px',
  },
});
