import React, {useCallback, useState} from 'react';
import {Button, Collapse, Grid, ListItem, ListItemIcon, ListItemText, makeStyles, Theme} from '@material-ui/core';
import {ExpandMore, ChevronRight} from '@material-ui/icons';

type Props = {
  isReadOnly: boolean;
  sectionName: string;
  numberOfCount: number;
  onAddRelatedContent: () => void;
};

export const RelatedContentSection: React.FC<Props> = ({
  isReadOnly,
  sectionName,
  numberOfCount,
  children,
  onAddRelatedContent,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClickBtn = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onAddRelatedContent();
    },
    [onAddRelatedContent]
  );

  return (
    <div className={classes.root}>
      <ListItem
        button
        className={classes.collapseControl}
        onClick={() => {
          setOpen(!open);
        }}>
        <ListItemIcon className={classes.listIcon}>{open ? <ExpandMore /> : <ChevronRight />}</ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.listText,
          }}
          primary={
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>{`${sectionName}（${numberOfCount}）`}</Grid>
              <Grid item>
                {!isReadOnly && (
                  <Button onClick={handleClickBtn} className={classes.addButton}>
                    + 変更
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #D1D5DD',
    paddingBottom: '12px',
  },
  collapseControl: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
  },
  listIcon: {
    minWidth: 0,
    paddingRight: '8px',
  },
  listText: {
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
  },
  addButton: {
    color: theme.palette.secondary.dark,
    fontWeight: 700,
  },
}));
