import {atom} from 'jotai';
import {FetchHospitalProductWithInspectionProductParam} from '@modules/hospital_products/api';
import {SelectOptionProps} from '@components/molecules/Buttons/PopperSelectBoxButton';
import {
  DateRangeConditionValue,
  ResultType,
  TextConditionValue,
} from '@components/molecules/Drawers/FilterDrawer/types';
import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {convertDateToRFC3339, isNullish} from '@front-libs/helpers';
import {ViewInspectionType} from '@components/organisms/TypeMenu/type';
import {Order} from '@molecules/Table/props';

const LOCAL_STORAGE_KEY_PRODUCTS_LIST = 'hitotsu/start_date_page_size_status';
export const searchFilterResultsAtom = atom<ResultType[]>([]);
export const searchNameAtom = atom<string | null>(null);
export const pageAtom = atomWithReset<number>(1);
export const selectedMakerAtom = atom<SelectOptionProps[]>([]);
export const searchRootCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchSubCategoriesAtom = atom<SelectOptionProps[]>([]);
export const inspectionTypeAtom = atom<ViewInspectionType>('periodic');
export const pageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_PRODUCTS_LIST, 20);
export const orderKeyAtom = atom<Order | null>(null);

type DetailFilter = {
  name: string;
  managementId: string;
  inspectionName: string;
  periodicInspectionStartDateFrom: string;
  periodicInspectionStartDateTo: string;
};

export const inspectionStartDateVariables = atom<FetchHospitalProductWithInspectionProductParam>((get) => {
  const detailFilter = convertFilterResults(get(searchFilterResultsAtom));
  const rootCategories = get(searchRootCategoriesAtom);
  const subCategories = get(searchSubCategoriesAtom);
  const makerName = get(selectedMakerAtom);
  const searchName = get(searchNameAtom);
  const order = get(orderKeyAtom);

  const _p: FetchHospitalProductWithInspectionProductParam = {
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    name: searchName ? searchName : undefined,
    categoryHashIds:
      subCategories.length > 0
        ? subCategories.map((item) => item.value).join(',')
        : rootCategories.length > 0
          ? rootCategories.map((item) => item.value).join(',')
          : undefined,
    makerHashIds: makerName.length > 0 ? makerName.map((item) => item.value).join(',') : undefined,
    statuses: 'ready,working,uninspected,repairing',
    order: order ? `${order.direction === 'desc' ? '-' : ''}${order.fieldId}` : 'managementId',
    ...detailFilter,
  };
  return _p;
});

const convertFilterResults = (filterResults: ResultType[]): Partial<DetailFilter> => {
  const detailFilter: Partial<DetailFilter> = {};
  filterResults.forEach((item) => {
    switch (item.key) {
      case 'managementId':
        detailFilter.managementId = item.resultValue as TextConditionValue;
        break;
      case 'name':
        detailFilter.name = item.resultValue as TextConditionValue;
        break;
      case 'inspectionName':
        detailFilter.inspectionName = item.resultValue as TextConditionValue;
        break;
      case 'periodicInspectionStartDate': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.periodicInspectionStartDateFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.periodicInspectionStartDateTo = convertDateToQueryString(typedValue?.to);
        break;
      }
    }
  });

  return detailFilter;
};

const convertDateToQueryString = (date: Date | undefined): string | undefined => {
  return !isNullish(date) ? convertDateToRFC3339(date) : undefined;
};
