import {AccountCircle, InsertInvitation} from '@material-ui/icons';
import {RepairIndex} from '@modules/repairs/types';
import React from 'react';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {UserIndex} from '@modules/hospital_users/types';
import dayjs from 'dayjs';
import {CategoryFormatter} from '@modules/categories/helpers';
import {Grid, makeStyles} from '@material-ui/core';
import {
  getDealtCategoryByValue,
  getRootCauseCategoryByValue,
  getSymptomDetailCategoryLabel,
} from '@modules/repairs/constants';
import {StrUtil} from '@front-libs/helpers';

const DateProperty: React.FC = ({children}) => {
  const classes = useStyles();
  return (
    <Grid item>
      <InsertInvitation fontSize={'small'} className={classes.icon} />
      {children}
    </Grid>
  );
};

const UserProperty: React.FC = ({children}) => {
  const classes = useStyles();
  return (
    <Grid item>
      <AccountCircle fontSize={'small'} className={classes.icon} />
      {children}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  icon: {
    verticalAlign: 'middle',
    paddingRight: '12px',
    color: '#7A869A',
  },
}));

export const cardProperties: {
  [key: string]: {render: (data: RepairIndex, users: UserIndex[]) => React.ReactNode};
} = {
  managementId: {render: (data) => `管理番号：${data.faultHospitalProduct.managementId}`},
  makerName: {render: (data) => `メーカー：${data.faultHospitalProduct.maker.name}`},
  requestForRepairAt: {
    render: (data) => (
      <DateProperty>
        修理受付日時：{data.requestForRepairAt ? dayjs(data.requestForRepairAt).format('MM/DD') : ''}
      </DateProperty>
    ),
  },
  personInCharge: {
    render: (data, users) => {
      if (!users) return '';

      const tmpPic = users.find((i) => i.hashId === data.personInChargeHashId);
      const pic = tmpPic ? UserFormatter.getFullName(tmpPic) : '未割り当て';

      return <UserProperty>担当者：{pic}</UserProperty>;
    },
  },
  rootCategory: {
    render: (data) => `大分類：${CategoryFormatter.getRootCategory(data.faultHospitalProduct.categories)?.name ?? ''}`,
  },
  narrowCategory: {
    render: (data) =>
      `小分類：${CategoryFormatter.getNarrowCategory(data.faultHospitalProduct.categories)?.name ?? ''}`,
  },
  requestUserName: {render: (data) => `依頼者名：${data.requestUserName ?? ''}`},
  fixedAt: {
    render: (data) => <DateProperty>完了日：{data.fixedAt ? dayjs(data.fixedAt).format('MM/DD') : ''}</DateProperty>,
  },
  appliedAtToSuppliesDepartment: {
    render: (data) => (
      <DateProperty>
        事務への申請日：
        {data.appliedAtToSuppliesDepartment ? dayjs(data.appliedAtToSuppliesDepartment).format('MM/DD') : ''}
      </DateProperty>
    ),
  },
  symptomDetailCategory: {
    render: (data) => `事象詳細区分：${getSymptomDetailCategoryLabel(data.symptomDetailCategory ?? undefined)}`,
  },
  rootCauseCategory: {
    render: (data) => `原因区分：${getRootCauseCategoryByValue(data.rootCauseCategory ?? undefined)}`,
  },
  dealtCategory: {render: (data) => `処理区分：${getDealtCategoryByValue(data.dealtCategory ?? '')}`},
  costOfFix: {render: (data) => `修理金額：${data.costOfFix ? StrUtil.addComma(data.costOfFix) + '円' : ''}`},
  createdBy: {
    render: (data) => {
      const pic = data.createdBy ? UserFormatter.getFullName(data.createdBy) : '未割り当て';

      return <UserProperty>カード作成者:{pic}</UserProperty>;
    },
  },
  makerPicName: {render: (data) => `メーカー担当者名：${data.makerPicName ?? ''}`},
  makerContactTel: {render: (data) => `メーカー担当者 電話番号：${data.makerContactTel ?? ''}`},
  makerContactEmail: {render: (data) => `メーカー担当者 メールアドレス：${data.makerContactEmail ?? ''}`},
  makerContactAt: {
    render: (data) => (
      <DateProperty>
        メーカーへの連絡日：{data.makerContactAt ? dayjs(data.makerContactAt).format('MM/DD') : ''}
      </DateProperty>
    ),
  },
};
