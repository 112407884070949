import React, {useMemo} from 'react';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {Box, Divider, makeStyles} from '@material-ui/core';
import {InspectionItem} from '@modules/inspections/api';
import {Item} from './item';
import {Section} from './section';
import _ from 'lodash';

export const ResultDetailContent: React.FC<ResultDetailContentProps> = (props) => {
  const {inspectionResult, inspection, inspectionTable} = props;
  const classes = useStyles();

  const idToResultItem = useMemo(() => _.keyBy(inspectionResult.items, 'id'), [inspectionResult.items]);
  return (
    <Box className={classes.itemContainer}>
      {inspectionTable.items.map((item, i) => {
        if (item.type === 'section') {
          return (
            <Section key={i} name={item.name}>
              {item.items.map((sectionItem, j) => (
                <>
                  <Item
                    key={j}
                    item={sectionItem as InspectionItem & {id: string}}
                    resultItem={idToResultItem[sectionItem.id + '']}
                  />
                  <Divider className={classes.diver} />
                </>
              ))}
            </Section>
          );
        }
      })}
    </Box>
  );
};

type ResultDetailContentProps = {
  inspectionResult: InspectionResultIndex;
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: 'white',
    height: '120px',
    width: '100%',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  diver: {
    marginBottom: '8px',
  },
}));
