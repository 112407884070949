import {Box, Typography, styled} from '@material-ui/core';
import React from 'react';

export const ItemDescription = ({description}: ItemDescriptionProps) => {
  return (
    <RootBox>
      <DescriptionTxt>{description}</DescriptionTxt>
    </RootBox>
  );
};

type ItemDescriptionProps = {
  description: string;
};

const RootBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const DescriptionTxt = styled(Typography)({
  fontSize: '14px',
  color: '#65676B',
  margin: '0px',
})
