import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: 10,
    width: '100%',
  },
  tableCaption: {
    textAlign: 'center',
    marginBottom: 16,
    fontSize: 24,
  },
  tableHeader: {
    backgroundColor: '#FFF',
  },
  tableBody: {
    height: 56,
  },
}));

type Props = {
  lender: string;
};

export const LenderTable: React.VFC<Props> = ({lender}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.tableHeader}>
              返却者
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableBody}>
            <TableCell>{lender}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
