import React from 'react';
import {createStyles, makeStyles, Theme, Divider} from '@material-ui/core';
import {HeaderTop} from './HeaderTop';
import {HeaderBottom} from './HeaderBottom';
import {WithFormikProps, withFormik} from '@front-libs/core';
import {FormValue} from '@modules/hospital_ward_layout_setting/types';

type HeaderProps = {
  handleClickCancel: () => void;
};

const _Header: React.FC<WithFormikProps<FormValue, FormValue, HeaderProps>> = (props) => {
  const {handleClickCancel} = props;
  const classes = useStyles();
  return (
    <>
      <HeaderTop handleClickCancel={handleClickCancel} />
      <Divider className={classes.divider} />
      <HeaderBottom />
    </>
  );
};

export const Header = withFormik<FormValue, FormValue, HeaderProps>({
  displayName: 'Header',
  getNames: () => ['HashId', 'Setting'],
})(_Header);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      background: '#C6CBD4',
      margin: '0px 12px',
    },
  })
);
