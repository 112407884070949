import {useCallback, useMemo, useState} from 'react';

import ExcelJs from 'exceljs';
import {openSnackBar} from '@molecules/SnackBar';
import {isNullish} from '@front-libs/helpers';
import {yup} from '@front-libs/core';
import {createHospitalWard} from '@modules/hospital_wards/api';
import {bulkCreateHospitalRooms} from '@modules/hospital_places/api';

const objectSchema = yup.object({
  wardName: yup.string().required(),
  isGroundFloor: yup.boolean().required(),
  floorNumber: yup.number().min(0).required(),
  roomName: yup.string().required(),
  showRentalPlace: yup.boolean().required(),
});

const validationSchema = yup.array(objectSchema).required();

export type HospitalRoomTable = {
  wardName: string;
  isGroundFloor: boolean;
  floorNumber: number;
  roomName: string;
  showRentalPlace: boolean;
};

const getCellValue = (cell: ExcelJs.Cell, fileType: 'xlsx' | 'csv') => {
  if (fileType === 'csv') return cell.value?.toString().replace(/\s+/g, '');

  switch (cell.type) {
    case ExcelJs.ValueType.Number:
    case ExcelJs.ValueType.String:
    case ExcelJs.ValueType.Hyperlink:
    case ExcelJs.ValueType.Boolean:
      return cell.value?.toString().trim().replace(/\s+/g, '');
    case ExcelJs.ValueType.Date:
      return cell.value?.toLocaleString().trim().replace(/\s+/g, '');
    case ExcelJs.ValueType.Formula: {
      const formulaResult = cell.result as ExcelJs.CellFormulaValue['result'];
      if (isNullish(formulaResult)) return '';
      if (typeof formulaResult !== 'object') return formulaResult;
      if (formulaResult instanceof Date) {
        return formulaResult.toLocaleString().trim().replace(/\s+/g, '');
      } else {
        return formulaResult.error as unknown as string;
      }
    }
    case ExcelJs.ValueType.RichText:
      return (cell.value as ExcelJs.CellRichTextValue).richText
        .map((item) => item.text.trim().replace(/\s+/g, ''))
        .join('');
    default:
      return undefined;
  }
};

const validateAndBind = (data: {
  title?: string;
  dataSheet: ExcelJs.Worksheet;
}): {isValid: boolean; hospitalRoomTableData: HospitalRoomTable[]} => {
  const {title, dataSheet} = data;

  const hospitalRoomTableData: HospitalRoomTable[] = [];

  // 意図せずvalueを読み込んでしまうGからU列までを一時的に削除
  dataSheet.spliceColumns(7, 15);

  dataSheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      hospitalRoomTableData.push({
        wardName: getCellValue(row.getCell(1), 'xlsx')?.toString() ?? '',
        isGroundFloor: getCellValue(row.getCell(2), 'xlsx')?.toString() === '地上',
        floorNumber: Number(getCellValue(row.getCell(3), 'xlsx') ?? 0),
        roomName: getCellValue(row.getCell(5), 'xlsx')?.toString() ?? '',
        showRentalPlace: isNullish(getCellValue(row.getCell(6), 'xlsx'))
          ? true
          : getCellValue(row.getCell(6), 'xlsx')?.toString() === 'する',
      });
    }
  });

  const isValid = validationSchema.isValidSync(hospitalRoomTableData) && title === '建物・場所登録の流れ・例';

  return {isValid: isValid, hospitalRoomTableData: hospitalRoomTableData};
};

export const useImportHospitalRoomsExcel = (hospitalHashId: string) => {
  const wb = useMemo(() => new ExcelJs.Workbook(), []);
  const reader = useMemo(() => new FileReader(), []);

  const [hospitalRoomData, setHospitalRoomData] = useState<HospitalRoomTable[]>([]);
  const [isValidFile, setIsValidFile] = useState(false);

  const uploadFile = useCallback(
    (acceptedFile: File) => {
      reader.readAsArrayBuffer(acceptedFile);
      reader.onload = async () => {
        const buffer = reader.result;

        if (isNullish(buffer)) return;
        openSnackBar('アップロードされたファイルを読み込んでいます', 'left', 'bottom', 'info');

        const workbook = await wb.xlsx.load(buffer as ArrayBuffer);
        const exampleSheet = workbook.getWorksheet('建物・場所登録の流れ・例');
        const dataSheet = workbook.getWorksheet('建物・場所フォーム');
        if (isNullish(exampleSheet) || isNullish(dataSheet)) return;

        const {isValid, hospitalRoomTableData} = validateAndBind({
          title: exampleSheet.getCell(1, 2).value?.toString() ?? '',
          dataSheet,
        });

        if (!isValid) {
          openSnackBar('アップロードされたファイルの形式が異なります。', 'left', 'bottom', 'error');
          return;
        }

        setHospitalRoomData(hospitalRoomTableData);
        setIsValidFile(true);
        openSnackBar('ファイルの読み込みが完了しました。登録内容を確認してください。');
      };
    },
    [reader, wb]
  );

  const uniqueHospitalWards = useMemo(() => {
    const uniqueWards = new Set(hospitalRoomData.map((data) => data.wardName));
    return Array.from(uniqueWards);
  }, [hospitalRoomData]);

  const submitHospitalRoomData = useCallback(async () => {
    const wardData = await Promise.all(
      uniqueHospitalWards.map((item) => {
        return createHospitalWard(hospitalHashId, {name: item});
      })
    );

    const wardList = wardData.map((item) => ({hashId: item.data.hashId, name: item.data.name}));

    await bulkCreateHospitalRooms(
      hospitalHashId,
      hospitalRoomData.map((item) => {
        const ward = wardList.find((w) => w.name === item.wardName);
        return {
          name: item.roomName,
          hospitalWardHashId: ward?.hashId,
          floorNumber: item.floorNumber,
          isGroundFloor: item.isGroundFloor,
          showRentalPlace: item.showRentalPlace,
        };
      })
    );
  }, [hospitalHashId, hospitalRoomData, uniqueHospitalWards]);

  return {uploadFile, isValidFile, hospitalRoomData, submitHospitalRoomData} as const;
};
