import {axios} from '@front-libs/core';
import {useQuery, UseQueryOptions} from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GetRentalRatioOptions<T> = Omit<UseQueryOptions<T, unknown, T, any[]>, 'queryKey' | 'queryFn'>;

const baseUrl = (hospitalHashId: string) =>
  `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/rental_ratio`;

export type DailyRentalRatio = {
  numberOfDevice: number;
  ratio: number;
};

export type DailyRentalRatioResult = {
  data: DailyRentalRatio[];
};

export const getDailyRentalRatio = async (hospitalHashId: string, date: string, categoryHashId: string) => {
  const {data} = await axios.get<DailyRentalRatioResult>(`${baseUrl(hospitalHashId)}/daily`, {
    params: {
      date,
      categoryHashId,
    },
  });
  return data;
};

export const useGetDailyRentalRatioQuery = (
  hospitalHashId: string,
  date: string,
  categoryHashId: string,
  options?: GetRentalRatioOptions<DailyRentalRatioResult>
) => {
  return useQuery(
    ['getDailyRentalRatio', hospitalHashId, date, categoryHashId, hospitalHashId],
    () => getDailyRentalRatio(hospitalHashId, date, categoryHashId),
    options
  );
};

export type RentalRatio = {
  numberOfDevice: number;
  maxRatio: number;
  averageRatio: number;
};

export type RentalRatioResult = {
  data: RentalRatio[];
};

export const getWeeklyRentalRatio = async (hospitalHashId: string, startDate: string, categoryHashId: string) => {
  const {data} = await axios.get<RentalRatioResult>(`${baseUrl(hospitalHashId)}/weekly`, {
    params: {
      startDate,
      categoryHashId,
    },
  });
  return data;
};

export const useGetWeeklyRentalRatioQuery = (
  hospitalHashId: string,
  startDate: string,
  categoryHashId: string,
  options?: GetRentalRatioOptions<RentalRatioResult>
) => {
  return useQuery(
    ['getWeeklyRentalRatio', hospitalHashId, startDate, categoryHashId, hospitalHashId],
    () => getWeeklyRentalRatio(hospitalHashId, startDate, categoryHashId),
    options
  );
};

export const getMonthlyRentalRatio = async (
  hospitalHashId: string,
  year: number,
  month: number,
  categoryHashId: string
) => {
  const {data} = await axios.get<RentalRatioResult>(`${baseUrl(hospitalHashId)}/monthly`, {
    params: {
      year,
      month,
      categoryHashId,
    },
  });
  return data;
};

export const useGetMonthlyRentalRatioQuery = (
  hospitalHashId: string,
  year: number,
  month: number,
  categoryHashId: string,
  options?: GetRentalRatioOptions<RentalRatioResult>
) => {
  return useQuery(
    ['getMonthlyRentalRatio', hospitalHashId, year, month, categoryHashId, hospitalHashId],
    () => getMonthlyRentalRatio(hospitalHashId, year, month, categoryHashId),
    options
  );
};

export const getYearlyRentalRatio = async (hospitalHashId: string, year: number, categoryHashId: string) => {
  const {data} = await axios.get<RentalRatioResult>(`${baseUrl(hospitalHashId)}/yearly`, {
    params: {
      year,
      categoryHashId,
    },
  });
  return data;
};

export const useGetYearlyRentalRatioQuery = (
  hospitalHashId: string,
  year: number,
  categoryHashId: string,
  options?: GetRentalRatioOptions<RentalRatioResult>
) => {
  return useQuery(
    ['getYearlyRentalRatio', hospitalHashId, year, categoryHashId, hospitalHashId],
    () => getYearlyRentalRatio(hospitalHashId, year, categoryHashId),
    options
  );
};
