import {useCallback, useMemo} from 'react';
import {useMyInfo} from './useMyInfo';
import {useFetchHospitalUsers} from '../api';

export const useHospitalUsers = () => {
  const {myInfo} = useMyInfo();
  const hospitalUsersQuery = useFetchHospitalUsers(myInfo?.hospitalHashId);
  const hospitalUsers = hospitalUsersQuery?.data ?? [];
  const hospitalUsersMap = useMemo(
    () => Object.fromEntries(hospitalUsersQuery.data.map((user) => [user.hashId, user])),
    [hospitalUsersQuery]
  );
  const getHospitalUser = useCallback(
    (hashId: string) => {
      return hospitalUsersMap[hashId];
    },
    [hospitalUsersMap]
  );

  return {
    isLoading: hospitalUsersQuery.isLoading,
    hospitalUsers,
    getHospitalUser,
  };
};
