import {theme} from '@atoms/theme';

const productStatusType = [
  {
    label: '待機中',
    value: 'ready',
    color: '#3C9E58',
    searchAlias: ['たいきちゅう'],
  },
  {
    label: '貸出中',
    value: 'working',
    color: '#C7243A',
    searchAlias: ['かしだしちゅう'],
  },
  {
    label: '点検待ち',
    value: 'uninspected',
    color: '#826149',
    searchAlias: ['てんけんまち'],
  },
  {
    label: '修理中',
    value: 'repairing',
    color: theme.palette.primary.dark,
    searchAlias: ['しゅうりちゅう'],
  },
  {
    label: '廃棄済み',
    value: 'disabled',
    color: '#727272',
    searchAlias: ['はいきずみ'],
  },
];

// NOTE:未使用
/* export function getProductStatusByValue(value: string): string {
  const option = productStatusType.find((item) => item.value === value);
  return option ? option.label : '';
} */

export const productStatusOptions = productStatusType.map((item) => ({
  value: item.value,
  label: item.label,
  searchAlias: item.searchAlias,
}));

export const productStatusOptionsWODisabled = productStatusOptions.filter((item) => item.value !== 'disabled');

export const inspectionStatusType = {
  available: {
    label: '利用中',
    color: '#3C9E58',
  },
  draft: {
    label: '下書き',
    color: '#727272',
  },
};
