import React, {useState, useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import {useFormikContext, Formik, Form} from 'formik';
import Selector from '@molecules/Formik/fields/Selector';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import {InspectionType, InspectionTypeOptions, InspectionTypes} from '@modules/inspections/enum';
import {RequiredLabel} from '@molecules/FormRequiredLabel';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '24px 32px 0px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  closeButton: {
    padding: '0px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    padding: '0px 32px 0px',
    '& > div:not(:first-child)': {
      marginTop: '24px',
    },
  },
  formHelperText: {
    fontSize: 14,
    color: theme.palette.grey[600],
    marginLeft: '36px',
  },
  actions: {
    marginTop: '24px',
    padding: '0px 32px 32px',
  },
}));

type NewInspectionFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

const NewInspectionForm: React.VFC<NewInspectionFormProps> = (props) => {
  const {open, onClose} = props;
  const classes = useStyles();
  const {submitForm} = useFormikContext();

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs" aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.title} disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h2">{`作成する点検表のタイプを選択`}</Typography>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid item>
          <RequiredLabel>点検タイプ</RequiredLabel>
          <Selector name="type" size="small" options={InspectionTypeOptions} />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          作成
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type NewInspectionDialogProps = DialogProps;

export type NewInspectionDialogResult = {
  type: InspectionType;
};

type FormValues = {
  type?: InspectionType;
};

/**
 * 作成する点検表のタイプを選択 ダイアログ
 * @param {NewInspectionDialogProps} props - NewInspectionDialogProps
 * @returns
 */
export const NewInspectionDialog: React.FC<NewInspectionDialogProps> = (props) => {
  const {open} = props;

  const [validationSchema] = useState(() =>
    yup.object({
      type: yup.mixed<InspectionType>().oneOf(InspectionTypes).required(),
    })
  );

  const handleSubmit = useCallback(
    async (res: FormValues) => {
      await props.actions.resolve(res as NewInspectionDialogResult);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={validationSchema} isInitialValid={false}>
      <Form>
        <NewInspectionForm open={open ?? false} onClose={handleClose} />
      </Form>
    </Formik>
  );
};
