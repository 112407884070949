import React from 'react';
import {Typography, styled} from '@material-ui/core';
import {UserIndex} from '@modules/hospital_users/types';
import dayjs from 'dayjs';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {StrUtil} from '@front-libs/helpers';
import {Build} from '@material-ui/icons';


export type RepairCommentCardProps = {
  faultRepairHashId: string;
  displayDate: Date;
  createdUser?: UserIndex;
  comment: string;
};

export const RepairCommentCard = ({faultRepairHashId, displayDate, createdUser, comment}: RepairCommentCardProps) => {
  return (
    <RootContainer>
      <IconContainer>
        <Build style={{height: '22px'}} />
      </IconContainer>
      <CommentContentContainer>
        <CommentContentHeader>
          <Title>
            <Link href={`/repairs/${faultRepairHashId}`} target="_blank" rel="noreferrer">
              修理情報
            </Link>
            のコメント
          </Title>
          <CommentDate>{dayjs(displayDate).format('MM/DD HH:mm')}</CommentDate>
        </CommentContentHeader>
        <ContentTypography>
          {StrUtil.nl2br(comment)}
        </ContentTypography>
        <ContentTypography>
          投稿者：{createdUser ? `${UserFormatter.getFullName(createdUser)}` : '不明なユーザー'}
        </ContentTypography>
      </CommentContentContainer>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});

const CommentContentContainer = styled('div')({
  marginLeft: '8px',
  width: '100%',
});

const CommentContentHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '14px',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
  lineHeight: '32px',
});

const Link = styled('a')({
  color: '#0052CC',
  textDecoration: 'none',
});

const ContentTypography = styled(Typography)({
  fontSize: '14px',
  color: '#172B4D',
});
