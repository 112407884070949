import React, {useCallback} from 'react';
import {makeStyles, Drawer, Paper, Theme, Box} from '@material-ui/core';
import {TrainingScheduleProductsListContainer} from './TrainingScheduleProductsListContainer';
import {drawerAtom} from '../state';
import {useAtomValue, useSetAtom} from 'jotai';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import {TrainingSchedule} from '@modules/training_schedules/types';

type Props = {
  trainingSchedule: TrainingSchedule | null;
  onChangeProducts: VoidFunction;
};

export const RightSideNav: React.VFC<Props> = ({trainingSchedule, onChangeProducts}) => {
  const classes = useStyles();
  const setDrawerOpen = useSetAtom(drawerAtom);
  const drawerState = useAtomValue(drawerAtom);

  const handleOnClick = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={drawerState}
      PaperProps={{className: classes.drawerPaper, elevation: 4}}>
      <Paper className={classes.root}>
        <Box className={classes.closeBtnContainer}>
          <VerticalSplitIcon fontSize="large" onClick={handleOnClick} />
        </Box>
        <TrainingScheduleProductsListContainer
          trainingSchedule={trainingSchedule}
          onChangeProducts={onChangeProducts}
        />
      </Paper>
    </Drawer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 82px - 56px)',
    paddingTop: '56px',
    paddingBottom: '82px',
    overflowY: 'auto',
    [theme.breakpoints.up('laptop')]: {
      height: 'calc(100% - 82px)',
    },
  },
  drawerPaper: {
    zIndex: 1,
    width: '356px',
  },
  closeBtnContainer: {
    padding: '24px 16px 0px',
    [theme.breakpoints.up('laptop')]: {
      display: 'none',
    },
  },
  productsList: {},
}));
