import React, {useCallback, useMemo, useState} from 'react';
import {User} from '@auth0/auth0-react';
import {
  makeStyles,
  Avatar,
  Grid,
  Button,
  Paper,
  ClickAwayListener,
  Popper,
  MenuList,
  MenuItem,
  Divider,
  Typography,
} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import {PersonAddOutlined} from '@material-ui/icons';
import {useAuth0MultiSignInContext} from '@modules/auth/context';
import {useNavigate} from 'react-router-dom';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {isNullish} from '@front-libs/helpers';

const useUserMenuStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 100000,
    maxHeight: 360,
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: `1px solid ${theme.palette.grey.A100}`,
  },
  menuItemHovered: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  avatarBtn: {
    padding: '0px 0px 0px 8px',
    minWidth: 0,
    [theme.breakpoints.up(1025)]: {
      border: '1px solid #919BAB',
      padding: '3px 16px',
    },
  },
}));

type Prop = {
  smallViewport: boolean;
};

export const UserSelector: React.VFC<Prop> = ({smallViewport}) => {
  const {currentUser, users, signIn, switchUser, signOut} = useAuth0MultiSignInContext();
  const {myInfo} = useMyInfo();
  const classes = useUserMenuStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const selectableUsers = useMemo(() => users.filter((i) => i.email !== myInfo?.email), [myInfo, users]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const toggleMenu = () => setOpen((_open) => !_open);

  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    toggleMenu();
  };

  const handleClose = (e: React.MouseEvent<Document, MouseEvent>): void => {
    e.stopPropagation();
    if (!open) return;
    setOpen(false);
  };

  const handleSelectUser = (e: React.MouseEvent<HTMLLIElement>, user: User): void => {
    e.stopPropagation();
    setOpen(false);
    setAnchorEl(null);
    !isNullish(window.zE) && window.zE('messenger', 'logoutUser');

    // FIXME: チラつくので暫定的に遅延追加
    setTimeout(() => {
      switchUser(user?.sub + '');
    }, 250);
  };

  const handleClickNewSignIn = (e: React.MouseEvent<HTMLLIElement>): void => {
    signIn();
    !isNullish(window.zE) && window.zE('messenger', 'logoutUser');
  };

  const handleClickLogout = (e: React.MouseEvent<HTMLLIElement>): void => {
    e.stopPropagation();
    signOut();
    !isNullish(window.zE) && window.zE('messenger', 'logoutUser');
  };

  const handleClickAccountSetting = useCallback(() => {
    setOpen(false);
    navigate('/account/profile');
  }, [navigate]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div id="tutorial-account-setting-nav" className={classes.container}>
        <Button onClick={handleClickButton} className={classes.avatarBtn} data-testid="user-selector-button">
          <Avatar src={currentUser?.picture} />
          {!smallViewport && (
            <Typography variant="body1" style={{marginLeft: '16px', color: '#172B4D', fontWeight: 'bold'}}>
              {currentUser?.nickname}
            </Typography>
          )}
        </Button>
        <Popper className={classes.popper} open={open} anchorEl={anchorEl} transition placement="bottom">
          {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} style={{transformOrigin: placement}}>
              <Paper
                id="menu-list-grow"
                elevation={2}
                className={classes.paper}
                data-testid="user-selector-menu-account-setting">
                <MenuList style={{padding: '0px'}} onClick={handleClickAccountSetting}>
                  <MenuItem style={{padding: '24px 40px'}}>
                    <Typography variant="body2">アカウント設定</Typography>
                  </MenuItem>
                  <Divider />
                  {selectableUsers.map((u, idx) => {
                    const name = u.nickname;
                    return (
                      <MenuItem
                        style={{padding: '24px 40px'}}
                        onClick={(e) => handleSelectUser(e, u)}
                        data-testid={`user-selector-menu-switch-user-${idx}`}>
                        <Grid container>
                          <Grid
                            item
                            style={{
                              width: '40px',
                              height: '40px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Avatar src={u?.picture} />
                          </Grid>
                          <Grid item style={{marginLeft: '24px'}}>
                            <Typography
                              variant="body2"
                              style={{color: '#172B4D', fontWeight: 'bold', lineHeight: '40px'}}>
                              {name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    );
                  })}
                  <MenuItem
                    style={{padding: '24px 40px'}}
                    onClick={handleClickNewSignIn}
                    data-testid="user-selector-menu-other-account">
                    <Grid container>
                      <Grid
                        item
                        style={{
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <PersonAddOutlined style={{fontSize: '28px'}} />
                      </Grid>
                      <Grid item style={{marginLeft: '24px'}}>
                        <Typography variant="body2" style={{color: '#65676B', fontWeight: 'bold', lineHeight: '40px'}}>
                          別のアカウントを追加
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    style={{padding: '24px 40px'}}
                    onClick={handleClickLogout}
                    data-testid="user-selector-menu-logout">
                    <Typography variant="body2">ログアウト</Typography>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
