import React, {Suspense, useState, useCallback, useMemo} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {useInspectionSelectorOptions} from '@modules/inspection/hooks';
import {InspectionType} from '@modules/inspections/enum';
import {RFC3339Format, convertDateToJapaneseDate, isNullish} from '@front-libs/helpers';
import {StartInspectionForm} from './StartInspectionForm';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useJointingPostUseInspectionSettings} from '@modules/hospital_settings/hooks';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {StartInspectionFormValue} from './StartInspectionFormBody';
import {useFetchInspectionResultsQuery} from '@modules/inspection_results/api';
import {FAKE_INSPECTION_ID, useInspectionResult} from '@Apps/InspectionResult/pc/common/hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {useInitialized} from '@front-libs/core';

dayjs.extend(utc);

const formatPeriodicInspectionResultLabel = (r: InspectionResultIndex) => {
  if (r.inspection) {
    return `「${r.inspection.name}」 定期点検を代わりに実施する`;
  }

  if (r.scheduledTime) {
    return `${convertDateToJapaneseDate(new Date(r.scheduledTime))} 実施予定の定期点検を代わりに実施する`;
  }

  return '定期点検を代わりに実施する';
};

const formatPostUseInspectionResultLabel = (r: InspectionResultIndex) => {
  if (r.inspection) {
    return `「${r.inspection.name}」 使用後点検`;
  }

  return `${r.hospitalProduct.managementId} の使用後点検`;
};

export type StartInspectionDialogProps = DialogProps & {
  hospitalHashId: string;
  wholeProductHashId: string;
  hospitalProductHashId: string;
  inspectionResultHashId: string;
  defaultInspectorHashId?: string;
  defaultInspectionHashId?: string;
  inspectionType?: InspectionType;
  showsSelectInspection?: boolean;
};

export type StartInspectionDialogResult = {
  inspectionResult: string;
  inspection: string;
  inspector: string;
};

const StartInspectionDialogInner: React.FC<StartInspectionDialogProps> = (props) => {
  const {
    open,
    hospitalHashId,
    wholeProductHashId,
    hospitalProductHashId,
    inspectionResultHashId,
    defaultInspectorHashId,
    inspectionType,
    showsSelectInspection,
    actions,
  } = props;

  const {myInfo} = useMyInfo();
  const [now] = useState(dayjs());

  const {data: inspectionResult, isLoading: isInspectionResultLoading} = useInspectionResult(
    hospitalHashId,
    props.defaultInspectionHashId ?? FAKE_INSPECTION_ID,
    inspectionResultHashId
  );

  // 使用後点検の場合のみ、同じ製品の定期点検が予定されているか取得する
  const jointingPostUseInspectionSettings = useJointingPostUseInspectionSettings(myInfo.hospitalHashId);
  const enabledInspectionResultSelector =
    inspectionType === 'post_use' &&
    jointingPostUseInspectionSettings.allowed === true &&
    (jointingPostUseInspectionSettings.period ?? 0) > 0;

  const {data: periodicInspections, isLoading: isPeriodicInspectionResultsLoading} = useFetchInspectionResultsQuery(
    hospitalHashId,
    props.defaultInspectionHashId ?? FAKE_INSPECTION_ID,
    {
      // 同一製品のn日以内の未完了定期点検を取得
      types: 'periodic',
      hospitalProductHashId: hospitalProductHashId,
      statuses: 'unplanned,uncompleted',
      order: 'scheduledTime',
      scheduledAtFrom: now.startOf('date').format(RFC3339Format),
      scheduledAtTo: now
        // N日以内の場合は今日〜(今日+N-1)日の終わりまで
        // (今日だけの場合はN=1)
        .add((jointingPostUseInspectionSettings.period ?? 1) - 1, 'day')
        .endOf('day')
        .format(RFC3339Format),
    },
    {
      enabled: enabledInspectionResultSelector,
    }
  );

  const showsInspectionResultSelector = enabledInspectionResultSelector && periodicInspections.length > 0;

  const inspectionResultOptions = useMemo<
    Array<{label: string; value: string; defaultInspectionHashId?: string}> | undefined
  >(() => {
    if (!showsInspectionResultSelector) {
      return undefined;
    }

    const periodicInspectionResultsData = showsInspectionResultSelector
      ? periodicInspections
          .filter((x): x is InspectionResultIndex => Boolean(x))
          .map((e) => ({
            label: formatPeriodicInspectionResultLabel(e),
            value: e.hashId,
            defaultInspectionHashId: e?.inspectionHashId,
          }))
      : [];

    return inspectionResult
      ? [
          {
            label: formatPostUseInspectionResultLabel(inspectionResult),
            value: inspectionResult.hashId,
            defaultInspectionHashId: inspectionResult?.inspectionHashId,
          },
          ...periodicInspectionResultsData,
        ]
      : periodicInspectionResultsData;
  }, [showsInspectionResultSelector, periodicInspections, inspectionResult]);

  const handleSubmit = useCallback(
    async (data: StartInspectionFormValue) => {
      // eslint-disable-next-line no-shadow
      const {inspectionResult, inspection, inspector} = data;

      actions.resolve({
        inspectionResult: inspectionResult,
        inspection: inspection,
        inspector: inspector,
      });
    },
    [actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      actions.reject();
    },
    [actions]
  );

  const [searchInspectionName, setSearchInspectionName] = useState('');
  const handleSearchInspection = useCallback((name: string) => {
    setSearchInspectionName(name);
  }, []);

  const {options: inspectionOptions, isLoading: isInspectionsLoading} = useInspectionSelectorOptions(
    hospitalHashId,
    searchInspectionName,
    wholeProductHashId
  );

  const defaultInspectionHashId = useMemo(() => {
    if (props.defaultInspectionHashId) {
      return props.defaultInspectionHashId;
    }

    if (inspectionOptions.length >= 2 && inspectionOptions[0].options.length > 0) {
      const productInspections = inspectionOptions[0].options;
      const preferInspection = productInspections.find((i) => i.value.type === inspectionType);

      if (preferInspection !== undefined) {
        return preferInspection.value.hashId;
      }
    }

    return undefined;
  }, [inspectionType, inspectionOptions, props.defaultInspectionHashId]);

  const {hospitalUsers, isLoading: isUsersLoading} = useHospitalUsers();
  const userOptions = useMemo(
    () => UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true}),
    [hospitalUsers]
  );
  const defaultInspector = defaultInspectorHashId ?? myInfo.hashId;

  // NOTE:undefinedが代入されることがあり、ConsoleErrorが出るので暫定対応
  const isOpen = isNullish(open) ? false : open;

  // 2回目以降のデータ取得ではnullを返さない
  const isInitialized = useInitialized(
    !isUsersLoading && !isInspectionsLoading && !isInspectionResultLoading && !isPeriodicInspectionResultsLoading
  );
  if (!isInitialized) {
    return null;
  }

  return (
    <StartInspectionForm
      open={isOpen}
      // inspection result selector
      showsInspectionResultSelector={showsInspectionResultSelector}
      inspectionResultOptions={inspectionResultOptions}
      defaultInspectionResultHashId={inspectionResultHashId}
      // inspection selector
      showsInspectionSelector={showsSelectInspection ?? false}
      inspectionOptions={inspectionOptions}
      defaultInspectionHashId={defaultInspectionHashId}
      // inspector selector
      userOptions={userOptions}
      defaultInspectorHashId={defaultInspector}
      // callbacks
      onSearchInspection={handleSearchInspection}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export const StartInspectionDialog = (props: StartInspectionDialogProps) => (
  <Suspense fallback={null}>
    <StartInspectionDialogInner {...props} />
  </Suspense>
);
