export const rentalRatioChartOptions = [
  {
    value: 'daily',
    label: '日',
  },
  {
    value: 'weekly',
    label: '週',
  },
  {
    value: 'monthly',
    label: '月',
  },
  {
    value: 'yearly',
    label: '年',
  },
];

export const monthMenuItems = (year: number) => {
  const today = new Date();
  let maxMonth = 12;
  if (year === today.getFullYear()) {
    maxMonth = today.getMonth() + 1;
  }

  const items = [];
  for (let i = 0; i < maxMonth; i++) {
    items.push({label: `${i + 1}月`, value: i});
  }
  return items;
};

export const yearMenuItems = () => {
  const items = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  for (let i = 0; i < 15; i++) {
    items.push({label: `${currentYear - i}年度`, value: currentYear - i});
  }
  return items;
};
