import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const sideNavHeight = '100%';
export const sideNavWidth = 356;
export const sideBarWidth = 256;

export const use2ColumnsLayoutTemplate = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflowY: 'hidden',
    },
    sideNav: {
      width: sideNavWidth,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      // marginTop: appHeaderWidth,
    },
    content: {
      width: `calc(100% - ${sideNavWidth}px)`,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
    },
  })
);

export const use3ColumnsLayoutTemplate = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflowY: 'hidden',
    },
    leftSideNav: {
      width: sideNavWidth,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      // marginTop: appHeaderWidth,
    },
    rightSideNav: {
      width: 0,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      // marginTop: appHeaderWidth,
      [theme.breakpoints.up('laptop')]: {
        width: sideNavWidth,
      },
    },
    content: {
      width: `calc(100% - ${sideNavWidth}px)`,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      [theme.breakpoints.up('laptop')]: {
        width: `calc(100% - ${sideNavWidth}px - ${sideNavWidth}px)`,
      },
    },
  })
);

export const useSettingsContentTemplate = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontWeight: 'bold',
    },
    grid: {
      height: '100%',
      overflowY: 'visible',
    },
    sideBar: {
      width: sideBarWidth,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
    },
    content: {
      width: `calc(100% - ${sideBarWidth}px)`,
      height: `calc(100vh - 56px)`,
      overflowY: 'visible',
      padding: '48px 40px',
      '& > :last-child': {
        // 編集時にポップアップのフッターからコンテントが隠れるのを防ぐためのpadding
        paddingBottom: '75px',
      }
    },
    flex: {
      flexGrow: 1,
    },
    form: {
      backgroundColor: theme.palette.common.white,
      width: '100%',
      overflowY: 'visible',
    },
    pageSubTitle1: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
    },
    pageSubTitle2: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
    },
    divider: {
      margin: '16px 0',
      width: '100%',
    },
    link: {
      color: theme.palette.secondary.dark,
    },
  })
);
