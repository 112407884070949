import React, {useMemo} from 'react';
import {makeStyles, Grid, Typography, Theme, Avatar} from '@material-ui/core';
import {NotificationCategory, UserNotification} from '@modules/notifications/types';
import clsx from 'clsx';
import {getNotificationCategoryByValue} from '@modules/notifications/constant';
import {
  Brightness1,
  SvgIconComponent,
  Visibility,
  Today,
  CloudDownload,
  Edit,
  InsertDriveFile,
} from '@material-ui/icons';

export type NotificationListItemProps = {
  notification: UserNotification;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const notificationIconMap: {[key in NotificationCategory]: SvgIconComponent} = {
  rent_notification: Edit,
  return_notification: Edit,
  inspection_reminder: Today,
  fault_repair_pic_notification: Visibility,
  export_hospital_product_notification: CloudDownload,
  export_inspection_result_notification: CloudDownload,
  export_training_plan_notification: CloudDownload,
  import_hospital_product_notification: CloudDownload,
  generate_inspection_result_pdfs_notification: CloudDownload,
  pmda_upload_notification: InsertDriveFile,
  pmda_recall_notification: InsertDriveFile,
  // FIXME: ワーカーサーバ動き出したり、要件確定していったら追加してく
  // rental_amount_notification: Warning,
};

export const NotificationListItem: React.VFC<NotificationListItemProps> = (props) => {
  const classes = useStyles();

  const isUnread = useMemo(() => props.notification.status === 'unread', [props.notification.status]);
  const displayCategory = useMemo(
    () => getNotificationCategoryByValue(props.notification.category),
    [props.notification.category]
  );

  const displayDateDuration = useMemo(() => {
    const now = new Date();
    const createdAt = new Date(props.notification.createdAt);
    const duration = now.getTime() - createdAt.getTime();
    const durationInMinutes = Math.floor(duration / 1000 / 60);
    if (durationInMinutes < 60) {
      return `${durationInMinutes}分前`;
    }
    const durationInHours = Math.floor(durationInMinutes / 60);
    if (durationInHours < 24) {
      return `${durationInHours}時間前`;
    }
    const durationInDays = Math.floor(durationInHours / 24);
    return `${durationInDays}日前`;
  }, [props.notification.createdAt]);

  const NotificationIcon = useMemo(
    () => notificationIconMap[props.notification.category],
    [props.notification.category]
  );

  return (
    <Grid
      container
      className={classes.listItem}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      onClick={props.onClick}>
      <Grid item>
        <Avatar style={{backgroundColor: '#425B76'}}>
          <NotificationIcon />
        </Avatar>
      </Grid>
      <Grid item style={{paddingLeft: '16px', maxWidth: '260px'}}>
        <Typography className={isUnread ? clsx(classes.category, classes.unread) : classes.category}>
          {displayCategory}
        </Typography>
        <Typography className={isUnread ? clsx(classes.description, classes.unread) : classes.description}>
          {props.notification.description}
        </Typography>
      </Grid>
      <div className={classes.flex} />
      <Grid item>
        <Typography className={isUnread ? clsx(classes.description, classes.unread) : classes.description}>
          {isUnread && <Brightness1 className={classes.unreadMark} />}
          {displayDateDuration}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    width: '100%',
    padding: '24px 20px',
    borderBottom: `1px solid #EAF0F6`,
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#172B4D',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  unread: {
    fontWeight: 'bold',
  },
  category: {
    color: '#9DABC0',
    fontSize: '14px',
  },
  description: {
    fontSize: '14px',
  },
  flex: {
    flexGrow: 1,
  },
  unreadMark: {
    width: '10px',
    top: '6px',
    marginRight: '8px',
    position: 'relative',
    color: theme.palette.error.main,
  },
}));
