import React, {useCallback} from 'react';
import {TableRow as MTableRow, TableCell as MTableCell, Grid, Typography, Theme, makeStyles} from '@material-ui/core';
import {useAtomValue} from 'jotai';
import {ActionBarProps} from '../props';
import {numSelectedAtom, numDataAtom, useGetCheckedData} from '../atoms';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  selectAll: {
    cursor: 'pointer',
  },
  smallNumSelectText: {
    fontSize: '14px'
  },
  selectAllText: {
    size: '14px',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  action: {
    cursor: 'pointer',
  },
  actionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallActionIcon: {
    '& svg': {
      fontSize: '20px',
    }
  },
  actionLabel: {
    marginLeft: '8px',
    size: '14px',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    padding: '0px',
    fontSize: '14px',
  },
}));

// eslint-disable-next-line @typescript-eslint/ban-types
export const ActionBar = <T extends {}>(props: ActionBarProps<T>) => {
  const {numColumns, showsAllSelection, actions, actionSize, onClickSelectAll} = props;

  const classes = useStyles();
  const numSelected = useAtomValue(numSelectedAtom);
  const numTotal = useAtomValue(numDataAtom);
  const getCheckedData = useGetCheckedData();

  const handleClickAction = useCallback(
    // eslint-disable-next-line no-shadow
    async (e: React.MouseEvent, callback: (e: React.MouseEvent, data: T[]) => void | Promise<void>) => {
      await callback(e, await getCheckedData(true));
    },
    [getCheckedData]
  );

  return (
    <MTableRow>
      <MTableCell align="justify" colSpan={numColumns}>
        <Grid container wrap="wrap" direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Typography className={clsx(actionSize === 'small' && classes.smallNumSelectText)}>{`${numSelected}件が選択されました。`}</Typography>
          </Grid>
          {showsAllSelection && (
            <Grid item className={classes.selectAll} onClick={onClickSelectAll}>
              <Typography className={classes.selectAllText}>{`${numTotal}件すべてを検索します`}</Typography>
            </Grid>
          )}
          {actions.map(({label, IconComponent, onClick}) => (
            <Grid key={label} item className={clsx(classes.action, actionSize==='small' && classes.smallActionIcon)} onClick={(e) => handleClickAction(e, onClick)}>
              <Grid container wrap="nowrap" direction="row" alignItems="center" justifyContent="flex-start">
                <Grid item className={classes.actionIcon}>
                  <IconComponent />
                </Grid>
                <Grid item>
                  <Typography className={classes.actionLabel}>{label}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </MTableCell>
    </MTableRow>
  );
};
