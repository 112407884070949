import React from 'react';
import {Typography, TypographyProps} from '@material-ui/core';
import {styled} from '@material-ui/styles';

const Label = styled(Typography)({
  borderRadius: '3px',
  border: '1px solid #003FB5',
  padding: '2px 6px',
  background: '#003FB5',
  color: '#FFF',
  letterSpacing: '0.16px',
  lineHeight: '11px',
  display: 'inline',
});

export const RequiredLabelBadge: React.FC<TypographyProps> = (props) => {
  return (
    <Label {...props} variant="caption">
      必須
    </Label>
  );
};
