import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  Link,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import {MoreHoriz} from '@material-ui/icons';
import {ProductIndex} from '@modules/products/types';
import {CategoryFormatter} from '@modules/categories/helpers';

export const ProductCardHeaderHeight = '80px';

type Props = {
  product: ProductIndex;
};

export const ProductCardHeader: React.VFC<Props> = (props) => {
  const {product} = props;
  const classes = useStyles();

  const rootCategory = CategoryFormatter.getRootCategory(product.categories)?.name ?? '';
  const narrowCategory = CategoryFormatter.getNarrowCategory(product.categories)?.name ?? '';

  return (
    <Box className={classes.box}>
      <Grid container className={classes.container}>
        <Grid item style={{fontSize: 14}}>
          {product.displayName}
        </Grid>
        <Grid item>
          {rootCategory} {'>'} {narrowCategory}
        </Grid>
      </Grid>
      {/* FIXME PMDAやメーカーHPのデータが整い次第表示。 */}
      {/* <MenuInfo /> */}
    </Box>
  );
};

type MenuProps = {
  product: ProductIndex;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuInfo: React.VFC<MenuProps> = (props) => {
  const {product} = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const clickButton = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip ref={anchorRef} title="" className={classes.tooltip} onClick={clickButton}>
        <MoreHoriz />
      </Tooltip>

      <Popper open={open} anchorEl={anchorRef.current} transition>
        {({TransitionProps}) => (
          <Grow {...TransitionProps} style={{transformOrigin: 'placement'}}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleMenuItemClick}>
                    <Link href={product.makerUrl} target="_blank" underline="none" color="inherit">
                      メーカーホームページ
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuItemClick}>
                    <Link href={product.pmdaUrl} target="_blank" underline="none" color="inherit">
                      添付文書(PDF)
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    boxSizing: 'border-box',
    height: ProductCardHeaderHeight,
    width: '100%',
    padding: '16px 0px 0px 18px',
    backgroundColor: 'white',
    display: 'flex',
  },
  container: {
    flexDirection: 'column',
    fontSize: '12px',
  },
  tooltip: {
    cursor: 'pointer',
    padding: '4px',
  },
}));
