import Grid from '@material-ui/core/Grid';
import React, {useCallback, VFC} from 'react';
import {Button, makeStyles} from '@material-ui/core';
import {ProductCard} from './ProductCard';
import {ToolBar} from './ToolBar';
import {NoProducts} from './NoProducts';
import {PRODUCT_REGISTRATION_FOOTER_HEIGHT, PRODUCT_REGISTRATION_HEADER_HEIGHT} from '../constants';
import {createWholeProducts, FetchProductsParams, useFetchProductsQuery} from '@modules/products/api';
import {useAtom} from 'jotai';
import {searchNameAtom, selectedCategoryAtom, selectedMakerAtom} from '../state';
import {isNullish} from '@front-libs/helpers';
import {CreateWholeProduct, ProductIndex} from '@modules/products/types';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {NewRegistrationDialogProps, NewRegistrationDialogResult, NewRegistrationDialog} from './Dialog';
import {useNavigate} from 'react-router-dom';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

export const ProductCardList = () => {
  const classes = useStyles();
  const [searchName] = useAtom(searchNameAtom);
  const [selectedMaker] = useAtom(selectedMakerAtom);
  const [selectedCategory] = useAtom(selectedCategoryAtom);

  const params: FetchProductsParams = {
    page: 0,
    perPage: 100,
    name: searchName,
    makerHashId: isNullish(selectedMaker) ? undefined : selectedMaker,
    categoryHashId: isNullish(selectedCategory) ? undefined : selectedCategory,
  };
  const {data: wholeProducts} = useFetchProductsQuery(params);

  return (
    <Grid item container className={classes.container} alignContent="flex-start">
      <ToolBar />
      <Results products={wholeProducts} />
    </Grid>
  );
};

type ResultProps = {
  products: ProductIndex[];
};

const Results: VFC<ResultProps> = (props) => {
  const {products} = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  let res;
  const registerProduct = async () => {
    try {
      const formData = await dialogHandler.open<NewRegistrationDialogProps, NewRegistrationDialogResult>(
        NewRegistrationDialog,
        {}
      );

      const isNew = Boolean(formData.newMakerName);

      const newProduct: CreateWholeProduct = {
        rootCategoryHashId: formData.rootCategoryHashId,
        narrowCategoryHashId: formData.subCategoryHashId,
        makerHashID: isNew ? undefined : formData.makerHashId,
        makerName: isNew ? formData.newMakerName ?? undefined : undefined,
        name: formData.name,
        displayName: formData.displayName,
        janCode: formData.janCode,
        approvalNumber: formData.approvalNumber,
        className: formData.className,
      };

      res = await createWholeProducts(myInfo.hospitalHashId, newProduct);

      return res.data.hashId;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const handleClickNewProduct = useCallback(async () => {
    const hashId = await registerProduct();
    if (hashId !== null) {
      navigate(`/products/registration/Step2/${hashId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return products?.length === 0 ? (
    <Grid item className={classes.noDataProducts}>
      <NoProducts
        title="機種が見つかりませんでした。"
        message={`検索条件を変更するか、下記のボタンから\nHITOTSUマスタにまだ登録されていない機種を\n登録することができます。`}
      />
      <Button onClick={handleClickNewProduct} variant="contained" color="primary">
        機種情報を入力
      </Button>
    </Grid>
  ) : (
    <Grid item className={classes.cardContainer}>
      {products?.map((product) => {
        return <ProductCard product={product} key={product.hashId} />;
      })}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    padding: '40px 40px 0px 40px',
    height: 'auto',
    minHeight: `calc(100vh - ${PRODUCT_REGISTRATION_HEADER_HEIGHT})`,
  },
  cardContainer: {
    width: '100%',
    padding: '24px 0px 0px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    flexStart: 'flex-start',
    marginBottom: PRODUCT_REGISTRATION_FOOTER_HEIGHT,
  },
  noDataProducts: {
    position: 'absolute',
    top: '40%',
    left: '25%',
  },
}));
