import React, {memo} from 'react';
import {Box, Typography, Theme, styled} from '@material-ui/core';
import {InspectionTypeOptionsValues} from '@modules/inspections/enum';

export type InspectionTypeBarProps = {
  type: string;
  onChangeType: (value: InspectionTypeOptionsValues) => void;
};

export type MenuItemList = {
  label: string;
  value: InspectionTypeOptionsValues;
};

const StyledContainer = styled('div')(({theme}: {theme: Theme}) => ({
  overflowX: 'auto',
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  margin: '0px 16px',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  maxWidth: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
}));

const BarButtonBox = styled(({selected, ...otherProps}) => <Box {...otherProps} />)(
  ({selected}: {selected: boolean}) => ({
    borderBottom: selected ? '2px solid #0052CC' : undefined,
    padding: '10px 8px',
    marginRight: '16px',
  })
);
const BarButtonTypography = styled(({selected, ...otherProps}) => <Typography {...otherProps} />)(
  ({selected}: {selected: boolean}) => ({
    color: selected ? '#0052CC' : undefined,
    fontWeight: selected ? 'bold' : undefined,
  })
);

export const InspectionTypeMenuItemList: MenuItemList[] = [
  {label: '使用前点検', value: 'pre_use'},
  {label: '使用中点検', value: 'in_use'},
  {label: '使用後点検', value: 'post_use'},
  {label: '定期点検', value: 'periodic'},
];

/**
 * 点検タイプ切り替え
 * @param props.type 現在選択されている点検タイプ
 * @param props.onChangeType 項目選択時のコールバック関数
 */
export const InspectionTypeBar = memo(({type, onChangeType}: InspectionTypeBarProps) => {
  const handleClick = (selectedType: InspectionTypeOptionsValues) => {
    onChangeType(selectedType);
  };

  return (
    <StyledContainer>
      {InspectionTypeMenuItemList.map((v) => (
        <BarButton label={v.label} value={v.value} current={type} key={v.value} handleClick={handleClick} />
      ))}
    </StyledContainer>
  );
});

type BarButtonProps = {
  label: string;
  value: InspectionTypeOptionsValues;
  current: string;
  handleClick: (selectedType: InspectionTypeOptionsValues) => void;
};
/**
 * 点検タイプを選択するためのボタンコンポーネント。
 *
 * @param props - コンポーネントのプロパティ
 * @param props.label - ボタンに表示されるラベル。
 * @param props.value - このボタンに対応する点検タイプの値。
 * @param props.current - 現在選択されている点検タイプの値。
 * @returns ボタンコンポーネントを返す。
 */
const BarButton = ({label, value, current, handleClick}: BarButtonProps) => {
  const selected = current === value;
  return (
    <BarButtonBox selected={selected} onClick={() => handleClick(value)}>
      <BarButtonTypography selected={selected} align="center">
        {label}
      </BarButtonTypography>
    </BarButtonBox>
  );
};
