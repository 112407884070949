import {dexieDB} from '@indexedDB/database';
import {useLiveQuery} from 'dexie-react-hooks';
import {HospitalProductIndex} from '@modules/hospital_products/types';

export function useAllHospitalProducts() {
  const allHospitalProducts =
    useLiveQuery(async () => {
      return await dexieDB?.hospitalProducts.toArray();
    }, []) || [];

  return allHospitalProducts;
}

// indexedDBの値を更新する
export const upsertHospitalProducts = async (hospitalProducts: HospitalProductIndex[]) => {
  await dexieDB?.hospitalProducts.bulkPut(hospitalProducts);
};

// 病院IDに紐づく院内機器を全削除
export const deleteHospitalProductsByHospitalHashId = async (hospitalHashId: string) => {
  await dexieDB?.hospitalProducts.where('hospitalHashId').equals(hospitalHashId).delete();
};
