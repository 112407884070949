import {styled} from '@material-ui/core';
import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {FetchInspectionResultsResponse} from '@modules/inspection_results/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {UseQueryResult} from 'react-query';
import {InspectionStartIconButton} from '@molecules/Buttons/InspectionStartIconButton';
import {InspectionListItem} from '@molecules/InspectionListItem';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  CreateInspectionResultDialog,
  CreateInspectionResultDialogProps,
  CreateInspectionResultDialogResult,
} from '@organisms/CreateInspectionResultDialog';
import {createInspectionResult} from '@modules/inspection_results/api';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type Props = {
  wholeProductHashId: string;
  hospitalProductHashId: string;
  query: UseQueryResult<FetchInspectionResultsResponse, unknown>;
};

/*
 * 機器詳細の点検・修理タブの点検のタブ
 */
export const InspectionTab = ({hospitalProductHashId, wholeProductHashId, query}: Props) => {
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();

  const handleAddInspection = useCallback(async () => {
    try {
      const {inspectionHashId, scheduledDate, inspector, isAdhoc} = await dialogHandler.open<
        CreateInspectionResultDialogProps,
        CreateInspectionResultDialogResult
      >(CreateInspectionResultDialog, {
        hospitalProductHashId: hospitalProductHashId,
        defaultInspectorHashId: myInfo.hashId,
        defaultScheduledDate: convertDateToSimpleDate(new Date()),
      });

      const result = await createInspectionResult(myInfo.hospitalHashId, inspectionHashId, {
        hospitalProductHashId,
        category: 'in_hospital',
        scheduledTime: scheduledDate,
        inspectorHashId: inspector,
        status: 'draft',
        items: {},
        isAdhoc: isAdhoc ? true : undefined,
      });

      navigate(`/inspections/${result.data.inspectionHashId}/result/${result.data.hashId}`);
    } catch (err) {
      console.error(err);
    }
  }, [hospitalProductHashId, myInfo.hashId, myInfo.hospitalHashId, navigate]);

  const isFetched = useMemo(() => query.isFetched, [query]);
  return (
    <RootContainer>
      <InspectionHeader>
        {!isReadOnly && <InspectionStartIconButton onAddRelatedContent={handleAddInspection} />}
      </InspectionHeader>
      <InspectionContent>
        {isFetched &&
          (query.data?.data ?? []).map((item, index) => (
            <InspectionListItem
              key={index}
              inspectionResult={item}
              wholeProductHashId={wholeProductHashId}
              isReadOnly={isReadOnly}
            />
          ))}
      </InspectionContent>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  margin: '16px 0',
  display: 'flex',
  flexDirection: 'column',
});

const InspectionHeader = styled('div')({
  padding: '0 32px',
  display: 'flex',
  justifyContent: 'flex-end',
});

// スクロールしない部分の高さ
const FIXED_HEIGHT = 320;

const InspectionContent = styled('div')({
  marginBottom: '24px',
  padding: '0 32px',
  height: `calc(100vh - ${FIXED_HEIGHT}px)`,
  overflowY: 'auto',
  overflowX: 'hidden',
});
