import {atom} from 'jotai';

export type Product = {
  hashId?: string;
  name: string;
  displayName: string;
  makerName: string;
  rootCategory: string | null;
  narrowCategory: string | null;
  uuid?: string;
  searchKey: string;
};

export const productsAtom = atom<Product[]>([]);
// export const roomNamesAtom = atom<string[]>([]);
