import React from 'react';
import {ProductStatusConst} from '@modules/hospital_products/types';
import {Avatar, Grid, styled} from '@material-ui/core';
import {ProductStatus} from '@molecules/ProductStatus';
import {Assignment} from '@material-ui/icons';

const Container = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey[50],
  padding: '8px 16px 16px',
}));

const ProductThumbnail = styled(Avatar)({
  width: '64px',
  height: '64px',
});

const DisplayName = styled('div')({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#172B4D',
});

export type MobileProductInfoProps = {
  managementId: string;
  name?: string;
  displayName: string;
  makerName?: string;
  status: ProductStatusConst;
  thumbnailUrl?: string;
  isShrink: boolean;
};

export const MobileProductInfo = (props: MobileProductInfoProps) => {
  const {status, managementId, name, makerName, isShrink} = props;
  return (
    <Container>
      <Grid container alignItems="center" style={{marginBottom: 8}}>
        <Grid item style={{width: '80px'}}>
          <ProductStatus productStatus={status} />
        </Grid>
        <Grid item style={{fontSize: '16px', fontWeight: 700}}>
          {managementId}
        </Grid>
      </Grid>
      <Grid container style={{gap: '16px'}}>
        {!isShrink && (
          <Grid item>
            <ProductThumbnail variant="rounded">
              <Assignment />
            </ProductThumbnail>
          </Grid>
        )}
        <Grid item>
          <Grid container style={{gap: '4px'}} direction="column">
            <Grid item>
              <DisplayName>{props.displayName}</DisplayName>
            </Grid>
            {!isShrink && (
              <>
                <Grid item>
                  <div>{name}</div>
                </Grid>
                <Grid item>
                  <div>{makerName}</div>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
