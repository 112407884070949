import {FilterOption} from '@components/molecules/Drawers/FilterDrawer/types';

export const FilterDrawerOptions: FilterOption[] = [
  {
    label: '管理番号',
    value: 'managementId',
    optionType: 'text',
  },
  {
    label: '型式',
    value: 'name',
    optionType: 'text',
  },
  {
    label: '点検開始日',
    value: 'periodicInspectionStartDate',
    optionType: 'dateRange',
  },
  {
    label: '点検表',
    value: 'inspectionName',
    optionType: 'text',
  },
];
