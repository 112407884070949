import {atom} from 'jotai';

const initialState = 0;
const _tabAtom = atom<number>(initialState);

export const tabAtom = atom<number, number | null>(
  (get) => get(_tabAtom),
  (_get, set, num) => {
    set(_tabAtom, num === null ? _get(_tabAtom) : num);
  }
);

export const hospitalWardsAtom = atom<string[]>([]);
