import React from 'react';
import {FieldProps} from 'formik';
import {makeStyles, Theme} from '@material-ui/core/styles';
import wrapField from './wrapField';
import BaseTextField, {TextFieldProps} from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

type Props = FieldProps<string> & Omit<TextFieldProps, 'onChange' | 'variant' | 'className'>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    minWidth: '42%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '84%',
    },
  },
  textField: {
    minWidth: '92%',
  },
}));

export function PasswordField(props: Props) {
  const {field, ...rest} = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className={classes.root}>
      <BaseTextField
        {...rest}
        {...field}
        className={classes.textField}
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          autoComplete: 'new-password',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prevState) => !prevState)}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default wrapField(PasswordField);
