import React, {Suspense, useMemo} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {yup} from '@front-libs/core';
import {Form, Formik, useFormikContext} from 'formik';
import {Dialog} from '@atoms/Dialog/Dialog';
import {Button, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField} from '@material-ui/core';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import Selector from '@molecules/Formik/fields/Selector';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {HospitalRentalSetting, rentalSettings} from '@modules/hospital_settings/types';
import {useFetchHospitalSettingQuery} from '@modules/hospital_settings/api';
import {isNullish} from '@front-libs/helpers';
import {undefinedRentRoom} from '@modules/hospital_places/types';

type BulkRentFormProps = {
  hospitalRoomHashId: string;
  checkoutDeviceLenderName?: string;
};

type BulkRentFormTypeProps = {
  lenderSetting: HospitalRentalSetting;
};

const BulkRentForm: React.FC<BulkRentFormTypeProps> = ({lenderSetting}) => {
  const {values, handleChange} = useFormikContext<BulkRentFormProps>();
  const {myInfo} = useMyInfo();
  const {data: hospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    order: 'hospitalWard,name',
  });
  const {data: allowRentalRoomUndefinedData} = useFetchHospitalSettingQuery(
    myInfo.hospitalHashId,
    rentalSettings.allow_undefined_room.key
  );

  const hospitalRoomOptions = useMemo(() => {
    const roomOptions = hospitalRooms.map((item) => ({
      label: HospitalRoomFormatter.getFullRoom(item),
      value: item.hashId,
    }));

    if (!isNullish(allowRentalRoomUndefinedData) && allowRentalRoomUndefinedData.value === 'true') {
      roomOptions.unshift(undefinedRentRoom);
    }

    return roomOptions;
  }, [allowRentalRoomUndefinedData, hospitalRooms]);

  return (
    <Form>
      <Grid style={{marginBottom: 16}}>
        <RequiredLabel>貸出場所</RequiredLabel>
        <Selector name="hospitalRoomHashId" size="small" options={hospitalRoomOptions} />
      </Grid>
      {lenderSetting !== 'not_need' && (
        <Grid style={{marginBottom: 16}}>
          <label>持出者</label>
          <TextField
            fullWidth
            id="checkoutDeviceLenderName"
            name="checkoutDeviceLenderName"
            type="text"
            variant="outlined"
            size={'small'}
            value={values.checkoutDeviceLenderName}
            onChange={handleChange}
          />
        </Grid>
      )}
      <Grid xs={12}>待機中かつ貸出可の機器のみ貸出登録できます</Grid>
    </Form>
  );
};

type BulkRentDialogProps = {
  lenderSetting: HospitalRentalSetting;
} & DialogProps;

export const BulkRentDialog: React.VFC<BulkRentDialogProps> = (props) => {
  const classes = useStyles();
  const validationSchema = yup.object({
    hospitalRoomHashId: yup.string().required(),
    checkoutDeviceLenderName: yup.string(),
  });

  const handleSubmit = (res: BulkRentFormProps) => {
    props.actions.resolve(res);
  };

  const handleClose = () => {
    props.actions.reject();
  };

  return (
    <Suspense fallback={null}>
      <Formik<BulkRentFormProps>
        initialValues={{
          hospitalRoomHashId: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}>
        {(formik) => (
          <Dialog {...props}>
            <DialogTitle id="bulk-rent-dialog-title">貸出登録</DialogTitle>
            <DialogContent>
              <BulkRentForm lenderSetting={props.lenderSetting} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={() => handleSubmit(formik.values)}
                color="primary"
                variant="contained">
                登録
              </Button>
              <Button disabled={formik.isSubmitting} onClick={handleClose}>
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </Suspense>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'start',
    padding: '16px 24px 24px',
  },
  textFieldTitle: {
    marginTop: 0,
    marginBottom: 16,
  },
  information: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: '8px',
  },
  placeText: {
    padding: '12px',
    color: theme.palette.secondary.dark,
    position: 'relative',
    bottom: '3px',
  },
  exclamation: {
    position: 'relative',
    top: '6px',
    paddingRight: '4px',
  },
}));
