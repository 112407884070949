import {axios} from '@front-libs/core';

export type UpdatePasswordParams = {
  hashId: string;
  password: string;
};

export const updateUserPassword = async (data: UpdatePasswordParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/auth/change_password`;
  return axios.put(baseUrl, data);
};

export type ResetPasswordParams = {
  hashId: string;
};

export const resetUserPassword = async (data: ResetPasswordParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/auth/reset_password`;
  return axios.post(baseUrl, data);
};
