import React, {useMemo} from 'react';
import {TextField, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {useAtom, PrimitiveAtom} from 'jotai';
import {Selector, Option} from '@molecules/Selector';
import {productStatusOptions} from '@modules/hospital_products/constants';
import {DateField, DateFieldValue} from '@molecules/DateField';
import {Product} from '../../state';
import {
  formatAssetRegisterNumber,
  formatBarcode,
  formatGS1Barcode,
  formatLotNumber,
  formatManagementId,
  formatSerialNumber,
} from '@modules/hospital_products/utls';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {useHospitalDealerOption} from '@modules/hospital_dealer/api';
import {useHospitalProductMgmtSectionOption} from '@modules/hospital_product_mgmt_section/api';

type Props = {
  productAtom: PrimitiveAtom<Product>;
  index: number;
  isSelected: (key: string) => boolean;
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    height: 36,
    background: theme.palette.common.white,
  },
  cell: {
    padding: 8,
    minWidth: 170,
  },
  multiTextField: {
    padding: 8,
    background: theme.palette.common.white,
  },
}));

/**
 * 病院の機器情報を表示および編集するためのテーブル行を提供します。
 * 各行には、管理番号、シリアル番号、ロット番号などのフィールドが含まれ、
 * ユーザーはこれらのフィールドを直接編集できます。
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {WritableAtom<HospitalProductIndex | null, HospitalProductIndex | null>} props.productAtom - 編集中の機器情報のAtom
 * @param {number} props.index - テーブル内の行のインデックス
 * @param {Function} props.handleClick - チェックボックスのクリックイベントハンドラ
 * @param {Function} props.isSelected - 指定された機器が選択されているかどうかを判定する関数
 * @returns {React.ReactElement} テーブル行のReact要素
 */
export const TableFormContents: React.VFC<Props> = ({productAtom, index, handleClick, isSelected}) => {
  const labelId = `enhanced-table-checkbox-${index}`;
  const classes = useStyles();
  const {myInfo} = useMyInfo();

  const [product, setProduct] = useAtom(productAtom);
  const isItemSelected = isSelected(product?.uuid + '');
  const {data: hospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId);
  const hospitalRoomOptions = useMemo(
    () =>
      hospitalRooms.map((room) => ({
        label: HospitalRoomFormatter.getFullRoom(room),
        value: room.hashId,
      })),
    [hospitalRooms]
  );

  const hospitalDealerOptions = useHospitalDealerOption(myInfo.hospitalHashId);
  const hospitalProductMgmtSectionOptions = useHospitalProductMgmtSectionOption(myInfo.hospitalHashId);
  // TODO:項目追加や並び順の変更がやりにくいので個別関数にする等リファクタリングしたい
  return (
    <TableRow hover tabIndex={-1} key={product.uuid}>
      <TableCell padding="checkbox">
        <Checkbox
          onClick={(event) => handleClick(event, product?.uuid + '')}
          aria-checked={isItemSelected}
          checked={isItemSelected}
          inputProps={{'aria-labelledby': labelId}}
        />
      </TableCell>
      {/* 1.管理番号 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          key={product.managementId}
          variant="outlined"
          defaultValue={product.managementId}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                managementId: formatManagementId(e.target.value),
              };
            });
          }}
        />
      </TableCell>
      {/* 2.シリアル番号 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          key={product.serialNumber}
          defaultValue={product.serialNumber}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                serialNumber: formatSerialNumber(e.target.value),
              };
            });
          }}
        />
      </TableCell>
      {/* 3.ロット番号 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          key={product.lotNumber}
          defaultValue={product.lotNumber}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                lotNumber: formatLotNumber(e.target.value),
              };
            });
          }}
        />
      </TableCell>
      {/* 4.貸出区分 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.permanentlyAssigned}
          options={[
            {label: '貸出不可', value: true},
            {label: '貸出可', value: false},
          ]}
          onChange={(e: Option<boolean>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                permanentlyAssigned: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      {/* 5.管理部署 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.hospitalProductMgmtSectionHashId}
          options={hospitalProductMgmtSectionOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                hospitalProductMgmtSectionHashId: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>

      {/* 6.稼働状況 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.status}
          options={productStatusOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                status: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      {/* 7.機器管理場所 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.hospitalRoomHashId}
          options={hospitalRoomOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                hospitalRoomHashId: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      {/* 8.購入日 */}
      <TableCell classes={{root: classes.cell}}>
        <DateField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          value={product.dateOfPurchase}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(data: any) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                dateOfPurchase: data,
              };
            });
          }}
        />
      </TableCell>
      {/* 9.親機・子機 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.isBaseUnit}
          options={[
            {label: '親機', value: true},
            {label: '子機', value: false},
          ]}
          onChange={(e: Option<boolean>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                isBaseUnit: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      {/* 10.購入区分 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.waysOfPurchase}
          options={[
            {label: '購入', value: 'purchase'},
            {label: 'リース', value: 'lease'},
            {label: 'レンタル', value: 'rental'},
            {label: '代替品', value: 'alternative'},
            {label: 'デモ機', value: 'demo'},
            {label: '寄贈', value: 'gift'},
            {label: '移管', value: 'transfer_control'},
          ]}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                waysOfPurchase: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      {/* 11.院内耐用年数（年） */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          key={product.legalDurableYear}
          variant="outlined"
          type="number"
          defaultValue={product.legalDurableYear}
          inputProps={{min: 1}}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                legalDurableYear: parseInt(Number(e.target.value) >= 0 ? e.target.value : '0', 10),
              };
            });
          }}
        />
      </TableCell>
      {/* 12.保守契約 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.isMaintenanceContract}
          options={[
            {label: '保守契約', value: true},
            {label: '保守契約外', value: false},
          ]}
          onChange={(e: Option<boolean>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                isMaintenanceContract: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      {/* 13.担当代理店 */}
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.hospitalDealerHashId}
          options={hospitalDealerOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                hospitalDealerHashId: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      {/* 14.資産番号 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          key={product.assetRegisterNumber}
          defaultValue={product.assetRegisterNumber}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                assetRegisterNumber: formatAssetRegisterNumber(e.target.value),
              };
            });
          }}
        />
      </TableCell>
      {/* <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          type="number"
          key={product.depreciationAmount}
          defaultValue={product.depreciationAmount}
          inputProps={{min: 0}}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                depreciationAmount: parseInt(Number(e.target.value) >= 0 ? e.target.value : '0', 10),
              };
            });
          }}
        />
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          type="number"
          key={product.bookValue}
          defaultValue={product.bookValue}
          inputProps={{min: 0}}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                bookValue: parseInt(Number(e.target.value) >= 0 ? e.target.value : '0', 10),
              };
            });
          }}
        />
      </TableCell> */}

      {/* 15.廃棄日 */}
      <TableCell classes={{root: classes.cell}}>
        <DateField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          value={product.dateOfDisposal}
          onChange={(data: DateFieldValue) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                dateOfDisposal: data,
              };
            });
          }}
        />
      </TableCell>
      {/* 16.廃棄理由 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              multiline: classes.multiTextField,
            },
          }}
          multiline={true}
          variant="outlined"
          key={product.reasonOfDisposal}
          defaultValue={product.reasonOfDisposal}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                reasonOfDisposal: e.target.value,
              };
            });
          }}
        />
      </TableCell>
      {/* 17.院内点検開始日 */}
      <TableCell classes={{root: classes.cell}}>
        <DateField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          value={product.periodicInspectionStartDate}
          onChange={(data: DateFieldValue) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                periodicInspectionStartDate: data,
              };
            });
          }}
        />
      </TableCell>
      {/* 18.備考1 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              multiline: classes.multiTextField,
            },
          }}
          variant="outlined"
          multiline={true}
          key={product.notes}
          defaultValue={product.notes}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                notes: e.target.value,
              };
            });
          }}
        />
      </TableCell>
      {/* 19.備考2 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              multiline: classes.multiTextField,
            },
          }}
          variant="outlined"
          multiline={true}
          key={product.notes2}
          defaultValue={product.notes2}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                notes2: e.target.value,
              };
            });
          }}
        />
      </TableCell>
      {/* 20.備考3 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              multiline: classes.multiTextField,
            },
          }}
          variant="outlined"
          multiline={true}
          key={product.notes3}
          defaultValue={product.notes3}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                notes3: e.target.value,
              };
            });
          }}
        />
      </TableCell>
      {/* 21.バーコード読み取り値 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          key={product.optionalBarcode}
          defaultValue={product.optionalBarcode}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                optionalBarcode: formatBarcode(e.target.value),
              };
            });
          }}
        />
      </TableCell>
      {/* 22.GS1-128 */}
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          key={product.rawBarcode}
          defaultValue={product.rawBarcode}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                rawBarcode: formatGS1Barcode(e.target.value),
              };
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};
