import {axios} from '@front-libs/core';
import {FileIndex} from '@modules/files/types';

export const createFaultRepairFiles = async (
  hospitalHashId: string,
  faultRepairHashId: string,
  data: {fileHashIds: string[]}
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}/files`;
  return await axios.post<void>(baseURL, data);
};

export const listFaultRepairFiles = async (
  hospitalHashId: string,
  faultRepairHashId: string,
  params: Partial<{
    fileName: string;
    page: number;
    perPage: number;
    order: string;
  }>
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair/${faultRepairHashId}/files`;
  const response = await axios.get<FileIndex[]>(baseUrl, {params});
  return response.data;
};
