import React from 'react';
import {createStyles, makeStyles, Theme, Grid, Menu, Button, PopoverProps} from '@material-ui/core';

export type InlineEditMenuProps = {
  children: React.ReactNode;
  menuAnchor: PopoverProps['anchorEl'];
  isDisabledSave: boolean;
  onSave: () => void;
  onClose: () => void;
  onCancel: () => void;
};

export const InlineEditMenu: React.FC<InlineEditMenuProps> = (props) => {
  const {menuAnchor, children, isDisabledSave, onSave, onClose, onCancel} = props;
  const classes = useStyles();
  return (
    <Menu
      anchorEl={menuAnchor}
      getContentAnchorEl={null}
      anchorOrigin={{vertical: 'center', horizontal: 'center'}}
      open={Boolean(menuAnchor)}
      onClose={onClose}>
      <Grid className={classes.dialog} container direction="column">
        <Grid item container style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
          {children}
        </Grid>
        <Grid item container style={{marginTop: '8px'}}>
          <Button className={classes.actionButton} variant="outlined" onClick={onSave} disabled={isDisabledSave}>
            保存
          </Button>
          <Button className={classes.actionButton} variant="outlined" onClick={onCancel}>
            キャンセル
          </Button>
        </Grid>
      </Grid>
    </Menu>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    period: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    dialog: {
      minWidth: '250px',
      padding: '24px',
      zIndex: theme.zIndex.drawer,
    },
    createButton: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
    },
    actionButton: {
      background: 'rgba(9, 30, 66, 0.04)',
      '&:not(:first-of-type)': {
        marginLeft: '8px',
      },
    },
  })
);
