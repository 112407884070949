import React, {useMemo, useCallback} from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  IconButton,
  Theme,
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
  Button,
} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {NumericField as NumericFieldType, NumericValidator} from '@Apps/Inspection/types';
import {FormikErrors, getIn} from 'formik';
import {FormError} from '../FormError';

const useValidatorFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '24px 20px 0px',
    },
    formContainer: {
      display: 'flex',
      '&:not(:first-child)': {
        marginTop: '16px',
      },
    },
    validatorTypeSelectorContainer: {
      minWidth: '150px',
    },
    inputConditionContainer: {
      marginLeft: '16px',
      marginTop: '16px',
      width: '70px',
    },
    inputCustomErrorText: {
      marginLeft: '16px',
      marginTop: '16px',
      width: '200px',
    },
    conditionSeparator: {
      margin: '24px 0px 0px 16px',
    },
    clearIcon: {
      marginTop: '8px',
    },
  })
);

type ValidatorFormProps = {
  validators: NumericValidator[];
  errors: FormikErrors<NumericFieldType>;
  onClickAddValidator: React.MouseEventHandler;
  onChangeValidatorType: (index: number, type: NumericValidator['type']) => void;
  onChangeValidatorCondition: (index: number, conditionIndex: number, value: number) => void;
  onChangeCustomErrorText: (index: number, errorText: string) => void;
  onDeleteValidator: (index: number) => void;
};

const ValidatorForm: React.VFC<ValidatorFormProps> = (props) => {
  const {
    validators,
    errors,
    onClickAddValidator,
    onChangeValidatorType,
    onChangeValidatorCondition,
    onChangeCustomErrorText,
    onDeleteValidator,
  } = props;
  const classes = useValidatorFormStyles();

  return (
    <Grid container className={classes.root} direction="column">
      {validators.map((validator, index) => {
        const errorOfType = getIn(errors, `validators[${index}].type`);
        const errorOfValue = getIn(errors, `validators[${index}].value`);
        const errorOfLower = getIn(errors, `validators[${index}].lower`);
        const errorOfUpper = getIn(errors, `validators[${index}].upper`);
        const errorOfExpr = getIn(errors, `validators[${index}]._expr`);

        return (
          <Grid container direction="column">
            <Grid item key={index} className={classes.formContainer}>
              <FormControl className={classes.validatorTypeSelectorContainer}>
                <InputLabel htmlFor="validator-type">タイプ</InputLabel>
                <NativeSelect
                  value={validator.type}
                  error={errorOfType}
                  onChange={(e) => onChangeValidatorType(index, e.target.value as NumericValidator['type'])}
                  inputProps={{
                    name: 'type',
                    id: 'validator-type',
                  }}>
                  <option aria-label="None" value="" />
                  <option value="gt">次より大きい</option>
                  <option value="gte">次以上</option>
                  <option value="lt">次より小さい</option>
                  <option value="lte">次以下</option>
                  <option value="eq">次と等しい</option>
                  <option value="neq">次と等しくない</option>
                  <option value="between">次の間にある</option>
                  <option value="not-between">次の間にない</option>
                </NativeSelect>
              </FormControl>

              {(() => {
                switch (validator.type) {
                  case 'gt':
                  case 'gte':
                  case 'lt':
                  case 'lte':
                  case 'eq':
                  case 'neq': {
                    return (
                      <Grid container>
                        <Grid container direction="column" className={classes.inputConditionContainer}>
                          <FormControl>
                            <TextField
                              type="number"
                              error={!!errorOfValue}
                              variant="standard"
                              value={validator.value}
                              placeholder="数値"
                              onChange={(e) => onChangeValidatorCondition(index, 0, Number.parseFloat(e.target.value))}
                            />
                          </FormControl>
                        </Grid>
                        <Grid container direction="column" className={classes.inputCustomErrorText}>
                          <FormControl>
                            <TextField
                              type="text"
                              variant="standard"
                              placeholder="カスタムのエラーテキスト"
                              value={validator.errorText}
                              onChange={(e) => onChangeCustomErrorText(index, e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <IconButton className={classes.clearIcon} onClick={(e) => onDeleteValidator(index)}>
                          <Clear />
                        </IconButton>
                      </Grid>
                    );
                  }
                  case 'between':
                  case 'not-between': {
                    return (
                      <Grid container direction="column" style={{width: '100%'}}>
                        <Grid container>
                          <Grid container direction="column" className={classes.inputConditionContainer}>
                            <FormControl>
                              <TextField
                                type="number"
                                error={!!errorOfLower || !!errorOfExpr}
                                variant="standard"
                                placeholder="数値"
                                value={validator.lower}
                                onChange={(e) =>
                                  onChangeValidatorCondition(index, 0, Number.parseFloat(e.target.value))
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Typography className={classes.conditionSeparator}>と</Typography>
                          <Grid container direction="column" className={classes.inputConditionContainer}>
                            <FormControl>
                              <TextField
                                type="number"
                                error={!!errorOfUpper || !!errorOfExpr}
                                variant="standard"
                                placeholder="数値"
                                value={validator.upper}
                                onChange={(e) =>
                                  onChangeValidatorCondition(index, 1, Number.parseFloat(e.target.value))
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid container direction="column" className={classes.inputCustomErrorText}>
                            <FormControl>
                              <TextField
                                type="text"
                                variant="standard"
                                placeholder="カスタムのエラーテキスト"
                                value={validator.errorText}
                                onChange={(e) => onChangeCustomErrorText(index, e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <IconButton className={classes.clearIcon} onClick={(e) => onDeleteValidator(index)}>
                            <Clear />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  }
                  default:
                    return null;
                }
              })()}
            </Grid>
            <FormError error={errorOfType} />
            <FormError error={errorOfValue} />
            <FormError error={errorOfLower} />
            <FormError error={errorOfUpper} />
            <FormError error={errorOfExpr} />
          </Grid>
        );
      })}
      <Grid style={{marginTop: '16px', display: 'flex', alignItems: 'flex-end'}}>
        <Button onClick={onClickAddValidator}>新しいエラー条件を追加</Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inputValue: {
      width: '100%',
    },
    viewInputContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    viewInputValue: {
      minWidth: '300px',
    },
    viewUnit: {
      marginLeft: '16px',
      color: theme.palette.grey[600],
    },
    errorSummary: {
      marginTop: '8px',
    },
  })
);

// Numeric
type NumericFieldProps = {
  field: NumericFieldType;
  errors: FormikErrors<NumericFieldType>;
  focused: boolean;
  placeholder: string;
  placeholderForUnit: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeField: (fieldName: string | '', value: any, validate: boolean) => void;
};

export const NumericField: React.VFC<NumericFieldProps> = (props) => {
  const {field, errors, focused, placeholder, placeholderForUnit, onChangeField} = props;
  const classes = useStyles();

  const handleUnitChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeField('unit', e.target.value, false);
    },
    [onChangeField]
  );

  const handleAddValidator = useCallback(
    (e: React.MouseEvent) => {
      const newIndex = field.validators.length;
      onChangeField(
        `validators[${newIndex}]`,
        {
          type: null,
        },
        false
      );
    },
    [field.validators.length, onChangeField]
  );

  const handleChangeValidatorType = useCallback(
    (index: number, type: NumericValidator['type']) => {
      // pick out values
      const validator = field.validators[index];
      if (!validator) return;

      let values: number[] = [];
      switch (validator.type) {
        case 'gt':
        case 'gte':
        case 'lt':
        case 'lte':
        case 'eq':
        case 'neq':
          values = [validator.value];
          break;
        case 'between':
        case 'not-between':
          values = [validator.lower, validator.upper];
          break;
      }

      // set values
      switch (type) {
        case 'gt':
        case 'gte':
        case 'lt':
        case 'lte':
        case 'eq':
        case 'neq':
          onChangeField(`validators[${index}]`, {type, value: values[0]}, false);
          break;
        case 'between':
        case 'not-between':
          onChangeField(`validators[${index}]`, {type, lower: values[0], upper: values[1]}, false);
          break;
        case null:
          onChangeField(`validators[${index}]`, {type}, false);
      }
    },
    [field.validators, onChangeField]
  );

  const handleChangeValidatorCondition = useCallback(
    (index: number, conditionIndex: number, value: number) => {
      const validator = field.validators[index];
      if (!validator) return;

      const v = !Number.isNaN(value) ? value : undefined;
      switch (validator.type) {
        case 'gt':
        case 'gte':
        case 'lt':
        case 'lte':
        case 'eq':
        case 'neq':
          if (conditionIndex === 0) {
            onChangeField(`validators[${index}].value`, v, true);
          }
          break;
        case 'between':
        case 'not-between':
          if (conditionIndex === 0) {
            onChangeField(`validators[${index}].lower`, v, true);
          } else if (conditionIndex === 1) {
            onChangeField(`validators[${index}].upper`, v, true);
          }
          break;
      }
    },
    [field.validators, onChangeField]
  );

  const handleChangeCustomErrorText = useCallback(
    (index: number, errorText: string) => {
      const validator = field.validators[index];
      if (!validator) return;

      onChangeField(`validators[${index}].errorText`, errorText, false);
    },
    [field.validators, onChangeField]
  );

  const handleDeleteValidator = useCallback(
    (index: number) => {
      const newValidators = [...field.validators];
      newValidators.splice(index, 1);
      onChangeField('validators', newValidators, false);
    },
    [field.validators, onChangeField]
  );

  const errorSummary = useMemo(() => {
    const set = new Set<string>();
    field.validators.forEach((_, i) => {
      const errs = getIn(errors, `validators[${i}]`);
      if (errs) {
        Object.keys(errs).forEach((key) => {
          set.add(errs[key]);
        });
      }
    });
    return Array.from(set);
  }, [errors, field.validators]);

  const {
    unit,
    validators,
    settings: {showsValidator},
  } = field;

  return (
    <Grid container direction="column">
      {focused === true ? (
        <>
          <Grid className={classes.inputContainer}>
            <Grid md={6}>
              <TextField className={classes.inputValue} disabled={true} variant="standard" placeholder={placeholder} />
            </Grid>
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              placeholder={placeholderForUnit}
              value={unit}
              onChange={handleUnitChange}
            />
          </Grid>
          {showsValidator === true && (
            <ValidatorForm
              validators={validators}
              errors={errors}
              onClickAddValidator={handleAddValidator}
              onChangeValidatorType={handleChangeValidatorType}
              onChangeValidatorCondition={handleChangeValidatorCondition}
              onChangeCustomErrorText={handleChangeCustomErrorText}
              onDeleteValidator={handleDeleteValidator}
            />
          )}
        </>
      ) : (
        <Grid container direction="column">
          <Grid className={classes.viewInputContainer}>
            <Grid md={6}>
              <TextField className={classes.viewInputValue} variant="standard" placeholder={placeholder} />
            </Grid>
            <Typography className={classes.viewUnit} variant="inherit">
              {unit}
            </Typography>
          </Grid>
          <Grid className={classes.errorSummary}>
            {focused === false && errorSummary.map((err, i) => <FormError error={err} />)}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
