import React, {Suspense, useMemo} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Dialog} from '@atoms/Dialog/Dialog';
import {useInitialized, yup} from '@front-libs/core';
import {Form, Formik, useFormikContext} from 'formik';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import Select, {ValueType} from 'react-select';
import {InferType} from 'yup';
import {theme} from '@atoms/theme';
import {convertDisplaySimpleDate, isNullish} from '@front-libs/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {UserFormatter} from '@modules/hospital_users/helpers';
import dayjs from 'dayjs';
import {UserIndex} from '@modules/hospital_users/types';
import {useTrainingSchedulesQuery} from '../hooks';

const validationSchema = yup.object({
  trainingScheduleHashId: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required(),
  reportHospitalUserHashId: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  startedAt: yup.string().required(),
  finishedAt: yup.string().required(),
  description: yup.string().required(),
  numberOfAttendants: yup.number(),
  isCompletedSchedulePlan: yup.boolean().required(),
});

type TrainingFormData = InferType<typeof validationSchema>;

export type TrainingReportDialogResult = {
  trainingScheduleHashId: string;
  reportHospitalUserHashId: string;
  startedAt: Date;
  finishedAt: Date;
  description: string;
  numberOfAttendants?: number;
  isCompletedSchedulePlan: boolean;
};
export type TrainingReportDialogProps = DialogProps<TrainingReportDialogResult> & {
  isEdit: boolean;
  currentUser: UserIndex;
  initialValues?: Partial<TrainingReportDialogResult>;
  isHiddenScheduleHashId?: boolean;
};

type TrainingReportFormProps = {
  onCancel: VoidFunction;
  isEdit: boolean;
  isHiddenScheduleHashId: boolean;
};

const TrainingReportForm: React.FC<TrainingReportFormProps> = ({isHiddenScheduleHashId, onCancel, isEdit}) => {
  const {setFieldValue, values, isValid, handleSubmit, handleChange} = useFormikContext<TrainingFormData>();
  const {hospitalUsers} = useHospitalUsers();
  const hospitalUserOptions = useMemo(
    () =>
      hospitalUsers.map((item) => ({
        label: UserFormatter.getFullName(item),
        value: item.hashId,
      })),
    [hospitalUsers]
  );

  const {data: trainingSchedules} = useTrainingSchedulesQuery();
  const trainingScheduleOptions = useMemo(
    () =>
      (trainingSchedules?.data ?? []).map((item) => ({
        label: `${item.trainingPlan.name} - ${item.currentTimes}回目`,
        value: item.hashId,
      })),
    [trainingSchedules?.data]
  );

  return (
    <>
      <DialogContent>
        <Form style={{width: '100%'}}>
          {!isHiddenScheduleHashId && (
            <div style={{width: '100%', marginBottom: '16px'}}>
              <RequiredLabel>研修予定名</RequiredLabel>
              <Select
                menuPortalTarget={document.body}
                name="trainingScheduleHashId"
                styles={{
                  menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 10000000}),
                }}
                value={values.trainingScheduleHashId}
                options={trainingScheduleOptions}
                onChange={(val: ValueType<{label: string; value: string}, false>) => {
                  setFieldValue('trainingScheduleHashId', val);
                }}
              />
            </div>
          )}

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>報告者</RequiredLabel>
            <Select
              menuPortalTarget={document.body}
              name="reportHospitalUserHashId"
              styles={{
                menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 10000000}),
              }}
              value={values.reportHospitalUserHashId}
              options={hospitalUserOptions}
              onChange={(val: ValueType<{label: string; value: string}, false>) => {
                setFieldValue('reportHospitalUserHashId', val);
              }}
            />
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>開催／受講日時</RequiredLabel>
            <Grid container alignItems="center">
              <Grid item>
                <TextField
                  name="startedAt"
                  fullWidth
                  type="datetime-local"
                  variant="outlined"
                  size={'small'}
                  value={values.startedAt}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item style={{marginLeft: 16, marginRight: 16}}>
                〜
              </Grid>
              <Grid item>
                <TextField
                  name="finishedAt"
                  fullWidth
                  type="time"
                  variant="outlined"
                  size={'small'}
                  value={values.finishedAt}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>受講人数</RequiredLabel>
            <Grid container>
              <TextField
                name="numberOfAttendants"
                fullWidth
                type="number"
                variant="outlined"
                size={'small'}
                value={values.numberOfAttendants}
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">名</InputAdornment>,
                }}
              />
            </Grid>
          </div>

          <div style={{width: '100%', marginBottom: '16px'}}>
            <RequiredLabel>研修内容の報告</RequiredLabel>
            <Grid container>
              <TextField
                name="description"
                variant="outlined"
                fullWidth
                size={'small'}
                minRows={4}
                multiline
                value={values.description}
                onChange={handleChange}
              />
            </Grid>
          </div>
        </Form>
      </DialogContent>
      <DialogActions style={{marginRight: 16, marginLeft: 16}}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <label>
              <Checkbox
                color="primary"
                checked={values.isCompletedSchedulePlan}
                name="isCompletedSchedulePlan"
                onChange={handleChange}
              />{' '}
              研修の予定を完了にする
            </label>
          </Grid>
          <Grid item>
            <Button disabled={!isValid} variant={'contained'} color="primary" onClick={() => handleSubmit()}>
              {isEdit ? '更新' : '作成'}
            </Button>
            <Button onClick={() => onCancel()} color="primary">
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const InnerTrainingReportDialog: React.FC<TrainingReportDialogProps> = ({
  isEdit,
  currentUser,
  initialValues,
  isHiddenScheduleHashId = false,
  ...props
}) => {
  const {getHospitalUser} = useHospitalUsers();
  const {data, isLoading} = useTrainingSchedulesQuery();

  const initialized = useInitialized(!isLoading);

  const handleSubmit = (values: TrainingFormData) => {
    props.actions.resolve({
      ...values,
      trainingScheduleHashId: values.trainingScheduleHashId.value,
      reportHospitalUserHashId: values.reportHospitalUserHashId.value,
      startedAt: dayjs(values.startedAt).toDate(),
      finishedAt: dayjs(values.startedAt)
        .set('h', Number(values.finishedAt.split(':')[0]))
        .set('minutes', Number(values.finishedAt.split(':')[1]))
        .toDate(),
    });
  };
  const initialData = useMemo<TrainingFormData>(() => {
    const schedule = data?.data.find((item) => item.hashId === initialValues?.trainingScheduleHashId);

    if (!isNullish(initialValues)) {
      return {
        trainingScheduleHashId: !isNullish(schedule)
          ? {label: `${schedule.trainingPlan.name} - ${schedule.currentTimes}回目`, value: schedule.hashId}
          : {label: '', value: ''},
        reportHospitalUserHashId: !isNullish(initialValues.reportHospitalUserHashId)
          ? {
              label: UserFormatter.getFullName(getHospitalUser(initialValues.reportHospitalUserHashId)),
              value: initialValues.reportHospitalUserHashId,
            }
          : {label: UserFormatter.getFullName(currentUser), value: currentUser.hashId},
        startedAt: convertDisplaySimpleDate(initialValues.startedAt ?? dayjs().toDate(), 'YYYY-MM-DD HH:mm'),
        finishedAt: !isNullish(initialValues.finishedAt)
          ? convertDisplaySimpleDate(initialValues.finishedAt, 'HH:mm')
          : dayjs().toString(),
        description: initialValues.description ?? '',
        numberOfAttendants: initialValues.numberOfAttendants ?? undefined,
        isCompletedSchedulePlan: initialValues.isCompletedSchedulePlan ?? false,
      };
    } else {
      return {
        trainingScheduleHashId: {label: '', value: ''},
        reportHospitalUserHashId: {label: UserFormatter.getFullName(currentUser), value: currentUser.hashId},
        startedAt: dayjs().toString(),
        finishedAt: dayjs().toString(),
        description: '',
        numberOfAttendants: undefined,
        isCompletedSchedulePlan: false,
      };
    }
  }, [currentUser, data?.data, getHospitalUser, initialValues]);

  if (!initialized) return null;

  return (
    <Formik<TrainingFormData>
      initialValues={initialData}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={handleSubmit}>
      <Dialog
        open={props.open}
        onClose={() => props.actions.reject()}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'sm'}>
        <DialogTitle>{isEdit ? '実施記録を編集' : '研修の実施内容を記録'}</DialogTitle>
        <TrainingReportForm
          isHiddenScheduleHashId={isHiddenScheduleHashId}
          onCancel={props.actions.reject}
          isEdit={isEdit}
        />
      </Dialog>
    </Formik>
  );
};

export const TrainingReportDialog: React.FC<TrainingReportDialogProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <InnerTrainingReportDialog {...props} />
    </Suspense>
  );
};
