import React, {useMemo, useState} from 'react';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {ButtonProps, Grid, makeStyles, TextField} from '@material-ui/core';
import {Dropzone} from '@molecules/Dropzone';

type UploadFormProps = {
  file: File;
  fileName: string;
};

export type UploadDocumentDialogProps = {
  title?: string;
  setDefaultFileName?: boolean;
  acceptFile?: string[];
  children?: React.ReactNode;
} & Omit<DialogProps<UploadFormProps>, 'content'>;

export const UploadDocumentDialog = ({
  title = 'ファイルをアップロード',
  setDefaultFileName = true,
  acceptFile,
  children,
  ...props
}: UploadDocumentDialogProps) => {
  const classes = useStyles();

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const disabledButton = useMemo(() => {
    return file === null || fileName === '';
  }, [file, fileName]);

  const positiveButtonProps: ButtonProps = {
    disabled: disabledButton,
  };

  const dropzoneContent = useMemo(() => {
    if (file === null) return null;
    return <img src={URL.createObjectURL(file)} alt={file.name} />;
  }, [file]);

  // NOTE:Fileが取得できない場合null.txtを返す
  const nullFile = new File(['null'], 'null.txt', {
    type: 'text/plain',
  });

  return (
    <SimpleDialog
      {...props}
      positiveButtonProps={positiveButtonProps}
      actions={{
        resolve: async (res) => {
          props.actions.resolve({file: file ?? nullFile, fileName});
          return res;
        },
        reject: async (res) => {
          props.actions.reject(res);
          return res;
        },
      }}
      title={title}>
      <Grid container className={classes.fields} direction="column">
        <Grid>
          <Dropzone
            accept={acceptFile}
            onDrop={(acceptedFiles) => {
              setFile(acceptedFiles[0]);
              if (setDefaultFileName) setFileName(acceptedFiles[0].name);
            }}>
            {dropzoneContent}
          </Dropzone>
        </Grid>
        <Grid>
          <TextField
            fullWidth
            variant={'outlined'}
            className={classes.inputName}
            label={'ファイル名'}
            name="name"
            size="small"
            value={fileName}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
          />
        </Grid>
        {children || null}
      </Grid>
    </SimpleDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '24px 48px 24px',
  },
  divider: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
  },
  content: {
    padding: '24px 48px 0px',
  },
  fields: {
    '& > div': {
      marginTop: '16px',
    },
  },
  fileInput: {
    visibility: 'hidden',
  },
  inputName: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '0px 48px 24px',
  },
}));
