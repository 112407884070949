import React, {useMemo, useState, useCallback} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {Column, RowAction} from '@molecules/Table/props';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {TableLayout, TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useDebounceCallback} from '@front-libs/core';
import qs from 'qs';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  copyInspection,
  FetchInspectionsParams,
  updateInspection,
  useFetchInspectionCountsQuery,
  useFetchInspectionsQuery,
} from '@modules/inspections/api';
import {InspectionStatus, InspectionTypeMap} from '@modules/inspections/enum';
import {StatusSelector} from '@Apps/InspectionSetting/InspectionList/StatusSelector';
import {InspectionNameColumn} from '@Apps/InspectionSetting/InspectionList/InspectionNameColumn';
import {InspectionListElement, InspectionSettingListElement} from '@Apps/InspectionSetting/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  CopyInspectionDialog,
  CopyInspectionDialogProps,
  CopyInspectionDialogResult,
} from '@molecules/Dialogs/CopyInspectionDialog';
import {getQueryOrderKey, pickFirstQuery} from '@front-libs/helpers';
import {formatRFC3339Date} from '@front-libs/helpers';
import {MessageDialog} from '@components/molecules/Dialogs/MessageDialog';
import {openSnackBar} from '@components/molecules/SnackBar';
import {SearchBar} from './SearchBar';
import {InspectionIndex} from '@modules/inspections/types';
import {useAtom} from 'jotai';
import {pageSizeAtom, searchNameAtom} from './states';
import {Table} from '@molecules/Table';
import {useChangeToDraft} from '@modules/inspection/hooks';
import {styled} from '@material-ui/styles';
import {useInspectionType} from '@Apps/InspectionResultList/pc/InternalInspection/states/states';
import {InspectionUpdatedByColumn} from '../common/InspectionUpdatedByColumn';
import {InspectionProductsByColumn} from '../common/InspectionProductsByColumn';
import {EditProductsDialog, EditProductsDialogProps} from '@Apps/Inspection/ProductsForm/EditProductsDialog';
import {InspectionPeriodProduct} from '@modules/inspection_setting/types';
import {updateInspectionPeriods} from '@modules/inspection_setting/api';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {InspectionSettingDialog, InspectionSettingDialogProps} from './Dialogs/InspectionSettingDialog';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';

const SIDEBAR_WIDTH = 200;
/**
 *
 *
 * @param {(TableLayoutResult)} tableLayout
 * @return {*}
 */
const useTableColumns = (
  tableLayout: TableLayoutResult | undefined,
  handleClickEditProducts: (_e: React.MouseEvent, data: InspectionSettingListElement) => Promise<void>,
  handleClickRow: (_e: React.MouseEvent, data: InspectionListElement) => void
) => {
  return useMemo(() => {
    if (!tableLayout) return;
    const tableColumn = Object.assign<Column<InspectionListElement>[], TableLayout[]>([], tableLayout.currentLayout);
    return tableColumn.map<Column<InspectionListElement>>((item) => {
      if (item.field === 'name') {
        item.render = (rowData: InspectionListElement) => {
          return <InspectionNameColumn rowData={rowData} handleClickRow={handleClickRow} />;
        };
      }
      if (item.field === 'publishedAt') {
        // MaterialTableのソートを使うと、空文字が先頭に来てしまうので無効化する
        item.customSort = (_data1, _data2) => {
          return 1;
        };
      }
      if (item.field === 'inspectionProducts') {
        item.render = (rowData: InspectionSettingListElement) => {
          const inspectionProducts = rowData.inspectionProducts ?? [];
          return (
            <InspectionProductsByColumn
              inspectionProduct={inspectionProducts}
              handleClickEditProducts={(e) => handleClickEditProducts(e, rowData)}
            />
          );
        };
      }
      if (item.field === 'updatedBy') {
        item.sorting = false;
        item.render = (rowData: InspectionListElement) => {
          return <InspectionUpdatedByColumn updatedAt={rowData.updatedAt} updatedBy={rowData.updatedBy} />;
        };
      }
      return item;
    });
  }, [tableLayout, handleClickEditProducts, handleClickRow]);
};

/**
 * 点検表削除
 *
 * @param {string} hospitalHashId
 * @param {InspectionIndex} inspection
 * @return Promise<boolean>
 */
const disableInspection = async (hospitalHashId: string, inspection: InspectionIndex): Promise<boolean> => {
  try {
    await dialogHandler.open(MessageDialog, {
      title: `点検表「${inspection.name}」を削除`,
      content: (
        <span>
          「<FontBoldSpan>{inspection.name}</FontBoldSpan>
          」の点検表を削除しようとしています。
          <br />
          この操作は元に戻せません。
          <br />
          ※点検予定は手動での削除が必要です。
        </span>
      ),
      positiveButtonLabel: '削除',
      warning: true,
    });
  } catch (_e) {
    return false;
  }

  try {
    await updateInspection(hospitalHashId, inspection.hashId, {
      status: 'disabled',
    });
    openSnackBar('削除しました', 'left', 'bottom', 'info');

    return true;
  } catch (e: unknown) {
    openSnackBar(`エラーが発生しました: ${e}`, 'left', 'bottom', 'error');

    return false;
  }
};

const FontBoldSpan = styled('span')({
  fontWeight: 700,
});
const PageDescriptionGrid = styled(Grid)({
  margin: 'auto 0px',
  flex: 1,
});
const PaginationContainerGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
});
const EmptyGrid = styled(Grid)({
  flex: 1,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      flexWrap: 'nowrap',
    },
    sideBar: {
      flex: `0 0 ${SIDEBAR_WIDTH}px`,
      marginRight: '32px',
    },
    main: {
      flex: 1,
      height: '100%',
    },
  })
);

/**
 * 検査リスト
 * @returns
 */
export const InspectionList: React.VFC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const {myInfo} = useMyInfo();
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);

  const [tableLayout] = useTableLayout('inspectionList');

  const [page, setPage] = useState<number>(Number(queryParams.get('page')) || 1);
  const [orderKey, setOrderKey] = useState<string | null>(pickFirstQuery(queryParams.get('order')) ?? null);

  const [type] = useInspectionType();
  const [selectedStatus, setSelectedStatus] = React.useState<InspectionStatus | null>(
    (queryParams.get('status') as InspectionStatus) || null
  );

  const params = useMemo(() => {
    const _p: FetchInspectionsParams = {
      page: page - 1,
      perPage: pageSize,
    };

    if (orderKey) {
      _p.order = orderKey;
    }

    if (searchName) {
      _p.name = searchName;
    }

    _p.type = type === 'daily' ? 'pre_use,in_use,post_use' : type;

    _p.statuses = selectedStatus ?? 'available,draft';

    _p.isAssigned = true;

    navigate({
      search: qs.stringify(
        {..._p, statuses: undefined, status: selectedStatus || undefined, page: (_p?.page || 0) + 1},
        {arrayFormat: 'brackets'}
      ),
    });

    // convert order fields for API
    if (orderKey) {
      _p.order = getQueryOrderKey(orderKey);
    }

    return _p;
  }, [page, pageSize, orderKey, searchName, type, selectedStatus, navigate]);

  const query = useFetchInspectionsQuery(myInfo.hospitalHashId, params);
  const inspectionCountsQuery = useFetchInspectionCountsQuery(myInfo.hospitalHashId, params);

  const handleClickRow = useCallback(
    (event?: React.MouseEvent, rowData?: InspectionListElement) => {
      navigate(`/inspections/${rowData?.hashId}`);
    },
    [navigate]
  );

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  }, []);

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [page, pageSize, query.totalCount]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrderKey(null);
      } else {
        setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout?.currentLayout[columnIndex].field)}`);
      }
    },
    [tableLayout?.currentLayout]
  );

  const handleSelectStatus = useCallback((status: InspectionStatus | null) => {
    setSelectedStatus(status);
    setPage(1);
  }, []);

  const handleChangeSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
    },
    500,
    []
  );

  const handleClickCopy = useCallback(
    async (e: React.MouseEvent, data: InspectionListElement) => {
      const inspection = (query.data ?? []).find((i) => i.hashId === data.hashId);
      if (inspection === undefined) {
        return;
      }

      try {
        // eslint-disable-next-line no-shadow
        const {type, name, shouldMigrateProducts} = await dialogHandler.open<
          CopyInspectionDialogProps,
          CopyInspectionDialogResult
        >(CopyInspectionDialog, {
          defaultName: `${inspection.name}のコピー`,
        });

        await copyInspection(myInfo.hospitalHashId, inspection.hashId, {
          type: type,
          name: name,
          migrateProducts: shouldMigrateProducts,
        });

        query.refetch();
      } catch (_e) {
        console.error(_e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.data]
  );

  const changeToDraft = useChangeToDraft();

  const handleClickChangeToDraft = useCallback(
    async (e: React.MouseEvent, data: InspectionListElement) => {
      const inspection = (query.data ?? []).find((i) => i.hashId === data.hashId);
      if (inspection === undefined) {
        return;
      }

      try {
        const updated = await changeToDraft(myInfo.hospitalHashId, inspection);
        if (updated) {
          query.refetch();
          openSnackBar('下書きに戻しました');
        }
      } catch (_e: unknown) {
        console.error(_e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.data, myInfo]
  );

  const handleClickDelete = useCallback(
    async (_e: React.MouseEvent, data: InspectionListElement) => {
      const inspection = (query.data ?? []).find((i) => i.hashId === data.hashId);
      if (inspection === undefined) return;

      const updated = await disableInspection(myInfo.hospitalHashId, inspection);
      if (updated) {
        query.refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myInfo.hospitalHashId, query.data]
  );

  const listData = useMemo<InspectionListElement[]>(() => {
    return (query.data ?? []).map((i) => ({
      hashId: i.hashId,
      name: {
        name: i.name,
        status: i.status,
      },
      type: InspectionTypeMap[i?.type]?.label ?? '',
      createdAt: formatRFC3339Date(i.createdAt, 'YYYY/MM/DD HH:mm') ?? '',
      updatedAt: formatRFC3339Date(i.updatedAt, 'YYYY/MM/DD HH:mm') ?? '',
      publishedAt:
        i.status === 'available' && i.publishedAt ? formatRFC3339Date(i.publishedAt, 'YYYY/MM/DD HH:mm') ?? '' : 'ー',
      updatedBy: i.updatedBy,
      inspectionProducts: i.inspectionProducts,
    }));
  }, [query.data]);

  const inspectionCounts = useMemo(() => {
    return {
      available: inspectionCountsQuery.available,
      draft: inspectionCountsQuery.draft,
      unassigned: inspectionCountsQuery.unassigned,
    };
  }, [inspectionCountsQuery.available, inspectionCountsQuery.draft, inspectionCountsQuery.unassigned]);

  const handleClickInspectionSetting = useCallback(
    async (hashId: string) => {
      try {
        await dialogHandler.open<InspectionSettingDialogProps>(InspectionSettingDialog, {});
        navigate(`/inspections/${hashId}`);
      } catch (e: unknown) {
        if (e) {
          console.error(e);
        }
      }
    },
    [navigate]
  );

  const handleClickEditProducts = useCallback(
    async (_e: React.MouseEvent, data: InspectionSettingListElement) => {
      const inspection = (query.data ?? []).find((i) => i.hashId === data.hashId);
      if (inspection === undefined) return;

      const selectedProductsValues = data.inspectionProducts?.map((item) => item.wholeProduct) ?? [];

      try {
        const products = await dialogHandler.open<EditProductsDialogProps, InspectionPeriodProduct[]>(
          EditProductsDialog,
          {
            hospitalHashId: myInfo.hospitalHashId,
            inspectionHashId: data.hashId,
            inspectionType: inspection.type,
            defaultValues: selectedProductsValues,
          }
        );

        const additionalProducts = products.filter(
          (item) => selectedProductsValues && !selectedProductsValues.some((i) => i.hashId === item.hashId)
        );

        const deletedProducts = selectedProductsValues
          ? selectedProductsValues.filter((item) => !products.some((i) => i.hashId === item.hashId))
          : [];

        await updateInspectionPeriods(myInfo.hospitalHashId, {
          inspectionHashId: data.hashId,
          addWholeProductHashIds: additionalProducts.map((i) => i.hashId),
          deleteWholeProductHashIds: deletedProducts.map((i) => i.hashId),
        });

        if (additionalProducts.length > 0 || deletedProducts.length > 0) {
          openSnackBar('対象機種を更新しました', 'center', 'top', 'success');

          // 定期点検の場合ダイアログ表示
          if (inspection.type === 'periodic') {
            handleClickInspectionSetting(data.hashId);
          }
        }

        query.refetch();
        inspectionCountsQuery.refetch();
      } catch (e: unknown) {
        if (e) {
          console.error(e);
          openSnackBar(`機種追加に失敗しました: ${e}`, 'center', 'top', 'error');
        }
      }
    },
    [handleClickInspectionSetting, inspectionCountsQuery, myInfo.hospitalHashId, query]
  );
  const serializedTableColumn = useTableColumns(tableLayout, handleClickEditProducts, handleClickRow);

  /** マウスオーバーのボタンを定義 */
  const rowAction: RowAction<InspectionListElement>[] = useMemo(
    () => [
      {
        type: 'button',
        label: '下書きに戻す',
        onClick: handleClickChangeToDraft,
        isHidden: (data: InspectionListElement) => data.name.status !== 'available',
      },
      {
        type: 'button',
        label: '複製',
        onClick: handleClickCopy,
      },
      {
        type: 'button',
        label: '削除',
        onClick: handleClickDelete,
      },
    ],
    [handleClickChangeToDraft, handleClickCopy, handleClickDelete]
  );

  if (!serializedTableColumn) return null;
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.sideBar}>
        <StatusSelector selectedStatus={selectedStatus} counts={inspectionCounts} onSelectStatus={handleSelectStatus} />
      </Grid>
      <TableViewLayout className={classes.main}>
        <TableViewLayout.Header>
          <SearchBar
            defaultSearchName={searchName ?? ''}
            defaultType={null}
            onChangeSearchName={handleChangeSearchName}
          />
        </TableViewLayout.Header>
        <TableViewLayout.Body>
          <Table<InspectionListElement>
            stickyHeader={true}
            showSelection={false}
            isLoading={query.isLoading}
            columns={serializedTableColumn}
            rowActions={rowAction}
            data={listData}
            onOrderChange={handleOrderChange}
            tableSize="small"
          />
        </TableViewLayout.Body>
        <TableViewLayout.Footer container justifyContent="space-between">
          <PageDescriptionGrid item>
            {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
          </PageDescriptionGrid>
          <PaginationContainerGrid item>
            <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
            <DisplayNumberSelect
              pageSize={pageSize}
              update={(selectNum) => {
                setPageSize(selectNum);
                setPage(1);
              }}
            />
          </PaginationContainerGrid>
          <EmptyGrid />
        </TableViewLayout.Footer>
      </TableViewLayout>
    </Grid>
  );
};
