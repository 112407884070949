import React, {HTMLAttributes, ComponentProps} from 'react';
import {styled, Grid} from '@material-ui/core';

type Props = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

type SubProps = ComponentProps<typeof Grid> & {children: React.ReactNode};

export const TableViewLayout: React.FC<Props> & {
  Header: React.FC<SubProps>;
  Body: React.FC<SubProps>;
  Footer: React.FC<SubProps>;
} = ({children, ...rest}) => <Container {...rest}>{children}</Container>;

TableViewLayout.Header = ({children, ...props}) => <HeaderContainer {...props}>{children}</HeaderContainer>;

TableViewLayout.Body = ({children, ...props}) => <BodyContainer {...props}>{children}</BodyContainer>;

TableViewLayout.Footer = ({children, ...props}) => <FooterContainer {...props}>{children}</FooterContainer>;

const Container = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const HeaderContainer = styled(Grid)({
  marginBottom: '16px',
});

const BodyContainer = styled(Grid)({
  minHeight: 0,
  flex: 1,
  overflow: 'hidden',
  '& .MuiPaper-root': {
    height: '100%',
  }
});

const FooterContainer = styled(Grid)({
  marginTop: '16px',
});
