import {atomWithReset} from 'jotai/utils';
import {atom} from 'jotai';
import {InspectionPeriodProductWithCount} from '@modules/inspection_setting/types';
import _ from 'lodash';

export type ProductNameColumnProps = {
  name?: string;
  displayName?: string;
  assignedInspection: boolean;
};
export type ProductElement = InspectionPeriodProductWithCount & ProductNameColumnProps;

export const searchWordAtom = atomWithReset<string>('');
export const rootCategoryAtom = atomWithReset<string | null>(null);
export const narrowCategoryAtom = atomWithReset<string | null>(null);

// テーブルで表示・非表示問わず選択されているProduct
export const selectedProductMapAtom = atom<{[key: string]: ProductElement}>({});

export const selectedProductsAtom = atom(
  (get) => _.values(get(selectedProductMapAtom)),
  (_get, set, values: ProductElement[]) => {
    set(selectedProductMapAtom, _.chain(values).keyBy('hashId').value());
  }
);

// productが現在のテーブルで表示されているか保持する
const _isProductInTableAtom = atom<{[key: string]: boolean}>({});

// 前回のテーブル更新時に選択されているか保持する
const _isSelectedInPreviousAtom = atom<{[key: string]: boolean}>({});

export const updateTableElementsAtom = atom(null, (_get, set, allElements: ProductElement[]) => {
  set(
    _isProductInTableAtom,
    // 配列から hashID => boolean のマップに変換する
    _.chain(allElements)
      .keyBy('hashId')
      .mapValues(() => true)
      .value()
  );
});

export const updateSelectedElementsAtom = atom(null, (get, set, selected: ProductElement[]) => {
  const isProductInTable = get(_isProductInTableAtom);

  const newSelectedProductMap = {
    ...get(selectedProductMapAtom),
  };

  const isSelectedInCurrent: {[key: string]: boolean} = {};

  selected.forEach((e) => {
    const id = e.hashId;
    isSelectedInCurrent[id] = true;
    newSelectedProductMap[id] = e;
  });

  _.forEach(newSelectedProductMap, (elem, id) => {
    // 現在のテーブルに表示されている かつ 現在選択されていない場合
    if (isProductInTable[id] && !isSelectedInCurrent[id]) {
      delete newSelectedProductMap[id];
    }
  });

  set(_isSelectedInPreviousAtom, isSelectedInCurrent);
  set(selectedProductMapAtom, newSelectedProductMap);
});

export const removeSelectedProduct = atom(null, (get, set, hashId: string) => {
  const selectedProductMap = {
    ...get(selectedProductMapAtom),
  };

  delete selectedProductMap[hashId];

  set(selectedProductMapAtom, selectedProductMap);
});
