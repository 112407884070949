import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import React, {useCallback} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {yup} from '@front-libs/core';
import {RequiredLabel} from '@molecules/FormRequiredLabel';

const HospitalWardForm: React.VFC<DialogProps> = (props) => {
  const {submitForm, values, isValid} = useFormikContext();
  const handleClickResist = useCallback(() => {
    submitForm();
    props.actions.resolve(values);
  }, [props.actions, submitForm, values]);

  return (
    <Dialog
      open={props.open}
      onClose={props.actions.reject}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}>
      <DialogTitle>大エリアを新規追加</DialogTitle>
      <DialogContent>
        <Grid container>
          <Form style={{width: '100%'}}>
            <div style={{marginBottom: '16px'}}>
              <RequiredLabel>大エリア名</RequiredLabel>
              <TextField placeholder="東棟" name="name" size={'small'} fullWidth />
            </div>
          </Form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={handleClickResist}>
          作成
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NewRegistrationDialog: React.VFC<DialogProps> = (props) => {
  const validationSchema = yup.object({
    name: yup.string().required(),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (res: any) => {
    props.actions.resolve(res);
  };

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={validationSchema} isInitialValid={false}>
      <HospitalWardForm {...props} />
    </Formik>
  );
};
