import React from 'react';
import {useParams} from 'react-router-dom';
// FIXME 時期を見てfront-libに移行したい
import {useInspection, useInspectionResult} from '../pc/common/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@components/molecules/Loading';
import {EditInspectionResult} from './EditInspectionResult';
import {ViewInspectionResult} from './ViewInspectionResult';

/**
 * InspectionResultコンポーネントは、検査結果の表示と編集のためのUIを提供します。
 * 検査と検査結果のデータはカスタムフックを通じて取得されます。
 * @returns 検査結果の表示または編集用のコンポーネント
 */
export const InspectionResult = () => {
  const {inspectionHashId, inspectionResultHashId} = useParams<{
    inspectionHashId?: string;
    inspectionResultHashId?: string;
  }>();

  const {myInfo} = useMyInfo();
  const {data: inspection, isLoading: isInspectionLoading} = useInspection(
    myInfo.hospitalHashId,
    inspectionHashId || ''
  );

  const {data: inspectionResult, isLoading: isInspectionResultLoading} = useInspectionResult(
    myInfo.hospitalHashId,
    inspectionHashId || '',
    inspectionResultHashId || '',
    {noCache: true}
  );

  // ローディング状態のチェック
  if (isInspectionLoading || isInspectionResultLoading) {
    return <InnerLoading />;
  }

  // データの存在を確認
  if (!inspection || !inspectionResult) {
    return null;
  }

  const InspectionComponent = inspectionResult.status === 'completed' ? ViewInspectionResult : EditInspectionResult;

  return (
    <InspectionComponent
      inspection={inspection}
      inspectionTable={inspection.table}
      inspectionResult={inspectionResult}
    />
  );
};
