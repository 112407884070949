import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import {InnerLoading} from '@components/molecules/Loading';
import {useFetchRentalCapabilityNarrowCategoryQuery} from '@modules/dashboard/api';
import {useUpdateDashboardSubject, UpdatePaperType} from '../hooks';
import {useHospitalCategoryTreesQuery} from '@modules/categories/hooks/useCategoryTrees';
import {PercentBadge} from '@components/atoms/PercentBadge';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {RentalCapabilityNarrowCategory, RentalCapabilityNarrowCategoryItem} from '@modules/dashboard/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowY: 'scroll',
      maxWidth: '100%',
      marginTop: '24px',
      maxHeight: '80%',
      [theme.breakpoints.up('largeDesktop')]: {
        maxWidth: '100%',
        margin: 0,
      },
    },
    tableHead: {
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      backgroundColor: 'white',
    },
    tableHeaderFont: {
      fontSize: 14,
      fontWeight: 'bold',
      padding: '4px 8px 8px 8px',
      color: theme.palette.common.black,
      whiteSpace: 'nowrap',
    },
    tableFont: {
      fontSize: 14,
      color: '#616C82',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    tableRentableNum: {
      fontSize: 24,
      color: '#2A96E8',
      fontWeight: 800,
    },
    tableProductNum: {
      color: '#616C82',
      lineHeight: '24px',
    },
    tableCellRoot: {
      borderBottom: 'unset',
      lineHeight: 1,
      padding: 8,
    },
  })
);

export const RentableTable: React.VFC = () => {
  const {subscribe, unsubscribe} = useUpdateDashboardSubject();
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchRentalCapabilityNarrowCategoryQuery(myInfo.hospitalHashId);
  const {data: tree} = useHospitalCategoryTreesQuery(myInfo.hospitalHashId);
  const classes = useStyles();

  const rows = useMemo(() => {
    return tree.map((item) => {
      const narrowCategoriesArray = item.narrowCategories.map((category) => category.hashId);
      const narrowCategoryItems: RentalCapabilityNarrowCategoryItem[] = [];
      let totalRentableNum = 0;
      let totalProductNum = 0;
      data?.forEach((d) => {
        if (narrowCategoriesArray.includes(d.category.hashId)) {
          narrowCategoryItems.push(d);
          totalRentableNum += d.readyProductAmount;
          totalProductNum += d.totalProductAmount;
        }
      });

      return {
        categoryName: item.rootCategory.name,
        totalRentableNum: totalRentableNum,
        totalProductNum: totalProductNum,
        narrowCategoryItems: narrowCategoryItems.sort(
          (a, b) => b.readyProductAmount / b.totalProductAmount - a.readyProductAmount / a.totalProductAmount
        ),
      };
    });
  }, [data, tree]);

  const onUpdateDashboardSubject = useCallback(
    (paperType: UpdatePaperType) => {
      if (paperType !== 'all' && paperType !== 'rentables') return;
      refetch();
    },
    [refetch]
  );

  useEffect(() => {
    subscribe(onUpdateDashboardSubject);
    return () => unsubscribe(onUpdateDashboardSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableContainer
      className={classes.tableContainer}
      // style={layoutHeight === 1 ? {maxHeight: '180px'} : {maxHeight: '420px'}}
    >
      {isLoading || rows === undefined ? (
        <InnerLoading />
      ) : (
        <Table style={{tableLayout: 'fixed', borderCollapse: 'separate'}}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeaderFont}>大分類</TableCell>
              <TableCell className={classes.tableHeaderFont} align="center">
                貸出可能数/保有数
              </TableCell>
              <TableCell className={classes.tableHeaderFont} align="center">
                貸出率
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => row.totalProductNum !== 0 && <RentableTableRow row={row} key={row.categoryName} />)}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

type RentableTableRowProp = {
  row: {
    categoryName: string;
    totalRentableNum: number;
    totalProductNum: number;
    narrowCategoryItems: RentalCapabilityNarrowCategory;
  };
};

const RentableTableRow: React.VFC<RentableTableRowProp> = (props) => {
  const {row} = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const tableCellClass = useMemo(() => ({root: classes.tableCellRoot}), [classes]);

  return (
    <>
      <TableRow key={row.categoryName}>
        <TableCell classes={tableCellClass} className={classes.tableFont}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.categoryName}
        </TableCell>
        <TableCell classes={tableCellClass} align="center" className={classes.tableFont}>
          <Box component="span" className={classes.tableRentableNum}>
            {row.totalRentableNum}
          </Box>
          <Box component="span" className={classes.tableProductNum}>
            /{row.totalProductNum}台
          </Box>
        </TableCell>
        <TableCell classes={tableCellClass} align="center" className={classes.tableFont}>
          <PercentBadge percent={(1 - row.totalRentableNum / row.totalProductNum) * 100} />
        </TableCell>
      </TableRow>
      {open &&
        row.narrowCategoryItems.map((item) => (
          <TableRow key={item.category.name}>
            <TableCell classes={tableCellClass} className={classes.tableFont} style={{padding: '0px 0px 0px 60px'}}>
              {item.category.name}
            </TableCell>
            <TableCell classes={tableCellClass} align="center" className={classes.tableFont} style={{padding: 0}}>
              <Box component="span" className={classes.tableRentableNum}>
                {item.readyProductAmount}
              </Box>
              <Box component="span" className={classes.tableProductNum}>
                /{item.totalProductAmount}台
              </Box>
            </TableCell>
            <TableCell classes={tableCellClass} align="center" className={classes.tableFont} style={{padding: 0}}>
              <PercentBadge percent={(1 - item.readyProductAmount / item.totalProductAmount) * 100} />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};
