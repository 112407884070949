import React, {useCallback, useMemo} from 'react';
import {Tabs, Tab, Grid, makeStyles} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import qs from 'qs';
import {useSearchParams} from '@front-libs/core';
import {ProductTransactionHistory} from './TracsactionHistory';
import {Files} from './Files';
import {RepairIndex} from '@modules/repairs/types';

const tabs = [
  {
    label: '対応履歴',
    value: 'history',
  },
  {
    label: 'ファイル',
    value: 'file',
  },
];

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px',
    paddingBottom: '82px',
  },
}));

type Props = {
  faultRepair: RepairIndex;
};

export const ProductDetailInfo: React.VFC<Props> = ({faultRepair}) => {
  const hashId = faultRepair?.hashId;
  const classes = useStyles();
  const navigate = useNavigate();
  const {tab = tabs[0].value} = useSearchParams();
  const changeTab = useCallback(
    (_, newTab: string) => {
      navigate({search: qs.stringify({tab: newTab}, {arrayFormat: 'brackets'})});
    },
    [navigate]
  );

  const tabContent = useMemo(() => {
    if (tab === 'history') {
      return <ProductTransactionHistory faultRepair={faultRepair} />;
    } else if (tab === 'file') {
      return <Files faultRepairHashId={hashId ?? ''} />;
    }
  }, [faultRepair, hashId, tab]);

  return (
    <Grid container className={classes.root}>
      <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
        {tabs.map((item, index) => (
          <Tab label={item.label} value={item.value} key={index} />
        ))}
      </Tabs>
      {tabContent}
    </Grid>
  );
};
