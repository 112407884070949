import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    lineHeight: '24px',
  },
  headerInspectionType: {
    width: 80,
    paddingRight: 8,
  },
  headerAmount: {
    width: 40,
    paddingRight: 8,
  },
  buttonGroup: {
    border: 'none',
    height: '32px',
    marginRight: '8px',
    marginLeft: '4px',
  },
  button: {
    width: '34px',
    color: 'rgba(0, 125, 255, 1)',
    border: '1px solid rgba(0, 125, 255, 0.5)',
  },
  selectedButton: {
    backgroundColor: 'rgba(0, 125, 255, 0.08)',
  },
  datePickerButton: {
    marginLeft: '8px',
    minWidth: '112px',
    // fontWeight: 'bold',
    color: 'black',
    height: '32px',
    border: '2px solid #DFE1E5',
    backgroundColor: 'rgba(250, 251, 252, 1)',
  },
  selectCategoryButton: {
    marginLeft: '8px',
    minWidth: '112px',
    height: '32px',
    border: '2px solid #DFE1E5',
    backgroundColor: 'rgba(250, 251, 252, 1)',
  },
  highChartContainer: {
    marginTop: '12px',
  },
  paper: {
    padding: theme.spacing(2),
    height: 'calc(100% - 36px)',
  },
}));
