import React, {useEffect, useMemo, useRef} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {chartOptions, rentalRatioYAxis, hospitalProductYAxis} from './constants';
import {RentalRatioType} from '../types';
import {
  formatPercentage,
  newAverageRentalRatioSeries,
  newDailyAxisCategories,
  newHospitalProductSeries,
  newMaxRentalRatioSeries,
  newMonthlyAxisCategories,
  newWeeklyAxisCategories,
  newYearlyAxisCategories,
} from './helper';
import {DailyRentalRatio, RentalRatio} from '@modules/rental_ratio/api';

export type ChartProps = {
  date: Date;
  rentalRatioType: RentalRatioType;
  dailyRentalData?: DailyRentalRatio[];
  weeklyRentalData?: RentalRatio[];
  monthlyRentalData?: RentalRatio[];
  yearlyRentalData?: RentalRatio[];
};

export const Chart: React.FC<ChartProps> = ({
  date,
  rentalRatioType,
  dailyRentalData,
  weeklyRentalData,
  monthlyRentalData,
  yearlyRentalData,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  useEffect(() => {
    const current = chartComponentRef.current;
    current?.chart.reflow();
  }, []);

  const axisCategories = useMemo(() => {
    switch (rentalRatioType) {
      case 'daily':
        return newDailyAxisCategories(24);
      case 'weekly':
        return newWeeklyAxisCategories(date);
      case 'monthly':
        return newMonthlyAxisCategories(date.getFullYear(), date.getMonth() + 1);
      case 'yearly':
        return newYearlyAxisCategories(date.getFullYear());
    }
  }, [date, rentalRatioType]);

  const hospitalProductTransition = useMemo((): number[] => {
    switch (rentalRatioType) {
      case 'daily':
        return dailyRentalData?.map((d) => d.numberOfDevice) ?? [0];
      case 'weekly':
        return weeklyRentalData?.map((d) => d.numberOfDevice) ?? [0];
      case 'monthly':
        return monthlyRentalData?.map((d) => d.numberOfDevice) ?? [0];
      case 'yearly':
        return yearlyRentalData?.map((d) => d.numberOfDevice) ?? [0];
      default:
        return [0];
    }
  }, [dailyRentalData, monthlyRentalData, rentalRatioType, weeklyRentalData, yearlyRentalData]);

  const averageRentalRatioTransition = useMemo((): number[] => {
    switch (rentalRatioType) {
      case 'daily':
        return dailyRentalData?.map((d) => formatPercentage(d.ratio)) ?? [0];
      case 'weekly':
        return weeklyRentalData?.map((d) => formatPercentage(d.averageRatio)) ?? [0];
      case 'monthly':
        return monthlyRentalData?.map((d) => formatPercentage(d.averageRatio)) ?? [0];
      case 'yearly':
        return yearlyRentalData?.map((d) => formatPercentage(d.averageRatio)) ?? [0];
      default:
        return [0];
    }
  }, [dailyRentalData, monthlyRentalData, rentalRatioType, weeklyRentalData, yearlyRentalData]);

  const averageMaxRatioTransition = useMemo((): number[] => {
    switch (rentalRatioType) {
      case 'weekly':
        return weeklyRentalData?.map((d) => formatPercentage(d.maxRatio)) ?? [0];
      case 'monthly':
        return monthlyRentalData?.map((d) => formatPercentage(d.maxRatio)) ?? [0];
      case 'yearly':
        return yearlyRentalData?.map((d) => formatPercentage(d.maxRatio)) ?? [0];
      default:
        return [0];
    }
  }, [monthlyRentalData, rentalRatioType, weeklyRentalData, yearlyRentalData]);

  const newSeries = useMemo(() => {
    if (rentalRatioType === 'daily') {
      return [
        newHospitalProductSeries(hospitalProductTransition),
        newAverageRentalRatioSeries(averageRentalRatioTransition),
      ];
    } else {
      return [
        newHospitalProductSeries(hospitalProductTransition),
        newAverageRentalRatioSeries(averageRentalRatioTransition),
        newMaxRentalRatioSeries(averageMaxRatioTransition),
      ];
    }
  }, [averageMaxRatioTransition, averageRentalRatioTransition, hospitalProductTransition, rentalRatioType]);

  const options = useMemo(
    () => ({
      ...chartOptions,
      xAxis: [
        {
          categories: axisCategories,
          crosshair: true,
        },
      ],
      yAxis: [rentalRatioYAxis, hospitalProductYAxis],
      series: newSeries,
      credits: {
        enabled: false,
      },
    }),
    [axisCategories, newSeries]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
};
