import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {Sidebar} from '@components/organisms/Sidebar';
import {Form, Formik, useFormikContext} from 'formik';
import {InnerLoading} from '@molecules/Loading';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {withSuspense} from '@front-libs/core';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {Inspection} from '@modules/inspection/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateHospitalSettings, useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {
  inspectionTypeTokAllowApplyPreviousInspectionResultSettings,
  allowTypeStatusKey,
  allowUpdateCompletedInspectionResultStatusKey,
  automationCreateStatusKey,
  HospitalSettingKey,
  UpdateHospitalSettingsParams,
} from '@modules/hospital_settings/types';
import {Checkbox} from '@atoms/Checkbox';
import {openSnackBar} from '@molecules/SnackBar';
import {InspectionTypeOptions} from '@modules/inspections/enum';
import {TextField} from '@molecules/Formik/fields';
import {extractJointingPostUseInspectionSettings} from '@modules/hospital_settings/hooks';

const applyPreviousInspectionResultTypeOptions = [
  {
    label: '日常点検',
    value: 'daily',
  },
  ...InspectionTypeOptions,
] as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: `40px`,
    },
    formHelperText: {
      fontSize: 14,
      color: theme.palette.grey[600],
    },
    link: {
      color: theme.palette.secondary.dark,
      textDecoration: 'none',
    },
  })
);

export const _SettingsHospitalInspection: React.VFC = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <InspectionContainer>
        <InspectionForm />
      </InspectionContainer>
    </Grid>
  );
};

const InspectionContainer: React.FC = ({children}) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);

  const initialData: Inspection | undefined = useMemo(() => {
    if (isLoading || !data) return undefined;

    const jointingPostUseInspectionSetting = extractJointingPostUseInspectionSettings(data);

    return {
      hashId: myInfo.hospitalHashId,
      allowTypeStatus: data?.data?.find((x) => x.key === allowTypeStatusKey)?.value === 'true',
      automationCreateStatus: data?.data?.find((x) => x.key === automationCreateStatusKey)?.value === 'true',
      allowApplyPreviousInspectionResultDaily:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.daily.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultPreUse:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.pre_use.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultInUse:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.in_use.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultPostUse:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.post_use.key)
          ?.value === 'true',
      allowApplyPreviousInspectionResultPeriodic:
        data?.data?.find((x) => x.key === inspectionTypeTokAllowApplyPreviousInspectionResultSettings.periodic.key)
          ?.value === 'true',
      allowUpdateCompletedInspectionResult:
        data?.data?.find((x) => x.key === allowUpdateCompletedInspectionResultStatusKey)?.value === 'true',
      allowJointingPostUseInspectionResult: jointingPostUseInspectionSetting.allowed,
      periodForJointingPostUseInspectionResult: jointingPostUseInspectionSetting.period,
    };
  }, [data, isLoading, myInfo.hospitalHashId]);

  if (isLoading || initialData === undefined) {
    return <InnerLoading />;
  }

  const handleSubmit = async (res: Inspection) => {
    const keys: (keyof Inspection)[] = Object.keys(initialData) as (keyof Inspection)[];
    const updatedKeys = keys.filter((item: keyof Inspection): item is keyof Omit<Inspection, 'hashId'> => {
      return initialData[item] !== res[item];
    });

    try {
      const updatedData: UpdateHospitalSettingsParams[] = updatedKeys.map((key: string) => {
        return {
          hospitalHashId: res.hashId,
          key: key.replace(/[A-Z]/g, (s) => `_${s.toLowerCase()}`) as HospitalSettingKey,
          value: `${res[key as keyof Inspection]}`,
        };
      });
      await Promise.all(
        updatedData.map((d) => {
          return updateHospitalSettings(res.hashId, d);
        })
      );
      await refetch();
      openSnackBar('点検の設定を更新しました');
    } catch (error) {
      openSnackBar('点検の設定の更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  return (
    <Formik initialValues={initialData} onSubmit={handleSubmit} enableReinitialize={true}>
      {children}
    </Formik>
  );
};

const InspectionForm: React.VFC = () => {
  const classes = useStyles();
  const templateClasses = useSettingsContentTemplate();
  const {values, setFieldValue} = useFormikContext<Inspection>();

  return (
    <Form className={templateClasses.form}>
      <Grid container className={templateClasses.grid}>
        <Grid item className={templateClasses.sideBar}>
          <Sidebar />
        </Grid>
        <Grid item className={templateClasses.content}>
          <Grid container>
            <Grid item>
              <Typography variant={'h5'} className={templateClasses.pageTitle}>
                点検
              </Typography>
              <p>点検に関するユーザー共通設定を管理します。</p>
            </Grid>
            <div className={templateClasses.flex} />
          </Grid>
          <Divider variant="middle" className={templateClasses.divider} />
          <Grid container>
            <Grid item>
              <Typography variant={'h6'} className={templateClasses.pageSubTitle1}>
                セットアップ
              </Typography>
              <FormControl component="fieldset" className={classes.formControl}>
                <Typography className={templateClasses.pageSubTitle2}>
                  <Link to="/inspection/setting?tab=inspections" className={classes.link}>
                    点検表フォーマットの設定
                  </Link>
                </Typography>
                <FormHelperText className={classes.formHelperText}>
                  点検表フォーマットに関するユーザー共通設定を管理します。
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{marginTop: 44}}>
            <Grid item>
              <Typography variant={'h6'} className={templateClasses.pageSubTitle1}>
                点検の一括完了
              </Typography>
              <p style={{marginBottom: 0}}>点検タイプ毎に未実施点検の一括完了を有効化します</p>
              <FormControl component="fieldset" className={classes.formControl} style={{marginTop: 12}}>
                <FormGroup>
                  {applyPreviousInspectionResultTypeOptions.map(({label, value}) => {
                    const {field} = inspectionTypeTokAllowApplyPreviousInspectionResultSettings[value];
                    // 日常点検の場合はそれぞれどれかがfalseの場合は強制的にfalseにする
                    const disabled =
                      value === 'daily' &&
                      // eslint-disable-next-line no-shadow
                      (['pre_use', 'in_use', 'post_use'] as const).some((value) => {
                        // eslint-disable-next-line no-shadow
                        const {field} = inspectionTypeTokAllowApplyPreviousInspectionResultSettings[value];

                        return values[field] === false;
                      });

                    return (
                      <FormControlLabel
                        data-testid={`inspection-type-to-allow-apply-previous-inspection-result-settings-${value}`}
                        key={value}
                        disabled={disabled}
                        checked={values[field]}
                        onChange={() => setFieldValue(field, !values[field])}
                        control={<Checkbox color="secondary" disableRipple={false} />}
                        label={label}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid style={{marginTop: 44}}>
            <Grid item>
              <Typography variant={'h6'} className={templateClasses.pageSubTitle1}>
                機能の有効化
              </Typography>
              <FormControl component="fieldset" className={classes.formControl} style={{marginTop: 24}}>
                <FormGroup>
                  <FormControlLabel
                    data-testid={'allow-update-completed-inspection-result'}
                    checked={values.allowUpdateCompletedInspectionResult}
                    onChange={() =>
                      setFieldValue(
                        'allowUpdateCompletedInspectionResult',
                        !values.allowUpdateCompletedInspectionResult
                      )
                    }
                    control={<Checkbox color="secondary" disableRipple={false} />}
                    label="完了済みの点検結果の編集を許可"
                  />
                  <FormHelperText className={classes.formHelperText} style={{marginLeft: 32}}>
                    点検完了後に点検結果の編集を可能にします。
                  </FormHelperText>
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{marginTop: 44}}>
            <Grid item>
              <Typography variant={'h6'} className={templateClasses.pageSubTitle1}>
                自動化
              </Typography>
              <FormControl component="fieldset" className={classes.formControl} style={{marginTop: 24}}>
                <FormGroup>
                  <FormControlLabel
                    data-testid={'automation-create-status'}
                    checked={values.automationCreateStatus}
                    onChange={() => setFieldValue('automationCreateStatus', !values.automationCreateStatus)}
                    control={<Checkbox color={'secondary'} disableRipple={false} />}
                    label="返却後に使用後点検予定を自動作成"
                  />
                  <FormHelperText className={classes.formHelperText} style={{marginLeft: 32, marginBottom: 32}}>
                    点検表を紐づけた機器を返却した場合、使用後点検の予定を自動で作成します。
                  </FormHelperText>
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid={'allow-jointing-postuse-inspection-result'}
                      color="secondary"
                      disableRipple={false}
                      checked={values.allowJointingPostUseInspectionResult}
                    />
                  }
                  onChange={useCallback(() => {
                    setFieldValue('allowJointingPostUseInspectionResult', !values.allowJointingPostUseInspectionResult);
                  }, [setFieldValue, values])}
                  label="定期点検完了時に未実施の使用後点検をスキップする"
                />
                <FormHelperText className={classes.formHelperText} style={{marginLeft: 32, marginBottom: 16}}>
                  使用後点検を開始する際に、期間内に予定されている定期点検がある場合は定期点検完了時に使用後点検をスキップします
                </FormHelperText>
                <div style={{width: '150px', marginBottom: 32}}>
                  <TextField
                    data-testid={'period-for-jointing-postuse-inspection-result'}
                    disabled={!values.allowJointingPostUseInspectionResult}
                    name="periodForJointingPostUseInspectionResult"
                    type="number"
                    size="small"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                      endAdornment: <InputAdornment position="end">日以内</InputAdornment>,
                    }}
                  />
                </div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <FormikFormSubmitDrawer />
    </Form>
  );
};

export const SettingsHospitalInspection = withSuspense(_SettingsHospitalInspection, null);
