import {fetchHospitalUsers, HospitalUserParams} from '@modules/hospital_users/api';
import {deleteHospitalUsersByHospitalHashId, upsertHospitalUsers} from './repository';

export const initHospitalUsers = async (hospitalHashId: string) => {
  const NUM_PER_PAGE = 100;
  const params: HospitalUserParams = {
    page: 0,
    perPage: NUM_PER_PAGE,
  };

  const initialData = await fetchHospitalUsers(hospitalHashId, params);
  const totalPage = Math.ceil(initialData.totalCount / NUM_PER_PAGE);
  if (initialData.data !== null) {
    await upsertHospitalUsers(initialData.data);
  }

  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await fetchHospitalUsers(hospitalHashId, params);
    if (data.data !== null) {
      await upsertHospitalUsers(data.data);
    }
  }
};

// 差分データ取得
export const updateHospitalUsers = async (hospitalHashId: string, updatedAt: string) => {
  const NUM_PER_PAGE = 100;
  const params: HospitalUserParams = {
    page: 0,
    perPage: NUM_PER_PAGE,
    updatedAtFrom: updatedAt,
  };
  const updateData = await fetchHospitalUsers(hospitalHashId, params);
  const totalPage = Math.ceil(updateData.totalCount / NUM_PER_PAGE);
  if (updateData.data !== null) {
    await upsertHospitalUsers(updateData.data);
  }

  for (let currentPage = 1; currentPage < totalPage; currentPage++) {
    params.page = currentPage;
    const data = await fetchHospitalUsers(hospitalHashId, params);
    if (updateData.data !== null) {
      await upsertHospitalUsers(data.data);
    }
  }
};

// 病院IDに紐づくデータを削除
export const deleteHospitalUsers = async (hospitalHashId: string) => {
  await deleteHospitalUsersByHospitalHashId(hospitalHashId);
};
