import React from 'react';
import {isIPad13} from 'react-device-detect';
import {Document, Page, pdfjs} from 'react-pdf';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  src: string;
};

export const PDFDisplay: React.FC<Props> = ({src}) => {
  return isIPad13 ? <PDFDisplayForIpad src={src} /> : <PDFDisplayPC src={src} />;
};

const PDFDisplayPC: React.FC<Props> = ({src}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <object width={'100%'} className={classes.pdf} type={'application/pdf'} data={src}>
        object can't be rendered
      </object>
    </div>
  );
};

const PDFDisplayForIpad: React.FC<Props> = ({src}) => {
  const [numPages, setNumPages] = React.useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = (args: any) => {
    const {numPages: page} = args;
    setNumPages(page);
  };

  const renderAllPages = () => {
    return Array.from(new Array(numPages), (el, index) => (
      <Grid container wrap={'nowrap'} direction={'row'} justifyContent="center">
        <Page
          width={1000}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
        />
      </Grid>
    ));
  };

  return (
    <div style={{overflow: 'scroll', height: '100%', width: '100%'}}>
      <Document
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={''}
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}>
        {renderAllPages()}
      </Document>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
  },
  pdf: {
    width: '100%',
    height: '100%',
  },
}));
