import React, {useCallback} from 'react';
import {useAtom, useAtomValue} from 'jotai';
import {DragDropContext} from 'react-beautiful-dnd';
import {makeStyles} from '@material-ui/core/styles';
import {useDnDLogic} from './hooks';
import {RentalDnDSection} from './RentalDnDSection';
import {isTablet} from 'react-device-detect';
import {Header} from './Header';
import {customSequence} from '@modules/hospital_ward_layout_setting/types';
import {useFormikContext} from 'formik';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {Grid} from '@material-ui/core';
import {DEFAULT_ORDER} from './Header/consts';
import {layoutSettingSelectedFloorStatus, layoutSettingSelectedWardStatus, radioAtom, tabAtom} from './states';

type Option = {
  label: string;
  value: string;
};

type FloorOption = {
  label: string;
  value: string;
  floorNumber: string;
  isGroundFloor: boolean;
};

type PositionSettingProps = {
  data: customSequence[];
  wardOptions?: Option[];
  floorOptions?: FloorOption[];
  handleClickCancel: () => Promise<void>;
  resetFlag: boolean;
};

export const PositionSetting = ({
  data,
  wardOptions,
  floorOptions,
  handleClickCancel,
  resetFlag,
}: PositionSettingProps) => {
  const {setFieldValue} = useFormikContext<{Setting: customSequence[]}>();
  const classes = useStyles({isTablet});
  const {sections, handleOnDragEnd} = useDnDLogic(data || [], resetFlag);
  const radioButton = useAtomValue(radioAtom);
  const tab = useAtomValue(tabAtom);
  const [isDnDEnabled, setIsDnDEnabled] = React.useState(true);
  const [selectedWard, setSelectedWard] = useAtom(layoutSettingSelectedWardStatus);
  const [selectedFloor, setSelectedFloor] = useAtom(layoutSettingSelectedFloorStatus);

  React.useEffect(() => {
    if (radioButton === DEFAULT_ORDER) {
      setIsDnDEnabled(false);
    } else {
      setIsDnDEnabled(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flattenNestedArrays = (arrays: any) =>
      [].concat(...arrays.map((item: string) => (Array.isArray(item) ? flattenNestedArrays(item) : item)));
    const flattenedArray = flattenNestedArrays(sections);
    setFieldValue('Setting', flattenedArray);
  }, [radioButton, setFieldValue, data, sections]);

  const handleSelectWard = useCallback(
    (item: MenuItemType) => {
      setSelectedWard({
        label: item.label as string,
        value: item.value,
      });
    },
    [setSelectedWard]
  );

  const handleSelectFloor = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      setSelectedFloor({
        label: item.label as string,
        value: item.value,
        floorNumber: item.floorNumber,
        isGroundFloor: item.isGroundFloor,
      });
    },
    [setSelectedFloor]
  );

  return (
    <>
      <Header handleClickCancel={handleClickCancel} />
      {/* 小エリアの時のみ表示 */}
      {wardOptions !== undefined && tab === 'room' && (
        <Grid container className={classes.menuContainer}>
          <Grid item>
            <PopperMenuButton
              placement="bottom-start"
              buttonProps={{
                variant: 'contained',
                className: classes.buildingMenu,
                disableElevation: true,
              }}
              itemProps={{style: {minWidth: '400px', fontSize: '14px'}}}
              menuItemList={wardOptions}
              onMenuClick={handleSelectWard}>
              {selectedWard.label}
            </PopperMenuButton>
          </Grid>
          {floorOptions !== undefined && selectedFloor !== null && (
            <>
              <Grid item>・</Grid>
              <Grid item>
                <PopperMenuButton
                  placement="bottom-start"
                  buttonProps={{
                    variant: 'contained',
                    className: classes.buildingMenu,
                    disableElevation: true,
                  }}
                  itemProps={{style: {minWidth: '400px', fontSize: '14px'}}}
                  menuItemList={floorOptions}
                  onMenuClick={handleSelectFloor}>
                  {selectedFloor.label}
                </PopperMenuButton>
              </Grid>
            </>
          )}

          <Grid item className={classes.text}>
            {radioButton === DEFAULT_ORDER ? 'の小エリアボタン昇順' : 'の小エリアボタンを並び替える'}
          </Grid>
        </Grid>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className={classes.container}>
          {sections.map((section, sectionIndex) => (
            <RentalDnDSection
              section={section}
              sectionIndex={sectionIndex}
              isDnDEnabled={isDnDEnabled}
              isTablet={isTablet}
              itemCount={data.length}
            />
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

type StyleProps = {
  isTablet: boolean;
};

const useStyles = makeStyles({
  container: (props: StyleProps) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: props.isTablet ? 'center' : 'space-between',
    flexWrap: 'wrap',
    margin: '0px 48px',
  }),
  menuContainer: {
    marginTop: '24px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0px 48px',
  },
  buildingMenu: {
    width: '180px',
    height: '32px',
    backgroundColor: '#FFFFFF',
    border: '2px solid #DFE1E5',
    textOverflow: 'ellipsis',
  },
  text: {
    marginLeft: '8px',
  },
});
