import {dexieDB} from '@indexedDB/database';
import {HospitalLastSyncIndex} from './types';

/**
 * 最終更新日時
 * @param hospitalHashId
 * @returns
 */
export async function getHospitalLastSync(hospitalHashId: string) {
  return await dexieDB?.hospitalLastSync.get(hospitalHashId);
}

/**
 * indexedDBの値を更新する
 * @param hospitalLastSync
 */
export const upsertHospitalLastSync = async (hospitalLastSync: HospitalLastSyncIndex) => {
  await dexieDB?.hospitalLastSync.put(hospitalLastSync);
};

/**
 * 機器一覧
 * @param hospitalHashId
 * @returns
 */
export async function getHospitalProductsArray(hospitalHashId: string) {
  return (await dexieDB?.hospitalProducts.where('hospitalHashId').equals(hospitalHashId).toArray()) || [];
}

/**
 * 点検結果一覧
 * @param hospitalHashId
 * @returns
 */
export async function getInspectionResultsArray(hospitalHashId: string) {
  return (await dexieDB?.inspectionResults.where('hospitalHashId').equals(hospitalHashId).toArray()) || [];
}

/**
 * 点検表
 * @param hospitalHashId
 * @returns
 */
export async function getInspectionsArray(hospitalHashId: string) {
  return (await dexieDB?.inspections.where('hospitalHashId').equals(hospitalHashId).toArray()) || [];
}
/**
 * 利用ユーザ
 * @param hospitalHashId
 * @returns
 */
export async function getMeArray(hospitalHashId: string) {
  return (await dexieDB?.me.where('hospitalHashId').equals(hospitalHashId).toArray()) || [];
}
/**
 * ユーザー
 * @param hospitalHashId
 * @returns
 */
export async function getHospitalUsersArray(hospitalHashId: string) {
  return (await dexieDB?.hospitalUsers.where('hospitalHashId').equals(hospitalHashId).toArray()) || [];
}

/**
 * 点検結果送信
 * @param hospitalHashId
 * @returns
 */
export async function getSubmitInspectionResultsArray(hospitalHashId: string) {
  return (await dexieDB?.submitInspectionResults.where('hospitalHashId').equals(hospitalHashId).toArray()) || [];
}
/**
 * 点検に紐づく製品情報
 * @param hospitalHashId
 * @returns
 */
export async function getWholeProductInspectionProductsArray(hospitalHashId: string) {
  return (await dexieDB?.wholeProductInspectionProducts.where('hospitalHashId').equals(hospitalHashId).toArray()) || [];
}
