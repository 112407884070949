import React from 'react';
import {TextField, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {useAtom, PrimitiveAtom} from 'jotai';
import {Selector, Option} from '@molecules/Selector';
import {Product} from '../../../state';
import {CategoryTrees} from '@modules/categories/hooks/useCategoryTrees';
import {CategoryIndex} from '@modules/categories/types';
import {isNullish} from '@front-libs/helpers';

type Props = {
  productAtom: PrimitiveAtom<Product>;
  index: number;
  categoryTrees: CategoryTrees;
  rootCategories: CategoryIndex[];
  subCategories: CategoryIndex[];

  isSelected: (key: string) => boolean;
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    height: 36,
    background: theme.palette.common.white,
  },
  cell: {
    padding: 8,
    minWidth: 170,
  },
  multiTextField: {
    padding: 8,
    background: theme.palette.common.white,
  },
}));

export const TableFormContents: React.VFC<Props> = ({
  productAtom,
  index,
  handleClick,
  isSelected,
  rootCategories,
  subCategories,
  categoryTrees,
}) => {
  const labelId = `enhanced-table-checkbox-${index}`;
  const classes = useStyles();

  const [product, setProduct] = useAtom(productAtom);
  const isItemSelected = isSelected(product.uuid ?? '');

  return (
    <TableRow hover tabIndex={-1} key={product.uuid}>
      <TableCell padding="checkbox">
        <Checkbox
          onClick={(event) => handleClick(event, product.uuid ?? '')}
          aria-checked={isItemSelected}
          checked={isItemSelected}
          inputProps={{'aria-labelledby': labelId}}
        />
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          key={product.name}
          variant="outlined"
          defaultValue={product.name}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                name: e.target.value,
              };
            });
          }}
        />
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          key={product.displayName}
          defaultValue={product.displayName}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                displayName: e.target.value,
              };
            });
          }}
        />
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <TextField
          InputProps={{
            classes: {
              root: classes.formField,
            },
          }}
          variant="outlined"
          key={product.makerName}
          defaultValue={product.makerName}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                makerName: e.target.value,
              };
            });
          }}
        />
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.rootCategory ?? ''}
          options={rootCategories.map((item) => ({value: item.hashId, label: item.name}))}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                rootCategory: e.value,
                // rootCategoryに紐付いたnarrowCategoryを選択してる場合はクリアする
                narrowCategory: e.value === oldValue.rootCategory ? oldValue.narrowCategory : null,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <Selector
          value={product.narrowCategory}
          options={(isNullish(product.rootCategory)
            ? subCategories
            : categoryTrees.find((t) => t.rootCategory.hashId === product.rootCategory)?.narrowCategories ?? []
          ).map((item) => ({value: item.hashId, label: item.name}))}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              const rootCategory = categoryTrees.find((item) =>
                item.narrowCategories.find((innerItem) => innerItem.hashId === e.value)
              );
              return {
                ...oldValue,
                rootCategory: rootCategory?.rootCategory.hashId ?? '',
                narrowCategory: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </TableCell>
    </TableRow>
  );
};
