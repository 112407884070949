import React, {useCallback} from 'react';
import {Table} from '@molecules/Table';
import {RowAction} from '@molecules/Table/props';
import {useSearchVariables, useTableData, useUpdateTrainingScheduleMutation} from '../hooks';
import {ScheduleTableData} from '.';
import {useNavigate} from 'react-router-dom';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  TrainingScheduleDialog,
  TrainingScheduleDialogProps,
  TrainingScheduleDialogResult,
} from '@organisms/TrainingManagements/TrainingScheduleDialog';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

export const ScheduleTable: React.FC = () => {
  const {mutate} = useUpdateTrainingScheduleMutation();
  const {tableColumns, scheduleData, isLoading} = useTableData();
  const {setOrder} = useSearchVariables();
  const navigate = useNavigate();
  const {isReadOnly} = useMyRole();

  const handleNavigateTrainingReport = useCallback(
    async (_e: React.MouseEvent, data: ScheduleTableData) => {
      navigate('/training_managements/reports', {
        state: {
          trainingScheduleHashId: data.hashId,
        },
      });
    },
    [navigate]
  );

  const handleCompleteStatus = useCallback(
    async (_e: React.MouseEvent, data: ScheduleTableData) => {
      const currentStatus = data.status;

      await dialogHandler.open(MessageDialog, {
        title: 'ステータスの変更',
        content: `研修（予定）「${data.name} - ${data.currentTimes}回目」のステータスを${
          currentStatus === 'done' ? '未完了に戻しますか' : '完了にしますか'
        }？`,
        positiveButtonLabel: currentStatus === 'done' ? '未完了に戻す' : '完了',
      });
      mutate({
        trainingScheduleHashId: data.hashId,
        status: currentStatus === 'done' ? 'incomplete' : 'done',
      });
    },
    [mutate]
  );

  const handleEditSchedule = useCallback(
    async (_e: React.MouseEvent, data: ScheduleTableData) => {
      const result = await dialogHandler.open<TrainingScheduleDialogProps, TrainingScheduleDialogResult>(
        TrainingScheduleDialog,
        {
          isEdit: true,
          // FIXME: data.hashIdとtrainingPlanOptionsのhashIdが一致しない
          sourceTrainingPlanHashId: data.hashId,
          initialValues: {
            trainingPlanHashId: data.hashId,
            trainingPlanName: data.name,
            trainingTypes: undefined,
            wholeProductHashIds: undefined,
            place: data.place,
            startAt: data.scheduleDateRange[0].toString(),
            finishAt: data.scheduleDateRange[1].toString(),
            numberOfTrainees: data.numberOfTrainees,
          },
        }
      );
      mutate({
        ...result,
        trainingScheduleHashId: data.hashId,
        startAt: result.startAt.toString(),
        finishAt: result.finishAt.toString(),
        numberOfTrainees: result.numberOfTrainees?.toString(),
        wholeProductHashIds: result.wholeProductHashIds?.length === 0 ? undefined : result.wholeProductHashIds,
      });
    },
    [mutate]
  );

  const rowsActions: RowAction<ScheduleTableData>[] = [
    {
      type: 'menu',
      label: 'アクション',
      items: isReadOnly
        ? [{label: '実施済み一覧を表示', onClick: handleNavigateTrainingReport}]
        : [
            // FIXME: [研修] セルアクションを随時追加する
            {label: '編集', onClick: handleEditSchedule},
            // {label: '研修実施内容を登録', onClick: () => {}},
            {label: '実施済み一覧を表示', onClick: handleNavigateTrainingReport},
            {label: 'ステータスを変更', onClick: handleCompleteStatus},
          ],
    },
  ];
  return (
    <Table<ScheduleTableData>
      data={scheduleData}
      isLoading={isLoading}
      columns={tableColumns}
      showSelection={false}
      rowActions={rowsActions}
      defaultOrder={{
        fieldId: 'scheduleDateRange',
        direction: 'asc',
      }}
      onOrderChange={(columnIndex, orderDirection) => {
        setOrder(tableColumns[columnIndex].field, orderDirection);
      }}
    />
  );
};
