import {Box, Grid, Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import {ProductStatusIcon} from '@Apps/InspectionResultList/sp/InspectionResultRow/ProductStatusIcon';
import no_image from '@assets/no_image.svg';
import {HospitalProductIndex} from '@modules/hospital_products/types';

/**
 * 病院の製品情報を表示するコンポーネント。
 * 製品の状態アイコン、管理ID、名前、メーカー名を表示します。
 *
 * @param props コンポーネントのプロパティ
 * @param props.hospitalProduct 病院の製品情報を含むオブジェクト。製品の状態、管理ID、表示名、名前、サムネイルURL、メーカー情報が含まれる。
 * @param props.backgroundColor 背景色を指定するオプショナルな文字列。デフォルトは '#F3F4F6'。
 */
export const HospitalProductInfo: React.FC<Props> = (props) => {
  const {hospitalProduct, backgroundColor = '#F3F4F6'} = props;
  const classes = useStyles();
  return (
    <Box className={classes.root} style={{backgroundColor: backgroundColor}}>
      <Box className={classes.productBar}>
        <ProductStatusIcon status={hospitalProduct.status} />
        <Typography style={{marginLeft: '8px'}}>{hospitalProduct.managementId}</Typography>
      </Box>
      <Box className={classes.productContent}>
        {hospitalProduct.thumbnailUrl === '' ? (
          <img src={no_image} alt="サムネイル" width={'80px'} object-fit={'contain'} loading="lazy" decoding="async" />
        ) : (
          <img
            src={hospitalProduct.thumbnailUrl}
            alt="サムネイル"
            height={'80px'}
            decoding="async"
            loading="lazy"
            onError={({currentTarget}) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = no_image;
            }}
          />
        )}
        <Grid container item className={classes.rightContainer}>
          <Typography noWrap style={{fontWeight: 'bold', fontSize: '16px'}}>
            {hospitalProduct.displayName}
          </Typography>
          <Typography noWrap style={{fontSize: '14px'}}>
            {hospitalProduct.name}
          </Typography>
          <Typography noWrap style={{fontSize: '14px'}}>
            {hospitalProduct.maker.name}
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
};

type Props = {
  hospitalProduct: HospitalProductIndex;
  backgroundColor?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  productBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  productContent: {
    display: 'flex',
    overflow: 'hidden',
  },
  rightContainer: {
    width: '100%',
    paddingLeft: '16px',
    flexDirection: 'column',
  },
}));
