import React, {useCallback, useState} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {createInspectionResultFiles, useFetchInspectionResultFilesQuery} from '@modules/inspection_results/api';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {FileList} from '@organisms/Files/FileList';
import {FileIndex} from '@modules/files/types';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type FilesFormProps = {
  inspectionResult: InspectionResultIndex;
  isEditView: boolean;
};

export const FilesForm: React.VFC<FilesFormProps> = (props) => {
  const {inspectionResult, isEditView} = props;
  const [tableLayout] = useTableLayout('fileList');
  const [order, setOrder] = useState<string>();

  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const classes = useStyles();
  const {
    data: files = [],
    refetch,
    isLoading,
  } = useFetchInspectionResultFilesQuery(
    myInfo.hospitalHashId,
    inspectionResult.inspectionHashId,
    inspectionResult.hashId,
    order
  );

  const handleUploadFile = useCallback(
    async (file: FileIndex) => {
      try {
        await createInspectionResultFiles(
          myInfo.hospitalHashId,
          inspectionResult.inspectionHashId,
          inspectionResult.hashId,
          {
            fileHashIds: [file.hashId],
          }
        );
        refetch();
      } catch (err) {
        // empty function
      }
    },
    [inspectionResult.hashId, inspectionResult.inspectionHashId, myInfo.hospitalHashId, refetch]
  );

  const handleUpdateFile = useCallback(async () => {
    refetch();
  }, [refetch]);

  const handleDeleteFile = useCallback(async () => {
    refetch();
  }, [refetch]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout.currentLayout[columnIndex].field)}`);
    },
    [tableLayout.currentLayout]
  );

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid container md={isEditView ? 9 : 12} className={classes.toolBar}>
        <FileList
          files={files}
          fileCategory={'inspection_result'}
          isLoading={isLoading}
          disableEdit={isReadOnly}
          disableUpload={isReadOnly}
          onUploadFile={handleUploadFile}
          onDeleteFile={handleDeleteFile}
          onEditFileName={handleUpdateFile}
          onOrderChange={handleOrderChange}
          searchFileName={!isEditView}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '48px 32px 16px',
      width: '100%',
    },
    toolBar: {
      minWidth: '700px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    fileTableContainer: {
      marginTop: '32px',
      minWidth: '700px',
    },
  })
);
