import React, {useMemo, useRef} from 'react';
import {RepairIndex} from '@modules/repairs/types';
import {Card, Grid, makeStyles, Theme, Tooltip} from '@material-ui/core';
import dayjs from 'dayjs';
import {getSymptomCategoryShortLabel} from '@modules/repairs/constants';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import {useAtomValue} from 'jotai';
import {overdueSetting as overdueSettingAtom} from '../../jotai';
import {isNullish} from '@front-libs/helpers';
import {cardProperties} from './CardProperties';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';

type RepairProps = {
  repairData: RepairIndex;
  currentCardLayout: TableLayout[] | undefined;
};

export const RepairCard: React.VFC<RepairProps> = (props) => {
  const {hospitalUsers} = useHospitalUsers();
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/repairs/${props.repairData.hashId}`);
  };
  const cardFields = (props.currentCardLayout ?? []).map((item) => item.field);
  const productNameKanbanRef = useRef<HTMLDivElement>(null);
  const overdueSetting = useAtomValue(overdueSettingAtom);
  const isOverdue = useMemo(() => {
    if (isNullish(props.repairData.requestForRepairAt)) return false;

    return dayjs(props.repairData.requestForRepairAt).isBefore(
      dayjs().add(0 - overdueSetting.dateNumber, overdueSetting.unit)
    );
  }, [overdueSetting.dateNumber, overdueSetting.unit, props.repairData.requestForRepairAt]);

  const symptomShortLabel = useMemo(() => {
    return getSymptomCategoryShortLabel(props.repairData.symptomCategory ?? undefined);
  }, [props.repairData.symptomCategory]);

  const hideToolTip = useMemo(() => {
    // タイトルが3行未満の時に隠す
    return productNameKanbanRef.current &&
      productNameKanbanRef.current?.offsetHeight == productNameKanbanRef.current?.scrollHeight
      ? true
      : false;
  }, []);

  return (
    <Card className={clsx(classes.root, isOverdue && classes.overdueCard)} onClick={handleClick}>
      <Grid container justifyContent={'space-between'} alignItems={'flex-start'} className={classes.textContent}>
        <Tooltip title={props.repairData.faultHospitalProduct.displayName} disableHoverListener={hideToolTip}>
          <Grid
            item
            className={classes.productName}
            ref={productNameKanbanRef}
            id={props.repairData.faultHospitalProduct.managementId}>
            {props.repairData.faultHospitalProduct.displayName}
          </Grid>
        </Tooltip>
        <Grid item className={classes.causeOfIssueMessage}>
          {symptomShortLabel}
        </Grid>
      </Grid>
      {cardFields.map((item, index) => {
        return (
          <Grid container className={classes.textContent} key={`${item}${index}`}>
            {cardProperties[item]?.render(props.repairData, hospitalUsers) ?? ''}
          </Grid>
        );
      })}
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxSizing: 'border-box',
    minWidth: '264px',
    padding: '8px',
    fontSize: '14px',
  },
  overdueCard: {
    background: '#EBF0F5',
  },
  causeOfIssueMessage: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#42526E',
    color: '#FFF',
    width: '60px',
    borderRadius: '4px',
  },
  productName: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    maxWidth: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    maxHeight: '63px', //3行分
  },
  textContent: {
    marginBottom: '4px',
  },
}));
