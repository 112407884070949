import React, {useState} from 'react';
import {Accordion, Box, makeStyles} from '@material-ui/core';
import {SectionHeader} from './SectionHeader';
import {SectionContent} from './SectionContent';

export const Section: React.FC<SectionProps> = (props) => {
  const {name, children} = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <Box className={classes.root}>
      <Accordion
        expanded={expanded}
        onChange={toggleAccordion}
        style={{margin: '8px 0px', padding: '0px', minHeight: 'none'}}>
        <SectionHeader name={name} expanded={expanded} />
        <SectionContent>{children}</SectionContent>
      </Accordion>
    </Box>
  );
};

type SectionProps = {
  name: string;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
