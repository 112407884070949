import React from 'react';
import {Box} from '@material-ui/core';

type NoDataProps = {
  title: string;
  message: string;
};

export const NoData = ({title, message}: NoDataProps) => (
  <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
    {title && <p style={{display: 'inline-block', fontWeight: 'bold'}}>{title}</p>}
    {message && <p style={{display: 'inline-block'}}>{message}</p>}
  </Box>
);
