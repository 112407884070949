import {UserIndex} from '@modules/hospital_users/types';

export type HospitalWard = {
  hashId: string;
  hospitalHashId: string;
  name: string;
  numberOfRooms: number;
  createdBy: UserIndex;
  createdAt: string;
  updatedBy: UserIndex;
  updatedAt: string;
};

export type HospitalRoom = {
  hashId: string;
  hospitalHashId: string;
  name: string;
  isGroundFloor: boolean;
  floorNumber: number;
  showRentalPlace: boolean;
  hospitalWard: HospitalWard | null;
  createdBy: UserIndex;
  createdAt: string;
  updatedBy: UserIndex;
  updatedAt: string;
};

export const undefinedRentRoom = {label: '未定', value: 'undefined'} as const;
