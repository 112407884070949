import React, {useCallback, useMemo, useState} from 'react';
import {Button, Grid, makeStyles, TextField, Theme, Typography} from '@material-ui/core';
import {Search, Close, ChevronLeft} from '@material-ui/icons';
import {FilterOption} from './types';

type ConditionKeyListProps = {
  title?: string;
  filterOptions: FilterOption[];
  filteredOptionKeys?: string[];
  onClickCondition: (condition: FilterOption) => void;
  onReturnResultBody: () => void;
};

export const ConditionKeyList: React.VFC<ConditionKeyListProps> = (props) => {
  const {
    title = '対象項目の選択',
    filteredOptionKeys = [],
    filterOptions,
    onClickCondition,
    onReturnResultBody,
  } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>('');

  const filteredFilterOption = useMemo(() => {
    return filterOptions.filter((item) => item.label.includes(searchText));
  }, [searchText, filterOptions]);

  const handleChangeSearchText = useCallback((e) => setSearchText(e.target.value), []);

  return (
    <>
      <Grid container className={classes.searchContainer}>
        {filteredOptionKeys.length > 0 && (
          <Grid container>
            <Button color="inherit" className={classes.actionMenu} onClick={onReturnResultBody}>
              <ChevronLeft />
              <span>検索条件を表示</span>
            </Button>
          </Grid>
        )}
        <Typography style={{fontWeight: 'bold', fontSize: '18px'}}>{title}</Typography>
        <TextField
          placeholder={'項目名を検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment:
              searchText === '' ? <Search /> : <Close style={{cursor: 'pointer'}} onClick={() => setSearchText('')} />,
          }}
          className={classes.searchText}
          value={searchText}
          onChange={handleChangeSearchText}
        />
      </Grid>
      <Grid container className={classes.contentContainer}>
        {filteredFilterOption.length > 0 && (
          <Typography style={{fontWeight: 'bold', fontSize: '18px'}}>すべての項目</Typography>
        )}
        {filteredFilterOption
          .filter((item) => !filteredOptionKeys.includes(item.value))
          .map((item, index) => (
            <Button
              fullWidth
              key={index}
              style={{fontSize: '14px', margin: '4px', marginLeft: '-8px', justifyContent: 'flex-start'}}
              onClick={() => {
                setSearchText('');
                onClickCondition(item);
              }}>
              <Typography variant={'button'}>{item.label}</Typography>
            </Button>
          ))}
        {filteredFilterOption.length === 0 && <span>一致する項目がありません</span>}
      </Grid>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 450,
  },
  headerContainer: {
    padding: '32px 24px 0 32px',
  },
  actionMenu: {
    padding: 0,
    marginBottom: '16px',
    marginLeft: '-12px',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  searchContainer: {
    width: '400px',
    padding: '32px 32px 0',
  },
  contentContainer: {
    padding: '32px',
  },
  property: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  searchText: {
    marginTop: '16px',
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
  },
}));
