import {useMemo} from 'react';
import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {HospitalWard, HospitalWardDetail} from './types';

interface StandardListResponse<T> {
  totalCount: number;
  page: number;
  data: T[];
}

export type HospitalWardParams = {
  page?: number;
  perPage?: number;
  name?: string;
  onlyRentableRooms?: boolean;
};

export type CreateHospitalWardParams = {
  name: string;
  groundFloorNumber?: number;
  basementFloorNumber?: number;
};

type UpdateHospitalWardParams = CreateHospitalWardParams;

export const fetchHospitalWard = async (hospitalHashId: string, hospitalWardHashId: string) => {
  const hospitalUserUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/wards/${hospitalWardHashId}`;
  const res = await axios.get<HospitalWard>(hospitalUserUrl);
  return res.data;
};

export const fetchHospitalWards = async (hospitalHashId: string, params: HospitalWardParams) => {
  const hospitalWardsUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/wards`;
  const {data} = await axios.get<StandardListResponse<HospitalWard>>(hospitalWardsUrl, {params});
  return data;
};

export const useFetchHospitalWards = (hospitalHashId: string, params: HospitalWardParams = {page: 0, perPage: 100}) => {
  const query = useQuery([hospitalHashId, params], () => fetchHospitalWards(hospitalHashId, params));
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const createHospitalWard = async (hospitalHashId: string, data: CreateHospitalWardParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/wards`;
  return await axios.post<HospitalWardDetail>(baseUrl, data);
};

export const updateHospitalWard = async (
  hospitalHashId: string,
  hospitalWardHashId: string,
  data: UpdateHospitalWardParams
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/wards/${hospitalWardHashId}`;
  return axios.put<HospitalWardDetail>(baseUrl, data);
};

export const deleteHospitalWard = async (hospitalHashId: string, hospitalWardHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/wards/${hospitalWardHashId}`;
  return axios.delete(baseUrl);
};
