import React, {useMemo, useCallback} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Formik} from 'formik';
import {useAtomValue} from 'jotai';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {tabAtom} from '@Apps/InspectionResult/pc/EditInspectionResult/states';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {InnerLoading} from '@molecules/Loading';
import {ItemsForm} from '@Apps/InspectionResult/pc/EditInspectionResult/ItemsForm';
import {FilesForm} from '@Apps/InspectionResult/pc/common/FilesForm';
import {FormValue} from '@Apps/InspectionResult/pc/common/types';
import {InspectionIndex, InspectionTableIndex} from '@modules/inspections/types';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {Header} from '@Apps/InspectionResult/pc/EditInspectionResult/Header';
import {formFieldToItems, recordToFormValue} from '@Apps/InspectionResult/pc/common/mapper';
import {getFormSchema} from '@Apps/InspectionResult/pc/common/validator';
import {
  FinishInspectionDialog,
  FinishInspectionDialogProps,
  FinishInspectionDialogResult,
} from '../common/dialogs/FinishInspectionDialog';
import {convertDateToRFC3339, convertDateToSimpleDate, convertDateToSimpleDateTime} from '@front-libs/helpers';
import {
  updateInspectionResult,
  NextUpdateInspectionStatusParam,
  useFetchInspectionResultsQuery,
  updateInspectionResults,
} from '@modules/inspection_results/api';
import {openSnackBar} from '@components/molecules/SnackBar';
import {useBackPrevious} from '@front-libs/core';
import {useJointingPostUseInspectionSettings} from '@modules/hospital_settings/hooks';
import {FAKE_INSPECTION_ID} from '../common/hooks';
import {SubmitFormDispatcherProvider} from './contexts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'flex-start',
    },
    pageTitle: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
    },
    flex: {
      flexGrow: 1,
    },
    mainContent: {
      width: '100%',
      paddingTop: '26px',
    },
  })
);

type EditInspectionResultInnerProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
  onSaveAsDraft: (e: React.MouseEvent, values: FormValue) => Promise<void>;
  onDiscardChanges: () => Promise<void>;
};

export const EditInspectionResultInner: React.VFC<EditInspectionResultInnerProps> = (props) => {
  const {inspection, inspectionTable, inspectionResult, onSaveAsDraft, onDiscardChanges} = props;
  const classes = useStyles();
  const tab = useAtomValue(tabAtom);

  return (
    <Grid container className={classes.root}>
      <Header
        inspection={inspection}
        hospitalProduct={inspectionResult.hospitalProduct ?? null}
        onSaveAsDraft={onSaveAsDraft}
        onDiscardChanges={onDiscardChanges}
      />
      {tab === 'items' && <ItemsForm inspectionTable={inspectionTable} />}
      {tab === 'files' && <FilesForm inspectionResult={inspectionResult} isEditView={true} />}
    </Grid>
  );
};

type EditInspectionResultProps = {
  inspection: InspectionIndex;
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
  nextUpdateInspectionStatusParam: NextUpdateInspectionStatusParam | null;
  onResultUpdate: () => void;
};

export const EditInspectionResult: React.VFC<EditInspectionResultProps> = (props) => {
  const {inspection, inspectionTable, inspectionResult, onResultUpdate, nextUpdateInspectionStatusParam} = props;
  const {myInfo} = useMyInfo();
  const goBackToResultsPage = useBackPrevious('/inspection/results');
  const initialValues = useMemo(() => {
    return recordToFormValue(inspectionTable, inspectionResult, inspection.status === 'draft');
  }, [inspection, inspectionTable, inspectionResult]);

  const validationSchema = useMemo(() => {
    if (!inspectionTable) return null;

    return getFormSchema(inspectionTable?.items ?? []);
  }, [inspectionTable]);

  const discardForm = useCallback(async () => {
    try {
      await dialogHandler.open(MessageDialog, {
        title: '点検を保存せずに終了しますか？',
        content: `入力した内容は保存されません。`,
        positiveButtonLabel: '終了',
      });
    } catch (_e) {
      return;
    }

    try {
      goBackToResultsPage();
      openSnackBar('保存せず元に戻りました。', 'center', 'top');
    } catch (e: unknown) {
      openSnackBar(e + '', 'center', 'top');
    }
  }, [goBackToResultsPage]);

  const jointingPostUseInspectionSettings = useJointingPostUseInspectionSettings(myInfo.hospitalHashId);
  const enabledSkipPostUseInspectionResult =
    inspectionResult.type === 'periodic' &&
    jointingPostUseInspectionSettings.allowed === true &&
    (jointingPostUseInspectionSettings.period ?? 0) > 0;
  const {data: postUseInspectionResults} = useFetchInspectionResultsQuery(
    myInfo.hospitalHashId,
    FAKE_INSPECTION_ID,
    {
      types: 'post_use',
      hospitalProductHashId: inspectionResult.hospitalProductHashId,
      statuses: 'unplanned,uncompleted',
      order: 'scheduledAt',
    },
    {
      enabled: enabledSkipPostUseInspectionResult,
    }
  );
  const canSkipPostUseInspectionResults = enabledSkipPostUseInspectionResult && postUseInspectionResults.length > 0;

  const openFinishInspectionDialog = useCallback(
    async (
      defaultCompletedAt: string
    ): Promise<
      | {completedAt: string; shouldSkipPostUseInspections: false}
      | {completedAt: string; shouldSkipPostUseInspections: true; skipReason: string}
    > => {
      const res = await dialogHandler.open<FinishInspectionDialogProps, FinishInspectionDialogResult>(
        FinishInspectionDialog,
        {
          completedAt: defaultCompletedAt,
          canSkipPostUseInspectionResults: canSkipPostUseInspectionResults,
          inspectionResult: inspectionResult,
        }
      );

      return res.shouldSkipPostUseInspections
        ? {
            completedAt: res.completedAt,
            shouldSkipPostUseInspections: res.shouldSkipPostUseInspections,
            skipReason: res.skipReason,
          }
        : {completedAt: res.completedAt, shouldSkipPostUseInspections: false};
    },
    [canSkipPostUseInspectionResults, inspectionResult]
  );

  const skipPostUseInspectionResults = useCallback(
    async (skipTime: string, reason: string) => {
      if (postUseInspectionResults.length === 0) {
        return;
      }

      await updateInspectionResults(myInfo.hospitalHashId, FAKE_INSPECTION_ID, {
        inspectionResults: postUseInspectionResults.map((r) => ({
          inspectionResultHashId: r.hashId,
          status: 'skipped',
          skippedTime: skipTime,
          skipReason: reason,
          skippedByHashId: myInfo.hashId,
        })),
      });
    },
    [myInfo, postUseInspectionResults]
  );

  const saveForm = useCallback(
    async (values: FormValue, isDraft: boolean) => {
      let completedAt = !isDraft
        ? convertDateToSimpleDateTime(
            inspectionResult.completedAt ? new Date(inspectionResult.completedAt) : new Date()
          )
        : undefined;

      let shouldChangeProductStatusToReady = false;
      let skipReason = '';

      try {
        if (isDraft) {
          await dialogHandler.open(MessageDialog, {
            title: '点検を保存して終了しますか？',
            content: `入力した内容は保存され、実施途中として記録されます`,
            positiveButtonLabel: '終了',
          });
        } else {
          const res = await openFinishInspectionDialog(completedAt + '');
          if (res.completedAt !== completedAt) {
            completedAt = res.completedAt;
          }

          if (res.shouldSkipPostUseInspections) {
            shouldChangeProductStatusToReady = res.shouldSkipPostUseInspections;
            skipReason = res.skipReason;
          }
        }
      } catch (_e) {
        return;
      }

      if (completedAt) {
        completedAt = convertDateToRFC3339(new Date(completedAt));
        // NOTE:iOS15ではcompletedAtの値が2021-04-16 03:00固定となりRFC3339に変換できない為、現在の時刻を登録
        if (completedAt === 'Invalid Date') {
          completedAt = convertDateToRFC3339(new Date());
        }
      }
      try {
        const res = await updateInspectionResult(
          myInfo.hospitalHashId,
          inspection.hashId,
          inspectionResult?.hashId ?? '',
          {
            inspectionTableHashId: inspectionTable.hashId,
            inspectorHashId: nextUpdateInspectionStatusParam?.inspectorHashId ?? myInfo.hashId,
            status: !isDraft ? 'completed' : 'uncompleted',
            items: formFieldToItems(inspectionTable, values),
            comment: '',
            completedAt: completedAt ? convertDateToRFC3339(new Date(completedAt)) : undefined,
            changeHospitalProductStatusToReady: shouldChangeProductStatusToReady ? true : undefined,
          }
        );

        if (shouldChangeProductStatusToReady) {
          await skipPostUseInspectionResults(convertDateToSimpleDate(new Date(res.data.completedAt)), skipReason);
        }

        if (isDraft) {
          openSnackBar('点検を保存しました', 'center', 'top');
          goBackToResultsPage();
        } else {
          openSnackBar('点検を完了しました　', 'center', 'top');
          onResultUpdate();
        }
      } catch (e: unknown) {
        openSnackBar(e + '', 'center', 'top');
      }
    },
    [
      nextUpdateInspectionStatusParam,
      inspectionResult.completedAt,
      inspectionResult?.hashId,
      myInfo.hospitalHashId,
      myInfo.hashId,
      inspection.hashId,
      inspectionTable,
      goBackToResultsPage,
      onResultUpdate,
      openFinishInspectionDialog,
      skipPostUseInspectionResults,
    ]
  );

  const handleSubmit = useCallback(
    (_: React.MouseEvent, values: FormValue) => {
      return saveForm(values, false);
    },
    [saveForm]
  );

  // Formikのcallbackを使用しないため、暫定的に空関数を渡す
  const handleSubmitFake = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (_values: FormValue) => {},
    []
  );

  const handleSaveAsDraft = useCallback(
    (_: React.MouseEvent, values: FormValue) => {
      return saveForm(values, true);
    },
    [saveForm]
  );

  const handleDiscardChanges = useCallback(() => discardForm(), [discardForm]);

  if (!initialValues || validationSchema === null) {
    return <InnerLoading />;
  }

  return (
    <SubmitFormDispatcherProvider handler={handleSubmit}>
      <Formik
        onSubmit={handleSubmitFake}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}>
        <EditInspectionResultInner
          inspection={inspection}
          inspectionTable={inspectionTable}
          inspectionResult={inspectionResult}
          onSaveAsDraft={handleSaveAsDraft}
          onDiscardChanges={handleDiscardChanges}
        />
      </Formik>
    </SubmitFormDispatcherProvider>
  );
};
