import React from 'react';
import {TableRow as MTableRow, TableCell as MTableCell, makeStyles} from '@material-ui/core';
import {NoDataProps} from '../props';

const useStyles = makeStyles(() => ({
  noDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const NoDataCell = (props: NoDataProps) => {
  const {numColumns, component} = props;
  const classes = useStyles();

  if (!component) {
    return null;
  }

  return (
    <MTableRow>
      <MTableCell align="justify" colSpan={numColumns}>
        <span className={classes.noDataContainer}>{component}</span>
      </MTableCell>
    </MTableRow>
  );
};
