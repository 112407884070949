import React from 'react';
import {makeStyles, Grid} from '@material-ui/core';
import {SearchProducts} from './SearchProducts';
import {SelectedProducts} from './SelectedProducts';

const useStyles = makeStyles((theme) => ({
  columns: {
    height: '100%',
    '& > *': {
      padding: '0px',
    },
    '& > div:first-child': {
      padding: '0px',
      borderRight: '1px solid #CDD6E1',
    },
    '& > div:last-child': {
      padding: '0px 0px 0px 32px',
    },
    '@media (max-width: 600px)': {
      '& > div:first-child': {
        padding: '0px',
        borderRight: 'none',
        borderBottom: '1px solid #CDD6E1',
      },
      '& > div:last-child': {
        padding: '32px 32px 0px',
      },
    },
  },
  column: {
    height: '100%',
  },
}));

type EditProductsFormsProps = {
  hospitalHashId: string;
};

export const EditProductsForms: React.VFC<EditProductsFormsProps> = (props) => {
  const {hospitalHashId} = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.columns}>
      <Grid className={classes.column} item xs={12} sm={6}>
        <SearchProducts hospitalHashId={hospitalHashId} />
      </Grid>
      <Grid className={classes.column} item xs={12} sm={6}>
        <SelectedProducts />
      </Grid>
    </Grid>
  );
};
