import React from 'react';
import Selector from 'react-select';
import {theme} from '@atoms/theme';
import {FormControl} from '@material-ui/core';
import {HospitalProductIndex} from '@modules/hospital_products/types';

type HospitalProductOption = {
  label: string;
  value: HospitalProductIndex;
};
type HospitalProductSelectorProps = {
  hospitalProductOptions: HospitalProductOption[];
  onSearchHospitalProduct: (name: string) => void;
  onSelectHospitalProduct: (value?: HospitalProductIndex) => void;
};

export const HospitalProductSelector: React.FC<HospitalProductSelectorProps> = ({
  hospitalProductOptions,
  onSearchHospitalProduct,
  onSelectHospitalProduct,
}) => {
  return (
    <div>
      <FormControl variant="outlined" size={'small'} fullWidth>
        <Selector<HospitalProductOption, false>
          menuPortalTarget={document.body}
          size="small"
          placeholder="検索"
          styles={{
            menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
          }}
          options={hospitalProductOptions}
          onInputChange={onSearchHospitalProduct}
          onChange={(option: HospitalProductOption | null) => onSelectHospitalProduct(option?.value)}
        />
      </FormControl>
    </div>
  );
};
