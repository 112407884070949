import {useQuery} from 'react-query';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {GetHospitalFileParam, getHospitalFiles} from '@modules/files/api';
import {useMemo} from 'react';
import {useQueryVariables} from './state';

export type useHospitalFilesProps = {
  initialVariables?: GetHospitalFileParam;
};

export const useHospitalFiles = (props: useHospitalFilesProps) => {
  const {initialVariables} = props;
  const {page, perPage, searchWord, order} = useQueryVariables();
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useQuery(
    ['getHospitalFiles', myInfo.hospitalHashId, page, perPage, searchWord],
    () =>
      getHospitalFiles(myInfo.hospitalHashId, {
        ...initialVariables,
        page,
        perPage,
        order,
        name: searchWord,
      })
  );
  const fileData = useMemo(() => data?.data, [data]);

  return {
    fileData,
    isLoading,
    refetch,
  };
};
