import React from 'react';
import dayjs from 'dayjs';
import {Typography, styled} from '@material-ui/core';
import {InspectionResultListElement} from '@Apps/InspectionResultList/pc/types';

// FIXME: このコンポーネントが親コンポーネントのループの中で動的に呼ばれたり呼ばれなかったりするので、hookを使ってると数が合わないから怒られる
// 暫定で消したりしたが、本当はおかしいのでいずれ対応する。
export const ScheduledDateColumn: React.VFC<InspectionResultListElement> = (props) => {
  const endOfLastMonth = dayjs().clone().subtract(1, 'month').endOf('month');
  const scheduledDate = props.scheduledTime ? dayjs(props.scheduledTime) : null;
  const textDate = scheduledDate ? scheduledDate.format('YYYY/MM/DD') : '';

  return scheduledDate && props.status !== 'completed' && endOfLastMonth.isAfter(scheduledDate) ? (
    <OverdueDate>{textDate}</OverdueDate>
  ) : (
    <Typography>{textDate}</Typography>
  );
};

const OverdueDate = styled(Typography)({
  display: 'inline',
  color: '#C7243A',
  fontWeight: 'bold',
});
