import React, {memo} from 'react';
import {Box, Typography, styled, Theme} from '@material-ui/core';
import {InspectionResultStatus} from '@modules/inspection_results/enum';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// NOTE: exportのStyledはInspectionTypeBarと共通
export const StyledContainerDiv = styled('div')({
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0px 12px',
});

export const StyledMenuButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
});

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({theme}: {theme: Theme}) => ({
  fontSize: '16px',
  fill: theme.palette.primary.main,
}));

const BarButtonBox = styled(({selected, ...otherProps}) => <Box {...otherProps} />)(
  ({theme, selected}: {theme: Theme; selected: boolean}) => ({
    backgroundColor: selected ? theme.palette.rent.main : undefined,
    padding: selected ? '1px 12px 0px' : '0px 8px',
    margin: selected ? '8px' : '4px',
    borderRadius: selected ? '12px' : undefined,
  })
);
const BarButtonTypography = styled(({selected, ...otherProps}) => <Typography {...otherProps} />)(
  ({selected}: {selected: boolean}) => ({
    color: selected ? '#FFFFFF' : '#172B4D',
    fontSize: '16px',
  })
);

export type InspectionStatusBarProps = {
  status: string;
  onChangeStatus: (value: InspectionResultStatus) => void;
};
type MenuItemList = {
  label: string;
  value: InspectionResultStatus;
};

export const InspectionStatusMenuItemList: MenuItemList[] = [
  {label: '対象機種', value: 'unplanned'},
  {label: '実施途中', value: 'uncompleted'},
  {label: '完了', value: 'completed'},
  {label: 'スキップ', value: 'skipped'},
];

/**
 * 点検のステータス切り替えバー
 * @param props.status 現在選択されている点検ステータス
 * @param props.isNarrow 表示領域が狭いか trueで表示領域が狭いSelectBoxを返す
 * @param props.onChangeType 項目選択時のコールバック関数
 */
export const InspectionStatusBar = memo(({status, onChangeStatus}: InspectionStatusBarProps) => {
  /**
   * ボタン押下時の処理
   *
   * @param selectedType - 選択された点検ステータスのオプション値。
   */
  const handleClick = (selectedType: InspectionResultStatus) => {
    onChangeStatus(selectedType);
  };

  return (
    <StyledContainerDiv>
      {InspectionStatusMenuItemList.map((v) => (
        <BarButton label={v.label} value={v.value} current={status} key={v.value} handleClick={handleClick} />
      ))}
    </StyledContainerDiv>
  );
});

type BarButtonProps = {
  label: string;
  value: InspectionResultStatus;
  current: string;
  handleClick: (selectedType: InspectionResultStatus) => void;
};

/**
 * 点検タイプを選択するためのボタンコンポーネント。
 *
 * @param props - コンポーネントのプロパティ
 * @param props.label - ボタンに表示されるラベル
 * @param props.value - このボタンに対応する点検タイプの値
 * @param props.current - 現在選択されている点検タイプの値
 * @returns ボタンコンポーネントを返す
 */
const BarButton = ({label, value, current, handleClick}: BarButtonProps) => {
  const selected = current === value;
  return (
    <BarButtonBox selected={selected} onClick={() => handleClick(value)}>
      <BarButtonTypography selected={selected} align="center">
        {label}
      </BarButtonTypography>
    </BarButtonBox>
  );
};
