import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {MakerInspectionStatusMenus} from './constants';
import {MakerInspectionResultStatus} from './types';

type StatusSelectorProps = {
  status: MakerInspectionResultStatus;
  counts?: {[key in MakerInspectionResultStatus]: number};
  onSelectStatus: (status: MakerInspectionResultStatus | null) => void;
};

export const StatusSelector: React.FC<StatusSelectorProps> = (props) => {
  const {status, counts = {} as {[key: string]: number}, onSelectStatus} = props;
  const classes = useStyles();

  return (
    <List>
      {MakerInspectionStatusMenus.map(({value, label}) => {
        const count = counts[value] ?? 0;

        return (
          <ListItem
            button
            key={value}
            onClick={() => onSelectStatus(value as MakerInspectionResultStatus)}
            selected={status === value}
            className={classes.item}>
            <ListItemText primary={label} />
            <ListItemSecondaryAction>
              <ListItemText primary={`${count}`} />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      marginTop: '8px',
      padding: '2px 8px',
      '&.Mui-selected': {
        backgroundColor: 'rgba(42, 150, 232, 0.1) !important',
      },
      '&.Mui-selected span, &.Mui-selected + .MuiListItemSecondaryAction-root span': {
        fontWeight: 'bold',
      },
    },
  })
);
