import {Typography, styled} from '@material-ui/core';
import React from 'react';
import dayjs from 'dayjs';
import AssignmentIcon from '@material-ui/icons/Assignment';

export type InspectionResultCardProps = {
  title: React.ReactNode;
  displayDate?: Date;
  contentSlot?: {label: string; value: string}[];
};

export const InspectionResultCard = ({title, displayDate, contentSlot}: InspectionResultCardProps) => {
  return (
    <RootContainer>
      <IconContainer>
        <AssignmentIcon style={{height: '22px'}} />
      </IconContainer>
      <Container>
        <Header>
          <Title>
            {title}
          </Title>
          <CommentDate>
            {dayjs(displayDate).format('MM/DD HH:mm')}
          </CommentDate>
        </Header>
        <Content>
          {contentSlot?.map((content, index) => (
            <Row key={index}>
              <Label>{content.label}</Label>
              <Data>{content.value}</Data>
            </Row>
          ))}
        </Content>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
});

const Container = styled('div')({
  paddingLeft: '8px',
  width: '100%',
});

const Content = styled('div')({});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const Title = styled('div')({
  fontWeight: 600,
  color: '#172B4D',
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
});

const Label = styled('div')({
  minWidth: '70px', // ：の開始位置を修正。他２項目が5文字のため、5文字分の70px分確保する。
  color: '#172B4D',
});

const Data = styled(Typography)({
  fontSize: '14px',
  color: '#172B4D',
});

