import {Typography, styled} from '@material-ui/core';
import React from 'react';
import dayjs from 'dayjs';
import {UserFormatter} from '@modules/hospital_users/helpers';
import LocalHospital from '@material-ui/icons/LocalHospitalOutlined';
import {HospitalProductDetail} from '@modules/hospital_products/types';

export type RegistrationCardProps = {
  hospitalProduct?: HospitalProductDetail;
  displayDate: Date;
};

export const RegistrationCard = ({hospitalProduct, displayDate}: RegistrationCardProps) => {
  return (
    <RootContainer>
      <IconContainer>
        <LocalHospital style={{height: '22px'}} />
      </IconContainer>
      <Container>
        <Header>
          <Title>機器情報の登録</Title>
          <CommentDate>
            {dayjs(displayDate).format('MM/DD HH:mm')}
          </CommentDate>
        </Header>
        <Content>
          <ContentTypography>
            登録者：{UserFormatter.getFullName(hospitalProduct?.createdBy)}
          </ContentTypography>
        </Content>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '16px',
});

const IconContainer = styled('div')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0F2F5',
});

const CommentDate = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
});

const ContentTypography = styled(Typography)({
  fontSize: '14px',
  color: '#172B4D',
});

const Container = styled('div')({
  paddingLeft: '8px',
  width: '100%',
});

const Content = styled('div')({});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
});

const Title = styled('div')({
  fontWeight: 600,
  color: '#172B4D',
});
