import {Box, Divider, styled} from '@material-ui/core';
import {InspectionItem} from '@modules/inspections/api';
import React, {useEffect, useState} from 'react';
import {InspectionTableIndex} from '@modules/inspections/types';
import {Section} from './section';
import {Item} from './item';
import {HeaderFieldProps} from './Header';
import {FastField, FieldInputProps, FormikProps} from 'formik';
import {SectionStatusMap, getSectionStatus} from '../../sp/common/validator';
import {FormValue} from '../../pc/common/types';

/**
 * InspectionResultContent コンポーネントは検査表の結果を表示するために使用されます。
 * 検査項目はセクションごとに整理され、各セクション内の項目がリストされます。
 *
 * @param props.inspectionTable - 表示する検査表のデータ。各アイテムはセクションまたは個別の検査項目を表すことができます。
 * @returns 検査結果の内容を表示するReactコンポーネント
 */
export const InspectionResultContent = (props: InspectionResultContentProps) => {
  return (
    <FastField name="">
      {({field, form}: HeaderFieldProps) => <InnerInspectionResultContent {...props} field={field} form={form} />}
    </FastField>
  );
};
const InnerInspectionResultContent = ({
  inspectionTable,
  field,
  form,
}: InspectionResultContentProps & {field: FieldInputProps<FormValue>; form: FormikProps<FormValue>}) => {
  const [status, setStatus] = useState<SectionStatusMap>();

  useEffect(() => {
    const _status = getSectionStatus(field, form.errors, inspectionTable.items);
    setStatus(_status);
  }, [field, form.errors, inspectionTable, inspectionTable.items]);

  return (
    <StyledItemContainer>
      {inspectionTable.items.map((item, index) => {
        if (item.type === 'section') {
          return (
            <Section name={item.name} key={`Section_${index}`} inputStatus={status ? status[item.name] : 'none'}>
              {item.items.map((sectionItem, i) => (
                <React.Fragment key={`resultItem${item.id}-${i}`}>
                  <Item item={sectionItem as InspectionItem & {id: string}} />
                  {/* 最後の要素はDividerを表示しない */}
                  {item.items.length - 1 > i ? <StyledDivider /> : <StyledSpan />}
                </React.Fragment>
              ))}
            </Section>
          );
        } else {
          return undefined;
        }
      })}
    </StyledItemContainer>
  );
};

type InspectionResultContentProps = {
  inspectionTable: InspectionTableIndex;
};

const StyledItemContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '24px',
});

const StyledSpan = styled('span')({
  marginBottom: '8px',
});
const StyledDivider = styled(Divider)({
  margin: '0 12px 8px',
});
