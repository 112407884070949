import React, {useMemo} from 'react';
import clsx from 'clsx';
import {Outlet, useLocation} from 'react-router-dom';
import {Grid, makeStyles, Theme} from '@material-ui/core';
import {Header, NavLinkType} from '@components/organisms/Header/pc';
import {useTemplate} from '@templates/NoSideBarLayoutForPC';
import {withSuspense} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {isNullish} from '@front-libs/helpers';
import {useFetchHospitalQuery} from '@modules/hospital/api';
import {useUserPermissions} from '@modules/auth/hooks';
import {HospitalUserPermission} from '@modules/auth/consts';
import {isMobileOnly} from 'react-device-detect';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const isMobilePath = (pathname: string) => {
  return pathname.indexOf('/sp') === 0;
};

const _BaseProductManager: React.FC = ({children, ...props}) => {
  const templateClasses = useTemplate();
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {isRentalOnly, isAdmin} = useMyRole();
  const {data} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const {isPermitted} = useUserPermissions();
  const location = useLocation();

  const navLinkItems: NavLinkType[] = useMemo(
    () => [
      {
        to: '/dashboard',
        label: 'ダッシュボード',
        hasPermission: () => isPermitted([HospitalUserPermission.Dashboard]),
      },
      {
        to: '',
        label: '機器管理',
        childLinks: [
          {label: '機器一覧', to: '/products'},
          {
            label: 'チャンネル一覧',
            to: '/channels',
            hasPermission: () => isPermitted([HospitalUserPermission.TrainingManagement]),
          },
        ],
      },
      {
        to: '/inspection/results',
        label: '点検',
        hasPermission: () => isPermitted([HospitalUserPermission.Inspection]),
      },
      {
        to: '/repairs',
        label: '修理',
        hasPermission: () => isPermitted([HospitalUserPermission.Repair]),
      },
      {
        to: '/rentals',
        label: '貸出・返却',
        hasPermission: () => isPermitted([HospitalUserPermission.Rental]),
      },
      {
        to: '/training_managements',
        label: '研修',
        childLinks: [
          {label: '研修計画マスタ', to: '/training_managements/plans'},
          {label: '研修（予定）', to: '/training_managements/schedules'},
          {label: '研修（実施済み）', to: '/training_managements/reports'},
        ],
        hasPermission: () => isPermitted([HospitalUserPermission.TrainingManagement]),
      },
    ],
    [isPermitted]
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isMobileOnly && !isMobilePath(location.pathname)) {
      navigate('/sp/products', {replace: true});
      return;
    }

    if (!isNullish(data) && !data.assetInitialized) {
      navigate('/signup/hospital', {replace: true});
      return;
    }
    if (!isNullish(myInfo.initialized) && !myInfo.initialized) {
      navigate('/signup/user', {replace: true});
      return;
    }
    if (isRentalOnly) {
      navigate('/shared', {replace: true});
    }
  }, [data, isRentalOnly, location.pathname, myInfo, navigate]);

  return (
    <Grid container className={clsx(templateClasses.root, classes.root)}>
      {/*{!props!.headerless && <Header className={templateClasses.header} navLinkItems={navLinkItems} />}*/}
      <Header className={templateClasses.header} navLinkItems={navLinkItems} isUserAdmin={isAdmin} />
      <Grid container className={templateClasses.content}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export const BaseProductManager = withSuspense(_BaseProductManager);
