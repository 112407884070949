import React, {useCallback, useMemo, useState} from 'react';
import {Button, Grid, makeStyles, Paper, TextField, Theme} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import dayjs from 'dayjs';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {StrUtil} from '@front-libs/helpers';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {ArrowTooltipComponent} from '@components/atoms/ArrowTooltip';
import {styled} from '@material-ui/styles';
import {UserIndex} from '@modules/hospital_users/types';
import {DeleteCard} from './DeleteCard';

/**
 * コメントカードのProps
 */
export type CommentCardProps = {
  user?: UserIndex;
  displayDate?: Date;
  description?: string;
  updateBy?: UserIndex;
  updateAt?: string;
  isArchived?: boolean;
  isReadOnly?: boolean;
  canDelete?: boolean;
  isUserAdmin?: boolean;
  pinned?: boolean;
  /**
   * コメントが更新された時に呼び出される関数
   * @param comment - 更新されたコメント。
   */
  onUpdateComment: (comment: string) => void;
  /**
   * ピン留めが更新された時に呼び出される関数
   */
  onUpdatePinned: () => void;

  /**
   * コメントが削除された時に呼び出される関数
   */
  onDeleteComment: () => void;
};

/**
 * コメントカードの表示
 * @param {CommentCardProps} CommentCardProps
 * @param user - 作成者のUserIndex
 * @param displayDate - 作成日のDate
 * @param description - コメント内容
 * @param updateBy - 編集者のUserIndex
 * @param updateAt - 変更日
 * @param isReadOnly - アクションを表示するか
 * @param canDelete - 削除のアクションを表示するか
 * @param isArchived - 削除されているか
 * @param pinned - ピン留めされているか
 * @returns
 */
// FIXME: useMyInfoの呼び出しを消す
export const CommentCard = ({
  user,
  updateAt,
  updateBy,
  displayDate,
  description,
  isArchived,
  pinned,
  isReadOnly,
  canDelete,
  isUserAdmin,
  onUpdateComment,
  onUpdatePinned,
  onDeleteComment,
}: CommentCardProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState(description ?? '');
  const [editingComment, setEditingComment] = useState(description ?? '');
  const [commentUpdateAt, setCommentUpdateAt] = useState<Date>();
  const {myInfo} = useMyInfo();
  const classes = useHistoryCardStyles();
  /** コメントを保存し、編集モードを終了、親コンポーネントに更新を通知する関数 */
  const handleSaveComment = useCallback(() => {
    setComment(editingComment);
    setIsEditing(false);
    // 編集済み表示判別に利用
    setCommentUpdateAt(new Date());
    onUpdateComment(editingComment);
  }, [editingComment, onUpdateComment]);

  /** コメントを削除し、編集モードを終了、親コンポーネントに削除を通知する関数 */
  const handleCancelEditComment = useCallback(() => {
    setIsEditing(false);
    setEditingComment(comment);
  }, [comment]);

  /** アクションボタン押下時 */
  const handleActionMenuClick = useCallback(
    async (item: MenuItemType) => {
      switch (item.value) {
        case 'pinned':
          onUpdatePinned();
          break;
        case 'delete':
          onDeleteComment();
          break;
        default:
          break;
      }
    },
    [onUpdatePinned, onDeleteComment]
  );

  /**
   * アクションボタンを使える権限があるか
   * 自身のコメントもしくは管理者権限があればtrueを返す
   **/
  const canActionButton = useMemo(() => {
    if (isReadOnly) return false;
    if (!user) return false;
    if (user.hashId === myInfo.hashId) return true;
    return isUserAdmin;
  }, [isReadOnly, user, myInfo.hashId, isUserAdmin]);

  /** コメントを編集可能か */
  const isEditableComment = useMemo(() => {
    if (isReadOnly) return false;
    if (!user) return false;
    return user.hashId === myInfo.hashId;
  }, [isReadOnly, user, myInfo.hashId]);

  /** 編集済み表示 */
  const editedView = useMemo(() => {
    const currentUpdateAt = commentUpdateAt || (updateAt && new Date(updateAt));
    // NOTE:displayDateはcreatedAtから作られている +をつけることでミリ秒に変換できる
    if (!currentUpdateAt || !displayDate || +displayDate === +currentUpdateAt) return null;

    const dayText = () => {
      const today = dayjs();
      const update = dayjs(currentUpdateAt);
      if (today.isSame(update, 'day')) return '今日';
      if (today.subtract(1, 'day').isSame(update, 'day')) return '昨日';
      return update.format('YYYY年M月D日 HH:mm');
    };

    return (
      <ArrowTooltipComponent tooltipText={dayText()}>
        <StyledEditedText>{'(編集済み)'}</StyledEditedText>
      </ArrowTooltipComponent>
    );
  }, [displayDate, updateAt, commentUpdateAt]);

  /** 削除コメント表示 */
  const deleteCard = useMemo(() => {
    if (!updateBy || !updateAt || !displayDate) return null;
    return (
      <DeleteCard displayDate={displayDate} updateBy={updateBy} updateAt={commentUpdateAt || new Date(updateAt)} />
    );
  }, [updateBy, updateAt, displayDate, commentUpdateAt]);

  const actionMenuItems = useMemo(
    () => [
      {
        label: pinned ? 'ピン留めを解除' : 'ピン留め',
        value: 'pinned',
      },
      ...(canDelete ? [{label: '削除', value: 'delete'}] : []),
    ],
    [canDelete, pinned]
  );

  // 削除済み表示
  if (isArchived) return deleteCard;

  return (
    <Paper className={classes.root}>
      <Grid container alignItems={'center'} className={classes.cardHeader}>
        <Grid item className={classes.boldText}>
          {user ? `${UserFormatter.getFullName(user)}さん` : '不明なユーザー'}によるコメント
        </Grid>
        <div className={classes.flex} />
        <Grid item className={classes.displayDate}>
          {canActionButton && (
            <PopperMenuButton
              buttonProps={{size: 'small', color: 'inherit', className: classes.actionMenu}}
              menuItemList={actionMenuItems}
              onMenuClick={handleActionMenuClick}>
              アクション
            </PopperMenuButton>
          )}
        </Grid>
        <Grid item className={classes.displayDate}>
          {dayjs(displayDate).format('YYYY年M月D日 HH:mm')}
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} className={classes.propertyContainer}>
        <Grid item style={{width: '100%'}}>
          {isEditing ? (
            <>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                className={classes.commentEditor}
                value={editingComment}
                onChange={(e) => setEditingComment(e.target.value)}
                style={{marginBottom: '16px'}}
              />
              <Button className={classes.editCommentBtn} style={{marginRight: '8px'}} onClick={handleSaveComment}>
                保存
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCancelEditComment}>
                キャンセル
              </Button>
            </>
          ) : (
            <StyledCommentButton disabled={!isEditableComment} fullWidth onClick={() => setIsEditing(true)}>
              {StrUtil.nl2br(comment)}
              <span className={classes.flex} />
              <Edit className={classes.commentBtnIcon} />
            </StyledCommentButton>
          )}
          {editedView}
        </Grid>
      </Grid>
    </Paper>
  );
};

const StyledCommentButton = styled(Button)(({theme}: {theme: Theme}) => ({
  color: theme.palette.common.black,
  boxSizing: 'border-box',
  justifyContent: 'flex-start',
  textAlign: 'left',
  border: '2px solid',
  borderColor: 'rgba(0,0,0,0)',
  '&:hover': {
    borderColor: '#4173C5',
    backgroundColor: theme.palette.grey[50],
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  },
  '&:hover $commentBtnIcon': {
    visibility: 'visible',
  },
  '&.Mui-disabled': {
    color: theme.palette.common.black,
  },
}));

const StyledEditedText = styled('span')(({theme}: {theme: Theme}) => ({
  padding: '0px 8px',
}));

const useHistoryCardStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
  },
  cardHeader: {
    paddingTop: 8,
    paddingLeft: 16,
  },
  actionMenu: {
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  flex: {
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 700,
  },
  displayDate: {
    color: theme.palette.grey[600],
  },
  propertyContainer: {
    paddingTop: 16,
    paddingLeft: 8,
    paddingBottom: 8,
  },
  property: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.common.black,
  },
  propertyLabel: {
    margin: 0,
    paddingBottom: '4px',
    color: theme.palette.grey[600],
  },
  commentBtnIcon: {
    visibility: 'hidden',
    transition: 'visibility',
    transitionDelay: '100ms',
  },
  commentEditor: {
    // borderColor: 'rgba(0,0,0,0)',
    '&:hover': {
      borderColor: '#4173C5',
    },
  },
  cancelBtn: {
    backgroundColor: '##F5F6F7',

    borderRadius: '2px',
    border: '1px solid #E5E8EC',
    padding: '8px 22px',
    color: '#42526E',
    '&:hover': {
      backgroundColor: '##F5F6F7',
    },
  },
  editCommentBtn: {
    backgroundColor: '#323F4E',
    borderRadius: '2px',
    padding: '8px 22px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
}));
