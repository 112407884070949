import React from 'react';
import {CardContent, styled} from '@material-ui/core';
import {Card, RadioProps, CardActionArea} from '@material-ui/core';

type Props = {
  label: string;
  onChange: (value: string) => void;
} & Omit<RadioProps, 'onChange'>;

export const RentalCard: React.VFC<Props> = ({label, ...props}) => {
  const handleClick = () => {
    props.onChange(props.value as string);
  };
  return (
    <CardRoot>
      <ActionArea onClick={handleClick} data-testid={'rental-card'}>
        <Content>{label}</Content>
      </ActionArea>
    </CardRoot>
  );
};

const CardRoot = styled(Card)(({theme}) => ({
  borderRadius: '45px',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  '&:active': {
    color: theme.palette.rent.light,
  },
  height: '100%',
  width: '100%',
}));

const ActionArea = styled(CardActionArea)(({theme}) => ({
  height: '100%',
  paddingBottom: 32,
}));

const Content = styled(CardContent)(({theme}) => ({
  height: '100%',
  // width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '36px',
  fontWeight: 'bold',
  color: theme.palette.rent.main,
  [theme.breakpoints.up('tabletH')]: {
    fontSize: '64px',
  },
}));
