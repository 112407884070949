import {
  submitOfflineCreatedInspectionResultToServer,
  submitOfflineUpdatedInspectionResultToServer,
} from '@indexedDB/SubmitInspectionResults/service';

export const submitOfflineInspectionResult = async (hospitalHashId: string) => {
  // 新規点検作成済みの点検を送信
  await submitOfflineCreatedInspectionResultToServer(hospitalHashId);
  // サーバで作成した点検の更新結果を送信
  await submitOfflineUpdatedInspectionResultToServer(hospitalHashId);
};
