import {useQuery} from 'react-query';
import {getInspectionKey, getInspectionResultKey} from '@constants/api';
import {getInspection} from '@modules/inspections/api';
import {getInspectionResult, useFetchInspectionResultsQuery} from '@modules/inspection_results/api';
import {useOnlyOnce} from '@front-libs/core';
import {isUndefined} from 'lodash';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

type QueryOptions = {
  noCache?: boolean;
};

export const FAKE_INSPECTION_ID = 'EgqdR7b5l3mzr6Y';

export const useInspection = (hospitalHashId: string, inspectionHashId: string) => {
  const query = useQuery(
    [getInspectionKey, hospitalHashId, inspectionHashId],
    () => getInspection(hospitalHashId, inspectionHashId),
    {enabled: false}
  );
  const {refetch} = query;
  useOnlyOnce(refetch);
  return query;
};

export const useInspectionResult = (
  hospitalHashId: string,
  inspectionHashId: string,
  inspectionResultHashId: string,
  queryOption?: QueryOptions
) => {
  const query = useQuery(
    [getInspectionResultKey, hospitalHashId, inspectionHashId, inspectionResultHashId],
    () => getInspectionResult(hospitalHashId, inspectionHashId, inspectionResultHashId),
    {
      cacheTime: !isUndefined(queryOption?.noCache) && queryOption?.noCache ? 0 : undefined,
    }
  );
  return query;
};

export const useLatestInspectionResult = (hospitalProductHashId?: string) => {
  const {myInfo} = useMyInfo();
  const query = useFetchInspectionResultsQuery(myInfo.hospitalHashId, FAKE_INSPECTION_ID, {
    hospitalProductHashId: hospitalProductHashId,
    statuses: 'completed',
    order: '-completedAt',
  });

  return {
    ...query,
    data: query.data.length > 0 ? query.data[0] : null,
  };
};
