import React from 'react';
import {Column} from '@molecules/Table/props';
import {ProductIndex} from '@modules/products/types';
import {productClassTypeToLabel} from '@modules/products/constants';
import {Table} from '@molecules/Table';

const tableColumn: Column<ProductIndex>[] = [
  {
    title: '大分類',
    field: 'broadCategory',
    render: ({categories}) => {
      return categories && categories.find(({depth}) => depth === 0)?.name;
    },
  },
  {
    title: '小分類',
    field: 'narrowCategory',
    render: ({categories}) => {
      return categories && categories.find(({depth}) => depth === 1)?.name;
    },
  },
  {
    title: 'メーカー',
    field: 'maker_name',
    render: ({maker}) => {
      return maker?.name;
    },
  },
  {
    title: '機種名',
    field: 'displayName',
  },
  {
    title: '型式',
    field: 'name',
  },
  {
    title: 'クラス',
    field: 'className',
    render: ({className}) => {
      return (className && productClassTypeToLabel[className]) || '';
    },
  },
];

type Props = {
  wholeProducts?: ProductIndex;
};

export const CategoryTable: React.VFC<Props> = ({wholeProducts}) => {
  return wholeProducts ? <Table showSelection={false} columns={tableColumn} data={[wholeProducts]} /> : null;
};
