import {createElement, ComponentType} from 'react';
import {Field, FieldAttributes, FieldProps} from 'formik';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const wrapField = (Component: ComponentType<FieldProps & {[K: string]: any}>) => (props: FieldAttributes<any>) =>
  createElement(Field, {
    ...props,
    component: Component,
  });

export default wrapField;
