import {TableBody, TableCell, TableRow} from '@material-ui/core';
import React from 'react';
import {StatusTable, StyledTableHead, StyledTableRow} from '.';

/**
 * ユーザーエージェント情報
 * @returns
 */
export const UserAgentList = () => {
  return (
    <StatusTable>
      <StyledTableHead>
        <TableRow>
          <TableCell>UserAgent</TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        <StyledTableRow>
          <TableCell component="th" scope="row">
            {window.navigator.userAgent}
          </TableCell>
        </StyledTableRow>
      </TableBody>
    </StatusTable>
  );
};
