import React from 'react';
import {Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, createStyles, makeStyles} from '@material-ui/core';

export type CheckboxProps = MuiCheckboxProps;

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const classes = useStyles();
  return <MuiCheckbox classes={classes} {...props} />;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    colorPrimary: {
      '&.Mui-checked': {
        color: theme.palette.primary.dark,
      },
    },
    colorSecondary: {
      '&.Mui-checked': {
        color: theme.palette.secondary.dark,
      },
    },
  })
);
