import React, {useCallback} from 'react';
import {
  TableHead as MTableHead,
  TableRow as MTableRow,
  TableCell as MTableCell,
  TableSortLabel,
} from '@material-ui/core';
import {useAtom, useAtomValue} from 'jotai';
import {Column, Order, SelectionAction, TableHeadProps} from './props';
import {
  useSelectAll,
  selectedAllAtom,
  selectedAnyAtom,
  columnsAtom,
  orderAtom,
  useInitialized,
  numSelectedAtom,
} from './atoms';
import {
  DragDropContext,
  DropResult,
  Droppable,
  Draggable,
  ResponderProvided,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import {isNumber} from 'lodash';
import {useOnlyOnce} from '@front-libs/core';
import {isNullish} from '@front-libs/helpers';
import {CheckboxCell} from './TableBody/CheckboxCell';
import {ActionBar} from './TableBody/ActionBar';
import {useStyles} from '@Apps/Settings/Place/styles';

type TableHeaderCheckboxProps = {
  size: 'medium' | 'small';
  onSelectAllChange: React.ChangeEventHandler<HTMLInputElement>;
};

const TableHeaderCheckbox = (props: TableHeaderCheckboxProps) => {
  const {size, onSelectAllChange} = props;
  const selectedAll = useAtomValue(selectedAllAtom);
  const selectedAny = useAtomValue(selectedAnyAtom);

  return (
    <CheckboxCell
      size={size}
      color="primary"
      indeterminate={selectedAny && !selectedAll}
      checked={selectedAny || selectedAll}
      onChange={onSelectAllChange}
    />
  );
};

type TableHeaderColumnProps = {
  fieldId: string;
  wrap?: boolean;
  children: React.ReactNode;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;

  enablesSort: boolean;
  sortFieldId: string | null;
  sortDirection: 'asc' | 'desc' | null;

  onOrderChange: (e: React.MouseEvent, nextDirection: 'asc' | 'desc') => void;
};

const TableHeaderColumn = React.forwardRef((props: TableHeaderColumnProps, ref) => {
  const {
    fieldId,
    wrap,
    children,
    draggableProps,
    dragHandleProps,
    enablesSort,
    sortFieldId,
    sortDirection,
    onOrderChange,
  } = props;

  const isSortActive = enablesSort && sortFieldId === fieldId;

  const handleTableSortLabelClicked = useCallback(
    (e: React.MouseEvent) => {
      const isAsc = isSortActive && sortDirection === 'asc';
      const nextDirection = isAsc ? 'desc' : 'asc';

      onOrderChange(e, nextDirection);
    },
    [isSortActive, onOrderChange, sortDirection]
  );

  const whiteSpace = wrap === true ? 'normal' : 'nowrap';

  if (!enablesSort) {
    return (
      <MTableCell component="th" scope="row" style={{whiteSpace}}>
        {children}
      </MTableCell>
    );
  }

  const sortAria = isSortActive ? sortDirection ?? undefined : false;
  const sortIconDirection = enablesSort ? sortAria || 'asc' : undefined;

  return (
    <MTableCell
      ref={ref}
      component="th"
      scope="row"
      sortDirection={sortAria}
      {...draggableProps}
      {...dragHandleProps}
      style={{whiteSpace}}>
      <TableSortLabel
        data-testid={`column-sort-label-${fieldId}`}
        active={isSortActive}
        direction={sortIconDirection}
        onClick={handleTableSortLabelClicked}
        style={{whiteSpace}}>
        {children}
        {isSortActive && (
          <span style={{display: 'none'}}>{sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
        )}
      </TableSortLabel>
    </MTableCell>
  );
});

type TableHeaderRowProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Column<any>[];
  order: Order | null;

  showSelection: boolean;
  enablesSort: boolean;
  enablesDragColumn: boolean;
  rowSize: 'medium' | 'small';

  onSelectAllChange: React.ChangeEventHandler<HTMLInputElement>;
  onOrderChange: (e: React.MouseEvent, columnIndex: number, direction: 'asc' | 'desc') => void;
};

const TableHeaderRow = (props: TableHeaderRowProps) => {
  const {columns, order, showSelection, enablesSort, enablesDragColumn, rowSize, onSelectAllChange, onOrderChange} =
    props;

  return (
    <>
      {showSelection && <TableHeaderCheckbox size={rowSize} onSelectAllChange={onSelectAllChange} />}
      {columns.map((col, index) => {
        return enablesDragColumn ? (
          <Draggable key={col.field} draggableId={col.field} index={index}>
            {(innerProvided, _innerSnapshot) => (
              <TableHeaderColumn
                ref={innerProvided.innerRef}
                wrap={isNullish(col.headerWrap) ? false : col.headerWrap}
                draggableProps={innerProvided.draggableProps}
                dragHandleProps={innerProvided.dragHandleProps ?? undefined}
                fieldId={col.field}
                enablesSort={enablesSort && (col.sorting ?? true)}
                sortFieldId={order?.fieldId ?? null}
                sortDirection={order?.direction ?? null}
                onOrderChange={(e, nextDirection) => onOrderChange(e, index, nextDirection)}>
                {col.title}
              </TableHeaderColumn>
            )}
          </Draggable>
        ) : (
          <TableHeaderColumn
            key={col.field}
            fieldId={col.field}
            wrap={isNullish(col.headerWrap) ? false : col.headerWrap}
            enablesSort={enablesSort && (col.sorting ?? true)}
            sortFieldId={order?.fieldId ?? null}
            sortDirection={order?.direction ?? null}
            onOrderChange={(e, nextDirection) => onOrderChange(e, index, nextDirection)}>
            {col.title}
          </TableHeaderColumn>
        );
      })}
    </>
  );
};

type TableHeaderRowContainerProps<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Column<any>[];
  order: Order | null;

  selectionActions: SelectionAction<T>[];
  showSelection: boolean;
  enablesSort: boolean;
  enablesDragColumn: boolean;
  rowSize: 'medium' | 'small';
  showsAllSelection: boolean;

  onSelectAllChange: React.ChangeEventHandler<HTMLInputElement>;
  onOrderChange: (e: React.MouseEvent, columnIndex: number, direction: 'asc' | 'desc') => void;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const TableHeaderRowContainer = <T extends {}>(props: TableHeaderRowContainerProps<T>) => {
  const {
    columns,
    order,
    showSelection,
    enablesSort,
    rowSize,
    enablesDragColumn,
    selectionActions,
    showsAllSelection,
    onSelectAllChange,
    onOrderChange,
    onDragEnd,
  } = props;

  const numSelected = useAtomValue(numSelectedAtom);
  const showsActionBar = numSelected > 0;
  const numColumns = columns.length + (showSelection ? 1 : 0);
  const selectAll = useSelectAll();
  const handleSelectAll = useCallback(
    (e: React.MouseEvent) => {
      selectAll(true);
    },
    [selectAll]
  );

  const content = (
    <TableHeaderRow
      columns={columns}
      order={order}
      showSelection={showSelection}
      enablesSort={enablesSort}
      enablesDragColumn={enablesDragColumn}
      rowSize={rowSize}
      onSelectAllChange={onSelectAllChange}
      onOrderChange={onOrderChange}
    />
  );
  return enablesDragColumn ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, _snapshot) => (
          <>
            <MTableRow {...provided.droppableProps} ref={provided.innerRef}>
              {content}
              {provided.placeholder}
            </MTableRow>
            {showsActionBar && (
              <ActionBar
                actionSize={rowSize}
                numColumns={numColumns}
                showsAllSelection={showsAllSelection}
                actions={selectionActions}
                onClickSelectAll={handleSelectAll}
              />
            )}
          </>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <>
      <MTableRow>{content}</MTableRow>
      {showsActionBar && (
        <ActionBar
          actionSize={rowSize}
          numColumns={numColumns}
          showsAllSelection={showsAllSelection}
          actions={selectionActions}
          onClickSelectAll={handleSelectAll}
        />
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const TableHead = <T extends {}>(props: TableHeadProps<T>) => {
  const {
    showSelection,
    enablesSort,
    enablesDragColumn = false,
    rowSize,
    defaultOrder,
    onSelectionChange,
    onOrderChange,
    onColumnsOrderChange,
    selectionActions,
    showsAllSelection,
  } = props;

  const classes = useStyles();
  const initialized = useInitialized();
  const [columns, setColumns] = useAtom(columnsAtom);
  const [order, setOrder] = useAtom(orderAtom);
  const selectAll = useSelectAll();

  useOnlyOnce(() => {
    if (defaultOrder) setOrder(defaultOrder);
  }, initialized);

  const handleSelectAllChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const newData = await selectAll(e.target.checked);

      if (onSelectionChange) {
        onSelectionChange(
          // チェックボックスにチェックの場合は全要素、
          // そうでない場合は空配列が選択要素になる
          e.target.checked ? newData : [],
          null,
          e.target.checked
        );
      }
    },
    [onSelectionChange, selectAll]
  );

  const handleOrderChange = useCallback(
    (e: React.MouseEvent, columnIndex: number, direction: 'asc' | 'desc') => {
      const column = columns[columnIndex];

      // column.sorting is active as default
      if (column && column.sorting !== false) {
        setOrder({fieldId: column.field, direction});

        if (onOrderChange) {
          onOrderChange(columnIndex, direction);
        }
      }
    },
    [columns, setOrder, onOrderChange]
  );

  const handleDragEnd = useCallback(
    ({source, destination}: DropResult) => {
      const {index: sourceIndex} = source;
      const {index: destinationIndex} = destination ?? {};

      if (isNumber(destinationIndex)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newColumns = [...columns] as Column<any>[];

        [newColumns[sourceIndex], newColumns[destinationIndex]] = [
          newColumns[destinationIndex],
          newColumns[sourceIndex],
        ];

        setColumns(newColumns);

        if (onColumnsOrderChange) {
          onColumnsOrderChange(newColumns);
        }
      }
    },
    [columns, onColumnsOrderChange, setColumns]
  );

  return (
    <MTableHead data-testid="thead" className={classes.header}>
      <TableHeaderRowContainer
        columns={columns}
        order={order}
        showSelection={showSelection}
        selectionActions={selectionActions}
        enablesSort={enablesSort}
        enablesDragColumn={enablesDragColumn}
        rowSize={rowSize}
        onSelectAllChange={handleSelectAllChange}
        onOrderChange={handleOrderChange}
        onDragEnd={handleDragEnd}
        showsAllSelection={showsAllSelection}
      />
    </MTableHead>
  );
};
