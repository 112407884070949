import {Button, Grid, styled, Typography} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import React from 'react';
import {PageRoutingMenu} from '@organisms/PageRoutingMenu';

type PageHeaderProps = {
  isReadOnly: boolean;
  onRegisterSchedule: VoidFunction;
};

export const PageHeader: React.FC<PageHeaderProps> = ({isReadOnly, onRegisterSchedule}) => {
  return (
    <Grid container>
      <Grid item>
        <PageRoutingMenu>
          <PageTitle variant={'h5'}>研修（予定）</PageTitle>
          <ArrowDropDown />
        </PageRoutingMenu>
      </Grid>
      <Grow />
      <Grid item>
        {!isReadOnly && (
          <Button variant={'contained'} color={'primary'} onClick={onRegisterSchedule}>
            研修（予定）を新規登録
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const PageTitle = styled(Typography)(({theme}) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.primary.dark,
}));

const Grow = styled('div')({
  flexGrow: 1,
});
