import {UserIndex} from '@modules/hospital_users/types';

export type FileCategory =
  | 'general'
  | 'profile'
  | 'inspection_result'
  | 'whole_product_thumbnail'
  | 'hospital_product'
  | 'fault_repair'
  | 'hospital_user_import_file'
  | 'hospital_product_import_file'
  | 'training_schedule_material'
  | 'training_schedule_trainee_list'
  | 'training_report_name_list'
  | 'maker_inspection_result';

export type FileIndex = {
  /** ファイルのハッシュID */
  hashId: string;
  /** ファイルのカテゴリ */
  category: FileCategory;
  /** ファイルのMIMEタイプ */
  fileType: string;
  /** HITOTSUでのファイル名タイプ */
  fileName: string;
  /** ファイルのフルURL */
  url: string;
  createdAt: string;
  updatedAt: string;
  createdBy: UserIndex;
  updatedBy: UserIndex;
};

export type FileListResponse = {
  data: FileIndex[];
  totalCount: number;
  page: number;
};

/** 病院内製品に添付されたファイル */
export type HospitalProductFileIndex = {
  /** Trueの場合は院内製品間でファイル共有がされている */
  isFileSharingAcrossProducts: boolean;
  hospitalProductHashId: string;
} & FileIndex;

const isFileIndex = (value: unknown): value is FileIndex => {
  if (typeof value !== 'object' || value == null) return false;
  const maybeValue = value as Partial<FileIndex>;
  return (
    typeof maybeValue.hashId === 'string' &&
    typeof maybeValue.category === 'string' &&
    typeof maybeValue.fileType === 'string' &&
    typeof maybeValue.fileName === 'string' &&
    typeof maybeValue.url === 'string' &&
    typeof maybeValue.createdAt === 'string' &&
    typeof maybeValue.updatedAt === 'string' &&
    typeof maybeValue.createdBy === 'object' &&
    typeof maybeValue.updatedBy === 'object'
  );
};

/**
 * HospitalProductFileIndexの型ガード
 * @param value 判別するAPIのレスポンス
 * @returns trueの時HospitalProductFileIndexであることが保証される
 */
export const isHospitalProductFileIndex = (value: unknown): value is HospitalProductFileIndex => {
  if (typeof value !== 'object' || value == null) return false;
  const maybeValue = value as Partial<HospitalProductFileIndex>;
  return isFileIndex(value) && typeof maybeValue.isFileSharingAcrossProducts === 'boolean';
};
