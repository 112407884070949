import {TabValues} from '@front-libs/helpers';

export const DEFAULT_ORDER = 'default_order' as const;
export const CUSTOMIZE = 'customize' as const;

export const tabs = [
  {
    label: '大エリア',
    value: 'ward',
  },
  {
    label: '小エリア',
    value: 'room',
  },
] as const;

export type TabKey = TabValues<(typeof tabs)[number]>;

export const radios = [
  {
    label: '昇順',
    value: DEFAULT_ORDER,
  },
  {
    label: 'カスタマイズ',
    value: CUSTOMIZE,
  },
] as const;

export type RadioKey = TabValues<(typeof radios)[number]>;
