import {Grid, styled, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import React, {useMemo} from 'react';
import {PopperSelectBoxButton, SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {useSearchVariables} from '../hooks';
import {useDebounceCallback} from '@front-libs/core';

import {PlanStatus, TrainingTypeValue} from '@modules/training_plans/types';
import {trainingTypeOptions} from '@modules/training_plans/constants';
import {planStatusOptions} from '../constants';

export const Toolbar: React.FC = () => {
  const {variables, setName, setStatus, setTrainingCategories} = useSearchVariables();

  const initialStatusOption = useMemo(
    () => planStatusOptions.find((item) => item.value === variables.status),
    [variables.status]
  );

  const initialTrainingCategoryOptions = useMemo(
    () => trainingTypeOptions.filter((item) => variables.trainingTypes?.includes(item.value as TrainingTypeValue)),
    [variables.trainingTypes]
  );

  const handleSearchName = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    300,
    [setName]
  );

  const handleChangeStatus = (selectValue?: SelectOptionProps) => {
    setStatus((selectValue?.value as PlanStatus) ?? null);
  };

  const handleTrainingCategoryOptions = (selectValue?: SelectOptionProps[]) => {
    setTrainingCategories(selectValue?.map((item) => item.value as TrainingTypeValue) ?? []);
  };

  return (
    <ToolBarContainer container>
      <SearchNameContainer item sm={4} md={3}>
        <SearchNameTextField
          label={'研修名で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={variables.name}
          onChange={handleSearchName}
        />
      </SearchNameContainer>
      <Grid item>
        <Grid container>
          <Grid item>
            <PopperSelectBoxButton
              buttonLabel={'ステータス'}
              options={planStatusOptions}
              isMulti={false}
              onChange={handleChangeStatus}
              searchable={false}
              initialOption={initialStatusOption}
            />
          </Grid>
          <Grid item>
            <PopperSelectBoxButton
              buttonLabel={'研修内容'}
              options={trainingTypeOptions}
              isMulti={true}
              onChange={handleTrainingCategoryOptions}
              searchable={false}
              initialOption={initialTrainingCategoryOptions}
            />
          </Grid>
        </Grid>
      </Grid>
    </ToolBarContainer>
  );
};

const ToolBarContainer = styled(Grid)({
  marginBottom: '24px',
});

const SearchNameContainer = styled(Grid)({
  marginRight: 32,
});

const SearchNameTextField = styled(TextField)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));
