import {AccordionDetails} from '@material-ui/core';
import React from 'react';

export const SectionContent: React.FC = (props) => {
  const {children} = props;
  return (
    <AccordionDetails
      style={{
        margin: '0px',
        padding: '0px',
        flexDirection: 'column',
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-in-out',
      }}>
      {children}
    </AccordionDetails>
  );
};
