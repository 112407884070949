import {Button, Theme, Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {styled} from '@material-ui/core/styles';
import {CategoryIndex} from '@modules/categories/types';
import {useAtom, useAtomValue} from 'jotai';
import React, {useEffect, useState} from 'react';
import {filterCheckListAtom, searchTextChangeAtom} from '../jotai';

const flexCenterCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const StyledHeadTextDiv = styled('div')({
  ...flexCenterCss,
});

const StyledCheckBoxDiv = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  // FIXME:%指定だとスクロールがうまく行かないことがあるので一旦PX指定
  maxHeight: '500px',
  minHeight: '100px',
  width: '100%',
  overflowY: 'auto',
});

const StyledFilterContentsDiv = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledCheckText = styled('div')({
  ...flexCenterCss,
});
const StyledCheckBox = styled(Checkbox)(({theme}: {theme: Theme}) => ({
  '&.MuiIconButton-colorPrimary': {
    color: theme.palette.grey[600],
  },
  '&.Mui-checked': {
    color: theme.palette.primary.dark,
  },
}));

/**
 * スマホ用機器一覧のフィルター入力ボックス
 * @returns
 */
export const CategoryFilter = ({categories}: {categories: CategoryIndex[]}) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const searchText = useAtomValue(searchTextChangeAtom);
  const defaultProductStatus = categories.map((category) => ({
    label: category.name,
    value: category.hashId,
  }));
  const [productStatus, setProductStatus] = useState(defaultProductStatus);
  const [categoryStatusFilterCheckList, setCategoryFilterCheckListAtom] = useAtom(filterCheckListAtom);

  // 大分類であればtrue
  const isRootCategories = categories.find((v) => v.depth === 0);

  useEffect(() => {
    if (!searchText || searchText === '') {
      setProductStatus(defaultProductStatus);
      return;
    }
    setProductStatus(defaultProductStatus.filter((v) => v.label.includes(searchText)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    // 選択解除ボタンのDisabledを切り替えるためにチェックしている箇所があるかを判別
    setDisabled(!Object.values(categoryStatusFilterCheckList).some((v) => v === true));
  }, [categoryStatusFilterCheckList]);

  /** 全フィルター解除 */
  const onClickAllDisable = () => {
    const allDisable = Object.keys(categoryStatusFilterCheckList).reduce<{[key: string]: boolean}>((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setCategoryFilterCheckListAtom({...allDisable});
  };

  /** チェック */
  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, value: string) => {
    const currentCheck = {...categoryStatusFilterCheckList, [value]: checked};
    setCategoryFilterCheckListAtom(currentCheck);
  };

  return (
    <>
      <StyledHeadTextDiv>
        <Typography>{`${isRootCategories ? '大' : '小'}分類で絞り込み`}</Typography>
        <Button color="primary" disabled={disabled} onClick={onClickAllDisable} style={{fontSize: '16px'}}>
          すべて選択解除
        </Button>
      </StyledHeadTextDiv>
      <StyledCheckBoxDiv>
        {productStatus.map((v, i) => (
          <StyledFilterContentsDiv key={`filter_${i}`}>
            <StyledCheckText>{v.label}</StyledCheckText>
            <StyledCheckBox
              value={v.value}
              checked={categoryStatusFilterCheckList[v.value] || false}
              color="primary"
              onChange={(e, checked) => handleCheckBox(e, checked, v.value)}
            />
          </StyledFilterContentsDiv>
        ))}
      </StyledCheckBoxDiv>
    </>
  );
};
