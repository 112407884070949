import {getWholeProductsInspectionProducts} from '@modules/products/api';
import {
  deleteAndCreatewholeProductsInspectionProducts,
  deleteWholeProductsInspectionProductsByHospitalHashId,
} from './repository';

// 初回&差分データ取得(DeleteAndAdd)
export const initAndUpdateWholeProductsInspectionProducts = async (hospitalHashId: string) => {
  const data = await getWholeProductsInspectionProducts(hospitalHashId);
  await deleteAndCreatewholeProductsInspectionProducts(data);
};

// 病院IDに紐づくデータを削除
export const deleteWholeProductsInspectionProducts = async (hospitalHashId: string) => {
  await deleteWholeProductsInspectionProductsByHospitalHashId(hospitalHashId);
};
