import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {axios} from '@front-libs/core';
import {
  PeriodUnit,
  InspectionPeriodProduct,
  InspectionPeriodProductWithCount,
  WholeProductsInspectionsPeriodProducts,
} from './types';
import {getInspectionPeriodsKey, getInspectionPeriodProductsByAssignedKey} from '@constants/api';
import {InspectionType} from '../inspections/enum';
import {Inspection} from '../inspection/types';
import {WholeProductIndex} from '../products/types';

export type InspectionProduct = {
  hashId: string;
  hospitalHashId: string;
  wholeProductHashId: string;
  inspectionHashId: string;
  wholeProduct: WholeProductIndex;
};

export type InspectionProductWithWholeProduct = InspectionProduct & {
  wholeProduct: InspectionPeriodProductWithCount;
};

export type FetchInspectionPeriodProductsParam = {
  perPage?: number;
  page?: number;
  inspectionHashId?: string;
  hasInspectionId?: boolean;
  hasInspectionPeriod?: boolean;
  inspectionType: InspectionType;
  name?: string;
  categoryHashIds?: string;
  makerHashId?: string;
};

export type FetchWholeProductsInspectionsPeriodProductsParam = {
  perPage?: number;
  page?: number;
  inspectionHashId?: string;
  hasInspectionId?: boolean;
  hasInspectionPeriod?: boolean;
  inspectionType: InspectionType;
  name?: string;
  categoryHashIds?: string;
  makerHashIds?: string;
  inspectionName?: string;
  order?: string;
};

export type FetchInspectionPeriodProductsByAssignedParam = {
  perPage?: number;
  page?: number;
  inspectionHashId: string;
  inspectionType: InspectionType;
  name?: string;
  categoryHashIds?: string;
};

export type FetchWholeProductsInspectionsPeriodProductsResponse = {
  totalCount: number;
  page: number;
  data: WholeProductsInspectionsPeriodProducts[];
};

export type FetchInspectionProductParam = {
  inspectionType: InspectionType;
};

export type FetchInspectionPeriodProductsResponse = {
  totalCount: number;
  page: number;
  data: InspectionPeriodProduct[];
};

export type FetchInspectionPeriodProductsByAssignedResponse = {
  totalCount: number;
  page: number;
  data: InspectionPeriodProductWithCount[];
};

export type FetchInspectionProductResponse = InspectionProduct & {
  inspection: Inspection;
};

export type InspectionPeriodParam = {
  wholeProductHashId: string;
  inspectionHashId?: string;
  periodicInspectionPeriod?: number;
  periodicInspectionPeriodUnit?: PeriodUnit;
  makerInspectionPeriod?: number;
  makerInspectionPeriodUnit?: PeriodUnit;
};

export type UpdateInspectionPeriodParam = {
  wholeProductHashId?: string;
  periodicInspectionPeriod?: number;
  periodicInspectionPeriodUnit?: PeriodUnit;
  makerInspectionPeriod?: number;
  makerInspectionPeriodUnit?: PeriodUnit;
};

export type UpdateInspectionPeriodsParam = {
  inspectionHashId: string;
  addWholeProductHashIds: string[];
  deleteWholeProductHashIds: string[];
};

export type UpdateInspectionPeriodsResponse = {
  createdItemCount: number;
  deletedItemCount: number;
};

export const getInspectionPeriodProducts = async (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsParam
) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspections/settings/products`;
  const {data} = await axios.get<FetchInspectionPeriodProductsResponse>(baseURL, {params});

  return data;
};

export const getInspectionPeriodProductsByAssigned = async (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsByAssignedParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/settings/products/assigned_non_assigined`;
  const {data} = await axios.get<FetchInspectionPeriodProductsByAssignedResponse>(baseURL, {params});

  return data;
};

export const getInspectionProduct = async (
  hospitalHashId: string,
  wholeProductHashId: string,
  params: FetchInspectionProductParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/settings/products/${wholeProductHashId}`;
  const {data} = await axios.get<FetchInspectionProductResponse>(baseURL, {params});

  return data;
};

export const updateInspectionPeriods = async (hospitalHashId: string, params: UpdateInspectionPeriodsParam) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/inspections/settings/products/jobs`;
  const {data} = await axios.post<UpdateInspectionPeriodsResponse>(baseURL, params);
  return data;
};

export const updateInspectionPeriod = async (hospitalHashId: string, params: UpdateInspectionPeriodParam) => {
  const baseURL = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/inspections/settings/periods`;
  const {data} = await axios.put<unknown>(baseURL, params);
  return data;
};

export const useFetchInspectionPeriodProductsQuery = (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsParam
) => {
  const query = useQuery([getInspectionPeriodsKey, hospitalHashId, params], () =>
    getInspectionPeriodProducts(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchInspectionPeriodProductsByAssignedQuery = (
  hospitalHashId: string,
  params: FetchInspectionPeriodProductsByAssignedParam
) => {
  const query = useQuery([getInspectionPeriodProductsByAssignedKey, hospitalHashId, params], () =>
    getInspectionPeriodProductsByAssigned(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const getWholeProductsInspectionsPeriodProducts = async (
  hospitalHashId: string,
  params: FetchWholeProductsInspectionsPeriodProductsParam
) => {
  const baseURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/whole_products/inspections/assigned`;
  const {data} = await axios.get<FetchWholeProductsInspectionsPeriodProductsResponse>(baseURL, {params});

  return data;
};

export const useFetchWholeProductsInspectionsPeriodProductsQuery = (
  hospitalHashId: string,
  params: FetchWholeProductsInspectionsPeriodProductsParam
) => {
  const query = useQuery([getInspectionPeriodsKey, hospitalHashId, params], () =>
    getWholeProductsInspectionsPeriodProducts(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
