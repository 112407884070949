import {useAtomValue} from 'jotai';
import {useQuery} from 'react-query';
import {FetchFaultRepairsParams, FetchFaultRepairsResult} from '@modules/repairs/types';
import {getFaultRepairsKey} from '@constants/api';
import {getFaultRepairs} from '@modules/repairs/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {faultRepairParams} from './jotai';
import {useCallback, useState} from 'react';
import {TableLayoutResult, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';

export const useFaultRepairsQuery = (additionalParams?: FetchFaultRepairsParams) => {
  const {myInfo} = useMyInfo();
  const filterValues = useAtomValue(faultRepairParams);
  const params: FetchFaultRepairsParams = {
    ...filterValues,
    ...additionalParams,
  };
  const query = useQuery<FetchFaultRepairsResult>([getFaultRepairsKey, params], async () => {
    return await getFaultRepairs(myInfo.hospitalHashId, params);
  });

  return {
    ...query,
    data: query.data?.data ?? [],
    totalCount: query?.data?.totalCount ?? 0,
  };
};

export type UpdateKanbanStatusObserver = (hashIds: string[]) => void | Promise<void>;

class UpdateKanbanStatusSubject {
  public observers: UpdateKanbanStatusObserver[];

  constructor() {
    this.observers = [];
  }

  public subscribe(func: UpdateKanbanStatusObserver) {
    this.observers.push(func);
  }

  public unsubscribe(func: UpdateKanbanStatusObserver) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notify(hashIds: string[]) {
    this.observers.forEach((observer) => observer(hashIds));
  }
}

const updateKanbanStatusSubject = new UpdateKanbanStatusSubject();

export const useUpdateKanbanStatusSubject = () => {
  const subscribe = useCallback((func: UpdateKanbanStatusObserver) => {
    updateKanbanStatusSubject.subscribe(func);
  }, []);
  const unsubscribe = useCallback((func: UpdateKanbanStatusObserver) => {
    updateKanbanStatusSubject.unsubscribe(func);
  }, []);
  const notify = useCallback((hashIds: string[]) => {
    updateKanbanStatusSubject.notify(hashIds);
  }, []);
  return {subscribe, unsubscribe, notify};
};

export const useKanbanCardLayout = () => {
  const [cardLayout, setCardLayoutState] = useTableLayout('repairKanban');
  const [cardFields, setCardFields] = useState<string[]>((cardLayout?.currentLayout ?? []).map((item) => item.field));

  const setCardLayout = useCallback(
    (newCardLayout: TableLayoutResult) => {
      setCardFields(newCardLayout.currentLayout.map((item) => item.field));
      setCardLayoutState(newCardLayout);
    },
    [setCardLayoutState]
  );

  return {
    cardLayout,
    setCardLayout,
    cardFields,
  };
};
