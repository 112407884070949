import React, {memo} from 'react';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {StyledMenuButtonContainer, StyledExpandMoreIcon} from './InspectionStatusBar';
import {InspectionTypeBarProps, InspectionTypeMenuItemList} from './InspectionTypeBar';

/**
 * 点検タイプ切り替えセレクトボックス
 * @param props.type 現在選択されている点検タイプ
 * @param props.onChangeType 項目選択時のコールバック関数
 */
export const InspectionTypeSelectBox = memo(({type, onChangeType}: InspectionTypeBarProps) => {
  /**
   * ボタン押下時の処理
   *
   * @param selectedType - 選択された点検ステータスのオプション値。
   */
  const onClick = (item: MenuItemType, e: React.MouseEvent<Document, MouseEvent>) => {
    onChangeType(item.value);
  };
  // 選択されている項目の背景色を変更する対応
  const selectedMenuItemList = InspectionTypeMenuItemList.map((v) => ({
    ...v,
    selectedMenuItem: v.value === type,
  }));

  return (
    <PopperMenuButton
      onMenuClick={onClick}
      containerProps={{style: {display: 'inline-flex'}}}
      buttonProps={{color: 'primary'}}
      menuItemList={selectedMenuItemList}
      hiddenArrow={true}>
      <StyledMenuButtonContainer>
        {InspectionTypeMenuItemList.find((v) => v.value === type)?.label || '未選択'}
        <StyledExpandMoreIcon />
      </StyledMenuButtonContainer>
    </PopperMenuButton>
  );
});
