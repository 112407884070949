import React, {useLayoutEffect} from 'react';
import {Box, Grid, styled} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {RentalCard} from './RentalCard';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {useNavigateRentOrReturn} from './hooks';
import {useDetectCameraEffect} from '../hooks/useDetectCameraEffect';
import {rentOrReturnSettingAtom} from '../states';
import {useAtomValue} from 'jotai';

export const RentOrReturn: React.VFC = () => {
  const templateClasses = useTemplate();
  const {proceedRent, proceedReturn} = useNavigateRentOrReturn();
  const rentOrReturnSetting = useAtomValue(rentOrReturnSettingAtom);

  useDetectCameraEffect();

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
    });
    setOuterHeight();
    setOuterWidth();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
      });
    };
  }, []);

  return (
    <>
      <Header showLogo />
      <Box id={'mainContent'} className={templateClasses.content} style={defaultHeightWidth}>
        <ContainerGrid container>
          {rentOrReturnSetting !== 'onlyReturnMenu' && (
            <ItemGrid item>
              <RentalCard label={'貸出'} value={'rent'} onChange={proceedRent} checked={false} />
            </ItemGrid>
          )}
          {rentOrReturnSetting !== 'onlyRentalMenu' && (
            <ItemGrid item>
              <RentalCard label={'返却'} value={'return'} onChange={proceedReturn} checked={false} />
            </ItemGrid>
          )}
        </ContainerGrid>
      </Box>
      <Footer text={'選択してください'} backButtonText={'貸出・返却一覧に戻る'} backButtonSize="large" />
    </>
  );
};

const ContainerGrid = styled(Grid)(({theme}) => ({
  placeContent: 'center',
  gridGap: '96px',
  [theme.breakpoints.up('laptop')]: {
    gridGap: '128px',
  },
}));

const ItemGrid = styled(Grid)(({theme}) => ({
  width: '100%',
  height: '100%',
  maxWidth: 384,
  maxHeight: 368,
}));
