import React from 'react';
import {Typography, Grid, makeStyles, TextField} from '@material-ui/core';
import {useAtomValue} from 'jotai';
import {newInspectionAtom} from '../../jotai';

const useStyles = makeStyles((theme) => ({
  fields: {
    fontSize: '16px',
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
}));

type PeriodsColumnProps = {
  newPeriod?: number;
  period?: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PeriodsColumn: React.FC<PeriodsColumnProps> = (props) => {
  const {newPeriod, period, handleChange} = props;
  const classes = useStyles();
  const newInspection = useAtomValue(newInspectionAtom);

  return (
    <Grid container direction="column" className={classes.fields} style={{color: '#172B4D'}}>
      新たな点検表を紐づけ、保存しました。
      <br />
      次に点検間隔を設定してください。
      <Grid>
        <Typography>紐づけた点検表:{newInspection?.name}</Typography>
      </Grid>
      <Grid container alignItems="center">
        <Typography>点検間隔</Typography>
        <Grid container direction="row" alignItems="center">
          <Grid item style={{width: '80px'}}>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              size="small"
              value={newPeriod}
              defaultValue={period ?? ''}
              InputProps={{inputProps: {min: 1, max: 12}}}
              onChange={handleChange}
            />
          </Grid>
          <Typography>ヶ月</Typography>
        </Grid>
      </Grid>
      <Grid>
        <Typography>
          後で設定する場合は、「点検対象製品の設定」の「点検間隔」の項目から設定することができます。
        </Typography>
      </Grid>
    </Grid>
  );
};
