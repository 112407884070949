import {Box, makeStyles} from '@material-ui/core';
import React from 'react';
import {ResultSummaryHeader} from './ResultSummaryHeader';
import {HospitalProductInfo} from '@organisms/HospitalProductInfo/sp';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {ResultSummaryContent} from './ResultSummaryContent';
import {InspectionIndex} from '@modules/inspections/types';

export const ResultSummary: React.FC<ResultSummaryProps> = (props) => {
  const {inspectionResult, inspection} = props;

  const classes = useStyles();
  return (
    <Box className={classes.resultSummaryRoot}>
      <ResultSummaryHeader />
      <HospitalProductInfo hospitalProduct={inspectionResult.hospitalProduct} backgroundColor="white" />
      <ResultSummaryContent inspectionResult={inspectionResult} inspection={inspection} />
    </Box>
  );
};

type ResultSummaryProps = {
  inspectionResult: InspectionResultIndex;
  inspection: InspectionIndex;
};

const useStyles = makeStyles(() => ({
  resultSummaryRoot: {
    backgroundColor: 'white',
    borderRadius: '16px',
  },
}));
