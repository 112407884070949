import {Box, Typography, makeStyles} from '@material-ui/core';
import React from 'react';

export const ItemDescription: React.FC<ItemDescriptionProps> = (props) => {
  const {description} = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.descriptionTxt}>{description}</Typography>
    </Box>
  );
};

type ItemDescriptionProps = {
  description: string;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  descriptionTxt: {
    fontSize: '14px',
    color: '#65676B',
    margin: '0px',
  },
}));
