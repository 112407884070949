import React, {useCallback} from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Divider,
  Typography,
  TextField,
  Box,
  Avatar,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {Sidebar} from '@components/organisms/Sidebar';
import {InnerLoading} from '@molecules/Loading';
import {Form, Formik} from 'formik';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {PopperMenuButton, MenuItemType} from '@molecules/Buttons/PopperMenuButton';
import {UploadImageDialog} from './Dialogs/UploadImageDialog';
import {updateHospitalUser, UpdateHospitalUserParams, useFetchMyInfo} from '@modules/hospital_users/api';
import {openSnackBar} from '@components/molecules/SnackBar';
import {deleteFile} from '@modules/files/api';
import Image from '@assets/image.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontSize: 28,
    },
    formControl: {
      marginTop: 24,
    },
    formHelperText: {
      fontSize: 14,
      color: theme.palette.grey[600],
    },
    circleContainer: {
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    avatarIcon: {
      width: 56,
      height: 56,
    },
    avatarIconOverlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      backgroundColor: 'rgba(70, 72, 75, 0.8)',
      borderRadius: 100,
      transition: 'opacity 0.25s',
      opacity: 0,
      '&:hover': {
        opacity: 1,
      },
    },
    editIcon: {
      color: theme.palette.common.white,
      display: 'block',
    },
    popperMenuButtonBtn: {
      padding: 0,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    textFieldsContainer: {
      marginBottom: 45,
    },
    fontBold: {
      fontWeight: 700,
    },
    text: {
      color: '#172B4D',
      fontSize: '20px',
      marginBottom: '24px',
    },
    image: {
      maxHeight: '380px',
    },
  })
);

const ProfileForm: React.FC = () => {
  const {myInfo} = useMyInfo();
  const classes = useStyles();
  const templateClasses = useSettingsContentTemplate();
  const {data, isLoading, refetch} = useFetchMyInfo(myInfo.hashId);

  const profileImageMenuItems = [
    {
      label: '画像をアップロード',
      value: 'upload',
    },
    {
      label: '画像を削除',
      value: 'delete',
    },
  ];

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    try {
      await deleteFile(data?.thumbnailFile?.hashId || '');
      await refetch();
      openSnackBar('プロファイルを削除しました');
    } catch (error) {
      openSnackBar('プロファイルの削除に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  const handleMenuClick = useCallback((item: MenuItemType) => {
    switch (item.value) {
      case 'upload':
        handleClickOpen();
        break;

      case 'delete':
        handleDelete();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (res: UpdateHospitalUserParams) => {
    try {
      const updateData: UpdateHospitalUserParams = {
        lastName: res?.lastName,
        firstName: res?.firstName,
        email: res?.email,
      };
      await updateHospitalUser(myInfo.hospitalHashId, myInfo.hashId, updateData);
      await refetch();
      openSnackBar('プロファイルを更新しました');
    } catch (error) {
      openSnackBar('プロファイルの更新に失敗しました', 'left', 'bottom', 'error');
      throw error;
    }
  };

  if (isLoading) {
    return <InnerLoading />;
  }

  return (
    <Formik initialValues={data || myInfo} onSubmit={handleSubmit} enableReinitialize={true}>
      {(props) => {
        return (
          <Form className={templateClasses.form}>
            <Grid container className={templateClasses.grid}>
              <Grid item className={templateClasses.sideBar}>
                <Sidebar />
              </Grid>
              <Grid item className={templateClasses.content}>
                <Grid container style={{marginBottom: '32px'}}>
                  <Grid item>
                    <Typography variant={'h1'} className={classes.pageTitle}>
                      プロファイル
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.text}>
                  氏名、ユーザーID、パスワード、メールアドレスはHITOTSU Hospitalで変更できます。
                </Grid>
                <Grid>
                  <a href={import.meta.env.VITE_ACCOUNT_SERVICE_URL} target="_blank" rel="noreferrer">
                    <Button variant="contained" color="primary">
                      HITOTSU Hospitalへ移動
                    </Button>
                  </a>
                </Grid>
                <div>
                  <div className={classes.text} style={{marginTop: '24px', marginBottom: 0}}>
                    変更手順:
                  </div>
                  <Grid container style={{gap: '24px'}}>
                    <Grid item>
                      <img src={Image} alt="操作方法" width={750} />
                    </Grid>
                    <Grid item style={{marginTop: 24}}>
                      <Typography>①HITOTSU Hospitalにアクセスし、右上に表示されるユーザー名をクリック</Typography>
                      <Typography>②プロフィールの右上に出てくる編集ボタンをクリック</Typography>
                      <Typography>③変更したい項目を選び、変更する</Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <FormikFormSubmitDrawer />
          </Form>
        );
      }}
    </Formik>
  );
};

export const AccountProfile: React.FC = () => {
  const templateClasses = useSettingsContentTemplate();

  return (
    <Grid container className={templateClasses.grid}>
      <ProfileForm />
    </Grid>
  );
};

// export const AccountProfile = withSuspense(_SettingsAccountProfile, null);
