import React, {useMemo} from 'react';
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import {CloudUpload} from '@material-ui/icons';

const baseStyle = {
  flex: 1,
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const Dropzone: React.FC<DropzoneOptions> = (props) => {
  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({...props});

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const child = useMemo(() => {
    return props.children ? (
      props.children
    ) : (
      <span>
        ファイルをドロップするか、 <br /> 枠内をクリックしてファイルを選択ください
      </span>
    );
  }, [props.children]);

  // const previewUrl = useMemo(() => {
  //   if (file !== null) {
  //     return URL.createObjectURL(file);
  //   } else {
  //     return null;
  //   }
  // }, [file]);

  return (
    <div className="container" style={{textAlign: 'center'}}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <CloudUpload style={{textAlign: 'center', fontSize: '5rem'}} />
        <p style={{textAlign: 'center', marginTop: 0}}>{child}</p>
      </div>
    </div>
  );
};
