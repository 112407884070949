import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {getHospitalProductPmdaFilesKey} from '@constants/api';
import {CreatePmdaFileParam, PmdaFile, PmdaFilesResult} from './types';

export const getHospitalProductPmdaFiles = async (hospitalHashId: string, hospitalProductHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/pmda_files`;
  const response = await axios.get<PmdaFilesResult>(baseUrl);
  return response.data;
};

export const deletePmdaFile = async (hospitalHashId: string, hospitalProductHashId: string, pmdaFileHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/pmda_files/${pmdaFileHashId}`;
  return await axios.delete(baseUrl);
};

export const useHospitalProductPmdaFilesQuery = (hospitalHashId: string, hospitalProductHashId: string) => {
  return useQuery([getHospitalProductPmdaFilesKey, hospitalHashId], () =>
    getHospitalProductPmdaFiles(hospitalHashId, hospitalProductHashId)
  );
};

export const createPmdaFile = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  data: CreatePmdaFileParam
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/pmda_files`;
  const response = await axios.post<PmdaFile>(baseUrl, data);
  return response.data;
};
