import React from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';

type SameHospitalProductDialogProps = {
  managementId: string;
  statusName: string;
} & DialogProps;

export const SameHospitalProductDialog: React.FC<SameHospitalProductDialogProps> = (props) => {
  const {managementId, statusName, ...rest} = props;
  return (
    <SimpleDialog
      {...rest}
      title={'対象機器が重複しています'}
      positiveButtonLabel={'登録'}
      negativeButtonLabel={'キャンセル'}
      content={
        <>
          <div>
            管理番号「{managementId}」 の機器は「{statusName}」のレーンに存在しています。
          </div>
          <br />
          <div>重複して修理案件を登録しますか？</div>
        </>
      }
    />
  );
};
