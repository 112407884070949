import React, {useCallback, useMemo} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {Form, Formik, useFormikContext} from 'formik';
import {HospitalWard} from '@modules/hospital_places/types';
import {TextField} from '@molecules/Formik/fields';
import {yup} from '@front-libs/core';

const HospitalWardForm: React.VFC<WardEditationDialogProps> = (props) => {
  const {submitForm, values, isValid} = useFormikContext();
  const handleClickResist = useCallback(() => {
    submitForm();
    props.actions.resolve(values);
  }, [props.actions, submitForm, values]);

  return (
    <Dialog
      open={props.open}
      onClose={props.actions.reject}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}>
      <DialogTitle>大エリアを編集する</DialogTitle>
      <DialogContent>
        <Grid container>
          <Form style={{width: '100%'}}>
            <div style={{marginBottom: '16px'}}>
              <label>病棟名</label>
              <TextField placeholder="東棟" name="name" size={'small'} fullWidth />
            </div>
          </Form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isValid} variant={'contained'} color="primary" onClick={handleClickResist}>
          保存
        </Button>
        <Button onClick={props.actions.reject} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type WardEditationDialogProps = {
  ward: HospitalWard;
} & DialogProps;

export const NewEditationDialog: React.VFC<WardEditationDialogProps> = (props) => {
  const validationSchema = yup.object({
    name: yup.string().required(),
    groundFloorNumber: yup.number(),
    basementFloorNumber: yup.number(),
  });

  const initialValues = useMemo(
    () => ({
      name: props.ward.name,
    }),
    [props.ward.name]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (res: any) => {
    props.actions.resolve(res);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}>
      <HospitalWardForm {...props} />
    </Formik>
  );
};
