import {Box, TextField, makeStyles} from '@material-ui/core';
import React from 'react';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import dayjs from 'dayjs';
import {convertDateToSimpleDateTime} from '@front-libs/helpers';
import {useFormikContext} from 'formik';
import {FinishInspectionFormValue} from '.';

export const InspectionRecord: React.FC = () => {
  const classes = useStyles();
  const maxDateTime = convertDateToSimpleDateTime(new Date());
  const {setFieldValue, values} = useFormikContext<FinishInspectionFormValue>();
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <RequiredLabel>点検完了日時</RequiredLabel>
        <TextField
          type="datetime-local"
          name="completedAt"
          value={values.completedAt}
          onChange={(e) => {
            setFieldValue('completedAt', e.target.value);
          }}
          size="small"
          fullWidth
          InputProps={{
            inputProps: {
              max: maxDateTime,
              style: {},
            },
          }}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px',
    width: '240px',
    height: '72px',
    margin: '8px 16px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
  },
  txt: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#172B4D',
  },
}));
