import {Box, Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {InspectionItem} from '@modules/inspections/api';

export const ItemContent: React.FC<ItemContentProps> = (props) => {
  const {error, value, item} = props;
  return (
    <div>
      {item.type === 'multi-select' ? (
        <MultiSelectItems error={error} value={value} item={item} />
      ) : (
        <SingleSelectItems error={error} value={value} item={item} />
      )}
    </div>
  );
};

type ItemContentProps = {
  error?: 'error' | null;
  value: any;
  item: InspectionItem & {id: string};
};

const MultiSelectItems: React.FC<ItemContentProps> = (props) => {
  const {error, value} = props;
  console.log(value);
  const classes = useStyles();
  const isError = error === 'error';
  return (
    <div>
      {isError ? (
        <div>
          <ErrorOutlineIcon className={classes.errIcon} />
          {Object.keys(value)
            .filter((key) => value[key] === true)
            .map((key) => (
              <Typography className={classes.errTxt}>{key}</Typography>
            ))}
        </div>
      ) : (
        Object.keys(value)
          .filter((key) => value[key] === true)
          .map((key) => <Typography>{key}</Typography>)
      )}
    </div>
  );
};

const SingleSelectItems: React.FC<ItemContentProps> = (props) => {
  const {error, value} = props;
  const classes = useStyles();
  const isError = error === 'error';
  return (
    <div>
      {isError ? (
        <Box className={classes.box}>
          <ErrorOutlineIcon className={classes.errIcon} />
          <Typography className={classes.errTxt}>{value}</Typography>
        </Box>
      ) : (
        <Typography>{value}</Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  errIcon: {
    color: '#C7243A',
    alignContent: 'center',
    paddingRight: '4px',
  },
  errTxt: {
    color: '#C7243A',
  },
}));
