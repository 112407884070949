import {styled, Typography} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import React, {useCallback, useMemo, useState} from 'react';
import {PmdaFile} from '@modules/pmda_files/types';
import dayjs from 'dayjs';
import {isNullish} from '@front-libs/helpers';
import {DeleteIconButton} from '@molecules/Buttons/DeleteIconButton';

type PopperPmdaVersionButtonProps = {
  pmdaFiles: PmdaFile[] | [];
  onClickSettingMenu: (url: string) => void;
  onClickDelete: (hashId: string) => void;
};

export const PmdaPopperVersionButton: React.VFC<PopperPmdaVersionButtonProps> = (props) => {
  const {pmdaFiles, onClickSettingMenu, onClickDelete} = props;
  const [selectedFile, setSelectedFile] = useState(pmdaFiles.at(0) ?? null);
  const menuItemList = pmdaFiles?.map((pmdaFile, index) => {
    const {createdAt, isUserUpload, file} = pmdaFile;
    const version = `${dayjs(createdAt).format('YYYY年MM月DD日')}版 ${isUserUpload ? '手動アップロード' : '自動更新'}`;
    const label = index === 0 ? `${version} (最新)` : version;
    return {label, value: file.hashId} as MenuItemType;
  });

  const displayLabel = useMemo(
    () =>
      !isNullish(selectedFile) ? `最新のバージョン(${dayjs(selectedFile.createdAt).format('YYYY年MM月DD日')}時点)` : '',
    [selectedFile]
  );

  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      const file = pmdaFiles.find((pmdaFile) => pmdaFile.file.hashId === item.value);
      if (isNullish(file)) return;

      onClickSettingMenu(file.file.url);
      setSelectedFile(file);
    },
    [onClickSettingMenu, pmdaFiles]
  );

  const handleClickDelete = useCallback(() => {
    if (isNullish(selectedFile)) return;
    onClickDelete(selectedFile.hashId);
  }, [onClickDelete, selectedFile]);

  return (
    <div style={{display: 'flex', flexDirection: 'row', flexGrow: 1, justifyContent: 'space-between'}}>
      <PopperMenuButton
        placement="bottom-start"
        hiddenArrow={true}
        buttonProps={{variant: 'text', style: {width: '100%', padding: '0'}}}
        menuItemList={menuItemList || []}
        itemProps={{style: {minWidth: '350px'}}}
        onMenuClick={handleMenuClick}>
        {pmdaFiles.length != 0 && <LabelTypography>{displayLabel}</LabelTypography>}
        <ArrowDropDown />
      </PopperMenuButton>
      {selectedFile?.isUserUpload && <DeleteIconButton onClickDelete={handleClickDelete} />}
    </div>
  );
};

const LabelTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
}));
