import React, {useCallback} from 'react';
import {Button, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {RepairIndex} from '@modules/repairs/types';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    managementId: {
      padding: '0px',
      display: 'inline',
      color: theme.palette.info.dark,
      fontSize: '14px',
      fontWeight: 'bold',
    },
  })
);

export const RouterLinkColumn: React.VFC<RepairIndex> = (props) => {
  const {faultHospitalProduct} = props;
  const classes = useStyle();
  const navigate = useNavigate();

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(`/products/${faultHospitalProduct.hashId}`);
    },
    [faultHospitalProduct.hashId, navigate]
  );

  return (
    <Button onClick={onClick} className={classes.managementId}>
      <Typography className={classes.managementId}>{faultHospitalProduct.managementId}</Typography>
    </Button>
  );
};
