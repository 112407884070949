import {useGetHospitalDescendantCategories} from '@modules/categories/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useSelectView, useSelectedRootCategoryHashId} from './states';
import {useFaultRatioQuery} from '@modules/repairs/hooks';
import {useHospitalFiscalYear} from '@modules/hospital/hooks';
import {isNullish} from '@front-libs/helpers';
import {seriesForChartBySymptomCategory, seriesForChartByRootCauseCategory} from './constants';
import {FaultRatio} from '@modules/repairs/api/faultRatioApi';
import {RootCauseCategoryType, SymptomCategoryType} from '@modules/repairs/types';

export const useXAxisCategories = () => {
  const {myInfo} = useMyInfo();
  const [rootCategoryHashId] = useSelectedRootCategoryHashId();
  const {data: descendantCategories} = useGetHospitalDescendantCategories(
    myInfo.hospitalHashId,
    rootCategoryHashId ?? undefined
  );

  return descendantCategories.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)).map((item) => item.name);
};

export const useFaultRatioSeries = () => {
  const {myInfo} = useMyInfo();
  const [selectView] = useSelectView();
  const [rootCategoryHashId] = useSelectedRootCategoryHashId();
  const {fiscalYear} = useHospitalFiscalYear();

  const {data: faultRatio} = useFaultRatioQuery({
    type: selectView,
    params: {
      hospitalHashId: myInfo.hospitalHashId,
      rootCategoryHashId: rootCategoryHashId ?? '',
      fiscalYear: fiscalYear,
    },
    options: {
      enabled: !isNullish(rootCategoryHashId),
    },
  });
  const xAxisCategories = useXAxisCategories();
  const series: {name: string; color: string; data: number[]}[] = [];

  const baseSeries =
    selectView === 'symptomCategory' ? seriesForChartBySymptomCategory : seriesForChartByRootCauseCategory;

  Object.keys(baseSeries).forEach((key) => {
    if (selectView === 'symptomCategory') {
      const symptomCategoryRatio =
        (faultRatio?.data as (FaultRatio & {symptomCategory: SymptomCategoryType})[] | undefined) ?? [];
      const symptomCategoryData = symptomCategoryRatio.filter((item) => item.symptomCategory === key);
      const seriesData = xAxisCategories.map(
        (xAxis) => symptomCategoryData.find((item) => item.narrowCategoryName === xAxis)?.faultRatio ?? 0
      );

      series.push({
        ...seriesForChartBySymptomCategory[key as SymptomCategoryType],
        data: seriesData,
      });
    } else {
      const rootCauseCategoryRatio =
        (faultRatio?.data as (FaultRatio & {rootCauseCategory: RootCauseCategoryType})[] | undefined) ?? [];
      const symptomCategoryData = rootCauseCategoryRatio.filter((item) => item.rootCauseCategory === key);
      const seriesData = xAxisCategories.map(
        (xAxis) => symptomCategoryData.find((item) => item.narrowCategoryName === xAxis)?.faultRatio ?? 0
      );

      series.push({
        ...seriesForChartByRootCauseCategory[key as RootCauseCategoryType],
        data: seriesData,
      });
    }
  });

  return series;
};
