export const planStatusOptions = [
  {
    value: 'all',
    label: 'すべてのステータス',
  },
  {
    value: 'complete',
    label: '完了',
  },
  {
    value: 'incomplete',
    label: '未完了',
  },
];
