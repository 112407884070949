import {TableLayoutResponseDB, dexieDB} from '@indexedDB/database';

/**
 * テーブルレイアウトのアップデート
 * @param tableLayoutResponse APIのレスポンス
 */
export const upsertTableLayoutSetting = async (tableLayoutResponse: TableLayoutResponseDB[]) => {
  await dexieDB?.tableLayoutSetting.bulkPut(tableLayoutResponse);
};

/**
 * IndexedDBのテーブルレイアウトを返す
 * @param hospitalUserHashId
 * @returns
 */
export const getTableLayoutForDB = async (hospitalUserHashId: string) => {
  try {
    return await dexieDB?.tableLayoutSetting.where('hospitalUserHashId').equals(hospitalUserHashId).first();
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
