import * as Yup from 'yup';
import {yup} from '@front-libs/core';
import {InspectionTypeOptions} from '@modules/inspections/enum';
import {
  Field,
  SelectorOption,
  NumericValidator,
  FormValue,
  FormError,
  EMPTY_INSPECTION_NAME_ERROR,
  EmptyInspectionNameError,
  INVALID_INSPECTION_TYPE_ERROR,
  InvalidInspectionTypeError,
  EMPTY_SECTIONS_ERROR,
  EmptySectionsError,
  EMPTY_SECTION_NAME_ERROR,
  EmptySectionNameError,
  EMPTY_FIELDS_ERROR,
  EmptyFieldsError,
  EMPTY_FIELD_NAME_ERROR,
  EmptyFieldNameError,
  INVALID_FIELD_TYPE_ERROR,
  EMPTY_FIELD_TYPE_ERROR,
  EmptyFieldTypeError,
  InvalidFieldTypeError,
  EMPTY_FIELD_OPTIONS_ERROR,
  EmptyFieldOptionsError,
  EMPTY_FIELD_OPTION_VALUE_ERROR,
  EmptyFieldOptionValueError,
  DUPLICATE_FIELD_OPTIONS_ERROR,
  DuplicateFieldOptionsError,
  EmptyFieldValidatorsError,
  INVALID_NUMERIC_VALIDATOR_TYPE_ERROR,
  EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR,
  EmptyNumericValidatorTypeError,
  InvalidNumericValidatorTypeError,
  EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR,
  EmptyNumericValidatorComparisonValueError,
  EMPTY_FIELD_VALIDATOR_ERROR,
  EmptyFieldValidatorError,
  INVALID_NUMERIC_VALIDATOR_RANGE_ERROR,
  InvalidNumericValidatorRangeError,
} from './types';
import {FormikErrors, getIn} from 'formik';
import _ from 'lodash';
import {DEFAULT_INSPECTION_NAME} from './states';
import {SelectValidator} from '@modules/inspections/api';

const INSPECTION_TYPES = InspectionTypeOptions.map((x) => x.value);

const hasValidatorMap = {
  numeric: true,
  checkbox: true,
  'pull-down': true,
  radio: true,
} as const;

const hasValidator = (fieldName?: string | null): fieldName is keyof typeof hasValidatorMap => {
  return !!fieldName && fieldName in hasValidatorMap
    ? hasValidatorMap[fieldName as keyof typeof hasValidatorMap]
    : false;
};

const getParentPath = (path: string) => {
  const paths = path.split('.');
  return paths.splice(0, paths.length - 1).join('.');
};

const SelectorOptionsSchema = Yup.array()
  .of(
    Yup.object()
      .shape({
        label: Yup.string().required(EMPTY_FIELD_OPTION_VALUE_ERROR),
        value: Yup.string().required(EMPTY_FIELD_OPTION_VALUE_ERROR),
      })
      .required(EMPTY_FIELD_OPTION_VALUE_ERROR)
  )
  .required(EMPTY_FIELD_OPTIONS_ERROR)
  .test('uniqueOptions', '', function (items: SelectorOption[] | undefined) {
    if (items === undefined || items.length === 0) {
      return this.createError({
        path: `${getParentPath(this.path)}.options`,
        message: EMPTY_FIELD_OPTIONS_ERROR,
      });
    }

    const nonEmptyItems = items.filter((i) => i.value !== undefined && i.value !== '');
    if (nonEmptyItems.length !== new Set(nonEmptyItems.map((item) => item.value)).size) {
      return this.createError({
        path: `${getParentPath(this.path)}._options`,
        message: DUPLICATE_FIELD_OPTIONS_ERROR,
      });
    }

    return true;
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumericValidatorSchema: any = Yup.lazy(function (validator: NumericValidator) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shape: any = {
    type: Yup.string()
      .oneOf(['gt', 'gte', 'lt', 'lte', 'eq', 'neq', 'between', 'not-between'], INVALID_NUMERIC_VALIDATOR_TYPE_ERROR)
      .required(EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR)
      .typeError(EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR),
    errorText: Yup.string().optional(),
  };

  if (validator.type !== null) {
    if (['gt', 'gte', 'lt', 'lte', 'eq', 'neq'].includes(validator.type)) {
      shape.value = Yup.number().required(EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR);
    } else if (['between', 'not-between'].includes(validator.type)) {
      shape.lower = Yup.number().required(EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR);
      shape.upper = Yup.number().required(EMPTY_NUMERIC_VALIDATOR_COMPARISON_VALUE_ERROR);
    }
  }

  return (
    Yup.object()
      .shape(shape)
      .required(EMPTY_FIELD_VALIDATOR_ERROR)
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any
      .test('valid_exprs', '', function (this: any, validator: NumericValidator | undefined) {
        if (validator === undefined) {
          return this.createError({
            path: `${this.path}`,
            message: EMPTY_FIELD_VALIDATOR_ERROR,
          });
        }

        if (validator?.type === null) {
          return this.createError({
            path: `${this.path}`,
            message: EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR,
          });
        }

        if (validator.type === 'between' || validator.type === 'not-between') {
          if (validator.lower >= validator.upper) {
            return this.createError({
              path: `${this.path}._expr`,
              message: INVALID_NUMERIC_VALIDATOR_RANGE_ERROR,
            });
          }
        }

        return true;
      })
  );
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OptionValidatorSchema: any = Yup.lazy(function (validator: NumericValidator) {
  return Yup.object().shape({
    values: yup.array(yup.string()),
  });
});

const NumericValidatorsSchema = Yup.array().of(NumericValidatorSchema);

const FieldSchema = Yup.lazy((value: Field) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const settingsShape: any = {
    showsInspectionPoint: Yup.boolean().required(),
    showsDescription: Yup.boolean().required(),
  };

  if (hasValidator(value?.type)) {
    settingsShape.showsValidator = Yup.boolean().required();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shape: any = {
    id: Yup.string().optional(),
    name: Yup.string().required(EMPTY_FIELD_NAME_ERROR),
    type: Yup.string()
      .oneOf(
        ['checkbox', 'pull-down', 'radio', 'numeric', 'text', 'multiline-text', 'date', 'time'],
        INVALID_FIELD_TYPE_ERROR
      )
      .required(EMPTY_FIELD_TYPE_ERROR),
    required: Yup.bool().required(),
    settings: Yup.object().shape(settingsShape),
  };

  if (value.type === 'numeric') {
    shape.unit = Yup.string().optional();
  }

  if (['checkbox', 'pull-down', 'radio'].includes(value.type ?? '')) {
    shape.options = SelectorOptionsSchema;
  }

  // optional
  if (value.settings.showsInspectionPoint) {
    shape.inspectionPoint = Yup.string().nullable();
  }

  if (value.settings.showsDescription) {
    shape.description = Yup.string().nullable();
  }

  if (hasValidator(value.type)) {
    switch (value.type) {
      case 'numeric':
        if (value.settings.showsValidator) {
          shape.validators = NumericValidatorsSchema;
        }
        break;
      case 'checkbox':
      case 'pull-down':
      case 'radio':
        if (value.settings.showsValidator) {
          shape.validators = OptionValidatorSchema;
        }
        break;
    }
  }

  return Yup.object().shape(shape);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any;

const SectionSchema = Yup.object().shape({
  id: Yup.string().optional(),
  name: Yup.string().required(EMPTY_SECTION_NAME_ERROR),
  fields: Yup.array().of(FieldSchema).required(EMPTY_FIELDS_ERROR).min(1, EMPTY_FIELDS_ERROR),
});

export const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required(EMPTY_INSPECTION_NAME_ERROR)
    .notOneOf([yup.ref(DEFAULT_INSPECTION_NAME)], EMPTY_INSPECTION_NAME_ERROR),
  type: Yup.mixed().oneOf(INSPECTION_TYPES, INVALID_INSPECTION_TYPE_ERROR).required(),
  sections: Yup.array().of(SectionSchema).required(EMPTY_SECTIONS_ERROR).min(1, EMPTY_SECTIONS_ERROR),
});

export const getErrorSummaries = (errors: FormikErrors<FormValue>, values: FormValue): FormError[] => {
  const summaries: FormError[] = [];

  // name
  {
    const nameError = getIn(errors, 'name');
    if (_.isString(nameError)) {
      summaries.push({
        type: nameError,
      } as EmptyInspectionNameError);
    }
  }

  // type
  {
    const typeError = getIn(errors, 'type');
    if (_.isString(typeError)) {
      summaries.push({
        type: typeError,
      } as InvalidInspectionTypeError);
    }
  }

  // sections
  {
    const sectionsError = getIn(errors, 'sections');
    if (sectionsError !== undefined) {
      if (_.isString(sectionsError)) {
        summaries.push({
          type: sectionsError,
        } as EmptySectionsError);
      } else if (_.isArray(sectionsError)) {
        summaries.push(...getSectionsError(sectionsError, getIn(values, 'sections')));
      }
    }
  }

  return summaries;
};

const getSectionsError = (
  sectionErrors: FormikErrors<FormValue['sections']>,
  sections: FormValue['sections']
): FormError[] => {
  const summaries: FormError[] = [];

  sectionErrors.forEach((sectionError, sectionIndex) => {
    // name
    {
      const nameError = getIn(sectionError, 'name');
      if (_.isString(nameError)) {
        summaries.push({
          type: nameError,
          sectionIndex: sectionIndex,
        } as EmptySectionNameError);
      }
    }

    const sectionName = getIn(sections, `[${sectionIndex}].name`);

    // fields
    {
      const fieldsError = getIn(sectionError, 'fields');
      if (_.isString(fieldsError)) {
        summaries.push({
          type: fieldsError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
        } as EmptyFieldsError);
      } else if (_.isArray(fieldsError)) {
        const fields = getIn(sections, `[${sectionIndex}].fields`);

        summaries.push(...getFieldsError(fieldsError, fields, sectionName, sectionIndex));
      }
    }
  });

  return summaries;
};

const getFieldsError = (
  fieldsError: FormikErrors<FormValue['sections'][number]['fields']>,
  fields: FormValue['sections'][number]['fields'],
  sectionName: string,
  sectionIndex: number
): FormError[] => {
  const summaries: FormError[] = [];

  fieldsError.forEach((fieldError, fieldIndex) => {
    // name
    {
      const nameError = getIn(fieldError, 'name');
      if (_.isString(nameError)) {
        summaries.push({
          type: nameError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldIndex: fieldIndex,
        } as EmptyFieldNameError);
      }
    }

    const fieldName = getIn(fields, `[${fieldIndex}].name`);

    // type
    {
      const typeError = getIn(fieldError, 'type');
      if (_.isString(typeError)) {
        const summary = {
          type: typeError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldIndex: fieldIndex,
          fieldName: fieldName,
        };

        switch (typeError) {
          case EMPTY_FIELD_TYPE_ERROR:
            summaries.push(summary as EmptyFieldTypeError);
            break;
          case INVALID_FIELD_TYPE_ERROR:
            summaries.push(summary as InvalidFieldTypeError);
            break;
        }
      }
    }

    // options
    {
      const optionsError = getIn(fieldError, 'options');
      if (_.isString(optionsError)) {
        summaries.push({
          type: optionsError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldIndex: fieldIndex,
          fieldName: fieldName,
        } as EmptyFieldOptionsError);
      } else if (_.isArray(optionsError)) {
        optionsError.forEach((optionError, optionIndex) => {
          const valueError = getIn(optionError, 'value');
          if (_.isString(valueError)) {
            summaries.push({
              type: valueError,
              sectionName: sectionName,
              sectionIndex: sectionIndex,
              fieldIndex: fieldIndex,
              fieldName: fieldName,
              optionIndex: optionIndex,
            } as EmptyFieldOptionValueError);
          }
        });
      }
    }

    // _options
    {
      const _optionsError = getIn(fieldError, '_options');
      if (_.isString(_optionsError)) {
        summaries.push({
          type: _optionsError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldName: fieldName,
          fieldIndex: fieldIndex,
        } as DuplicateFieldOptionsError);
      }
    }

    // validators
    {
      const validatorsError = getIn(fieldError, 'validators');
      if (_.isString(validatorsError)) {
        summaries.push({
          type: validatorsError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldName: fieldName,
          fieldIndex: fieldIndex,
        } as EmptyFieldValidatorsError);
      } else if (_.isArray(validatorsError)) {
        // NumericValidator
        const validators = getIn(fields, `[${fieldIndex}].validators`);
        summaries.push(
          ...getNumericValidatorsError(validatorsError, validators, sectionName, sectionIndex, fieldName, fieldIndex)
        );
      } else if (_.isPlainObject(validatorsError)) {
        // SelectValidator
        const valuesError = getIn(validatorsError, 'values');

        if (_.isString(valuesError)) {
          summaries.push({
            type: valuesError,
            sectionName: sectionName,
            sectionIndex: sectionIndex,
            fieldName: fieldName,
            fieldIndex: fieldIndex,
          } as EmptyFieldValidatorsError);
        }
      }
    }
  });

  return summaries;
};

const getNumericValidatorsError = (
  validatorsError: FormikErrors<(NumericValidator | SelectValidator)[]>,
  validators: (NumericValidator | SelectValidator)[],
  sectionName: string,
  sectionIndex: number,
  fieldName: string,
  fieldIndex: number
): FormError[] => {
  const summaries: FormError[] = [];

  validatorsError.forEach((validatorError, validatorIndex) => {
    if (_.isString(validatorError)) {
      summaries.push({
        type: validatorError,
        sectionName: sectionName,
        sectionIndex: sectionIndex,
        fieldName: fieldName,
        fieldIndex: fieldIndex,
        validatorIndex: validatorIndex,
      } as EmptyFieldValidatorError);

      return;
    }

    // type
    {
      const typeError = getIn(validatorError, 'type');
      if (_.isString(typeError)) {
        const summary = {
          type: typeError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldName: fieldName,
          fieldIndex: fieldIndex,
          validatorIndex: validatorIndex,
        };

        switch (typeError) {
          case EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR:
            summaries.push(summary as EmptyNumericValidatorTypeError);
            break;
          case INVALID_NUMERIC_VALIDATOR_TYPE_ERROR:
            summaries.push(summary as InvalidNumericValidatorTypeError);
            break;
          case '入力内容の形式が異なります':
            // HOTFIX
            summaries.push({
              type: EMPTY_NUMERIC_VALIDATOR_TYPE_ERROR,
              sectionName: sectionName,
              sectionIndex: sectionIndex,
              fieldName: fieldName,
              fieldIndex: fieldIndex,
              validatorIndex: validatorIndex,
            } as EmptyNumericValidatorTypeError);
            break;
        }
      }
    }

    // value
    {
      const valueError = getIn(validatorError, 'value');
      if (_.isString(valueError)) {
        summaries.push({
          type: valueError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldName: fieldName,
          fieldIndex: fieldIndex,
          validatorIndex: validatorIndex,
        } as EmptyNumericValidatorComparisonValueError);
      }
    }

    // lower
    let hasLowerError = false;
    {
      const lowerError = getIn(validatorError, 'lower');
      if (_.isString(lowerError)) {
        summaries.push({
          type: lowerError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldName: fieldName,
          fieldIndex: fieldIndex,
          validatorIndex: validatorIndex,
        } as EmptyNumericValidatorComparisonValueError);
        hasLowerError = true;
      }
    }

    // upper
    {
      const upperError = getIn(validatorError, 'upper');
      if (_.isString(upperError) && !hasLowerError) {
        summaries.push({
          type: upperError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldName: fieldName,
          fieldIndex: fieldIndex,
          validatorIndex: validatorIndex,
        } as EmptyNumericValidatorComparisonValueError);
      }
    }

    // _expr
    {
      const exprError = getIn(validatorError, '_expr');
      if (_.isString(exprError)) {
        summaries.push({
          type: exprError,
          sectionName: sectionName,
          sectionIndex: sectionIndex,
          fieldName: fieldName,
          fieldIndex: fieldIndex,
          validatorIndex: validatorIndex,
        } as InvalidNumericValidatorRangeError);
      }
    }
  });

  return summaries;
};
