import {useState} from 'react';
import _ from 'lodash';

/**
 * debounce処理が適用された状態とそのセッター関数を提供するカスタムフック
 * debounce処理 は連続した呼び出しをグループ化して、一定時間の間に発生した呼び出しを1つの呼び出しにまとめる処理であり
 * コレイにより高速化が見込める
 * @param initialState - 状態の初期値
 * @param ms - debounceの遅延時間（ミリ秒）
 * @returns 現在の状態とセッター関数のタプル
 */
export function useDebounceState<T>(initialState: T, ms: number): [T, (newState: T) => void] {
  const [state, setState] = useState<T>(initialState);
  const debounceFunc = _.debounce((newState: T) => setState(newState), ms);
  return [state, debounceFunc];
}
