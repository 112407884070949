import {Grid, Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link, useLocation} from 'react-router-dom';
import LocalHospital from '@material-ui/icons/LocalHospitalOutlined';
import {InspectionSVG} from '@components/atoms/Icons/inspectionIcons';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    margin: theme.spacing(1),
    padding: 0,
  },
  menuItemCaption: {
    margin: 0,
    color: '#0052CC',
  },
  activeCaption: {
    color: theme.palette.primary.main, // パスに応じたアクティブな文字色
  },
  inactiveCaption: {
    color: '#A2A2A2',
  },
  footer: {
    padding: '8px',
    textAlign: 'center',
    marginTop: 'auto',
    width: '100%',
    backgroundColor: 'white',
    color: 'white',
    position: 'fixed',
    height: '58px',
    bottom: '0px',
    justifyContent: 'space-around', // 子要素を等間隔に配置
    borderTop: '1px solid #F0F2F5',
    alignItems: 'center',
    overflow: 'hidden',
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const getCaptionClassName = (path: string, targetPath: string) => {
    return `${classes.menuItemCaption} ${path === targetPath ? classes.activeCaption : classes.inactiveCaption}`;
  };
  // FIXME どこかでリファクタリング。同じ定義を何度もしているので、共通化する
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.footer}>
      <Grid item />
      <Grid item>
        <IconButton
          component={Link}
          to="/sp/products"
          className={classes.iconButton}
          style={{margin: '0px', padding: '0'}}>
          <LocalHospital style={{color: location.pathname === '/sp/products' ? '#0052CC' : '#A2A2A2'}} />
        </IconButton>
        <Grid container>
          <Typography
            variant="caption"
            className={`${classes.menuItemCaption}
            ${getCaptionClassName(location.pathname, '/sp/products')}`}>
            機器
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton
          component={Link}
          to="/sp/inspection/results"
          className={classes.iconButton}
          style={{margin: '0px', padding: '0'}}>
          <InspectionSVG color={location.pathname === '/sp/inspection/results' ? '#0052CC' : '#A2A2A2'} />
        </IconButton>
        <Grid container>
          <Typography
            variant="caption"
            className={`${classes.menuItemCaption}
            ${getCaptionClassName(location.pathname, '/sp/inspection/results')}`}>
            点検
          </Typography>
        </Grid>
      </Grid>
      <Grid item />
    </Grid>
  );
};
