import React, {useCallback} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FileIndex} from '@modules/files/types';
import {FileList} from '@organisms/Files/FileList';
import {useState} from 'react';
import {useEffect} from 'react';
import {makeStyles, createStyles, Grid} from '@material-ui/core';
import {useFetchTrainingScheduleMaterials} from '@modules/training_schedules/hooks/useFetchTrainingScheduleMaterials';
import {createTrainingScheduleMaterials} from '@modules/training_schedules/api';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type FilesFormProps = {
  trainingScheduleHashId: string;
};

export const Materials: React.VFC<FilesFormProps> = ({trainingScheduleHashId}) => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [tableLayout] = useTableLayout('fileList');
  const [order, setOrder] = useState<string>();
  const {isReadOnly} = useMyRole();

  const {data, refetch, isLoading} = useFetchTrainingScheduleMaterials(
    myInfo.hospitalHashId,
    trainingScheduleHashId,
    order
  );

  const [files, setFiles] = useState(data);
  useEffect(() => {
    setFiles(data || []);
  }, [data]);

  const handleUploadFile = useCallback(
    async (newFile: FileIndex) => {
      await createTrainingScheduleMaterials(myInfo.hospitalHashId, trainingScheduleHashId, {
        fileHashIds: [newFile.hashId],
      });
      await refetch();
    },
    [myInfo.hospitalHashId, refetch, trainingScheduleHashId]
  );
  const handleRefetch = () => refetch();

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout.currentLayout[columnIndex].field)}`);
    },
    [tableLayout.currentLayout]
  );

  return (
    <Grid className={classes.root} container justifyContent="center">
      <FileList
        files={files}
        fileCategory={'training_schedule_material'}
        isLoading={isLoading}
        disableUpload={isReadOnly}
        disableEdit={isReadOnly}
        onDeleteFile={handleRefetch}
        onEditFileName={handleRefetch}
        onUploadFile={handleUploadFile}
        onOrderChange={handleOrderChange}
        searchFileName={false}
        uploadButtonTitle={'研修資材を追加'}
        isDarkButton={true}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '24px 0px 16px',
      width: '100%',
    },
    addCommentBtn: {
      backgroundColor: '#323F4E',
      borderRadius: '2px',
      padding: '8px 22px',
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: '#323F4E',
      },
    },
  })
);
