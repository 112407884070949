import React, {useMemo} from 'react';
import {PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {useStyles} from './styles';

export type RootCategorySelectorProps = {
  rootCategoryOptions: {
    label: string;
    value: string;
  }[];
  currentValue: string | null;
  onChangeHashId: (value: string) => void;
};

export const RootCategorySelector: React.FC<RootCategorySelectorProps> = ({
  rootCategoryOptions,
  currentValue,
  onChangeHashId,
}) => {
  const classes = useStyles();
  const currentLabel = useMemo(
    () => rootCategoryOptions.find((i) => i.value === currentValue)?.label ?? 'カテゴリを選択',
    [rootCategoryOptions, currentValue]
  );
  return (
    <PopperMenuButton
      menuItemList={rootCategoryOptions}
      onMenuClick={({value}: {value: string}) => onChangeHashId(value)}
      buttonProps={{variant: 'contained', disableElevation: true, className: classes.selectCategoryButton}}>
      {currentLabel}
    </PopperMenuButton>
  );
};
