import {atomWithReset} from 'jotai/utils';
import {Section, FocusedItem, EmptyField, TabKey, FormValue} from '@Apps/Inspection/types';

export const tabAtom = atomWithReset<TabKey>('items');

export const focusedItemAtom = atomWithReset<FocusedItem>({sectionIndex: 0, fieldIndex: null, el: null});

export const DEFAULT_FIELD_NAME = '';
export const DEFAULT_SECTION_NAME = '';
export const DEFAULT_INSPECTION_NAME = '無題の点検表';

export const getDefaultField = (): EmptyField => {
  return {
    type: '',
    name: DEFAULT_FIELD_NAME,
    required: false,
    inspectionPoint: null,
    description: null,
    settings: {
      showsInspectionPoint: false,
      showsDescription: false,
    },
  };
};

export const getDefaultSection = (): Section => {
  return {
    name: DEFAULT_SECTION_NAME,
    fields: [getDefaultField()],
  };
};

export const getDefaultFormValue = (): FormValue => {
  return {
    name: DEFAULT_INSPECTION_NAME,
    type: 'periodic',
    sections: [getDefaultSection()],
  };
};
