import React, {useCallback} from 'react';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
  DialogActions,
  Button,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {StartDateColumn} from './StartDateColumn';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));

export type InspectionStartDateDialogProps = DialogProps & {
  isPeriodsSet: boolean;
};

export const InspectionStartDateDialog: React.FC<InspectionStartDateDialogProps> = (props) => {
  const {open, isPeriodsSet} = props;
  const classes = useStyles();

  const isOpen = typeof open === 'boolean' ? open : false;

  const handleClickSelect = useCallback(() => {
    props.actions.resolve({});
  }, [props.actions]);

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2">
          {isPeriodsSet === true ? '最後に、点検開始日を設定してください' : '点検開始日を設定しますか？'}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <StartDateColumn isPeriodsSet={isPeriodsSet} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleClickSelect}>
          点検開始日を設定する
        </Button>
        <Button color="primary" onClick={handleClose}>
          後で設定する
        </Button>
      </DialogActions>
    </Dialog>
  );
};
