import React, {useState, useCallback} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Checkbox,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import {Form, Formik, getIn, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import Selector from '@molecules/Formik/fields/Selector';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import * as yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import {InspectionType, InspectionTypes, InspectionTypeOptions} from '@modules/inspections/enum';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '24px 32px 0px',
    '& h2': {
      color: '#172B4D',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  closeButton: {
    padding: '0px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    padding: '0px 32px 0px',
    '& > div:not(:first-child)': {
      marginTop: '24px',
    },
  },
  formHelperText: {
    fontSize: 14,
    color: theme.palette.grey[600],
    marginLeft: '36px',
  },
  actions: {
    marginTop: '24px',
    padding: '0px 32px 32px',
  },
}));

type EditProductsFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

/**
 * 点検表の複製フォーム
 * @param {EditProductsFormProps} props
 * @return {*}
 */
const CopyInspectionForm: React.VFC<EditProductsFormProps> = (props) => {
  const {open, onClose} = props;
  const classes = useStyles();
  const {values, submitForm, setFieldValue} = useFormikContext();

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const shouldMigrateProducts = getIn(values, 'shouldMigrateProducts');
  const handleChangeShouldMigrateProducts = useCallback(
    (_e: React.ChangeEvent<unknown>, checked: boolean) => {
      setFieldValue('shouldMigrateProducts', checked);
    },
    [setFieldValue]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs" aria-labelledby="form-dialog-title">
      <DialogTitle className={classes.title} disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h2">{`この点検表を複製`}</Typography>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid item>
          <label>点検タイプ</label>
          <Selector name="type" size="small" options={InspectionTypeOptions} />
        </Grid>
        <Grid item>
          <label>点検表名</label>
          <TextField name="name" size="small" fullWidth />
        </Grid>
        <FormGroup>
          <FormControlLabel
            value={shouldMigrateProducts}
            onChange={handleChangeShouldMigrateProducts}
            control={<Checkbox color="secondary" disableRipple={false} />}
            label="複製前の点検表と同じ機種を紐付ける"
          />
          <FormHelperText className={classes.formHelperText}>
            他の点検表にすでに機種が紐付いている場合に、複製された点検表に新しく紐付けられます。
          </FormHelperText>
        </FormGroup>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant={'contained'} onClick={handleSubmit} color="primary">
          保存
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type CopyInspectionDialogProps = {
  defaultName: string;
} & DialogProps;

export type CopyInspectionDialogResult = {
  type: InspectionType;
  name: string;
  shouldMigrateProducts: boolean;
};

type FormValues = {
  type?: InspectionType;
  name: string;
  shouldMigrateProducts: boolean;
};

/**
 * 複製ダイアログ
 * @param {CopyInspectionDialogProps} props
 * @returns
 */
export const CopyInspectionDialog: React.FC<CopyInspectionDialogProps> = (props) => {
  const {open, defaultName} = props;

  const [validationSchema] = useState(() =>
    yup.object({
      type: yup.mixed<InspectionType>().oneOf(InspectionTypes).required(),
      name: yup.string(),
      shouldMigrateProducts: yup.boolean(),
    })
  );

  const handleSubmit = useCallback(
    async (res: FormValues) => {
      await props.actions.resolve(res as CopyInspectionDialogResult);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Formik
      initialValues={{name: defaultName, shouldMigrateProducts: false}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      <Form>
        <CopyInspectionForm open={open ?? false} onClose={handleClose} />
      </Form>
    </Formik>
  );
};
