import React, {useEffect, Suspense, useState, useCallback} from 'react';
import {yup} from '@front-libs/core';
import {Grid, useMediaQuery} from '@material-ui/core';
import {use3ColumnsLayoutTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {SideNav} from './SideNav';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Form, Formik, useFormikContext} from 'formik';
import {InnerLoading} from '@molecules/Loading';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {openSnackBar} from '@molecules/SnackBar';
import {RightSideNav} from './RightSideNav';
import {useParams} from 'react-router-dom';
import {TrainingScheduleDetail} from '@Apps/TrainingManagementScheduleDetail/Content';
import {useMutation} from 'react-query';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {useSetAtom} from 'jotai';
import {drawerAtom} from './state';
import {updateTrainingSchedule, UpdateTrainingScheduleParam} from '@modules/training_schedules/api';
import {TrainingSchedule} from '@modules/training_schedules/types';
import {useFetchTrainingScheduleQuery} from '@modules/training_schedules/hooks/useTrainingSchedule';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const TrainingManagementScheduleDetailForm: React.FC = () => {
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const templateClasses = use3ColumnsLayoutTemplate();
  const trainingScheduleContext = useFormikContext<TrainingSchedule>();
  const setDrawerOpen = useSetAtom(drawerAtom);
  const matches = useMediaQuery('(min-width:1280px)');
  const {refetch} = useFetchTrainingScheduleQuery(myInfo.hospitalHashId, hashId ?? '');
  const {isReadOnly} = useMyRole();

  useEffect(() => {
    matches ? setDrawerOpen(true) : setDrawerOpen(false);
  }, [matches, setDrawerOpen]);

  return (
    <Form style={{width: '100%'}}>
      <Grid container className={templateClasses.root}>
        <Grid item className={templateClasses.leftSideNav}>
          <SideNav trainingSchedule={trainingScheduleContext.initialValues} onUpdateComplete={refetch} />
        </Grid>
        <Grid item className={templateClasses.content}>
          <TrainingScheduleDetail trainingScheduleHashId={hashId ?? ''} />
        </Grid>
        <Grid item className={templateClasses.rightSideNav}>
          <RightSideNav trainingSchedule={trainingScheduleContext.initialValues} onChangeProducts={refetch} />
        </Grid>
      </Grid>
      {!isReadOnly && <FormikFormSubmitDrawer />}
    </Form>
  );
};

const TrainingManagementScheduleContainer: React.FC = ({children}) => {
  const {myInfo} = useMyInfo();
  const {hashId} = useParams();
  const {data, isLoading, refetch} = useFetchTrainingScheduleQuery(myInfo.hospitalHashId, hashId ?? '');

  const [initialValues, setInitialValues] = useState(data);
  const validationSchema = yup.object({
    place: yup.string(),
  });

  const {mutate} = useMutation<unknown, unknown, UpdateTrainingScheduleParam>(
    (variables) => updateTrainingSchedule(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => refetch(),
    }
  );

  const handleSubmit = useCallback(
    async (values: TrainingSchedule) => {
      try {
        const updateValues: UpdateTrainingScheduleParam = {
          trainingScheduleHashId: values.hashId,
          startAt: convertDateToSimpleDate(values.startAt),
          finishAt: convertDateToSimpleDate(values.finishAt),
          status: values.status,
          place: values.place,
          numberOfTrainees: values.numberOfTrainees?.toString() ?? '',
        };
        mutate(updateValues);
        openSnackBar('研修計画マスタを更新しました');
      } catch (error) {
        openSnackBar('研修計画マスタの更新に失敗しました', 'left', 'bottom', 'error');
        throw error;
      }
    },
    [mutate]
  );

  useEffect(() => {
    setInitialValues(data);
  }, [data]);

  if (isLoading || !initialValues) {
    return <InnerLoading />;
  }
  return (
    <Formik<TrainingSchedule>
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}>
      {children}
    </Formik>
  );
};

export const TrainingManagementScheduleDetail: React.FC = () => {
  const templateClasses = use3ColumnsLayoutTemplate();
  return (
    <Grid container className={templateClasses.root}>
      <Suspense fallback={null}>
        <TrainingManagementScheduleContainer>
          <TrainingManagementScheduleDetailForm />
        </TrainingManagementScheduleContainer>
      </Suspense>
    </Grid>
  );
};
