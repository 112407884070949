import {Box, Typography, styled} from '@material-ui/core';
import React from 'react';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import dayjs from 'dayjs';
import {InspectionTypeOptionsValues} from '@modules/inspections/enum';
type InspectionCustomItemProps = {
  inspectionResult: InspectionResultIndex;
};
type ScheduledDateProps = {
  scheduledDate: string | null;
};

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  whiteSpace: 'nowrap',
});

export const InspectionCustomItem = (props: InspectionCustomItemProps) => {
  const {inspectionResult} = props;
  const {status, type} = inspectionResult;

  let content;
  switch (type) {
    case 'pre_use':
      switch (status) {
        case 'unplanned':
        case 'uncompleted':
        case 'skipped':
          content = <DailyInspectionCount count={inspectionResult.numberOfSameDayFormerResults} />;
          break;
        case 'completed':
          content = (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <DailyInspectionCount count={inspectionResult.numberOfSameDayFormerResults} />
              <CompletedDateTime completedDate={inspectionResult.completedAt} />
            </Box>
          );
          break;
        default:
          content = null;
          break;
      }
      break;
    case 'in_use':
      switch (status) {
        case 'completed':
          content = <CompletedDateTime completedDate={inspectionResult.completedAt} />;
          break;
        default:
          content = null;
          break;
      }
      break;
    case 'post_use':
      switch (status) {
        case 'completed':
          content = <CompletedDateTime completedDate={inspectionResult.completedAt} />;
          break;
        default:
          content = null;
          break;
      }
      break;
    case 'periodic':
      switch (status) {
        case 'unplanned':
        case 'uncompleted':
        case 'skipped':
          content = <ScheduledDate scheduledDate={inspectionResult.scheduledTime} />;
          break;
        case 'completed':
          content = <CompletedDateTime completedDate={inspectionResult.completedAt} />;
          break;
        default:
          content = null;
          break;
      }
      break;
    default:
      content = null;
  }

  return content;
};

const DailyInspectionCount: React.FC<DailyInspectionCountProps> = (props) => {
  const {count} = props;
  return <StyledTypography>当日の点検回数:{count === null ? '0' : count}回</StyledTypography>;
};
type DailyInspectionCountProps = {
  count: number | null;
};

const CompletedDateTime: React.FC<CompletedDateProps> = (props) => {
  const {completedDate} = props;
  return (
    <StyledTypography>
      点検完了日時：{completedDate ? dayjs(completedDate).format('YYYY/MM/DD HH:mm') : ''}
    </StyledTypography>
  );
};
type CompletedDateProps = {
  completedDate: string | null;
};

const ScheduledDate: React.FC<ScheduledDateProps> = (props) => {
  const {scheduledDate} = props;
  return (
    <StyledTypography>点検予定日：{scheduledDate ? dayjs(scheduledDate).format('YYYY/MM/DD') : ''}</StyledTypography>
  );
};
