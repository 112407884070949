import {useMemo} from 'react';
import {axios} from '@front-libs/core';
import {atom} from 'jotai';
import {useQuery} from 'react-query';
import {getCategoriesKey} from '@constants/api';
import {CategoryIndex} from '../types';
import {isNullish} from '@front-libs/helpers';

export type FetchCategoriesParams = {
  depth?: number;
  isMedicalDevice?: boolean;
};

export type FetchCategoriesResult = {
  data: CategoryIndex[];
};

const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/categories`;
const DEPTH_FOR_NARROW_CATEGORY = 1;

export const rootCategoriesAtom = atom(async () => {
  const result = await axios.get<FetchCategoriesResult>(baseUrl, {
    params: {
      depth: 0,
    },
  });
  return result.data;
});

export const subCategoriesAtom = atom(async () => {
  const result = await axios.get<FetchCategoriesResult>(baseUrl, {
    params: {
      depth: 1,
    },
  });
  return result.data;
});

export const fetchCategoriesDto = atom<FetchCategoriesParams | null>(null);
export const fetchCategories = atom(async (get) => {
  const param = get(fetchCategoriesDto);
  if (param === null) {
    return {
      totalCount: 0,
      page: 0,
      data: [],
    };
  }

  const result = await axios.get<FetchCategoriesResult>(baseUrl, {params: param});
  return result.data;
});

export const fetchCategoryDto = atom<string | null>(null);
export const fetchCategory = atom(async (get) => {
  const id = get(fetchCategoryDto);
  if (id === null) return;
  const result = await axios.get<CategoryIndex>(`${baseUrl}/${id}`);
  return result.data;
});

export const getDescendantCategories = async (categoryHashId: string) => {
  // eslint-disable-next-line no-shadow
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/categories/${categoryHashId}/descendants`;
  const response = await axios.get<CategoryIndex[]>(baseUrl);
  return response;
};

export const getCategories = async (params: FetchCategoriesParams) => {
  const {data} = await axios.get<FetchCategoriesResult>(baseUrl, {params});
  return data;
};

export const useFetchRootCategoriesQuery = (param: FetchCategoriesParams) => {
  const query = useQuery<FetchCategoriesResult>([getCategoriesKey, param], () => getCategories(param));
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
    }),
    [query]
  );
};

export const useDescendantCategoryQuery = (broadCategoryHashId?: string | null) => {
  return useQuery(['getDescendantCategories', broadCategoryHashId], async () => {
    if (isNullish(broadCategoryHashId)) {
      const {data: res} = await getCategories({depth: DEPTH_FOR_NARROW_CATEGORY});
      return res;
    } else {
      const {data: res} = await getDescendantCategories(broadCategoryHashId);
      return (res ?? []).filter((item) => item.depth === DEPTH_FOR_NARROW_CATEGORY); // 小分類のみにフィルタ
    }
  });
};
