import {ProductList} from '@Apps/ProductsList/sp';
import {InspectionResultList} from '@Apps/InspectionResultList/sp';
import React from 'react';
import {InspectionResult} from '@Apps/InspectionResult/sp';
import {MobileProductDetail} from './Apps/ProductDetail';

// NOTE:スマートフォン用のルーティング
export const spRoutes = {
  path: '/sp',
  children: [
    {
      key: 'spProducts',
      path: 'products',
      element: <ProductList />,
    },
    {
      key: 'spInspectionResults',
      path: 'inspection/results',
      element: <InspectionResultList />,
    },
    {
      key: 'spInspectionResult',
      path: 'inspections/:inspectionHashId/result/:inspectionResultHashId',
      element: <InspectionResult />,
    },
    {
      key: 'spProductDetail',
      path: 'products/:hashId',
      element: <MobileProductDetail />,
    },
  ],
};
