import {
  TermAllOption,
  TermCustomOption,
  TermLastMonthOption,
  TermLastWeekOption,
  TermThisMonthOption,
  TermThisWeekOption,
  TermThisYearOption,
  TermTodayOption,
  TermYesterdayOption,
} from '@molecules/TermPicker';

// 貸出・返却日の範囲フィルターのオプション
export const TermOptions = [
  TermAllOption,
  TermYesterdayOption,
  TermTodayOption,
  TermThisWeekOption,
  TermLastWeekOption,
  TermLastMonthOption,
  TermThisMonthOption,
  TermThisYearOption,
  TermCustomOption,
] as const;
