import {useCallback} from 'react';
import {useAuth0MultiSignInContext} from '../context';
import {useLogout} from './useLogout';

// シングル/マルチ問わずサインアウトできる関数を提供
export const useSignOut = () => {
  const multiSignInCtx = useAuth0MultiSignInContext();
  const logout = useLogout();

  return useCallback(async () => {
    if (multiSignInCtx.currentUser !== null) {
      return multiSignInCtx.signOut();
    } else {
      return logout();
    }
  }, [multiSignInCtx, logout]);
};
