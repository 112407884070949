import React, {useEffect} from 'react';
import {createStyles, Dialog, DialogContent, makeStyles, Theme} from '@material-ui/core';
import {Header} from './Header';
import {useSteps} from './hooks';
import {MailAndPasswordSetting} from './Step1MailAndPassword';
import {UserInformationSetting} from './Step2UserInformation';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

export const UserSignUp = () => {
  const classes = useStyles();
  const {currentStep} = useSteps();
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (myInfo.initialized) navigate('/', {replace: true});
  }, [myInfo.initialized, navigate]);

  return (
    <>
      {/* FIX ME: chatbotのフォームがfocusされないため、一旦非表示に */}
      <Helmet>
        <style>
          {`
            iframe {
              display: none !important;
            }
        `}
        </style>
      </Helmet>
      <Dialog open={true} fullScreen>
        <DialogContent>
          <Header />
          <div className={classes.pageContainer}>
            {currentStep === 0 ? (
              <MailAndPasswordSetting />
            ) : currentStep === 1 ? (
              <UserInformationSetting />
            ) : undefined}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      maxWidth: 420,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 82,
    },
  })
);
