import React, {useMemo} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {InspectionTableIndex} from '@modules/inspections/types';
import {Section} from '@components/molecules/InspectionTableFormItems/Section';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {Item} from '@Apps/InspectionResult/pc/ViewInspectionResult/Item';
import _ from 'lodash';
import {InspectionItem} from '@modules/inspections/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '24px 0px',
    },
    items: {
      '& > div:not(:first-child)': {
        marginTop: '42px',
      },
    },
  })
);

type ItemsProps = {
  inspectionTable: InspectionTableIndex;
  inspectionResult: InspectionResultIndex;
  isEditable: boolean;
};

export const Items: React.VFC<ItemsProps> = (props) => {
  const {isEditable, inspectionTable, inspectionResult} = props;
  const classes = useStyles();

  const idToResultItem = useMemo(() => _.keyBy(inspectionResult.items, 'id'), [inspectionResult.items]);

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid container direction="column" className={classes.items}>
        {inspectionTable.items.map((item, i) => {
          if (item.type === 'section') {
            return (
              <Section key={i} name={item.name}>
                {item.items.map((sectionItem, j) => (
                  <Item
                    key={j}
                    isEditable={isEditable}
                    item={sectionItem as InspectionItem & {id: string}}
                    resultItem={idToResultItem[sectionItem.id + '']}
                  />
                ))}
              </Section>
            );
          } else {
            return (
              <Item
                key={i}
                isEditable={isEditable}
                item={item as InspectionItem & {id: string}}
                resultItem={idToResultItem[item.id + '']}
              />
            );
          }
        })}
      </Grid>
    </Grid>
  );
};
