import React, {useMemo} from 'react';
import {useMediaQuery} from '@material-ui/core';
import {FastField, useFormikContext} from 'formik';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {MakerInspectionSetting} from '@modules/maker_inspection_settings/types';
import _ from 'lodash';
import {
  SectionFormField,
  SectionFormFieldInTablet,
  SectionContainer,
  SectionLabel,
  SectionDetail,
  SectionDetailInTablet,
  APPENDIX_WIDTH_BREAK_POINT
} from '@Apps/ProductDetail/styled';

type FormField = {
  type: string;
  label: string;
  name:
    | keyof HospitalProductDetail
    | keyof MakerInspectionSetting
    | 'rootCategory'
    | 'narrowCategory'
    | 'makerInspectionSetting.dueDateOfMakerInspection'
    | 'makerInspectionSetting.inspectionPeriod'
    | 'makerInspectionSetting.nextInspectionDate';
  getValue?: (product: HospitalProductDetail) => string | number | boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputComponent: React.ComponentType<any>;
  readOnly?: boolean | ((values: HospitalProductDetail, initialValues: HospitalProductDetail) => boolean);
  isVisible?: boolean | ((values: HospitalProductDetail) => boolean);
  options?: {
    label: string;
    value: string | number | boolean | (string | number | boolean)[];
  }[];
  multiline?: boolean;
  clearable?: boolean;
};

export type FormFieldsSectionProps = {
  sectionName: string;
  fields: FormField[];
};

export type FormSection = {
  sectionName: string;
  fields: FormField[];
};

type Props = FormSection;

export const FormFieldsSection: React.VFC<Props> = ({sectionName, fields}) => {
  const context = useFormikContext<HospitalProductDetail>();
  const isTabletDisplay = useMediaQuery(`(max-width:${APPENDIX_WIDTH_BREAK_POINT})`);

  const filteredFields = useMemo(
    () =>
      fields.filter((field) => {
        if (field.isVisible === undefined || field.isVisible === true) {
          return true;
        }

        if (_.isFunction(field.isVisible) && field.isVisible(context.values)) {
          return true;
        }

        return false;
      }),
    [fields, context.values]
  );

  const renderedSections = filteredFields.map((field, index) => {
    // prefer to use value by getValue
    const value = field.getValue
      ? field.getValue(context.values)
      : field.name in context.values
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (context.values as any)[field.name]
      : '';
    const readOnly = _.isFunction(field.readOnly)
      ? field.readOnly(context.values, context.initialValues)
      : field.readOnly;

    return (
      isTabletDisplay ? (
        <SectionFormFieldInTablet key={sectionName + index}>
          <FastField
            type={field.type}
            label={field.label}
            fullWidth
            name={field.name}
            value={value !== null ? value : ''}
            component={field.InputComponent}
            disabled={readOnly}
            options={field.options}
            multiline={field.multiline}
            clearable={field.clearable}
          />
        </SectionFormFieldInTablet>
        ) : (
        <SectionFormField key={sectionName + index}>
          <FastField
            type={field.type}
            label={field.label}
            fullWidth
            name={field.name}
            value={value !== null ? value : ''}
            component={field.InputComponent}
            disabled={readOnly}
            options={field.options}
            multiline={field.multiline}
            clearable={field.clearable}
          />
        </SectionFormField>
      )
    );
  });

  return (
    <SectionContainer>
      <SectionLabel>{sectionName}</SectionLabel>
      {isTabletDisplay ? (
        <SectionDetailInTablet>{renderedSections}</SectionDetailInTablet>
      ) : (
        <SectionDetail>{renderedSections}</SectionDetail>
      )}
    </SectionContainer>
  );
};
