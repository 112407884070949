import React, {Suspense, useCallback, useMemo, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Divider,
} from '@material-ui/core';
import {ArrowForward, Close} from '@material-ui/icons';
import {InspectionIndex} from '@modules/inspections/types';
import {InspectionType, InspectionTypeMap} from '@modules/inspections/enum';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {isNullish} from '@front-libs/helpers';
import {InspectionCard} from './InspectionCard';
import {InspectionTable} from './InspectionTable';
import {ProductElement} from '../../InspectionProduct';

export type EditInspectionDialogProps = DialogProps & {
  inspectionType: InspectionType;
  currentInspection?: InspectionIndex;
  product: ProductElement;
};

export type EditInspectionDialogResult = {
  newInspectionHashId: string;
};

export const EditInspectionDialog: React.FC<EditInspectionDialogProps> = (props) => {
  const {open, currentInspection, inspectionType, product} = props;

  const isOpen = typeof open === 'boolean' ? open : false;

  const classes = useStyles();

  const [newInspection, setNewInspection] = useState<InspectionIndex | undefined>();

  const isValid = useMemo(
    () => !isNullish(newInspection?.hashId) && currentInspection?.hashId !== newInspection?.hashId,
    [currentInspection?.hashId, newInspection]
  );

  const handleClickSelect = useCallback(() => {
    if (isNullish(newInspection)) return;

    props.actions.resolve({
      newInspectionHashId: newInspection.hashId,
    });
  }, [newInspection, props.actions]);

  const handleClickInspectionName = useCallback((hashId: string) => {
    window.open(`/inspections/${hashId}`, '_blank');
  }, []);

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title" maxWidth="lg">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} style={{fontSize: '20px', fontWeight: 'bold'}}>
          紐づける点検表を選択
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.fields} direction="column">
          <Grid item container spacing={3}>
            <Grid item className={classes.details}>
              <Typography variant="body2" className={classes.detailColor}>
                大分類: {product.rootCategory}
              </Typography>
              <Typography variant="body2" className={classes.detailColor}>
                小分類: {product.narrowCategory}
              </Typography>
            </Grid>
            <Grid item className={classes.details}>
              <Typography variant="body2" className={classes.detailColor}>
                型式: {product.name}
              </Typography>
              <Typography variant="body2" className={classes.detailColor}>
                機種名: {product.displayName}
              </Typography>
            </Grid>
            <Grid item className={classes.details}>
              <Typography variant="body2" className={classes.detailColor}>
                メーカー名: {product.makerName}
              </Typography>
              <Typography variant="body2" className={classes.detailColor}>
                点検タイプ: {InspectionTypeMap[inspectionType].label}
              </Typography>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} style={{background: '#65676B'}} />

          <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
            <Grid item style={{width: 'calc((100% - 40px) / 2)'}}>
              <InspectionCard
                cardTitle="現在紐づいている点検表"
                inspection={currentInspection}
                onClickInspectionName={() => {
                  if (currentInspection) handleClickInspectionName(currentInspection.hashId);
                }}
              />
            </Grid>
            <Grid item style={{textAlign: 'center'}}>
              <ArrowForward />
            </Grid>
            <Grid item style={{width: 'calc((100% - 40px) / 2)'}}>
              <InspectionCard
                cardTitle="新しく紐づける点検表"
                inspection={newInspection}
                onClickInspectionName={() => {
                  if (newInspection) handleClickInspectionName(newInspection.hashId);
                }}
                currentTitle={currentInspection?.name}
              />
            </Grid>
          </Grid>
        </Grid>
        <Suspense fallback={null}>
          <InspectionTable
            currentInspection={currentInspection}
            inspectionType={inspectionType}
            distInspection={newInspection}
            onSelectInspection={(inspection: InspectionIndex) => setNewInspection(inspection)}
            onClickInspectionName={handleClickInspectionName}
          />
        </Suspense>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleClickSelect} disabled={!isValid}>
          {inspectionType === 'periodic' ? '保存して点検間隔の設定へ進む' : '保存'}
        </Button>
        <Button variant="text" onClick={handleClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 30px',
  },
  title: {
    color: '#172B4D',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  actions: {
    padding: '24px 32px 32px',
  },
  divider: {
    margin: '0px',
    width: '100%',
  },
  boxTitle: {
    fontSize: 14,
    fontColor: '#172B4D',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'max-content',
  },
  detailColor: {
    color: '#172B4D',
  },
}));
