import {styled} from '@material-ui/core';
import React from 'react';

const Label = styled('div')({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#65676B',
});

const Value = styled('div')({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#172B4D',
});

export type MobileProductFormFieldProps = {
  label: string;
  value: string;
};

export const MobileProductFormField = (props: MobileProductFormFieldProps) => {
  const {label, value} = props;
  return (
    <div>
      <Label>{label}</Label>
      <Value>{value === '' ? '未入力' : value}</Value>
    </div>
  );
};
