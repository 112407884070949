import {Grid, makeStyles} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {theme} from '@components/atoms/theme';
import barcode from './assets/readLenderBarcode.png';
import {isNullish} from '@front-libs/helpers';

type Props = {
  onSubmitInput: (lenderName: string) => void;
};

export const BarcodeContent: React.FC<Props> = ({onSubmitInput}) => {
  const classes = useStyles();
  const [lenderName, setLenderName] = useState('');

  const focusInput = useCallback((e: React.FocusEvent<HTMLInputElement, Element>) => {
    // FIX ME: 名前検索の実装後、by_direct_inputは消す
    e.target.focus();
  }, []);

  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Enter以外のキーを検知したらlenderNameに連結する
      if (event.key !== 'Enter') {
        setLenderName(lenderName + event.key);
        return;
      } else if (event.key === 'Enter' && lenderName?.length === 0) {
        return;
      }

      onSubmitInput(lenderName);
    },
    [lenderName, onSubmitInput]
  );

  return (
    <Grid container alignItems="center" justifyContent="space-around" className={classes.root}>
      <input
        type="text"
        autoFocus
        readOnly
        onBlur={focusInput}
        value={!isNullish(lenderName) ? lenderName : ''}
        onKeyPress={handleBarcodeReaderInput}
        className={classes.barcodeInput}
      />
      <Grid item className={classes.tableContainer}>
        <img src={barcode} alt="バーコードの使い方" className={classes.barcodeImg} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '40px',
  },
  barcodeInput: {
    position: 'absolute',
    top: '20',
    marginTop: '-1550px',
  },
  tableContainer: {
    borderRadius: '10px',
    padding: '40px 26px',
    [theme.breakpoints.up('laptop')]: {
      padding: '48px 32px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '54px 40px',
    },
  },
  barcodeImg: {
    width: '464px',
    [theme.breakpoints.up('laptop')]: {
      width: '580px',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '696px',
    },
  },
  barcodeImg2: {
    width: '352px',
    paddingTop: '56px',
    [theme.breakpoints.up('laptop')]: {
      width: '480px',
      paddingTop: '64px',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '528px',
      paddingTop: '80px',
    },
  },
}));
