import {axios} from '@front-libs/core';
import {HospitalProductLogIndex} from '@modules/hospital_products/types';
import {
  CreateHospitalProductLogParams,
  FetchHospitalProductLogsParams,
  FetchHospitalProductLogsResult,
  UpdateHospitalProductLogParams,
} from '@modules/hospital_products/api/index';

export const getHospitalProductLogs = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  params: FetchHospitalProductLogsParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/logs`;
  const {data} = await axios.get<FetchHospitalProductLogsResult>(baseUrl, {params});
  return data;
};

export const createHospitalProductLog = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  data: CreateHospitalProductLogParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/logs`;
  return await axios.post<HospitalProductLogIndex>(baseUrl, data);
};

export const updateHospitalProductLog = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  hospitalProductLogHashId: string,
  data: UpdateHospitalProductLogParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/logs/${hospitalProductLogHashId}`;
  return axios.put<HospitalProductLogIndex>(baseUrl, data);
};

export const deleteHospitalProductLog = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  hospitalProductLogHashId: string
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}/logs/${hospitalProductLogHashId}`;
  return axios.delete(baseUrl);
};
