import {useMemo, useCallback} from 'react';
import {atom} from 'jotai';
import {useAtomValue, useSetAtom} from 'jotai';
import {focusedItemAtom} from '@Apps/Inspection/states';

export const useFocused = (sectionIndex: number, fieldIndex: number | null) => {
  const updateFocusedItem = useSetAtom(focusedItemAtom);
  const isFocused = useAtomValue(
    useMemo(
      () =>
        atom((get) => {
          const focusedItem = get(focusedItemAtom);
          return focusedItem.sectionIndex === sectionIndex && focusedItem.fieldIndex === fieldIndex;
        }),
      [sectionIndex, fieldIndex]
    )
  );
  const onFocused = useCallback(
    (el: HTMLDivElement) => {
      updateFocusedItem({
        el,
        sectionIndex,
        fieldIndex,
      });
    },
    [updateFocusedItem, sectionIndex, fieldIndex]
  );
  return [isFocused, onFocused] as const;
};
