import React from 'react';
import {DurationButton} from '../../components/DurationButton';
import {DatePicker} from '@components/molecules/DatePicker';
import {monthMenuItems, yearMenuItems, rentalRatioChartOptions} from './constants';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {RentalRatioType} from '../types';
import {useStyles} from './styles';

export type ToolbarProps = {
  date: Date;
  rentalRatioType: RentalRatioType;
  actionMenuItems: MenuItemType[];
  categoryHashId: string;
  onChangeRentalRatioType: (type: RentalRatioType) => void;
  onChangeDate: (date: Date | null) => void;
  onChangeMonth: (month: number) => void;
  onChangeYear: (year: number) => void;
  onChangeCategory: (category: string) => void;
};

export const Toolbar: React.FC<ToolbarProps> = ({
  date,
  rentalRatioType,
  actionMenuItems,
  categoryHashId,
  onChangeRentalRatioType,
  onChangeDate,
  onChangeMonth,
  onChangeYear,
  onChangeCategory,
}) => {
  const classes = useStyles();
  return (
    <>
      <DurationButton
        value={rentalRatioType}
        buttonGroup={rentalRatioChartOptions}
        handleChange={(value) => onChangeRentalRatioType(value as RentalRatioType)}
      />

      {(rentalRatioType === 'daily' || rentalRatioType === 'weekly') && (
        <DatePicker
          value={date}
          placeholder="日付を選択"
          onChange={onChangeDate}
          maxDate={new Date()}
          buttonProps={{className: classes.datePickerButton}}
          buttonColor="#000"
        />
      )}
      {(rentalRatioType === 'monthly' || rentalRatioType === 'yearly') && (
        <PopperMenuButton
          menuItemList={yearMenuItems()}
          onMenuClick={({value}: {value: number}) => onChangeYear(value)}
          buttonProps={{variant: 'contained', disableElevation: true, className: classes.selectCategoryButton}}>
          {date.getFullYear()}年度
        </PopperMenuButton>
      )}
      {rentalRatioType === 'monthly' && (
        <PopperMenuButton
          menuItemList={monthMenuItems(date.getFullYear())}
          onMenuClick={({value}: {value: number}) => onChangeMonth(value)}
          buttonProps={{variant: 'contained', disableElevation: true, className: classes.selectCategoryButton}}>
          {date.getMonth() + 1}月
        </PopperMenuButton>
      )}
      <PopperMenuButton
        menuItemList={actionMenuItems}
        onMenuClick={({value}: {value: string}) => onChangeCategory(value)}
        buttonProps={{variant: 'contained', disableElevation: true, className: classes.selectCategoryButton}}>
        {actionMenuItems.find((i) => i.value === categoryHashId)?.label ?? 'カテゴリを選択'}
      </PopperMenuButton>
    </>
  );
};
