import {atom} from 'jotai';
import {InspectionType} from '@modules/inspections/enum';
import {atomWithReset} from 'jotai/utils';
import {InspectionIndex} from '@modules/inspections/types';

export const selectedTemplateAtom = atomWithReset<InspectionIndex | null>(null);
export const searchNameAtom = atom<string | null>(null);
export const inspectionTypeAtom = atom<InspectionType | null>(null);
export const rootCategoryAtom = atomWithReset<string | null>(null);
export const narrowCategoryAtom = atomWithReset<string | null>(null);
export const pageAtom = atom<number>(1);
