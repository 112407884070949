import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {CompanyIndex} from './types';

export type FetchCompaniesParam = {
  page?: number;
  perPage?: number;
  name?: string;
};

export type FetchCompaniesResult = {
  totalCount: number;
  page: number;
  data: CompanyIndex[];
};

export const fetchCompanies = async (hospitalHashId: string, fetchCompaniesParam: FetchCompaniesParam) => {
  const {data} = await axios.get<FetchCompaniesResult>(`${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/companies`, {params: fetchCompaniesParam});
  return data;
};

export const useFetchCompaniesQuery = (hospitalHashId: string, fetchCompaniesQueryParam: FetchCompaniesParam) => {
  return useQuery(['fetchCompanies', hospitalHashId, fetchCompaniesQueryParam], () => fetchCompanies(hospitalHashId, fetchCompaniesQueryParam));
};
