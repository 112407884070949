import React, {useEffect, useRef} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {chartOptions} from './constants';
import {useCostTrendsByRootCategorySeries, useXAxisCategories} from '../hooks';

export const ChartByRootCategory: React.FC = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    const current = chartComponentRef.current;
    current?.chart.reflow();
  }, []);

  const xAxisCategory = useXAxisCategories();
  const series = useCostTrendsByRootCategorySeries();

  const options = {
    ...chartOptions,
    xAxis: {
      categories: xAxisCategory,
    },
    series,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
};
