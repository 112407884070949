import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FAKE_INSPECTION_ID} from '@Apps/InspectionResult/pc/common/hooks';
import {InnerLoading} from '@components/molecules/Loading';
import {useInitialized} from '@front-libs/core';
import {RFC3339Format} from '@front-libs/helpers';
import {Box, Container} from '@material-ui/core';
import {KeyboardArrowDown} from '@material-ui/icons';
import {styled} from '@material-ui/styles';
import {useJointingPostUseInspectionSettings} from '@modules/hospital_settings/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useInspectionSelectorOptions} from '@modules/inspection/hooks';
import {useFetchInspectionResultsQuery} from '@modules/inspection_results/api';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionType} from '@modules/inspections/enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {InspectionSheetSelector} from './InspectionSheetSelector';
import {UserIndex} from '@modules/hospital_users/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {InspectorSelector} from './InspectorSelector';
import {useAtom} from 'jotai';
import {selectedInspectorAtom, selectedInspectionAtom} from './hooks';

dayjs.extend(utc);
/* 未使用
const formatPeriodicInspectionResultLabel = (r: InspectionResultIndex) => {
  if (r.inspection) {
    return `「${r.inspection.name}」 定期点検を代わりに実施する`;
  }

  if (r.scheduledTime) {
    return `${convertDateToJapaneseDate(new Date(r.scheduledTime))} 実施予定の定期点検を代わりに実施する`;
  }

  return '定期点検を代わりに実施する';
};

const formatPostUseInspectionResultLabel = (r: InspectionResultIndex) => {
  if (r.inspection) {
    return `「${r.inspection.name}」 使用後点検`;
  }

  return `${r.hospitalProduct.managementId} の使用後点検`;
};
 */
const StyledButton = styled('button')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px',
  border: `1px solid  #C6CBD4`,
  borderRadius: '4px',
  backgroundColor: 'white',
  width: '100%',
});

const StyledKeyboardArrowDown = styled(KeyboardArrowDown)({
  fontSize: '12px',
});
const StyledContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
});

const StyledDefaultTextCSS = {
  fontSize: '16px',
  fontWeight: 400,
};

const StyledTitleText = styled('p')({
  ...StyledDefaultTextCSS,
  color: '#172B4D',
  margin: '16px 0px 4px',
});
const StyledSelectBoxText = styled('p')({
  ...StyledDefaultTextCSS,
  margin: '4px',
});

type InspectionRecordProps = {
  inspectionResult: InspectionResultIndex;
  wholeProductHashId: string;
  hospitalProductHashId: string;
  defaultInspectionHashId?: string;
  inspectionType?: InspectionType;
  showsSelectInspection?: boolean;
  hospitalUsers: UserIndex[];
};

/**
 * 点検記録コンポーネント。
 * 点検表の選択と点検者の選択ダイアログを提供し、点検プロセスの一部を管理します。
 *
 * @param props コンポーネントのプロパティ
 * @param props.inspectionResult 点検結果のデータを含むオブジェクト。
 * @param props.wholeProductHashId 製品全体のハッシュID。
 * @param props.hospitalProductHashId 病院製品のハッシュID。
 * @param props.defaultInspectionHashId デフォルトの点検ハッシュID。
 * @param props.inspectionType 点検のタイプ（例：'post_use'）。
 * @param props.showsSelectInspection 点検表選択の表示/非表示を制御するブール値。
 * @param props.hospitalUsers 病院のユーザー情報のリスト。
 */
export const InspectionRecord = ({
  inspectionResult,
  wholeProductHashId,
  hospitalProductHashId,
  defaultInspectionHashId,
  inspectionType,
  showsSelectInspection,
  hospitalUsers: userIndexList,
}: InspectionRecordProps) => {
  // 点検表
  const [openInspectionBox, setOpenInspectionBox] = useState(false);
  const [selectedInspection, setSelectedInspection] = useAtom(selectedInspectionAtom);
  const isDefaultInspection = useRef(false);
  // 点検者
  const [openInspectorBox, setOpenInspectorBox] = useState(false);
  const [selectedInspector, setSelectedInspector] = useAtom(selectedInspectorAtom);
  // NOTE:以下 components/organisms/StartInspectionDialog/index.tsxとほぼ共通
  const {myInfo} = useMyInfo();
  const [now] = useState(dayjs());

  // 点検者初期値
  const defaultInspectorHashId = myInfo.hashId;

  // 使用後点検の場合のみ、同じ製品の定期点検が予定されているか取得する
  const jointingPostUseInspectionSettings = useJointingPostUseInspectionSettings(myInfo.hospitalHashId);
  const enabledInspectionResultSelector =
    inspectionType === 'post_use' &&
    jointingPostUseInspectionSettings.allowed === true &&
    (jointingPostUseInspectionSettings.period ?? 0) > 0;

  const {isLoading: isPeriodicInspectionResultsLoading} = useFetchInspectionResultsQuery(
    myInfo.hospitalHashId,
    defaultInspectionHashId ?? FAKE_INSPECTION_ID,
    {
      // 同一製品のn日以内の未完了定期点検を取得
      types: 'periodic',
      hospitalProductHashId: hospitalProductHashId,
      statuses: 'unplanned,uncompleted',
      order: 'scheduledTime',
      scheduledAtFrom: now.startOf('date').format(RFC3339Format),
      scheduledAtTo: now
        // N日以内の場合は今日〜(今日+N-1)日の終わりまで
        // (今日だけの場合はN=1)
        .add((jointingPostUseInspectionSettings.period ?? 1) - 1, 'day')
        .endOf('day')
        .format(RFC3339Format),
    },
    {
      enabled: enabledInspectionResultSelector,
    }
  );

  // 文字列検索
  const [searchInspectionName, setSearchInspectionName] = useState('');
  const handleSearchInspection = useCallback((name: string) => {
    setSearchInspectionName(name);
  }, []);

  // 点検表完了ボタン押下時のコールバック関数
  const completeInspectionCallBack = useCallback(() => {
    setOpenInspectionBox(false);
  }, []);

  // 点検者完了ボタン押下時のコールバック関数
  const completeInspectorCallBack = useCallback(
    (userIndex?: UserIndex) => {
      setOpenInspectorBox(false);
      if (userIndex) setSelectedInspector(userIndex);
    },
    [setSelectedInspector]
  );

  const {options: inspectionOptions, isLoading: isInspectionsLoading} = useInspectionSelectorOptions(
    myInfo.hospitalHashId,
    searchInspectionName,
    wholeProductHashId
  );

  // デフォルト点検者
  const defaultInspector = defaultInspectorHashId ?? myInfo.hashId;
  const defaultUser = useMemo(() => {
    const user = userIndexList.find((v) => v.hashId === defaultInspector);
    setSelectedInspector(user);
    return user ?? userIndexList[0];
  }, [defaultInspector, setSelectedInspector, userIndexList]);

  // 2回目以降のデータ取得ではnullを返さない
  const isInitialized = useInitialized(!isInspectionsLoading && !isPeriodicInspectionResultsLoading);

  // ここまでpackages/front-app-pc/@components/organisms/StartInspectionDialog/index.tsxとほぼ共通

  useEffect(() => {
    if (isDefaultInspection.current) return;

    // 機種に基づく点検表があればデフォルトにする
    if (inspectionOptions && inspectionOptions.length > 0 && inspectionOptions[0].options.length > 0) {
      const defaultInspection = inspectionOptions[0].options[0].value;
      setSelectedInspection(defaultInspection);
      isDefaultInspection.current = true;
    }
  }, [inspectionOptions, openInspectionBox, setSelectedInspection]);

  /** 点検表クリック */
  const onClickCheckList = useCallback(async () => {
    setOpenInspectionBox(true);
  }, []);
  /** 点検者クリック */
  const onClickInspector = useCallback(() => {
    setOpenInspectorBox(true);
  }, []);

  if (!isInitialized) {
    return <InnerLoading />;
  }

  return (
    <>
      {/* 点検表ダイアログ*/}
      {openInspectionBox && (
        <InspectionSheetSelector
          inspectionOptions={inspectionOptions}
          handleSearchInspection={handleSearchInspection}
          completeCallBack={completeInspectionCallBack}
        />
      )}
      {/* 点検者ダイアログ*/}
      {openInspectorBox && (
        <InspectorSelector
          userIndexList={userIndexList}
          defaultUser={defaultUser}
          completeCallBack={completeInspectorCallBack}
        />
      )}
      <Container>
        {/* 点検表 */}
        {showsSelectInspection && (
          <StyledContainerBox>
            <StyledTitleText>点検表</StyledTitleText>
            <StyledButton onClick={onClickCheckList}>
              <StyledSelectBoxText>{selectedInspection ? selectedInspection.name : '検索'}</StyledSelectBoxText>
              <StyledKeyboardArrowDown />
            </StyledButton>
          </StyledContainerBox>
        )}
        {/* 点検者 */}
        <StyledContainerBox>
          <StyledTitleText>点検者</StyledTitleText>
          <StyledButton onClick={onClickInspector}>
            <StyledSelectBoxText>
              {selectedInspector
                ? UserFormatter.getFullName(selectedInspector)
                : defaultUser
                ? UserFormatter.getFullName(defaultUser)
                : `点検者`}
            </StyledSelectBoxText>
            <StyledKeyboardArrowDown />
          </StyledButton>
        </StyledContainerBox>
      </Container>
    </>
  );
};
