import React from 'react';
import {Grid, Paper, styled} from '@material-ui/core';
import dayjs from 'dayjs';
import AssignmentIcon from '@material-ui/icons/Assignment';

const CardText = styled('div')({
  fontSize: '14px',
  lineHeight: '16px',

  color: '#172B4D',
});

export type MobileInspectionResultCardProps = {
  inspectionName: string;
  displayDate: Date;
  status: string; // 正常、異常あり
  completedAt: string; // YYYY年MM月DD日
  inspectorFullName: string;
};

export const MobileInspectionResultCard = (props: MobileInspectionResultCardProps) => {
  const {inspectionName, displayDate, status, completedAt, inspectorFullName} = props;
  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: 0,
        borderBottom: '0.5px solid #DDE0E4',
        padding: 16,
        marginLeft: '-16px',
        marginRight: '-16px',
      }}>
      <Grid container alignItems={'center'} justifyContent="space-between" style={{marginBottom: 8}}>
        <Grid item>
          <Grid container style={{gap: 8}} alignItems="center">
            <Grid item>
              <div
                style={{
                  width: 28,
                  height: 28,
                  paddingTop: 4,
                  paddingLeft: 4,
                  background: '#F0F2F5',
                  borderRadius: '16px',
                }}>
                <AssignmentIcon />
              </div>
            </Grid>
            <Grid item style={{fontSize: 16}}>
              <span style={{color: '#0052CC'}}>{inspectionName}</span>の実施
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{dayjs(displayDate).format('YYYY/M/D')}</Grid>
      </Grid>
      <Grid container alignItems={'center'} style={{paddingLeft: 40}}>
        <Grid item style={{width: '100%'}}>
          <CardText>結果：{status}</CardText>
          <CardText>点検完了日：{completedAt}</CardText>
          <CardText>最終点検者：{inspectorFullName}</CardText>
        </Grid>
      </Grid>
    </Paper>
  );
};
