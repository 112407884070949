import {useAtomValue} from 'jotai';
import {inspectionStartDateVariables, pageSizeAtom, searchFilterResultsAtom} from './jotai';
import {useFetchHospitalProductWithInspectionProductQuery} from '@modules/hospital_products/api';
import {useCallback, useMemo, useState} from 'react';
import {useGetHospitalCategories, useGetHospitalSomeDescendantCategories} from '@modules/categories/api';

export const useHospitalProductWithInspectionProductQuery = (hospitalHashId: string) => {
  const variables = useAtomValue(inspectionStartDateVariables);
  const pageSize = useAtomValue(pageSizeAtom);
  const query = useFetchHospitalProductWithInspectionProductQuery(hospitalHashId, variables);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  return {...query, totalPage};
};

export const useIsDetailFilterActive = () => {
  const searchFilterResults = useAtomValue(searchFilterResultsAtom);
  return searchFilterResults.length > 0;
};

export const useCategoryQuery = (hospitalHashId: string) => {
  const [selectedRootCategory, setSelectedRootCategory] = useState<string[]>([]);
  const rootCategoryQuery = useGetHospitalCategories(hospitalHashId, {depth: 0});
  const narrowCategoryQuery = useGetHospitalCategories(hospitalHashId, {depth: 1});

  const descendantCategoriesQueries = useGetHospitalSomeDescendantCategories(hospitalHashId, selectedRootCategory);

  const selectRootCategory = useCallback((categoryHashIds: string[] | null) => {
    setSelectedRootCategory(categoryHashIds ?? []);
  }, []);

  return {rootCategoryQuery, narrowCategoryQuery, descendantCategoriesQueries, selectRootCategory};
};
