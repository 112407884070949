import {atom} from 'jotai';
import {atomWithReset, atomWithStorage} from 'jotai/utils';
import {
  DateRangeConditionValue,
  ResultType,
  SelectorConditionValue,
  TextConditionValue,
  NumberConditionValue,
} from '@molecules/Drawers/FilterDrawer/types';
import {FetchHospitalProductsParams} from '@modules/hospital_products/api';
import {SelectOptionProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {Order} from '@molecules/Table/props';
import {productStatusOptionsWODisabled} from '@constants/constants';
import {convertDateToRFC3339, isNullish} from '@front-libs/helpers';

const LOCAL_STORAGE_KEY_PRODUCTS_LIST = 'hitotsu/products_page_size_status';
export const includeDisposedAtom = atom<boolean>(false);
export const searchNameAtom = atom<string | null>(null);
export const orderKeyAtom = atom<Order | null>(null);
export const searchStatusesAtom = atom<SelectOptionProps[]>([]);
export const searchRootCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchSubCategoriesAtom = atom<SelectOptionProps[]>([]);
export const searchFilterResultsAtom = atom<ResultType[]>([]);
export const pageAtom = atomWithReset<number>(1);
export const pageSizeAtom = atomWithStorage<number>(LOCAL_STORAGE_KEY_PRODUCTS_LIST, 20);

type DetailFilter = {
  permanentlyAssigneds: boolean;
  waysOfPurchases: string;
  hospitalWardHashId: string;
  hospitalRoomHashId: string;
  hospitalDealerHashId: string;
  hospitalProductMgmtSectionHashId: string;
  notes: string;
  managementId: string;
  serialNumber: string;
  lotNumber: string;
  name: string;
  displayName: string;
  reasonOfDisposal: string;
  assetRegisterNumber: string;
  rentalId: string;
  leaseId: string;
  optionalBarcode: string;
  rawBarcode: string;
  rentalDealerName: string;
  leaseDealerName: string;
  isMaintenanceContract: boolean;
  isBaseUnit: boolean;
  purchasedNationalExpense: boolean;
  createdByHashId: string;
  rentHospitalWardHashId: string;
  rentHospitalRoomHashId: string;
  dateOfPurchaseFrom: string;
  dateOfPurchaseTo: string;
  catalogPriceFrom: number;
  catalogPriceTo: number;
  legalDurableYearFrom: number;
  legalDurableYearTo: number;
  periodicInspectionStartDateFrom: string;
  periodicInspectionStartDateTo: string;
  dateOfDisposalFrom: string;
  dateOfDisposalTo: string;
  createdAtFrom: string;
  createdAtTo: string;
  rentalStartDateFrom: string;
  rentalStartDateTo: string;
  rentalFeeFrom: number;
  rentalFeeTo: number;
  leaseStartDateFrom: string;
  leaseStartDateTo: string;
  leaseFeeFrom: number;
  leaseFeeTo: number;
};

export const hospitalProductsVariables = atom<FetchHospitalProductsParams>((get) => {
  const includeDisposed = get(includeDisposedAtom);
  const rootCategories = get(searchRootCategoriesAtom);
  const subCategories = get(searchSubCategoriesAtom);
  const detailFilter = convertFilterResults(get(searchFilterResultsAtom));
  const order = get(orderKeyAtom);
  const statuses = get(searchStatusesAtom);

  const _p: FetchHospitalProductsParams = {
    page: get(pageAtom) - 1 || 0,
    perPage: get(pageSizeAtom),
    name: get(searchNameAtom) ?? undefined,
    statuses:
      statuses.length > 0
        ? statuses.map((item) => item.value).join(',')
        : !includeDisposed
        ? productStatusOptionsWODisabled.map((item) => item.value).join(',')
        : undefined,
    categoryHashIds:
      subCategories.length > 0
        ? subCategories.map((item) => item.value).join(',')
        : rootCategories.length > 0
        ? rootCategories.map((item) => item.value).join(',')
        : undefined,
    order: order ? `${order.direction === 'desc' ? '-' : ''}${order.fieldId}` : 'managementId',
    ...detailFilter,
  };
  return _p;
});

const convertFilterResults = (filterResults: ResultType[]): Partial<DetailFilter> => {
  const detailFilter: Partial<DetailFilter> = {};
  filterResults.forEach((item) => {
    switch (item.key) {
      case 'permanentlyAssigneds':
        detailFilter.permanentlyAssigneds = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'waysOfPurchases':
        detailFilter.waysOfPurchases = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'hospitalWardHashId':
        detailFilter.hospitalWardHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'hospitalRoomHashId':
        detailFilter.hospitalRoomHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'notes':
        detailFilter.notes = item.resultValue as TextConditionValue;
        break;
      case 'managementId':
        detailFilter.managementId = item.resultValue as TextConditionValue;
        break;
      case 'serialNumber':
        detailFilter.serialNumber = item.resultValue as TextConditionValue;
        break;
      case 'lotNumber':
        detailFilter.lotNumber = item.resultValue as TextConditionValue;
        break;
      case 'name':
        detailFilter.name = item.resultValue as TextConditionValue;
        break;
      case 'displayName':
        detailFilter.displayName = item.resultValue as TextConditionValue;
        break;
      case 'reasonOfDisposal':
        detailFilter.reasonOfDisposal = item.resultValue as TextConditionValue;
        break;
      case 'assetRegisterNumber':
        detailFilter.assetRegisterNumber = item.resultValue as TextConditionValue;
        break;
      case 'rentalId':
        detailFilter.rentalId = item.resultValue as TextConditionValue;
        break;
      case 'leaseId':
        detailFilter.leaseId = item.resultValue as TextConditionValue;
        break;
      case 'optionalBarcode':
        detailFilter.optionalBarcode = item.resultValue as TextConditionValue;
        break;
      case 'rawBarcode':
        detailFilter.rawBarcode = item.resultValue as TextConditionValue;
        break;
      case 'rentalDealerName':
        detailFilter.rentalDealerName = item.resultValue as TextConditionValue;
        break;
      case 'leaseDealerName':
        detailFilter.leaseDealerName = item.resultValue as TextConditionValue;
        break;
      case 'isMaintenanceContract':
        detailFilter.isMaintenanceContract = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'isBaseUnit':
        detailFilter.isBaseUnit = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'purchasedNationalExpense':
        detailFilter.purchasedNationalExpense = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'createdByHashId':
        detailFilter.createdByHashId = (item.resultValue as SelectorConditionValue)[0].value;
        break;
      case 'rentHospitalWardHashId':
        detailFilter.rentHospitalWardHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'rentHospitalRoomHashId':
        detailFilter.rentHospitalRoomHashId = (item.resultValue as SelectorConditionValue)[0].value as string;
        break;
      case 'dateOfPurchase': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.dateOfPurchaseFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.dateOfPurchaseTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 点検開始日
      case 'periodicInspectionStartDate': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.periodicInspectionStartDateFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.dateOfPurchaseTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 廃棄日
      case 'dateOfDisposal': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.dateOfDisposalFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.dateOfDisposalTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 登録日
      case 'createdAt': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.createdAtFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.createdAtTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // レンタル開始日
      case 'rentalStartDate': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.rentalStartDateFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.rentalStartDateTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // リース開始日
      case 'leaseStartDate': {
        const typedValue = item.resultValue as DateRangeConditionValue;
        detailFilter.leaseStartDateFrom = convertDateToQueryString(typedValue?.from);
        detailFilter.leaseStartDateTo = convertDateToQueryString(typedValue?.to);
        break;
      }
      // 定価
      case 'catalogPrice': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.catalogPriceFrom = typedValue.from;
        detailFilter.catalogPriceTo = typedValue.to;
        break;
      }
      // 耐用年数
      case 'legalDurableYear': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.legalDurableYearFrom = typedValue.from;
        detailFilter.legalDurableYearTo = typedValue.to;
        break;
      }
      // レンタル金額
      case 'rentalFee': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.rentalFeeFrom = typedValue.from;
        detailFilter.rentalFeeTo = typedValue.to;
        break;
      }
      // リース金額
      case 'leaseFee': {
        const typedValue = item.resultValue as NumberConditionValue;
        detailFilter.leaseFeeFrom = typedValue.from;
        detailFilter.leaseFeeTo = typedValue.to;
        break;
      }
      // 担当代理店
      case 'hospitalDealerHashId': {
        detailFilter.hospitalDealerHashId = (item.resultValue as SelectorConditionValue)[0].value + '';
        break;
      }
      // 管理部署
      case 'hospitalProductMgmtSectionHashId': {
        detailFilter.hospitalProductMgmtSectionHashId = (item.resultValue as SelectorConditionValue)[0].value + '';
        break;
      }
      default:
        break;
    }
  });
  return detailFilter;
};

const convertDateToQueryString = (date: Date | undefined): string | undefined => {
  return !isNullish(date) ? convertDateToRFC3339(date) : undefined;
};
