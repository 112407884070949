import React, {useCallback, useMemo, useState} from 'react';
import {Grid} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {useBackPrevious, useDebounceCallback, withSuspense} from '@front-libs/core';
import {ListPageLayout} from '@components/layouts/ListPageLayout';
import {PageHeader} from './PageHeader';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {ToolBar} from './Toolbar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionStartDateList} from './InspectionStartDate';
import {useHospitalProductWithInspectionProductQuery} from './hooks';
import {useAtom} from 'jotai';
import {pageAtom, pageSizeAtom, searchFilterResultsAtom, searchNameAtom} from './jotai';
import {styled} from '@material-ui/styles';
import {Pagination} from '@material-ui/lab';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';
import {FilterDrawer} from '@molecules/Drawers/FilterDrawer';
import {FilterDrawerOptions} from './constants';
import {ResultType} from '@components/molecules/Drawers/FilterDrawer/types';
import {InspectionType} from '@modules/inspections/enum';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  NewInspectionDialog,
  NewInspectionDialogProps,
  NewInspectionDialogResult,
} from '@molecules/Dialogs/NewInspectionDialog';
import {createInspection} from '@modules/inspections/api';
import {DEFAULT_INSPECTION_NAME} from '@Apps/Inspection/states';
import {openSnackBar} from '@components/molecules/SnackBar';
import {getHospitalProduct, updateHospitalProduct} from '@modules/hospital_products/api';
import {isNullish} from '@front-libs/helpers';

const PageDescriptionGrid = styled(Grid)({
  margin: 'auto 0px',
  flex: 1,
});
const PaginationContainerGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
});
const EmptyGrid = styled(Grid)({
  flex: 1,
});

const _InspectionSettingStartDate: React.FC = () => {
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const [page, setPage] = useAtom(pageAtom);
  const [searchName, setSearchName] = useAtom(searchNameAtom);
  const query = useHospitalProductWithInspectionProductQuery(myInfo.hospitalHashId);
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);
  const [searchFilterResults, setSearchFilterResults] = useAtom(searchFilterResultsAtom);
  const goBackToResultsPage = useBackPrevious('/inspection/results');

  const handleSearchName = useDebounceCallback(
    (name: string) => {
      setPage(1);
      setSearchName(name);
    },
    300,
    [setPage, setSearchName]
  );

  const startDisplayPosition = useMemo(() => {
    return query.totalCount === 0 ? 0 : Math.ceil((page - 1) * pageSize + 1);
  }, [page, pageSize, query.totalCount]);

  const endDisplayPosition = useMemo(() => {
    const endPosition = Math.ceil(page * pageSize);
    return endPosition > query.totalCount ? query.totalCount : endPosition;
  }, [page, pageSize, query.totalCount]);

  const totalPage = useMemo(() => {
    return Math.ceil(query.totalCount / pageSize);
  }, [pageSize, query.totalCount]);

  const handleChangePage = useCallback(
    (_e: React.ChangeEvent<unknown>, p: number) => {
      setPage(p);
    },
    [setPage]
  );

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClickDrawer = useCallback(() => {
    setOpenDrawer((x) => !x);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const handleFilterChange = useCallback(
    (values: ResultType[]) => {
      setPage(1);
      setSearchFilterResults(values);
    },
    [setPage, setSearchFilterResults]
  );

  const handleClickPrevButton = useCallback(() => {
    goBackToResultsPage();
  }, [goBackToResultsPage]);

  const newInspection = async (hospitalHashId: string): Promise<string | null> => {
    let type: InspectionType;
    try {
      const res = await dialogHandler.open<NewInspectionDialogProps, NewInspectionDialogResult>(
        NewInspectionDialog,
        {}
      );
      type = res.type;
    } catch (_e) {
      return null;
    }

    try {
      const res = await createInspection(hospitalHashId, {
        name: DEFAULT_INSPECTION_NAME,
        type: type,
        status: 'draft',
      });

      return res.data.hashId;
    } catch (e) {
      console.error(e);
      openSnackBar('点検表作成に失敗しました', 'left', 'bottom', 'error');
    }

    return null;
  };

  const handleClickNewInspection = useCallback(async () => {
    const hashId = await newInspection(myInfo.hospitalHashId);
    if (hashId !== null) {
      navigate(`/inspections/${hashId}`);
    }
  }, [myInfo.hospitalHashId, navigate]);

  const handleChangeStartDate = useCallback(
    async (productHashId: string, date: string) => {
      if (isNullish(date)) {
        return;
      }

      const hospitalProduct = await getHospitalProduct(myInfo.hospitalHashId, productHashId);
      hospitalProduct.periodicInspectionStartDate = date;
      await updateHospitalProduct(myInfo.hospitalHashId, productHashId, {
        ...hospitalProduct,
        maker: undefined,
        catalogPrice: undefined,
        deliveryPrice: undefined,
        taxRate: undefined,
        legalDurableYear: undefined,
        leaseFee: undefined,
        rentalFee: undefined,
      });
      query.refetch();
      openSnackBar('点検開始日を更新しました。', 'center', 'top', 'success');
    },
    [myInfo.hospitalHashId, query]
  );

  return (
    <ListPageLayout page="inspection_setting_start_date">
      <ListPageLayout.Header>
        <PageHeader onClickPrevButton={handleClickPrevButton} onClickNewInspection={handleClickNewInspection} />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <TableViewLayout>
          <TableViewLayout.Header>
            <ToolBar
              searchName={searchName ?? ''}
              onChangeSearchName={handleSearchName}
              onClickDrawer={handleClickDrawer}
            />
          </TableViewLayout.Header>

          <TableViewLayout.Body>
            <InspectionStartDateList
              isLoading={query.isLoading}
              products={query.data}
              handleChangeStartDate={handleChangeStartDate}
              refetch={query.refetch}
            />
          </TableViewLayout.Body>

          <TableViewLayout.Footer container justifyContent="space-between">
            <PageDescriptionGrid item>
              {query.totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
            </PageDescriptionGrid>
            <PaginationContainerGrid item>
              <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
              <DisplayNumberSelect
                pageSize={pageSize}
                update={(selectNum) => {
                  setPageSize(selectNum);
                  setPage(1);
                }}
              />
            </PaginationContainerGrid>
            <EmptyGrid />
          </TableViewLayout.Footer>
        </TableViewLayout>
      </ListPageLayout.Content>

      <FilterDrawer
        open={openDrawer}
        onDrawerClose={handleDrawerClose}
        currentCount={query.totalCount}
        filterOptions={FilterDrawerOptions}
        onFilterChange={handleFilterChange}
        defaultFilterResults={searchFilterResults}
      />
    </ListPageLayout>
  );
};

export const InspectionSettingStartDate = withSuspense(_InspectionSettingStartDate, null);
