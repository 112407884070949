import {axios} from '@front-libs/core';
import {RepairStatuses} from '../types';

export const getFaultRepairStatuses = async (hospitalHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/fault_repair_settings/statuses`;
  const {data} = await axios.get<{data: RepairStatuses[]}>(baseUrl);
  return data;
};
