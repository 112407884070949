import {Typography, styled} from '@material-ui/core';
import React from 'react';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

const RootContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  color: theme.palette.primary.main,
}));

const LabelTypography = styled(Typography)({
  paddingLeft: '8px',
  fontSize: '14px',
  cursor: 'pointer',
});

const AlignTopIcon = styled(VerticalAlignTopIcon)(({theme}) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));
type Props = {
  onUploadFile: () => void;
};

export const FileUploadIconButton = ({onUploadFile}: Props) => {
  return (
    <RootContainer>
      <AlignTopIcon onClick={onUploadFile} />
      <LabelTypography onClick={onUploadFile}>ファイルをアップロード</LabelTypography>
    </RootContainer>
  );
};
