import {useQuery} from 'react-query';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {getMakerInspectionResult, getMakerInspectionResultFiles, getMakerInspectionResults} from './api';
import {MakerInspectionResultStatus, MakerInspectionResultStatusToLabel} from './constants';

export const useFetchMakerInspectionResultsCountsQuery = () => {
  const {myInfo} = useMyInfo();
  return useQuery(['useFetchMakerInspectionResultsCountsQuery', myInfo.hospitalHashId], async () => {
    const statuses = Object.keys(MakerInspectionResultStatusToLabel) as (MakerInspectionResultStatus | 'all')[];

    const results = await Promise.all(
      statuses.map((status) =>
        getMakerInspectionResults(myInfo.hospitalHashId, {
          perPage: 1,
          status: status === 'all' ? undefined : status,
        })
      )
    );

    const data: Record<string, number> = {};
    statuses.forEach((status, index) => {
      data[status] = results[index].data.totalCount;
    });

    return data as {[key in MakerInspectionResultStatus | 'all']: number};
  });
};

export const useFetchMakerInspectionResultQuery = (hospitalHashId: string, hashId: string) => {
  return useQuery(['useFetchMakerInspectionResultQuery', hospitalHashId, hashId], () =>
    getMakerInspectionResult(hospitalHashId, hashId)
  );
};

export const useFetchMakerInspectionResultFilesQuery = (
  hospitalHashId: string,
  inspectionResultHashId: string,
  order?: string
) => {
  return useQuery(['useFetchInspectionResultFilesQuery', hospitalHashId, inspectionResultHashId, order], () =>
    getMakerInspectionResultFiles(hospitalHashId, inspectionResultHashId, order)
  );
};
