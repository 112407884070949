import {isNullish} from '@front-libs/helpers';
import {
  createInspectionResult,
  CreateInspectionResultParam,
  updateOfflineInspectionResult,
  UpsertInspectionResultParam,
} from '@modules/inspection_results/api';
import dayjs from 'dayjs';
import {getInspectionResultByIndex, updateInspectionResultByPrimaryKey} from '../inspectionResults/repository';
import {WhereIndexType} from '../types';
import {
  upsertSubmitInspectionResult,
  getSubmitInspectionResultByIndex,
} from '@indexedDB/SubmitInspectionResults/repository';
import {SubmitInspectionResultsIndex} from '@indexedDB/SubmitInspectionResults/types';
import {InspectionType} from '@modules/inspections/enum';

// サーバにオフライン環境で「新規作成」された点検結果を送信する
export const submitOfflineCreatedInspectionResultToServer = async (hospitalHashId: string) => {
  const submitOfflineInspectionResults = await getUnSubmitForNewInspectionResult(hospitalHashId);
  // 新規作成済みの未送信の点検がない場合、早期リターン
  if (isNullish(submitOfflineInspectionResults)) {
    return;
  }
  // オフラインで作成した新規点検の未送信データの送信(データ送信後、点検結果制御TBL、点検結果を更新する)
  for (const submitOfflineInspectionResult of submitOfflineInspectionResults) {
    await submitForNewInspectionResult(submitOfflineInspectionResult);
  }
};

// オフライン環境で新規点検作成した点検結果一覧を取得する。
// 点検結果制御送信TBLから未送信点検を検索し、UUIDをキーに点検結果一覧を取得する。
const getUnSubmitForNewInspectionResult = async (hospitalHashId: string) => {
  const whereIndex: WhereIndexType = {hospitalHashId: hospitalHashId};
  const filter = (item: SubmitInspectionResultsIndex) =>
    item.inspectionResultUuId !== null && item.isSubmitStartInspection === false;
  // 点検結果制御送信TBLの未送信点検結果一覧を取得する。
  const submitOfflineInspectionResults = await getSubmitInspectionResultByIndex(whereIndex, filter);
  if (isNullish(submitOfflineInspectionResults)) {
    return null;
  }
  return submitOfflineInspectionResults;
};

// オフラインで作成した新規点検の未送信データの送信
const submitForNewInspectionResult = async (submitOfflineInspectionResult: SubmitInspectionResultsIndex) => {
  const whereIndex: WhereIndexType = {hashId: submitOfflineInspectionResult.inspectionResultUuId};
  const inspectionResult = await getInspectionResultByIndex(whereIndex);

  const scheduledTime = dayjs(inspectionResult?.scheduledTime).format('YYYY-MM-DD');
  const payload: CreateInspectionResultParam = {
    hospitalProductHashId: inspectionResult?.hospitalProductHashId + '',
    category: 'in_hospital',
    scheduledTime: scheduledTime,
    inspectorHashId: inspectionResult?.inspectorHashId + '',
    status: 'uncompleted',
    items: {},
    isAdhoc: inspectionResult?.isAdhocDate,
  };

  // エラー発生時永続的にエラーを送信し続けないように、送信済みに更新する。
  try {
    const data = await createInspectionResult(
      inspectionResult?.hospitalHashId + '',
      inspectionResult?.inspectionHashId + '',
      payload
    );
    //未送信のデータ送信後、送信済みに更新する。
    submitOfflineInspectionResult.inspectionResultHashId = data.data.hashId;
    submitOfflineInspectionResult.isSubmitStartInspection = true;

    // 点検結果制御TBLする。
    await upsertSubmitInspectionResult(submitOfflineInspectionResult);
    // 点検結果を更新する。
    if (inspectionResult) await updateInspectionResultByPrimaryKey(inspectionResult, data.data.hashId);
  } catch (e) {
    console.error(e);
    submitOfflineInspectionResult.isSubmitStartInspection = true;
    await upsertSubmitInspectionResult(submitOfflineInspectionResult);
  }
};

// サーバにオフライン環境で更新した点検結果を送信する
export const submitOfflineUpdatedInspectionResultToServer = async (hospitalHashId: string) => {
  // オフラインで更新した点検の未送信データの一覧取得
  const submitOfflineInspectionResults = await getUnSubmitForUpdateInspectionResult(hospitalHashId);

  if (isNullish(submitOfflineInspectionResults)) {
    return;
  }

  // 更新処理を実施する。
  await submitForUpdateInspectionResult(submitOfflineInspectionResults);
};

// オフライン環境で更新した点検結果一覧を取得する。
const getUnSubmitForUpdateInspectionResult = async (hospitalHashId: string) => {
  const whereIndex: WhereIndexType = {hospitalHashId: hospitalHashId};
  const filter = (item: SubmitInspectionResultsIndex) => item.isSubmitExecInspection === false;
  // 点検結果制御送信TBLの未送信点検結果一覧を取得する。
  const submitOfflineInspectionResults = await getSubmitInspectionResultByIndex(whereIndex, filter);
  if (isNullish(submitOfflineInspectionResults)) {
    return null;
  }
  return submitOfflineInspectionResults;
};

// オフラインで更新した点検の未送信データの送信
const submitForUpdateInspectionResult = async (submitOfflineInspectionResults: SubmitInspectionResultsIndex[]) => {
  // オフラインで更新した未送信データの一覧を取得する。
  for (const submitOfflineInspectionResult of submitOfflineInspectionResults) {
    const whereIndex: WhereIndexType = {hashId: submitOfflineInspectionResult.inspectionResultHashId};
    const inspectionResult = await getInspectionResultByIndex(whereIndex);
    if (isNullish(inspectionResult)) {
      continue;
    }

    const payload: UpsertInspectionResultParam = {
      hospitalProductHashId: inspectionResult?.hospitalProductHashId,
      type: inspectionResult?.type as InspectionType,
      inspectionTableHashId: inspectionResult?.inspectionTableHashId,
      inspectorHashId: inspectionResult?.inspectorHashId + '',
      status: inspectionResult?.status,
      items: inspectionResult?.items,
      comment: inspectionResult?.comment,
      completedAt: inspectionResult?.createdAt,
    };

    // エラー発生時、永続的にエラーを送信し続けないように、送信済みに更新する。
    try {
      // サーバ送信
      await updateOfflineInspectionResult(
        inspectionResult.hospitalHashId,
        inspectionResult.inspectionHashId,
        inspectionResult.hashId,
        payload
      );
      submitOfflineInspectionResult.isSubmitExecInspection = true;
      // 送信完了後、送信済みフラグをtrueに更新する。
      await upsertSubmitInspectionResult(submitOfflineInspectionResult);
    } catch (e) {
      console.error(e);
      submitOfflineInspectionResult.isSubmitExecInspection = true;
      // 送信完了後、送信済みフラグをtrueに更新する。
      await upsertSubmitInspectionResult(submitOfflineInspectionResult);
    }
  }
  return submitOfflineInspectionResults;
};
