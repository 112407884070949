import React from 'react';
import {Grid, TextField, makeStyles} from '@material-ui/core';
import {TextConditionValue} from '../types';
import {useDebounceCallback} from '@front-libs/core';

type NewType = TextConditionValue;

type Props = {
  label: string;
  value: string;
  defaultValue?: TextConditionValue;
  optionType: 'text';
  onSubmit: (text: TextConditionValue) => void;
  onChange: (options: NewType) => void;
};

export const FilterDrawerTextField: React.VFC<Props> = (props) => {
  const {defaultValue = '', onChange} = props;
  const classes = useStyles();

  const handleChange = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    300,
    [onChange]
  );

  return (
    <Grid container className={classes.root}>
      <div style={{width: '100%'}}>
        <TextField
          variant="outlined"
          defaultValue={defaultValue}
          style={{width: '100%'}}
          placeholder={''}
          onChange={handleChange}
          onBlur={handleChange}
        />
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '32px',
  },
}));
