export const MODE_KEY = 'mode';
export const MODE_NEW_SIGNIN = 'new_signin';

export const ZendeskJwtConsts: {
  header: {
    alg: string;
    type: string;
    kid: string;
  };
  payload: {
    iss?: string;
    aud?: string;
    exp?: number;
    scope?: string;
    external_id?: string;
    email?: string;
    name?: string;
  };
  secret: string;
} = {
  header: {
    alg: 'HS256',
    type: 'JWT',
    kid: 'app_63ac17ab658a420105ad754c',
  },
  payload: {},
  secret: import.meta.env.VITE_ZENDESK_MESSAGING_SECRET_KEY,
};

export const HospitalUserPermission = {
  TrainingManagement: 'hospital:use:training_management',
  Inspection: 'hospital:use:inspection',
  ChannelManagement: 'hospital:use:channel_management',
  Repair: 'hospital:use:repair',
  Rental: 'hospital:use:rental',
  Dashboard: 'hospital:use:dashboard',
  GrantManagementDashboard: 'hospital:grant:management_dashboard',
  ReadManagementDashboard: 'hospital:read:management_dashboard',
  ReadCostManagementDashboard: 'hospital:read:cost_management_dashboard',
  ReadRentalRatioManagementDashboard: 'hospital:read:rental_ratio_management_dashboard',
  ReadRepairRatioManagementDashboard: 'hospital:read:repair_ratio_management_dashboard',
  GeneralFileList: 'hospital:use:general_file_list',
};
