import React from 'react';
import {ListItem, ListItemIcon, Checkbox, ListItemText, makeStyles} from '@material-ui/core';

type Props = {
  label: string;
  value: string;
  checked?: boolean;
  onChange: (value: string, checked: boolean) => void;
};

export const FilterPopperListItem: React.VFC<Props> = ({checked = false, label, value, onChange}) => {
  const classes = useStyles();
  const handleToggle = () => {
    onChange(value, !checked);
  };

  return (
    <ListItem button onClick={handleToggle} className={classes.root}>
      <ListItemIcon style={{marginRight: '9px', minWidth: 0}}>
        <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple />
      </ListItemIcon>
      <ListItemText primary={label} primaryTypographyProps={{style: {fontSize: 14}}} />
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 24px',
  },
}));
