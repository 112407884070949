import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography} from '@material-ui/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateHospitalUser, useFetchMyInfo} from '@modules/hospital_users/api';
import {Formik, useFormikContext} from 'formik';
import {DialogProps, FormikProps} from './types';
import {openSnackBar} from '@components/molecules/SnackBar';
import {InnerLoading} from '@components/molecules/Loading';
import {MuiDialogTitle} from './DialogTitle';
import Axios, {AxiosError} from 'axios';
import {Info} from '@material-ui/icons';
const getUpdatingUsernameMessageError = (error: unknown) => {
  if (Axios.isAxiosError(error)) {
    const apiError = error as AxiosError<{status: number; message: string}>;
    if (apiError.response?.status === 400) {
      return apiError.response.data.message;
    }
  }

  return 'ユーザーIDの更新に失敗しました';
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'start',
    padding: '16px 24px 24px',
  },
  textFieldTitle: {
    marginTop: 0,
    marginBottom: 8,
  },
  information: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: '8px',
  },
  placeText: {
    padding: '12px',
    color: theme.palette.secondary.dark,
    position: 'relative',
    bottom: '3px',
  },
  exclamation: {
    position: 'relative',
    top: '6px',
    paddingRight: '4px',
  },
}));

export const EditUsernameDialogInner: React.FC<DialogProps & {formikProps: FormikProps}> = (props) => {
  const {open, onClose, formikProps} = props;
  const classes = useStyles();
  const {submitForm} = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };
  const dialogPaperProps = useMemo(() => ({style: {maxWidth: 440} as const}), []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onClose}
      aria-labelledby="edit-username-dialog-title"
      open={open}
      PaperProps={dialogPaperProps}>
      <MuiDialogTitle id="edit-username-dialog-title" onClose={onClose}>
        ユーザーIDを編集
      </MuiDialogTitle>
      <DialogContent>
        <p className={classes.textFieldTitle}>ユーザーID</p>
        <TextField
          fullWidth
          id="username"
          name="username"
          type="text"
          variant="outlined"
          size={'small'}
          value={formikProps.values.username}
          onChange={formikProps.handleChange}
        />
        <Grid item xs={12} className={classes.information}>
          <Typography variant={'body2'} className={classes.placeText}>
            <Info className={classes.exclamation} />
            右上のユーザーIDは再ログイン後に更新されます。
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          保存
        </Button>
        <Button onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </Dialog>
  );
};

type handleSubmitProp = {username: string};

export const EditUsernameDialog: React.FC<DialogProps> = (props) => {
  const {open, onClose} = props;
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchMyInfo(myInfo.hashId);

  if (!data) {
    return null;
  }

  const initialValues = {username: data.username};

  const handleSubmit = async (value: handleSubmitProp) => {
    try {
      await updateHospitalUser(data.hospitalHashId, data.hashId, {username: value.username});
      onClose();
      refetch();
      openSnackBar('ユーザーIDを更新しました');
    } catch (error) {
      const errorMessage = getUpdatingUsernameMessageError(error);
      openSnackBar(errorMessage, 'left', 'bottom', 'error');

      throw error;
    }
  };

  if (isLoading) {
    return <InnerLoading />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik: FormikProps) => (
        <form onSubmit={formik.handleSubmit}>
          <EditUsernameDialogInner open={open} onClose={onClose} formikProps={formik} />
        </form>
      )}
    </Formik>
  );
};
