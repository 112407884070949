import React, {useCallback} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {styled} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';

const StyledBox = styled(Box)({
  width: '100%',
  padding: '16px',
  '& .MuiListItemIcon-root': {
    minWidth: '36px',
  },
  '& .MuiTypography-root': {
    fontSize: '16px',
  },
});
const StyledTitle = styled(Typography)({
  textAlign: 'center',
  fontWeight: 'bold',
});

/**
 * ファイルメニューリストのための型定義。
 *
 * @typeparam T ボタンのタイプを表す型。
 * @param type ボタンのタイプ。
 * @param primary ボタンのラベルテキスト。
 * @param icon ボタンに表示されるアイコン。
 */
export type ListItemType<T> = {
  type: T;
  primary: string;
  icon: JSX.Element;
};

/**
 * ファイルメニューリストコンポーネントのプロパティ。
 *
 * @typeparam T ボタンのタイプを表す型。
 * @param fileName ファイル名。
 * @param buttonListItem ボタンリストのアイテム配列。
 * @param onClick ボタンクリック時のコールバック関数。
 */
export type FileMenuListProps<T> = {
  fileName: string;
  ariaLabel: string;
  buttonListItem: ListItemType<T>[];
  onClick: (buttonType: T) => void;
};

/**
 * MobileUnderBox用メニューリストコンポーネント。
 * ファイルに関連する操作（開く、名前の変更、削除など）のリストを表示します。
 *
 * @typeparam T ボタンのタイプを表す型。
 * @param props コンポーネントに渡されるプロパティ。
 * @param props.onClick ボタンクリック時のコールバック関数。
 * @param props.buttonListItem ボタンリストのアイテム配列。
 * @param props.fileName ファイル名。
 * @returns レンダリングされたファイルメニューリストコンポーネント。
 */
export const MobileMenuList = <T,>({onClick, buttonListItem, fileName, ariaLabel}: FileMenuListProps<T>) => {
  const onClickButton = useCallback(
    (buttonType: T) => {
      onClick(buttonType);
    },
    [onClick]
  );

  const CreateListButton = useCallback(() => {
    return buttonListItem.map((item, index) => {
      return (
        <ListItem button onClick={() => onClickButton(item.type)} key={`listItem_${index}`}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.primary} />
        </ListItem>
      );
    });
  }, [buttonListItem, onClickButton]);

  return (
    <StyledBox>
      <StyledTitle variant="h6">{fileName}</StyledTitle>
      <List component="nav" aria-label={ariaLabel}>
        {CreateListButton().map((v) => v)}
      </List>
    </StyledBox>
  );
};
