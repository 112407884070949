import {useAtom, useAtomValue} from 'jotai';
import React, {useMemo, useCallback} from 'react';
import {useMutation, useQuery} from 'react-query';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {getTrainingPlans} from '@modules/training_plans/api';
import {searchVariablesAtom} from './state';
import {getTrainingSchedules} from '@modules/training_schedules/api';
import {
  createTrainingReport,
  CreateTrainingReportParam,
  deleteTrainingReport,
  getTrainingReports,
  updateTrainingReport,
  UpdateTrainingReportParam,
} from '@modules/training_reports/api';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {ReportTableData} from './ReportList';
import {Column} from '@molecules/Table/props';
import {convertDateToSimpleDateTime, convertDisplaySimpleDate, isNullish, StrUtil} from '@front-libs/helpers';
import {trainingTypeOptions} from '@modules/training_plans/constants';
import {Link} from 'react-router-dom';
import {styled} from '@material-ui/core';

const SortingDisableMap: Record<string, boolean> = {
  trainingTypes: true,
};

export const useSearchVariables = () => {
  const {data: trainingPlans} = useTrainingPlansQuery();
  const {data: trainingSchedules} = useTrainingSchedulesQuery();
  const [variables, setVariables] = useAtom(searchVariablesAtom);

  const trainingPlanOptions = useMemo(
    () => (trainingPlans?.data ?? []).map((item) => ({value: item.hashId, label: item.name})),
    [trainingPlans?.data]
  );

  const trainingScheduleOptions = useMemo(
    () =>
      (trainingSchedules?.data ?? []).map((item) => ({
        value: item.hashId,
        label: `${item.trainingPlan.name} - ${item.currentTimes}回目`,
      })),
    [trainingSchedules?.data]
  );

  const setName = useCallback(
    (name: string) => {
      setVariables({...variables, name: name === '' ? null : name});
    },
    [setVariables, variables]
  );

  const setTrainingPlanHashIds = useCallback(
    (hashIds: string[]) => {
      setVariables({...variables, trainingPlanHashIds: hashIds.length === 0 ? null : hashIds});
    },
    [setVariables, variables]
  );

  const setTrainingScheduleHashIds = useCallback(
    (hashIds: string[]) => {
      setVariables({...variables, trainingScheduleHashIds: hashIds.length === 0 ? null : hashIds});
    },
    [setVariables, variables]
  );

  const setPage = useCallback(
    (newPage: number) => {
      setVariables({...variables, page: newPage});
    },
    [setVariables, variables]
  );
  const setOrder = useCallback(
    (field: string, orderDirection: 'asc' | 'desc') => {
      setVariables({...variables, order: `${orderDirection === 'desc' ? '-' : ''}${field}`});
    },
    [setVariables, variables]
  );

  return {
    variables,
    trainingPlanOptions,
    trainingScheduleOptions,
    setName,
    setTrainingPlanHashIds,
    setTrainingScheduleHashIds,
    setPage,
    setOrder,
  };
};

const useTrainingPlansQuery = () => {
  const {myInfo} = useMyInfo();
  const query = useQuery(['useTrainingPlansQuery', myInfo], () =>
    getTrainingPlans(myInfo.hospitalHashId, {
      perPage: 100,
    })
  );
  return {...query, data: query.data};
};

export const useTrainingSchedulesQuery = () => {
  const {myInfo} = useMyInfo();

  const query = useQuery(['useTrainingSchedulesQuery', myInfo], () =>
    getTrainingSchedules(myInfo.hospitalHashId, {
      perPage: 100,
    })
  );
  return {...query, data: query.data};
};

export const useTrainingReportsQuery = () => {
  const {myInfo} = useMyInfo();
  const searchVariables = useAtomValue(searchVariablesAtom);

  const query = useQuery(['useTrainingReportsQuery', myInfo, searchVariables], () =>
    getTrainingReports(myInfo.hospitalHashId, {
      name: searchVariables.name ?? undefined,
      trainingPlanHashIds: searchVariables.trainingPlanHashIds ?? undefined,
      trainingScheduleHashIds: searchVariables.trainingScheduleHashIds ?? undefined,
      page: searchVariables.page,
      perPage: searchVariables.perPage,
      order: searchVariables.order ?? undefined,
    })
  );
  return {...query, data: query.data};
};

export const useTableData = () => {
  const {data, isLoading} = useTrainingReportsQuery();
  const [tableLayout] = useTableLayout('trainingReportList');

  const tableColumns = useMemo(() => {
    return (tableLayout?.currentLayout ?? []).map<Column<ReportTableData>>((item) => {
      const column: Column<ReportTableData> = {
        title: item.title,
        field: item.field,
        noBodyWrap: true,
        sorting: SortingDisableMap[item.field] === true ? false : true,
      };
      switch (item.field) {
        case 'scheduleName':
          column.render = ({scheduleName, scheduleHashId}: ReportTableData) => (
            <NavLink to={`/training_managements/schedules/${scheduleHashId}`}>{scheduleName}</NavLink>
          );
          break;

        case 'trainingTypes':
          column.render = ({trainingTypes}: ReportTableData) =>
            StrUtil.nl2br(
              trainingTypes
                .map((category) => {
                  const displayCategory = trainingTypeOptions.find((typeOption) => typeOption.value === category);
                  if (isNullish(displayCategory)) {
                    return '';
                  } else {
                    return `■ ${displayCategory.label}`;
                  }
                })
                .join('\n')
            );
          break;
        case 'trainingDateRange':
          // FIXME: もしかしたらフォーマットの修正いるかも
          column.render = ({trainingDateRange}: ReportTableData) => {
            const finishAtFormat =
              convertDisplaySimpleDate(trainingDateRange[0]) === convertDisplaySimpleDate(trainingDateRange[1])
                ? 'HH:mm'
                : 'YYYY/MM/DD HH:mm';

            const startAt = convertDateToSimpleDateTime(trainingDateRange[0]);
            const finishAt = convertDisplaySimpleDate(trainingDateRange[1], finishAtFormat);
            return `${startAt} 〜 ${finishAt}`;
          };
          break;
        default:
          break;
      }
      return column;
    });
  }, [tableLayout?.currentLayout]);

  const reportData = useMemo<ReportTableData[]>(() => {
    return (
      data?.data.map((item) => ({
        hashId: item.hashId,
        scheduleHashId: item.trainingSchedule.hashId,
        reportedUserHashId: item.reportedHospitalUser.hashId,
        scheduleName: `${item.trainingSchedule.trainingPlan.name} - ${item.trainingSchedule.currentTimes}回目`,
        name: item.trainingSchedule.trainingPlan.name,
        trainingTypes: item.trainingSchedule.trainingPlan.trainingTypes,
        trainingDateRange: [item.startedAt, item.finishedAt],
        place: item.trainingSchedule.place ?? '',
        numberOfAttendants: item.numberOfAttendants,
        description: item.description,
      })) ?? []
    );
  }, [data?.data]);

  return {tableColumns, reportData, isLoading};
};

export const useCreateTrainingReportMutation = () => {
  const {refetch} = useTrainingReportsQuery();
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, CreateTrainingReportParam>(
    (variables) => createTrainingReport(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => refetch(),
    }
  );

  return mutation;
};

export const useUpdateTrainingReportMutation = () => {
  const {refetch} = useTrainingReportsQuery();
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, UpdateTrainingReportParam>(
    (variables) => updateTrainingReport(myInfo.hospitalHashId, variables),
    {
      onSuccess: () => refetch(),
    }
  );

  return mutation;
};

export const useDeleteTrainingReportMutation = () => {
  const {refetch} = useTrainingReportsQuery();
  const {myInfo} = useMyInfo();
  const mutation = useMutation<unknown, unknown, string>(
    (hashId) => deleteTrainingReport(myInfo.hospitalHashId, hashId),
    {
      onSuccess: () => refetch(),
    }
  );

  return mutation;
};

const NavLink = styled(Link)(({theme}) => ({
  color: theme.palette.secondary.dark,
  textDecoration: 'none',
  fontWeight: 'bold',
}));
