import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalQuery} from './api';
import dayjs from 'dayjs';

export const useHospitalFiscalYear = () => {
  const {myInfo} = useMyInfo();
  const {data: hospital} = useFetchHospitalQuery(myInfo.hospitalHashId);
  const today = dayjs();

  const currentYear = today.year();
  // 今月を1-12で取得する
  const currentMonth = today.month() + 1;
  const termStartMonth = hospital?.termStartMonth ?? 1;
  const fiscalYear = currentMonth >= termStartMonth ? currentYear : currentYear - 1;
  return {fiscalYear, termStartMonth};
};
