import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles, createStyles, Theme, Grid, Typography, styled} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {Table} from '@components/molecules/Table';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Column} from '@molecules/Table/props';
import {CategoryFormatter} from '@modules/categories/helpers';
import {InspectionIndex} from '@modules/inspections/types';
import {WholeProductsInspectionsPeriodProducts} from '@modules/inspection_setting/types';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {
  EditInspectionDialog,
  EditInspectionDialogProps,
  EditInspectionDialogResult,
} from './Dialogs/EditInspectionDialog';
import {openSnackBar} from '@components/molecules/SnackBar';
import {updateInspectionPeriod, updateInspectionPeriods} from '@modules/inspection_setting/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InspectionPeriodicColumn} from './InspectionPeriodColumn';
import {InspectionType} from '@modules/inspections/enum';
import {useAtom, useAtomValue} from 'jotai';
import {inspectionProductsVariables, inspectionTypeAtom, orderKeyAtom, pageAtom, pageSizeAtom} from './jotai';
import {isNullish} from '@front-libs/helpers';
import {
  EditInspectionPeriodsDialog,
  EditInspectionPeriodsDialogProps,
  EditInspectionPeriodsResult,
} from './Dialogs/EditInspectionPeriodsDialog/EditInspectionPeriodsDialog';
import {
  InspectionStartDateDialogProps,
  InspectionStartDateDialog,
} from './Dialogs/InspectionStartDateDialog/InspectionStartDateDialog';
import {useNavigate} from 'react-router-dom';
import {searchNameAtom} from '../InspectionStartDate/jotai';
import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {DisplayNumberSelect} from '@components/molecules/DisplayNumberSelect';

const FontBoldP = styled('p')({
  fontWeight: 700,
});
const PageDescriptionGrid = styled(Grid)({
  margin: 'auto 0px',
  flex: 1,
});
const PaginationContainerGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
});
const EmptyGrid = styled(Grid)({
  flex: 1,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  })
);

export type ProductElement = {
  hashId: string;
  rootCategory: string;
  narrowCategory: string;
  makerName: string;
  name: string;
  displayName: string;
  table: string | undefined;
  periodicInspectionPeriod: number | undefined;
  inspectionProduct?: InspectionIndex;
};

type Props = {
  isLoading: boolean;
  hospitalProducts: WholeProductsInspectionsPeriodProducts[];
  page: number;
  totalPage: number;
  totalCount: number;
  startDisplayPosition: number;
  endDisplayPosition: number;
  onChangeOrder: (order: string) => void;
  onChangePage: (page: number) => void;
  onChangePeriod: (wholeProductID: string, inspectionType: InspectionType, periodicInspectionPeriod: number) => void;
  refetch: () => void;
};

export const InspectionProductSettingList: React.VFC<Props> = (props) => {
  const {
    hospitalProducts,
    isLoading,
    onChangePage,
    page,
    totalPage,
    startDisplayPosition,
    endDisplayPosition,
    totalCount,
    onChangePeriod,
    refetch,
  } = props;
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [tableLayout] = useTableLayout('inspectionProductSettingList');
  const variables = useAtomValue(inspectionProductsVariables);
  const type = useAtomValue(inspectionTypeAtom);
  const [, setOrder] = useAtom(orderKeyAtom);
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);
  const [, setPage] = useAtom(pageAtom);
  const [newInspection, setNewInspection] = useState<InspectionIndex>();
  const [dialogUpdate, setDialogUpdate] = useState(false);
  const navigate = useNavigate();
  const [, setSearchName] = useAtom(searchNameAtom);

  useEffect(() => {
    // 点検表が更新されていなければダイアログを開く処理を実行
    if (dialogUpdate) {
      return;
    }

    listData.forEach((rowData) => {
      if (rowData.inspectionProduct) {
        const inspection = rowData.inspectionProduct;
        if (inspection && inspection.hashId === newInspection?.hashId) {
          handleClickEditInspection(rowData.hashId, rowData, inspection);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalProducts]);

  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, p: number) => {
      onChangePage(p);
    },
    [onChangePage]
  );

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      if (columnIndex === -1) {
        setOrder(null);
      } else {
        setOrder({
          fieldId: String(tableLayout?.currentLayout[columnIndex].field),
          direction: orderDirection,
        });
      }
    },
    [setOrder, tableLayout?.currentLayout]
  );

  const noDataComponent = useMemo(() => {
    if (variables.name) {
      return (
        <div>
          <FontBoldP>現在の検索条件に一致する機器はありません。</FontBoldP>
          <p>検索条件を変えて、再度検索してみてください。</p>
        </div>
      );
    }
  }, [variables.name]);

  const listData = hospitalProducts?.map((data) => {
    return {
      ...data,
      hashId: data.hashId,
      rootCategory: CategoryFormatter.getRootCategory(data.categories)?.name || '',
      narrowCategory: CategoryFormatter.getNarrowCategory(data.categories)?.name || '',
      makerName: data.maker.name,
      table: data.inspectionProduct?.inspection?.name || undefined,
      periodicInspectionPeriod: data.inspectionPeriod ? data.inspectionPeriod.periodicInspectionPeriod : undefined,
      inspectionProduct: data.inspectionProduct ? data.inspectionProduct.inspection : undefined,
    };
  });

  // 点検開始日ダイアログ
  const handleClickInspectionStartDate = useCallback(
    async (isPeriodsSet: boolean, name: string) => {
      try {
        await dialogHandler.open<InspectionStartDateDialogProps>(InspectionStartDateDialog, {
          isPeriodsSet: isPeriodsSet,
        });
        setSearchName(name);
        navigate(`/inspection/setting/start_date`);
      } catch (error) {
        return null;
      }
    },
    [navigate, setSearchName]
  );

  // 点検間隔ダイアログ
  const handleClickInspectionPeriods = useCallback(
    async (wholeProductHashId: string, name: string, period?: number) => {
      try {
        const {periods} = await dialogHandler.open<EditInspectionPeriodsDialogProps, EditInspectionPeriodsResult>(
          EditInspectionPeriodsDialog,
          {
            period: period,
          }
        );

        await updateInspectionPeriod(myInfo.hospitalHashId, {
          wholeProductHashId: wholeProductHashId,
          periodicInspectionPeriod: periods,
          periodicInspectionPeriodUnit: 'month',
        });
        handleClickInspectionStartDate(true, name);
        refetch();
      } catch (error) {
        handleClickInspectionStartDate(false, name);
        return null;
      }
    },
    [handleClickInspectionStartDate, myInfo.hospitalHashId, refetch]
  );

  const handleClickEditInspection = useCallback(
    async (wholeProductHashId: string, product: ProductElement, inspection?: InspectionIndex) => {
      let updatedInspection = inspection;
      if (inspection !== undefined) {
        updatedInspection = inspection;
      }
      setNewInspection(updatedInspection);

      try {
        const {newInspectionHashId} = await dialogHandler.open<EditInspectionDialogProps, EditInspectionDialogResult>(
          EditInspectionDialog,
          {
            inspectionType: type === 'daily' ? 'pre_use,in_use,post_use' : type,
            product: product,
            currentInspection: updatedInspection,
          }
        );
        await updateInspectionPeriods(myInfo.hospitalHashId, {
          inspectionHashId: newInspectionHashId,
          addWholeProductHashIds: [wholeProductHashId],
          deleteWholeProductHashIds: [],
        });

        // 定期点検の場合点検間隔設定ダイアログを開く
        if (type === 'periodic') {
          handleClickInspectionPeriods(wholeProductHashId, product.displayName, product.periodicInspectionPeriod);
        }

        if (inspection?.hashId !== newInspectionHashId) {
          openSnackBar('対象機種を更新しました。', 'center', 'top', 'success');
          setDialogUpdate(true);
        }
        refetch();
      } catch (error) {
        if (error === undefined) {
          return null;
        }
        console.error(error);
        openSnackBar('対象機種の更新に失敗しました', 'center', 'top', 'error');
      }
    },
    [handleClickInspectionPeriods, myInfo.hospitalHashId, refetch, type]
  );

  const serializedTableColumn = useMemo(() => {
    let tableColumn = Object.assign<Column<ProductElement>[], TableLayout[]>([], tableLayout?.currentLayout ?? []);

    // 定期点検の場合のみ、点検間隔を表示する
    if (type !== 'periodic') {
      tableColumn = tableColumn.filter((column) => column.field !== 'periodicInspectionPeriod');
    }
    return tableColumn.map<Column<ProductElement>>((item) => {
      switch (item.field) {
        case 'rootCategory':
          item.render = (rowData) => {
            return rowData.rootCategory ?? '';
          };
          break;
        case 'narrowCategory':
          item.render = (rowData) => {
            return rowData.narrowCategory ?? '';
          };
          break;
        case 'makerName':
          item.render = (rowData) => {
            return rowData.makerName ?? '';
          };
          break;
        case 'name':
          item.render = (rowData) => {
            return rowData.name ?? '';
          };
          break;
        case 'displayName':
          item.render = (rowData) => {
            return rowData.displayName ?? '';
          };
          break;
        case 'tableName':
          item.render = (rowData) => {
            return (
              <Grid>
                <Typography
                  className={classes.cell}
                  onClick={() => handleClickEditInspection(rowData.hashId, rowData, rowData.inspectionProduct)}>
                  {rowData.table ?? '未設定'}
                </Typography>
              </Grid>
            );
          };
          break;
        case 'periodicInspectionPeriod':
          item.render = (rowData) => {
            if (isNullish(rowData.inspectionProduct)) {
              return 'ー';
            }
            return (
              <InspectionPeriodicColumn
                period={rowData.periodicInspectionPeriod}
                periodUnit={'month'}
                wholeProductID={rowData.hashId}
                onSave={onChangePeriod}
              />
            );
          };
          break;
        default:
          break;
      }
      return item;
    });
  }, [classes.cell, handleClickEditInspection, onChangePeriod, tableLayout?.currentLayout, type]);

  return (
    <>
      <TableViewLayout.Body>
        <Table<ProductElement>
          stickyHeader={true}
          showSelection={false}
          isLoading={isLoading}
          columns={serializedTableColumn}
          data={listData}
          noDataComponent={noDataComponent}
          onOrderChange={handleOrderChange}
          tableSize="small"
        />
      </TableViewLayout.Body>
      <TableViewLayout.Footer container justifyContent="space-between">
        <PageDescriptionGrid item>
          {totalCount}件のうち{startDisplayPosition}件目-{endDisplayPosition}件目までを表示しています
        </PageDescriptionGrid>
        <PaginationContainerGrid item>
          <Pagination page={page} count={totalPage} shape="rounded" onChange={handleChangePage} />
          <DisplayNumberSelect
            pageSize={pageSize}
            update={(selectNum) => {
              setPageSize(selectNum);
              setPage(1);
            }}
          />
        </PaginationContainerGrid>
        <EmptyGrid />
      </TableViewLayout.Footer>
    </>
  );
};
