import React from 'react';
import {CardContent, makeStyles, Theme} from '@material-ui/core';
import {Card, RadioProps, CardActionArea} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: '45px',
    boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  },
  content: {
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '36px',
    fontWeight: 'bold',
    color: theme.palette.rent.main,
    [theme.breakpoints.up('laptop')]: {
      height: '160px',
    },
  },
}));

type Props = {
  label: string;
  onChange: (value: string) => void;
} & Omit<RadioProps, 'onChange'>;

export const ReturnButton: React.VFC<Props> = ({label, ...props}) => {
  const classes = useStyles();
  const handleClick = () => {
    props.onChange(props.value as string);
  };
  return (
    <Card className={classes.card}>
      <CardActionArea onClick={handleClick} data-testid={'return-button'}>
        <CardContent className={classes.content}>{label}</CardContent>
      </CardActionArea>
    </Card>
  );
};
