import React, {useMemo} from 'react';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {Grid, Link, Typography} from '@material-ui/core';
import {Column} from '@molecules/Table/props';
import {Sidebar} from '@components/organisms/Sidebar';
import {useSettingsContentTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {Table} from '@molecules/Table';

const rows = [
  {name: 'HITOTSU Hospital サービス利用規約', url: 'https://hitotsu.co.jp/tos/hospital'},
  {name: 'HITOTSU Asset サービス利用規約', url: 'https://hitotsu.co.jp/tos/hospital/asset'},
  {name: 'プライバシーポリシー', url: 'https://hitotsu.co.jp/privacy-policy'},
];
export const SettingsHospitalDocument: React.VFC = () => {
  const templateClasses = useSettingsContentTemplate();
  const [tableLayout] = useTableLayout('documentList');

  const serializedTableColumn = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableColumn = Object.assign<Column<any>[], TableLayout[]>([], tableLayout?.currentLayout);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return tableColumn.map<Column<any>>((item) => {
      if (item.field === 'name') {
        item.render = (row) => {
          return (
            <Link href={row.url} className={templateClasses.link} target="_blank" rel="noopener">
              {row.name}
            </Link>
          );
        };
      }
      return item;
    });
  }, [tableLayout?.currentLayout, templateClasses.link]);

  return (
    <Grid container className={templateClasses.grid} style={{backgroundColor: '#ffffff'}}>
      <Grid item className={templateClasses.sideBar}>
        <Sidebar />
      </Grid>
      <Grid item className={templateClasses.content}>
        <Grid container style={{marginBottom: '32px'}}>
          <Grid item>
            <Typography variant={'h5'} className={templateClasses.pageTitle}>
              各種規約
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: '24px'}}>
          <Table columns={serializedTableColumn} data={rows} showSelection={false} />
        </Grid>
      </Grid>
    </Grid>
  );
};
