import React, {useMemo} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {Dialog} from '@atoms/Dialog/Dialog';
import {DialogContent} from '@atoms/Dialog/DialogContent';
import {StrUtil} from '@front-libs/helpers';
import {DialogButton} from './DialogButton';
import {UnacceptedStatus} from './RentDevices/types';

type Props = {
  open: boolean;
  status: UnacceptedStatus;
  type: 'rent' | 'return';
  buttonLabel?: string;
  onClickButton: () => void;
};

export const RentalErrorDialog: React.VFC<Props> = ({
  open,
  status,
  type,
  buttonLabel = '前の画面に戻る',
  onClickButton,
}) => {
  const classes = useStyles();
  const message = useMemo(() => {
    switch (status) {
      case 'working':
        return 'この医療機器は貸出中のため貸出できません。';
      case 'uninspected':
        return 'この医療機器は点検が未完了のため貸出できません。';
      case 'repairing':
        return 'この医療機器は修理中のため貸出できません。';
      case 'disabled':
        return 'この医療機器は廃棄済みのため貸出できません。';
      case 'overdue_inspection':
        return 'この医療機器は点検予定日が近いので貸出できません。';
      default:
        return `この医療機器は${type === 'rent' ? '貸出' : '返却'}できません。`;
    }
  }, [status, type]);
  return (
    <Dialog open={open} maxWidth="xl" data-testid={'rental-error-dialog'}>
      <DialogContent className={classes.content}>
        <Grid container direction="column" style={{height: '100%'}} alignContent="center">
          <Grid
            item
            style={{height: '60%', display: 'flex', alignItems: 'center'}}
            data-testid={'rental-error-dialog-content'}>
            {StrUtil.nl2br(`${message}\n別の医療機器を読み取り直してください。`)}
          </Grid>
          <Grid item style={{height: '40%', display: 'flex', alignItems: 'center'}}>
            <DialogButton onClick={onClickButton}>{buttonLabel}</DialogButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90%',
    maxHeight: '90%',
  },
  content: {
    width: '60vw',
    height: '70vh',
    fontWeight: 700,
    fontSize: 32,
    textAlign: 'center',
  },
}));
