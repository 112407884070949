import React from 'react';
import './loading.css';
import clsx from 'clsx';

type Props = {
  fullScreen?: boolean;
};

const classes = {
  root: 'loader-root',
  fullScreen: 'loader-full-screen',
  notFullScreen: 'loader-not-full-screen',
  loader: 'loader-ball-scale-multiple',
};

export const Loading: React.VFC<Props> = ({fullScreen = true}) => {
  return (
    <div
      className={clsx(classes.root, {
        [classes.fullScreen]: fullScreen,
        [classes.notFullScreen]: !fullScreen,
      })}
    >
      <div className="loader">loading...</div>
    </div>
  );
};
