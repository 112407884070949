import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {useSetAtom} from 'jotai';
import {useNavigate} from 'react-router-dom';
import {Grid, makeStyles} from '@material-ui/core';
import {defaultHeightWidth, setOuterHeight, setOuterWidth, useTemplate} from '@templates/RentalTemplate';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  defaultBarcodeScannerTypeAtom,
  lenderNameAtom,
  rentalSettingAtom,
  rentOrReturnAtom,
  rentStepperAtom,
  rentWardAtom,
  returnLenderNameAtom,
} from '../states';
import {BarcodeContent} from './BarcodeContent';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {useAtomValue} from 'jotai';
import {useFetchHospitalWards} from '@modules/hospital_wards/api';
import {DirectInputContent} from './DirectInputContent';
import {isNullish} from '@front-libs/helpers';

export const LenderSelection: React.FC = () => {
  const templateClasses = useTemplate();
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const {data: wardsData} = useFetchHospitalWards(myInfo.hospitalHashId);
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);
  const rentOrReturn = useAtomValue(rentOrReturnAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);

  const setLenderNameAtom = useSetAtom(lenderNameAtom);
  const setReturnLenderNameAtom = useSetAtom(returnLenderNameAtom);

  const setRentalStepper = useSetAtom(rentStepperAtom);
  const setRentalWard = useSetAtom(rentWardAtom);
  const [keyboardShowing, setKeyboardShowing] = useState(false);

  const navigate = useNavigate();

  const handleInputLenderName = useCallback(
    (lenderName: string) => {
      setRentalStepper(1);

      if (rentOrReturn === 'rent') {
        setLenderNameAtom(lenderName);
        if (wardsData.length > 1) {
          navigate('/shared/rental/wardSelection');
        } else {
          setRentalWard(wardsData[0]);
          navigate('/shared/rental/roomSelection');
        }
      } else {
        setReturnLenderNameAtom(lenderName);
        defaultBarcodeScannerType === 'barcode_reader'
          ? navigate('/shared/return/product/barcode_reader')
          : navigate('/shared/return/product/camera');
      }
    },
    [
      defaultBarcodeScannerType,
      navigate,
      rentOrReturn,
      setLenderNameAtom,
      setRentalStepper,
      setRentalWard,
      setReturnLenderNameAtom,
      wardsData,
    ]
  );

  const hideFooter = useCallback(() => {
    // ヴァーチャルkeyboardが出ているかどうか検知する方法がないため、500px以下になったらfooter消すようにする
    if (window.innerHeight < 480) {
      setKeyboardShowing(true);
    } else {
      setKeyboardShowing(false);
    }
  }, []);

  // 設定がフェッチできていなかったり、not_needになっている場合（リロード時とか）はトップまで戻す
  useEffect(() => {
    if (isNullish(hospitalRentalSetting) || hospitalRentalSetting === 'not_need') navigate('/shared');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setOuterHeight();
      setOuterWidth();
      hideFooter();
    });
    setOuterHeight();
    setOuterWidth();
    hideFooter();
    return () => {
      window.removeEventListener('resize', () => {
        setOuterHeight();
        setOuterWidth();
        hideFooter();
      });
    };
  }, [hideFooter]);

  useEffect(() => {
    setRentalStepper(0);
  }, [setRentalStepper]);

  return (
    <>
      <Header title={rentOrReturn === 'rent' ? '貸出' : '返却'} />
      <Grid
        item
        id={'mainContent'}
        className={clsx(templateClasses.content, classes.contentContainer)}
        style={defaultHeightWidth}>
        {hospitalRentalSetting === 'by_barcode' ? (
          <BarcodeContent onSubmitInput={handleInputLenderName} />
        ) : (
          <DirectInputContent onSubmitInput={handleInputLenderName} />
        )}
      </Grid>
      {!keyboardShowing && (
        <Footer
          text={
            hospitalRentalSetting === 'by_barcode'
              ? '名札のバーコードを読み取って下さい'
              : 'あなたの名前を入力してください'
          }
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  textField: {
    width: '70%',
    paddingTop: '40px',
    margin: 8,
  },
}));
