import React from 'react';
import {Button, createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {FileIndex} from '@modules/files/types';

type ToolbarProp = {
  files: FileIndex[];
  searchName: string;
  isReadOnly: boolean;
  onChangeSearchName: React.ChangeEventHandler<HTMLInputElement>;
  onUploadFile: () => void;
};

export const Toolbar: React.VFC<ToolbarProp> = (props) => {
  const {searchName, isReadOnly, onChangeSearchName, onUploadFile} = props;
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item sm={4} md={3} className={classes.searchNameContainer}>
        <TextField
          className={classes.searchName}
          label={'ファイル名で検索'}
          variant={'outlined'}
          fullWidth
          size={'small'}
          InputProps={{
            endAdornment: <Search />,
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          defaultValue={searchName}
          onChange={onChangeSearchName}
        />
      </Grid>

      {/* TODO: ファイルタイプのフィルタが作れたらここを有効にする  */}
      {/* <Grid item>
        <PopperSelectBoxButton
          buttonLabel="ファイルタイプ"
          options={[]}
          isMulti={true}
          onChange={(selectValues) =>
            onSelectFileCategories(selectValues?.map((item) => item.value as FileCategory) ?? [])
          }
          searchable={true}
        />
      </Grid> */}

      <div className={classes.flex} />
      {!isReadOnly && (
        <Button variant="contained" color="primary" onClick={onUploadFile}>
          ファイルをアップロード
        </Button>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 24,
    },
    flex: {
      flexGrow: 1,
    },
    searchNameContainer: {
      minWidth: '270px',
    },
    searchName: {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
      // [theme.breakpoints.up('tabletH')]: {},
    },
  })
);
