import React, {useEffect, Suspense, useState, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import {use2ColumnsLayoutTemplate} from '@templates/ContentLayout/InnerSidebarContentLayout';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Form, Formik, useFormikContext} from 'formik';
import {InnerLoading} from '@molecules/Loading';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {openSnackBar} from '@molecules/SnackBar';
import {SideNav} from './SideNav';
import {ProductDetailInfo} from './Content';
import {useFetchFaultRepairQuery, useFetchFaultRepairStatuses} from '@modules/repairs/hooks';
import {RepairIndex} from '@modules/repairs/types';
import {updateFaultRepair} from '@modules/repairs/api';
import {getNextStatus} from './helper';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type Props = {
  hashId: string;
};

const ProductDetailForm: React.FC<Props> = ({hashId}) => {
  const templateClasses = use2ColumnsLayoutTemplate();
  const context = useFormikContext<RepairIndex>();
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const {refetch} = useFetchFaultRepairQuery(myInfo.hospitalHashId, hashId);

  return (
    <Form style={{width: '100%'}}>
      <Grid container className={templateClasses.root}>
        <Grid item className={templateClasses.sideNav}>
          <SideNav faultRepair={context.initialValues} onUpdateComplete={refetch} />
        </Grid>
        <Grid item className={templateClasses.content}>
          <ProductDetailInfo faultRepair={context.initialValues} />
        </Grid>
      </Grid>
      {!isReadOnly && <FormikFormSubmitDrawer />}
    </Form>
  );
};

const ProductDetailContainer: React.FC<Props> = ({hashId, children}) => {
  const {myInfo} = useMyInfo();
  const {data, isLoading, refetch} = useFetchFaultRepairQuery(myInfo.hospitalHashId, hashId);
  const {data: repairStatuses} = useFetchFaultRepairStatuses(myInfo.hospitalHashId);

  const [initialValues, setInitialValues] = useState(data);

  const handleSubmit = useCallback(
    async (values: RepairIndex) => {
      values.dateOfDisposal = values.faultHospitalProduct.dateOfDisposal;
      values.reasonOfDisposal = values.faultHospitalProduct.reasonOfDisposal;
      const updateValues = {
        ...values,
        dateOfDisposal: values.faultHospitalProduct.dateOfDisposal,
        reasonOfDisposal: values.faultHospitalProduct.reasonOfDisposal,
        // symptomCategory が internal_failure 以外の場合は symptomDetailCategory を undefined にする
        symptomDetailCategory: values.symptomCategory === 'internal_failure' ? values.symptomDetailCategory : undefined,
      };

      const {willCompleted} = getNextStatus(initialValues, values);
      if (willCompleted) {
        const completeStatus = repairStatuses.find((status) => status.statusType === 'complete');
        try {
          await dialogHandler.open(SimpleDialog, {
            title: 'ステータスを変更しますか？',
            content: '修理完了に関する情報が入力されました。\n修理のステータスを「完了」に変更しますか？',
            positiveButtonLabel: '変更する',
            negativeButtonLabel: '変更せずに更新',
          });
          updateValues.statusHashId = completeStatus?.hashId ?? '';
        } catch (e) {
          // ban
        }
      }

      try {
        await updateFaultRepair(myInfo.hospitalHashId, values.faultHospitalProduct.hashId, hashId, updateValues);
        await refetch();
        openSnackBar('修理情報を更新しました');
      } catch (error) {
        openSnackBar('修理情報の更新に失敗しました', 'left', 'bottom', 'error');
        throw error;
      }
    },
    [hashId, initialValues, myInfo.hospitalHashId, refetch, repairStatuses]
  );

  useEffect(() => {
    setInitialValues(data);
  }, [data]);

  return isLoading || !initialValues ? (
    <InnerLoading />
  ) : (
    <Formik<RepairIndex> initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
      {children}
    </Formik>
  );
};

export const RepairDetail: React.FC = (props) => {
  const templateClasses = use2ColumnsLayoutTemplate();
  const {hashId} = useParams();

  return (
    <Grid container className={templateClasses.root}>
      <Suspense fallback={null}>
        <ProductDetailContainer hashId={hashId ? hashId : ''}>
          <ProductDetailForm hashId={hashId ? hashId : ''} />
        </ProductDetailContainer>
      </Suspense>
    </Grid>
  );
};
