import React, {createContext, useContext} from 'react';

type UpdateScheduledDateDispatcher = (hashId: string, date: Date | null) => Promise<void>;

const UpdateScheduledDateDispatcherContext = createContext<UpdateScheduledDateDispatcher>(async () => {
  // empty function
});

type UpdateScheduledDateDispatcherProviderProps = {
  handler: UpdateScheduledDateDispatcher;
};

export const UpdateScheduledDateDispatcherProvider: React.FC<UpdateScheduledDateDispatcherProviderProps> = ({
  handler,
  children,
}) => {
  return (
    <UpdateScheduledDateDispatcherContext.Provider value={handler}>
      {children}
    </UpdateScheduledDateDispatcherContext.Provider>
  );
};

export const useUpdateScheduledDateDispatcher = () => {
  return useContext(UpdateScheduledDateDispatcherContext);
};

type DeleteInspectionResultDispatcher = (hashId: string) => Promise<void>;

const DeleteInspectionResultDispatcherContext = createContext<DeleteInspectionResultDispatcher>(async () => {
  // empty function
});

type DeleteInspectionResultDispatcherProviderProps = {
  handler: DeleteInspectionResultDispatcher;
};

export const DeleteInspectionResultDispatcherProvider: React.FC<DeleteInspectionResultDispatcherProviderProps> = ({
  handler,
  children,
}) => {
  return (
    <DeleteInspectionResultDispatcherContext.Provider value={handler}>
      {children}
    </DeleteInspectionResultDispatcherContext.Provider>
  );
};

export const useDeleteInspectionResultDispatcher = () => {
  return useContext(DeleteInspectionResultDispatcherContext);
};

type UpdateTableDispatcher = () => void | Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const UpdateTableDispatcherContext = createContext<UpdateTableDispatcher>(async () => {});

type UpdateTableDispatcherContextProviderProps = {
  handler: UpdateTableDispatcher;
};

export const UpdateTableDispatcherContextProvider: React.FC<UpdateTableDispatcherContextProviderProps> = ({
  handler,
  children,
}) => {
  return <UpdateTableDispatcherContext.Provider value={handler}>{children}</UpdateTableDispatcherContext.Provider>;
};

export const useUpdateTableDispatcher = () => {
  return useContext(UpdateTableDispatcherContext);
};
